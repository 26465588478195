<div class="row">
	<div class="col-md-5 mb-0 pt-1">
		<h4 class="titlehead vThdx1" style="position: relative;top: 5px;z-index: 9;">All Agents List </h4>
	</div>
	<div class="col-md-6 v-lftRyt mb-2">
		<div style="display: inline-flex;flex-wrap: wrap;">
			<div style="width:250px;">
				<mat-form-field appearance="outline">
					<input matInput placeholder="Search" autocomplete="off" [ngClass]="'vSerchField'"
						(keyup)="applySearchFilter($event.target.value)">
					<mat-icon matSuffix
						style="font-size: 24px;position: relative;top: 6px;color: #c0cbd6;">search</mat-icon>
				</mat-form-field>
			</div>
		</div>
	</div>
	<div class="col-md-1 mb-0 pt-1">
		<p class="closeP"><span style="top: -8px;right: -8px;" (click)="closeDialog()"
				title="Close"><mat-icon>close</mat-icon></span></p>
	</div>
</div>


<div class="row">
	<div class="col-md-12 mb-1">

		<mat-table [dataSource]="dataSource" class="vkTableAgent">
			<ng-container matColumnDef="select">
				<mat-header-cell *matHeaderCellDef> Select </mat-header-cell>
				<mat-cell *matCellDef="let element">
					<!-- <mat-checkbox (click)="adding_agent(element)"></mat-checkbox> -->
					<mat-checkbox [(ngModel)]="element.to_selected"
						(change)="adding_agent(element, $event)"></mat-checkbox>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="agentID">
				<mat-header-cell *matHeaderCellDef> Agent ID </mat-header-cell>
				<mat-cell *matCellDef="let element" style="color: #7094ba !important;font-weight: 500;"> {{element.id}}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="agentName">
				<mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Agent Name </mat-header-cell>
				<mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.name}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="email">
				<mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Email </mat-header-cell>
				<mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.email}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="currentCampaign">
				<mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Current Campaign </mat-header-cell>
				<mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.active_campaign}} </mat-cell>
			</ng-container>


			<mat-header-row *matHeaderRowDef=displayedColumns style="border-bottom-color: #fff;"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
		<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>


	</div>
</div>

<div style="display: flex;flex-wrap: wrap;justify-content: flex-end;">
	<div style="width:185px">
		<button class="vbtnxk" style="color: #8994a1;" (click)="cancelBtn()">CANCEL</button>
	</div>
	<div style="width:185px">
		<button class="vbtnxk" (click)="addAgentBtn()"> <mat-icon class="mr-1"
				style="vertical-align: middle;">add</mat-icon> ADD AGENT </button>
	</div>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>