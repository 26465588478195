<div class="v-mainDCDiv">
    <!-- <div class="row mb-4"> -->
      <!-- <div class="col-md-9"> -->
        <div class="mt-3">
          <mat-card class="v-dcCard1 pt-3  hty1">
            <!-- <p > <span class="v-dcTitle1">Campaign List</span> </p> -->
            <div *ngIf="showHideCampDetails">
              <p ><!--*ngIf="user.sip_id"--> <span class="v-dcTitle1">Active Campaigns</span> </p>
              <!-- <app-contact-list-campaign-details-page></app-contact-list-campaign-details-page> -->
  
              <p class="noData1" *ngIf="user.sip_id && campaignActiveListData.length==0">
                <span>No Available Active Campaigns Data</span>
              </p>
  
              <div class="row mt-4 mb-4" ><!--*ngIf="user.sip_id"-->
                <div class="col-lg-12 col-md-12 col-sm-12"
                  *ngIf="campaignActiveListData && campaignActiveListData.length > 0">
  
                  <carousel [arrowsOutside]="false" [autoplay]="false" [dots]="false" [loop]="true" [cellsToShow]="3"
                    class="croCls">
  
                    <div class="carousel-cell  btnsCrousal " *ngFor="let v of jsonData;let index=index;">
                      <mat-card class="mcdz" [ngClass]="{ activeMcdz: isActive(index) }" (click)="openCardTab(index,v)">
                        <table class="mcdz-table">
                          <tr style="background: #fd6c21;">
                            <td colspan="3" class="p-3">
                              <div class="tbBld text-truncate"  matTooltip="{{v.campaign}}">
                                {{v.campaign}}
                              </div>
                              <!-- <span style="float: right;"><button
                                  mat-mini-fab class="btnBag">
                                  {{v.campaign_priority}}
                                </button>
                              </span> -->
                              </td>
                          </tr>
                          <!-- <tr><td><b>Available</b></td><td>{{v.Available}}</td></tr>
                                              <tr><td><b>Completed</b></td><td>{{v.Completed}}</td></tr> -->
  
                          <!-- <tr class="bdrbm1">
                            <td> Campaign&nbsp;Type</td>
                            <td class="text-center">{{v.campaign_type}}</td>
                          </tr> -->
  
                          <!-- <tr class="bdrbm1"><td><b>Total&nbsp;Data </b></td><td class="text-right">{{v.total_data}}</td></tr> -->
                          
                          <tr class="bdrbm2title">
                            <td class="text-center bdrbm2title">{{(v.campaign_type == 'PREVIEW') ? v.total_data : 'NA'}}</td>
                            <td class="text-center">{{v.total_dialed}}</td>
                            <td class="text-center">{{(v.campaign_type == 'PREVIEW') ? v.total_available : 'NA'}}</td>
                          </tr>

                          <tr class="bdrbm2">
                            <td class="text-left">Assigned </td>
                            <td class="text-center">Called</td>
                            <td class="text-right">Available</td>
                            
                          </tr>
                          <!-- <tr class="bdrbm3">
                            <td>Available</td>
                            
                          </tr> -->
                          <!-- <tr class="bdrbm2" style="background: #f0f0f0;">
                            <td colspan="2" class="text-center mt-2">Start Time </td>
                          </tr> -->
                          <tr class="bdrbm3" style="background: #f0f0f0;">
                            <td class="text-center">{{v.campaign_type}}</td>
                            <td class="text-center">{{v.created_at | date:'M/d/yy, h:mm a'}}</td>
                            <td class="text-center">{{v.campaign_priority}}</td>
                          </tr>
                        </table>
                      </mat-card>
                    </div>
  
  
                  </carousel>
                  <div class="mt-4" style="overflow: auto;height:400px;">
                    <table class="viewTablex1">
                      <tr class="bdrbm1" style="position: sticky;top: 0px;background: #fff;z-index: 2;">
                        <td>Details</td>
                        <td>Customer Name</td>
                        <td>Phone</td>
                        <td>Call Status
                          <mat-icon [matMenuTriggerFor]="menuFilter" class="menuFiltercs"> arrow_drop_down</mat-icon>
                          <mat-menu #menuFilter="matMenu">
                            <button mat-menu-item (click)="statusFilterClick('All')">All </button>
                            <button mat-menu-item (click)="statusFilterClick('NEW-DATA')">NEW DATA </button>
                            <button mat-menu-item (click)="statusFilterClick('FOLLOW-UP')">FOLLOW-UP</button>
                          </mat-menu>
                        </td>
                        <td>Updated At</td>
                        <td colspan="2"></td>
                      </tr>
                      <tbody *ngFor="let a of vTable;let index=index;">
                        <tr>
                          <td>
                            <mat-icon class="vCallCellIconx1" style="background: #fd6c21;" (click)="contactNameDetails(a)"
                              matTooltip="View Details">open_in_new</mat-icon>
                          </td>
                          <td>{{a.name}}</td>
                          <td>{{a.number | mask_number:'--'}}</td>
                          <td>{{a.call_status}}</td>
                          <td>{{a.updated_at | date:'d MMM y, h:mm:ss a'}}</td>
                          <td>
                            <mat-icon class="vCallCellIconx1" matTooltip="Click Whatsapp Template"
                              (click)="callWhatsapp('whatsapp',a)">chat</mat-icon>
                          </td>
                          <td>
                            <mat-icon class="vCallCellIconx1" matTooltip="Call" (click)="callButtonClick(a)">call
                            </mat-icon>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th class="warncsx1" colspan="7">{{warningMsg}}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!showHideCampDetails">
            <div ><!--*ngIf="!user.sip_id"-->
              <div class="dc-labelGroup">
                <p *ngIf="dataSource">
                  <input matInput placeholder="Search Contacts" (keyup)="applyFilter($event.target.value)"
                    autocomplete="off"
                    style="border: solid 1px #dbe5ed;background-color: #f9fafc !important;width:100%" />
                  <mat-icon matSuffix class="srch1">search</mat-icon>
                </p>
              </div>
            </div>
            <div ><!--*ngIf="!user.sip_id"-->
              <mat-table class="tableContainer" #table [dataSource]="dataSource">
                <ng-container matColumnDef="alphabetStart">
                  <mat-cell *matCellDef="let element" class="charName" (click)="toggleRow(element)">
                    <span [ngStyle]="{'font-weight': highlightedRows===element ? '600' : '500'}"> {{element.Name ?
                      element.Name.charAt(0) : 'NA'}}</span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Name">
  
                  <mat-cell *matCellDef="let element">
                    <span class="nameImgIco" matTooltip="Contact Details"
                      (click)="contactDataDetails(element)">{{element.Name ? element.Name.charAt(0) : 'NA'}}</span>
                    <span class="conNameView" (click)="toggleRow(element)"
                      [ngStyle]="{'font-weight': highlightedRows===element ? '600' : '500'}">
                      {{element.Name ? element.Name : 'NA'}}
                    </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                  <mat-cell *matCellDef="let element" style="display: flex;justify-content: flex-end;"
                    (click)="toggleRow(element)">
                    <mat-icon class="vCallCellIcon" (click)="CallChatPhoneClick('whatsapp',element)"
                      [ngClass]="_flagStartStop=='StopFlag'?'vCallCellIcon1':'vCallCellIcon2'">
                      <!-- whatsapp -->
                      chat
                    </mat-icon>
                    <mat-icon class="vCallCellIcon ml-4" (click)="_flagStartStop=='StopFlag'?'vCallCellIcon1':CallChatPhoneClick('phone',element)"
                      [ngClass]="_flagStartStop=='StopFlag'?'vCallCellIcon1':'vCallCellIcon2'">
                      call</mat-icon>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                  <mat-cell *matCellDef="let detail">
  
                    <div class="p-4">
                      <p class="contactTitle1 mb-2">Select Whatsapp Template</p>
                      <div class="dc-labelGroup">
                        <p>
                          <mat-select [ngClass]="'arrowcs'" class="dropdownCs mt-3" [(ngModel)]="templateddlValue"
                            (selectionChange)="changeWhatsappTemplate($event.value)" placeholder="Select a Template">
                            <mat-option [value]="v.template_name" *ngFor="let v of _ddlListExtraOption">
                              {{v.template_name}}</mat-option>
                          </mat-select>
                        </p>
                      </div>
                      <div class="dc-labelGroup">
                        <span class="contactTitle2">
                          <p [innerHTML]="templatepart1 " style="padding-left: 15px;">{{templatepart1}}</p>
                        </span>
                      </div>
                      <!-- <div class="dc-labelGroup">
                                  <p>
                                      <input matInput placeholder="Birthday Wishes" autocomplete="off" style="width:300px;"/>
                                  </p>
                              </div> -->
                      <div class="dc-labelGroup" *ngIf="ismedia">
                        <p>
                          <img src={{media}} style="width: 100%;" />
                        </p>
                      </div>
  
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Relationship_Manager">
                        <p>
                          <span class="lvl">Relationship_Manager</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="relationshipManagerModel">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Product_Name">
                        <p>
                          <span class="lvl">Product_Name</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Product_Name">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Tracking_Link">
                        <p>
                          <span class="lvl">Tracking Link</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="trackingLinkModel">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Phone_Number">
                        <p>
                          <span class="lvl">Phone number</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput (keypress)="onlyNumberValidation($event)" class="mt-3 dtcs" autocomplete="off"
                              maxlength="15" [(ngModel)]="phoneNoModel">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Accept_Key">
                        <p>
                          <span class="lvl">Accept_Key</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Accept_Key">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Reject_Key">
                        <p>
                          <span class="lvl">Reject_Key</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Reject_Key">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Publisher_Name">
                        <p>
                          <span class="lvl">Publisher_Name</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Publisher_Name">
                          </mat-form-field>
                        </p>
                      </div>
  
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Service_Station">
                        <p>
                          <span class="lvl">Service_Station</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Service_Station">
                          </mat-form-field>
                        </p>
                      </div>
  
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Vehicle_Number">
                        <p>
                          <span class="lvl">Vehicle_Number</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Vehicle_Number">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Invoice_Number">
                        <p>
                          <span class="lvl">Invoice_Number</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Invoice_Number">
                          </mat-form-field>
                        </p>
                      </div>
  
  
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Service_Name">
                        <p>
                          <span class="lvl">Service_Name</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Service_Name">
                          </mat-form-field>
                        </p>
                      </div>
  
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Product_Link">
                        <p>
                          <span class="lvl">Product_Link</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Product_Link">
                          </mat-form-field>
                        </p>
                      </div>
  
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Web_Link">
                        <p>
                          <span class="lvl">Web_Link</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Web_Link">
                          </mat-form-field>
                        </p>
                      </div>
                      <div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Next_Update_Time">
                        <!-- *ngIf="_rowExtraOptionData['Next_Update_Time']['type']=='DATE'" -->
                        <p>
                          <span class="lvl">Next_Update_Time</span> <br>
                          <mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
                            <input #fromInput matInput [min]="minDate" [matDatepicker]="picker" class="mt-3 dtcs"
                              placeholder="Date" [(ngModel)]="dateSelect" readonly (click)="picker.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 18px !important;">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                        </p>
                      </div>
                      <p class="text-right"> <button class="dcContactSubmit" (click)="saveBtn()"> Submit </button></p>
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-row *matRowDef="let element; let row; columns: displayedColumns;" matRipple class="element-row"
                  style="min-height: 70px;"></mat-row>
  
                <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                  [@detailExpand]="row.element.show ? 'expanded' : 'collapsed'" style="overflow: hidden">
                </mat-row>
              </mat-table>
            </div>
            </div>
          </mat-card>
        </div>
  
      <!-- </div> -->
      <!-- <div class="col-md-3 pl-0">
        <mat-card class="v-dcCard1 mt-3 pl-1 pr-1 vMinHtStart">
          <div class="col-md-12">
            <div *ngIf="showHideStart">
              <p class="text-center"><img src="../assets/desktop_calling/v-Start-Logo.png" class="vStartLogo1" /></p> 
              <p class="text-center mb-1">
                <button class="v-Start-Dial-Btn" (click)="startBtnCall()" [disabled]="!isContactsAvilable">
                  <mat-icon class="vCallIcon1"> call</mat-icon>START
                </button>
              </p>
              <p class="text-center"> <span class="v-dcSpan3"> Click Start For Calling</span></p> 
              <p class="text-center" *ngIf="campaignActiveListData.length!=0"> <span class="v-dcSpan3"> Click Start For
                Calling</span></p>
        <p class="text-center" *ngIf="!isContactsAvilable"> <span class="v-dcSpan3_disabled">Syncing customers</span></p>
            </div>
            <div *ngIf="showHideStop">
               <p class="text-center"><img src="../assets/desktop_calling/v-Stop-Logo.png" class="vStartLogo1" /></p> 
              <p class="text-center mb-1">
                <button class="v-Stop-Dial-Btn" (click)="stopBtnCall()">
                  <mat-icon class="vCallIcon1"> stop_circle</mat-icon>STOP
                </button>
              </p>
              <p class="text-center"> <span class="v-dcSpan3"> Click here to end session</span></p>
            </div>
          </div>
        </mat-card>
        <div class="mt-3" *ngIf="isStageDial">
          <mat-card class="v-dcCard1 vHt1" style="padding: 10px;">
  
  
            <div class="text-center">
              <table style="width:100%">
                <tr>
                  <td colspan="3">
                    <div class="output mb-3" style="height: 70px;">
                        <input id="codeDialPad" class="numInput" [disabled]="showHideDiallerPage? true:false" autocomplete="off" maxlength="10" (keypress)="phoneInputPress($event)" (paste)="pasteTextPhone($event)">
                        <span class="v-cnName" *ngIf="!showHideDiallerPage">Contact Number</span>
                    </div>
                </td>
                </tr>
                <tr>
                  <td> <button class="v-digit" id="one" (click)="clickNumBtn(1)"
                    [disabled]="showHideDiallerPage? true:false"> 1</button> </td>
                  <td> <button class="v-digit" id="two" (click)="clickNumBtn(2)"
                    [disabled]="showHideDiallerPage? true:false"> 2</button></td>
                  <td> <button class="v-digit" id="three" (click)="clickNumBtn(3)"
                    [disabled]="showHideDiallerPage? true:false"> 3</button> </td>
                </tr>
                <tr>
                  <td> <button class="v-digit" id="four" (click)="clickNumBtn(4)"
                    [disabled]="showHideDiallerPage? true:false"> 4</button> </td>
                  <td> <button class="v-digit" id="five" (click)="clickNumBtn(5)"
                    [disabled]="showHideDiallerPage? true:false"> 5</button> </td>
                  <td> <button class="v-digit" id="six" (click)="clickNumBtn(6)"
                    [disabled]="showHideDiallerPage? true:false"> 6</button></td>
                </tr>
                <tr>
                  <td> <button class="v-digit" id="seven" (click)="clickNumBtn(7)"
                    [disabled]="showHideDiallerPage? true:false"> 7</button></td>
                  <td> <button class="v-digit" id="eight" (click)="clickNumBtn(8)"
                    [disabled]="showHideDiallerPage? true:false"> 8</button></td>
                  <td> <button class="v-digit" id="nine" (click)="clickNumBtn(9)"
                    [disabled]="showHideDiallerPage? true:false"> 9</button></td>
                </tr>
                
                <tr>
                  <td> <button class="v-digit" [disabled]="showHideDiallerPage? true:false"> <span
                              style="font-size: 40px;position:relative;top:8px;">* </span></button> </td>
                  <td> <button class="v-digit" (click)="clickNumBtn(0)"
                          [disabled]="showHideDiallerPage? true:false"> 0</button> </td>
                  <td> <button class="v-digit" [disabled]="showHideDiallerPage? true:false">
                          #</button> </td>
              </tr>
                
                <tr>
                  <td></td>
                  <td>
  
                      <mat-icon (click)="checkCustomerName()" class="vDigitCall"
                          [ngClass]="showHideDiallerPage?'vDigitCall1':'vDigitCall'"
                          [disabled]="showHideDiallerPage? true:false">call</mat-icon>
  
                  </td>
                  <td> <button class="v-digit" [disabled]="showHideDiallerPage? true:false"
                          style="border: none;box-shadow: none;">
                          <img src="../assets/desktop_calling/closeDigitImg.png" class="closeDigit"
                              (click)="removeNum()"
                              [ngClass]="showHideDiallerPage?'cur1':'cur2'" />
                      </button> </td>
                      
              </tr>
              </table>
            </div>
          </mat-card>
        </div>
      </div> -->
    <!-- </div> -->
  </div>
  
  <div class="dc-popupoverlay11" *ngIf="showHidePopup">
    <div class="dc-delete-popup11">
      <p> <span class="v-dcTitle1">Update Details</span> </p>
      <div>
        <mat-form-field style="width:100%;" [ngClass]="'vUpDet'">
          <input matInput placeholder="Enter Name" class="vUpDetailsInput" (keypress)="onlyNumAlphabetValidation($event)"
            [(ngModel)]="customer_name_entered" autocomplete="off" />
        </mat-form-field>
      </div>
      <p class="text-right mt-3 mb-1">
        <button class="dcClear1" (click)="cancelUpdateBtn()"> Cancel </button>
        <button class="dcClear1" (click)="clearBtn()"> Clear </button>
        <button class="dcUpdate1" (click)="startCallLoadingUpdate()"> Update </button>
      </p>
    </div>
  </div>
  <div id="cover-spin" *ngIf="showLoader"></div>  
