import { Component, OnInit,Input,Inject,Output,EventEmitter,ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { MyserviceService } from '../myservice.service';
import { ServicesService } from '../dashboard/services.service';

import {Utils} from '../utils'

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, AfterViewInit {
	currentTitle: string = "customer";
	
	today = moment().format('DD-MMM-YYYY');
	
	default_summary = function () {
		return {
			'call_summary': {
				'dialled': 0,
				'contacted': 0,
				'rnr': 0
			},
			'l1_data': {
				'total': 0,
				'selected': 0,
				'rejected': 0
			},
			'l2_data': {
				'total': 0,
				'selected': 0,
				'rejected': 0
			},
			'client_data': {
				'total': 0,
				'selected': 0,
				'rejected': 0
			}
		}
	}
	
	currentLoader: boolean = false;
	table_data: any;
	util = new Utils();
	displayedColumns: string[] = ['sl_no', 'agency', 'name', 'aadhar', 'phone', 'event_1_status', 'event_2_status',
	'event_3_status', 'training_status', 'certified', 'onBoarding'];

	dataSource = new MatTableDataSource();
	customer_summary = this.default_summary();

  	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		private http: HttpClient,
		private _service: MyserviceService,
		private dashService: ServicesService,
		private router: Router
	) {
		// console.log('******* Agency Constructor starts ******');
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
		.subscribe((event: NavigationEnd) => {
			// console.log('subscribe event:', event);
			if (event.id == 1) {
				this.refreshData();
			}
		});
	}
	ngOnInit() {
		//console.log('comig to agency dashboard');
		this.dataSource.paginator = this.paginator;
		this.dashService.applicantData.subscribe(
			(d) => {
				this.loadTableData(d);
			}
		)
	}

	ngAfterViewInit(){
		this.dataSource.paginator = this.paginator; 
	}
	loadTableData(data: any) {
		if (data && data.length) {
			var arr = []
			for (var i in data) {
				var obj = new Object();
				let index = parseInt(i);
				let loc_obj = data[i];
				obj['sl_no'] = index + 1;
				obj['name'] = loc_obj.name;
				obj['agency'] = this.util.getValue(loc_obj.master_campaign, '');
				obj['aadhar'] = 'XXXXX';
				obj['phone'] = loc_obj.number;
				obj['created_at'] = moment(loc_obj.created_at,'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY');
				obj['current_status'] = loc_obj.current_status;
				obj['event_1_interview'] = this.util.utcToLocal(loc_obj.event_1_date,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY');
				obj['event_1_organizer'] = loc_obj.event_1_organizer;
				obj['event_1_status'] = loc_obj.event_1_status;
				obj['event_2_interview'] = this.util.utcToLocal(loc_obj.event_2_date,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY');
				obj['event_2_organizer'] = loc_obj.event_2_organizer;
				obj['event_2_status'] = loc_obj.event_2_status;
				obj['event_3_interview'] = this.util.utcToLocal(loc_obj.event_3_date,'YYYY-MM-DDTHH:mm:ss','DD/MM/YYYY');;
				obj['event_3_organizer'] = loc_obj.event_3_organizer;
				obj['event_3_status'] = loc_obj.event_3_status;
				obj['training_start_date'] = loc_obj.training_start_date;
				obj['training_end_date'] = loc_obj.training_end_date;
				obj['trainer_id'] = loc_obj.trainer_id;
				obj['training_status'] = '';
				obj['certified'] = '';
				obj['onBoarding'] = '';
				arr.push(obj);
			}
			//console.log('printing arr',arr)
			this.dataSource = new MatTableDataSource(arr);
			this.dataSource.paginator = this.paginator;
			this.prepareSummary();
		}
	}
	prepareSummary() {
		let obj = this.default_summary();
		let raw_data = this.dataSource.data;
		if (raw_data && raw_data.length) {
			for (var i in raw_data) {
				let loc_obj = raw_data[i];
				if (loc_obj['event_1_status']) {
					if (loc_obj['event_1_status'] == 'selected') {
						obj['l1_data']['total'] += 1;
						obj['l1_data']['selected'] += 1;
					}
					if (loc_obj['event_1_status'] == 'rejected') {
						obj['l1_data']['total'] += 1;
						obj['l1_data']['rejected'] += 1;
					}
				}
				if (loc_obj['event_2_status']) {
					if (loc_obj['event_2_status'] == 'selected') {
						obj['l2_data']['total'] += 1;
						obj['l2_data']['selected'] += 1;
					}
					if (loc_obj['event_2_status'] == 'rejected') {
						obj['l2_data']['total'] += 1;
						obj['l2_data']['rejected'] += 1;
					}
				}
				if (loc_obj['event_3_status']) {
					if (loc_obj['event_3_status'] == 'selected') {
						obj['l3_data']['total'] += 1;
						obj['l3_data']['selected'] += 1;
					}
					if (loc_obj['event_3_status'] == 'rejected') {
						obj['l3_data']['total'] += 1;
						obj['l3_data']['rejected'] += 1;
					}
				}
			}
		}
		this.customer_summary = obj;
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	getL1InterviewColor(l1interview){
		switch (l1interview) {
			case 'RNR':
				return '#ff6803';
			case 'selected':
				return '#0dca0d';
			case 'NEW':
				return '#a129e5';
			case 'rejected':
				return '#ff8499';
		}
	}
	getL2InterviewColor(l2interview)
	{
		switch (l2interview) {
			case 'RNR':
				return '#ff6803';
			case 'selected':
				return '#0dca0d';
			case 'NEW':
				return '#a129e5';
			case 'rejected':
				return '#ff8499';
		}
	}

	refreshData() {
		this.currentLoader = true;
		this.dashService.setApplicantsData("","").then(
			() => {
				this.currentLoader = false;
				// Received data from the api service - Applicant data
			}, (err) => {
				this.currentLoader = false;
				alert('dash-component, Error in the api service')
			}
		)
	}
}
