import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { Subject, BehaviorSubject, observable, Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from 'src/app/myservice.service';


@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  api_end_point:any;
  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private API:ApiService,
    private myservice: MyserviceService) {
	  this.angularFireMessaging.messages.subscribe(
		  (_messaging: AngularFireMessaging) => {
			  _messaging.onMessage = _messaging.onMessage.bind(_messaging);
			  _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
		  })
    
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("Krunal1990",token);
        this.updateToken(userId, token);
        var myUser = this.myservice.get_loggedin_user();
		this.api_end_point = this.myservice.get_API_End_Point();
        this.API.remotePushToken(myUser.id, token, myUser.server_url,this.api_end_point).subscribe(
          (res: any) => {
            console.log(res)
            
          }, (err) => {
            console.log(err)
          }
        )
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }

  getMessage(): Observable<any> {
		return this.currentMessage.asObservable();
	}
}