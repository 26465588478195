import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { ApiService } from '../api.service';
import { MyserviceService } from 'src/app/myservice.service';

@Component({
  selector: 'app-payment-dashboard-configure',
  templateUrl: './payment-dashboard-configure.component.html',
  styleUrls: ['./payment-dashboard-configure.component.scss']
})
export class PaymentDashboardConfigureComponent implements OnInit {
  createdForm:FormGroup;
  _color:any="";
  showHidePassingMessage;
  passingMessage:any="";
  api_end_point:any;
  constructor(private formbuilder:FormBuilder,private API: ApiService,private myService: MyserviceService) { 
    this.createdForm=this.formbuilder.group({ 
      basePriceType:['',Validators.required],
      basePriceRate:['',Validators.required],
      surgePriceType:[''],
      surgePriceMinvalue:[''],
      surgePriceRate:['']
    })
  }

  ngOnInit() {
	this.api_end_point = this.myService.get_API_End_Point();
  }
  saveBtn(createdForm:any){
    this.passingMessage="";
    this._color='';
     this.showHidePassingMessage = false;

     let _basePriceType=createdForm.controls.basePriceType.value;
     let _basePriceRate=createdForm.controls.basePriceRate.value;
     let _surgePriceType=createdForm.controls.surgePriceType.value;
     let _surgePriceMinvalue=createdForm.controls.surgePriceMinvalue.value;
     let _surgePriceRate=createdForm.controls.surgePriceRate.value;

if(_basePriceType=="" || _basePriceType==null){
  this.passingMessage="* Please select base price type";
  this._color='red';
   this.showHidePassingMessage = true;
   setTimeout(()=>{
    this.passingMessage="";
    this._color='';
     this.showHidePassingMessage = false;
   },6000);
   return;
}

if(_basePriceRate=="" || _basePriceRate==null){
  this.passingMessage="* Please enter base price rate";
  this._color='red';
   this.showHidePassingMessage = true;
   setTimeout(()=>{
    this.passingMessage="";
    this._color='';
     this.showHidePassingMessage = false;
   },6000);
   return;
}

let _obj={
  "base_price":{
  "type":_basePriceType,
  "value":_basePriceRate
},
"surge_price":{
  "type":"",
  "min_value":"",
  "value":""
}
}
this.API.savePaymentConfigure(_obj,this.api_end_point).subscribe((data: any) => {
  let _response = data;
  if (_response.success== "Data created successfully.") {
    this.passingMessage="Data created successfully.";
    this._color='#3fb91f';
     this.showHidePassingMessage = true;     
     this.createdForm.reset();
     setTimeout(()=>{
      this.passingMessage="";
      this._color='';
       this.showHidePassingMessage = false;
     },4000);

  }
});
    }
    resetBtn(){
     this.createdForm.reset();
     this.passingMessage="";
     this._color='';
      this.showHidePassingMessage = false;
    }
 
	/*onlyNumberValidation(evt){
	var charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode != 46 && charCode > 31 
				&& (charCode < 48 || charCode > 57))
				return false;

			return true;
	}*/
	decimalFilter(event: any) {
		const reg = /^-?\d*(\.\d{0,2})?$/;
		let input = event.target.value + String.fromCharCode(event.charCode);
	 
		if (!reg.test(input)) {
			event.preventDefault();
		}
	 }
}
