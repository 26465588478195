<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<!-- Activity table starts -->
<table class="maintable">
  <!-- <tr>
    <td>
      <h3 class="titlehead pt-3"><span class="back_link" routerLink="../agent_dash">Back to Dashboard</span></h3>
    </td>
  </tr> -->
  <tr>
    <td>
		<div class="title_bar">
			<div class="act_title inline_div">
				<span class="recName"> Agent Activity Table</span>
			</div>
			<div class="date_field inline_div">
				<span>
					<mat-form-field>
						<input matInput [matDatepicker]="frompicker" [max]="maxDate" placeholder="From" readonly
						  (click)="frompicker.open()" (dateChange)="change_date_status('start_date', $event)"
						  [(ngModel)]="StartDate">
						<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
						<mat-datepicker #frompicker></mat-datepicker>
					  </mat-form-field>
				</span>
			</div>
			<div class="search_filed inline_div">
				<div class="search_box">
					<span class="searchSpan">
						<mat-form-field>
						  <input matInput (keyup)="applySearchFilter($event.target.value)" placeholder="Search">
						  <i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
						</mat-form-field>
					  </span>
				  </div>
			</div>
		</div>
		<div class="divprintRt">
			<!--<span class="recName">
				Agent Activity Table
			  </span>
			  <div class="search_box">
				<span class="searchSpan">
					<mat-form-field>
					  <input matInput (keyup)="applySearchFilter($event.target.value)" placeholder="Search">
					  <i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
					</mat-form-field>
				  </span>
			  </div>
			<div class="row pl-4 pt-3">
			  <div class="col-md-3">
				<span>
				  <mat-form-field class="wd-100">
					<input matInput [matDatepicker]="frompicker" [max]="maxDate" placeholder="From" readonly
					  (click)="frompicker.open()" (dateChange)="change_date_status('start_date', $event)"
					  [(ngModel)]="StartDate">
					<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
					<mat-datepicker #frompicker></mat-datepicker>
				  </mat-form-field>
				</span>
			  </div>
			  <div class="col-md-3"><span>
				  <mat-form-field class="wd-100">
					<input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate" placeholder="To" readonly
					  (click)="topicker.open()" (dateChange)="change_date_status('end_date', $event)"
					  [(ngModel)]="EndDate">
					<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
					<mat-datepicker #topicker></mat-datepicker>
				  </mat-form-field>
				</span>
			  </div>
			  <div class="col-md-2">
				<span (click)="refreshData()">
				  <button mat-button>Go</button>
				</span>
			  </div> 
			  <div class="col-md-4">
				<span class="searchSpan">
				  <mat-form-field>
					<input matInput (keyup)="applySearchFilter($event.target.value)" placeholder="Search">
					<i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
				  </mat-form-field>
				</span>
			  </div>
			</div>-->
			<p>
			  <!-- <span class="das-pe">Print</span> -->
			  <span class="das-pe" (click)="refreshData()">Refresh</span>
			  <span class="das-pe" (click)="exportExcel()">Export</span>
			  <!-- <span class="das-pe" (click)="refreshCampaignData()">Refresh</span> -->
			</p>
		  </div>
    </td>
  </tr>
  <tr>
    <td>
		<div class="md-12 sm-12 xs-12">
			<table mat-table [dataSource]="activity_table" matSort class="mat-elevation-z8">
  
			  <ng-container matColumnDef="agent_name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Agents </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.agent_name}} </td>
			  </ng-container>
  
			  <ng-container matColumnDef="agent_email">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.agent_email}} </td>
			  </ng-container>
  
			  <ng-container matColumnDef="login_time">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Login Time </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.login_time}} </td>
			  </ng-container>
  
			  <ng-container matColumnDef="active_hrs">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Active Hours </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.active_hrs}} </td>
			  </ng-container>
  
			  <ng-container matColumnDef="break_hrs">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Break Hours </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.break_hrs}} </td>
			  </ng-container>
  
			  <ng-container matColumnDef="logout_time">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header> Logout Time </th>
				  <td mat-cell *matCellDef="let element" class="CR"> {{element.logout_time}} </td>
				</ng-container>
			  
			  <ng-container matColumnDef="data">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header> More </th>
				  <td mat-cell *matCellDef="let element" class="CR">
					  <button mat-icon-button aria-label="Open Detail Timings" class=""
						  matTooltip="Details" matTooltipPosition="below" (click)="openDetailActivity(element)">
						  <mat-icon style="color: #fd6c21 !important;">launch</mat-icon>
					  </button>
				  </td>
			  </ng-container>
  
			  <tr mat-header-row *matHeaderRowDef="activity_table_columns; sticky: true"></tr>
			  <tr mat-row *matRowDef="let row; columns: activity_table_columns;"></tr>
			</table>
			<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
		  </div>
    </td>
  </tr>
</table>
<!-- Activity table ends -->
<div id="cover-spin" *ngIf="currentLoader"></div>
