<ngb-carousel #uearnSlide [showNavigationArrows]="false" [showNavigationIndicators]="true">
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<div class="row">
				<div class="col-sm-5 col-md-5 col-lg-5 item_image image1"></div>
				<div class="col-sm-7 col-md-7 col-lg-7 item_text">
					<h3 style="font-size: 25px;">A Virtual Customer Experience Platform</h3>
                    <p>Uearn connects various brands with freelance customer experience experts like you to solve their customer's problems in real time</p>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<div class="row">
				<div class="col-sm-5 col-md-5 col-lg-5 item_image image2"></div>
				<div class="col-sm-7 col-md-7 col-lg-7 item_text">
					<h3>A customer has a problem</h3>
                    <p>A customer needs help with the product or service, they reach us on multiple touchpoints. </p>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<div class="row">
				<div class="col-sm-5 col-md-5 col-lg-5 item_image image3"></div>
				<div class="col-sm-7 col-md-7 col-lg-7 item_text">
					<h3>You will interact with the customer</h3>
                    <p>Uearn App will facilitate the interaction with the customer on your mobile</p>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<div class="row">
				<div class="col-sm-5 col-md-5 col-lg-5 item_image image4"></div>
				<div class="col-sm-7 col-md-7 col-lg-7 item_text">
					<h3>You solve their problem & earn money</h3>
                    <p>Uearn app will train you on specific processes to solve these problems, earn money for every customer experience resolution you  you make, and get paid weekly</p>
				</div>
			</div>
		</div>
	</ng-template>
</ngb-carousel>
