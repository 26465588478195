import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuditorDataViewDialogComponent } from '../auditor-data-view-dialog/auditor-data-view-dialog.component';
import { MyserviceService } from '../../myservice.service';
import { ExcelService } from '../../reportExcel.service';
import { ApiService } from '../../api.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utils } from '../../utils';
import * as moment from 'moment';

@Component({
	selector: 'app-auditor-data',
	templateUrl: './auditor-data.component.html',
	styleUrls: ['./auditor-data.component.scss']
})
export class AuditorDataComponent implements OnInit {

	displayedColumns = ['project_name', 'call_id', 'agent_name', 'start_time', 'end_time', 'quality_score', 'audit_status', 'action'];
	dataSource: any = new MatTableDataSource();
	myUser: any;
	allProjects: any[] = [];
	isToday: boolean = true;
	table_data: any = [];
	searchFilter = '';
	callStartDate = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
	callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	maxDate = moment().subtract(0, 'day').toISOString();
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;
	@ViewChild('searchInput', {
		read: MatInput
	}) searchInput: MatInput;

	isEditBatch: boolean = false;
	selectedFilter: any = "All";
	_allData: any;
	_ActiveData: any;
	_InactiveData: any;

	constructor(private API: ApiService, private dialog: MatDialog, public router: Router, private service: MyserviceService, private utils: Utils, private _snackBar: MatSnackBar, public excelService: ExcelService) {
	}
	ngOnInit() {

		this.myUser = this.service.get_loggedin_user();

		this.refreshData(false);

	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}
	viewAuditors(element: any) {
		const dialogRef = this.dialog.open(AuditorDataViewDialogComponent, {
			width: '50%',
			disableClose: true,
			data: element
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}

	/*refreshTable(){
	  let obj: any = {};
	  obj.for_group = 2053;
	  obj.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
	  obj.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
  
	  //console.log(obj);
	  this.API.getAllCallsAudit(this.myUser.id,obj).subscribe((response: any) => {
		  this.dataSource = new MatTableDataSource(response.success);
		  this.dataSource.paginator = this.paginator;
		  this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
			return data.project_name.toString().toLowerCase().includes(filter) || data.status.toString().toLowerCase().includes(filter)
		  };
		});
	}*/

	refreshData(isHistory?: boolean) {
		//debugger;
		if (!this.callStartDate || !this.callEndDate) {
			return;
		}
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.paginator = this.paginator;
		this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
			return data.name.toString().toLowerCase().includes(filter)
		};
		let obj: any = {};
		obj.for_group = 2053;
		obj.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');

		//console.log(obj);
		this.API.getAllCallsAudit(this.myUser.id, obj).subscribe((data: any) => {

			let _response = data.success;
			//console.log(_response);
			//debugger;
			if (_response) {
				let ELEMENT_DATA = _response;
				
				this.dataSource = new MatTableDataSource(ELEMENT_DATA);
				this.dataSource.paginator = this.paginator;
				this.table_data = this.dataSource.data;
				this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
					return data.name.toString().toLowerCase().includes(filter)
				};

			}
		});


	}

	openCallUrl(element: any) {
		//console.log(this.myUser.access_token);
		//let call_url = element.call_url+"&changeFilter="+this.myUser.access_token;
		//	console.log(element.call_url);
		//?access_token=this.myUser.access_token}
		window.open(element.call_url, "_target")
	}
	downLoadReport() {
		if (this.dataSource.data.length == 0) {
			this._snackBar.open("No data found*", 'close');
		}
		//debugger;
		let _data = this.dataSource.data;
		this.excelService.exportAsExcelFile(_data, 'report');
	}
	change_date_call_status(key: string, event: MatDatepickerInputEvent<Date>) {
		this.isToday = false;
		if (key == 'start_date') {
			this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				//this.endDateFilter = "";
				//alert("Start date should be less than end date");
				//return;
			}
			/* if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			} */
		} else {
			this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this._snackBar.open("Start date should be less than end date *", 'close');

			}

		}
	}
	changeFilter(val: any) {
		this.searchInput.value = '';
		// this.currentLoader = true;
		this.selectedFilter = val;
		// this.refreshTable();
		this.refreshTable();
	}
	refreshTable() {
		// console.log('table refreshed');
		//debugger;
		if (this.selectedFilter == "All") {
			this._allData = this.table_data;
			this.dataSource = new MatTableDataSource(this._allData);
		}
		else if (this.selectedFilter == "Active") {
			//console.log(this.table_data);
			this._ActiveData= this.table_data.filter(v => { 
				//console.log(v['status']);v.status == 1||
				return v.audit_status == 1;
			});
		//	console.log(this._ActiveData);
			this.dataSource = new MatTableDataSource(this._ActiveData);

		}
		else if (this.selectedFilter == "Inactive") {
			this._InactiveData = this.table_data.filter(v => { return v.audit_status == 0 });

			this.dataSource = new MatTableDataSource(this._InactiveData);

		} else {
			this.dataSource = new MatTableDataSource(this.table_data);
		}
		this.dataSource.paginator = this.paginator;
		// console.log('search filter', this.searchFilter);
		if (this.searchFilter && this.searchFilter.length) {
			this.dataSource.filter = this.searchFilter.trim().toLowerCase();
		}
		// this.dataSource.data.pushValue
		// 
		// this.table.renderRows();
	}

}
