<div  class="m-3">

        <mat-card  class="mt-cd">
          <div class="flexcontainer">
            <div> <span class="span-label"> Call Id:</span> 
                        <span class="span-label-data">xxx</span></div>
            <div>  <span class="span-label"> Agent:</span> 
                        <span class="span-label-data">xxx</span></div>      
            <div>  <span class="span-label">Project:</span> 
                        <span class="span-label-data">xxxx</span></div>
            <div>     <span class="span-label">Emails to Audit:</span> 
                        <span class="span-label-data">xxxx</span></div>
            <div> <span class="span-label"> Audited:</span> 
                        <span class="span-label-data">xxxx</span></div>  
            <div> <span class="span-label"> Total Earned:</span> 
                        <span class="span-label-data"> xxxx</span></div>
                      <div>&nbsp;</div>
     <!--  <div>  <span class="span-label"> <input type="text" placeholder="search" class="input-field"></span></div> -->
          </div>
       <!-- <div class="row">        
            <div class="col-md-2">
              <span class="span-label"> Call Id:</span> 
              <span class="span-label-data">12038255</span>
          </div>
            <div class="col-md-2">
                 <span class="span-label"> Agent:</span> 
              <span class="span-label-data">Rachana Sachan</span>
          </div>
            <div class="col-md-2">
                 <span class="span-label">Project:</span> 
              <span class="span-label-data">OYO Rooms</span>
          </div>
            <div class="col-md-2">
                 <span class="span-label">Emails to Audit:</span> 
              <span class="span-label-data">6</span>
          </div>
            <div class="col-md-2">
                 <span class="span-label"> Audited:</span> 
              <span class="span-label-data">866</span>
          </div>
           <div class="col-md-2">
                 <span class="span-label"> Total Earned:</span> 
              <span class="span-label-data">5266</span>
          </div> 
          
  </div>  
  
    <div class="mt-2" style="float:right;">
                 <span class="span-label"> <input type="text" placeholder="search" class="input-field"></span>
          </div>  -->

  <div class="span-voice">
  <span class="span-label pdl"> Email Audit </span> 
    </div>  
        </mat-card>
  
        <div class="row mt-2">        
            <div class="col-md-6 mt-2">
              <mat-card class="matcard" >
                <p class="title-heading">Questionnaire Response</p>
                <div class="tablediv">
              <table class="table-cls">
                <tr><td><span style="color: #e40a67;font-size: 14px;"> Questions </span> </td><td> <span style=" font-size: 14px;color: #e40a67;"> Answer </span></td> </tr>
                <tr *ngFor="let v of QuestionAnswer"><td>{{v.question}} </td><td>{{v.answer}} </td> </tr>
                </table> 
                <br><br>
                  </div>
              </mat-card>
    <mat-card class="matcard mt-2" >
  <table style="width:100%">
    <tr>
      <td>
        <img class="ml-3" src="../assets/web_images/uearn_logo.svg" width="100px"/>
         </td>
    <td class="dateCSS"> 
      <span>10-Jun-2020 </span>
      </td></tr></table>
      <p class="p-mail">
  Dear AirIndia,<br>
  From Airindia they announced those booked on after march 15th they can cancel the tickets and 100%
  refund. But in my case, they told cancellation charges will apply. I ask is there any other alternate ways they
  told you can postpone your travel up to august. Now August month also came no hope commercial flights
  won’t be resumed until this year.
  I tried to reach customer support several times they are not giving proper response and cut my call or else
  attend put on hold.
  I just want my refund amount with the same payment gateway. <br>
  ---------------------
  -----------<br>
  Regards
  Ecommerce Helpdesk/MV
  
        </p>
    </mat-card>
            </div>          
                <div class="col-md-6 mt-1">
       
  <mat-card class="matcard mt-2">
      <p class="title-heading">Call Auditing score</p>
        <div class="tablediv" *ngFor="let a of CallAuditingscore;let i=index;">
      <p class="mt-1" style="color: #e40a67;margin-bottom: 3px;">{{i+1}}. {{a.label}} </p>
          <table  class="tableaudit">
  <tr *ngFor="let b of a.data;let j=index;"> 
    <td  style="color: gray;"> {{i+1}}.{{j+1}} {{b.title}} </td>
   <td style="position:absolute;right:20px"> 
     <select class="selectddl" [value]="b.select">
       <option value="yes">Yes</option>
        <option value="no">No</option>
     </select>
      </td> 
   </tr>
            </table>
      </div>
      <div  class="tablediv">
                <table style="width:100%;">
   <tr>
     <td colspan="2"> <p class="mt-2" style="color: #e40a67;"><b>Comments</b> </p> </td>
     </tr>
      <tr>
     <td colspan="2"> 
       <input type ="text" class="input-field wd"/>
        </td>
     </tr>
        <tr>
     <td> 
      <p class="mt-2" style="color: #184b89;"><b>Total Score: 5</b> </p> 
        </td>
          <td style="position:absolute;right:10px;margin-top:7px"> 
      <button class="submit-Btn">Submit</button>
        </td>
     </tr>
            </table>
      </div>
  </mat-card>
            </div>
        </div>
      
</div>
