import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
//import { SocketioService } from '../../socketio.service'
import { Router } from '@angular/router';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
//import * as CryptoJS from 'crypto-js';
//import {io} from 'socket.io-client/build/index';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import * as io from 'socket.io-client';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'underscore';
import { MessagingService } from '../../messaging.service';

@Component({
  selector: 'app-chat-agent',
  templateUrl: './chat-agent.component.html',
  styleUrls: ['./chat-agent.component.scss']
})
export class ChatAgentComponent implements OnInit {

  isNoInternetPopup: boolean = false;
  mandatoryFiled: boolean = false;
  socketConnection: boolean = false;
  messages: any = [];
  selectedActive: any = -1;
  username: any = '';
  role: any = '';
  IsVisible: boolean = true;
  _dataTabName: any;
  _dataToId: any;
  filterChannel: any;
  txtmsg: any;
  myUser: any;
  groupList: any[] = [];
  channels:any = "";
  selectedChannel: any = "";
  allData:any="";
  socket:any="";
  selectedFile:any;
  msgType: string = "TEXT";
  socketId:any="";
  socketStatusBeforeCheck:any="";
  uniqueAgents:any [] = [];
  widgetUser:any;
  userInterval:any;
  isTextComponent:boolean = false;
  searchmsgValue: any;

  isWhatsappComponent:boolean = false;
  _gplist:any=[];
  api_end_point:any;
  callStatus;
	constructor(private _service: MyserviceService, private API: ApiService, private router: Router, private http: HttpClient, protected _sanitizer: DomSanitizer, private messagingService: MessagingService) {
    this.myUser = this._service.get_loggedin_user();
    //this.socket = io('https://devchat.youearn.in',{"transports":["polling"]});
	//this.socket = io('https://devchat.contiinex.com',{"transports":["polling"]});
  }

  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
	  this.callStatus = this._service.getLastCallStatus();
	//   this.messagingService.requestPermission(this.myUser.id)
	  this.messagingService.receiveMessage();
	  this.messagingService.getMessage().subscribe(obj => {

		  if (obj != null) {
			//  console.log("recccccc2", obj.data.data)
			  var notifData = JSON.parse(obj.data.data);
			  if (notifData.title == 'Customer Page URL UPDATE'){
				  this.API.getWidgetUserByPhone('' + notifData.customer_number,this.api_end_point).subscribe((data: any) => {
					  let _response = data;
					  if (_response && _response.success) {

						  if (this.groupList[this.selectedActive].messages[0].phone == _response.success.phone) {
							  this.widgetUser = _response.success;
							  this.counter = 0;
						  }
					  }
					  else {

					  }
				  });
			  }
			  
		  }
		});
	  this._service.getCallStatus().subscribe(obj => {
	//	console.log("home-chat-plugin", obj);
		// this.callStatus = obj;
		// if(this.callStatus && this.callStatus.widgetUser){
		// 	if (this.groupList[this.selectedActive].messages[0].phone == this.callStatus.widgetUser.phone){
		// 		this.widgetUser = this.callStatus.widgetUser;
		// 		this.counter = 0;
		// 	}
			
		// }

	})
    // var interval_id = window.setInterval(()=>{}, 99999);
    // for (var i = 0; i < interval_id; i++){
    //   window.clearInterval(i);
    // }
  	
    this.groupList=[];
    this.subscribe();
    this.initSocket()
    if(window.location.pathname.includes('text')){
      this.isTextComponent = true;
    }
    if(window.location.pathname.includes('whatsapp')){
      this.isWhatsappComponent = true;
    }

	
    // this.setupSocketConnection("CUSTOMER_CHANNEL")
  }

  subscribe() {
    var _obj = {
      "username": this.myUser.name,
      "userid": this.myUser.id,
      "client": "WEB"
    };
    this.API.subscribe(_obj)
      .subscribe(
        (response: any) => {
          if (response && response.message === "Subscription Done") {
            this.channels = response.channels;
            this.allData = response;
            this.extractGroupList(response.channels)

          }
        });
  }

  extractGroupList(data: any) {
    
    // var uniqueAgents:any [] = [];
    for (let key in data) {
   //   console.log(key)
      if(key == 'CUSTOMER'){
        this.selectedChannel = this.channels[key];
      }
    }
      let value = data['CUSTOMER'];
      if(value.messages && value.messages.length){
        for(let msgKey in value.messages){
          var msg = value.messages[msgKey];
          const agentIndex = this.uniqueAgents.indexOf(msg.from_id);
          const agentIndex2 = this.uniqueAgents.indexOf(msg.to_id);
          
            if((msg["channel_type"] && msg["channel_type"] == "WHATSAPP") || msg.channel == "CUSTOMER_CHANNEL"){
            //  console.log(msg.message,msg.decrypt)
              if(msg.channel == "CUSTOMER_CHANNEL" && !msg.decrypt){
                msg.message = decodeURIComponent(escape(window.atob(msg.message)))
              }else{
                msg.message = msg.message;
              }
              
              
            }else{
                msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
            }
         // console.log(msg.message)
          // msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
          msg.decrypt = true;
          if(this.isTextComponent){
            if(msg.channel_type == 'SMS'){
              if(this.myUser.id != msg.from_id){
                this.appendMessageToGroup('CUSTOMER',msg,agentIndex)
              }else{
                this.appendMessageToGroup('CUSTOMER',msg,agentIndex2)
              }
            }
            
          }
          
          else if(this.isWhatsappComponent){
            if(msg.channel_type == 'WHATSAPP'){
              if(this.myUser.id != msg.from_id){
				  this.appendMessageToGroup('CUSTOMER', msg, agentIndex, msg[msg.to_id + "_seen"] ? false : true)
              }else{
				  this.appendMessageToGroup('CUSTOMER', msg, agentIndex2, msg[msg.to_id + "_seen"] ? false : true, false, msg.to_id)
              }
            }            
          }

          
          else{
			  if (this.myUser.id != msg.from_id && msg.channel_type != 'SMS' && msg.channel_type != 'WHATSAPP'){
				  this.appendMessageToGroup('CUSTOMER', msg, agentIndex, msg[msg.to_id + "_seen"] ? false : true)
			  } else if (msg.channel_type != 'SMS' && msg.channel_type != 'WHATSAPP'){
				  this.appendMessageToGroup('CUSTOMER', msg, agentIndex2, msg[msg.to_id + "_seen"] ? false : true)
            }
          }
          
          
        }
        this.sortByTime();
      }
    
    // console.log(this.uniqueAgents)
    // console.log(this.groupList)
    // console.log(this.selectedChannel.channel)
    this.setupSocketConnection(this.selectedChannel.channel);

  }

  initSocket(){
    
    this.socket.on('connect', () => {
      console.log(this.socket);
      this.socketId = this.socket.id;
      console.log("Connecting -- ",this.socket.id)
        this.socket.emit("SOCKET_CONNECTION", {
          userid: this.myUser.id,
          socketid: this.socketId
      });
      
    });
    
  //   this.socket.emit("SOCKET_CONNECTION", {
  //     userid: this.myUser.id,
  //     socketid: this.socket.id
  // });
    this.socket.on("TEST_CONNECTION", (msg: any) => {
      // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
      this.socketStatusBeforeCheck = msg.status;
      console.log(this.socketStatusBeforeCheck);
  });
    this.username = this.myUser.name;
    this.role =  this.myUser.role;
    
  }

  fetchUser(phone){
    // this.widgetUser = ""
    this.API.getWidgetUserByPhone(phone.includes('+') ? phone : '+'+phone,this.api_end_point).subscribe((data: any) => {
      let _response = data;
      if (_response.success) {
        if(!this.widgetUser){
          this.widgetUser = _response.success;
        }else if(_response.success.external_url != this.widgetUser.external_url || _response.success.state != this.widgetUser.state || _response.success.name != this.widgetUser.name){
          this.widgetUser.external_url = _response.success.external_url;
          this.widgetUser.state = _response.success.state;
          this.widgetUser.name = _response.success.name;
          this.widgetUser.message = _response.success.message;
          this.counter = 0;
        }
         
      }
      else{

      }
    });
  }

  counter:any=0;
  transformUrl(url: string){ 
    if(this.counter==0){
      this.counter++;
  //  console.log(this._sanitizer.bypassSecurityTrustResourceUrl(url))
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
  
  sendMessage_With_Enter(messageToSend:any){
    this.mandatoryFiled=false;
    if(this.txtmsg=="" || messageToSend==undefined){
      this.mandatoryFiled=true;
      return;
    }
   // console.log("You entered: ", messageToSend);
    let msg = {
      type: "CHAT_MESSAGE",
      message_type:this.msgType,
      channel: "CUSTOMER_CHANNEL",
      groupid:  this.myUser.groupid,
      from_id: this.myUser.id,
      to_id: this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length ? this.groupList[this.selectedActive].messages[0].from_id : this.myUser.groupid,
      user: this.myUser.name,
      phone: this.myUser.phone,
      session_id:"",
      // message: mesg //.trim()
      // message: $scope.encryptMessage($scope.userid, $scope.groupid, mesg)
  } as any;
  //debugger;
  if(this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length && this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length-1].session_id){
    msg["session_id"] = this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length-1].session_id;
  }
  if(this.groupList[this.selectedActive].messages[0].channel_type == "WHATSAPP"){
    msg["channel_type"] = "WHATSAPP";
  }
  if((this.groupList[this.selectedActive].messages[0].channel_type && this.groupList[this.selectedActive].messages[0].channel_type == "WHATSAPP") || msg.channel == "CUSTOMER_CHANNEL"){
      msg["message"] = btoa(unescape(encodeURIComponent(messageToSend)));
  }else{
      msg["message"] = this.encryptMessage(this.myUser.id, this.allData.groupid, messageToSend);
  }
  if(this.groupList[this.selectedActive].messages[0].channel_type == 'SMS'){
    msg['channel_type'] = 'SMS';
  }
    // msg["message"] = this.encryptMessage(this.myUser.id, this.allData.groupid, messageToSend);

    this.API.message(msg)
      .subscribe(
        (response: any) => {
         // console.log(response);
          this.txtmsg = "";
      //     var obj = {} as any;
      //     obj.message = this.txtmsg;
      //     obj.date = new Date();
      //     obj.from_id = this.myUser.id;
      //     obj.user = this.myUser.name;
      //     obj.message_type = this.msgType;
      //     obj.session_id = msg.session_id;
      //     obj.message_time = (new Date()).toLocaleTimeString([], {
      //       hour: '2-digit',
      //       minute: '2-digit'
      //     });
      //     this.groupList[this.selectedActive].last_message = obj.message_type == 'TEXT' ? obj.message : obj.message_type == 'AUDIO'?"AUDIO":"IMAGE";
      // this.groupList[this.selectedActive].last_message_time = (new Date(obj.date)).toLocaleTimeString([], {
      //   hour: '2-digit',
      //   minute: '2-digit'
      // });
      // this.groupList[this.selectedActive].lmt = this.groupList[this.selectedActive].last_message_time.replace(':','');
      //     this.groupList[this.selectedActive].messages.push(obj)
      //     this.txtmsg = "";
      //     this.scrollToBottom();
      //     this.mandatoryFiled=false;
      //     if (!msg[this.myUser.id + "_delivered"]) {
      //       console.log("delivering")
      //       this.sendMessageAcknowledgment(msg);
      //     }
        });
  }

  setupSocketConnection(channel: any) {
    
    // this.socket.emit('my message', 'Hello there from Angular.');
   // console.log(channel);
    
    this.socket.on(channel, (msg: any) => {
     // console.log(msg);
      
        if((msg["channel_type"] && msg["channel_type"] == "WHATSAPP") || msg.channel == "CUSTOMER_CHANNEL"){
          if(msg.channel == "CUSTOMER_CHANNEL"){
            msg.message = decodeURIComponent(escape(window.atob(msg.message)));
          }else{
            msg.message = msg.message;
          }
          
        }else{
          msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
        }
      
      // msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
      msg.decrypt = true;
      var agentIndex = -1;
    //  console.log(agentIndex)
     

      if(msg.from_id != this.myUser.id){
        agentIndex = this.groupList.findIndex(group=>group.id == msg.from_id);
        if(this.isTextComponent){
          if(msg.channel_type == 'SMS'){
            this.appendMessageToGroup("TL",msg,agentIndex, true, true)
          }
        }
        
        else if(this.isWhatsappComponent){
          if(msg.channel_type == 'WHATSAPP'){
            this.appendMessageToGroup("TL",msg,agentIndex, true, true)
          }
        }
        
        
        else if(msg.channel_type != 'SMS'){
          this.appendMessageToGroup("TL",msg,agentIndex, true, true)
        }
        
      }else{
        agentIndex = this.groupList.findIndex(group=>group.id == msg.to_id);
        if(this.isTextComponent){
          if(msg.channel_type == 'SMS' && agentIndex == -1){
            this.appendMessageToGroup("TL",msg,agentIndex, true, true)
          }
        }
        else if(this.isWhatsappComponent){
          if(msg.channel_type == 'WHATSAPP'){
            this.appendMessageToGroup("TL",msg,agentIndex, true, true)
          }
        }
        
        
        else if(msg.channel_type != 'SMS'){
          this.appendMessageToGroup("TL",msg,agentIndex, true, true)
        }
        
      }
      this.sortByTime();
      // if(this.selectedActive == 0){
      //   this.groupList[this.selectedActive].messages.push(msg);
      // }
      
      setTimeout(()=>{
        this.scrollToBottom();
      },500)
    });
  }

  appendMessageToGroup(key: any,msg: any,agentIndex: any, checkUnread: any="", fromSocket: any = false, toConsiderId: any = ''){
    if(agentIndex == -1 ){
	  this.uniqueAgents.push(toConsiderId ? toConsiderId : msg.from_id);
     // console.log("inserting")
      var group = {
        key,
        id: msg.from_id,
        tab_name:  msg.user == 'Server' ? msg.to_id : msg.user,
        last_message: '',
        last_message_time: '11:11',
        lmt: '1111',
        unread_messages: 0,
        messages: []
      } as any;
      
      group.last_message = msg.message_type == 'TEXT' ? msg.message : msg.message_type == 'AUDIO'?"AUDIO":"IMAGE";
      group.last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
      group.lmt = new Date(msg.date).getTime();
      if(checkUnread){
        if(this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id){
        }else{
          if(msg.from_id != this.myUser.id){
            group.unread_messages = group.unread_messages + 1;
          }
         
        }
      }
      
      group.messages.push(msg);
	  group.show = true
      this.groupList.push(group);
      this._gplist= this.groupList;
    }else {
      this.groupList[agentIndex].last_message = msg.message_type == 'TEXT' ? msg.message : msg.message_type == 'AUDIO'?"AUDIO":"IMAGE";
      this.groupList[agentIndex].last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
      this.groupList[agentIndex].lmt = new Date(msg.date).getTime();
      if(checkUnread){
        if(this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id){
        }else{
          if(msg.from_id != this.myUser.id){
            this.groupList[agentIndex].unread_messages = this.groupList[agentIndex].unread_messages + 1;
          }
          
        }
      }
      
      this.groupList[agentIndex].messages.push(msg);
    }
    if (!msg[this.myUser.id + "_seen"] && this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id) {
     // console.log("confirming")
      this.sendMessageSeenConfirmation(msg);
    }
    if (!msg[this.myUser.id + "_delivered"]) {
     // console.log("delivering")
      this.sendMessageAcknowledgment(msg);
    }

    
    

  }

  sortByTime = () => {
    //  this.groupList = this.groupList.sort(function(a,b){return b.lmt - a.lmt});
    var prevSelected:any;
    if(this.selectedActive != -1){
      prevSelected = this.groupList[this.selectedActive];
    }
    
   // console.log(this.groupList)
     this.groupList = _.sortBy(this.groupList, function(o) { return -o.lmt; })
     ;
     if(this.selectedActive != -1){
      this.selectedActive = _.findIndex(this.groupList , function(obj) { return obj.id == prevSelected.id })
    }
    
    // console.log(this.groupList.sort(function(a,b){console.log(a); return b.lmt - a.lmt}));
  }

  startSession(){
    let msg = {
      type: "SESSION_STARTS",
      message_type:this.msgType,
      channel: "CUSTOMER_CHANNEL",
      groupid:  this.myUser.groupid,
      from_id: this.myUser.id,
      to_id: this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length ? this.groupList[this.selectedActive].messages[0].from_id : this.myUser.groupid,
      user: this.myUser.name,
      username: this.myUser.name,
      phone: this.myUser.phone,
      session_id:"",
      message:"",
  } as any;
    this.API.message(msg)
      .subscribe(
        (response: any) => {
          

        });
  }

  endSession(){
    let msg = {
      type: "SESSION_ENDS",
      message_type:this.msgType,
      channel: "CUSTOMER_CHANNEL",
      groupid:  this.myUser.groupid,
      from_id: this.myUser.id,
      to_id: this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length ? this.groupList[this.selectedActive].messages[0].from_id : this.myUser.groupid,
      user: this.myUser.name,
      username: this.myUser.name,
      phone: this.myUser.phone,
      session_id:"",
      message:"",
  } as any;
  if(this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length && this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length-1].session_id){
    msg["session_id"] = this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length-1].session_id;
  }
    this.API.message(msg)
      .subscribe(
        (response: any) => {
          alert("Session ended")

        });
  }

  sendMessageSeenConfirmation(message: any){
    this.socket.emit("MESSAGE_SEEN", {
        id: message.id,
        session_id: message.session_id,
        channel: message.channel,
        userid: this.myUser.id
    });
}

sendMessageAcknowledgment(message: any){
  this.socket.emit("MESSAGE_DELIVERY", {
      id: message.id,
      session_id: message.session_id,
      channel: message.channel,
      userid: this.myUser.id
  });
}

  generateAESKey(value: string = "", type: string = "") {
    // return CryptoJS.enc.Base64.parse("SMB_CHAT_SERVICE_"+inputValue);
    if (type == "KEY") {
      return CryptoJS.enc.Base64.parse('u/Gu5posvwDsXUnV5Zaq4g==');
    } else {
      return CryptoJS.enc.Base64.parse('5D9r9ZVzEYYgha93/aUK2w==');
    }
  }

  encryptMessage(senderId: any, groupId: any, plainText: any){
    var key = this.generateAESKey(senderId, "KEY");
        var iv = this.generateAESKey(groupId, "IV");
        let encryptedMessage = CryptoJS.AES.encrypt(
            plainText,
            key, {
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
                iv: iv
            }
        );
        let cipherText = encryptedMessage.toString();
        return cipherText;
  }
  decryptMessage(senderId: any, groupId: any, cipherText: string){
    var key = this.generateAESKey(senderId, "KEY");
        var iv = this.generateAESKey(groupId, "IV");
        var decodeCipherText = CryptoJS.enc.Base64.parse(cipherText);
        // console.log("decodeCipherText : ", decodeCipherText);
        let decryptedMessage = CryptoJS.AES.decrypt({
                ciphertext: decodeCipherText
            } as any,
            key, {
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
                iv: iv
            }
        );
        // console.log("decryptedMessage : ", decryptedMessage);
        let plainText = CryptoJS.enc.Utf8.stringify(decryptedMessage);
        // console.log("plainText : ", plainText);
        return plainText;
  }

  tabkeyMenu(key: any, index: any) {
    // clearInterval(this.userInterval);
    // var interval_id = window.setInterval(()=>{}, 99999);
    // for (var i = 0; i < interval_id; i++){
    //   window.clearInterval(i);
    // }
    // this.widgetUser = ""
    this.counter = 0;
    this.selectedActive = index;
    this._dataTabName = this.groupList[index].tab_name;
    this.groupList[index].unread_messages = 0;
  //  console.log(this.groupList[index])
	this.fetchUser(this.groupList[index].messages[0].phone)
	  for (let msg of this.groupList[this.selectedActive].messages) {
		  if (!msg[this.myUser.id + "_seen"] && this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id) {
			//  console.log("confirming")
			  this.sendMessageSeenConfirmation(msg);
		  }
	  }
	  setTimeout(() => {
		  this.scrollToBottom();
	  }, 500)
    // this.userInterval = setInterval(()=>{
    //   this.fetchUser(this.groupList[index].messages[0].phone)
    // },3000)
    
    // this.startSession();
  }
 

  scrollToBottom() {    
    let messageBody:any = document.querySelector('#messageBody');
    messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
}
currentImageClick(index:any){     
  var img:any = document.getElementById("imgId"+index);
  var modal:any = document.getElementById("myModal");
  var modalImg:any = document.getElementById("img01");   
  modal.style.display = "block";
  modalImg.src = img.src;
}

closeLarge() { 
var modal:any = document.getElementById("myModal");
modal.style.display = "none";
}
uploadFile(event:any){
  
}
onTextMessage(){
  if(this.txtmsg.length>0){
this.mandatoryFiled=false;
  }
  else{
   // this.mandatoryFiled=true;
  }
}
searchFromArray(val:any){
 // this.groupList=this._gplist;
  let matches = [];
    for (let i = 0; i < this.groupList.length; i++) {
		if (this.groupList[i].tab_name.toLowerCase().match(val.toLowerCase())) {
        // matches.push(this.groupList[i]);
			this.groupList[i].show = true
      }else{
			this.groupList[i].show = false
	  }
    }
    if(matches.length>0){
    //   this.groupList=matches;
    }
    else{
		
    //   this.groupList=[];
    //   this.groupList=this._gplist;
    }
	if(val == ''){
		for (let i = 0; i < this.groupList.length; i++) {
			this.groupList[i].show = true;
		}
	}
  };

  colWd:any=6;
  expandOpen(){
this.colWd=12;
  }
  expandClose(){
    this.colWd=6;
  }
}
