<h3 class="sBiz-title">SmarterBiz</h3><br>
<div class="text-center">
    <p  class="hdtext"><span> On-Boarding Process</span></p>
    <p class="LText">Boost your operational efficiency with realtime<br>
        customer journey orchestration across channels.</p>
   
</div>

<div>
<mat-card class="m-card">
    <div class="row bgText">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            UEARN - METRICS
        </div>
    </div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">PCM earning (INR)</span>
        <p style="font-size: 10px;word-spacing: normal;word-break: break-all;">Attach calculation file</p>
     
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" />
          </div>
    </div>
</div>

<br><br>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
       <button class="onboard-submit"  (click)="onNextBtn()">NEXT</button>
    </div>
</div>
<br><br>
</mat-card>
</div> 
