import { Injectable } from '@angular/core';
import { BehaviorSubject, ObjectUnsubscribedError } from 'rxjs';

import { ApiService } from '../api.service';


interface getIssueObj{
	start_date: string,
	end_date: string
}

@Injectable({
  providedIn: 'root'
})
export class TechService {

	private TECH_ISSUES = new BehaviorSubject([]);
	public tech_issues = this.TECH_ISSUES.asObservable();

	constructor(
		private API: ApiService
	) { }
	
	getTechIssues(obj: getIssueObj) {
		let promise = new Promise((resolve, reject) => {
			this.API.getIssues(obj).subscribe(
				(res: any) => {
					if (res.success) {
						this.TECH_ISSUES.next(res.success);
						resolve()
					} else {
						let d:any = {};
						this.TECH_ISSUES.next(d);
						reject(res.errors)
					}
				}, (err) => {
					reject(err)
				}
			)
		})
		return promise;
	}

	updateTicket(obj) {
		let promise = new Promise((resolve, reject) => {
			this.API.updateTicket(obj).subscribe(
				(res:any) => {
					if (res.success) {
						resolve(res.success)
					} else {
						reject(res.errors)
					}
				}, (err) => {
					reject(err)
				}
			)
		})
		return promise;
	}
}
