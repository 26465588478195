<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<div class="pt-1">


<div class="pdt" style="widthh:100%;">
    <!-- <div class="row TA-voice">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <h2 class="d-head">VOICE PROCESS <img src="../assets/underline.svg"
                class="linebelow"> </h2>
            </div>
    </div> -->
<div class="row">
    <div class="col-md-3 col-sm-12 col-xs-12 mbtm">
<mat-card style="box-shadow: 0 2px 15px #d2d5d95e;">
<div class="dHd dash-production vk-fntSW" >
    <!-- PRODUCTION  -->
    Production
</div>
<p class="ptop">
<span class="TR-desc pro">{{_productionActive}}</span>
<span class="TR-Agents">Active Agents</span>
<span><hr></span>
<span class="TR-desc TRGray">{{_productionInActive}}</span>
<span class="TR-Agents">Inactive Agents</span>
</p>
</mat-card>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12 mbtm">
<mat-card style="box-shadow: 0 2px 15px #d2d5d95e;">
    <div class="dHd dash-ojtCertification vk-fntSW" >
        <!-- OJT + CERTIFICATION -->
        OJT + Certification
    </div>
    <p class="ptop">
        <span class="TR-desc ojtcert">{{_ojtActive}}</span>
        <span class="TR-Agents">Active Agents</span>
        <span><hr></span>
        <span class="TR-desc TRGray">{{_ojtInActive}}</span>
        <span class="TR-Agents">Inactive Agents</span>
        </p>
</mat-card>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12 mbtm">
        <mat-card style="box-shadow: 0 2px 15px #d2d5d95e;">
    <div class="dHd dash-training vk-fntSW">
        <!-- TRAINING -->
        Training
    </div>
    <p class="ptop">
        <span class="TR-desc tra">{{_trainingActive}}</span>
        <span class="TR-Agents">Active Agents</span>
        <span><hr></span>
        <span class="TR-desc tra">{{_trainingBatches}}</span>
        <span class="TR-Agents">Batches</span>
        </p>
        </mat-card>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12 mbtm">
        <mat-card style="box-shadow: 0 2px 15px #d2d5d95e;">
    <div class="dHd dash-totaltrainers vk-fntSW">
        <!-- TOTAL TRAINERS -->
        Total Trainers
    </div>
    <p class="ptop">
        <span class="TR-desc totTra">{{_activeTrainers}}</span>
        <span class="TR-Agents">Active Trainers</span>
        <span><hr></span>
        <span class="TR-desc totTra">{{_availTrainers}}</span>
        <span class="TR-Agents">Available Trainers</span>
        </p>
        </mat-card>
    </div>
</div>

<div class="row pdt">
    <div class="col-md-6 col-sm-12 col-xs-12">
     <mat-card style="height: 100%;box-shadow: 0 2px 15px #d2d5d95e;">
         <h2 class="d-head vk-fntSW" style="color: #000000;">Batches On Live <img src="../assets/underline.svg"
            class="linebelow"> </h2>
            <app-dashboard-batchonlive-table></app-dashboard-batchonlive-table>
    </mat-card>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12">
          <mat-card style="box-shadow: 0 2px 15px #d2d5d95e;">
            <h2 class="d-head vk-fntSW" style="color: #000000;">Batch Overview [ Active Agents]<img src="../assets/underline.svg"
                class="linebelow"> </h2> 
               <table width="100%">
                   <tr>
                       <td>
                        <div style="min-width: 300px; height: 300px; max-width: 300px;" [chart]="donutChartrecord"></div>
                   </td>
                   <td>
                    <div>
                        <p class="p-donut"><span> <i class="fa fa-circle" style="color: #E0DAC2;padding-right: 10px;" aria-hidden="true"></i> </span><span>Production</span></p>
                        <p class="p-donut"><span> <i class="fa fa-circle" style="color: #F6C054;padding-right: 10px;" aria-hidden="true"></i></span><span>OJT + Certification</span></p>
                        <p class="p-donut"><span> <i class="fa fa-circle" style="color: #A9BA45;padding-right: 10px;" aria-hidden="true"></i></span><span>Training</span></p>
                        
                                    </div>
                </td>
                </tr>
               </table>
         <br>
            </mat-card>
            </div>
</div>
<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
     <mat-card style="box-shadow: 0 2px 15px #d2d5d95e;">
         <h2 class="d-head vk-fntSW" style="color: #000000;">Trainer Availability </h2>
         <app-dashboard-traineravailibity-table></app-dashboard-traineravailibity-table>
    </mat-card>
    </div>
  
</div>
</div>
<br>
<br>
</div>