<div style="margin-left: 5%;margin-right: 2%;">
  <div class="row">
      <div class="col-md-12 col-sm-7 col-xs-12">        
          <h2 class="d-head"> {{profileMode}}</h2>
          <h4 *ngIf="showHidePassingMessage" [ngStyle]="{'color':_color}" style="font-weight: bold;font-size: 20px;">{{passingMessage}}</h4>
        </div>
  </div>
  <form [formGroup]="createdForm">
      <div class="row pdt" *ngIf="this.viewAuditFormDetails && !this.viewAuditFormDetails.upload">

          <div  *ngIf="this.viewAuditFormDetails && this.viewAuditFormDetails.upload" class="col-md-12 col-sm-12 col-xs-12" >
            <mat-form-field class="wdh" >
                <input matInput placeholder="Audit-client_id" formControlName="audit_client_id" (keypress)="omit_special_char($event)" >
            </mat-form-field>
        </div>

        <div  *ngIf="this.viewAuditFormDetails && !this.viewAuditFormDetails.upload" class="col-md-12 col-sm-12 col-xs-12" >
          <mat-form-field class="wdh">
              <input matInput placeholder="Form name" formControlName="audit_form_name" (keypress)="omit_special_char($event)">
          </mat-form-field>
        </div>
        <!-- <div  *ngIf="this.viewAuditFormDetails && !this.viewAuditFormDetails.upload" class="col-md-12 col-sm-12 col-xs-12" >
          <mat-form-field class="wdh">
              <input matInput placeholder="CX-Touch-Point" formControlName="cx_touch_point" (keypress)="omit_special_char($event)">
          </mat-form-field>
        </div>-->
        <div  *ngIf="this.viewAuditFormDetails && !this.viewAuditFormDetails.upload" class="col-md-12 col-sm-12 col-xs-12" >
          <mat-form-field class="wdh">
              <input matInput placeholder="Normalized score" formControlName="normalized_score" maxlength="4" (keypress)="only_number($event)">
          </mat-form-field>
        </div> 

        <!--<div  *ngIf="this.viewAuditFormDetails && !this.viewAuditFormDetails.upload" class="col-md-12 col-sm-12 col-xs-12" >
          <mat-form-field class="wdh">
              <input matInput placeholder="Audit-form-object" formControlName="audit_form_object" >
          </mat-form-field>
        </div>-->

        <div *ngIf="this.viewAuditFormDetails && !this.viewAuditFormDetails.upload" class="col-md-12 col-sm-12 col-xs-12">
            <span class="dnldbtn">
                <button mat-button (click)="download_file()">Download Excel Template
                &nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
                </button>
                </span>
        </div>

        <div>
            <!-- <p style="color: rgb(7, 7, 6);font-weight: bold;font-size: 14px;padding-top: 10px;"><span>Data</span><span> (only supports xlsx file)</span></p> -->
               <!-- <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)"/> -->
               <!-- <label for="file">Upload</label> -->
               <div class="upload-btn-wrapper" style="margin-left: 15px;">
                  <button class="btnbrowse">Browse</button>
               <input type="file" #fileUpload class="filecs" id="fileID" formControlName="file" accept=".xlsx" (change)="changeFile($event)"/>
               <span style="color: #bababa;padding-left: 10px;">{{currentFileobj.name}}</span>
                </div>
        </div>

      </div>
      
      <div class="row pdt" *ngIf="this.viewAuditFormDetails && !this.viewAuditFormDetails.upload">      
              <div class="col-md-12 col-sm-12 col-xs-12 rightside">
                  <button mat-button  (click)="closeDialog('')">Cancel</button>&nbsp;&nbsp;&nbsp;
                  <span  *ngIf="!showHideProfileStatus" >
                      <button mat-button  (click)="clearField()">Clear</button>&nbsp;&nbsp;&nbsp;
                  </span>
                  <span *ngIf="showHideProfileStatus">
                  <button mat-button (click)="deleteAuditFrom()">Delete</button>&nbsp;&nbsp;&nbsp;
              </span>
                  <button mat-button (click)="saveButton(createdForm)" >Save</button><!--[disabled]="!createdForm.valid"-->
              </div>
          
      </div>
      
      </form>
    </div>
    <div id="cover-spin" *ngIf="currentLoader"></div>