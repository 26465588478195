import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as Excel from 'exceljs';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyserviceService } from '../../../myservice.service';
import { ServicesService } from '../../services.service';
import { ApiService } from '../../../api.service';

import { Utils } from '../../../utils';


@Component({
	selector: 'app-create-dialog',
	templateUrl: './create-dialog.component.html',
	styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent implements OnInit {
	utils = new Utils();
	showLoader = false;
	myUser: any;
	assessmentForm: FormGroup;
	currentFileobj: any = {};
	assessmentUpload_msg: string = '';
	assessmentType: string = 'hiring';
	default: string = '0';
	editData: any;
	showHideMessageView: any = "Assessment uploaded successfully";
	msgColor: any = "";
	showHideMessage: boolean = false;
	batchOptionList: any = [];
	disableSubmit: boolean = false;
	selectedBatchForAssessment;
	_color: string = "green";
	isHidden: boolean = true;
	api_end_point:any;
	@ViewChild('fileUpload')
	fileUploadInput: ElementRef;
	constructor(private API: ApiService,
		public dialogRef: MatDialogRef<CreateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public viewData,
		private service: MyserviceService,
		private dashService: ServicesService,
		private formbuilder: FormBuilder,
		private _snackBar: MatSnackBar,
		private myservice: MyserviceService) {
		//console.log(viewData);
		this.editData = viewData;
		this.assessmentForm = this.formbuilder.group({
			"name": ['', Validators.required],
			"passingScore": ['', Validators.required],
			"totalScore": ['', Validators.required],
			"timing": ['', Validators.required],
			"default": ['', Validators.required],
			"fileSource": [''],
			"file": [''],
			"selectedBatchForAssessment": [''],
		})
	}

	ngOnInit(): void {
		this.api_end_point = this.myservice.get_API_End_Point();
		if (window.location.href.includes('training')) {
			this.assessmentType = "training";
			this.getCertificationBatch();
		}
		if (this.editData) {
			// this.assessmentForm.controls.category.setValue(this.editData.data.type);
			//console.log(this.editData);
			this.isHidden = false;
			this.assessmentForm.controls.name.setValue(this.editData.data.name);
			this.assessmentForm.controls.passingScore.setValue('' + this.editData.data.passing_score);
			this.assessmentForm.controls.totalScore.setValue('' + this.editData.data.total_score);
			this.assessmentForm.controls.timing.setValue('' + this.editData.data.timing);
			this.assessmentForm.controls.default.setValue('' + this.editData.data.latest);

			if (this.editData.data.batch) {
				this.selectedBatchForAssessment = this.editData.data.batch.split(",").map(function (item) {
					return parseInt(item, 10);
				});
				this.assessmentForm.controls.selectedBatchForAssessment.setValue(this.selectedBatchForAssessment);
			}
			//console.log(this.selectedBatchForAssessment)
			this.default = '' + this.editData.data.latest
		}
		this.myUser = this.service.get_loggedin_user();
	}

	getCertificationBatch() {
		let obj = {
			"for_group": 2053,
			"for_certificate": true,
			"training_type": 'Regular'
		}
		this.API.getBatchList("", "", "Regular",this.api_end_point).subscribe((data: any) => {
			let _response = data.success;
			if (_response) {
				this.batchOptionList = _response;
			}
		});
	}

	get _form() { return this.assessmentForm.controls }

	changeBatch(value) {
	//	console.log(value);
		this.selectedBatchForAssessment = value;
	}

	download_file() {
		this.API.getAssessmentTemplate(this.api_end_point).subscribe(
			(res: HttpResponse<any>) => {
				let content_type = res.headers.get("content-type");
				let file_data = res.headers.get("content-disposition");
				let p = file_data.split(';')[1];
				let file_name = p.slice(p.indexOf('"') + 1, p.length - 1);
				let body = res.body;
				let blob: any = new Blob([body], { type: content_type });
				let url = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.download = file_name;
				anchor.href = url;
				// debugger;
				anchor.click();
			});
	}

	changeFile(event) {
		this.currentFileobj = {};
		this.assessmentUpload_msg = "";
		let filePath = event.target.files[0].name;
		let allowedExtensions = /(\.xlsx)$/i;
		if (!allowedExtensions.exec(filePath)) {
			//alert('Please upload file having extensions .pdf/.doc/.docx/.xls/.xlsx only.');
			this.assessmentUpload_msg = "Please upload file having extensions .xlsx only.";
			this._color = "red";
			setTimeout(() => {
				this.assessmentUpload_msg = "";
				this._color = "green"
			}, 3000)
			return false;
		}

		const file = event.target.files[0]
		const reader = new FileReader()
		const workbook = new Excel.Workbook();
		var rowCount = 0;
		reader.readAsArrayBuffer(file)
		reader.onload = () => {
			const buffer: any = reader.result;
			workbook.xlsx.load(buffer).then(workbook => {
				//console.log(workbook, 'workbook instance')

				workbook.eachSheet((sheet, id) => {

					sheet.eachRow((row, rowIndex) => {
						rowCount = rowCount + 1;
						//console.log(row.values, rowIndex)
					})
				})
				if (rowCount < 2) {
					this.currentFileobj = {};
					this.assessmentUpload_msg = "Data empty for uploaded file";
					this._color = "red";
					this.fileUploadInput.nativeElement.value = "";
					this.assessmentForm.controls.fileSource.setValue(null);
					this.assessmentForm.controls.file.setValue(null);
					setTimeout(() => {
						this.assessmentUpload_msg = "";
						this._color = "green"
					}, 3000);

				} else {
					if (event.target.files && event.target.files.length) {
						//console.log(event.target.files);
						this.currentFileobj = event.target.files[0];
						const [upload_file] = event.target.files;
						this.assessmentForm.patchValue({
							fileSource: upload_file
						});
					}
				}

			})
		}

	}

	delete_assessment() {
		//console.log("---", this.editData.data);
		let assessment = this.editData.data;
		//console.log("--delete--",assessment);
		this.API.assessmentDelete(assessment,this.api_end_point).subscribe(
			(res: any) => {

				if (res.success) {
					this._snackBar.open("Assessment Deleted", "Close", {
						duration: 3000
					  });
					setTimeout(() => {
						this.showHideMessage = false;
						this.closePopup('true');
						this.disableSubmit = false;
					}, 3000)
				}
				if (res.error) {
					this._snackBar.open(res.errors, "Close", {
						duration: 3000
					  });
					setTimeout(() => {
						this.showHideMessage = false;
						this.closePopup('true');
						this.disableSubmit = false;
					}, 3000)
				}
			})

	}

	submit_Camapign(assessmentForm: FormGroup) {
		this.showHideMessageView = "";
		this.msgColor = "";
		this.showHideMessage = false;
		this.disableSubmit = true;
		let formData = new FormData();
		let assessment = assessmentForm;
		//debugger;
		let passingScore = assessmentForm.controls.passingScore.value;
		let totalScore = assessmentForm.controls.totalScore.value;
		
		if (Number(passingScore) > Number(totalScore)) {
			this.assessmentForm.controls['passingScore'].setValue('');
			this.showHideMessageView="Passing Score should be less than Total Score.";
			this.msgColor="red";
			this.showHideMessage=true;
			setTimeout(()=>{
				this.showHideMessage=false;
				this.disableSubmit = false;
			  },4000)
			return;
		} else {

			let timing = assessment.controls.timing.value;
			let name = assessment.controls.name.value;
			let type = this.assessmentType;
			let latest = assessment.get('default').value;

			let bulk_file = assessment.get('fileSource').value;


			// formData.append('production_group', this.myUser.groupid);
			formData.append('type', type);
			formData.append('passingScore', passingScore);
			formData.append('totalScore', totalScore);
			formData.append('timing', timing);
			formData.append('name', name);
			formData.append('latest', latest);
			if (this.selectedBatchForAssessment) {
				formData.append('batch_for_assessment', this.selectedBatchForAssessment.toString());
			}
			if (bulk_file) {
				formData.append('leads', bulk_file, bulk_file.name);
			}
			var query = `?passingScore=${passingScore}&totalScore=${totalScore}&timing=${timing}&name=${name}&type=${type}&latest=${latest}`
			if (this.editData) {
				if (this.editData.data.id) {
					query = query + `&id=${this.editData.data.id}`
				}
				this.API.assessmentUpdate(formData, query, this.myUser.groupid,this.api_end_point).subscribe(
					(res: any) => {

						if (res.success) {

							//alert('Assessment updated successfully');
							this.showHideMessageView = "Assessment updated successfully";
							this.msgColor = "green";
							this.showHideMessage = true;
							setTimeout(() => {
								this.showHideMessage = false;
								this.closePopup('true');
								this.disableSubmit = false;
							}, 6000)
						} else {
							this._snackBar.open(res.errors, "Close", {
								duration: 3000
							  });
							this.disableSubmit = false;
						}
						this.showLoader = false;
					}, (err) => {
						// debugger;
						this.disableSubmit = false;
					//	console.log(err)
					})

			} else {

				if (assessment.get('file').value == "") {
					this.showHideMessageView = "Please attach file*";
					this.msgColor = "red";
					this.showHideMessage = true;
					this.disableSubmit = false;
					return;
				}
				// console.log("--1--" + assessment.get('fileSource').value.name);
				// console.log("--2--" + assessment.get('fileSource').value);

				let _fileDetails = assessment.get('fileSource').value;
				let _fileName = assessment.get('fileSource').value.name;
				let fileInput = document.getElementById('fileID') as HTMLInputElement;
				let filePath = assessment.get('fileSource').value.name;

				//console.log(filePath);
				let allowedExtensions = /(\.xlsx)$/i;
				//console.log(allowedExtensions.exec(filePath));
				if (!allowedExtensions.exec(filePath)) {
					this.showHideMessageView = 'Please upload .xlsx file extension only';
					this.msgColor = "red";
					this.showHideMessage = true;
					this.disableSubmit = false;
					return;
				}
				this.API.assessmentUploadAPI(formData, query, this.myUser.groupid,this.api_end_point).subscribe(
					(res: any) => {

						if (res.success) {
							this.showHideMessageView = "Assessment uploaded successfully";
							this.msgColor = "green";
							this.showHideMessage = true;
							setTimeout(() => {
								this.showHideMessage = false;
								this.closePopup('true');
								this.disableSubmit = false;
							}, 6000)
							//alert('Assessment uploaded successfully');
						} else {
							this._snackBar.open(res.errors, "Close", {
								duration: 3000
							  });
							this.disableSubmit = false;
						}
						this.showLoader = false;
					}, (err) => {
						
						// debugger;
						this.disableSubmit = false;
					//	console.log(err)
					})
			}
		}

	}

	closePopup(refresh) {
		this.assessmentForm.reset();
		this.dialogRef.close(refresh);
	}
	omit_special_char(event) {
		let k;
		k = event.charCode;  // k = event.keyCode; (Both can be used)
		if (k == 95) {
			return true;
		}
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
	}
	onlyNumberValidation(event) {
		return event.charCode >= 48 && event.charCode <= 57;
	}
}
