<body>
    <app-home-header></app-home-header>
    <div>
            <div class="row" style="margin: 8% 8% 2% auto;">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <h2 class="forgot-title">Forgot Password </h2>
                    <div class="under_line"></div><br>
                        <div class="input-label-group">
                            <label>Email *</label>
                            <input type="email" class="" placeholder="Enter Email Id*" [(ngModel)]="email" >                        
                        </div>
                        <div style="margin-top: 20px;" *ngIf="errorMsg != '' || msg != ''">
                            <small style="color:red;">{{errorMsg}}</small>
                        </div>
                        <div style="margin-top: 20px;">
                            <button class="loginbtn" type="submit" (click)="forgotSubmit()">Submit</button> 
                        </div>
                </div>
                <div class="col-md-6"></div>
            </div>
            <div class="row" >
                <div class="col-md-2"></div>
                <div class="col-md-10" *ngIf="msg != '' && errorMsg == ''">
                    <p style="font-size: 14px;margin-bottom: 2px;">
                        To reset your password, click the link sent to your email Id: 
        <span style="color:#7030a0"> {{email}} </span></p>
        <p  style="font-size: 14px;margin-bottom: 2px;">
        The password recovery link expires in two hours after password recovery request initiation.</p>                   
                    </div>
            </div>
    </div>  
</body>
<div id="cover-spin" *ngIf="showLoader"></div>
