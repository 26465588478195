
<!-- <a [routerLink]="['/signin']" routerLinkActive class="signcs">Sign</a> -->
<!--
    Created by Omkar 01 June 2020
-->
<app-home-header></app-home-header>
<!-- <section id="mobile_navbar">
    <div class="row">
        <div id="brand_img">
            <a href="/">
                <img src="/assets/uearn_logo.svg" alt="Uearn Money">
            </a>
        </div>
        <div id="playstore_img">
            <a href="https://play.google.com/store/apps/details?id=smarter.uearn.money&hl=en" target="_blank">
                <img src="/assets/mob_images/google-play.svg" alt="Download">
            </a>
        </div>
    </div>
</section>
<section id="web_navbar" >
    <div class="web_container">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <a href="/" class="web_logo_img">
                    <img src="/assets/uearn_logo.svg" alt="Uearn Money">
                </a>
            </div>
           
            <div class="col-sm-12 col-md-3 col-lg-3" style="color: white;font-size: 14px;position: relative; top: 25px;">
              <span style="margin-right: 6px;"><i class="fa fa-phone-square" aria-hidden="true"></i></span> 
              <span> +91 63669 54899</span>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 text-right">
                <a [routerLink]="['/signin']" routerLinkActive class="web_signin">Enterprise Login</a>
                <a href="https://play.google.com/store/apps/details?id=smarter.uearn.money&hl=en" target="_blank" class="top_download">
                    <img src="/assets/mob_images/google-play.svg" alt="Download">
                </a>
            </div>
        </div>
    </div>
</section> -->
<div *ngIf="showHideJoinus">
    <app-joinus-business></app-joinus-business>
</div>




<div *ngIf="!showHideJoinus">

<section id="mobile_banner">
    <div id="banner_div">
        <div id="banner_img"></div>
        <div id="banner_cotent">
            <h2>WORK FROM ANYWHERE, ANYTIME!</h2>
            <p>Use your extra time at home or outside to support some of India's best known companies, and get paid every single time you solve a customer's problem.</p>
            <p>Neha uses her extra time to earn money on Uearn, everyday.</p>
            <button class="homeJoinUsNow"  (click)="joinusBusiness()">JOIN US NOW</button>
        </div>
    </div>
</section>

<section id="web_banner1">
    <div class='webContain'>
        <div>
            <h2 class="h2Head">WORK FROM ANYWHERE,<br> ANYTIME!</h2>
            <p class="pdt">Use your extra time at home or outside to support some  of<br>
                India's best known companies, and get paid every single time <br> you solve a customer's problem.</p>
            <p class="pdt">Neha uses her extra time to earn money on Uearn, everyday.</p>
            <button class="homeJoinUsNow" (click)="joinusBusiness()" >JOIN US NOW</button>
        </div>
    </div>
</section>
    
</div>
<section id="work_earn_content">
    <h3>Work and earn from home at your own convenience!</h3>
    <div class="under_line"></div>
    <p ng-init="read_more=false">
        With UEarn, you earn money at your own comfort, wherever you want, whenever you want! Just download the ‘UEarn’ app from the Google Play Store, fill in a few details, go through a quick training and you are all set on your way to start making calls and earning money! Yes, it’s that simple!
        <br>
        You will be paid for each customer experience resolution you make and each valuable minute you spend speaking to our customers. With earnings of upto Rs. 100/hour, <span ng-show="read_more">you could end up making as much as Rs.15,000 per month if you put in 5 hours/day, all this while being in the comfort of your home and working at a time of your own choice! But that’s not all, you can choose to earn weekly, bi-monthly or monthly and the more time you spend helping out our customers, the more you can earn on a per day/month basis.</span><br>
        <span ng-show="!read_more" ng-click="read_more=true" class="btn_link">READ MORE</span>
        <span ng-show="read_more" ng-click="read_more=false" class="btn_link">READ LESS</span>
    </p>
</section>
<section id="web_work_earn">
    <div class="web_container">
        <h2>Work and earn from home at your own convenience!</h2>
        <div class="under_line"></div>
        <p>With UEarn, you earn money at your own comfort, wherever you want, whenever you want! Just download the ‘UEarn’ app from the Google Play Store, fill in a few details, go through a quick training and you are all set on your way to start making calls and earning money! Yes, it’s that simple!<br>
            You will be paid for each customer experience resolution you make and each valuable minute you spend speaking to our customers. With earnings of upto Rs. 100/hour, you could end up making as much as Rs.15,000 per month if you put in 5 hours/day, all this while being in the comfort of your home and working at a time of your own choice! But that’s not all, you can choose to earn weekly, bi-monthly or monthly and the more time you spend helping out our customers, the more you can earn on a per day/month basis.</p>
    </div>
</section>
<section id="easy_carousel">
    <div class="easy_content">
        <div id="easy_carousel_div" class="carousel slide" data-ride="carousel">
            <!-- Wrapper for slides -->
            <div class="carousel-inner">
                <div class="item active">
                    <h3>It's Easy to Start</h3>
                    <div class="under_line"></div>
                    <div class="image_icon">
                        <img src="/assets/mob_images/arrows.svg" alt="Download">
                    </div>
                    <div class="content">
                        <p>Download the UEARN app from playstore & We are currently available on "android phones" only.</p>
                    </div>
                    <div class="img_banner img1"></div>
                </div>
                <div class="item">
                    <h3>It's Easy to Start</h3>
                    <div class="under_line"></div>
                    <div class="image_icon">
                        <img src="/assets/mob_images/up-arrow.svg" alt="Download">
                    </div>
                    <div class="content">
                        <p>Sign up for Free & Become a customer experience professional, freshers welcome.</p>
                    </div>
                    <div class="img_banner img2"></div>
                </div>
                <div class="item">
                    <h3>It's Easy to Start</h3>
                    <div class="under_line"></div>
                    <div class="image_icon">
                        <img src="/assets/mob_images/XMLID_3114_.svg" alt="Download">
                    </div>
                    <div class="content">
                        <p>Get trained on the customer experience processes & Start working from anywhere, anytime.</p>
                    </div>
                    <div class="img_banner img3"></div>
                </div>
                <div class="item">
                    <h3>It's Easy to Start</h3>
                    <div class="under_line"></div>
                    <div class="image_icon">
                        <img src="/assets/mob_images/business-and-finance (1).svg" alt="Business and Finance">
                    </div>
                    <div class="content">
                        <p>Get paid for every customer customer experience resolution you take & make handsome bonuses for conversions, everyday.</p>
                    </div>
                    <div class="img_banner img4"></div>
                </div>
            </div>
            <!-- Indicators -->
            <ol class="carousel-indicators">
                <li data-target="#easy_carousel_div" data-slide-to="0" class="active"></li>
                <li data-target="#easy_carousel_div" data-slide-to="1"></li>
                <li data-target="#easy_carousel_div" data-slide-to="2"></li>
                <li data-target="#easy_carousel_div" data-slide-to="3"></li>
            </ol>
        </div>
    </div>
</section>
<section id="web_easy">
    <div class="web_container">
        <h2>It's Easy to Start</h2>
        <div class="under_line"></div>
        <div class="row">
            <div class="col-sm-3 col-md-3 col-lg-3">
                <div class="section_item">
                    <div class="icon">
                        <img src="/assets/web_images/arrows.svg" alt="Download">
                    </div>
                    <div class="text">
                        <p>Download the UEARN app from playstore & We are currently available on "android phones" only.</p>
                    </div>
                    <div class="image image1"></div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <div class="section_item">
                    <div class="icon">
                        <img src="/assets/web_images/up-arrow.svg" alt="Sign up">
                    </div>
                    <div class="text">
                        <p>Sign up for Free & Become a customer experience professional, freshers welcome.</p>
                    </div>
                    <div class="image image2"></div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <div class="section_item">
                    <div class="icon">
                        <img src="/assets/web_images/XMLID_3114_.svg" alt="Training">
                    </div>
                    <div class="text">
                        <p>Get trained on the customer experience processes & Start working from anywhere, anytime.</p>
                    </div>
                    <div class="image image3"></div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <div class="section_item">
                    <div class="icon">
                        <img src="/assets/web_images/business-and-finance (1).svg" alt="Payment">
                    </div>
                    <div class="text">
                        <p>Get paid for every customer customer experience resolution you take & make handsome bonuses for conversions, everyday.</p>
                    </div>
                    <div class="image image4"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="what_is_uearn">
    <h2>What is Uearn</h2>
    <div class="under_line"></div>
    <div id="what_is_carousel" class="carousel slide" data-ride="carousel">
        <!-- Wrapper for slides -->
        <div class="carousel-inner">
            <div class="item active">
                <div class="image_sec img1"></div>
                <div class="content_heading">
                    <p>
                        A Virtual Customer Experience Platform</p>
                </div>
                <div class="content">
                    <p>Uearn connects various brands with freelance customer experience experts like you to solve their customer's problems in real time</p>
                </div>
            </div>
            <div class="item">
                <div class="image_sec img2"></div>
                <div class="content_heading">
                    <p>A customer has a problem</p>
                </div>
                <div class="content">
                    <p>A customer needs help with the product or service, they reach us on multiple touchpoints. </p>
                </div>
            </div>
            <div class="item">
                <div class="image_sec img3"></div>
                <div class="content_heading">
                    <p>You will interact with the customer</p>
                </div>
                <div class="content">
                    <p>Uearn App will facilitate the interaction with the customer on your mobile</p>
                </div>
            </div>
            <div class="item">
                <div class="image_sec img4"></div>
                <div class="content_heading">
                    <p>You solve their problem & earn money</p>
                </div>
                <div class="content">
                    <p>Uearn app will train you on specific processes to solve these problems, earn money for every customer experience resolution you  you make, and get paid weekly</p>
                </div>
            </div>
        </div>
        <!-- Indicators -->
        <ol class="carousel-indicators">
            <li data-target="#what_is_carousel" data-slide-to="0" class="active">1</li>
            <li data-target="#what_is_carousel" data-slide-to="1">2</li>
            <li data-target="#what_is_carousel" data-slide-to="2">3</li>
            <li data-target="#what_is_carousel" data-slide-to="3">4</li>
        </ol>
    </div>
</section>
<section id="web_what_is_uearn">
    <div class="web_container">
        <h2>What is Uearn</h2>
		<div class="under_line"></div>
		<div id="web_what_is_carousel">
			<app-uearn-slide></app-uearn-slide>
		</div>
    </div>
</section>
<section id="benefits">
    <h3>Benefits of Uearn</h3>
    <div class="under_line"></div>
    <div class="content">
        <p>The platform is real time and operates 24/7, so you can choose your work hours based on which days and which times of the day you are available for work. Just log-in and SWIPE TO START!</p>
    </div>
    <div class="listitems">
        <div class="item">
            <div class="icon">
                <img src="/assets/mob_images/business-and-finance (2).svg" alt="Earn">
            </div>
            <div class="text">
                <p class="heading">EARN MORE</p>
                <p class="description">Uearn is designed to offer you substantial earnings for giving just 4 hours/day</p>
            </div>
        </div>
        <div class="item">
            <div class="icon">
                <img src="/assets/mob_images/interface (1).svg" alt="Payment">
            </div>
            <div class="text">
                <p class="heading">WEEKLY PAYMENT</p>
                <p class="description">Uearn pays you per customer experience resolution & pays you once every week, yes you heard it right</p>
            </div>
        </div>
        <div class="item">
            <div class="icon">
                <img src="/assets/mob_images/interface (2).svg" alt="Flexible">
            </div>
            <div class="text">
                <p class="heading">FLEXIBLE HOURS</p>
                <p class="description">Uearn maps your availability to the brand's support timings & allocates a flexi shift</p>
            </div>
        </div>
        <div class="item">
            <div class="icon">
                <img src="/assets/mob_images/clock (2).svg" alt="Save Time">
            </div>
            <div class="text">
                <p class="heading">SAVE TIME</p>
                <p class="description">Uean is completely an online model, you don’t need to travel to work whatsoever</p>
            </div>
        </div>
        <div class="item">
            <div class="icon">
                <img src="/assets/mob_images/people (1).svg" alt="Work Life">
            </div>
            <div class="text">
                <p class="heading">WORK-LIFE BALANCE</p>
                <p class="description">Uearn takes your personal life seriously and is designed to give you more life</p>
            </div>
        </div>
        <div class="item">
            <div class="icon">
                <img src="/assets/mob_images/Coin-02.svg" alt="Work Life">
            </div>
            <div class="text">
                <p class="heading">LEADING BRANDS</p>
                <p class="description">Uearn works with some of the leading brands you may already be a user yourself</p>
            </div>
        </div>
    </div>
</section>
<section id="web_benifts">
    <div class="web_container">
        <h2>Benefits of Uearn</h2>
        <div class="under_line"></div>
        <div class="content">
            <p>The platform is real time and operates 24/7, so you can choose your work hours based on which days and which times of the day you are available for work. Just log-in and SWIPE TO START!</p>
        </div>
        <div class="content_icons">
            <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="item">
                        <div class="icon">
                            <img src="/assets/mob_images/business-and-finance (2).svg" alt="Earn">
                        </div>
                        <div class="text">
                            <p class="heading">EARN MORE</p>
                            <p class="description">Uearn is designed to offer you substantial earnings for giving just 4 hours/day</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="item">
                        <div class="icon">
                            <img src="/assets/mob_images/interface (1).svg" alt="Payment">
                        </div>
                        <div class="text">
                            <p class="heading">WEEKLY PAYMENT</p>
                            <p class="description">Uearn pays you per customer experience resolution & pays you once every week, yes you heard it right</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="item">
                        <div class="icon">
                            <img src="/assets/mob_images/interface (2).svg" alt="Flexible">
                        </div>
                        <div class="text">
                            <p class="heading">FLEXIBLE HOURS</p>
                            <p class="description">Uearn maps your availability to the brand's support timings & allocates a flexi shift</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="item">
                        <div class="icon">
                            <img src="/assets/mob_images/clock (2).svg" alt="Save Time">
                        </div>
                        <div class="text">
                            <p class="heading">SAVE TIME</p>
                            <p class="description">Uean is completely an online model, you don’t need to travel to work whatsoever</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="item">
                        <div class="icon">
                            <img src="/assets/mob_images/people (1).svg" alt="Work Life">
                        </div>
                        <div class="text">
                            <p class="heading">WORK-LIFE BALANCE</p>
                            <p class="description">Uearn takes your personal life seriously and is designed to give you more life</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="item">
                        <div class="icon">
                            <img src="/assets/mob_images/Coin-02.svg" alt="Work Life">
                        </div>
                        <div class="text">
                            <p class="heading">LEADING BRANDS</p>
                            <p class="description">Uearn works with some of the leading brands you may already be a user yourself</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="stories">
    <h3>Uearn Stories</h3>
    <div class="under_line"></div>
    <div class="main_content">
        <p>Uearn helps you get away from the 9 to 5 commitment or simply earn more money on top of your everyday job</p>
	</div>
	<div class="row rectangle8">
        <div id="carouselExampleControlsM" class="carousel slide">
            <ol class="carousel-indicators carousel_indicators_bar">
                <li data-target="#carouselExampleControlsM" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleControlsM" data-slide-to="1"></li>
                <li data-target="#carouselExampleControlsM" data-slide-to="2"></li>
                <li data-target="#carouselExampleControlsM" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="slider_item row">
                        <div class="col-lg-6 col-md-6 col-sm-6 youtube_video">
                            <iframe width="300px" height="300px" src="https://www.youtube.com/embed/4fVBb5jZR_w" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="review_text_video">
                                "After an incident that paralyze me for life, Uearn has helped me with a perfect opportunity I was looking for and helped me gain my confidence back. Uearn has given me a platform to be financially Independent."
                            </div>
                            <div class="customer_details_video">
                                Sushanta Das,<br>
                                UEARN Tele calling agent,<br>
                                Ranaghat, West Bengal.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="slider_item row">
                        <div class="col-lg-6 col-md-6 col-sm-6 youtube_video">
                            <iframe width="300px" height="300px" src="https://www.youtube.com/embed/qJBOrugKAc8" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="review_text_video">
                                "Being a homemaker and a mother is a full time job, but I still wanted to be financially independent and support my family, and Uearn gave me the platform were I can work on my own flexible timings, with managing and balancing my personal and professional life."
                            </div>
                            <div class="customer_details_video">
                                Baisakhi Das,<br>
                                UEARN Tele calling agent,<br>
                                Bangalore.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="slider_item row">
                        <div class="col-lg-6 col-md-6 col-sm-6 youtube_video">
                            <iframe width="300px" height="300px" src="https://www.youtube.com/embed/bgh2pDio9NE" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="review_text_video">
                                "I work from the comfort of my home and I am doing the job I like the most."
                            </div>
                            <div class="customer_details_video">
                                Swagata Basak,<br>
                                UEARN Trainer,<br>
                                Mangalore.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="slider_item row">
                        <div class="col-lg-6 col-md-6 col-sm-6 youtube_video">
                            <iframe width="300px" height="300px" src="https://www.youtube.com/embed/7_pC0XEqrb4" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="review_text_video">
                                "Uearn has helped me to earn conveniently while boosting my self confidence and communication."
                            </div>
                            <div class="customer_details_video">
                                Manmeet Kaur,<br>
                                2nd year B.com student & UEARN Tele calling agent,<br>
                                Dehradun, Uttarakhand.
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
    </div>
</section>
<section id="web_stories">
    <div class="web_container">
        <h2>Uearn Stories</h2>
		<div class="under_line"></div>
		<app-story-slider></app-story-slider>
    </div>
</section>
<section id="media">
    <h3>Media Coverage</h3>
    <div class="under_line"></div>
    <p>Recognised by the industry for innovation and transformation</p>
    <div class="media_images">
        <div class="image1"></div>
        <div class="image2"></div>
    </div>
</section>
<section id="web_media">
    <div class="web_container">
        <h2>Media Coverage</h2>
        <div class="under_line"></div>
        <p>Recognised by the industry for innovation and transformation</p>
        <div class="row">
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/silicon.png" alt="Silicon">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/big-news.png" alt="Big News">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/telecome.png" alt="Telecom">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/indiafior.png" alt="India Forum">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/india-todau.png" alt="India Today">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/new.png" alt="News 18">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/economic.png" alt="The Economic Times">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/business-logo.png" alt="Business Line">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/india.png" alt="Indian Express">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/cnbx.png" alt="Money Control">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/cab-in.png" alt="CanIndia">
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2">
                <img src="/assets/web_images/oldd.png" alt="OLS">
            </div>
        </div>
    </div>
</section>
<section id="download">
    <div id="download_screen"></div>
    <div id="download_content">
        <h3>DOWNLOAD OUR APP</h3>
        <p>Register for free and start earning right away</p>
        <div class="download_img">
            <a href="https://play.google.com/store/apps/details?id=smarter.uearn.money&hl=en" target="_blank">
                <img src="/assets/mob_images/google-play.svg" alt="Download">
            </a>
        </div>
    </div>
</section>
<section id="web_download">
    <!--<div class="background_img"></div>-->
    <div class="download_banner">
        <img src="/assets/mob_images/uiiiis.png" alt="Download Uearn">
    </div>
    <div class="download_div">
        <div class="web_container">
            <h3>DOWNLOAD OUR APP</h3>
            <p>Register for free and start earning right away</p>
            <div class="download_img">
                <a href="https://play.google.com/store/apps/details?id=smarter.uearn.money&hl=en" target="_blank">
                    <img src="/assets/mob_images/google-play.svg" alt="Download">
                </a>
            </div>
        </div>
    </div>
</section>
<section id="mob_footer">
    <div class="row">
        <div class="col-xs-0 col-sm-9">
            <p>2020 © Smarter Biz Technologies Private Limited. All rights reserved.</p>
        </div>
        <div class="col-xs-2 col-sm-2">
            <a id="privacy_policy" href="https://smarterbiz.ai/privacy">
                Privacy Policy
            </a>
        </div>
        <div class="col-xs-1 col-sm-1">
            <a href="https://www.linkedin.com/company/smarter-biz-technologies-private-limited">
                <img src="/assets/mob_images/linkedin (1).svg" alt="Linkedin">
            </a>
            <span><mat-icon class="phoneSymbol CR" matTooltip="Place a C2C Call" (click)="phoneCall()">phone</mat-icon></span>
        </div>
    </div>
</section>



<div class="popupoverlay" *ngIf="showHidePopupPhoneCall">
    <div class="delete-popup" style="width:40%;margin: 0 auto;padding: 10px 0 0 15px;">
        <h3 class="h3Message">Phone Call</h3>
        <a class="close CR" (click)="closePhoneCallPopup()">&times;</a>
        <div class="content">
            <div class="row">
<div class="col-md-12">
<table style="line-height: 35px;">
    <tr>
        <td>
            AParty: <input type="text" [(ngModel)]="aParty"/>
        </td>
    </tr>
    <tr><td><br></td></tr>
    <tr>
        <td>
            BParty: <input type="text"  [(ngModel)]="bParty"/> 
        </td>
    </tr>
</table>
</div>
</div>
<div class="row">
                <div class="col-md-12">
                    <button class="cancelDelete" (click)="cancelPhoneCall()">Cancel</button>&nbsp;&nbsp;
                    <button class="delete-button" (click)="savePhoneCall()">Save</button>&nbsp;&nbsp;
                </div>
              </div>
     <br>
        </div>
    </div>
  </div>

  <div id="cover-spin1" *ngIf="currentLoader"></div>