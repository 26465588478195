<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <app-menu  [navigateLink]="currentTitle" ></app-menu>
    </mat-drawer>
<div class="CN-Main">
    <p class="CN-Tab-head">Process Flow Builder</p>
    <div>
        <mat-card class="CN-Card cardFirst" style="height:auto">
            <!-- <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 cn-dat text-right">{{getCurrentDate()}}</div>
            </div>
            <br> -->
            <div style="display: flex;flex-wrap: wrap;">
                <div style="width:250px;">
                    <label class="lvX">Select A Project</label>
                    <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(value)]="project_name"
                        (selectionChange)="OnChangeProject($event.value)">
                        <mat-option value="">Select</mat-option>
                        <mat-option value="NewFlow"><b><span style="font-weight: 600;margin-right: 5px;font-size: 24px;vertical-align: bottom;">+</span> Create New Project</b></mat-option>
                        <mat-option value="ImportRecord" (click)="importTemplate()"><b>Import </b></mat-option>
                        <mat-option *ngFor="let prj of projects.split('|')" [value]="prj"><b>{{prj}}</b></mat-option> 
                       
                    </mat-select>
                </div>
                <!-- <div style="width:320px;padding-left: 10px;" class="pt-1">
                    <label class="lvX">&nbsp;</label>
                    <mat-radio-group class="rbgp" (change)="changeRadio($event.value)" [(ngModel)]="byRadioValue">
                        <mat-radio-button class="rbcs" value="NewFlow" checked>New Flow</mat-radio-button>
                        <mat-radio-button class="rbcs" value="ImportRecord">Import Record</mat-radio-button>
                    </mat-radio-group>
                </div> -->
                <div style="width:250px;padding-left: 10px;" class="pt-0 ml-3" *ngIf="showHideForProcess">
                    <span>
                        <label class="lvX">
                            <!-- Create Flow -->
                           Process
                        </label>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(value)]="process_name"   (selectionChange)="OnChangeProcess($event.value)">
                        <mat-option value="">Select</mat-option>
                        <mat-option value="createNewProcess"><b><span style="font-weight: 600;margin-right: 5px;font-size: 24px;vertical-align: bottom;">+</span> Create New Process</b></mat-option>
                        <mat-option value="Collections" ><b> Collections </b></mat-option>
                       
                    </mat-select>
                        <!-- <input type="text" class="input_UI" autocomplete="off"> -->
                    </span>
                    <span *ngIf="false">
                        <!-- <label class="lvX">&nbsp;</label> -->
                        <button class="importBtn mt-4" mat-button (click)="importTemplate()">
                            <mat-icon style="margin-right: 10px;position: relative;
                            top: 1px;">upload_file</mat-icon>Import Template
                        </button>
                    </span>
                </div>



                <div style="width:250px;padding-left: 10px;" class="pt-0 ml-3" *ngIf="showHideForTemplate">
                    <label class="lvX">  Select Template   </label>
                    <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(value)]="template_name"   (selectionChange)="OnChangeTemplate($event.value)">
                    <mat-option value="">Select</mat-option>
                    <mat-option value="Collections" ><b> Collections </b></mat-option>
                    
                   
                </mat-select>
                    </div>






                

            </div>
            <div class="row" *ngIf="showHideCreateFlow">
                <div class="col-md-12 col-sm-12 col-xs-12 cn-dat text-right">
                    <button class="prevAdd mt-0" mat-button (click)="previewDialog()">
                        <mat-icon>visibility</mat-icon>PREVIEW
                    </button>
                    <button class="prevAdd mt-0 ml-3" mat-button (click)="addItem()">
                        <mat-icon>add</mat-icon>ADD
                    </button>
                </div>
            </div>
            <br><br>
            <div *ngIf="showHideCreateFlow">
                <mat-accordion>
                    <mat-expansion-panel [ngClass]="'expArrow'" class="mep"
                        *ngFor="let item of items;let index=index;trackBy:indexTracker">
                        <mat-expansion-panel-header class="meh">
                            <mat-panel-title style=" color: #1a5896;">
                                {{index +1}}.&nbsp;&nbsp;  <u> <b>{{item.id}} </b></u>
                            </mat-panel-title>
                            <mat-panel-description (click)="$event.stopPropagation();" style="position: absolute;right: 50px;font-size: 14px;
                            color: #3fb33f !important;font-weight: 600;cursor: pointer;">
                              <span style="color: #ef0e0e !important;"  *ngIf="enableEdit && enableEditIndex == index" (click)="enableEdit=false" class="mr-4">CANCEL</span>
                              <span *ngIf="enableEdit && enableEditIndex == index" (click)="saveModify()">SAVE</span>
                               <span *ngIf="!enableEdit" (click)="editClick($event, index)">EDIT</span>
                              </mat-panel-description>
                        </mat-expansion-panel-header>

                        <br>
                        <div class="row  mt-4" style="border-top: 1.3px solid #c1c1c1;padding: 19px 0 0 0;">
                            <div class="col-md-12 col-sm-12 col-xs-12" style="overflow: auto;">
                                <section class="section1">
                                    <div class="squareStage1">
                                      
                                            <div style="display: table-cell; vertical-align: middle;">
                                              <div>
                                                {{item.touchpoint || 'Stage 1' }}
                                              </div>
                                            </div>



                                    </div>
                                    <div class="line1"></div>
                                    <div class="newExpEvent1">                                      


                                        <div style="display: table-cell; vertical-align: middle;">
                                            <div>
                                                {{item.experience  || 'New Experience Event'}}
                                            </div>
                                          </div>




                                    </div>


                                    <div class="line1"></div>
                                    <div class="parallelogram">
                                        <span class="setIcon" *ngIf="item.nb_post_action=='add_customer_to_auto_dialer'">
                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                        </span>
                                        <span class="setIcon" *ngIf="item.nb_post_action=='send_wa_message_to_customer'">
                                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                        </span>
                                        <span class="setIcon" *ngIf="item.nb_post_action=='sms'">
                                            <i class="fa fa-comments" aria-hidden="true"></i>
                                        </span>
                                        <span class="setIcon" *ngIf="item.nb_post_action=='email'">
                                            <i class="fa fa-envelope" aria-hidden="true"></i>
                                        </span>
                                        <span class="pSpanText">
                                          
                                        </span>

                                        <div style="display: table-cell; vertical-align: middle;">
                                            <div>
                                                {{item.action_data || 'Use This script to Call'}}
                                            </div>
                                          </div>





                                    </div>
                                    <div class="line1"></div>
                                    <div class="newExpEvent2">
                                       
                                        <div style="display: table-cell; vertical-align: middle;">
                                            <div>
                                                {{item.pf_next_expected_event || 'New Expected Event'}}
                                            </div>
                                          </div>

                                    </div>
                                    <div class="line1"></div>
                                    <div class="squareStage2">
                                      
                                        <div style="display: table-cell; vertical-align: middle;">
                                            <div>
                                                {{item.next_touchpoint || 'Stage 2'}}
                                            </div>
                                          </div>
                                    </div>
                                </section>
                            </div>                 
                        </div>








                        <div>
                            <br>
                            <div class="row mt-4">
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">Experience</label>
                                  
                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                        [(ngModel)]="item.experience">
                                        <span class="closeInputui" *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">ox_channel</label>
                                     
                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                    [(ngModel)]="item.channel">
                                    <span class="closeInputui"  *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
                                    <!-- <mat-select [ngClass]="'arrowcs'" class="dropdownCs ht-x"
                                        [(ngModel)]="item.channel">
                                        <mat-option value="Option1">Option1</mat-option>
                                    </mat-select> -->
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">State</label>

                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                    [(ngModel)]="item.touchpoint">
                                    <span class="closeInputui"  *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
                                    <!-- <mat-select [ngClass]="'arrowcs'" class="dropdownCs ht-x"
                                        [(ngModel)]="item.touchpoint">
                                        <mat-option value="State1">State1</mat-option>
                                    </mat-select> -->
                                </div>
                            </div>
                            <div class="row  mt-4">
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">ox_event</label>

                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                    [(ngModel)]="item.event">
                                    <span class="closeInputui"  *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
                                    <!-- <mat-select [ngClass]="'arrowcs'" class="dropdownCs ht-x"
                                        [(ngModel)]="item.event">
                                        <mat-option value="Option1">Option1</mat-option>
                                    </mat-select> -->
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">ox_nba_pre</label>
                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                    [(ngModel)]="item.nb_pre_action">
                                    <span class="closeInputui"  *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
                                    <!-- <mat-select [ngClass]="'arrowcs'" class="dropdownCs ht-x"
                                        [(ngModel)]="item.nb_pre_action">
                                        <mat-option value="Option1">Option1</mat-option>
                                    </mat-select> -->
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">ox_nba_post</label>
                                    <mat-select [ngClass]="'arrowcs'" class="dropdownCs ht-x"
                                        (selectionChange)="onChangeNbaPost($event.value)"
                                        [(ngModel)]="item.nb_post_action">
                                        <mat-option value="send_wa_message_to_customer">Whatsapp</mat-option>
                                        <mat-option value="sms">SMS</mat-option>
                                        <mat-option value="add_customer_to_auto_dialer">IVR(Call)</mat-option>
                                        <mat-option value="email">Email</mat-option>
                                    </mat-select>
                                    <span class="closeInputui"  *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
                                </div>
                            </div>
                            <div class="row  mt-4">
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">ox_next_State</label>
                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                    [(ngModel)]="item.next_touchpoint">
                                    <span class="closeInputui"  *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
                                    <!-- <mat-select [ngClass]="'arrowcs'" class="dropdownCs ht-x"
                                        [(ngModel)]="item.next_touchpoint">
                                        <mat-option value="State2">State2</mat-option>
                                    </mat-select> -->
                                </div>
                             
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">next_expected_event</label>
                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                        [(ngModel)]="item.pf_next_expected_event">
                                        <span class="closeInputui"  *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
                                </div>
                            </div>

<div  class="row  mt-4">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <label class="lvX">action_data</label>
        <!-- <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
            [(ngModel)]="item.action_data"> -->
            <textarea  class="input_UI wdHnd ht-x" style="height: 120px;resize:none"  autocomplete="off"
            [(ngModel)]="item.action_data"></textarea>
            <span class="closeInputui"  *ngIf="enableEdit && enableEditIndex == index"><mat-icon  class="closeiconui">highlight_off</mat-icon></span>
    </div>
</div>


                            <div class="row  mt-4">
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">Funnel</label>
                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                        [(ngModel)]="item.nb_state_update.split('|')[0]">
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">&nbsp;</label>
                                    <input type="number" class="input_UI wdHnd ht-x" style="width: 60px;"
                                        autocomplete="off" [(ngModel)]="item.nb_state_update.split('|')[1]">
                                    <!-- <input type="number" class="input_UI wdHnd ht-x ml-2" style="width: 60px;"
                                        autocomplete="off" [(ngModel)]="item.nb_state_update.split('|')[1]"> -->
                                </div>

                            </div>
                            <div class="row  mt-4">
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">Metric</label>
                                    <input type="text" class="input_UI wdHnd ht-x" autocomplete="off"
                                        [(ngModel)]="item.nb_metric_update.split('|')[1] + ' '+ item.nb_metric_update.split('|')[2]">
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="lvX">&nbsp;</label>
                                    <input type="number" class="input_UI wdHnd ht-x" style="width: 60px;"
                                        autocomplete="off" [(ngModel)]="item.nb_metric_update.split('|')[0]">
                                    <!-- <input type="number" class="input_UI wdHnd ht-x ml-2" style="width: 60px;"
                                        autocomplete="off" [(ngModel)]="item.pf_metric_max"> -->
                                </div>

                            </div>
                            <div class="row  mt-4" style="border-top: 1.3px solid #c1c1c1;padding: 19px 0 0 0;">
                                <div class="col-md-8 col-sm-12 col-xs-12 text-center">
                                    <p class="sMsg">{{successMsg}}</p>
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12 text-right">


                                    <button class="prevAdd mt-0 ml-3" *ngIf="index !=0"
                                        style="color:#dd133c !important;border:1px solid #dd133c !important" mat-button
                                        (click)="deleteItem(index)">
                                        <mat-icon>cancel</mat-icon>DELETE
                                    </button>
                                    <!-- <button class="prevAdd mt-0 ml-3" mat-button style="border: 1.5px solid #30ad27 !important;color: #30ad27 !important;" (click)="previewBtn()">
                                        <mat-icon>visibility</mat-icon>PREVIEW
                                    </button> -->
                                    <button class="prevAdd mt-0 ml-3"
                                        style="color:#0bac1e !important;border:1px solid #0bac1e !important" mat-button
                                        (click)="saveItem(index)">
                                        <mat-icon>done</mat-icon>SAVE
                                    </button>

                                </div>
                            </div>

                            <!--start preview ui-->
                         
                        <!-- <ul id="myUL" class="tree">
  <li>Start<span class="caret"  matTooltip="Double Click"></span>
    <ul class="nested">
      <li> {{item.touchpoint || 'Stage 1' }}<span class="caret" matTooltip="Double Click"></span>
        <ul class="nested">
          <li> {{item.experience  || 'New Experience Event'}}<span class="caret"  matTooltip="Double Click"></span>
            <ul class="nested">
              <li> 
                  
                <span class="setIcon1" *ngIf="item.nb_post_action=='add_customer_to_auto_dialer'">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                </span>
                <span class="setIcon1" *ngIf="item.nb_post_action=='send_wa_message_to_customer'">
                    <i class="fa fa-whatsapp" aria-hidden="true"></i>
                </span>
                <span class="setIcon1" *ngIf="item.nb_post_action=='sms'">
                    <i class="fa fa-comments" aria-hidden="true"></i>
                </span>
                <span class="setIcon1" *ngIf="item.nb_post_action=='email'">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                </span>
                
                
                <span style="color: #f07318;">
                
                {{item.action_data || 'Use This script to Call'}} </span><span class="caret"  matTooltip="Double Click"></span>
               <ul class="nested">
              <li>   {{item.pf_next_expected_event || 'New Expected Event'}}<span class="caret"  matTooltip="Double Click"></span>
                  <ul class="nested">
              <li>   {{item.next_touchpoint || 'Stage 2'}}</li>              
            </ul></li>              
            </ul>
              </li>              
            </ul>
          </li>
        </ul>
      </li> 
    </ul>
  </li>
</ul> -->
                          
                    
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-card>
    </div>
</div>
</mat-drawer-container>