
<br>

<div class="row">
<div class="col-lg-3 col-md-3"  style="padding-right: 0px;"> 
	<div class="card">
		<div class="card-header"><b>Summary</b></div>
		<div class="card-body">
		
		
			<table class="Cam-table">
				<tr><td><span>Application</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="campaignSummary.summary.total_perc"></mat-progress-bar>
					</td>
					<td class="tdData1"><span>{{campaignSummary.summary.total_perc}}% ({{ campaignSummary.summary.total }})</span></td>
				</tr>
				<tr><td><span>Candidates called</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="campaignSummary.summary.data_called_perc"></mat-progress-bar>
					</td>
					<td class="tdData2"><span>{{campaignSummary.summary.data_called_perc}}% ({{ campaignSummary.summary.data_called }})</span>
					</td>
				</tr>
				<tr>
					<td><span>Contacted</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="campaignSummary.summary.data_contacted_perc"></mat-progress-bar>
					</td>
					<td class="tdData3"><span>{{campaignSummary.summary.data_contacted_perc}}% ({{campaignSummary.summary.data_contacted}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Selected</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-four'" [value]="campaignSummary.summary.data_selected_perc"></mat-progress-bar>
					</td>
					<td class="tdData4"><span>{{campaignSummary.summary.data_selected_perc}}% ({{campaignSummary.summary.data_selected}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Rejected</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-five'" [value]="campaignSummary.summary.data_rejected_perc"></mat-progress-bar>
					</td>
					<td class="tdData5"><span>{{campaignSummary.summary.data_rejected_perc}}%	({{campaignSummary.summary.data_rejected}})</span>
				 </td>
				</tr>
			</table>					
		</div>                                    
	</div>

</div>
<div class="col-lg-3 col-md-3"  style="padding-right: 0px;"> 
	<div class="card">
		<div class="card-header"><span style="color: #5e5e5e;"><b>L1</b></span> Interview<small>(current)</small></div>
		<div class="card-body pl-0">
			<div class="tile-row-pie justify-content-center">
				<div style="height: 210px; width: 210px;" [chart]="recruiterL1Chart"></div>
				<div>
					<div class="tile-row-pie"><span> <hr class="pie-series"></span><span style="padding-left: 5px;"> <span style="color: #606d35; font-weight: 500; font-size: 20px;">{{campaignSummary.summary.data_selected_l1}}</span> <p class="tile-text mb-1">Selected</p> </span></div>
					<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #ff6619;"></span><span style="padding-left: 5px;"> <span style="color: #ff6619; font-weight: 500; font-size: 20px;">{{campaignSummary.summary.data_rejected_l1}}</span> <p class="tile-text mb-1">Rejected</p> </span></div>

				</div>
			</div>   
		</div>                                 
	</div>

</div>
<div class="col-lg-3 col-md-3"  style="padding-right: 0px;"> 
	<div class="card">
		<div class="card-header"><span style="color: #5e5e5e;"><b>L2</b></span> Interview<small>(current)</small></div>
		<div class="card-body pl-0">
			<div class="tile-row-pie justify-content-center">
				<div style="height: 210px; width: 210px;" [chart]="recruiterL2Chart"></div>
				<div>
					<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #e31359;"></span><span style="padding-left: 5px;"> <span style="color: #e31359; font-weight: 500; font-size: 20px;">{{campaignSummary.summary.data_selected_l2}}</span> <p class="tile-text mb-1">Selected</p> </span></div>
					<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #1e7ec6;"></span><span style="padding-left: 5px;"> <span style="color: #1e7ec6; font-weight: 500; font-size: 20px;">{{campaignSummary.summary.data_rejected_l2}}</span> <p class="tile-text mb-1">Rejected</p> </span></div>

				</div>
			</div>   
		</div>                                 
	</div>

</div>
<div class="col-lg-3 col-md-3"> 
	<div class="card">
		<div class="card-header"><span style="color: #5e5e5e;"><b>Client</b></span> Interview<small>(current)</small></div>
		<div class="card-body pl-0">
			<div class="tile-row-pie justify-content-center">
				<div style="height: 210px; width: 210px;" [chart]="recruiterL3Chart"></div>
				<div>
					<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #9845a1;"></span><span style="padding-left: 5px;"> <span style="color: #9845a1; font-weight: 500; font-size: 20px;">{{campaignSummary.summary.data_selected_client}}</span> <p class="tile-text mb-1">Selected</p> </span></div>
					<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #caae3c;"></span><span style="padding-left: 5px;"> <span style="color: #caae3c; font-weight: 500; font-size: 20px;">{{campaignSummary.summary.data_rejected_client}}</span> <p class="tile-text mb-1">Rejected</p> </span></div>

				</div>
			</div>   
		</div>                                 
	</div>

</div>
</div>

