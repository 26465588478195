 <div class="searchAdjust">
        <mat-form-field [ngClass]="'inputClr'" style="font-size: 12px;">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search Batch/Trainer">
            <i class="fa fa-search search-icon" matSuffix></i>
          </mat-form-field>
    </div>
   <br>

<div class="scrollTable">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="live">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> <i class="fa fa-circle" style="color: #86c75e;" aria-hidden="true"></i> </td>
    </ng-container>
  
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef id="vk-fntWT"> Batch </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
  
    <ng-container matColumnDef="end_date">
      <th mat-header-cell *matHeaderCellDef id="vk-fntWT"> Complete On </th>
      <td mat-cell *matCellDef="let element"> {{element.end_date | date:'yyyy-MM-dd'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef id="vk-fntWT"> Trainers </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
