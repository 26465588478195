import { NgModule, Inject } from '@angular/core';
import { Routes, RouterModule, Router, Event, NavigationStart, NavigationEnd, NavigationError, RoutesRecognized } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AgencyComponent } from './agency/agency.component';
import { CustomerComponent } from './customer/customer.component';
import { TechsupportComponent } from './techsupport/techsupport.component';
import { OpsdashboardComponent } from './opsdashboard/opsdashboard.component';
import { PmdashboardComponent } from './pmdashboard/pmdashboard.component';
import { TrainingComponent } from './training/training.component';
import { OnboardTechsupportComponent } from './onboard-techsupport/onboard-techsupport.component';
import { AgentsListComponent } from './agents-list/agents-list.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { BatchCreateComponent } from './batch-create/batch-create.component';
import { TrainerCreateComponent } from './trainer-create/trainer-create.component';
import { TrainerListComponent } from './trainer-list/trainer-list.component';
import { ReportComponent } from './report/report.component';
import { CertificationComponent } from './certification/certification.component';
import { OnJobTrainingComponent } from './on-job-training/on-job-training.component';
import { OnJobTrainingAgentComponent } from './on-job-training-agent/on-job-training-agent.component';
import { CreateOjtBatchComponent } from './create-ojt-batch/create-ojt-batch.component';
import { SendMessageComponent } from './send-message/send-message.component';
import { BatchDaysTrainingComponent } from './batch-days-training/batch-days-training.component';
import { CalandarComponent } from './calandar/calandar.component';
import { ActivateGuard } from './activate.guard';
import { DashboardTrainingComponent } from './dashboard-training/dashboard-training.component';
import { HiringComponent } from './hiring/hiring.component';
import { Hiring2Component } from './hiring2/hiring2.component';
import { ManagementTeamComponent } from './management-team/management-team.component';
import { PmdashComponent } from './pmdash/pmdash.component';
import { FkEmpComponent } from './fk-emp/fk-emp.component';
import { DashboardInboundComponent } from './dashboard-inbound/dashboard-inbound.component';
import { VoiceCallAuditComponent } from './voice-call-audit/voice-call-audit.component';
import { EmailAuditComponent } from './email-audit/email-audit.component';
import { ChatAuditComponent } from './chat-audit/chat-audit.component';
import { AuditorManagementComponent } from './auditor/auditor-management/auditor-management.component';
import { AuditorDashboardComponent } from './auditor/auditor-dashboard/auditor-dashboard.component';
import { AuditorProjectsComponent } from './auditor/auditor-projects/auditor-projects.component';
import { AuditorAuditorsComponent } from './auditor/auditor-auditors/auditor-auditors.component';
import { AuditorReportsComponent } from './auditor/auditor-reports/auditor-reports.component';
import { AuditorHelpComponent } from './auditor/auditor-help/auditor-help.component';
import { AuditorDataComponent } from './auditor/auditor-data/auditor-data.component';
import { CampaignDetailsComponent } from './campaign-v2/campaign-details/campaign-details.component';
import { DashboardComponent as campaignv2 } from './campaign-v2/dashboard.component';
import { DevTestComponent } from './dev-test/dev-test.component';
import { CallFlowFormComponent } from './call-flow-web-form/call-flow-form/call-flow-form.component';
import { CallFormCreateComponent } from './call-flow-web-form/call-form-create/call-form-create.component';
import { ForgotPasswordComponent } from './home/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './home/reset-password/reset-password.component';
import { JoinusBusinessComponent } from './home/joinus-business/joinus-business.component';
import { JoinusComponent } from './home/joinus/joinus.component';
import { FaqComponent } from './home/faq/faq.component';
import { OrchestrationComponent } from './orchestration/orchestration/orchestration.component';
import { OrchestrationEmailComponent } from './orchestration/orchestration-email/orchestration-email.component';
import { AssesmentComponent } from './agentPlugin/assesment/assesment.component';
import { CallHistoryAgentPluginComponent } from './agentPlugin/call-history-agent-plugin/call-history-agent-plugin.component';
import { EarningsAgentPluginComponent } from './agentPlugin/earnings-agent-plugin/earnings-agent-plugin.component';
import { FaceRecognizeComponent } from './agentPlugin/face-recognize/face-recognize.component';
import { HomeAgentPluginComponent } from './agentPlugin/home-agent-plugin/home-agent-plugin.component';
import { PluginComponent } from './agentPlugin/plugin/plugin.component';
import { WorkSlotAgentPluginComponent } from './agentPlugin/work-slot-agent-plugin/work-slot-agent-plugin.component';
import { ProfilePluginComponent } from './agentPlugin/profile-plugin/profile-plugin.component';
import { ProfileAttendanceDialogComponent } from './agentPlugin/profile-attendance-dialog/profile-attendance-dialog.component';
import { RegisterAgentComponent } from './agentPlugin/register-agent/register-agent.component';
import { ChatAgentComponent } from './agentPlugin/chat-agent/chat-agent.component';
import { LoginAgentComponent } from './agentPlugin/login-agent/login-agent.component';

import { FeedDashComponent } from './feed-dash/feed-dash.component';
import { OrcheContentComponent } from './orchestration/orche-content/orche-content.component';
import { CallrecordsdashComponent } from './callrecordsdash/callrecordsdash.component';
import { AgentDashComponent } from './sharekhan/agent-dash/agent-dash.component';
import { AgentFeedBackComponent } from './sharekhan/agent-feed-back/agent-feed-back.component';
import { SharekhanComponent } from './sharekhan/sharekhan/sharekhan.component';
import { ActivityComponent } from './sharekhan/activity/activity.component';
import { VoiceCallUploadAudioComponent } from './voice-call-upload-audio/voice-call-upload-audio.component';
import { OnboardingGetStartedComponent } from './onboarding/onboarding-get-started/onboarding-get-started.component';
import { OnboardingProcessPage1Component } from './onboarding/onboarding-process-page1/onboarding-process-page1.component';
import { OnboardingProcessPage2Component } from './onboarding/onboarding-process-page2/onboarding-process-page2.component';
import { OnboardingProcessPage3Component } from './onboarding/onboarding-process-page3/onboarding-process-page3.component';
import { OnboardingProcessPage4Component } from './onboarding/onboarding-process-page4/onboarding-process-page4.component';
import { OnboardingProcessPage5Component } from './onboarding/onboarding-process-page5/onboarding-process-page5.component';
import { OnboardingProcessPage6Component } from './onboarding/onboarding-process-page6/onboarding-process-page6.component';
import { OnboardingProcessPage7Component } from './onboarding/onboarding-process-page7/onboarding-process-page7.component';
import { DemoPortalComponent } from './demo-portal/demo-portal/demo-portal.component';
import { DemoPortalDashboardComponent } from './demo-portal/demo-portal-dashboard/demo-portal-dashboard.component';
import { DemoPortalCrcCallComponent } from './demo-portal/demo-portal-crc-call/demo-portal-crc-call.component';
import { DemoPortalSettingComponent } from './demo-portal/demo-portal-setting/demo-portal-setting.component';
import { VoiceBiometricComponent } from './voice-biometric/voice-biometric.component';
import { AssessmentUploadComponent } from './training/assessment-upload/assessment-upload.component';
import { GenericComponent } from './generic/generic.component';
import { GenericOutboundComponent } from './generic/generic-outbound/generic-outbound.component';
import { GenericFeedbackComponent } from './generic/generic-feedback/generic-feedback.component';
import { AuditFormsComponent } from './audit-forms/audit-forms.component';
import { TimeslotSettingComponent } from './timeslot-setting/timeslot-setting.component';
import { WhatsappDashboardComponent } from './generic/whatsapp-dashboard/whatsapp-dashboard.component';
import { PaymentDashboardComponent } from './payment-dashboard/payment-dashboard.component';
import { ContiinexReportsComponent } from './contiinex-reports/contiinex-reports.component';
import { ContiinexWebApplicationComponent } from './contiinex-web-application/contiinex-web-application.component';
import { ContiinexComnexPageComponent } from './contiinex-comnex-page/contiinex-comnex-page.component';
import { ContiinexGignexPageComponent } from './contiinex-gignex-page/contiinex-gignex-page.component';
import { ContiinexAinexPageComponent } from './contiinex-ainex-page/contiinex-ainex-page.component';
import { ContiinexContactusPageComponent } from './contiinex-contactus-page/contiinex-contactus-page.component';
import { ContiinexWebLoginComponent } from './contiinex-web-login/contiinex-web-login.component';
import { ContactCallPluginComponent } from './agentPlugin/contact-call-plugin/contact-call-plugin.component';
import { FaqNewComponent } from './home/faq-new/faq-new.component';
import { ContiinexWebForgotResetPasswordComponent } from './contiinex-web-forgot-reset-password/contiinex-web-forgot-reset-password.component';
//import {ContiinexLoginNewuiComponent} from './contiinex-login-newui/contiinex-login-newui.component';
import { DesktopMenuComponent } from './desktop-calling/desktop-menu/desktop-menu.component';
import { DesktopDashboardComponent } from './desktop-calling/desktop-dashboard/desktop-dashboard.component';
import { ContactListDesktopCallingComponent } from './desktop-calling/contact-list-desktop-calling/contact-list-desktop-calling.component';
import { ChatDesktopCallingComponent } from './desktop-calling/chat-desktop-calling/chat-desktop-calling.component';
import { MyearningsDesktopCallingComponent } from './desktop-calling/myearnings-desktop-calling/myearnings-desktop-calling.component';
import { SupportDesktopCallingComponent } from './desktop-calling/support-desktop-calling/support-desktop-calling.component';
import { SettingsDesktopCallingComponent } from './desktop-calling/settings-desktop-calling/settings-desktop-calling.component';
import { DesktopCallingNewPageComponent } from './desktop-calling-new-page/desktop-calling-new-page.component';
import { DesktopContactListPageComponent } from './desktop-contact-list-page/desktop-contact-list-page.component';
import { CampaignDetailsViewPageComponent } from './campaign-v2/campaign-details-view-page/campaign-details-view-page.component';
import { CampaignCentralCreatePageComponent } from './campaign-v2/campaign-central-create-page/campaign-central-create-page.component';
import { BADashboardComponent } from './badashboard/badashboard.component';
import { CentralDashboardComponent } from './central-dashboard/central-dashboard.component'//'./centralcampaign/centralcampaign.component';
import { CentralcampaignComponent } from './centralcampaign/centralcampaign.component';

import { NewContiinexHomePageComponent } from './contiinex-website/new-contiinex-home-page/new-contiinex-home-page.component'
import { NewContiinexMenuPageComponent } from './contiinex-website/new-contiinex-menu-page/new-contiinex-menu-page.component';
import { NewContiinexComnexEngagePageComponent } from './contiinex-website/new-contiinex-comnex-engage-page/new-contiinex-comnex-engage-page.component';
import { NeContiinexComnexEnablePageComponent } from './contiinex-website/ne-contiinex-comnex-enable-page/ne-contiinex-comnex-enable-page.component';
import { NewContiinexNewContactPageComponent } from './contiinex-website/new-contiinex-new-contact-page/new-contiinex-new-contact-page.component';
import { NewContiinexWhatsappApiPageComponent } from './contiinex-website/new-contiinex-whatsapp-api-page/new-contiinex-whatsapp-api-page.component';
import { NewContiinexComnexExperiencePageComponent } from './contiinex-website/new-contiinex-comnex-experience-page/new-contiinex-comnex-experience-page.component';
import { NewContiinexPrivacyPolicySectionPageComponent } from './contiinex-website/new-contiinex-privacy-policy-section-page/new-contiinex-privacy-policy-section-page.component';

import { ContiinexRegisterNewuiComponent } from './contiinex-website/contiinex-register-newui/contiinex-register-newui.component';

import { ContiinexLoginNewuiComponent } from './contiinex-website/contiinex-login-newui/contiinex-login-newui.component';

//comnex
import { ContiinexProfileSettingsComponent } from './comnex/contiinex-profile-settings/contiinex-profile-settings.component';
import { ContiinexProfilePreviewDialogComponent } from './comnex/contiinex-profile-preview-dialog/contiinex-profile-preview-dialog.component';
import { ContiinexSelectChannelDialogComponent } from './comnex/contiinex-select-channel-dialog/contiinex-select-channel-dialog.component';
import { ContiinexCampaignPageComponent } from './comnex/contiinex-campaign-page/contiinex-campaign-page.component';
import { ContiinexMessageTemplateComponent } from './comnex/contiinex-message-template/contiinex-message-template.component';
import { ContiinexSandboxUiComponent } from './comnex/contiinex-sandbox-ui/contiinex-sandbox-ui.component';
import { ContiinexReports1Component } from './comnex/contiinex-reports1/contiinex-reports1.component';
import { ContiinexDashboardComponent } from './comnex/contiinex-dashboard/contiinex-dashboard.component';
import { ProjectOneComponent } from './comnex/project-one/project-one.component';
import { ContiinexShopifyDashboardComponent } from './comnex/contiinex-shopify-dashboard/contiinex-shopify-dashboard.component';
import { ContiinexShopifyTeamComponent } from './comnex/contiinex-shopify-team/contiinex-shopify-team.component';
import { ContiinexShopifyBillingComponent } from './comnex/contiinex-shopify-billing/contiinex-shopify-billing.component';

import { ContiinexShopifySettingsComponent } from './comnex/contiinex-shopify-settings/contiinex-shopify-settings.component';
import { ContiinexProcessFlowBuilderComponent } from './comnex/contiinex-process-flow-builder/contiinex-process-flow-builder.component';
import { ContiinexDetailsViewComponent } from './comnex/contiinex-details-view/contiinex-details-view.component';
import { CampaignFunnelDashboardComponent } from './comnex/campaign-funnel-dashboard/campaign-funnel-dashboard.component';
import { ContiinexCompanyPageComponent } from './contiinex-website/contiinex-company-page/contiinex-company-page.component';
import { LcCordinatorPageComponent } from './lc-cordinator-page/lc-cordinator-page.component';
import { DesktopCampaignListPageComponent } from './desktop-campaign-list-page/desktop-campaign-list-page.component';
import { SettingsComponent } from './settings/settings.component';

const routes: Routes = [
	//{ path: '', component: ContiinexWebApplicationComponent },
	{
		path: '', component: NewContiinexHomePageComponent, data: {//NewContiinexWebsiteComponent
			title: 'Contiinex | Customer Engagement Automation'
		}
	},
	//{ path: 'home', component: HomeComponent },
	//{ path: 'signin', component: SigninComponent },
	{ path: 'dashboard-recruiter', component: DashboardComponent, canActivate: [ActivateGuard] },

	{ path: 'agency', component: AgencyComponent, canActivate: [ActivateGuard] },
	{ path: 'customer', component: CustomerComponent, canActivate: [ActivateGuard] },
	{ path: 'techSupport', component: TechsupportComponent, canActivate: [ActivateGuard] },
	{ path: 'opsdashboard', component: OpsdashboardComponent, canActivate: [ActivateGuard] },
	{ path: 'pmdashboard', component: PmdashComponent, canActivate: [ActivateGuard] },
	//{path: 'training', component: TrainingComponent, canActivate:[ActivateGuard]},
	{ path: 'callrecords', component: CallrecordsdashComponent, canActivate: [ActivateGuard] },
	{ path: 'fk_service_dash', component: FkEmpComponent, canActivate: [ActivateGuard] },

	{ path: 'Stagesettings', component: TimeslotSettingComponent, canActivate: [ActivateGuard] },
	{
		path: 'training', component: TrainingComponent, canActivate: [ActivateGuard],
		children: [
			{ path: 'dashboardTraining', component: DashboardTrainingComponent, canActivate: [ActivateGuard] },
			{ path: 'agents', component: AgentsListComponent, canActivate: [ActivateGuard] },
			{ path: 'batch', component: BatchListComponent, canActivate: [ActivateGuard] },
			{ path: 'create_batch', component: BatchCreateComponent, canActivate: [ActivateGuard] },
			{ path: 'batch_training', component: BatchDaysTrainingComponent, canActivate: [ActivateGuard] },
			{ path: 'trainers', component: TrainerListComponent, canActivate: [ActivateGuard] },
			{ path: 'create_trainer', component: TrainerCreateComponent, canActivate: [ActivateGuard] },
			{ path: 'onJobTraining', component: OnJobTrainingComponent, canActivate: [ActivateGuard] },
			{ path: 'create_ojt_batch', component: CreateOjtBatchComponent, canActivate: [ActivateGuard] },
			{ path: 'onJobTrainingAgent', component: OnJobTrainingAgentComponent, canActivate: [ActivateGuard] },
			{ path: 'certification', component: CertificationComponent, canActivate: [ActivateGuard] },
			{ path: 'reports', component: ReportComponent, canActivate: [ActivateGuard] },
			{ path: 'send-message', component: SendMessageComponent, canActivate: [ActivateGuard] },
			{ path: 'calandar', component: CalandarComponent, canActivate: [ActivateGuard] },
			{ path: 'assessment', component: AssessmentUploadComponent, canActivate: [ActivateGuard] }
		]
	},
	{ path: 'hiring', component: HiringComponent },
	{ path: 'agent-hiring', component: Hiring2Component },
	{ path: 'managementTeam', component: ManagementTeamComponent, canActivate: [ActivateGuard] },
	{ path: 'pmdash', component: PmdashComponent, canActivate: [ActivateGuard] },
	{ path: 'dashboard-inbound', component: DashboardInboundComponent, canActivate: [ActivateGuard] },
	{ path: 'voiceCallAudit', component: VoiceCallAuditComponent, canActivate: [ActivateGuard] },
	{ path: 'emailAudit', component: EmailAuditComponent, canActivate: [ActivateGuard] },
	{ path: 'chatAudit', component: ChatAuditComponent, canActivate: [ActivateGuard] },
	{ path: 'uploadAudio', component: VoiceCallUploadAudioComponent, canActivate: [ActivateGuard] },
	{ path: 'voiceBiometric', component: VoiceBiometricComponent, canActivate: [ActivateGuard] },
	{
		path: 'campaignV2', component: campaignv2, canActivate: [ActivateGuard], children: [
			{ path: 'details', component: CampaignDetailsComponent, canActivate: [ActivateGuard] },
			//	{ path: 'campaignDetails', component: CampaignDetailsViewPageComponent, canActivate: [ActivateGuard] },

		]
	},
	{ path: 'test_dev', component: DevTestComponent },

	{
		path: 'auditor', component: AuditorManagementComponent, canActivate: [ActivateGuard],
		children: [
			{ path: 'auditor-dashboard', component: AuditorDashboardComponent, canActivate: [ActivateGuard] },
			{ path: 'auditor-projects', component: AuditorProjectsComponent, canActivate: [ActivateGuard] },
			{ path: 'auditor-auditors', component: AuditorAuditorsComponent, canActivate: [ActivateGuard] },
			{ path: 'auditor-report', component: AuditorReportsComponent, canActivate: [ActivateGuard] },
			{ path: 'auditor-help', component: AuditorHelpComponent, canActivate: [ActivateGuard] },
			{ path: 'team', component: ManagementTeamComponent, canActivate: [ActivateGuard] },
			{ path: 'audit-forms', component: AuditFormsComponent, canActivate: [ActivateGuard] },
			{ path: 'auditor-data', component: AuditorDataComponent, canActivate: [ActivateGuard] },
		]
	},
	{
		path: 'call-flow-form', component: CallFlowFormComponent,
		children: [
			{ path: 'call-flow-create', component: CallFormCreateComponent }
		]
	},
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'changepassword', component: ResetPasswordComponent },
	{ path: 'joinus-business', component: JoinusBusinessComponent },
	{ path: 'joinus', component: JoinusComponent },
	{ path: 'uearn/faq', component: FaqComponent },
	{
		path: 'orchestrator', component: OrchestrationComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'email' },
			{ path: 'email', component: OrchestrationEmailComponent },
			{ path: 'orche', component: OrcheContentComponent }
		]
	},
	{ path: 'feedback', component: FeedDashComponent },
	{
		path: 'view', component: GenericComponent, canActivate: [ActivateGuard],
		children: [
			{ path: "outbound", component: GenericOutboundComponent, canActivate: [ActivateGuard] },
			{ path: "forms", component: GenericFeedbackComponent, canActivate: [ActivateGuard] }
		]
	},
	{
		path: 'sharekhan', component: SharekhanComponent, canActivate: [ActivateGuard],
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'agent_dash', canActivate: [ActivateGuard] },
			{ path: 'agent_dash', component: AgentDashComponent, canActivate: [ActivateGuard] },
			{ path: 'feedback', component: AgentFeedBackComponent, canActivate: [ActivateGuard] },
			{ path: 'agent_activity', component: ActivityComponent, canActivate: [ActivateGuard] }
		]
	},
	{
		path: 'agentPluginPC', component: PluginComponent,
		//path: 'agentPlugin', component: DesktopDashboardComponent,
		// Create new login pages with contiinex ui 
		children: [
			{ path: 'home', component: HomeAgentPluginComponent },
			{ path: 'work-slot', component: WorkSlotAgentPluginComponent },
			{ path: 'call-history', component: CallHistoryAgentPluginComponent },
			{ path: 'earnings', component: EarningsAgentPluginComponent },
			{ path: 'face', component: FaceRecognizeComponent },
			{ path: 'assessment', component: AssesmentComponent },
			{ path: 'profile', component: ProfilePluginComponent },
			{ path: 'chat', component: ChatAgentComponent },
			{ path: 'text', component: ChatAgentComponent },
			{ path: 'whatsapp', component: ChatAgentComponent },
			{ path: 'contacts', component: ContactCallPluginComponent },
		]
	},
	{ path: 'register', component: ContiinexRegisterNewuiComponent },
	{ path: 'register/:id', component: ContiinexRegisterNewuiComponent },
	{ path: 'registered', component: RegisterAgentComponent },
	{ path: 'registered/:id', component: RegisterAgentComponent },
	{ path: 'agent-login', component: LoginAgentComponent },
	{ path: 'onboarding', component: OnboardingGetStartedComponent },
	{ path: 'onboarding_process_page1', component: OnboardingProcessPage1Component },
	{ path: 'onboarding_process_page2', component: OnboardingProcessPage2Component },
	{ path: 'onboarding_process_page3', component: OnboardingProcessPage3Component },
	{ path: 'onboarding_process_page4', component: OnboardingProcessPage4Component },
	{ path: 'onboarding_process_page5', component: OnboardingProcessPage5Component },
	{ path: 'onboarding_process_page6', component: OnboardingProcessPage6Component },
	{ path: 'onboarding_process_page7', component: OnboardingProcessPage7Component },
	{
		path: 'demo-portal', component: DemoPortalComponent,
		children: [
			{ path: 'demo-portal-dashboard', component: DemoPortalDashboardComponent },
			{ path: 'crc-call', component: DemoPortalCrcCallComponent },
			{ path: 'demo-portal-setting', component: DemoPortalSettingComponent },
		]
	},
	{ path: 'whatsapp-dashboard', component: WhatsappDashboardComponent },
	{ path: 'Payment', component: PaymentDashboardComponent },
	{ path: 'reports', component: ContiinexReportsComponent },
	{ path: 'home', component: NewContiinexHomePageComponent },// ContiinexWebApplicationComponent },
	{ path: 'Comnex', component: ContiinexComnexPageComponent },
	{ path: 'Gignex', component: ContiinexGignexPageComponent },
	{ path: 'Ainex', component: ContiinexAinexPageComponent },
	{ path: 'Contactus', component: ContiinexContactusPageComponent },
	//	{ path: 'signin', component: ContiinexWebLoginComponent},
	{ path: 'signin', component: ContiinexLoginNewuiComponent },
	{ path: 'contiinex/faq', component: FaqNewComponent },
	{ path: 'forgot-password/reset', component: ContiinexWebForgotResetPasswordComponent },
	//{path:'desktop-calling',component:DesktopMenuComponent},
	//{ path: 'desktop-calling-dashboard', component: DesktopDashboardComponent,canActivate: [ActivateGuard]},
	{
		path: 'agentPlugin', component: DesktopMenuComponent,

		children: [
			{ path: 'home', component: DesktopCallingNewPageComponent },
			// { path: 'home', component: DesktopDashboardComponent },
			{ path: 'contact-list', component: DesktopContactListPageComponent },
			// { path: 'contact-list', component: ContactListDesktopCallingComponent },
			// { path: 'chat', component: ChatDesktopCallingComponent },
			// { path: 'My-earning', component: MyearningsDesktopCallingComponent },
			//{ path: 'support', component: SupportDesktopCallingComponent },ContactCallPluginComponent
			//{ path: 'settings', component: SettingsDesktopCallingComponent }
			// { path: 'desktopCalling', component: DesktopCallingNewPageComponent },
			// { path: 'contactList', component: DesktopContactListPageComponent },
			{ path: 'campaign-list', component: DesktopCampaignListPageComponent },
		]
	},

	//{ path: 'signin', component: ContiinexWebLoginComponent},
	{ path: 'contiinex/faq', component: FaqNewComponent },
	{ path: 'forgot-password/reset', component: ContiinexWebForgotResetPasswordComponent },
	{ path: 'campaignV2/campaignDetails', component: CampaignDetailsViewPageComponent, canActivate: [ActivateGuard] },


	{ path: 'central-dashboard', component: CentralDashboardComponent, canActivate: [ActivateGuard] },
	{ path: 'central-campaign/campaignDetails', component: CampaignDetailsViewPageComponent, canActivate: [ActivateGuard] },
	{ path: 'central-campaign/campaign', component: CampaignCentralCreatePageComponent, canActivate: [ActivateGuard] },
	{ path: 'central-campaign', component: CentralcampaignComponent, canActivate: [ActivateGuard], children: [] },
	{
		path: 'BI-Dashboard', component: BADashboardComponent, canActivate: [ActivateGuard], children: []
	},



	//contiinex page page


	// { path: 'Comnex-Engage', component: NewContiinexComnexEngagePageComponent },
	{ path: 'Unified-Communication-as-a-Service', component: NewContiinexComnexEngagePageComponent },
	{ path: 'Comnex-Enable', component: NeContiinexComnexEnablePageComponent },
	//{ path: 'Comnex-Experience', component: NewContiinexComnexExperiencePageComponent },
	{ path: 'Quality-Assurance-Automation', component: NewContiinexComnexExperiencePageComponent },
	{ path: 'Contact', component: NewContiinexNewContactPageComponent },
	{ path: 'Company', component: ContiinexCompanyPageComponent },
	{ path: 'WhatsApp-Business-Api', component: NewContiinexWhatsappApiPageComponent },
	{ path: 'Privacy-Policy', component: NewContiinexPrivacyPolicySectionPageComponent },
	{ path: 'Login', component: ContiinexLoginNewuiComponent },
	{ path: 'Register', component: ContiinexRegisterNewuiComponent },
	//comnex router pages

	{ path: 'Profile-Settings', component: ContiinexProfileSettingsComponent, canActivate: [ActivateGuard] },
	{ path: 'contiinex-campaign', component: ContiinexCampaignPageComponent, canActivate: [ActivateGuard] },
	{ path: 'Message-Template', component: ContiinexMessageTemplateComponent, canActivate: [ActivateGuard] },
	{ path: 'Contiinex-sandbox', component: ContiinexSandboxUiComponent },
	{ path: 'Contiinex-Reports', component: ContiinexReports1Component },
	{
		path: 'Dashboard', component: ContiinexDashboardComponent,
		children: [
			{ path: 'project1', component: ProjectOneComponent }
		]
	},
	{ path: 'Shopify-Dashboard', component: ContiinexShopifyDashboardComponent },
	{ path: 'Shopify-Team', component: ContiinexShopifyTeamComponent },
	{ path: 'Shopify-Billing', component: ContiinexShopifyBillingComponent },
	{ path: 'Shopify-Settings', component: ContiinexShopifySettingsComponent },
	{ path: 'Funnel-Dashboard', component: CampaignFunnelDashboardComponent },
	{ path: 'ProcessFlowBuilder', component: ContiinexProcessFlowBuilderComponent },
	{ path: 'Details-View', component: ContiinexDetailsViewComponent },
	{ path: 'Login/Ainex', component: ContiinexLoginNewuiComponent },
	{ path: 'signin', component: ContiinexLoginNewuiComponent },
	//{ path: 'Contiinex/signin', component: ContiinexLoginNewuiComponent},
	{ path: 'Contiinex/Login/Ainex', component: ContiinexLoginNewuiComponent },
	{ path: 'LC-Coordinator', component: LcCordinatorPageComponent },
	{ path: 'settings', loadChildren: ()=> import('./settings/settings.module').then(m => m.SettingsModule)},
	{ path: '**', component: HomeComponent },


];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
