import { Component, OnInit, Inject, ViewChild,ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit {

	passingMessage:any
	showHidePassingMessage:any
	_color:any = "green"
	currentLoader:any
	showHideWaitUpload: boolean = false;
	passbookShowHide:boolean = false;
	docUploadForm: FormGroup = new FormGroup({
		doc_file: new FormControl(''),
		name: new FormControl(''),
		// doc_type: new FormControl(''),
		bank_name: new FormControl(''),
		bank_branch: new FormControl(''),
		ifsc_code: new FormControl(''),
		candidate_address: new FormControl(''),
		doc_value: new FormControl('')
	})
	@ViewChild('fileUpload') _fileUpload: ElementRef;
	showHideUploadMsg:boolean=false;
	api_end_point:any;
	constructor(
		public dailogRef: MatDialogRef<DocUploadComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private API: ApiService,
		private myservice: MyserviceService
	) {
	//	console.log('input data', this.data);
	}

	ngOnInit() {
		this.api_end_point = this.myservice.get_API_End_Point();
	//	console.log('dailogRef', this.dailogRef);
		this.displayPassBook();
	}

	closePopup() {
		this.resetForm();
		this.dailogRef.close();
	}

	changeFile(event) {
		this.showHideUploadMsg=false;
		let filePath = event.target.files[0].name;
		let allowedExtensions = /(\.pdf|\.doc|\.docx|\.xls|\.xlsx|\.jpeg|\.jpg|\.png)$/i;
		if(!allowedExtensions.exec(filePath)){
			//alert('Please upload file having extensions .pdf/.doc/.docx/.xls/.xlsx only.');
			this.showHideUploadMsg=true;
			this._fileUpload.nativeElement.value = "";
			return false;
		}
		const reader = new FileReader();
		if (event.target.files && event.target.files.length) {
			let file:File = event.target.files[0];
		//	console.log('file', file);
			this.docUploadForm.patchValue({
				'doc_file': file,
				'name': file.name
			});
		//	console.log('document change complete');
		} else {
			console.log('no files');
		}
	}
	displayPassBook()
	{
		if(this.data.selected_doc_type== "PASS BOOK")
		{
			this.passbookShowHide = true;
		}
		else{
			this.passbookShowHide = false;
		}	
	}

	uploadDoc() {
		//debugger;
		var regex = /^[A-Za-z0-9 ]+$/;
		this.showHideUploadMsg=false;
	//	console.log('form details', this.docUploadForm);
		let file = this.docUploadForm.get('doc_file').value;
		let file_name = this.docUploadForm.get('name').value;
		if(file_name==""||(this._fileUpload.nativeElement.value == "")){
			this.showHideUploadMsg=true;
		//	alert('Please upload file having extensions .pdf/.doc/.docx/.xls/.xlsx only.');
		this.passingMessage = "Please choose upload file*";
		this._color = "red";
			this._fileUpload.nativeElement.value = "";
				setTimeout(()=>{
				this.passingMessage = "";
				this._color = "";
				this.showHideUploadMsg=false;
			},3000)
			return false;
		}

		// let file_type = this.docUploadForm.get('doc_type').value;
		let file_type = this.data.selected_doc_type;
		let doc_id = this.docUploadForm.get('doc_value').value;
		let  bank_name = this.docUploadForm.get('bank_name').value;
		let  bank_branch = this.docUploadForm.get('bank_branch').value;
		let  ifsc_code = this.docUploadForm.get('ifsc_code').value;
		let candidate_address = this.docUploadForm.get('candidate_address').value;

		//console.log(regex.test(this.docUploadForm.get('doc_value').value));

		if(this.data.selected_doc_type== "PASS BOOK")
		{
			if (!this.docUploadForm.get('doc_value').value) {
				this.passingMessage = "Please enter document id*";
				this._color = "red";
				this.showHideUploadMsg=true;
				setTimeout(()=>{
					this.passingMessage = "";
					this._color = "green";
					this.showHideUploadMsg=false;
				},3000)
				return false;
			}
			if (!this.docUploadForm.get('bank_name').value) {
				this.passingMessage = "Please enter bank_name*";
				this._color = "red";
				this.showHideUploadMsg=true;
				setTimeout(()=>{
					this.passingMessage = "";
					this._color = "green";
					this.showHideUploadMsg=false;
				},3000)
				return false;
			}
			if (!this.docUploadForm.get('bank_branch').value) {
				this.passingMessage = "Please enter bank_branch*";
				this._color = "red";
				this.showHideUploadMsg=true;
				setTimeout(()=>{
					this.passingMessage = "";
					this._color = "green";
					this.showHideUploadMsg=false;
				},3000)
				return false;
			}
			if (!this.docUploadForm.get('ifsc_code').value) {
				this.passingMessage = "Please enter ifsc code*";
				this._color = "red";
				this.showHideUploadMsg=true;
				setTimeout(()=>{
					this.passingMessage = "";
					this._color = "green";
					this.showHideUploadMsg=false;
				},3000)
				return false;
			}
			if (!this.docUploadForm.get('candidate_address').value) {
				this.passingMessage = "Please enter candidate address*";
				this._color = "red";
				this.showHideUploadMsg=true;
				setTimeout(()=>{
					this.passingMessage = "";
					this._color = "green";
					this.showHideUploadMsg=false;
				},3000)
				return false;
			}
			if (regex.test(this.docUploadForm.get('doc_value').value) == false) {
				this.passingMessage = "Special Characters not allowed*";
				this._color = "red";
				this.showHideUploadMsg=true;
				setTimeout(()=>{
					this.passingMessage = "";
					this._color = "green";
					this.showHideUploadMsg=false;
				},3000)
				return false;
			}
			this.showHideWaitUpload = true;
			//console.log(this.data)
			let formData = new FormData();
			formData.append('attachment', file, file.name.replace(/\s/g, '').substring(0, 30));
			formData.append('file_name', file.name.replace(/\s/g, '').substring(0, 30));
			formData.append('doc_type', file_type);
			formData.append('applicant_id', this.data.applicant_id);
			formData.append('applicant_email', this.data.applicant_email);
			formData.append('doc_value', doc_id);
			formData.append('agent_id', this.data.sl_no);
			formData.append('doc_Bank',bank_name);
			formData.append('doc_IFSC_Code',ifsc_code);
			formData.append('doc_Cadidate_Address',candidate_address);
			formData.append('doc_Bank_Branch',bank_branch);
					//console.log(formData);
		this.API.documentUpload(formData,this.api_end_point).subscribe((res:any) => {
			this.showHideWaitUpload = false;
			if (res.errors) {
				this.showHideUploadMsg = true;
				this.passingMessage = res.errors;
				this._color = "red"
			} else {
				this.showHideUploadMsg = true;
				this.passingMessage = res.success;
				this._color = "green"
			}
			setTimeout(()=>{
				this.showHideUploadMsg = false;
				this.passingMessage = "";
				this._color = "green";
				this.closePopup();
			},2000)
			
		}, (err) => {
				alert('Error in the Upload')
				this.showHideWaitUpload = false;
		})
		}else{
			if (!this.docUploadForm.get('doc_value').value) {
				this.passingMessage = "Please enter document id*";
				this._color = "red";
				this.showHideUploadMsg=true;
				setTimeout(()=>{
					this.passingMessage = "";
					this._color = "green";
					this.showHideUploadMsg=false;
				},3000)
				return false;
			}
			if (regex.test(this.docUploadForm.get('doc_value').value) == false) {
				this.passingMessage = "Special Characters not allowed*";
				this._color = "red";
				this.showHideUploadMsg=true;
				setTimeout(()=>{
					this.passingMessage = "";
					this._color = "green";
					this.showHideUploadMsg=false;
				},3000)
				return false;
			}
			this.showHideWaitUpload = true;
			//console.log(this.data)
			let formData = new FormData();
			formData.append('attachment', file, file.name.replace(/\s/g, '').substring(0, 30));
			formData.append('file_name', file.name.replace(/\s/g, '').substring(0, 30));
			formData.append('doc_type', file_type);
			formData.append('applicant_id', this.data.applicant_id);
			formData.append('applicant_email', this.data.applicant_email);
			formData.append('doc_value', doc_id);
			formData.append('agent_id', this.data.sl_no);
					//console.log(formData);
		this.API.documentUpload(formData,this.api_end_point).subscribe((res:any) => {
			this.showHideWaitUpload = false;
			if (res.errors) {
				this.showHideUploadMsg = true;
				this.passingMessage = res.errors;
				this._color = "red"
			} else {
				this.showHideUploadMsg = true;
				this.passingMessage = res.success;
				this._color = "green"
			}
			setTimeout(()=>{
				this.showHideUploadMsg = false;
				this.passingMessage = "";
				this._color = "green";
				this.closePopup();
			},2000)
			
		}, (err) => {
				alert('Error in the Upload')
				this.showHideWaitUpload = false;
		})
		}

	}

	resetForm() {
		this.showHideUploadMsg=false;
		this.docUploadForm.reset();
		this._fileUpload.nativeElement.value = "";
	}

}
