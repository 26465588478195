
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu>  	  
    </mat-drawer>
    <div class="gig-sidenav-content">	  
        <div style="padding: 54px 8px;background-color: #f6f6f6 !important;background: #f6f6f6 !important;">
    <div class="row">		
        <div class="col-md-12">            
            <h4 class="titlehead thd1">Payment</h4>
            </div>
    </div>
    <div class="row paymentTabList" >
        <div class="col-md-9" style="padding: 0px 3.5px;">
            <mat-button-toggle-group class="dashPaymentcs" #group="matButtonToggleGroup" [value]="selectedButtonToggleVal"
            (change)="onToggleButtonValChange(group.value)">
            <mat-button-toggle value="dashboard">Dashboard</mat-button-toggle>
            <mat-button-toggle value="configure">Configure</mat-button-toggle>
            <mat-button-toggle value="history">History</mat-button-toggle>
            <mat-button-toggle value="approval">Approval</mat-button-toggle>
            <mat-button-toggle value="events"  style="border-right: solid 1px #0000001f;">Events</mat-button-toggle>
        </mat-button-toggle-group>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 cn-dat">{{getCurrentDate()}}</div>
    </div>

	<div class="row">		
        <div class="col-md-12">
            <div *ngIf="showPaymentDashboard">
                <app-payment-dashboard-table></app-payment-dashboard-table>
            </div>
            <div *ngIf="showPaymentConfigure">
                <app-payment-dashboard-configure></app-payment-dashboard-configure>
                
            </div>
            <div *ngIf="showPaymentHistory">
                <app-payment-dashboard-history></app-payment-dashboard-history>
            </div>
            <div *ngIf="showPaymentApproval">
                <app-payment-dashboard-approval></app-payment-dashboard-approval>
            </div>
            <div *ngIf="showPaymentEvents">
                <app-payment-dashboard-events></app-payment-dashboard-events>
            </div>
        </div>
    </div>


</div>
</div>
    </mat-drawer-container>
