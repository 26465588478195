import { Component, OnInit, ViewChild, HostListener, HostBinding } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MyserviceService} from '../../myservice.service';
import { ApiService } from '../../api.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ContiinexProcessImportDialogComponent } from '../contiinex-process-import-dialog/contiinex-process-import-dialog.component';
import { ContiinexProcessDeleteDialogComponent } from '../contiinex-process-delete-dialog/contiinex-process-delete-dialog.component';
import { ContiinexPreviewDialogComponent } from '../contiinex-preview-dialog/contiinex-preview-dialog.component';
import { getAutomaticTypeDirectiveNames } from 'typescript';
import { Routes, RouterModule, Router,ActivatedRoute, Params  } from '@angular/router';

@Component({
  selector: 'app-contiinex-process-flow-builder',
  templateUrl: './contiinex-process-flow-builder.component.html',
  styleUrls: ['./contiinex-process-flow-builder.component.scss']
})
export class ContiinexProcessFlowBuilderComponent implements OnInit {


  @HostListener('click')
  onclick() {
    var toggler = document.getElementsByClassName("caret");
    var i;
    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function() {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      });
    }
  }




  project_name:any = "";
  //byRadioValue:any="NewFlow";
  showHideNewFlow:boolean=false;
  showHideImportRecord:boolean=false;
  items:any=[
    {id:"Title",experience:"",channel:"",touchpoint:"",event:"",nb_pre_action:"",nb_post_action:"",next_touchpoint:"",action_data:"",pf_next_expected_event:"",nb_state_update:"",pf_funnel_min:"",pf_funnel_max:"",nb_metric_update:"",pf_metric_min:"",pf_metric_max:""}
  ];

  successMsg:any="";
  //showHidepreviewUIfLOW:boolean=true;

  experience:any;
  channel:any;
  touchpoint:any;
event:any;
nb_pre_action:any;
nb_post_action:any;
next_touchpoint:any;
action_data:any;
pf_next_expected_event:any;
nb_state_update:any;
pf_funnel_min:any;
pf_funnel_max:any;
nb_metric_update:any;
pf_metric_min:any;
pf_metric_max:any;

customer_name:string;
projects:any;

showHideForProject:boolean=false;
process_name:any="";
template_name:any="";
showHideCreateFlow:boolean=false;

showHideForProcess:boolean=false;
showHideForTemplate:boolean=false;
_processValue:any="createNewProcess";

enableEdit = false;
enableEditIndex = null;
api_end_point:any;
currentTitle = 'processFlowBuilder';
currentUrl:any;
  constructor(private _service:MyserviceService, private API: ApiService,private dialog: MatDialog,private router: Router) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.projects = this._service.get_loggedin_user().project;
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();

    }


  
  }

getPreItems(){ 
  this.API.getpreItemsData(this.customer_name,this.project_name,this.api_end_point).subscribe((res: any) => {
    let _response= res;
    this.items=_response;  
  }, (err) => {
  })
}

  OnChangeProject(val:any){
    this.showHideNewFlow=false;
    this.showHideImportRecord=false;
    this.showHideForProject=false;
    this.process_name="";
    this.template_name="";
    this.showHideForProcess=false;
    this.showHideForTemplate=false;
    this.showHideCreateFlow=false;
    this._service.set_current_project(val);
    // if(!val || val!="allProjects"){
    //   this.project_name = String(this._service.get_current_project()).toLowerCase();
    //   this.project_name = this.project_name == 'undefined' ? '' : this.project_name;
    //   this.getPreItems();
    //   this.showHideNewFlow=false;
    
    // } 
    if(val=="NewFlow"){
      this.showHideNewFlow=true;
      this.project_name = val;
      this.items=[];
      this.items.push({id:"Title",experience:"",channel:"",touchpoint:"",event:"",nb_pre_action:"",nb_post_action:"",next_touchpoint:"",action_data:"",pf_next_expected_event:"",nb_state_update:"",pf_funnel_min:"",pf_funnel_max:"",nb_metric_update:"",pf_metric_min:"",pf_metric_max:""});
      this.showHideForProject=true;
      this.process_name="createNewProcess";

      this.showHideForProcess=true;
      this.showHideForTemplate=true;
    }

    else if(val=="ImportRecord"){
      this.showHideImportRecord=true;
      this.project_name = val;
    }
    else{
      this.project_name = String(this._service.get_current_project()).toLowerCase();
      this.project_name = this.project_name == 'undefined' ? '' : this.project_name;
      this.getPreItems();
      this.showHideNewFlow=true;
      this.project_name = val;

      this.showHideForProject=true; 


      this.showHideCreateFlow=true; 
     this.showHideForProcess=true;
     this.showHideForTemplate=false;
    }
  }
  // changeRadio(val:any){
  //   this.showHideNewFlow=false;
  //   this.showHideImportRecord=false;
  //   if(val=="NewFlow"){
  //     this.showHideNewFlow=true;
  //   }
  //   if(val=="ImportRecord"){
  //     this.showHideImportRecord=true;
  //   }

  // }
  getCurrentDate(){
    let myDate = new Date();
  let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
  let date = myDate.getDate();
  let month = monthsList[myDate.getMonth()];
  let year = myDate.getFullYear();
  let day = daysList[myDate.getDay()];
  let today = `${date} ${month} ${year}, ${day}`;
  return today;
  }

  importTemplate(){
    const dialogRef = this.dialog.open(ContiinexProcessImportDialogComponent, {
			width: '250px',
			disableClose: true,
			data: '',
		});

		dialogRef.afterClosed().subscribe(result => {
		//	console.log('The dialog was closed', result);
			if (result) {
		
			}
		});
  }


addItem(){
   this.items.push({id:"",experience:"",channel:"",touchpoint:"",event:"",nb_pre_action:"",nb_post_action:"",next_touchpoint:"",action_data:"",pf_next_expected_event:"",nb_state_update:"",pf_funnel_min:"",pf_funnel_max:"",nb_metric_update:"",pf_metric_min:"",pf_metric_max:""});
}
deleteItem(index){
  const dialogRef = this.dialog.open(ContiinexProcessDeleteDialogComponent, {
    width: '300px',
    disableClose: true,
    data: '',
  });

  dialogRef.afterClosed().subscribe(result => {
   // console.log('The dialog was closed', result);
    if (result=="true") {
      this.items.splice(index, 1);
    }
  });
 
}
saveItem(index){
this.successMsg="Data Saved Successfully !";
setTimeout(()=>{
  this.successMsg="";
},4000)
}
previewDialog(){
  const dialogRef = this.dialog.open(ContiinexPreviewDialogComponent, {
    width: '60%',
    height:'90%',
    disableClose: true,
    data: '',
  });

  dialogRef.afterClosed().subscribe(result => {
    //console.log('The dialog was closed', result);
    if (result=="true") {
     
    }
  });
}
// previewBtn(){
//   this.showHidepreviewUIfLOW=true;
// }
// closeBtn(){
// this.showHidepreviewUIfLOW=false;
// }
onChangeNbaPost(val:any){
  this.nb_post_action="";
  this.nb_post_action=val;
if(val=="send_wa_message_to_customer"){

}
if(val=="sms"){

}
if(val=="add_customer_to_auto_dialer"){

}
if(val=="email"){

}
}
indexTracker(index: number, value: any) {
  return index;
}
OnChangeTemplate(val:any){
 // debugger;
  this.showHideCreateFlow=false;
  if(val !=""){
    if(this._processValue=="createNewProcess"){
    this.showHideCreateFlow=true; 
    }
    else{
      this.showHideCreateFlow=false; 
    }
  }
}
OnChangeProcess(val:any){
 // debugger;
  this._processValue=val;
  this.showHideCreateFlow=false; 

  this.template_name="";
  if(val !="createNewProcess"){

  }
}

editClick(e, i) {
 // debugger;
  alert("clicked edit button !!");
  this.enableEdit = true;
  this.enableEditIndex = i;
 // console.log(i, e);
}
saveModify(){
  this.enableEdit = false;
}
}
