import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { AnyNsRecord } from 'node:dns';
@Component({
  selector: 'app-view-details-dialog',
  templateUrl: './view-details-dialog.component.html',
  styleUrls: ['./view-details-dialog.component.scss']
})
export class ViewDetailsDialogComponent implements OnInit {
  elementData:any;
  _channelType:any;
  showHideWhatsapp:boolean=false;
  showHideIVR:boolean=false;
  constructor( public dialogRef: MatDialogRef<ViewDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public Data) { }

  ngOnInit() {
   // debugger;
    this.elementData=this.Data.element;
    this._channelType=this.Data.channelType;
    if(this._channelType=="whatsapp" || this._channelType=="Whatsapp"){
      this.showHideWhatsapp=true;
      this.showHideIVR=false;
    }
    if(this._channelType=="outboundIvr" || this._channelType=="IVR"){
      this.showHideWhatsapp=false;
      this.showHideIVR=true;
    }
    if(this.elementData.content){
      this.elementData.content = this.elementData.content.replaceAll('\\n\\n', '<br>')
    }
    if(this.elementData.call_url){
      setTimeout(()=>{
      document.getElementById('callurl').innerHTML = '<audio controls><source src="'+this.elementData.call_url+'" type="audio/mpeg"></audio>';
    },2000)
    }
    
    
   // console.log(this.elementData);
    
  }

  getEventName(val){
    var event = 'Call Missed';
    switch(val){
      case 'ivr_out_completed':
        event = 'Call Connected';
        break;
      default:
          break;
    }
    return event;
  }
  closeinfo(){
    this.dialogRef.close();
      }
}
