
<div class="row ">		
	<div class="col-md-10 col-sm-10 col-xs-9 mb-3 pt-1 pl-4">
	  <h4 class="titlehead vThdx1" style="position: relative;top: 5px;z-index: 9;">Transfer Data </h4>
	  </div>
	  <div class="col-md-2 col-sm-2 col-xs-3 mb-3 pt-1 pl-4">
		<p class="closeP"><span  style="top: -8px;right: -8px;" (click)="closeDialog()" title="Close"><mat-icon>close</mat-icon></span></p>
	  </div>
	
 </div>


 <div style="display:inline-flex;flex-wrap:nowrap;align-items: center;justify-content: center;">		
	<div class="mb-1" style="min-width:300px;margin-left:115px;">		
        <div class="mb-0">            
                  <mat-form-field appearance="outline" style="width: 100%;">
                      <input matInput placeholder="Search" autocomplete="off" [ngClass]="'vSerchField'" (keyup)="applySearchFilter1($event.target.value)">
                      <mat-icon matSuffix style="font-size: 24px;position: relative;top: 6px;color: #c0cbd6;">search</mat-icon>
                    </mat-form-field>
          </div>
		<div class="mb-0">            
			<label>From</label>
		</div>
		<mat-table [dataSource]="dataSourceA" class="vkTableAgent">
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef  style="flex:0 0 80px;"> Select </mat-header-cell>
                <mat-cell *matCellDef="let element"  style="flex:0 0 80px;" > 
                <mat-checkbox [(ngModel)]="element.from_selected" (change)="from_tableagents(element,$event)" [disabled]="element.to_selected"></mat-checkbox>    
                </mat-cell>
             </ng-container>		
			<ng-container matColumnDef="agentName">
			   <mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Agent Name </mat-header-cell>
			   <mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.name}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="email">
			   <mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Email </mat-header-cell>
			   <mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.email}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="dataValue">
			   <mat-header-cell *matHeaderCellDef style="flex:0 0 90px;">Data </mat-header-cell>
			   <mat-cell *matCellDef="let element" style="flex:0 0 90px;"> {{element.data}} </mat-cell>
			</ng-container>
		
		
			<mat-header-row *matHeaderRowDef=displayedColumnsA style="border-bottom-color: #fff;"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumnsA"></mat-row>
		 </mat-table>
		 <mat-paginator #paginatorA="matPaginator"  [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>


	</div>

     <div class="mb-1 pl-4"  style="min-width:20px;">	
        <!--<div class="vRecShape">
            <div class="vTextItem activeVText">
              <a class="vTextInside"> Transfer </a>
              <div class="arrowShape"></div>
            </div>
    </div>-->
</div> 
    <div class="mb-1" style="min-width:300px;">		
        <div class="mb-0">            
                  <mat-form-field appearance="outline" style="width: 100%;">
                      <input matInput placeholder="Search" autocomplete="off" [ngClass]="'vSerchField'" (keyup)="applySearchFilter2($event.target.value)">
                      <mat-icon matSuffix style="font-size: 24px;position: relative;top: 6px;color: #c0cbd6;">search</mat-icon>
                    </mat-form-field>
          </div>
		  <div class="mb-0">            
			<label>To</label>
		</div>
		<mat-table [dataSource]="dataSourceB" class="vkTableAgent">
            <ng-container matColumnDef="select" >
                <mat-header-cell *matHeaderCellDef  style="flex:0 0 80px;"> Select </mat-header-cell>
                <mat-cell *matCellDef="let element"  style="flex:0 0 80px;" > 
                <!-- <mat-checkbox [(ngModel)]="element.to_selected" (change)="to_agents(element,$event)" [disabled]="getcurrentstate(element.id)"></mat-checkbox>     -->
				<mat-checkbox [(ngModel)]="element.to_selected" (change)="to_agents(element,$event)" [disabled]="element.from_selected"></mat-checkbox>    
                </mat-cell>
             </ng-container>		
			<ng-container matColumnDef="agentName">
			   <mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Agent Name </mat-header-cell>
			   <mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.name}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="email">
			   <mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Email </mat-header-cell>
			   <mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.email}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="dataValue">
			   <mat-header-cell *matHeaderCellDef style="flex:0 0 90px;">Data </mat-header-cell>
			   <mat-cell *matCellDef="let element" style="flex:0 0 90px;"> {{element.data}} </mat-cell>
			</ng-container>
		
		
			<mat-header-row *matHeaderRowDef=displayedColumnsB style="border-bottom-color: #fff;"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumnsB" ></mat-row>
		 </mat-table>
		 <mat-paginator #paginatorB="matPaginator"  [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>


	</div>
</div>

<div style="display: flex;flex-wrap: wrap;justify-content: flex-end;">  
    <div style="width:185px">
        <button class="vbtnxk" style="color: #8994a1;" (click)="cancelBtn()">CANCEL</button>
    </div>
    <div style="width:185px">
        <button class="vbtnxk" (click)="transferDoneBtn()"> DONE </button>
    </div>
  </div>
  <div id="cover-spin" *ngIf="currentLoader"></div>