import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../../api.service';
import {MyserviceService} from '../../myservice.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
new_password:string = "";
confirm_password:string = "";
showLoader:boolean=false;
msg:string = "";
errorMsg:string = "";
api_end_point:any;
constructor(private route: ActivatedRoute,private API: ApiService,private _service:MyserviceService) { }

ngOnInit(): void {
	this.api_end_point = this._service.get_API_End_Point();
  console.log(this.route.snapshot.queryParamMap.get("token"))
}
omit_special_char(event)
  {   
    let k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k != 32); 
  }
resetPasswordSubmit(){
  this.errorMsg = "";
  
  if(this.new_password == ""){
    this.errorMsg = "Password is empty";
    return;
  }
  if(this.new_password.length <5 ){
    this.errorMsg = "Password length should be greater than 5.";
    return;
  }
  if( this.confirm_password == ""){
    this.errorMsg = "Confirm Password is empty";
    return;
  }
    if(this.confirm_password.length<5){
    this.errorMsg = "Confirm Password length should be greater than 5.";
    return;
  }
  if(this.new_password != this.confirm_password){
    this.errorMsg = "Password do not match";
    this.new_password = "";
    this.confirm_password = "";
    return;
  }
  var obj = {} as any;
            obj.new_password = this.new_password;
            obj.confirm_password = this.confirm_password;
  this.API.changePassword(obj,this.route.snapshot.queryParamMap.get("token"),this.api_end_point ).subscribe((res: any) => {
    this.new_password = "";
    this.confirm_password = "";
    if (res.errors) {
      this.errorMsg = res.errors;
    }else{
      this.msg = "Your password has been successfully changed.";
      setTimeout(()=>{
        window.location.href = window.location.origin + "/signin";
      }, 2000)
    } 
  }, (err) => {
    this.new_password = "";
     this.confirm_password = "";
     this.errorMsg = "Something went wrong we couldnt not fetch your details";
  });
}
}