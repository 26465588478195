import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orchestration-available-agent-template',
  templateUrl: './orchestration-available-agent-template.component.html',
  styleUrls: ['./orchestration-available-agent-template.component.scss']
})
export class OrchestrationAvailableAgentTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
