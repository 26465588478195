
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, ObjectUnsubscribedError, Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router'
import * as _ from 'underscore';
import * as moment from 'moment';
import { RtddataService } from '../rtdData.service';
import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';
import { OjtAgentInfoComponent } from '../ojt-agent-info/ojt-agent-info.component';
import { MatDialog } from '@angular/material/dialog';
import { CentralDashboardAgentsPageDialogComponent } from '../central-dashboard-agents-page-dialog/central-dashboard-agents-page-dialog.component'
import { ApiService } from '../api.service';
import { filter } from 'underscore';
import { async } from 'rxjs/internal/scheduler/async';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
	selector: 'app-central-dashboard-active-time-page',
	templateUrl: './central-dashboard-active-time-page.component.html',
	styleUrls: ['./central-dashboard-active-time-page.component.scss']
})
export class CentralDashboardActiveTimePageComponent implements OnInit {

	private subscription: Subscription = new Subscription();
	_fetchData: any = [];
	sortedData: BehaviorSubject<[]> = new BehaviorSubject([]);
	private utils = new Utils();
	zsmData: any = [];
	rsmData: any = [];
	asmData: any = [];
	AllData: any = [];
	selecteBtnSM: any;
	selectedDate: any = "Today";
	showHideDateRangeDiv: boolean = false;
	isASM_selected: boolean = false;
	showRolesTabs$ = this.service.CENTRAL_BI_ROLES.asObservable();
	currentLoader: boolean = false;
	nameSearch: any = "";
	rangeDate = new FormGroup({
		startDate: new FormControl(),
		endDate: new FormControl(),
	});
	ddList: BehaviorSubject<any> = new BehaviorSubject([]);
	ddListSelected = [];

	@ViewChild('selectZSM') selectZSM: MatSelect;
	allSelectedZsm = false;
	@ViewChild('selectRSM') selectRSM: MatSelect;
	allSelectedRsm = false;
	@ViewChild('selectASM') selectASM: MatSelect;
	allSelectedAsm = false;
	@ViewChild('selectUser') selectUser: MatSelect;
	toggleMasterSelection: boolean = true;
	stage_setting_access: boolean = false;
	maxDate = new Date();
	api_end_point: any;
	userid: any;
	id: any;
	callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);

  myUser: any;
  _data: any;
  start_date:any;
  end_date:any;

	constructor(private service: MyserviceService, private router: Router, private rtd: RtddataService, private dialog: MatDialog, private API: ApiService) { }

	ngOnInit() {
    this.selectedDate= "Today";
		var Stagesettings_details;
		Stagesettings_details = this.service.getSettingsWithKeyValue('web', 'name', 'rtd_asm_dashboard');
		this.api_end_point = this.service.get_API_End_Point();
		this.userid = this.service.get_loggedin_user();
    this.myUser = this.service.get_loggedin_user();
		let obj1 = {
			groupid: this.userid.groupid
		}
		//console.log(Stagesettings_details,"\n",this.userid.groupid);
		if (Stagesettings_details == null) {
			this.API.get_groupid_settingsAPI(obj1, this.api_end_point).subscribe(
				(res: any) => {
					 ////debugger;
					if (res.error) {
						console.log(res.error);
					}
					else {
						console.log(res.success);
						console.log(res.success.web[0].name)
					}
				})
		}
		else {
			if (Stagesettings_details.definition == "on") {
				this.stage_setting_access = true;
			}
		}
		setInterval(() => {
			this.battleInit();
		}, (1000 * 60 * 5));
		this.getRtdValues();
		this.subscription.add(
			this.showRolesTabs$.subscribe(roles => {
				console.log(roles);
				this.selecteBtnSM = roles[0];
				this.id = this.battleInit();
				//this.changeddlList();
				setTimeout(()=>{
					this.smChangeToggle(this.selecteBtnSM);
				},2000);
			})
		)

		this.subscription.add(
			this.rtd.zsmData.subscribe(zsmData => {
				////debugger;
				console.log('zsm data', zsmData);
				this.AllData.push(zsmData);
				this.zsmData = zsmData;
				this.updateFetchData(this.selecteBtnSM);
			})
		)
		this.subscription.add(
			this.rtd.rsmData.subscribe(rsmData => {
				////debugger;
				console.log('rsm data', rsmData);
				this.AllData.push(rsmData);
				this.rsmData = rsmData;
				this.updateFetchData(this.selecteBtnSM);
			})
		)
		this.subscription.add(
			this.rtd.asmData.subscribe(asmData => {
				////debugger;
				console.log('asm data', asmData);
				this.AllData.push(asmData);
				this.asmData = asmData;
				console.log("asm-data", JSON.stringify(this.asmData));
				this.updateFetchData(this.selecteBtnSM);
			})
		)
		//console.log("--all-data--", JSON.stringify(this.AllData));

    this._data = {};
		let centraldata;
		this._data.ParentChildchecklist = [];
		//console.log("----"+JSON.stringify(this.myUser));
		var obj = {
			role: this.myUser.role,
			role_id: this.myUser.groupid
		}
		//console.log("--2-" + JSON.stringify(obj))
		this.API.getcenteralgroupid(obj,this.api_end_point).subscribe((res: any) => {
			if (res.errors) {
				//console.log(res.errors);
			} else {
				//console.log("--3-" + JSON.stringify(res.success))
				let centraldata = res.success;
        for (var c in centraldata) {
					//console.log(centraldata[c].center_name);
					var obj;

						obj = {
							id: centraldata[c].id, groupid: centraldata[c].groupid, center_name: centraldata[c].center_name, ASM:centraldata[c].asm_name, RSM: centraldata[c].rsm_name,  ZSM:centraldata[c].zsm_name,TotalAgents: centraldata[c].user_count	}
						this._data.ParentChildchecklist.push(obj);
				}
        console.log(JSON.stringify(this._data.ParentChildchecklist));
      }
    })


	}

	getRtdValues() {
		var dummyallData = this.AllData;
		console.log('tlbase', dummyallData);
		let arr = [];
		for (let tl in dummyallData) {
			var row = JSON.stringify(dummyallData[tl]);
			//console.log("--2--", JSON.stringify(row));
			let obj: any = {};
			obj.title = tl;
			obj.rData = [];
			for (var i in dummyallData[tl]) {
				let obj: any = {};
				obj.idleTime = dummyallData[tl][i]['rtd_idletime_count'] //this.utils.getDivision(dummyallData[tl][i]['rtd_idletime_count'], dummyallData[tl][i]['rtd_signedin_count'])
				obj.waitTime = dummyallData[tl][i]['rtd_waittime_count'] //this.utils.getDivision(dummyallData[tl][i]['rtd_waittime_count'], dummyallData[tl][i]['rtd_signedin_count'])
				obj.spokenTime = dummyallData[tl][i]['rtd_spokentime_count'] //this.utils.getDivision(dummyallData[tl][i]['rtd_spokentime_count'], dummyallData[tl][i]['rtd_signedin_count'])
				obj.wrapTime = dummyallData[tl][i]['rtd_wraptime_count']  //this.utils.getDivision(dummyallData[tl][i]['rtd_wraptime_count'], dummyallData[tl][i]['rtd_signedin_count'])
				obj.activeTime = dummyallData[tl][i]['rtd_activetime_count'] //this.utils.getDivision(dummyallData[tl][i]['rtd_activetime_count'], dummyallData[tl][i]['rtd_signedin_count'])
				//console.log('obj', obj);
				//arr.push(obj);
			}
			arr.push(obj);
		}
		//console.log('after add data', JSON.stringify(arr));
		this.formTable(arr);
	}
	formTable(arr?) {
		this._fetchData = []
		if (arr && arr.length) {
			this._fetchData = arr;
		}
		this.sortedData.next(this._fetchData.slice());
	}
	changeddlList() {
		this.subscription.add(
			this.service.ddlSelection.subscribe(ddlData => {
				// console.log('printing ddl Data', ddlData);
				setTimeout(()=>{
					this.ddList.next(ddlData);
					this.updateFetchData(this.selecteBtnSM);
				},1000)

				// console.log('ddl List', this.ddList.getValue());
			})
		)
	}
	sortData(sort: Sort) {
		const data = this.sortedData.getValue();
		if (!sort.active || sort.direction === '') {
			return;
		}

		const arr = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'ZSM':
					return this.compare(a.name, b.name, isAsc);
				case 'idleTime':
					return this.compare(a.idleTime, b.idleTime, isAsc);
				case 'waitTime':
					return this.compare(a.waitTime, b.waitTime, isAsc);
				case 'spokenTime':
					return this.compare(a.spokenTime, b.spokenTime, isAsc);
				case 'wrapTime':
					return this.compare(a.wrapTime, b.wrapTime, isAsc);
				case 'activeTime':
					return this.compare(a.activeTime, b.activeTime, isAsc);
				case 'diallcount':
					return this.compare(a.diallcount, b.diallcount, isAsc);
				case 'signedIn':
					return this.compare(a.signedIn, b.signedIn, isAsc);
				default:
					return 0;
			}
		});
		this.sortedData.next(arr);
	}

	smChangeToggle(val: any) {

		// console.log('value on click', val);
		this.selecteBtnSM = val;
		this.service.SELECTED_BI_ROLE.next(val);
		this.selectedDate = "";
		this.nameSearch = "";
		this.changeddlList();

		this.rangeDate.reset();
		this.showHideDateRangeDiv = false;

		this.updateFetchData(val)
	}
	async updateFetchData(val) {
		//this._fetchData="";
		if (val == "ZSM") {
			this.isASM_selected = false;
      this.selectedDate ="Today";
			this._fetchData = await this.modifiedValues(this.zsmData, 'zsm')
		}
		else if (val == "RSM") {
			this.isASM_selected = false;
      this.selectedDate ="Today";
			this._fetchData = await this.modifiedValues(this.rsmData, 'rsm')

		}
		else if (val == "ASM") {
			this.isASM_selected = true;
      this.selectedDate ="Today";
			this._fetchData = await this.modifiedValues(this.asmData, 'asm');
		}
		this.sortedData.next(this._fetchData);
	}

	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	getTotalValue(variable: string) {
		//////debugger;
		let total = 0;
		const values = this.sortedData.getValue();
		//console.log(JSON.stringify(values));
		if (values && values.length) {
			for (let j = 0; j < values.length; j++) {
				if (values[j]['visible']) {
					//console.log(values[j]['id']);
					if (!values[j][variable]) {
						total = total+ 0;
					}else{
						//console.log(values[j]['id'],"---",values[j][variable]);
						total = total+ Number(values[j][variable]);
						//console.log(total);
					}
				}
			}
		}
		//console.log(variable, "---", total);
		return total;
}
modifiedValues(data, role) {
	// console.log('modified values data', data);
	let arr = [];
	const ddlList = this.ddList.getValue()[role];
	//console.log('printing ddList', JSON.stringify(ddlList));
	for (var i in data) {
		let obj: any = {};
		if (ddlList && ddlList.hasOwnProperty(i)) {
			obj.name = ddlList[i].name;
			obj.visible = ddlList[i].visible;
			obj.id = ddlList[i].id;
			obj.idleTime = data[i]['rtd_idletime_count'] //this.utils.getDivision(data[i]['rtd_idletime_count'], data[i]['rtd_signedin_count'])
			obj.waitTime = data[i]['rtd_waittime_count'] //this.utils.getDivision(data[i]['rtd_waittime_count'], data[i]['rtd_signedin_count'])
			obj.spokenTime = data[i]['rtd_spokentime_count'] //this.utils.getDivision(data[i]['rtd_spokentime_count'], data[i]['rtd_signedin_count'])
			obj.wrapTime = data[i]['rtd_wraptime_count'] //this.utils.getDivision(data[i]['rtd_wraptime_count'], data[i]['rtd_signedin_count'])
			obj.activeTime = data[i]['rtd_activetime_count'] //this.utils.getDivision(data[i]['rtd_activetime_count'], data[i]['rtd_signedin_count'])
			obj.usercount = data[i]['usercount'] || 0;
			obj.diallcount = data[i]['rtd_dialled_count']
			obj.signedIn = data[i]['rtd_signedin_count']
			// console.log('obj', obj);

      obj.totalDataAvailable = (data[i]['rtd_data_pushed_count'] > 0) ? data[i]['rtd_data_pushed_count'] : 0; //data[i]['rtd_pd_data_pushed_count']+data[i]['rtd_pd_national_data_pushed_count']);
      obj.nationalCampaign = (data[i]['rtd_national_data_pushed_count'] > 0) ? data[i]['rtd_national_data_pushed_count'] : 0; //+data[i]['rtd_pd_national_data_pushed_count']);
      obj.localCampaign = (data[i]['rtd_local_data_pushed_count'] > 0) ? data[i]['rtd_local_data_pushed_count'] : 0; //-data[i]['rtd_national_data_pushed_count']); data[i]['rtd_pd_data_pushed_count'])-data[i]['rtd_pd_national_data_pushed_count']
      obj.referenceCustomers = (data[i]['rtd_ref_customer_count'] > 0) ? data[i]['rtd_ref_customer_count'] : 0;
      obj.manualDialed = (data[i]['rtd_manual_consumed_count'] > 0) ? data[i]['rtd_manual_consumed_count'] : 0;
      obj.dataUtilization = this.utils.getPercentage(data[i]['rtd_new_data_dialled_count'],data[i]['rtd_data_pushed_count']);
      obj.Utilization = data[i]['rtd_new_data_dialled_count'];
      obj.pushed_Count=data[i]['rtd_data_pushed_count']
      obj.followUpsMissed = data[i]['rtd_followup_data_count'];
      obj.totalDialed = data[i]['rtd_dialled_count'];
      obj.newDataDialed = data[i]['rtd_new_data_dialled_count'];
      obj.followUpDialed = data[i]['rtd_flp_data_dialled_count'];
      obj.connected = this.utils.getPercentage(data[i]['rtd_contacted_count'], data[i]['rtd_dialled_count']);
      obj.connected_count = data[i]['rtd_contacted_count'];
      obj.dialled_count = data[i]['rtd_dialled_count'];
      obj.totalContacted = data[i]['rtd_contacted_count'];
      obj.dnc = data[i]['rtd_dnd_count'];
      obj.wrongNumber = data[i]['rtd_wrong_number_dialed_count'];
      obj.customer_contacted = data[i]['rtd_unique_customer_contacted_count'];
      obj.rnr = data[i]['rtd_rnr_data_count'];
      obj.idleTime = data[i]['rtd_idletime_count']
      obj.waitTime = data[i]['rtd_waittime_count']
      obj.spokenTime = data[i]['rtd_spokentime_count']
      obj.wrapTime = data[i]['rtd_wraptime_count']
      obj.activeTime = data[i]['rtd_activetime_count']
      obj.usercount = data[i]['usercount'] || 0;
      obj.diallcount = data[i]['rtd_dialled_count']
      obj.signedIn = data[i]['rtd_signedin_count']
      obj.loggedIn = this.utils.getPercentage(data[i]['rtd_signedin_count'], data[i]['usercount'], 1)
      obj.agentsalive_count = data[i]['rtd_agentsalive_count']
      obj.totalLoggedIn = data[i]['rtd_signedin_count'] || 0
      obj.liveIn = this.utils.getPercentage(data[i]['rtd_agentsalive_count'], data[i]['rtd_signedin_count'], 1)
      obj.totalLive = data[i]['rtd_agentsalive_count'] || 0
      obj.dialperagent = this.utils.getDivision(data[i]['rtd_dialled_count'],data[i]['rtd_signedin_count'])

			arr.push(obj);
		}
	}
	return arr;
}
dateChangeDDL(val: any) {
  this.showHideDateRangeDiv = false;
  if (val == "Yesterday") {
    debugger;
    this.showHideDateRangeDiv = false;
    this.selectedDate = "Yesterday";
    let start_time = '01:30';
    let dateRangeStart =  moment().subtract(1, "days").format("DD-MM-YYYY");
    let dateRangeEnd =moment().subtract(0, "days").format("DD-MM-YYYY");
    let yesterday_startdate = moment(dateRangeStart + 'T' + start_time, 'DD-MM-YYYYTHH:mm:ss');
    console.log(yesterday_startdate.format('DD-MM-YYYYTHH:mm:ss'))
    let yesterday_enddate = moment(dateRangeEnd + 'T' + start_time, 'DD-MM-YYYYTHH:mm:ss');
    console.log(yesterday_enddate.format('DD-MM-YYYYTHH:mm:ss'))

    // let yesterday_startdate = moment().add(0, 'days').startOf("day").format('YYYY-MM-DDTHH:mm:ss');
    // let yesterday_enddate = moment().add(0, 'days').endOf("day").format('YYYY-MM-DDTHH:mm:ss');
    console.log(yesterday_startdate,"---",yesterday_enddate);
      this.start_date = yesterday_startdate.format('YYYY-MM-DDTHH:mm:ss');
      this.end_date = yesterday_enddate.format('YYYY-MM-DDTHH:mm:ss');
  }
  else if (val == "customDate") {
    this.showHideDateRangeDiv = true;
  }

}

dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
  // ////debugger;
    console.log(dateRangeStart.value);
    console.log(dateRangeEnd.value);


    if(dateRangeStart.value && dateRangeEnd.value)
    {
      let start_time = '18:29';

    let StartdateTime = moment(dateRangeStart.value + 'T' + start_time, 'MM/DD/YYYYTHH:mm:ss');
    console.log(StartdateTime.format('YYYY-MM-DDTHH:mm:ss'))
    let enddateTime = moment(dateRangeEnd.value + 'T' + start_time, 'MM/DD/YYYYTHH:mm:ss');
    console.log(enddateTime.format('YYYY-MM-DDTHH:mm:ss'))

    let obj ={
      "start_date":StartdateTime.format('YYYY-MM-DDTHH:mm:ss'),
      "end_date":enddateTime.format('YYYY-MM-DDTHH:mm:ss')
    }
    function openInNewTab(url) {
      window.open(url, '_blank').focus();
    }
    let url_download = 'https://dev.smarter-biz.com/api/v1/bi_dashboard_report?start_date='
    let final_url = url_download.concat(obj.start_date,'&','end_date=',obj.end_date,'&access_token=',this.myUser.access_token)
    console.log("--final-",final_url);
    openInNewTab(final_url);
    }
  }

applyFilter(filterValue: string) {
	filterValue = filterValue.trim();
	filterValue = filterValue.toLowerCase();
	this.nameSearch = filterValue;
}
getSummationData(variable) {
	let total = 0;
	const values = this.sortedData.getValue();
	//console.log(JSON.stringify(values));
	if (values && values.length) {
		for (let j = 0; j < values.length; j++) {
			if (values[j][variable] != null || values[j] != undefined) {

				total += Number(values[j][variable]);
			}
		}
		return total;
	}
}
	battleInit() {

	   let objReq: any = {};
		this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
		this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
		objReq.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		objReq.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		//console.log("---",JSON.stringify(this.groupid));
		this.timer_value_by_API(objReq);
	}

timer_value_by_API(details) {
	this.currentLoader = true;
	var no_of_days = 0;
	let all_groups = this.ddList.getValue()['asm'];
	this.ddList.getValue()['rsm'];
	this.ddList.getValue()['zsm'];
	//console.log(JSON.stringify(this.rtd.asmData));
	//console.log(JSON.stringify(this.ddList.getValue()['asm']));
	let user_list1 = []
	let timer_count_obj: any = {
		"start_date": details.start_date,
		"end_date": details.end_date,
		"users_list": ''
	}
	for (let m in all_groups) {
		//console.log(all_groups[m].id);
		user_list1.push(all_groups[m].id);
	}
	//console.log(JSON.stringify(user_list1));
	timer_count_obj.users_list = user_list1;

	console.log(JSON.stringify(timer_count_obj));
	this.API.timer_count_all_asm(timer_count_obj, this.api_end_point).subscribe((res: any) => {
		////debugger;
		this.currentLoader = false;
		let time = moment().set("hour", 0).set("minute", 0).set("second", 0).format('HH:mm:ss');
		//console.log('printing success', res.success);
		if (res.success) {
			//let All_Data = this.AllData;
			let dummyallData = this.AllData;
			console.log('tlbase', dummyallData);
			let arr = [];
			let zsm_total_ideal_time = 0;
			let zsm_total_wait_time = 0;
			let zsm_total_Active_time = 0;
			let rsm_total_ideal_time = 0;
			let rsm_total_wait_time = 0;
			let rsm_total_Active_time = 0;
			//////debugger;
			let data = []
			let data1 = [];
			for (let i = 0; i < res.success.length; i++) {
				//console.log('data', res.success[i].asm_value);
				//console.log("--3--", JSON.stringify(this.AllData));
				rsm_total_ideal_time = res.success[i].total_time.ideal_time;
				rsm_total_wait_time = res.success[i].total_time.wait_time;
				zsm_total_ideal_time = res.success[i].total_time.ideal_time;
				zsm_total_wait_time = res.success[i].total_time.wait_time;
				let RSM_total_value = {
					total_ideal_time: null,
					total_wait_time: null,
					rsm_id: null,
				}
				let ZSM_total_value = {
					total_ideal_time: null,
					total_wait_time: null,
					zsm_id: null,
				}
				for (let k = (i + 1); k < res.success.length; k++) {
					if (res.success[i].rsm_value == res.success[k].rsm_value) {
						rsm_total_ideal_time = (res.success[i].total_time.ideal_time + res.success[k].total_time.ideal_time);
						rsm_total_wait_time = (res.success[i].total_time.wait_time + res.success[k].total_time.wait_time);
						//console.log("--", rsm_total_ideal_time);
					}
					if (res.success[i].zsm_value == res.success[k].zsm_value) {
						zsm_total_ideal_time = (res.success[i].total_time.ideal_time + res.success[k].total_time.ideal_time);
						zsm_total_wait_time = (res.success[i].total_time.wait_time + res.success[k].total_time.wait_time);
						//console.log("--", rsm_total_ideal_time);
					}
				}
				RSM_total_value.total_ideal_time = rsm_total_ideal_time;
				RSM_total_value.total_wait_time = rsm_total_wait_time;
				RSM_total_value.rsm_id = res.success[i].rsm_value;
				ZSM_total_value.total_ideal_time = zsm_total_ideal_time;
				ZSM_total_value.total_wait_time = zsm_total_wait_time;
				ZSM_total_value.zsm_id = res.success[i].zsm_value;

				let result = data.filter((e) => {
					if (e.rsm_id == RSM_total_value.rsm_id) { return true; }
				})
				console.log(result);
				if (result.length >= 1) { }
				else { data.push(RSM_total_value); }

				let result1 = data1.filter((e) => {
					if (e.zsm_id == ZSM_total_value.zsm_id) { return true; }
				})
				//console.log(result1);
				if (result1.length >= 1) { }
				else { data1.push(ZSM_total_value); }
				for (let tl in this.AllData) {
					for (var j in this.AllData[tl]) {
						if (Number(j) == res.success[i].asm_value) {

							if (this.AllData[tl][j]['rtd_activetime_count']) {

								this.AllData[tl][j]['rtd_activetime_count'] = (this.AllData[tl][j]['rtd_activetime_count'] - this.AllData[tl][j]['rtd_waittime_count']) + res.success[i].total_time.wait_time;
							}
							else if (this.AllData[tl][j]['rtd_activetime_count'] == 0) {
								this.AllData[tl][j]['rtd_activetime_count'] = (this.AllData[tl][j]['rtd_activetime_count'] + res.success[i].total_time.wait_time);
							} else {
								this.AllData[tl][j]['rtd_activetime_count'] = res.success[i].total_time.wait_time;
							}

							if (this.AllData[tl][j]['rtd_waittime_count']) {
								//console.log(this.AllData[tl][j]['rtd_waittime_count'],"--",res.success[i].total_time.wait_time);
								this.AllData[tl][j]['rtd_waittime_count'] = res.success[i].total_time.wait_time;
								//console.log(this.AllData[tl][j]['rtd_waittime_count'],"--",res.success[i].total_time.wait_time);
							} else {
								if (!this.AllData[tl][j]['rtd_waittime_count']) {
									this.AllData[tl][j]['rtd_waittime_count'] = res.success[i].total_time.wait_time;
								}
								//this.sortedData['_value'][j].rData[k].waitTime = resultstring1;
							}

							if (this.AllData[tl][j]['rtd_idletime_count']) {
								this.AllData[tl][j]['rtd_idletime_count'] = res.success[i].total_time.ideal_time;
							} else {
								//this.sortedData['_value'][j].rData[k].idleTime = resultstring;
								if (!this.AllData[tl][j].rtd_idletime_count) {
									this.AllData[tl][j]['rtd_idletime_count'] = res.success[i].total_time.ideal_time;
								}
							}
							//console.log(this.AllData[tl][j]);
						}
					}
				}
				//console.log(JSON.stringify(data));
				for (let l = 0; l < data.length; l++) {
					if (data[l].rsm_id == res.success[i].rsm_value) {
						//console.log(res.success[i].rsm_value,"----",JSON.stringify(res.success[i].total_time));
						console.log(data[l].rsm_id, "---", data[l].total_wait_time, "---", this.AllData[1][data[l].rsm_id]);
						//console.log(data[l].rsm_id, "----", data[l].total_ideal_time, "-rsm-");
						if (this.AllData[1][data[l].rsm_id]['rtd_activetime_count']) {
							if (!Number.isNaN(this.AllData[1][data[l].rsm_id]['rtd_activetime_count']) || this.AllData[1][data[l].rsm_id]['rtd_activetime_count'] != undefined || this.AllData[1][data[l].rsm_id]['rtd_activetime_count'] != null) {
								this.AllData[1][data[l].rsm_id]['rtd_activetime_count'] = (this.AllData[1][data[l].rsm_id]['rtd_activetime_count'] - this.AllData[1][data[l].rsm_id]['rtd_waittime_count']) + data[l].total_wait_time;
							}
							else {
								this.AllData[1][data[l].rsm_id]['rtd_activetime_count'] = data[l].total_wait_time;
							}
						}
						else {
							this.AllData[1][data[l].rsm_id]['rtd_activetime_count'] = data[l].total_wait_time;
							//console.log("---",this.AllData[tl][j]['rtd_activetime_count']);
						}
						//console.log("---",this.AllData[1][RSM_total_value.rsm_id])
						if (this.AllData[1][data[l].rsm_id]['rtd_waittime_count']) {
							//console.log(this.AllData[tl][j]['rtd_waittime_count'],"--",rsm_total_wait_time);
							this.AllData[1][data[l].rsm_id]['rtd_waittime_count'] = data[l].total_wait_time;
							console.log(this.AllData[1][data[l].rsm_id]['rtd_waittime_count'], "--");
						} else {
							this.AllData[1][data[l].rsm_id]['rtd_waittime_count'] = data[l].total_wait_time;
						}
						if (this.AllData[1][data[l].rsm_id]['rtd_idletime_count']) {
							this.AllData[1][data[l].rsm_id]['rtd_idletime_count'] = data[l].total_ideal_time;
						} else {
							this.AllData[1][data[l].rsm_id]['rtd_idletime_count'] = data[l].total_ideal_time;
						}
					}
				}
				//console.log(JSON.stringify(data1));
				for (let m = 0; m < data1.length; m++) {
					if (data1[m].zsm_id == res.success[i].zsm_value) {
						//console.log(res.success[i].rsm_value,"----",JSON.stringify(res.success[i].total_time));
						//console.log(data1[m].zsm_id,"---",data1[m].total_wait_time,"---",this.AllData[0][data1[m].zsm_id]);
						//console.log(data[l].rsm_id, "----", data[l].total_ideal_time, "-rsm-");
						console.log(JSON.stringify(this.AllData[0]));
						console.log(data1[m].zsm_id, "---", data1[m].total_wait_time, "---", this.AllData[0][data1[m].zsm_id]);

						if (this.AllData[0][data1[m].zsm_id]) {
							if (this.AllData[0][data1[m].zsm_id]['rtd_activetime_count']) {
								if (!Number.isNaN(this.AllData[0][data1[m].zsm_id]['rtd_activetime_count']) || this.AllData[0][data1[m].zsm_id]['rtd_activetime_count'] != undefined || this.AllData[0][data1[m].zsm_id]['rtd_activetime_count'] != null) {
									this.AllData[0][data1[m].zsm_id]['rtd_activetime_count'] = (this.AllData[0][data1[m].zsm_id]['rtd_activetime_count'] - this.AllData[0][data1[m].zsm_id]['rtd_waittime_count']) + data1[m].total_wait_time;
								}
								else {
									this.AllData[0][data1[m].zsm_id]['rtd_activetime_count'] = data1[m].total_wait_time;
								}
							}
							else {
								this.AllData[0][data1[m].zsm_id]['rtd_activetime_count'] = data1[m].total_wait_time;
								//console.log("---",this.AllData[tl][j]['rtd_activetime_count']);
							}
							//console.log("---",this.AllData[1][RSM_total_value.zsm_id])
							if (this.AllData[0][data1[m].zsm_id]['rtd_waittime_count']) {
								//console.log(this.AllData[tl][j]['rtd_waittime_count'],"--",rsm_total_wait_time);
								this.AllData[0][data1[m].zsm_id]['rtd_waittime_count'] = data1[m].total_wait_time;
								//console.log(this.AllData[0][data1[m].zsm_id]['rtd_waittime_count'],"--");
							} else {
								this.AllData[0][data1[m].zsm_id]['rtd_waittime_count'] = data1[m].total_wait_time;
							}

							if (this.AllData[0][data1[m].zsm_id]['rtd_idletime_count']) {
								this.AllData[0][data1[m].zsm_id]['rtd_idletime_count'] = data1[m].total_ideal_time;
							} else {
								this.AllData[0][data1[m].zsm_id]['rtd_idletime_count'] = data1[m].total_ideal_time;
							}
							// if (this.AllData[0][data1[m].zsm_id]['rtd_idletime_count']) {
							// 	this.AllData[0][data1[m].zsm_id]['rtd_idletime_count'] = data1[m].total_ideal_time;
							// } else {
							// 	this.AllData[0][data1[m].zsm_id]['rtd_idletime_count'] = data1[m].total_ideal_time;
							// }
						}
					}
				}
				this.AllData = this.AllData;
				//this.formTable(arr);
			}

		}
	})
}

getActiveTime(d: any) {
	var total_seconds = 0;
	var total_minutes = 0
	var total_hours = 0;
	for (let k = 0; k < d.rData.length; k++) {
		//total += Number(d.rData[k].idleTime);
		var seconds = parseInt(d.rData[k].idleTime.split(':')[2]);
		var minutes = parseInt(d.rData[k].idleTime.split(':')[1]);
		var hours = parseInt(d.rData[k].idleTime.split(':')[0]);

		//console.log(hours + "--" + minutes + "---" + seconds);
		if (total_minutes > 60) {
			total_hours = total_hours + 1;
		} else {
			total_minutes = total_minutes + minutes
		}
		if (total_seconds > 60) {
			total_seconds = total_minutes + 1;
		} else {
			total_seconds = total_seconds + seconds;
		}
		total_hours = total_hours + hours;
	}
	// console.log(m)
	var total_time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
	//return `${total_hours}:${total_minutes}:${total_seconds}`;
	return moment(total_time).format('HH:mm:ss');
}
convertToSeconds(hours, minutes, seconds) {
	return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
}
getAllActiveTime() {
	return this.getTotalCountValue('activeTime');
}
getAvgActiveTime() {
	//console.log(JSON.stringify(this.getTotalCountValue('activeTime'))+"---"+JSON.stringify(this.getTotalCountValue('signedIn')));
	//console.log(this.utils.getDivision(this.getTotalCountValue('activeTime'), this.getTotalCountValue('signedIn')));
	return this.utils.getDivision(this.getTotalCountValue('activeTime'), this.getTotalCountValue('signedIn'));
}
getDialledCount() {
	var totaldialled = this.getTotalCountValue('diallcount');
	var rtd_signedin_count = this.getTotalCountValue('signedIn');
	//console.log(totaldialled+"--2--"+rtd_signedin_count);
	return this.utils.getDivision(totaldialled, rtd_signedin_count);
	//return parseFloat(this.utils.getDivision(this.getSummationData('diallcount'),this.getSummationData('rtd_signedin_count')).toFixed(0));
}
getTotalCountValue(variable: string, allowAll ?: boolean) {
	let total = 0;
	const values = this.sortedData.getValue();
	if (values && values.length) {
		for (let j = 0; j < values.length; j++) {
			if (values[j]['visible'] || allowAll) {
				if (!isNaN(values[j][variable])) {
					if (!Number.isNaN(Number(values[j][variable])) || Number(values[j][variable]) != undefined || Number(values[j][variable]) != null) {
						total += Number(values[j][variable]);
					}

				}
			}
		}
		return total;
	}
}
get_time_format(sec) {
	//console.log('printing seconds',sec);
	if (!sec || isNaN(sec)) {
		// alert("Input data is incorrect!");
		return '00:00:00';
	}
	if (sec <= 0) {
		//alert("The second date occurred earlier than the first one!");
		return '00:00:00';
	}
	var minute = 60, hour = 60 * minute, day = 24 * hour;
	var hours = Math.floor(sec / hour);
	sec -= hours * hour;
	var minutes = Math.floor(sec / minute);
	sec -= minutes * minute;
	var seconds = Math.floor(sec);
	var hours_string, minutes_string, seconds_string

	var time = moment().set("hour", hours).set("minute", minutes).set("second", seconds);
	//console.log(time)
	return moment(time).format('HH:mm:ss');
	//return hours+":"+minutes+":"+seconds;
}
actionViewMore(details) {
	//////debugger;
	console.log(this.isASM_selected);
	console.log(JSON.stringify(details));
	if (this.isASM_selected && this.stage_setting_access) {
		//////debugger;
		let groupdata = this.sortedData['_value'];
		//console.log(JSON.stringify(groupdata));
		var dialogRef = this.dialog.open(CentralDashboardAgentsPageDialogComponent, {
			minWidth: '85%',
			hasBackdrop: true,
			data: details
		});

		dialogRef.afterClosed().subscribe(result => {
			//////debugger;
				// this.subscription.add(
				// 	this.showRolesTabs$.subscribe(roles => {
				// 		console.log(roles);
				// 		this.selecteBtnSM = roles[0];
				// 		this.id = this.battleInit();
				// 		//this.changeddlList();
				// 		setTimeout(()=>{
				// 			this.smChangeToggle(this.selecteBtnSM);
				// 		},2000);
				// 	})
				// )
				setTimeout(()=>{
					this.battleInit();
				},2000);
				//this.ngOnInit();
		});
	}
}
extractDataObject(ref, obj) {
  let arr = [];
  for (var i in ref) {
    if (obj.hasOwnProperty(ref[i])) {
      if( ref[i] == 'id' && obj['agent_reference_id']){
        arr.push(obj['agent_reference_id'])
      }else{
        if(obj[ref[i]] == undefined)
        {
          arr.push(0);
        }
        else{
          arr.push(obj[ref[i]])
        }
      }

    } else {
      arr.push('')
    }
  }
  return arr;
}

downloadReport(){
  //////debugger;
  if(this.selectedDate != "Yesterday" && this.selectedDate != "Today")
  {
    function openInNewTab(url) {
      window.open(url, '_blank').focus();
    }
    let url_download = 'https://dev.smarter-biz.com/api/v1/bi_dashboard_report?start_date='
    let final_url = url_download.concat(this.start_date,'&','end_date=',this.end_date,'&access_token=',this.myUser.access_token)
    console.log("--final-",final_url);
    openInNewTab(final_url);
  }
 else if(this.selectedDate == "Yesterday")
 {

      function openInNewTab(url) {
        window.open(url, '_blank').focus();
      }
      let url_download = 'https://dev.smarter-biz.com/api/v1/bi_dashboard_report?start_date='
      let final_url = url_download.concat(this.start_date,'&','end_date=',this.end_date,'&access_token=',this.myUser.access_token)
      console.log("--final-",final_url);
      openInNewTab(final_url);
}
  else{

  this._fetchData = this.modifiedValues(this.asmData, 'asm');
  let asm_data = this._fetchData;
  //console.log(JSON.stringify(asm_data));
  let data = this._data.ParentChildchecklist;
  //console.log("--data-sample--",JSON.stringify(data));

  var data_mapper = {};

  data_mapper = {'center_name':'Center Name', 'ZSM': 'ZSM', 'RSM': 'RSM' , 'ASM': 'ASM', 'TotalAgents': 'Total Agents',
  'AgentsDial%': 'Agents Dial%',
  'ReferenceCustomers': 'Reference Customers',
  'TotalDialled':'Total Dialled',
  'NewDataDialled':'New Data Dialled',
  'FollowupsDialled':'Followups Dialled',
  'ManualDialled':'Manual Dialled',
  'NationalDataDialled':'National Data Dialled',
  'LocalDataDialled':'Local Data Dialled',
  'PredectiveDataDialled':'Predective Data Dialled',
  'NormalDataDialled':'Normal Data Dialled',
  'Dialled/Agent':'Dialled / Agent',
  'TotalContacted':'Total Contacted',
  'Contacted/Agent':'Contacted/Agent',
  'Contact%':'Contact %',
  'DNC':'DNC',
  'RNR':'RNR',
  'WrongNumber':'Wrong Number',
  'NOTINTERESTED':'NOT INTERESTED',
  'PANGenerated':'PAN Generated',
  'PANApproved':'PAN Approved',
  'QDEGenerated':'QDE Generated',
  'QDEApproved':'QDE Approved',
  'QDERejected':'QDE Rejected',
  'QDE/Agent':'QDE/Agent',
  'IdleTime':'Idle Time',
  'WaitTime':'Wait Time',
  'SpokenTime':'Spoken Time',
  'WrapTime':'Wrap Time',
  'ActiveTime':'Active Time'};

  let headers = Object.values(data_mapper);
  if (data && data.length) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Candidate Data');
    worksheet.addRow(headers);
    //this.updateFetchData('ASM');
    //console.log(data);
    //console.log(this.sortedData._value);
    data.forEach((d: any) => {
      asm_data.filter((e)=>{
        //console.log(e.name,"---",d.ASM);
        if(e.name==d.ASM)
        {

          d['AgentsDial%'] = e.totalLoggedIn
          d['TotalContacted'] = e.totalContacted
          d['TotalDialled'] = e.totalDialed
          d['NewDataDialled'] = e.newDataDialed
          d['FollowupsDialled'] = e.followUpDialed
          d['Dialled/Agent'] = e.dialperagent
          d['Contacted/Agent'] = e.connected
          d['TotalContacted'] = e.connected_count
          d['RNR'] = e.rnr
          d['WrongNumber'] = e.wrongNumber
          d['IdleTime'] = this.get_time_format(e.idleTime)
          d['WaitTime'] = this.get_time_format(e.waitTime)
          d['SpokenTime'] = this.get_time_format(e.spokenTime)
          d['WrapTime'] =  this.get_time_format(e.wrapTime)
          d['ActiveTime'] = this.get_time_format(e.activeTime)
          d['NationalDataDialled']=e.totalDialed
          d['ManualDialled']=e.manualDialed
          d['DNC']=0
          d['ReferenceCustomers']=0
          d['PANApproved'] =0
          d['QDEGenerated']=0
          d['QDEApproved']=0
          d['QDERejected']=0
          d['QDE/Agent']=0
          d['PredectiveDataDialled'] = e.totalDialed

          return ;
        }
      })
      //console.log(d);
      // if(data[i])
      // {
      //   let current_row={name:data[i]['name'],totalDataAvailable:data[i]['totalDataAvailable'],nationalCampaign:data[i]['nationalCampaign'],manualDialed:data[i]['manualDialed'],localCampaign:data[i]['localCampaign'],referenceCustomers:data[i]['referenceCustomers']}
      //   worksheet.addRow(current_row)
      // }

      worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
      })
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Dashboard-data-Report.xlsx');
    })

}
}
}
getcolorStatus(val1: any, val2: any) {
	//console.log(val1,"\n",val2);
	if (val1 && val2) {
		return '#0000FF';
	} else {
		return '#000000';
	}
}
navigate_page() {
	this.router.navigateByUrl('/central-campaign/campaign');
}
ngOnDestroy() {
	this.subscription.unsubscribe();
	if (this.id) {
		clearInterval(this.id);
	}
}
}
