import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-iddailog',
  templateUrl: './iddailog.component.html',
  styleUrls: ['./iddailog.component.scss']
})
export class IddailogComponent implements OnInit {

	constructor(
	  public dialogRef: MatDialogRef<IddailogComponent>
  ) { }

  ngOnInit() {
  }
  closePopup(){
    this.dialogRef.close();
  }

}
