import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { empty } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallDialogComponent } from '../../desktop-calling/call-dialog/call-dialog.component';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from 'src/app/myservice.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
	Web, Invitation,
	Inviter,
	InviterOptions,
	Referral,
	Registerer,
	RegistererOptions,
	Session,
	SessionState,
	UserAgent,
	UserAgentOptions,
	InvitationAcceptOptions
} from "../../../assets/sip.js";
import { SIPConnection } from './../desktop-menu/SIPConnection';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import * as io from 'socket.io-client';
import { Time } from '@angular/common';
import { EndSessionDialogComponent } from '../end-session-dialog/end-session-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AudioDialogComponent } from '../audio-dialog/audio-dialog.component';
import { AudioFollowupDialogComponent } from '../audio-followup-dialog/audio-followup-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger } from '@angular/animations';
import { NotificationReadComponent } from '../notification-read/notification-read.component'

@Component({
	selector: 'app-desktop-dashboard',
	templateUrl: './desktop-dashboard.component.html',
	styleUrls: ['./desktop-dashboard.component.scss']
})
export class DesktopDashboardComponent implements OnInit {

	showHideDailpad: boolean = false;
	showHideStartPage: boolean = true;
	showHideCallingScreen: boolean = false;
	showHideCallStatus: boolean = false;
	showHideCalldialling: boolean = false;
	showHideCallEndingScreen: boolean = false;
	showHideStopPage: boolean = false;
	numInput = '0';
	dialogRef: MatDialogRef<any>;
	server = "wss://edge.sip.onsip.com";
	options: any;
	$player: HTMLAudioElement;
	transportOptions: any;
	showHideCallPad: boolean = false;
	showHideStartCall: boolean = false;
	showHideStartCallButton: boolean = true;
	showHideConnecting: boolean = false;
	showHide_Connected: boolean = false;
	subscription: Subscription;
	callStatus: any;
	callSession: any;
	allStatus: string[] = ['establishing', 'accepted'];
	callFlowJsonSections: any[] = [];
	user: any = this.myservice.get_loggedin_user();
	sessionStarted: boolean = false;
	currentFileobj: any = {};
	sioPushUrl: any = "";
	questionnaire: any = "";
	socketId: any = "";
	socketStatusBeforeCheck: any = "";
	socket: any = "";
	customerInfo: any = "";
	widgetUser: any = "";
	subscriptionDial: Subscription;
	_countLeadLength: any;
	_countfollowUpLength: any;
	_auditScore: any;

	_nextBooked: any;
	_availableSlots: any;

	counter1: number;
	timerRef;
	timer: any;
	running: boolean = false;
	salesStatusTrigger: boolean = false;
	startText = 'Start';

	dateModel: any;
	timeModel: any;
	callbacktime: any;
	disposition: any;
	salesSts: any;
	selectedRnrReason: any = '';
	rnroptions: any[] = ['Non Contactable']
	rnrDescription: any;

	@ViewChild('stream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
		this.$player = ref.nativeElement;
	}
	_caller_id: any = "";
	c2cEndPoint: any = "";
	myUser: any;
	isAppAgent: boolean = false;

	showHide_Form_before_DuringForm: boolean = true;
	showHide_Form_After_DuringForm: boolean = false;
	typeTab = 'update';
	showHideUpdate: boolean = false;
	showHideCallBack1: boolean = false;
	showHideCallBack2: boolean = false;
	showHideCallRNR: boolean = false;
	timeBtnValue: any = '';
	timeBtnValueTransformed: any = '';
	customernumber: any;
	customerlead_source: any;
	customerstatus: any;
	showhideLeadDiv: boolean = true;
	_talktime: any;
	_logintime: any;
	_breaktime: any;
	_activetime: any;
	_totalcalls: any;
	_contactedData: any;
	displayedColumns = ['name', 'lead_source', 'status', 'dateandtime', 'recording', 'call'];
	displayedColumns1 = ['name', 'lead_source', 'dateandtime', 'comment'];
	displayedColumns2 = ['name', 'lead_source', 'status', 'dateandtime', 'recording', 'call'];
	dataSource: any = new MatTableDataSource();
	followupSource: any = new MatTableDataSource();
	filledSource: any = new MatTableDataSource();
	@ViewChild('TableOnePaginator') tableOnePaginator: MatPaginator;
	@ViewChild('TableTwoPaginator') tableTwoPaginator: MatPaginator;
	@ViewChild('TableThreePaginator') tableThreePaginator: MatPaginator;

	ngAfterViewInit(): void {
		this.dataSource.paginator = this.tableOnePaginator;
		this.followupSource.paginator = this.tableThreePaginator;
		this.filledSource.paginator = this.tableTwoPaginator;
	}

	current_url: string = null;
	LeadsSelected: boolean = true;
	FollowupSelected: boolean = false;
	FilledFormsSelected: boolean = false;
	filledjsonObject: any = [];
	showhideQuestionAnwer: boolean = false;
	notificationcount: any = 0;
	notifications: any;
	unreadnotification: any;
	breakoption: any = "breakoption";
	countoffilleddata: any;
	fromDate: any;
	toDate: any;
	todayDate: Date = new Date();
	selectedFilter = 'TODAY';
	leadFilter = 'TODAY';
	mergecalldetails: any;
	selectdate: boolean = true;
	leadsdatefrom: any;
	leadsdateto: any;
	showDuringFormError: boolean = false;

	api_end_point:any;
	constructor(private _snackBar: MatSnackBar, private myservice: MyserviceService, private API: ApiService, private utils: Utils, private router: Router, private dialog: MatDialog
	) {
		if (sessionStorage.getItem('sessionStarted') == "true") {
			this.showHideStopPage = true;
			this.showHideStartPage = false;
			this.showHideDailpad = false;
			this.showHideCallStatus = false;
			this.showHideCalldialling = false;
			this.showHideCallEndingScreen = false;
			this.showHideStartCallButton = true;
			this.showHideCallPad = false;
			this.sessionStarted = true;
		}
		this.callStatus = this.myservice.getLastCallStatus();
		if (this.callStatus) {
			console.log("homelast12345", this.callStatus);
			if (this.callStatus.customerInfo) {
				this.customerInfo = this.callStatus.customerInfo;
			}
			if (this.callStatus.callFlowJsonSections) {
				this.callFlowJsonSections = this.callStatus.callFlowJsonSections;

				console.log(78, this.callFlowJsonSections)
			}

			if ((this.callStatus.status == 'establishing' || this.callStatus.status == 'accepted') && !this.callStatus.customerInfo) {
				this.showHideCallingScreen = true;
				this.showHideStartPage = false;
				this.showHideConnecting = true;
				this.showHideCalldialling = true;
				this.showHideCallingScreen = false

				this.showHideStopPage = false;
				this.currentFileobj = {};
			}

			if (this.callStatus.customerInfo && this.callStatus.status != 'end_call' && this.callStatus.status != 'terminated') {
				//this.startTimer();
				this.showHideCallingScreen = false;
				this.showHideStopPage = false;
				this.showHideConnecting = false;
				this.showHideCalldialling = false;
				this.showHide_Connected = true;
				this.showHideCallStatus = true;
				this.showHideCallPad = false;
			}
			// if(obj.status == 'accepted'){
			//   setTimeout(()=>{
			//     this.showHideConnecting = false;
			//     this.showHide_Connected = true;
			//   },10000)
			// }
			if (this.callStatus.status == 'end_call_agent') {
				this.currentFileobj = {};
				// this.dialogRef.close();
				//this.startTimer();
				this.showHideCallEndingScreen = false;
				this.showHideConnecting = false;
				this.showHideCalldialling = false;
				this.showHide_Connected = false;
				this.showHideCallStatus = false;
				this.showHideCallPad = false;
				this.showHideStopPage = true;
			}
		} else {
			this.callStatus = ""

		}
		this.subscription = this.myservice.getCallStatus().subscribe(obj => {
			this.callStatus = obj;
			if (obj) {
				console.log("home12345", obj)
				this.callStatus = obj;
				if (this.callStatus.customerInfo) {
					this.customerInfo = this.callStatus.customerInfo;
				}
				if (this.callStatus.callFlowJsonSections) {
					this.callFlowJsonSections = this.callStatus.callFlowJsonSections;

					console.log(78, this.callFlowJsonSections)
				}

				if (obj.status == 'establishing' && !this.callStatus.customerInfo) {
					this.showHideCallingScreen = true;
					this.showHideStartPage = false;
					this.showHideConnecting = true;
					this.showHideCalldialling = true;
					this.showHideCallingScreen = false
					this.showHideStopPage = false;
					this.currentFileobj = {};
				}

				if (obj.customerInfo && obj.status != 'end_call' && obj.status != 'terminated') {
					//this.startTimer();
					this.showHideCallingScreen = false;
					this.showHideStopPage = false;
					this.showHideConnecting = false;
					this.showHideCalldialling = false;
					this.showHide_Connected = true;
					this.showHideCallStatus = true;
					this.showHideCallPad = false;
				}

				// if(obj.status == 'accepted'){
				//   setTimeout(()=>{
				//     this.showHideConnecting = false;
				//     this.showHide_Connected = true;
				//   },10000)
				// }
				if (obj.status == 'end_call_agent' ) {
					this.currentFileobj = {};
					// this.dialogRef.close();
					//this.startTimer();
					this.showHideCallEndingScreen = false;
					this.showHideConnecting = false;
					this.showHideCalldialling = false;
					this.showHide_Connected = false;
					this.showHideCallStatus = false;
					this.showHideCallPad = false;
					this.showHideStopPage = true;

				}

				if (obj.status == 'terminated') {
					this.currentFileobj = {};
					// this.dialogRef.close();
					//this.startTimer();
					this.showHideCallEndingScreen = false;
					this.showHideConnecting = false;
					this.showHideCalldialling = false;
					this.showHide_Connected = false;
					this.showHideCallStatus = false;
					this.showHideCallPad = false;
					this.showHideStopPage = true;
				}
			} else {
				this.callStatus = ""

			}
		});
	}

	ngOnInit(): void {
		this.api_end_point = this.myservice.get_API_End_Point();
		this.callSession = this.myservice.getLastCallSession();
		this.myUser = this.myservice.get_loggedin_user();
		console.log(this.myUser.access_token);
		if (!this.myUser.sip_id) {
			this.myUser.sip_id = 'krunal@smarterbizkrunal.onsip.com';
			this.isAppAgent = true;
		}
		if (!this.user.sip_id) {
			this.user.sip_id = 'krunal@smarterbizkrunal.onsip.com';
			this.isAppAgent = true;
		}
		// var interval_id = window.setInterval(() => { }, 99999);
		// for (var i = 0; i < interval_id; i++) {
		// 	window.clearInterval(i);
		// }
		// this.myservice.clearCallStatus();
		//debugger;
		let stateSettings: any = this.myservice.get_stage_settings();

		let _inNumberOneDefinition = stateSettings.cloudtelephonyout.filter(v => { return v.name == "innumber1" });
		this._caller_id = _inNumberOneDefinition[0].definition;
		console.log(this._caller_id);

		if (stateSettings && stateSettings.extraoptions) {
			for (let opt of stateSettings.extraoptions) {
				if (opt.name == "c2cendpoint") {
					console.log(opt);
					this.c2cEndPoint = opt.definition;



				}
				if (opt.name == "biometric" && opt.definition == 'true') {
					if (this.user.isVisonEnabled != true) {
						this.router.navigate(['/agentPluginPC/face']);
						return;
					}
				}
				if (opt.name == "siopushurl") {
					this.sioPushUrl = opt.definition;
					console.log(72, this.sioPushUrl);
					//this.socket = io(this.sioPushUrl);
				}
				if (opt.name == "rnrfeedback") {

					let rnrObject = JSON.parse(opt.definition);
					this.rnroptions = this.getParsed(rnrObject.rnr_options);
				}
			}
		}
		// this.initSocket();
		this.getHomeData();
		//this.getDashboardList();
		this.getAgentSlot();
		this.getContacted();
		this.getNotification();

		// this.followupSource.paginator = this.tableThreePaginator;
		// this.dataSource.paginator = this.tableOnePaginator;
		// this.filledSource.paginator = this.tableTwoPaginator;
	}

	ngOnDestroy() {
		// unsubscribe to ensure no memory leaks
		this.subscription.unsubscribe();
	}


	callCustomer(number) {
		var callSession = this.myservice.getLastCallSession();
		if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
			alert("Please Press Start for Calling");
			return;
		}

		if (callSession && (!callSession.internet_speed || callSession.internet_speed < 10)){
			alert("Internet speed slow try later");
			return;
		}
		if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
			alert("Sip connection not available try later")
			return;
		}
		console.log("dd-debug",this.callStatus, number);
		// setTimeout(() => {
		// 	this._snackBar.open("please wait connecting", "close");
		// }, 2000);
		//     var data = {
		//       "telco_partner": "TWILIO",
		//       "agent_number": this.user.phone,
		//       "customer_number": obj.number,
		//       "cli": "+15512617144"
		//   }
		//     this.API.gsmtogsmcall(data, this.user.server_url).subscribe(
		//       (res: any) => {
		//         console.log(res)
		//         if(res.status== "success"){
		//           alert("Dialing...")
		//         }

		//       }, (err) => {
		//         console.log(err)
		//       }
		//     )
		var updateObj = this.callStatus;
		if (updateObj.status == undefined) {

			updateObj.dialNum = number;
			updateObj.dialing = 1;
			console.log("dd-debug20", updateObj)
			this.myservice.updateCallStatus(updateObj);
			updateObj = this.callStatus;
			updateObj.dialing = 2;
			console.log("dd-debug21", updateObj)
			this.myservice.updateCallStatus(updateObj);
		}
	}
	endCallFirst(endSession: boolean = false, triggerRnr: boolean = false): void {

		this.showHideStartPage = false;
		this.showHideCallingScreen = false;
		this.showHideDailpad = false;
		this.showHideCallStatus = false;
		this.showHide_Connected = false;
		this.showHideCalldialling = false;
		this.showHideConnecting = false;
		this.showHideCallEndingScreen = false;
		if(!this.callStatus.showDuringCallForm){
			this.showHide_Form_After_DuringForm = true;
		}
		this.showHideStopPage = true;
		this.salesStatusTrigger = true;
		var QA = [];
		for (let section of this.callFlowJsonSections) {
			var obj1: any = [];
			for (let child of section.child) {
				var temp1: any = { "Q:": child.q, "A:": child.answer || "" };
				if (child.action_type) {
					temp1["action_type:"] = child.action_type
				}

				obj1.push(JSON.stringify(temp1))
				if (child[child.answer]) {
					for (let child2 of child[child.answer]) {
						var temp2: any = { "Q:": child2.q, "A:": child2.answer || "" };
						if (child2.action_type) {
							temp2["action_type:"] = child2.action_type
						}
						obj1.push(JSON.stringify(temp2))
						if (child2[child2.answer]) {
							for (let child3 of child2[child2.answer]) {
								var temp3: any = { "Q:": child3.q, "A:": child3.answer || "" };
								if (child3.action_type) {
									temp3["action_type:"] = child3.action_type
								}
								obj1.push(JSON.stringify(temp3))

							}
						}

					}

				}

			}
			QA = obj1
		}
		console.log(QA);
		let updateObj = this.callStatus;
		updateObj.status = 'end_call_agent';
		if (this.callFlowJsonSections) {
			updateObj.callFlowJsonSections = this.callFlowJsonSections
			updateObj.QA = QA
		}
		const currentTime = moment().utc();
		let salesSts = {
			user_id: this.user.id,
			contact_person: {
				number: ""
			},
			description: "",
			order_potential: "",
			status: "NEW DATA",
			lat: "22.0858725",
			long: "82.1879959",
			start_time: this.callStatus.callStart || currentTime,
			substatus1: "",
			substatus2: "",
			machine_status: "",
			wrapup: 5000,
			lead_source: '',
			appointment_id: "",
			call_end_time: this.callStatus.callEnd || currentTime,
			customkvs: ""
		} as any;
		if (salesSts.start_time != salesSts.call_end_time) {
			salesSts.wrapup = salesSts.call_end_time - salesSts.start_time;
		}
		if (this.callStatus.customerInfo) {
			salesSts.contact_person.number = this.callStatus.customerInfo.customer_number;
			salesSts.contact_person.name = this.callStatus.customerInfo.customer_name;
			salesSts.transactionid = this.callStatus.customerInfo.transactionid;
		}else if(this.callStatus.lastDialled) {
			salesSts.contact_person.number = this.callStatus.lastDialled;
		}
		if (this.callStatus.callFlowJsonSections) {
			let finalObj = {
				experience: this.callStatus.QA
			};
			salesSts.extranotes = JSON.stringify(finalObj)
		}

		console.log("this.salesSts : ", this);
		this.salesSts = salesSts;
		if (this.isAppAgent && updateObj.status == 'end_call_agent') {
			let obj = { "number": this.customerInfo.customer_number, "transactionid": this.salesSts.transactionid, "user_id": this.myUser.id, "disconnect_type": "End Call", "source": "Agent disconnected" };

			this.API.callDisconnect(obj, this.user.server_url).subscribe(
				(res: any) => {

				}, (err) => {
					console.log(err)
				}
			);

			this.showHide_Connected = false;
			this.showHideCallStatus = false;
			this.showHideConnecting = false;
			this.showHideCalldialling = false;
			console.log("dd-debug23", updateObj)
			this.myservice.updateCallStatus(updateObj);

		} else {
			this.showHide_Connected = false;
			this.showHideCallStatus = false;
			this.showHideConnecting = false;
			this.showHideCalldialling = false;
			console.log("dd-debug24", updateObj)
			this.myservice.updateCallStatus(updateObj);
		}
		if (endSession) {
			this.endSession();
		}
		this.showHideStopPage = true;
		console.log("line509", this.customerInfo)
		if (triggerRnr || (!this.customerInfo)) {
			this.typeTab = 'rnr';
			this.clickHighlight('rnr')
			// this.endCall();
			this.showHide_Form_before_DuringForm = false;
			this.showHide_Form_After_DuringForm = true;
			delete updateObj.showDuringCallForm;
			console.log("dd-debug25", updateObj)
			this.myservice.updateCallStatus(updateObj);
		}
	}
	getOjectStringify(obj){
		return JSON.stringify(obj);
	}
	endCall() {
		if (this.typeTab == 'rnr') {
			if (!this.rnrDescription || !this.selectedRnrReason) {
				this.showDuringFormError = true;
				setTimeout(() => {
					this.showDuringFormError = false;
				}, 3000)
				return;
			}
			var obj1 = { Q: "Reason for RNR", A: this.selectedRnrReason }
			var obj2 = { Q: "Description for RNR", A: this.rnrDescription }
			var finalObj = [];
			finalObj.push(JSON.stringify(obj1))
			finalObj.push(JSON.stringify(obj2))
			this.salesSts.extranotes = JSON.stringify(finalObj)
		}
		if(this.typeTab == 'callback1'){
			if (!this.disposition){
				this.showDuringFormError = true;
				setTimeout(() => {
					this.showDuringFormError = false;
				}, 3000)
				return;
			}
		}

		console.log(this.callStatus.status)
		if (this.callStatus.status != 'end_call_agent' && this.callStatus.status != 'terminated'){
			alert("Please end call first");
			return;
		}
		this.showHide_Form_before_DuringForm = true;
		this.showHide_Form_After_DuringForm = false;
		var typeTab = this.typeTab;
		var phoneNumber = this.customerInfo.customer_number;
		// var date = moment(this.dateModel).format('DD/MM/YYYY');
		// var Time1 = moment(this.timeModel).format('HH:mm:ss');

		// var dateTime = moment(date + ' ' + this.timeModel, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.000Z');
		// this.callbacktime = dateTime;

		this.showHideStopPage = true;
		this.showHideStartPage = false;
		this.showHideCallingScreen = false;
		this.showHideDailpad = false;
		this.showHideCallStatus = false;
		this.showHideCalldialling = false;
		this.showHideCallEndingScreen = false;
		if (this.typeTab == 'rnr') {
			this.salesSts.status = 'ALL RNR';
			this.salesSts.substatus1 = '(ACP)';
		}
		if (this.typeTab == 'callback1') {
			this.salesSts.start_time = moment(this.timeBtnValueTransformed || this.callbacktime).utc();
      this.salesSts.created_at = moment().utc();
      this.salesSts.status = 'NEW DATA';
			this.salesSts.substatus1 = '(ACP)';
			this.salesSts.substatus2 = 'CALLBACK';
		}
		if (this.typeTab == 'callback2') {
			this.salesSts.start_time = moment().utc();
			this.salesSts.status = 'NEW DATA(REDIAL)';
		}
		this.API.salesStatus(this.salesSts, this.user.server_url).subscribe(
			(res: any) => {
				this.salesStatusTrigger = false;
				this.salesSts = "";

				this.customerInfo = "";
				this.showHideCallRNR = false;
				console.log("SalesStatus Response : ", res);
				// SIPConnection.reCheckConnection();
				this.leadFilter = 'TODAY';
				this.selectedFilter = 'TODAY';
				this.getContacted();
				this.restFm()
				if (typeTab == 'callback2' && phoneNumber) {
					this.callCustomer(phoneNumber);
				}
				this.myservice.clearCallStatus();
			}, (err) => {
				this.myservice.clearCallStatus();
				this.salesStatusTrigger = false;
				this.salesSts = "";
				this.showHideCallRNR = false;
				this.customerInfo = "";
				// SIPConnection.reCheckConnection();
				this.leadFilter = 'TODAY';
				this.selectedFilter = 'TODAY';
				this.getContacted();
				this.restFm()
				if (typeTab == 'callback2' && phoneNumber) {
					this.callCustomer(phoneNumber);
				}
				console.log(err)
			}
		)
		setTimeout(() =>{
			this.salesStatusTrigger = false;
			this.salesSts = "";
			this.showHideCallRNR = false;
			this.customerInfo = "";
			// SIPConnection.reCheckConnection();
			this.leadFilter = 'TODAY';
			this.selectedFilter = 'TODAY';
			this.getContacted();
			this.restFm();
			this.myservice.clearCallStatus();
		},3000)

		this.typeTab = 'update';
		this.showHide_Connected = false;
		this.showHideCallStatus = false;
		this.showHideConnecting = false;
		this.showHideCalldialling = false;
		this.showHideCallPad = false;
		this.myservice.clearCallStatus();
		// this.customerInfo = "";
		this.widgetUser = "";
		this.timeBtnValueTransformed = "";
		this.callbacktime = "";
		// var interval_id = window.setInterval(() => { }, 99999);
		// for (var i = 0; i < interval_id; i++) {
		// 	window.clearInterval(i);
		// }




	}

	selectRnrReason(value) {
		this.selectedRnrReason = value;
	}

	removeNum() {
		let _num = (document.getElementById('code') as HTMLFormElement).value;
		_num = _num.substring(0, _num.length - 1);
		(document.getElementById('code') as HTMLFormElement).value = _num;
		this.myservice.clearCallStatus();
		this.showHideStartCallButton = true;
		this.showHideCallPad = false;
	}
	clickNumBtn(num: any) {

		let _numLength = ((document.getElementById('code') as HTMLFormElement).value).length;
		if (_numLength == 12) {
			(document.getElementById('code') as HTMLFormElement).value;
			return false;
		}
		(document.getElementById('code') as HTMLFormElement).value = (document.getElementById('code') as HTMLFormElement).value + num.toString();
		console.log((document.getElementById('code') as HTMLFormElement).value);

		var updateObj = this.callStatus;
		updateObj.dialNum = (document.getElementById('code') as HTMLFormElement).value;
		updateObj.dialed = false;
		console.log("dd-debug26", updateObj)
		this.myservice.updateCallStatus(updateObj);
	}

	startCallLoading() {
		//this.showHideCallPad = true;

		this.showHideStartCallButton = false;
		this.showHideStartCall = false;

		if (this.showHideDailpad == true) {
			this.showHideDailpad = false;
			this.showHideStartPage = false;
			this.showHideStopPage = true;
			this.showHideConnecting = false;
			this.showHideCalldialling = false;
			this.showHide_Connected = false;
			this.showHideCallStatus = false;
			this.showHideCallPad = false;
		}
		else {
			this.showHideDailpad = true;
			this.showHideStartPage = false;
			this.showHideStopPage = false
			// this.showHideDailpad = false;
			this.showHideConnecting = false;
			this.showHideCalldialling = false;
			this.showHide_Connected = false;
			this.showHideCallStatus = false;
			this.showHideCallPad = false;
		}
		// setTimeout( () => {
		//    this.showHideConnecting=false;
		//    this.showHideStartCallButton=false;
		//    this.showHideStartCall=false;


		//   //  setTimeout( () => {
		//   //  this.showHide_Connected=true;
		//   //  this.showHideConnecting=false;
		//   //  this.showHideStartCallButton=false;
		//   //  this.showHideStartCall=false;
		//   // }, 8000 )

		//   }, 6000 )
		var updateObj = this.callStatus;
		if (this.callStatus && this.callStatus.dialing && this.callStatus.dialing == 1) {
			this.showHideStopPage = false;
			this.showHideStartPage = false;
			this.showHideStopPage = true;
			updateObj.dialing = 2;
			console.log("dd-debug27", updateObj)
			this.myservice.updateCallStatus(updateObj);
		}
		if (this.callStatus && !this.callStatus.dialing) {
			updateObj.dialing = 1;
			console.log("dd-debug28", updateObj)
			this.myservice.updateCallStatus(updateObj);
		}


	}

	resetDialer() {
		this.showHideDailpad = false;
		this.showHideStartPage = false;
		this.showHideStopPage = true;
		this.showHideConnecting = false;
		this.showHideCalldialling = false;
		this.showHide_Connected = false;
		this.showHideCallStatus = false;
		this.showHideCallPad = false;
		var updateObj = this.callStatus;
		delete updateObj.dialing;
		console.log("dd-debug29", updateObj)
		this.myservice.updateCallStatus(updateObj);
	}

	mddValueChange(event, index, childIndex, mddIndex) {
		console.log(event.checked, index, childIndex, mddIndex)
		if (event.checked) {
			var dd_data = this.callFlowJsonSections[index].child[childIndex].cb_data;
			var total = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer : "";
			if (total == "") {
				total = total + dd_data[mddIndex];
			} else {
				total = total + "," + dd_data[mddIndex];
			}
			this.callFlowJsonSections[index].child[childIndex].answer = total;
			console.log(this.callFlowJsonSections[index].child[childIndex].answer)
		} else {
			var array = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer.split(',') : []
			console.log(mddIndex, array)
			var findIndex = 0;
			for (var i = 0; i < array.length; i++) {
				if (array[i] == this.callFlowJsonSections[index].child[childIndex].cb_data[mddIndex]) {
					findIndex = i;
					array.splice(findIndex, 1);
					this.callFlowJsonSections[index].child[childIndex].answer = array.toString()
					break;
				}
			}
			console.log(array)
		}
		var arrayPrev = this.callFlowJsonSections[index].child[childIndex].answer ? this.callFlowJsonSections[index].child[childIndex].answer.split(',') : [];
		var arrayDD = this.callFlowJsonSections[index].child[childIndex].cb_data;
		var finalArray = [];
		for (var j = 0; j < arrayDD.length; j++) {
			for (var k = 0; k < arrayPrev.length; k++) {
				if (arrayDD[j] == arrayPrev[k]) {
					finalArray.push(arrayDD[j]);
				}
			}
		}
		console.log(finalArray);
		this.callFlowJsonSections[index].child[childIndex].answer = finalArray.toString();
		var updateObj = this.callStatus;
		if (this.callFlowJsonSections) {
			updateObj.callFlowJsonSections = this.callFlowJsonSections
		}
		console.log("dd-debug30", updateObj)
		this.myservice.updateCallStatus(updateObj);
	}
	change_date(event: MatDatepickerInputEvent<Date>, index, childIndex) {
		console.log(this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value), index, childIndex);
		this.callFlowJsonSections[index].child[childIndex].answer = event.value;
	}

	change_time(event: any, index, childIndex) {
		console.log(event.target.value, index, childIndex);
		var dateAdded = this.callFlowJsonSections[index].child[childIndex].answer;
		// dateAdded = moment(dateAdded);
		var timeAdded = event.target.value;
		var splitDate = timeAdded.split(':')
		var finalDate = moment(dateAdded).add(Number(splitDate[0]), 'hours').add(Number(splitDate[1]), 'minutes')
		console.log(finalDate.format());
	}


	changeFile(event, index) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			console.log(event.target.files);
			this.currentFileobj = event.target.files[0];
			let formData = new FormData();
			formData.append('image', event.target.files[0], event.target.files[0].name);
			this.API.uploadAnyFile(formData,this.api_end_point).subscribe(
				(res: any) => {
					this.callFlowJsonSections[0].child[index].answer = res.url;
				}, (err) => {
					console.log(err)
				}
			)
		}
	}
	changeFileLevel2(event, index1, index2, index3, answer1, answer2) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			console.log(event.target.files);
			this.currentFileobj = event.target.files[0];
			let formData = new FormData();
			formData.append('image', event.target.files[0], event.target.files[0].name);
			this.API.uploadAnyFile(formData,this.api_end_point).subscribe(
				(res: any) => {
					this.callFlowJsonSections[0].child[index1].answer1[index2].answer2[index3].answer = res.url;
				}, (err) => {
					console.log(err)
				}
			)
		}
	}

	initSocket() {
		this.socket.on('connect', () => {
			console.log(this.socket);
			this.socketId = this.socket.id;

			this.getHomeData();
			//this.getDashboardList();
			this.getAgentSlot();
			this.getContacted();
			this.getNotification();

			this.socket.emit("registerme", this.user.id);
			this.socket.on("registerme-ack", (msg: any) => {
				// console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
				console.log("socket-registerme-", msg)
			});
			this.socket.on("pushnotif", (type: any, message: any) => {
				// console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
				console.log("pushnotif", message)
				return;
				if (type == 'system alert') {
					this.showHideConnecting = false;
					this.showHideCalldialling = false;
					this.customerInfo = JSON.parse(message).data;
					var updateObj = this.callStatus;
					updateObj.customerInfo = this.customerInfo;
					this.myservice.updateCallStatus(updateObj);
					var interval_id = window.setInterval(() => { }, 99999);
					for (var i = 0; i < interval_id; i++) {
						window.clearInterval(i);
					}
					console.log(this.customerInfo);
					this.customerlead_source = this.customerInfo.lead_source;

					this.customerstatus = this.customerInfo.status;
					if (!this.isAppAgent && updateObj.dialNum == undefined) {
						this.fetchUser(this.customerInfo.customer_number)
					}
					//   setInterval(() => {
					// 	  if (!this.isAppAgent && updateObj.dialNum == undefined){
					// 		  this.fetchUser(this.customerInfo.customer_number)
					// 	  }

					//   }, 3000)
					this.startTimer();
				}

				this.socket.emit("pushnotif-ack", this.user.if, JSON.parse(message).data, "Connecting");

				console.log("socket-pushnotif-", type, message);
			});
			this.socket.on("isalive", (message: any) => {
				// console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);

				this.socket.emit("isalive-ack", this.user.id)
			});
			this.socket.on("message", (message: any) => {
				// console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);

				console.log("socket-", message);
			});
		});
		console.log(this.socket.id)


		this.socket.on("TEST_CONNECTION", (msg: any) => {
			// console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
			this.socketStatusBeforeCheck = msg.status;
			console.log(this.socketStatusBeforeCheck);
		});
		this.socket.on("questionnaire", (questionnaireMsg: any) => {
			// console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);

			console.log(questionnaireMsg);
		});


	}

	fetchUser(phone) {
		this.API.getWidgetUserByPhone(phone,this.api_end_point).subscribe((data: any) => {
			let _response = data;
			if (_response && _response.success) {

				var updateObj = this.callStatus;



				if (!this.widgetUser) {
					this.widgetUser = _response.success;
					updateObj.widgetUser = this.widgetUser;
					console.log("dd-debug31", updateObj)
					this.myservice.updateCallStatus(updateObj);
				} else if (_response.success.external_url != this.widgetUser.external_url) {
					this.counter = 0;
					this.widgetUser.external_url = _response.success.external_url;
					updateObj.widgetUser = this.widgetUser;
					console.log("dd-debug32", updateObj)
					this.myservice.updateCallStatus(updateObj);
				}
			}
			else {

			}
		});
	}

	onInvite(invitation) {
		console.log("call came")
		invitation.accept();
	}

	getHomeData() {
		let _today: any = new Date();
		let _startDate = new Date(_today.setDate(_today.getDate() - 30)).toISOString();
		let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString();
		let userid = this.user.id;
		let obj = {
			"userid": userid,
			"start_time": _startDate,
			"end_time": _endDate,
			"agents_list": userid
		}
		this.API.agentPluginHome(obj,this.api_end_point).subscribe((data: any) => {
			let _response = data;
			if (_response) {
				console.log(_response);
				let _talktime = _response.talk_time;
				let _logintime = _response.loggedin_time;
				let _breaktime = _response.break_time;
				let _activetime = _response.active_time;
				let _totalcalls = _response.total_calls;

				this.filledSource = _response.list;
				console.log(_response.list.length);
				this.countoffilleddata = _response.list.length;
				//this.filledSource.paginator = this.tableTwoPaginator;
				this._auditScore = _response.audited_score;
				this._talktime = _talktime.split(" ");
				//console.log(this._talktime);
				this._logintime = _logintime.split(" ");
				//console.log(this._logintime);
				this._breaktime = _breaktime.split(" ");;
				this._activetime = _activetime.split(" ");
				//this._totalcalls;
			}
			else {

			}
		});
	}
	getDashboardList() {

		if (this.fromDate != "" && this.toDate != "") {
			let _today: any = new Date();
			let today1 = (moment().subtract(0, 'days').startOf('day').toString());
			let today2 = (moment().subtract(0, 'days').endOf('day').toString());
			console.log("--daybeforeyesterday---" + today1 + "--" + today2);
			this.fromDate = moment(today1).format('YYYY-MM-DD HH:mm:ss');
			this.toDate = moment(today2).format('YYYY-MM-DD HH:mm:ss');
			console.log("--daybeforeyesterday-1---" + this.fromDate + "--" + this.toDate);
			let userid = this.user.id;
			let obj = {
				"userid": userid,
				"start_time": this.fromDate,
				"end_time": this.toDate,
				"skip": 0,
				"calendar_type": "smartercalendar",
				"top": 100

			}
			this.API.agentPluginDashboardList(obj,this.api_end_point).subscribe((data: any) => {
				let _response = data.event;
				//console.log("null", _response);
				//console.log("length", _response.length);
				let _aa = _response.filter(v => {
					return (v.responsestatus == "accepted" && v.flpcount[0] >= 0)
				})
				//console.log(_aa.length);
				this._countfollowUpLength = _aa.length;
				this.followupSource = new MatTableDataSource(_aa);;
				this.followupSource.paginator = this.tableThreePaginator;
				if (this.tableThreePaginator) {
					this.tableThreePaginator.length = this.followupSource.data.length;
				}

			});
		}

	}
	getAgentSlot() {
		let userid = this.user.id;
		let obj = {
			"userid": userid
		}
		this.API.agentPluginGetAgentSlot(obj,this.api_end_point).subscribe((data: any) => {
			let _response = data.success.data;
			if (_response) {
				for (let day of _response) {
					for (let slot of day.rows) {
						if (slot.status == 'Available' && !this._availableSlots) {
							this._availableSlots = day.date + " " + slot.time
						}
						if (slot.status == 'Booked' && !this._nextBooked) {
							this._nextBooked = day.date + " " + slot.time
						}
					}
				}

				let _workSlotArray = _response;
			}
		});
	}
	getContacted() {
		// debugger;
		if (this.leadsdatefrom != "" && this.leadsdateto != "") {
			let _today: any = new Date();
			let today1 = (moment().subtract(0, 'days').startOf('day').toString());
			let today2 = (moment().subtract(0, 'days').endOf('day').toString());
			console.log("--daybeforeyesterday---" + today1 + "--" + today2);
			this.leadsdatefrom = moment(today1).format('YYYY-MM-DD HH:mm:ss');
			this.leadsdateto = moment(today2).format('YYYY-MM-DD HH:mm:ss');
			console.log("--daybeforeyesterday-1---" + this.leadsdatefrom + "--" + this.leadsdateto);
			let userid = this.user.id;
			let obj = {
				"userid": userid,
				"start_time": this.leadsdatefrom,
				"end_time": this.leadsdateto,
			}
			this.API.agentPluginContacted(obj,this.api_end_point).subscribe((data: any) => {

				let _response = data.success;
				if (_response) {
					let ELEMENT_DATA = _response;
					let _contactedData = ELEMENT_DATA.filter(v => { return v.event_type == "call" })
					//console.log(JSON.stringify(_contactedData));
					this.dataSource = new MatTableDataSource(_contactedData);
					this.dataSource.paginator = this.tableOnePaginator;
					this._contactedData = _contactedData.length;

					this._countLeadLength = this.API.leadsConnected(_contactedData.length);
				}
			});
		}
	}
	getNotification() {

		let userid = this.user.id;
		let obj = {
			"userid": userid
		}
		this.API.getDesktopNotification(obj,this.api_end_point).subscribe((data: any) => {
			console.log("Notification-- ", data);
			let _response = data.success;
			console.log("length", _response.length);
			let _aa = _response.filter(v => {
				return (v.status == "UNREAD")
			})
			console.log(_aa.length);
			this.notificationcount = _aa.length;
			this.unreadnotification = _response;
			this.notifications = _response;
		})
	}
	btnNotification() {
		this.notifications = this.unreadnotification.filter(v => {
			return (v.status == "UNREAD")
		})
		this.notificationcount = 0;
	}
	startCallDial() {
		// const dialogRef = this.dialog.open(StartDialDialogComponent, {
		//   width: '30%',
		//   height: '90%',
		//   position: { right: '20px', bottom: '0' },
		//   data: ''
		// });
		// dialogRef.afterClosed().subscribe(result => {
		//   console.log('The dialog was closed');
		// });
		// this.myservice.updateCallStatus({dialing: true});
	}
	counter: any = 0;
	transformUrl(url: string) {
		// if(this.counter==0){
		//   this.counter++;
		// console.log(this._sanitizer.bypassSecurityTrustResourceUrl(url))
		// return this._sanitizer.bypassSecurityTrustResourceUrl(url);
		// }
	}

	startSession() {
		var updateObjSession = this.myservice.getLastCallSession();
		if (updateObjSession && (!updateObjSession.internet_speed || updateObjSession.internet_speed < 10)) {
			alert("Internet speed slow try later");
			return;
		}
		if (updateObjSession && !updateObjSession.isAppAgent && updateObjSession.sip_conn != true) {
			alert("Sip connection not available try later")
			return;
		}
		updateObjSession.sessionStarted = true;
		this.callSession = updateObjSession;

		this.myservice.updateCallSession(updateObjSession);
		sessionStorage.setItem('sessionStarted', 'true');
		this.showHideStopPage = true;
		this.showHideStartPage = false;
		this.showHideDailpad = false;
		this.showHideCallStatus = false;
		this.showHideCalldialling = false;
		this.showHideCallEndingScreen = false;
		if ((document.getElementById('code') as HTMLFormElement)) {
			let _num = (document.getElementById('code') as HTMLFormElement).value;
			_num = _num.substring(0, _num.length - 1);
			(document.getElementById('code') as HTMLFormElement).value = _num;
		}
		this.myservice.clearCallStatus();
		this.showHideStartCallButton = true;
		this.showHideCallPad = false;
		this.API.remoteDialStart(this.user.id, this.api_end_point).subscribe(
			(res: any) => {
				console.log(res)
				this.sessionStarted = true;
			}, (err) => {
				console.log(err)
			}
		)
	}
	endSession() {

		this.showHideStopPage = false;
		this.showHideStartPage = true;
		this.showHideDailpad = false;
		this.showHideCallStatus = false;
		this.showHideCalldialling = false;
		this.showHideCallEndingScreen = false;
		if ((document.getElementById('code') as HTMLFormElement)) {
			let _num = (document.getElementById('code') as HTMLFormElement).value;
			_num = _num.substring(0, _num.length - 1);
			(document.getElementById('code') as HTMLFormElement).value = _num;
		}

		this.myservice.clearCallStatus();
		this.showHideStartCallButton = true;
		this.showHideCallPad = false;


		this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
			console.log(data);
			console.log(data.success.extraoptions);
			let setting = data.success.extraoptions
			let stopsetting = setting.filter(v => {
				// console.log(v);
				return (v.name == "remoteautodialling")
			})
			console.log(stopsetting[0].definition);
			if (stopsetting[0].definition == "onsolicit") {
				this.endSessionCall();
			} else {
				this.API.remoteDialStop(this.user.id, this.api_end_point, this.breakoption).subscribe(
					(res: any) => {
						console.log(res)
						this.sessionStarted = false;
						// this.removeNum();
						this.myservice.clearCallStatus();
						var updateObjSession = this.myservice.getLastCallSession();
						updateObjSession.sessionStarted = false;
						this.callSession = updateObjSession;
						this.myservice.updateCallSession(updateObjSession);
						sessionStorage.setItem('sessionStarted', 'false');
						this.showHideStartCallButton = true;
					}, (err) => {
						console.log(err)
					}
				)
			}
		})

	}

	getParsed(data) {
		return JSON.parse(data.replace(/'/g, '"'));
	}

	checkNextTrigger(event, nextAction) {
		console.log(event, nextAction);
		if (nextAction && nextAction.post_url) {
			const currentTime = moment().utc();
			var salesSts = {
				user_id: this.user.id,
				contact_person: {
					number: this.customerInfo.customer_number
				},
				description: "",
				order_potential: "",
				status: "NEW DATA",
				lat: "22.0858725",
				long: "82.1879959",
				start_time: this.callStatus.callStart || currentTime,
				substatus1: "",
				substatus2: "",
				machine_status: "",
				wrapup: 5000,
				lead_source: '',
				appointment_id: "",
				call_end_time: this.callStatus.callEnd || currentTime,
				customkvs: ""
			} as any;
			if (salesSts.start_time != salesSts.call_end_time) {
				salesSts.wrapup = salesSts.call_end_time - salesSts.start_time;
			}
			if (this.callFlowJsonSections) {
				console.log(this.callFlowJsonSections)
				var whatsAppNo;
				var QA = [];
				for (let section of this.callFlowJsonSections) {
					var obj1: any = [];
					for (let child of section.child) {
						var temp1: any = { "Q:": child.q, "A:": child.answer || "" };
						if (child.at == 'FN') {
							whatsAppNo = child.answer;
							temp1["A:"] = ("" + whatsAppNo).includes('+91') ? "" + whatsAppNo : "+91" + whatsAppNo;
						}
						if (child.at == 'DATE' && child.answer) {
							temp1["A:"] = moment(child.answer).format('YYYY-MM-DD');
						}
						if (child.action_type) {
							temp1["action_type:"] = child.action_type
						}

						obj1.push(JSON.stringify(temp1))
						if (child[child.answer]) {
							for (let child2 of child[child.answer]) {
								var temp2: any = { "Q:": child2.q, "A:": child2.answer || "" };
								if (child2.action_type) {
									temp2["action_type:"] = child2.action_type
								}
								if (child2.at == 'DATE' && child2.answer) {
									temp2["A:"] = moment(child2.answer).format('YYYY-MM-DD');
								}
								obj1.push(JSON.stringify(temp2))
								if (child2[child2.answer]) {
									for (let child3 of child2[child2.answer]) {
										var temp3: any = { "Q:": child3.q, "A:": child3.answer || "" };
										if (child3.action_type) {
											temp3["action_type:"] = child3.action_type
										}
										if (child3.at == 'DATE' && child3.answer) {
											temp3["A:"] = moment(child3.answer).format('YYYY-MM-DD');
										}
										obj1.push(JSON.stringify(temp3))
									}

									obj1.push(JSON.stringify(temp3))
								}
							}

						}

					}
					QA = obj1
				}
				var finalObj = {
					experience: QA
				};
				salesSts.contact_person.number = ("" + whatsAppNo).includes('+91') ? "" + whatsAppNo : "+91" + whatsAppNo;
				salesSts.extranotes = JSON.stringify(finalObj);
			}

			this.API.customActionTrigger(salesSts, this.user.server_url, nextAction.post_url).subscribe(
				(res: any) => {
				}, (err) => {
					console.log(err)
				}
			)
		}

	}


	//   removeNum(){
	//     this.numInput="";
	// }

	// clickNumBtn(val) {
	//     if (this.numInput === '0') {
	//       this.numInput = val.toString();
	//     } else {
	//       this.numInput = `${this.numInput}${val}`;
	//     }
	//   }

	backtostartbutton() {

		this.showHideCallingScreen = false;
		this.showHideStartPage = true;
		this.showHideDailpad = false;
		this.showHideDailpad = false;
		this.showHideCallStatus = false;
		this.showHideCalldialling = false;
		this.showHideCallEndingScreen = false;
		this.showHideStopPage = false
	}

	start() {
		//this.showHideCallingScreen=true;
		this.showHideStopPage = true;
		this.showHideStartPage = false;
		this.showHideDailpad = false;
		this.showHideDailpad = false;
		this.showHideCallStatus = false;
		this.showHideCalldialling = false;
		this.showHideCallEndingScreen = false;

		/*this.API.remoteDialStart(this.user.id, this.user.server_url).subscribe(
			  (res: any) => {
				  console.log(res)
				  this.sessionStarted = true;
				this.showHideStartPage = true;
			  }, (err) => {
				  console.log(err)
			  }
		  )*/

	}

	toggle3() {
		this.showHideCalldialling = false;
		this.showHideCallStatus = true;
		this.showHideStopPage = false
	}

	rejectcall() {
		this.showHideStartPage = false;
		this.showHideDailpad = false;
		this.showHideDailpad = true;
		this.showHideCallingScreen = false;
		this.showHideCallStatus = false;
		this.showHideCalldialling = false;
		this.showHideCallEndingScreen = false;
		this.showHideStopPage = false
	}

	toggle5() {
		this.showHideCallStatus = false;
		this.showHideCallEndingScreen = true;
		this.showHideStopPage = false
	}

	toggle6() {
		this.showHideCallEndingScreen = false;
		this.showHideStartPage = true;
		this.showHideStopPage = false
	}

	startcall() {
		this.showHideCalldialling = true;
		this.showHideCallingScreen = false;
		this.showHideStopPage = false;
		this.showHideStartPage = false;
		this.showHideDailpad = false;
		this.showHideDailpad = false;
		this.showHideCallStatus = false;

		this.showHideCallEndingScreen = false;
	}
	answercall() {
		this.showHideCalldialling = true;
		this.showHideStartPage = false;
		this.showHideDailpad = false;
		this.showHideDailpad = false;
		this.showHideCallingScreen = false;
		this.showHideCallStatus = false;
		this.showHideCallEndingScreen = false;
		this.showHideStopPage = false
		this.running = !this.running;
		if (this.running) {
			this.startText = 'Stop';
			const startTime = Date.now() - (this.counter || 0);
			this.timerRef = setInterval(() => {
				this.counter = Date.now() - startTime;
				this.timer = moment.utc(this.counter).format('mm:ss')
			});
		}
	}

	toggle8() {
		this.showHideStartPage = true;
		this.showHideCallEndingScreen = false;
		this.showHideStopPage = false
	}

	startTimer() {

		this.running = !this.running;
		console.log("startTimer", this.running)
		if (true) {
			clearInterval(this.timerRef);
			this.timer = '';
			this.counter1 = 0;
			this.startText = 'Stop';
			const startTime = Date.now() - (this.counter1 || 0);
			console.log("startTimer2", startTime)

			this.timerRef = setInterval(() => {
				this.counter1 = Date.now() - startTime;
				console.log("startTimer2", startTime)
				this.timer = moment.utc(this.counter1).format('mm:ss')
				console.log(this.timer);
			}, 1000);
		} else {
			this.startText = 'Resume';
			clearInterval(this.timerRef);
		}
	}

	clearTimer() {
		this.running = false;
		this.startText = 'Start';
		this.counter = undefined;
		clearInterval(this.timerRef);
	}

	clearDuringCallForm(){
		let updateObj = this.callStatus || {};
		let stateSettings: any = this.myservice.get_stage_settings();
		if (stateSettings && stateSettings.extraoptions) {
			for (let opt of stateSettings.extraoptions) {
				if (opt.name == "questionnaire") {

					let questions = JSON.parse(opt.definition);
					this.questionnaire = questions;
					this.callFlowJsonSections = [{ title: "", description: "", mandatory: "n", child: this.questionnaire }];
					// var updateObj = this.callStatus || {};
					updateObj.callFlowJsonSections = this.callFlowJsonSections;
					// this.myservice.updateCallStatus(updateObj);
					console.log(78, this.questionnaire);
				}
			}
		}
	}

	// removeNum() {
	//   let _num = (document.getElementById('code') as HTMLFormElement).value;
	//   _num = _num.substring(0, _num.length - 1);
	//   (document.getElementById('code') as HTMLFormElement).value = _num;
	//   this.myservice.clearCallStatus();
	//   this.showHideStartCallButton = true;
	// this.showHideCallPad = false;
	// }
	// clickNumBtn(num: any) {

	//   let _numLength = ((document.getElementById('code') as HTMLFormElement).value).length;
	//   if (_numLength == 12) {
	//     (document.getElementById('code') as HTMLFormElement).value;
	//     return false;
	//   }
	//   (document.getElementById('code') as HTMLFormElement).value = (document.getElementById('code') as HTMLFormElement).value + num.toString();
	//   console.log((document.getElementById('code') as HTMLFormElement).value);

	//   var updateObj = this.callStatus;
	//   updateObj.dialNum = (document.getElementById('code') as HTMLFormElement).value;
	//   updateObj.dialed = false;
	//   this.myservice.updateCallStatus(updateObj);
	// }
	restFm() {
		// this.showHide_Form_before_DuringForm = true;
		// this.showHide_Form_After_DuringForm = false;
		this.rnrDescription = "";
		this.selectedRnrReason = "";
		this.timeBtnValue = '';
		this.timeBtnValueTransformed = '';
		this.timeModel = '';
		this.dateModel = '';
		this.disposition = '';
	}
	duringFormSubmit() {
		console.log(this.callFlowJsonSections)
		var acceptSubmit = true;
		for (let section of this.callFlowJsonSections) {
			for (let child of section.child) {
				if (child.mandatory == 'y' && (child.answer == undefined || child.answer == '')){
					acceptSubmit = false;
				}

				if (child[child.answer]) {
					for (let child2 of child[child.answer]) {
						if (child2.mandatory == 'y' && (child2.answer == undefined || child2.answer == '')) {
							acceptSubmit = false;
						}
						if (child2[child2.answer]) {
							for (let child3 of child2[child2.answer]) {
								if (child3.mandatory == 'y' && (child3.answer == undefined || child3.answer == '')) {
									acceptSubmit = false;
								}
							}

						}
					}

				}

			}
		}

		if (acceptSubmit == false){
			this.showDuringFormError = true;
			setTimeout(() =>{
				this.showDuringFormError = false;
			},3000)
			return;
		}

		this.showHide_Form_before_DuringForm = false;
		this.showHide_Form_After_DuringForm = true;
		var updateObj = this.callStatus;
		delete updateObj.showDuringCallForm;
		console.log("dd-debug33", updateObj)
		this.myservice.updateCallStatus(updateObj);
	}
	formSubmitBtn() {
		this.showHide_Form_before_DuringForm = false;
		this.showHide_Form_After_DuringForm = false;
		console.log(this.dateModel);
		console.log(this.timeModel);

		var date = moment(this.dateModel).format('DD/MM/YYYY');
		console.log(date);
		var Time1 = moment(this.timeModel).format('HH:mm:ss');
		console.log(Time1);

		// conversion
		var dateTime = moment(date + ' ' + this.timeModel, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.000Z');
		console.log(dateTime);
		this.callbacktime = dateTime;

		console.log(this.callbacktime);
	}
	clickHighlight(type: any) {
		this.showHideUpdate = false;
		this.showHideCallBack1 = false;
		this.showHideCallBack2 = false;
		this.showHideCallRNR = false;
		this.typeTab = type;
		if (type == "update") {
			this.showHideUpdate = true;
		}
		if (type == "callback1") {
			this.showHideCallBack1 = true;
		}
		if (type == "callback2") {
			this.showHideCallBack2 = true;
		}
		if (type == "rnr") {
			this.showHideCallRNR = true;

			var obj1 = { Q: "Reason for RNR", A: this.selectedRnrReason }
			var obj2 = { Q: "Description for RNR", A: this.rnrDescription }
			var finalObj = [];
			finalObj.push(JSON.stringify(obj1))
			finalObj.push(JSON.stringify(obj2))
			this.salesSts.extranotes = JSON.stringify(finalObj)

		}


	}
	isActive(tabType: any): boolean {
		return this.typeTab === tabType;
	}


	clickTimeHighlight(timeVal: any) {

		let now = moment().format("YYYY-MM-DD HH:mm:ss");
		console.log(now);
		this.timeBtnValue = timeVal;
		console.log(timeVal);
		this.timeBtnValueTransformed = (moment().add(timeVal, 'minute').format('YYYY-MM-DD HH:mm:ss'));
		moment.utc().format()
		console.log(this.timeBtnValueTransformed);
	}
	isTimeActive(timeVal: any): boolean {
		return this.timeBtnValue === timeVal;
	}

	getCustomerInfo() {
		const transactionid = this.callStatus.transactionid ? this.callStatus.transactionid : 'XXXXYYYY'
		this.API.getInfoCustomer(this.user.server_url, this.myUser.id, "",transactionid).subscribe((data: any) => {
			let _response = data;
			if (_response && _response.success && _response.success != "No Connected Customer" && _response.success != "Not a valid connected customer" && _response.info) {
				this.showHideConnecting = false;
				this.showHideCalldialling = false;
				var updateObj = this.callStatus;

				this.customerInfo = {
					lead_source: _response.info.lead_source,
					status: _response.info.lead_status,
					customer_number: _response.info.number,
					name: _response.info.name,
					customer_name: _response.info.name
				}
				updateObj.customerInfo = this.customerInfo;
				updateObj.showDuringCallForm = true;
				this.customerlead_source = this.customerInfo.lead_source;

				this.customerstatus = this.customerInfo.status;

				if (!this.widgetUser) {
					this.widgetUser = {
						lead_source: _response.info.lead_source,
						status: _response.info.lead_status,
						customer_number: _response.info.number,
						name: _response.info.name,
						customer_name: _response.info.name
					}
					updateObj.widgetUser = this.widgetUser;
					console.log("dd-debug34", updateObj)
					this.myservice.updateCallStatus(updateObj);
				}
				var interval_id = window.setInterval(() => { }, 99999);
				// for (var i = 0; i < interval_id; i++) {
				// 	window.clearInterval(i);
				// }
				// this.customerlead_source = this.customerInfo.lead_source;

				this.customerstatus = this.customerInfo.status;
				if (!this.isAppAgent && updateObj.dialNum == undefined) {
					this.fetchUser(this.customerInfo.customer_number)
				}
			}else if( _response.success == "No Connected Customer"){
				setTimeout(() => {
					this._snackBar.open("No Connected Customer", 'close');
				}, 2000);
			}
		});
	}

	endSessionCall() {
		//debugger;
		console.log(this.myUser);
		var dialogRef = this.dialog.open(EndSessionDialogComponent, {
			width: '40%',
			disableClose: true,
			data: this.myUser,
		});
		dialogRef.afterClosed().subscribe(result => {
			//debugger;
			console.log(result);
			if (result == "true") {
				this.sessionStarted = false;
				// this.removeNum();
				this.myservice.clearCallStatus();
				var updateObjSession = this.myservice.getLastCallSession();
				updateObjSession.sessionStarted = false;
				this.callSession = updateObjSession;
				this.myservice.updateCallSession(updateObjSession);
				sessionStorage.setItem('sessionStarted', 'false');
				this.showHideStopPage = false;
				this.showHideStartPage = true;
				this.showHideDailpad = false;
				this.showHideCallStatus = false;
				this.showHideCalldialling = false;
			}
			else {
				this.showHideStopPage = true;
				this.showHideStartPage = false;
				this.showHideDailpad = false;
				this.showHideCallStatus = false;
				this.showHideCalldialling = false;
			}
		});
	}
	reset_aftercall() {

	}
	submit_aftercall() {

	}

	last3call(item) {
		var today = moment().format('DD/MM/YYYY');
		var noofdays;
		console.log(item);
		console.log(item.number);
		let userid = this.user.id;
		let afterslipt = item.number.split('+');
		console.log(afterslipt);
		let numner = "%2b" + afterslipt[1];
		//
		let obj = {
			userid: userid,
			number: numner,
			count: 3
		}
		this.API.getLastThreeCallRecording(obj,this.api_end_point).subscribe((data: any) => {
			console.log(data.length);
			console.log(data.success);
			if (data.length == 0) {
				//this._snackBar.open("No Data found", "Close");
				let last3call = "No Data found";
				return last3call;
			}
			else {
				let obj = [];
				console.log(data);

				for (let audio in data) {
					console.log(data[audio]);
					console.log(data[audio].url);
					var dateofvisit = moment(data[audio].call_start_time);
					if (dateofvisit.diff(dateofvisit, 'days') == 0) {
						console.log(dateofvisit.diff(dateofvisit, 'days'));
						obj.push({ value: data[audio].url, viewValue: moment(data[audio].call_start_time).format('DD-MM-YYYY') });
					}
					else if (dateofvisit.diff(dateofvisit, 'days') == 1) {
						console.log(dateofvisit.diff(dateofvisit, 'days'));
						obj.push({ value: data[audio].url, viewValue: moment(data[audio].call_start_time).format('DD-MM-YYYY') });
					}
					else {
						obj.push({ value: data[audio].url, viewValue: moment(data[audio].call_start_time).format('DD-MM-YYYY') });
					}
				}
				for (let v in obj) {
					obj[v].value = obj[v].value + `&access_token=${this.myUser.access_token}`
				}
				console.log(obj);
				return obj

			}

		})

	}

	selectCall(calldetail) {

		var today = moment().format('DD/MM/YYYY');
		var noofdays;
		let threecalldata;
		console.log(calldetail);
		console.log(calldetail.number);
		let userid = this.user.id;
		let afterslipt = calldetail.number.split('+');
		console.log(afterslipt);
		let numner = "%2b" + afterslipt[1];
		//
		let obj = {
			userid: userid,
			number: numner,
			count: 3
		}
		this.API.getLastThreeCallRecording(obj,this.api_end_point).subscribe((data: any) => {
			console.log(data.length);
			console.log(data.success);
			if (data.length == 0) {
				//this._snackBar.open("No Data found", "Close");
				let last3call = "No Data found";
				threecalldata = last3call;
				console.log(threecalldata);
				let urlaudio = calldetail.url + `&access_token=${this.myUser.access_token}`
				console.log(urlaudio);
				this.dialog.open(AudioDialogComponent, {
					width: '400px',
					disableClose: true,
					data: {
						url: urlaudio
					}
				}).afterClosed()
					.subscribe((result: any) => {
						console.log(`Dialog result: ${JSON.stringify(result)}`);
					});
			}
			else {
				let obj = [];
				console.log(data);
					// let urlaudio = calldetail.url + `&access_token=${this.myUser.access_token}`
					// obj.push({ value: urlaudio, viewValue: 'today' });
				for (let audio in data) {
					console.log(data[audio]);
					console.log(data[audio].url);
					var currentdate = moment();
					console.log(currentdate);
					var dateofvisit = moment(data[audio].call_start_time);
					console.log(currentdate,dateofvisit);

					if ( currentdate.diff(dateofvisit, 'days') == 0) {
						console.log(dateofvisit.diff(dateofvisit, 'days'));
						obj.push({ value: data[audio].url, viewValue: moment(data[audio].call_start_time).format('DD-MM-YYYY')});
					}
					else if (currentdate.diff(dateofvisit, 'days') == 1) {
						console.log(dateofvisit.diff(dateofvisit, 'days'));
						obj.push({ value: data[audio].url, viewValue: moment(data[audio].call_start_time).format('DD-MM-YYYY') });
					}
					else {
						obj.push({ value: data[audio].url, viewValue: moment(data[audio].call_start_time).format('DD-MM-YYYY') });
					}
				}
				for (let v in obj) {
					obj[v].value = obj[v].value + `&access_token=${this.myUser.access_token}`
				}
				console.log(obj);
				threecalldata = obj;

			}

			console.log(threecalldata);
			// let urlaudio = calldetail.url + `&access_token=${this.myUser.access_token}`
			// console.log(urlaudio);
			this.dialog.open(AudioFollowupDialogComponent, {
				width: '400px',
				disableClose: true,
				data: {
					threecalls: threecalldata
					//url: 'https://ia801009.us.archive.org/8/items/EdSheeranPerfectOfficialMusicVideoListenVid.com/Ed_Sheeran_-_Perfect_Official_Music_Video%5BListenVid.com%5D.mp3'
				}
			}).afterClosed()
				.subscribe((result: any) => {
					console.log(`Dialog result: ${JSON.stringify(result)}`);
				});
		})


	}

	SelectLeads() {
		this.LeadsSelected = true;
		this.FilledFormsSelected = false;
		this.FollowupSelected = false;
		setTimeout(() => {
			this.dataSource = new MatTableDataSource(this.dataSource.data);;
			this.dataSource.paginator = this.tableOnePaginator;
			// this.tableOnePaginator.length = this.dataSource.data.length;
		}, 500)
	}

	SelectFollowup() {
		this.selectdate = true;
		this.selectedFilter = 'TODAY';
		this.FilledFormsSelected = false;
		this.FollowupSelected = true;
		this.LeadsSelected = false;
		setTimeout(() => {
			this.followupSource = new MatTableDataSource(this.followupSource.data);
			this.followupSource.paginator = this.tableThreePaginator;
			// this.tableThreePaginator.length = this.followupSource.data.length;
		}, 500)

	}
	SelectFilledForm() {
		this.LeadsSelected = false;
		this.FilledFormsSelected = true;
		this.FollowupSelected = false;
	}

	filledRowRecord(item) {
		// debugger;
		console.log(item);
		this.filledjsonObject = [];
		let data = item; //JSON.parse(item.feedback);
		var feedbackdata = item;
		console.log(feedbackdata);

		for (let item in feedbackdata.comment) {
			console.log(feedbackdata.comment[item])
			let question = feedbackdata.comment[item]
			//console.log(Object.values(question));
			var question_ans = Object.keys(question).map(function (e) {
				return question[e]
			})
			console.log(question_ans);
			var obj = {
				q: question_ans[0],
				a: question_ans[1],
				action: ''
			}
			if (question_ans[2]) {
				obj.action = question_ans[2];
			}
			this.filledjsonObject.push(obj);
			console.log(this.filledjsonObject);
		}
		console.log(this.filledjsonObject);
		if (data == null) {
			this._snackBar.open("No Feedback found", "action");
		}
		this.showhideQuestionAnwer = true;

	}

	readnotification(readingdata) {
		let rowdata = readingdata;
		console.log(rowdata);
		this.dialog.open(NotificationReadComponent, {
			width: '500px',
			disableClose: true,
			data: {
				obj: rowdata
			}
		}).afterClosed()
			.subscribe((result: any) => {
				console.log(result);
				if (result == 'true') {
					let obj = {
						"user_id": this.user.id,
						"postNotifications": [
							{

								"id": rowdata.id,
								"status": "READ"
							}]
					}

					console.log(obj);
					this.API.readNotification(obj).subscribe((data: any) => {
						console.log(data);
						this.getNotification();
					})
				}
			});
	}
	goBtn() {
		console.log("---" + this.fromDate);
		let followupdate = (moment(this.fromDate).format('YYYY-MM-DD HH:mm:ss'));
		console.log(followupdate);
		let todaydate = moment().format('YYYY-MM-DD HH:mm:ss');
		if (followupdate > todaydate) {
			console.log("date is greater than today date");
			this._snackBar.open("Please enter before current date","close");
		}
		else {
			let pastdate1 = (moment(followupdate).startOf('day').toString());
			let pastdate2 = (moment(followupdate).endOf('day').toString());
			console.log("--yesterday---" + pastdate1 + "--" + pastdate2);
			this.fromDate = moment(pastdate1).format('YYYY-MM-DD HH:mm:ss');
			this.toDate = moment(pastdate2).format('YYYY-MM-DD HH:mm:ss');
			console.log("--yesterday-1---" + this.fromDate + "--" + this.toDate);
			this.followupdatatable();
		}
	}
	onDate() {
		if (this.fromDate > this.toDate) {
			let _todate = this.toDate;
			this.fromDate = _todate;
		}
	}
	FollowFilter(val: any) {
		this.selectedFilter = val;
		this.FollowdateTable();
	}
	FollowdateTable() {
		// console.log('table refreshed');
		let _today: any = new Date();
		let _startDate = (moment().subtract(0, 'days').startOf('day').toString());
		let _endDate = (moment().subtract(0, 'days').endOf('day').toString());
		// debugger;
		if (this.selectedFilter == "TODAY") {
			this.fromDate = moment(_startDate).format('YYYY-MM-DD HH:mm:ss');
			this.toDate = moment(_endDate).format('YYYY-MM-DD HH:mm:ss');
			console.log("--today---" + this.fromDate + "--" + this.toDate);
			this.followupdatatable();
		}
		else if (this.selectedFilter == "YESTERDAY") {
			let yesterday1 = (moment().subtract(1, 'days').startOf('day').toString());
			let yesterday2 = (moment().subtract(1, 'days').endOf('day').toString());
			console.log("--yesterday---" + yesterday1 + "--" + yesterday2);
			this.fromDate = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
			this.toDate = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
			console.log("--yesterday-1---" + this.fromDate + "--" + this.toDate);
			this.followupdatatable();
		} else if (this.selectedFilter == "ALL_IN_PAST") {
			let yesterday1 = (moment().subtract(30, 'days').startOf('day').toString());
			let yesterday2 = (moment().subtract(0, 'days').endOf('day').toString());
			console.log("--ALL_IN_PAST---" + yesterday1 + "--" + yesterday2);
			this.fromDate = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
			this.toDate = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
			console.log("--ALL_IN_PAST-1---" + this.fromDate + "--" + this.toDate);
			this.followupdatatable();
		}
		else if (this.selectedFilter == "ALL_IN_FUTURE") {
			let yesterday2 = (moment().add(30, 'days').startOf('day').toString());
			let yesterday1 = (moment().subtract(0, 'days').endOf('day').toString());
			console.log("--ALL_IN_PAST---" + yesterday1 + "--" + yesterday2);
			this.fromDate = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
			this.toDate = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
			console.log("--ALL_IN_PAST-1---" + this.fromDate + "--" + this.toDate);
			this.followupdatatable();
		}
		else if (this.selectedFilter == "CHOOSE_A_DATE") {
			this.selectdate = false;
			//this.followupdatatable();
		}
	}
	followupdatatable() {
		let userid = this.user.id;
		let obj = {
			"userid": userid,
			"start_time": this.fromDate,
			"end_time": this.toDate,
			"skip": 0,
			"calendar_type": "smartercalendar",
			"top": 100

		}
		this.API.agentPluginDashboardList(obj,this.api_end_point).subscribe((data: any) => {
			let _response = data.event;
			console.log("null", _response);
			//console.log("length", _response.length);
			let _aa = _response.filter(v => {
				return (v.responsestatus == "accepted" && v.flpcount[0] >= 0)
			})
			//console.log(_aa.length);
			this._countfollowUpLength = _aa.length;
			if(this._countfollowUpLength == 0){
				this._snackBar.open("No Follow Up Found","close");
			}
			this.followupSource = new MatTableDataSource(_aa);;
			this.followupSource.paginator = this.tableThreePaginator;
			if (this.tableThreePaginator) {
				this.tableThreePaginator.length = this.followupSource.data.length;
			}

		});
	}
	LeadFilter(val: any) {
		this.leadFilter = val;
		this.LeaddateTable();
	}
	LeaddateTable() {
		// console.log('table refreshed');
		let _today: any = new Date();
		let _startDate = (moment().subtract(0, 'days').startOf('day').toString());
		let _endDate = (moment().subtract(0, 'days').endOf('day').toString());
		// debugger;
		if (this.leadFilter == "TODAY") {
			this.leadsdatefrom = moment(_startDate).format('YYYY-MM-DD HH:mm:ss');
			this.leadsdateto = moment(_endDate).format('YYYY-MM-DD HH:mm:ss');
			console.log("--today---" + this.leadsdatefrom + "--" + this.leadsdateto);
			this.Leadsdetailstable();
		}
		else if (this.leadFilter == "YESTERDAY") {
			let yesterday1 = (moment().subtract(1, 'days').startOf('day').toString());
			let yesterday2 = (moment().subtract(1, 'days').endOf('day').toString());
			console.log("--yesterday---" + yesterday1 + "--" + yesterday2);
			this.leadsdatefrom = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
			this.leadsdateto = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
			console.log("--yesterday-1---" + this.leadsdatefrom + "--" + this.leadsdateto);
			this.Leadsdetailstable();
		} else if (this.leadFilter == "ALL_IN_PAST") {
			let yesterday1 = (moment().subtract(30, 'days').startOf('day').toString());
			let yesterday2 = (moment().subtract(0, 'days').endOf('day').toString());
			console.log("--ALL_IN_PAST---" + yesterday1 + "--" + yesterday2);
			this.leadsdatefrom = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
			this.leadsdateto = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
			console.log("--ALL_IN_PAST-1---" + this.leadsdatefrom + "--" + this.leadsdateto);
			this.Leadsdetailstable();
		}
	}
	Leadsdetailstable() {
		let userid = this.user.id;
		let obj = {
			"userid": userid,
			"start_time": this.leadsdatefrom,
			"end_time": this.leadsdateto,
		}
		this.API.agentPluginContacted(obj,this.api_end_point).subscribe((data: any) => {

			let _response = data.success;
			if (_response) {
				let ELEMENT_DATA = _response;
				let _contactedData = ELEMENT_DATA.filter(v => { return v.event_type == "call" })
				//console.log(JSON.stringify(_contactedData));
				this.dataSource = new MatTableDataSource(_contactedData);
				this.dataSource.paginator = this.tableOnePaginator;
				this._contactedData = _contactedData.length;
				this._countLeadLength = this.API.leadsConnected(_contactedData.length);

				if (this._countLeadLength == 0) {
					this._snackBar.open("No Leads Found", "close");
				}
			}
		});
	}
	mergingcall() {
		// debugger;
		console.log(this.callStatus.customerInfo.transactionid);
		let obj = {
      "user_id":this.user.id,
			"agent_number": this.user.phone,
			"customer_number": this.customerInfo.customer_number,
			"transactionid": this.callStatus.customerInfo.transactionid,
			"caller_id": this._caller_id
		}
		console.log(obj);
		this.API.callmerge(obj, this.user.server_url).subscribe((data: any) => {
			if (data.success) {
				setTimeout(() => {
					this._snackBar.open("call merging", "close");
				}, 1000)

			}
		})
	}
	transferingcall() {
		console.log(this.callStatus.customerInfo.transactionid);
		let obj = {
      "user_id":this.user.id,
			"agent_number": this.user.phone,
			"customer_number": this.customerInfo.customer_number,
			"transactionid": this.callStatus.customerInfo.transactionid,
			"caller_id": this._caller_id
		}
		console.log(obj);
		this.API.calltransfer(obj, this.user.server_url).subscribe((data: any) => {
			if (data.success) {
				setTimeout(() => {
					this._snackBar.open("call transfering", "close");
				}, 1000)

			}
		})
	}
}
