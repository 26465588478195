
    <!-- <div class="row ml-0 mr-0">
        <div class="col-md-12 col-sm-12">
            <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                <input matInput placeholder="How has your fueling experience been At the HP outlets ?">
            </mat-form-field>
        </div>
    </div> -->
    <div class="row ml-0 mr-0">
        <div class="col-md-12 col-sm-12">
        <!-- <star-rating-comp style="float: left;" [starType]="'icon'" [size]="'large'" [rating]="4"></star-rating-comp> -->

        <ng-template #t let-fill="fill">
            <span class="star" [class.full]="fill === 100">
              <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
            </span>
          </ng-template>
          <ngb-rating style="float: left;" [max]="5" [rate]="4"  [starTemplate]="t" [readonly]="true"></ngb-rating>
             
        </div>
    </div>
 

    <div>
        <div class="row">
            <div class="col-md-4">
                &nbsp;
            </div>
            <div class="col-md-4">
                <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">     
                    <mat-select placeholder="Select Action Type" (selectionChange)="actionType($event.value)">
                      <mat-option>None</mat-option>
                      <!-- <mat-option value="1"> Action 1</mat-option>
                      <mat-option value="2">Action 2</mat-option>
                      <mat-option value="3">Action 3</mat-option> -->
               
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="col-md-4">
                <span class="callReq"> Required&nbsp;&nbsp;
                    <mat-slide-toggle class="callReq" [checked]="checked" (change)="triggerToggle($event)"></mat-slide-toggle></span>
                <span class="call-del" (click)="trigerRemoveSubSection()">
                    <mat-icon style="font-size: 35px;"  >delete_forever</mat-icon>
                  
                </span>
            </div>
        </div>
    </div>
