
    <div class="searchFix">
        <mat-form-field style="font-size: 12px;" appearance="outline">
            <input matInput #searchInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH AGENT">
            <i class="fa fa-search search-icon" matSuffix style="position: relative;
            top: -2px;"></i>
          </mat-form-field>
          </div>
    <br>
      
    <div>
        <div>
       
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>
               
            </th>
             <td mat-cell *matCellDef="let element; let i = index;">

            <mat-checkbox [(ngModel)]="element.isSelected" [checked]="element.isSelected" (change)="selectForMessageAgents( $event, i, element.email )"></mat-checkbox>
          </td>
          </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>NAME </th>
      <td mat-cell *matCellDef="let element">{{element.name}} </td>
    </ng-container>
    
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> PHONE </th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>
    <ng-container matColumnDef="userid">
        <th mat-header-cell *matHeaderCellDef>USER ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
     
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
         
    </div>
    <mat-paginator  [ngClass]="'paginator-training'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    <br>
    <div class="row pdt"> 
        
        <div class="col-md-12 col-sm-12 col-xs-12 rightside">
            <button mat-button class="cancelbtn" (click)="resetIds()" >Cancel</button>
            <button mat-button class="submitbtn" (click)="sendNotificationDialog()">SEND NOTIFICATION</button>
        </div>
      
        </div>
        <br><br>
    </div>
 