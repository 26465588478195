import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Routes, RouterModule, Router,ActivatedRoute, Params  } from '@angular/router';
import {MyserviceService}from '../../myservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContiinexSelectChannelDialogComponent } from '../contiinex-select-channel-dialog/contiinex-select-channel-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContiinexProfilePreviewDialogComponent } from '../contiinex-profile-preview-dialog/contiinex-profile-preview-dialog.component';
@Component({
  selector: 'app-contiinex-profile-settings',
  templateUrl: './contiinex-profile-settings.component.html',
  styleUrls: ['./contiinex-profile-settings.component.scss']
})
export class ContiinexProfileSettingsComponent implements OnInit {
  selectedButtonToggleVal: string = 'account';
  psSettingData:any;
  psProfileData:any;
  updatedImageUrl:any="";

  showHideForAccountTab:boolean=true;
  showHideForProfileTab:boolean=false;
  showHideForConfigureTab:boolean=false;

  filenameContainer:any;
  fileToUpload: File;
  createdForm: FormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  showLoader:boolean=false;
  _imgRender:any;

  isDisabled:boolean=false;
  showHideForCancel: boolean = false;
  showHideForEdit: boolean = false;
  showHideForSave: boolean = true;
  currentLoader:boolean=false;

  customer_name:any;
  businessType:any;
  api_end_point:any;
	currentTitle = 'Profile-Settings';
  currentUrl:any;
  constructor(private formbuilder: FormBuilder, private router: Router, private API: ApiService,private _service:MyserviceService, private activateRoute: ActivatedRoute, private route: ActivatedRoute,private _snackBar: MatSnackBar,	private dialog: MatDialog) {
  }

  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
    this.getSelectChannelMethod();
    this.currentUrl = this.router.url;
  }

  getSelectChannelMethod(){
  //  debugger;
    if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.businessType = String(this._service.get_loggedin_user().businessType);
    }


    let _setWabaVal_prp = localStorage.getItem('setWabaVal_prp');
  //   if(_setWabaVal_prp=="WABA" && this.businessType=="WABA"){
  //    localStorage.removeItem('setWabaVal_prp');
  //     const dialogRef = this.dialog.open(ContiinexSelectChannelDialogComponent, {
  //       width: '500px',
  //       height:'350px',
  //       disableClose: true,
  //       panelClass:'cbxSelect',
  //       data: ""
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //      // debugger;
  //       console.log('The dialog was closed', result);
  //       if(result=="true"){
  //         this.getViewData();
  //       }
  //      else if(result=="false"){
  //       this.getViewData();
  //       }
  //     });
  //   }
  // else{
     this.getViewData();
 // }
  }

getViewData(){
  this.currentLoader=true;
  this.API.getWabaProfile(this.customer_name,this.api_end_point).subscribe((res: any) => {
    this.currentLoader=false;
    if (res && res.account && res.profile) {
      this.psSettingData = res.account;
      this.psProfileData = res.profile;
      this.createdForm = this.formbuilder.group(res.profile);
      if (res.profile.uploadFileImg) {
        this._imgRender = res.profile.uploadFileImg;
      }
      if(res.profile){
        this.isDisabled=true;
        this.showHideForCancel = false;
        this.showHideForEdit = true;
        this.showHideForSave = false;
      }
      if (!res.profile.businessName) {
        this.showHideForAccountTab=false;
        this.showHideForConfigureTab=false;
        this.showHideForProfileTab=true;
      }
      if(this.psSettingData.length==0){
        this.psSettingData=[
          {"title":"Verified Business Name", "desc":"","borderColor":"#c0eee1","bColor":"#f8fffd"},
          {"title":"Account Connection Status", "desc":"","borderColor":"#afe2e9","bColor":"#f0fdff"},
          {"title":"Business Number", "desc":"","borderColor":"#ded7f4","bColor":"#f5f4f8"},
          {"title":"Business Verification Status", "desc":"","borderColor":"#f4dae3","bColor":"#fff4f7"}
        ] 
        this.showHideForAccountTab=true;
        this.showHideForProfileTab=false;
        this.showHideForConfigureTab=false;
      }
      if(this.psProfileData.length==undefined){
        this.psProfileData=[
          {alertEmail: "",bearerToken: "",businessDescription: "",businessManagerID: "",businessManagerIDRO: true,businessName: "",businessNameRO: true,
businessType: "",companyAddress: "",companyDescription: "",displayName: "",displayNameRO: true,receivedCB: "",statusCB: "", uploadFileImg: "", waNumber: "", waNumberRO: true,websiteURL: ""
      }];
      }
    } 
    else {
      this.psSettingData=[
        {"title":"Verified Business Name", "desc":"","borderColor":"#c0eee1","bColor":"#f8fffd"},
        {"title":"Account Connection Status", "desc":"","borderColor":"#afe2e9","bColor":"#f0fdff"},
        {"title":"Business Number", "desc":"","borderColor":"#ded7f4","bColor":"#f5f4f8"},
        {"title":"Business Verification Status", "desc":"","borderColor":"#f4dae3","bColor":"#fff4f7"}
      ]
    }
  });
}

  onToggleButtonValChange(val:any){
    this.showHideForAccountTab=false;
    this.showHideForProfileTab=false;
    this.showHideForConfigureTab=false;
    if(val=="account"){
      this.showHideForAccountTab=true;
    }
    else if(val=="profile"){
      this.showHideForProfileTab=true;
    }
    else if(val=="configure"){
      this.showHideForConfigureTab=true;
    }
  }

  uploadImageChange(event:any,_files: FileList){
    let fileInput =(<HTMLInputElement>document.getElementById("testfile")).value
    this.filenameContainer = fileInput.split('\\').pop();

    let reader;
    let input=event.target;

    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = function(e) {
      (<HTMLInputElement>document.getElementById("preview")).setAttribute('src', e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }

    this.fileToUpload = _files.item(0);
   this.API.businessProfileUploadLogo(this.customer_name,this.fileToUpload,this.api_end_point).subscribe((res: any) => {
     //console.log(res.url);//https://www.contiinex.com/api/v1/attachments/Innojar_Logo.png
     if (res.url) {
       this.updatedImageUrl = res.url;
     }
   });
  }

  saveProfileSetting(createdForm: any) {
   // debugger;
    let _businessName = createdForm.value.businessName;
    let _displayName= createdForm.value.displayName;
    let _businessDescription= createdForm.value.businessDescription;
    let _websiteURL= createdForm.value.websiteURL;
    let _businessManagerID= createdForm.value.businessManagerID;
    let _waNumber= createdForm.value.waNumber;
    let _uploadFileImg= createdForm.value.uploadFileImg;
    let _businessType= createdForm.value.businessType;
    let _companyDescription= createdForm.value.companyDescription;
    let _companyAddress= createdForm.value.companyAddress;
    let _alertEmail= createdForm.value.alertEmail;

    if(_businessName=="" || _businessName==null){
      this._snackBar.open('Please enter business name', 'close', {
        duration: 3000,
      });
      return;
    }
    if(_displayName=="" || _displayName==null){
      this._snackBar.open('Please enter display name', 'close', {
        duration: 3000,
      });
      return;
    }
    if(_businessDescription=="" || _businessDescription==null){
      this._snackBar.open('Please enter business description', 'close', {
        duration: 3000,
      });
      return;
    }
    if(_websiteURL=="" || _websiteURL==null ){
      this._snackBar.open('Please enter business website', 'close', {
        duration: 3000,
      });
      return;

    }
    if(_businessManagerID=="" || _businessManagerID==null){
      this._snackBar.open('Please enter business manager ID', 'close', {
        duration: 3000,
      });
      return;
    }
    if(_waNumber=="" || _waNumber==null){
      this._snackBar.open('Please enter whatsapp number', 'close', {
        duration: 3000,
      });
      return;
    }
    if(_uploadFileImg=="" || _uploadFileImg==null){
      this._snackBar.open('Please upload logo image', 'close', {
        duration: 3000,
      });
      return;
    }
    if(_alertEmail=="" || _alertEmail==null){
      this._snackBar.open('Please enter alert email', 'close', {
        duration: 3000,
      });
      return;
    }

    let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (reg.test(_alertEmail) == false)
    {
        this._snackBar.open('Please enter valid alert email', 'close', {
          duration: 3000,
        });
        return false;
    }

    if(_businessType=="" || _businessType==null){
      this._snackBar.open('Please select business vertical type', 'close', {
        duration: 3000,
      });
      return;

    }
    if(_companyDescription=="" || _companyDescription==null){
      this._snackBar.open('Please enter about company', 'close', {
        duration: 3000,
      });
      return;
    }
    if(_companyAddress=="" || _companyAddress==null){
      this._snackBar.open('Please enter company address', 'close', {
        duration: 3000,
      });
      return;
    }


    let obj = {
      waba_details: {
        account: this.psSettingData,
        profile: createdForm.getRawValue()
      }
    };

    if (this.updatedImageUrl) {
      obj.waba_details.profile.uploadFileImg = this.updatedImageUrl;
    }

    this.API.updateWabaProfile(this.customer_name,obj).subscribe((res: any) => {
      if (res.success) {
        //alert(res.success);
        this._snackBar.open(res.success, 'close', {
          duration: 5000,
        });
        this.cancelBtn();
      } else {
       // alert('Failure in updation');
        this._snackBar.open('Failure in updation', 'close', {
          duration: 5000,
        });
      }
    });
  }
  editBtn(createdForm:any){
this.isDisabled=false;
this.showHideForCancel = true;
this.showHideForEdit = false;
this.showHideForSave = true;
  }
  cancelBtn(){
    this.isDisabled=true;

this.showHideForCancel = false;
this.showHideForEdit = true;
this.showHideForSave = false;
this.getViewData();
  }
  onlyNumberWithPlusValidation(event){
    return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 43 || event.charCode == 32;
  }
  previewBtn(createdForm:any){
    const dialogRef = this.dialog.open(ContiinexProfilePreviewDialogComponent, {

      height:'95%',
      disableClose: true,
      panelClass:'msDialogCls',
      data: createdForm,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }
}
