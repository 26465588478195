<div class="col-md-12">
	<h4 style="text-align: center;">
		<b>
			Call Recordings
		</b>
	</h4>
	<div class="row" style="margin-top:4px;"><!---->
		<div class="col-md-12">
			<mat-dialog-content>
				<mat-slider class="time-slider" min="0" [max]="state?.duration" step="1" [value]="state?.currentTime"
					(input)="onSliderChangeEnd($event)" [disabled]=" state?.error"></mat-slider>
				<div>
					Duration: {{ state?.readableDuration }}<br>
					Current time: {{ state?.readableCurrentTime }}
				</div>
			</mat-dialog-content>
		</div>
		<div class="col-md-12">
			<mat-dialog-actions align="end">
				<div class="col-md-5" class="btn-grp">
					<button mat-button matTooltip="Play" (click)="play()">
						<mat-icon>play_arrow</mat-icon>
					</button>
					<button mat-button class="action_btn" matTooltip="Pause"(click)="pause()">
						<mat-icon>pause</mat-icon>
					</button>
				</div>
			</mat-dialog-actions>
		</div>
	</div>

	<div class="col-md-12">
		<div style="text-align: right;margin-top: 30px;">
			<button mat-button class="action_btn" (click)="close()">CLOSE</button>
		</div>
	</div>
</div>