<div class="col-md-12">
	<h4 style="text-align: center;">
		<b>
			Call Recordings
		</b>
	</h4>
	<div class="row" style="display:none;">
		<mat-form-field appearance="fill"  style="width: 100%;">
			<mat-label>Select Day</mat-label>
			<!-- daywise.threecalls -->
			<mat-select [(ngModel)]="selectedday" (ngModelChange)="onDaySelection()">
			  <mat-option *ngFor="let day of dayWiseThreeCalls" [value]="day.value"> 
				{{day.viewValue}}
				<!-- -{{day.value}} -->
			  </mat-option>
			</mat-select>
		  </mat-form-field>
		  
		  
	</div>
	<div class="row" style="margin-top:4px;" >
		<div class="col-md-12">
			<mat-dialog-content>
				<mat-slider class="time-slider" min="0" [max]="state?.duration" step="1" [value]="state?.currentTime"
					(input)="onSliderChangeEnd($event)" [disabled]=" state?.error"></mat-slider>
				<div *ngIf="state?.readableDuration && state?.currentTime">
					Duration: {{ state?.readableDuration }}<br>
					Current time: {{ state?.readableCurrentTime }}
				</div>
			</mat-dialog-content>
		</div>
		<div class="col-md-12" >
			<mat-dialog-actions align="end" >
				<div class="col-md-3" style="text-align: center;">
					<button class="mat-focus-indicator mat-button mat-button-base" matTooltip="Play" style="height: 32px;" (click)="play()">
						<mat-icon style="margin-top: 3px;">play_arrow</mat-icon>
					</button>
					</div>
					<div class="col-md-3" >
					<button class="mat-focus-indicator mat-button mat-button-base" matTooltip="Pause" style="height: 32px;" (click)="pause()">
						<mat-icon class="mt-1">pause</mat-icon>
					</button>
				</div>
			</mat-dialog-actions>
		</div>
	</div>

	<div class="col-md-12">
		<div style="text-align: right;margin-top: 30px;">
			<button mat-button (click)="close()" class="mat-focus-indicator mat-button mat-button-base" style="border: 1px solid #3fb91f !important; background: #fff !important; color: #3fb91f !important;">Close</button>
		</div>
	</div>
</div>