import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { MyserviceService } from '../myservice.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {

        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
        day: parseInt(date[0], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class HiringComponent implements OnInit {
  public linkHome = "/";
  hiringForm: FormGroup;
  submitted = false;
  dob: NgbDateStruct;
  maxDate: any;
  api_end_point:any;
  
  constructor(private formbuilder: FormBuilder, private API: ApiService, public router: Router,private myService:MyserviceService) {
    this.hiringForm = this.formbuilder.group({
      source: ['', Validators.required],
      name: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
      whatsappNo: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")]],
      gender: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      city: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      state: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
      interest: ['', [Validators.required]],
      graduation: ['', [Validators.required]],
      laptop: ['', [Validators.required]],
      internet: ['', [Validators.required]],
      android: ['', [Validators.required]],
      language: ['', [Validators.required]],
    })
    const today = new Date();
    this.maxDate = {year: today.getFullYear(), month: today.getMonth()+1, day: today.getDate()}
  }

  ngOnInit(): void {
	this.api_end_point = this.myService.get_API_End_Point();
  }

  get f() { return this.hiringForm.controls; }

  onSubmit(hiringForm: any) {
    this.submitted = true;
    if (this.hiringForm.invalid) {
      return;
    }
    let data = {
      'name': hiringForm.value.name,
      'email': hiringForm.value.email,
      'phone': '+91' + hiringForm.value.phone,
      'gender': hiringForm.value.gender,
      'dob': hiringForm.value.dob.year + "-" + hiringForm.value.dob.month + "-" + hiringForm.value.dob.day,
      'city': hiringForm.value.city,
      'state': hiringForm.value.state,
      'education': hiringForm.value.graduation ? 'UG' : "",
      'language': hiringForm.value.language == 'yes' ? ['Hindi', 'English'] : "",
      "additional_data": JSON.stringify(
        {
          'source': hiringForm.value.source,
          'whatsapp': hiringForm.value.whatsappNo,
          'wfh_interest': hiringForm.value.interest,
          'setup_options': hiringForm.value.internet == 'yes' ? ['Broadband Internet', 'Wireless Internet (2G | 3G | 4G)', 'Android Phone', 'Desktop/Laptop'] : ""
        }
      ),
      "reg_mode": "WEB ADS"
    }
    this.API.postCmailUser(data,this.api_end_point).subscribe((data: any) => {
      
      if (data.errors) {
        alert(data.errors);
      }else{
        alert("Application Posted Successfully");
        this.hiringForm.reset();
        window.location.href = 'https://uearn.ai';
      }
    });
  }
}
