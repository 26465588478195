import { Component, OnInit, Output, EventEmitter } from '@angular/core';


import { ServicesService } from '../services.service';
import { Chart } from 'angular-highcharts';
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-recruiter',
  templateUrl: './recruiter.component.html',
  styleUrls: ['./recruiter.component.scss']
})
export class RecruiterComponent implements OnInit {
	
	recruiterL1Chart: any;
	recruiterL2Chart: any;
	recruiterL3Chart: any;
	dashSummary: any = {
		'l1_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'l2_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'client_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'training': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		}
	}
	campaignSummary:any = {
		'summary': {
			'total': 0,
			'data_tocall': 0,
			'data_called': 0,
			'data_contacted': 0,
			'data_selected': 0,
			'data_rejected': 0,
			'data_rejected_perc': 0,
			'data_selected_perc': 0,
			'total_perc': 100.0,
			'data_called_perc': 0,
			'data_contacted_perc': 0,
			'data_selected_l1': 0,
			'data_rejected_l1': 0,
			'data_rejected_l1_perc': 0,
			'data_selected_l1_perc': 0,
			'data_selected_l2': 0,
			'data_rejected_l2': 0,
			'data_rejected_l2_perc': 0,
			'data_selected_l2_perc': 0,
			'data_selected_client': 0,
			'data_rejected_client': 0,
			'data_rejected_client_perc': 0,
			'data_selected_client_perc': 0,
		},
		'campaigns': {
			'total': 0,
			'active': 0,
			'inactive': 0,
		},
		'campaigns_active': {
			'total': 0,
			'data_tocall': 0,
			'data_called': 0,
			'data_contacted': 0,
			'data_selected': 0,
			'data_rejected': 0,
			'data_rejected_perc': 0,
			'data_selected_perc': 0,
			'total_perc': 100.0,
			'data_called_perc': 0,
			'data_contacted_perc': 0,
		},
		'campaigns_inactive': {
			'total': 0,
			'data_tocall': 0,
			'data_called': 0,
			'data_contacted': 0,
			'data_selected': 0,
			'data_rejected': 0,
			'data_rejected_perc': 0,
			'data_selected_perc': 0,
			'total_perc': 100.0,
			'data_called_perc': 0,
			'data_contacted_perc': 0,
		},
		'today_campaigns': {
			'total': 0,
			'active': 0,
			'inactive': 0
		},
		'agencies': {
			'organic': 0,
			'online': 0,
			'offline': 0
		},
		'agency_name': {
			'Vahan': 0,
			'Workex': 0,
			'Capricco': 0,
			'Others': 0,
			'Vahan_Perc': 0,
			'Workex_Perc': 0,
			'Capricco_Perc': 0,
			'Cii':0,
			'CII_Perc':0,
			'AspiringMinds':0,
			'AspiringMinds_Perc':0,
			'Others_Perc': 0
		},
		allData: []
	} as any;
	@Output() applicantDataRefresh: EventEmitter<any> = new EventEmitter();
	constructor(
		private dashService: ServicesService
	) { }

	ngOnInit() {
		// this.applicantDataRefresh.emit(null);
		this.dashService.dashSummaryData.subscribe(
			(d: any) => {
				if (d) {
					//console.log(1)
					this.dashSummary = d;
				}
				// debugger;
			}
		)
		// this.dashService.campaignSummaryData.subscribe(
		// 	(d: any) => {
		// 		console.log(2,d)
		// 		this.campaignSummary = d;
		// 		this.preparePieChart();

		// 		// debugger;
		// 	}
		// )
		this.dashService.applicantData.subscribe((d:any) => {
		//	console.log(d);
			var l1Sel = 0,l1Rej = 0, l2Sel = 0,l2Rej = 0, clientSel = 0,clientRej = 0, total = d ? d.length : 0;
			for(const obj of d){
				if(obj.applicant_status == 'Level 1 Selected' || obj.current_status == 'Level 1 Selected'){
					l1Sel = l1Sel + 1;
				}else if(obj.applicant_status == 'Level 1 Rejected' || obj.current_status == 'Level 1 Rejected'){
					l1Rej = l1Rej + 1;
				}else if(obj.applicant_status == 'Level 2 Selected' || obj.current_status == 'Level 2 Selected'){
					l2Sel = l2Sel + 1;
				}else if(obj.applicant_status == 'Level 2 Rejected' || obj.current_status == 'Level 2 Rejected'){
					l2Rej = l2Rej + 1;
				}else if(obj.applicant_status == 'Client Selected' || obj.current_status == 'Client Selected'){
					clientSel = clientSel + 1;
				}else if(obj.applicant_status == 'Client Rejected' || obj.current_status == 'Client Rejected'){
					clientRej = clientRej + 1;
				}
			}
			//console.log(l1Sel)
			this.campaignSummary.summary.data_selected_l1 = l1Sel;
			this.campaignSummary.summary.data_selected_l2 = l2Sel;
			this.campaignSummary.summary.data_selected_client = clientSel;
			this.campaignSummary.summary.data_rejected_l1 = l1Rej;
			this.campaignSummary.summary.data_rejected_l2 = l2Rej;
			this.campaignSummary.summary.data_rejected_client = clientRej;
			
			this.campaignSummary.summary.data_selected_l1_perc = this.calculatePercBy(l1Sel, total);
			this.campaignSummary.summary.data_selected_l2_perc = this.calculatePercBy(l2Sel, total);
			this.campaignSummary.summary.data_selected_client_perc = this.calculatePercBy(clientSel, total);

			this.campaignSummary.summary.data_rejected_l1_perc = this.calculatePercBy(l1Rej, total);
			this.campaignSummary.summary.data_rejected_l2_perc = this.calculatePercBy(l2Rej, total);
			this.campaignSummary.summary.data_rejected_client_perc = this.calculatePercBy(clientRej, total);

			this.preparePieChart();
		})
		
	}

	calculatePercBy(num1, num2) {
		if (num1 == 0 && num2 == 0) {
			return "0.0";
		}
		var value: any = (num1 / num2) * 100;
		value = value.toFixed(1);
		return value
	}

	valueChecker(val, def) {
		// console.log(val)
		try {
			if (val) {
				return val;
			}
			return def;
		} catch (e) {
			return def;
		}
	}

	preparePieChart(){
		let chartdata = new Chart({
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						distance: -30,
						enabled: true,
						color: 'white',
						style: {
							fontWeight: '500',
						}
					},
					showInLegend: false,
					borderWidth: 5,
				},
			},

			series: [
				{
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							enabled: false
						}
					},
					name: 'Active',
					data: [
						
						{
							name: this.campaignSummary.summary.data_selected_l1_perc,
							y: Number(this.campaignSummary.summary.data_selected_l1_perc), color: '#606d35'
						}, {
							name: this.campaignSummary.summary.data_rejected_l1_perc,
							y: Number(this.campaignSummary.summary.data_rejected_l1_perc), color: '#ff6619'
						},
						// {
						// 	name: '32%',
						// 	y: 32, color: '#fbbf29'
						// }
					],
					type: 'pie',
					
					innerSize: '40%',
				}]
		});

		let chartdata2 = new Chart({
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						distance: -30,
						enabled: true,
						color: 'white',
						style: {
							fontWeight: '500',
						}
					},
					showInLegend: false,
					borderWidth: 5,
				},
			},

			series: [
				{
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							enabled: false
						}
					},
					name: 'Active',
					data: [
						
						{
							name: this.campaignSummary.summary.data_selected_l2_perc,
							y: Number(this.campaignSummary.summary.data_selected_l2_perc), color: '#e31359'
						}, {
							name: this.campaignSummary.summary.data_rejected_l2_perc,
							y: Number(this.campaignSummary.summary.data_rejected_l2_perc), color: '#1e7ec6'
						},
						// {
						// 	name: '32%',
						// 	y: 32, color: '#09566d'
						// }
					],
					type: 'pie',
					
					innerSize: '40%',
				}]
		});

		let chartdata3 = new Chart({
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						distance: -30,
						enabled: true,
						color: 'white',
						style: {
							fontWeight: '500',
						}
					},
					showInLegend: false,
					borderWidth: 5,
				},
			},

			series: [
				{
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							enabled: false
						}
					},
					name: 'Active',
					data: [
						
						{
							name: this.campaignSummary.summary.data_selected_client_perc,
							y: Number(this.campaignSummary.summary.data_selected_client_perc), color: '#9845a1'
						}, {
							name: this.campaignSummary.summary.data_rejected_client_perc,
							y: Number(this.campaignSummary.summary.data_rejected_client_perc), color: '#caae3c'
						},
						// {
						// 	name: '32%',
						// 	y: 32, color: '#13b18b'
						// }
					],
					type: 'pie',
					
					innerSize: '40%',
				}]
		});
		this.recruiterL1Chart = chartdata;
		this.recruiterL2Chart = chartdata2;
		this.recruiterL3Chart = chartdata3;
	}

}
