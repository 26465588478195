<app-menu [navigateLink]="currentTitle"></app-menu>
<br>
<br>


<!-- <div *ngIf="!isVisibleSelectClient">
    <mat-card>
        <div class="PMain">
            <p class="TR-P-client-choose">            
                <mat-form-field appearance="outline">
                    <mat-select [(value)]="selectedAdmin">
                      <mat-option>None</mat-option>
                      <mat-option value="admin">Admin</mat-option>
                      <mat-option value="other">Other</mat-option>
                    </mat-select>
                  </mat-form-field>
            </p>
        <p class="TR-P-client">
            <mat-form-field class="TR-search-client">
                <input matInput placeholder="Search a client">
                <i class="fa fa-search TR-search-icon" matSuffix></i>
              </mat-form-field>
            </p>
        <h3 class="TR-header">Select A Client</h3>
        <div class="client-flex-container">
            <div *ngFor="let v of selectClientView" class="divClient" (click)="clientViewSelect(v.company,v.nameImg)">
             <div>
              <img [src]="v.nameImg" class="clientImg"/>
             </div>
             <span class="c-name">{{v.company}}</span>
            </div>           
          </div>
        </div>
    </mat-card>
  </div> -->
  
  
  <div style="position: relative;top: -4px;">
  <mat-sidenav-container autosize style="min-height: calc(100vh);">
    <mat-sidenav #sidenav class="TR-sidenav" mode="side" opened="true" style="position: fixed; margin-top: 68px;width: 17% !important;background-color: #fff !important;">
      <div>
      <mat-nav-list>    
      <!-- <mat-nav-list>
  <span>
  <img src="../assets/uearn_moneyLogo.svg" class="imgLOGO"/>
  </span>
  <span>
    <img [src]="_renderClientImage" class="img-client" />
    <b style="color: white;font-size: 12px;position: relative;
    right: 0;bottom: 3px;float: right;    top: 10px;">{{_companyName}}</b>
  </span>
      </mat-nav-list> -->

        <mat-list-item (click)="clickForEmptySelectCompany()"  [routerLink]="['./dashboardTraining']" [routerLinkActive]="['active']">       
           <p matLine> <img  src="../assets/dashboardicon.svg" class="TR-imag"/>Dashboard</p>
        </mat-list-item>
        <mat-list-item (click)="clickForEmptySelectCompany()"  [routerLink]="['./assessment']" [routerLinkActive]="['active']">
          <p matLine><img  src="../assets/agenticon.svg" class="TR-imag"/>Assessment</p>
       </mat-list-item>
        <mat-list-item (click)="clickForEmptySelectCompany()"  [routerLink]="['./agents']" [routerLinkActive]="['active']">
            <p matLine><img  src="../assets/agenticon.svg" class="TR-imag"/>Agents</p>
         </mat-list-item>
         <mat-list-item (click)="clickForEmptySelectCompany()"  [routerLink]="['./batch']"  [ngClass]="{'active': currentRoute  == '/training/create_batch' ||  currentRoute == '/training/batch'}" >
            <p matLine><img  src="../assets/batchicon.svg" class="TR-imag"/> Batch</p>
         </mat-list-item>
         <mat-list-item (click)="clickForEmptySelectCompany()"  [routerLink]="['./trainers']" [routerLinkActive]="['active']">
            <p matLine><img src="../assets/trainersicon.svg" class="TR-imag"/>Trainers</p>
         </mat-list-item>
  
         <mat-list-item [routerLinkActive]="['active']" (click)="toggleOjt()">        
        <p matLine><img src="../assets/ojticon.svg" class="TR-imag"/>OJT<span style="float: right;"><i class="fa fa-caret-down" style="color: #fd6c21;" aria-hidden="true"></i></span></p>   
       </mat-list-item>
  
  
       <mat-list-item (click)="clickForEmptySelectCompany('ojtBatch')" [routerLink]="['./onJobTraining']" [ngClass]="{'active': currentRoute  == '/training/onJobTraining' ||  currentRoute == '/training/create_ojt_batch'}" *ngIf="showPart">
        <p matLine style="font-size: 13px;text-align: right;">OJT Batch</p>
     </mat-list-item>
     <mat-list-item (click)="clickForEmptySelectCompany('ojtAgent')"  [routerLink]="['./onJobTrainingAgent']" [routerLinkActive]="['active']" *ngIf="showPart">
      <p matLine style="font-size: 13px;text-align: right;" >OJT Agents
      </p>
   </mat-list-item>
  
    <mat-list-item (click)="clickForEmptySelectCompany()" [routerLink]="['./certification']" [routerLinkActive]="['active']">
      <p matLine><img  src="../assets/certificationicon.svg" class="TR-imag"/>Certification</p>
   </mat-list-item>
  
  <mat-list-item (click)="clickForEmptySelectCompany()" [routerLink]="['./reports']" [routerLinkActive]="['active']">
    <p matLine><img src="../assets/reportsicon.svg" class="TR-imag"/>Reports</p>
  </mat-list-item>
  
  <mat-list-item (click)="clickForEmptySelectCompany()"  [routerLink]="['./calandar']" [routerLinkActive]="['active']">
    <p matLine><img src="../assets/calandaricon.svg" class="TR-imag"/>Calendar</p>
  </mat-list-item>
  
  <mat-list-item (click)="clickForEmptySelectCompany()" [routerLinkActive]="['active']">
    <p matLine><img src="../assets/helpicon.svg" class="TR-imag"/>Help</p>
  </mat-list-item>
  
      </mat-nav-list>
    </div>
    </mat-sidenav>
    <div class="TR-sidenav-content">
      <div>



        <div class="row trainingTabList" >
          <div class="col-md-12" style="padding: 0px 3.5px;">
            <mat-button-toggle-group class="dasTrainingcs" #group="matButtonToggleGroup" [value]="selectedButtonToggleVal"
            (change)="onToggleButtonValChange(group.value)">
            <mat-button-toggle value="training">Training</mat-button-toggle>
            <mat-button-toggle value="managementTeam" style="border-right: solid 1px #0000001f;">Manage Team</mat-button-toggle>
          </mat-button-toggle-group>
          </div>
        </div>

<br>






       <mat-card style="height:75px;box-shadow: 0 2px 15px #d2d5d95e;">
        <div class="row pdt pico">
          <div class="col-md-9 col-sm-9 col-xs-12">
            <mat-form-field  [ngClass]="'inputClr'" style="position: relative;bottom: 12px;width: 210px;">     
              <mat-select placeholder="Select A Client" [(ngModel)]="companySelected"  (selectionChange)="clientCompanySelect($event.value)">
                <mat-option>None</mat-option>
                <mat-option [value]="d" *ngFor="let d of ClientCompany">{{d.fullname}} - {{d.project}}</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="d-flex flex-row justify-content-end ">
                <div style="cursor: pointer;" class="mr-4"  (click)="clickForEmptySelectCompany()"  [routerLink]="['/training/send-message']">
                <mat-icon aria-hidden="false" aria-label=""  style="font-size: 30px;color: #fd6c21;">message</mat-icon></div>
                <!-- <div class="mr-3" style="border-right: 1.2px solid #000; margin-top: -7px; border-style: dashed; border-left: 0px; border-top: 0px; border-bottom: 0px;"></div>

                <div class="mr-4" style="cursor: pointer;">
                <mat-icon aria-hidden="false" aria-label=""  style="font-size: 30px;color: #1dafa7;">search</mat-icon></div>
                <div class="mr-3" style="border-right: 1.2px solid #000; margin-top: -7px; border-style: dashed; border-left: 0px; border-top: 0px; border-bottom: 0px;"></div>

                <div class="mr-4" style="cursor: pointer;">
                <mat-icon aria-hidden="false" aria-label=""  style="font-size: 30px;color: #1dafa7;">notifications</mat-icon></div>
                <div class="mr-3" style="border-right: 1.2px solid #000; margin-top: -7px; border-style: dashed; border-left: 0px; border-top: 0px; border-bottom: 0px;"></div>
                <div class="mr-4" style="cursor: pointer;"> 
                  <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="" style="font-size: 30px;color: #1dafa7;">person_pin</mat-icon>
            <mat-menu #menu="matMenu">
             
              <button mat-menu-item (click)="logout()">Logout</button>
            </mat-menu>
              </div> -->
              </div>
              
         <!-- <table>
           <tr>
             <td>
              <div class="TA-outline"  (click)="clickForEmptySelectCompany()"  [routerLink]="['/training/send-message']">
                <span class="bryt"> <img src="../assets/messageBoxIcon.svg" class="TR-imag CR"/></span>
              </div>
           </td>
           <td >
            <span> <img src="../assets/profileicon.svg"  [matMenuTriggerFor]="menu" class="TR-imag CR"/></span>
            <mat-menu #menu="matMenu">
             
              <button mat-menu-item (click)="logout()">Logout</button>
            </mat-menu>
          </td>
          </tr>
         </table> -->
              </div>
         
            </div>
       </mat-card>
	   <div *ngIf="showhideManagementContent"><app-management-team></app-management-team></div>
      </div>
    <router-outlet></router-outlet>
  
    </div>
  
  </mat-sidenav-container>

  
  </div>