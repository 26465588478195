import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyserviceService } from 'src/app/myservice.service';
@Component({
  selector: 'app-contiinex-select-channel-dialog',
  templateUrl: './contiinex-select-channel-dialog.component.html',
  styleUrls: ['./contiinex-select-channel-dialog.component.scss']
})
export class ContiinexSelectChannelDialogComponent implements OnInit {
  listCB:any=['WhatsApp','Chat','Call','Text Me Back'];
  waModel:any=true;
  chatModel:any=true;
  callModel:any=true;
  textModel:any=true;
  customer_name:any;
  constructor(public dialogRef: MatDialogRef<ContiinexSelectChannelDialogComponent>,private _snackBar: MatSnackBar, private _service:MyserviceService) { }

  ngOnInit() {
    if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
      this.customer_name = this._service.get_loggedin_user().name;
    }
  }
  updateBtn(){

    let _cbxTrue={
      "wa":this.waModel,
      "chat":this.chatModel,
      "call":this.callModel,
      "text":this.textModel
    };

    let cursettings = this._service.get_loggedin_user();
    cursettings.touch_points = JSON.stringify(_cbxTrue);
    this._service.set_loggedin_user(cursettings);

    if (!(this.waModel||this.chatModel||this.callModel||this.textModel)) {
      this._snackBar.open('Atleast one option should be selected', 'close', {
        duration: 4000,
      });
    } else {
      this.dialogRef.close(_cbxTrue)
    }
  }
  skipBtn(){
    this.dialogRef.close('false')
  }
  closeDialog(){
    this.dialogRef.close('false')
  }
}
