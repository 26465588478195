<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	  
	  
	</mat-drawer>

    <div class="gig-sidenav-content">
        <div style="padding: 54px 8px;background-color: #f6f6f6 !important;background: #f6f6f6 !important;">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="titlehead thd1">BI Dashboard</h4>
                </div>
            </div>
            <div class="row bADashboardTabList">
                <div class="col-md-9" style="padding: 0px 3.5px;">
                    <mat-button-toggle-group class="dashBAcs" #group="matButtonToggleGroup"
                        [value]="selectedButtonToggleVal" (change)="onToggleButtonValChange(group.value)">
                        <mat-button-toggle value="dataTab">Data</mat-button-toggle>
                        <mat-button-toggle value="dialedTab">Dialed</mat-button-toggle>
                        <mat-button-toggle value="contactedTab">Contacted</mat-button-toggle>
                        <mat-button-toggle value="agentsTab">Agents</mat-button-toggle>
                        <mat-button-toggle value="activeTimeTab">Active Time</mat-button-toggle>
                        <mat-button-toggle value="qdeTab" style="border-right: solid 1px #0000001f;">QDE
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 cn-dat">{{getCurrentDate()}}</div>
            </div>

            <div class="row">
                <div class="col-md-12" *ngIf="showHideDataTabPage">
					<app-central-dashboard-data-page></app-central-dashboard-data-page>
                </div>
                <div class="col-md-12" *ngIf="showHideDialedTabPage">
                    <app-central-dashboard-dialed-page></app-central-dashboard-dialed-page>
                </div>
                <div class="col-md-12" *ngIf="showHideContactedTabPage">
                    <app-central-dashboard-contacted-page></app-central-dashboard-contacted-page>
                </div>
                <div class="col-md-12" *ngIf="showHideAgentsTabPage">
                    <app-central-dashboard-agents-page></app-central-dashboard-agents-page>
                </div>
                <div class="col-md-12" *ngIf="showHideActiveTimeTabPage">
                    <app-central-dashboard-active-time-page></app-central-dashboard-active-time-page>
                </div>
                <div class="col-md-12" *ngIf="showHideQdeTabPage">
                    <app-central-dashboard-qde-page></app-central-dashboard-qde-page>
                </div>
            </div>

        </div>
    </div>
    <div id="cover-spin" *ngIf="showLoader"></div>
</mat-drawer-container>