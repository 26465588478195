import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MyserviceService} from '../../myservice.service';
import { ApiService } from '../../api.service';
@Component({
  selector: 'app-contiinex-preview-dialog',
  templateUrl: './contiinex-preview-dialog.component.html',
  styleUrls: ['./contiinex-preview-dialog.component.scss']
})
export class ContiinexPreviewDialogComponent implements OnInit {
  fetchDataTreeGraph:any=[];
  fetchDataFlowData:any=[];
  flowData:any=[];
  
customer_name:string;
projects:any;
project_name:any = "";

  nodes: any[] = [
    {
      id: 'first',
      label: 'Idle'
    }, {
      id: 'second',
      label: 'Confirm_For_Catalogue (WA-Text)'
    }, {
      id: 'third',
      label: 'Options_Presented (WA-Image)'
    }, {
      id: 'fourth',
      label: 'Selection_Confirmation (WA-Text)'
    }, {
      id: 'fifth',
      label: 'Order_Confirmed'
    }
  ];

  links: any[] = [
    {
      id: 'a',
      source: 'first',
      target: 'second',
      label: 'Request_For_Catalogue'
    }, {
      id: 'b',
      source: 'second',
      target: 'third',
      label: 'Know_More_Clicked'
    }, {
      id: 'c',
      source: 'third',
      target: 'fourth',
      label: 'Selected_Option'
    }, {
      id: 'd',
      source: 'fourth',
      target: 'fifth',
      label: 'Buy_Now_COD_Selected'
    }
  ];
  itemsjsonDataTree:any=[];
  api_end_point:any;
  constructor(	public dialogRef: MatDialogRef<ContiinexPreviewDialogComponent>,private _service:MyserviceService, private API: ApiService,) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.showGraph();
    this.getPreviewData();
  }
  closeDialog(){
    this.dialogRef.close()
  }


    showGraph() {
      this.fetchDataFlowData =[
        {"title":"Idle","edgeText":"Request_For_Catalogue"},
        {"title":"Confirm_For_Catalogue (WA-Text)","edgeText":"Know_More_Clicked"},
        {"title":'Options_Presented (WA-Image)',"edgeText":"Selected_Option"},
        {"title":'Selection_Confirmation (WA-Text)',"edgeText":"Buy_Now_COD_Selected"},
        { "title":'Order_Confirmed',"edgeText":""}
]


    }
    getPreviewData(){
       if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
         this.projects = this._service.get_loggedin_user().project;
         this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
         this.project_name = String(this._service.get_current_project()).toLowerCase();
         this.project_name = this.project_name == 'undefined' ? '' : this.project_name;
       }
         this.API.getpreItemsData(this.customer_name,this.project_name,this.api_end_point).subscribe((res: any) => {
           let _response= res;
           this.itemsjsonDataTree=_response;  
         }, (err) => {
         })
     }
    //   this.fetchDataTreeGraph =[
    //     {"topLabel":"Idle",
    //     "data": [
    //             {"title":"Confirm_For_Catalogue (WA-Text)","edgeText":"Request_For_Catalogue",
    //             "child":[
    //                         {"title":'Options_Presented (WA-Image)',"edgeText":"Know_More_Clicked",
    //                         "child":[
    //                           {"title":'Selection_Confirmation (WA-Text)',"edgeText":"Selected_Option",
    //                           "child":[
    //                             { "title":'Order_Confirmed',"edgeText":"Buy_Now_COD_Selected"}
    //                           ]
    //                         }
                            
    //                         ]},
                
    //           //  {"title":'Child2',"edgeText":""}    
    //             ],
                
    //           },
    //    // {"title":"Second","edgeText":"",
    //     //"child":[
    //      // {"title":'Child3',"edgeText":"",},
    //   //  ]}
    //   ]
    // }]
  


//   this.flowData=[
//     {title:'Parent', data:[
//      {title:'child1',edge:'edge1', 
//      data:[
//        {title:'subchild1',edge:'edge11' ,  data:[
//          {title:'grandchild1',edge:'AAA' }]},
//        {title:'subchild2',edge:'edge12' ,  data:[
//          {title:'grandchild2',edge:'BBB' }]},
//        {title:'subchild3',edge:'edge13', data:[
//          {title:'grandchild3',edge:'CCC' }]  },
//      ]
//    },
//      {title:'child2',edge:'edge2',  data:[
//        {title:'subchild2',edge:'edge14', data:[
//          {title:'grandchild4',edge:'DDD' }] }] },
//      {title:'child3',edge:'edge3',  data:[
//        {title:'subchild3',edge:'edge15', data:[
//          {title:'grandchild5',edge:'EEE' }] }]  }
//  ]
// }]

}
