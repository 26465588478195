<div  #container>
<form [formGroup]="assessmentForm">
	<table class="tbl mt-3">
		<tr style="border-bottom: 1px solid #ccc;">
			<td>
				<h5 class="hd5">Create Assessment</h5>
			</td>
			<td style="text-align: right;"> 
				<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup('')"></i>
			</td>
	   </tr>
	   <tr><td colspan="2" *ngIf="showHideMessage">
		   <p class="mgClr"  [ngStyle]="{'color':msgColor}">{{showHideMessageView}}</p>
	   </td></tr>
	   <!-- <tr>
		<td><span>Assessment Type</span></td>
		<td>
		 <mat-form-field>
			 <mat-select formControlName="category" [(ngModel)]="assessmentType">
				 <mat-option value="hiring">Hiring</mat-option>
				 <mat-option value="training">Training</mat-option>
			 </mat-select>
		 </mat-form-field>
		</td>
	</tr> -->
	</table>
	<div class="scrollAss" #container>
	<table class="tbl mt-3">
	   <tr>
		<td><span>Name*</span></td>
		<td>
		   <mat-form-field>
			  <input matInput placeholder="Name" autocomplete="off" formControlName="name" [ngClass]="{'is-invalid': _form.name.errors}" (keypress)="omit_special_char($event)"  maxlength="100">
			  <div *ngIf="_form.name.errors" class="invalid-feedback">
				  <div *ngIf="_form.name.errors.required">Name is required</div>
			  </div>
		   </mat-form-field>
		</td>
	 </tr>
	 <tr>
		<td><span>Default</span></td>
		<td>
		 <mat-form-field>
			 <mat-select formControlName="default" [(ngModel)]="default">
				 <mat-option value="1">True</mat-option>
				 <mat-option value="0">False</mat-option>
			 </mat-select>
		 </mat-form-field>
		</td>
	</tr>
	<tr>
		<td><span>Total Score*</span></td>
		<td>
		   <mat-form-field>
			  <input matInput placeholder="Total Score" autocomplete="off" formControlName="totalScore" [ngClass]="{'is-invalid': _form.totalScore.errors}" (keypress)="onlyNumberValidation($event)" maxlength="4">
			  <div *ngIf="_form.totalScore.errors" class="invalid-feedback">
				  <div *ngIf="_form.totalScore.errors.required">Total score is required</div>
			  </div>
		   </mat-form-field>
		</td>
	 </tr>
	   <tr>
		  <td><span>Passing Score*</span></td>
		  <td>
			 <mat-form-field>
				<input matInput placeholder="Passing Score" autocomplete="off" formControlName="passingScore" [ngClass]="{'is-invalid': _form.passingScore.errors}" (keypress)="onlyNumberValidation($event)"  maxlength="4">
				<div *ngIf="_form.passingScore.errors" class="invalid-feedback">
					<div *ngIf="_form.passingScore.errors.required">Passing score is required</div>
				</div>
			 </mat-form-field>
		  </td>
	   </tr>
	   <tr>
		<td><span>Timing(in mins)*</span></td>
		<td>
		   <mat-form-field>
			  <input matInput placeholder="Timing" autocomplete="off" formControlName="timing" [ngClass]="{'is-invalid': _form.timing.errors}" (keypress)="onlyNumberValidation($event)"  maxlength="4">
			  <div *ngIf="_form.timing.errors" class="invalid-feedback">
				  <div *ngIf="_form.timing.errors.required">Timing is required</div>
			  </div>
		   </mat-form-field>
		</td>
	 </tr>
	 <tr *ngIf="assessmentType == 'training'">
		<td><span>Select Batch</span></td>
		<td>
			<!-- <mat-checkbox>All</mat-checkbox> -->
			<mat-form-field>
			<mat-select multiple placeholder="Select A Batch" formControlName="selectedBatchForAssessment" (selectionChange)="changeBatch($event.value)">
				<mat-option style="font-family:poppins" [value]="v.id" *ngFor="let v of batchOptionList">Batch {{v.id}}({{v.training_type}})</mat-option>
			  </mat-select>
			</mat-form-field>
		</td>
	 </tr>
	<tr>
		<td>&nbsp;</td>
		<td> 
			<span class="dnldbtn">
			<button mat-button (click)="download_file()">Download Excel Template
			&nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
			</button>
			</span>
		</td>
	</tr>
	   <tr>
		  <td><span>Upload Questions<br/> (only supports xlsx file)*</span></td>
		  <td>
			 <!-- <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)"/> -->
			 <!-- <label for="file">Upload</label> -->
			 <div class="upload-btn-wrapper">
				<button class="btnbrowse">Browse</button>
			 <input type="file" #fileUpload class="filecs" id="fileID" formControlName="file" accept=".xlsx" (change)="changeFile($event)"/>
			  </div>
			  <span>
				<button mat-button [disabled]="!assessmentForm.valid || disableSubmit" class="upload_btn uploadSave" (click)="container.scrollTop = 0;submit_Camapign(assessmentForm)">Submit assessment </button>
			  </span>
		  </td>
	   </tr>
	   <tr [hidden]="isHidden">
		<td><span>Delete Assessment</span></td>
		<td>
			<span class="dnldbtn">
				<button mat-button (click)="delete_assessment()">Delete</button>
				</span>
		</td>
	 </tr>
	   <tr>
		   <td></td>
		   <td style="color: #bababa;">{{currentFileobj.name}}</td>
	   </tr>
	</table>
</div>
	<p class="succData" [style.color]="_color" *ngIf="assessmentUpload_msg">{{ assessmentUpload_msg }}</p>

</form>
</div>
<div id="cover-spin" *ngIf="showLoader"></div>
