<h3 class="sBiz-title">SmarterBiz</h3><br>
<div class="text-center">
    <p  class="hdtext"><span> On-Boarding Process</span></p>
    <p class="LText">Boost your operational efficiency with realtime<br>
        customer journey orchestration across channels.</p>
   
</div>

<div>
<mat-card class="m-card">
    <p *ngIf="showHideMessage" style="font-weight: bold;" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
    <form  [formGroup]="createdForm">
    <div class="row bgText">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            TELCO INFRA PLANNING REQUIREMENT (OUTBOUND)
        </div>
    </div>


<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">TYPE OF LINES  <span class="starMandatory">*</span></span>
        <br>
       <mat-checkbox class="mcbx" formControlName="promotional">Promotional</mat-checkbox>
       <mat-checkbox class="mcbx" formControlName="transational">Transational</mat-checkbox>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">TYPE OF DIALING </span>
        <br>
       <mat-checkbox formControlName="predictiveDialing" class="mcbx">Predictive Dialing</mat-checkbox>
       <mat-checkbox formControlName="serverDialing" class="mcbx">Server Dialing</mat-checkbox>
       <mat-checkbox formControlName="normalDialing" class="mcbx">Normal Dialing</mat-checkbox>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">PREDICTIVE RATIO </span><br><br>
        <span class="labelText">Current Dialing ratio </span>
        <br>
        <mat-form-field style="width: 100%;">
            <input matInput placeholder="Your Answer" formControlName="currentDialingAnswer"/>
            </mat-form-field>
    </div>
 
    <div class="col-md-12 col-sm-12 col-xs-12">   <br>
        <span class="labelText">Preferred Dialing ratio</span>
        <br>
        <mat-form-field style="width: 100%;">
            <input matInput placeholder="Your Answer" formControlName="preferredDialingAnswer"/>
            </mat-form-field>
    </div>
</div>
<br><br>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
       <button class="onboard-submit"  (click)="onNextBtn(createdForm)">NEXT</button>
    </div>
</div>
<br><br>
</form>
</mat-card>
</div> 
