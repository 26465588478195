import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as highcharts from 'highcharts';
import * as Highcharts from 'highcharts';
import {Router} from '@angular/router'
import {MyserviceService} from '../../myservice.service';
import { ApiService } from 'src/app/api.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contiinex-dashboard',
  templateUrl: './contiinex-dashboard.component.html',
  styleUrls: ['./contiinex-dashboard.component.scss']
})

export class ContiinexDashboardComponent implements OnInit {
  //lineChart:any;
  selected:any;
  projects:any;
  session:any;
  Highcharts = Highcharts;
  //AreachartOptions:any;
  showHideMainDashboard:boolean=true;
  showHideprojectOne:boolean=false;
  selectedDayWise:any;
  showHideBasedOnProject:boolean=false;

  showHideCustomDate:boolean=false;
  //showHideCustomTime:boolean=false;
   _startCustomDate:any;
  _endCustomDate:any;
  
  customer_name:string;
  project_name:string = "";
  business_phones:string="";
  selected_business_phone:string="";

  api_end_point:any;
	currentTitle = 'Dashboard';
  currentUrl:any;
  constructor(private router:Router, private _service:MyserviceService,private API:ApiService,private _snackBar: MatSnackBar) {
    window.scrollTo(0,0);
    
  }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    this.session = this.getParameterByName('session');
    if (this.session) {
      this._service.clearAllItems();
      window.sessionStorage.clear();
      sessionStorage.setItem('from_external', 'true');
      this.processRedirectedUser(JSON.parse(atob(this.session)))

    }
    this.selected="allProjects";
    this.selectedDayWise="Today";
    this.selected_business_phone="ALL";
    this.projects = this._service.get_loggedin_user().project;
    this._service.set_current_time_range(this.selectedDayWise);
// if(this.projects=="wa_flow"){
//   this.selected="wa_flow";
//   this.OnChangeProject("wa_flow")
// }
// if(this.projects=="WABA"){
//   this.selected="WABA";
//   this.OnChangeProject("WABA")
// }
// if(this.projects=="ivr_wa_in_out"){
//   this.selected="ivr_wa_in_out";
//   this.OnChangeProject("ivr_wa_in_out")
// }
if(this.projects){
  this.selected=this.projects;
  this.OnChangeProject(this.projects)
}


    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
          this.projects = String(this._service.get_loggedin_user().project);
          this.business_phones = String(this._service.get_loggedin_user().phone);
         this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
         this.project_name = String(this._service.get_loggedin_user().project).toLowerCase();
        this.project_name = this.project_name == 'undefined' ? '' : this.project_name;
    
      }
  }

  processRedirectedUser(res) {
    var userDetails = res, stageSettings;
    let access_token = res['access_token'];
    let landing_page = '';
    if (access_token) {
      this._service.set_access_token(access_token);
      localStorage.setItem('access_token', access_token);
      this._service.set_loggedin_user(res);
    }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  OnChangeProject(val:any) {
    this.showHideMainDashboard = false;
    this.showHideprojectOne = false;
    this._service.set_current_project(val);

    if (val != "allProjects") {
      this.router.navigateByUrl('/Dashboard/project1');
      this.showHideprojectOne = true;
      this.showHideBasedOnProject = true;

    } else {
      this.router.navigateByUrl('/Dashboard');
      this.showHideMainDashboard = true;
      this.showHideBasedOnProject = false;
    }
  }

  OnChangeBusinessPhone(val:any) {
    //  console.log('OnChangeBusinessPhone', val, this.project_name);
      this._service.set_current_business_phone(val);
      this.selected_business_phone = val;
  }

getCurrentDate(){
  let myDate = new Date();
let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
let date = myDate.getDate();
let month = monthsList[myDate.getMonth()+1];
let year = myDate.getFullYear();
let day = daysList[myDate.getDay()];
let today = `${date} ${month} ${year}, ${day}`;
return today;
}
OnChangeDayTime(val:any){
 // this.showHideCustomTime=false;
  this.selectedDayWise=val;
  this._service.set_current_time_range(this.selectedDayWise);

  if(val=="CustomDate"){
   this.showHideCustomDate=true;
  }
  else{
   this.showHideCustomDate=false;
  }
}

dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this._startCustomDate=moment(dateRangeStart.value).format('YYYY-MM-DDTHH:mm:ss');
   this._endCustomDate=moment(dateRangeEnd.value).format('YYYY-MM-DDTHH:mm:ss');

   let _sTime = (document.getElementById("myStartTime") as HTMLInputElement).value;
   let _eTime = (document.getElementById("myEndTime") as HTMLInputElement).value;
  // this._service.set_current_time_range("NotClickVerify");
   if(this._startCustomDate !="Invalid date" && this._endCustomDate !="Invalid date"){
     
  
if(_sTime=="00:00" && _eTime=="23:59"){
    let _splitStartDate=this._startCustomDate.split("T");
    let _joinStartDate= _splitStartDate[0] + "T" + _sTime + ":00"; 
  
    let _splitEndDate=this._endCustomDate.split("T");
    let _joinEndDate= _splitEndDate[0] + "T" + _eTime + ":00"; 

    let val=this.selectedDayWise + " " + _joinStartDate + " " + _joinEndDate;
    this._service.set_current_time_range(val);
}

else{
let val=this.selectedDayWise + " " +  this._startCustomDate + " " +  this._endCustomDate;
this._service.set_current_time_range(val);
}
   }
   
}
verifyButton(){
  let _sTime = (document.getElementById("myStartTime") as HTMLInputElement).value;
  let _eTime = (document.getElementById("myEndTime") as HTMLInputElement).value;
  /*if(_sTime =="" || _eTime==""){
     this._snackBar.open("Please enter start time and end time both.", 'close', {
     duration: 4000,
  });
  return;
  }
  else{
    this._snackBar.open("Set Done", 'close', {
      duration: 2000,
   });*/
  if(this._startCustomDate !="Invalid date" && this._endCustomDate !="Invalid date"){
    let _splitStartDate=this._startCustomDate.split("T");
    let _joinStartDate= _splitStartDate[0] + "T" + _sTime + ":00"; 
  
    let _splitEndDate=this._endCustomDate.split("T");
    let _joinEndDate= _splitEndDate[0] + "T" + _eTime + ":00"; 

    let val=this.selectedDayWise + " " + _joinStartDate + " " + _joinEndDate;
   this._service.set_current_time_range(val);
  }
}
//}
}


// qualityOfDeleiver(){
//   this.lineChart = new Chart({
//     chart: {
//       type: 'line',
//       height: 300
//      // borderWidth: 3
//     },
//     credits: {
//       enabled: false
//     },
//     title: {
//       text: ''
//     },
//     xAxis: {
//       categories: [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "Jun",
//         "July",
//         "Aug",
//         "Sep",
//         "Oct",
//         "Nov",
//         "Dec"
//       ],
//       gridLineWidth: 1,
//       minorGridLineWidth: 1

//     },
//     yAxis: {
//       title:null,
//       gridLineWidth: 1,
//       minorGridLineWidth: 1
//     },
//     legend: {
//       reversed: false,
//       verticalAlign: "top",
//       align: "right",
//       borderWidth: 1,

//     },
//     plotOptions: {
//       line: {
//         allowPointSelect: true,
//         cursor: 'pointer',
//         dataLabels: {
//           enabled: false
//         },
//         showInLegend: true
//       },

//     },
//     series: [

//       {
//         type:undefined,
//         name: "Sales",
//         data: [40, 50, 40, 70, 70, 60, 70, 70, 80, 90, 90, 85],
//         color: "#f2ba2a"
//       },
//       {
//         type:undefined,
//         name: "Orders",
//         data: [10, 30, 20, 60, 60, 40, 50, 40, 30, 20, 40, 30],
//         color: "#ed372d"
//       }
//     ]
//   });

// }


// getArea(){

//   let _AreachartOptions = new Chart( {

//      chart: {
//       //backgroundColor: 'red',
//  // plotBackgroundColor: 'green',
//  height: 300,
//         type: "areaspline",

//      },
//      title: {
//        text: ''
//      },
//      xAxis:{
//        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun'] ,
//        title: null,
//        gridLineWidth: 1,
//        minorGridLineWidth: 1,


//      },
//      yAxis : {
//        min:100,
//        title: null,
//        gridLineWidth: 1,
//   minorGridLineWidth: 1
//      },

//      plotOptions : {
//         area: {
//         pointStart: 100,
//           stacking: 'normal',
//           lineWidth: 2,
//           marker: {
//             enabled: false,
//             symbol: 'circle',
//             radius: 2,
//             states: {
//                 hover: {
//                     enabled: true,
//                 }
//             }
//         },
//        }
//      },

//      credits:{
//        enabled: false
//      },
//      series: [
//         {
//         lineColor:'#ed372d',
//         fillColor: '#fedbdb',
//           name:"Record",
//           type:undefined,
//            data: [100,400, 200, 300, 500,200],
//            color:'#f2ba2a'
//         }
//      ]
//   });
//   this.AreachartOptions=_AreachartOptions;
// }



