import { Injectable } from '@angular/core';
import { CHANNEL_SUMMARY, FOLLOW_UPS, AGENT_STATUS, CALLS_SUMMARY, AGENT_TABLE, AGENT_DATA, PROGRESS_BAR } from './generic-outbound.interface'
import { BehaviorSubject, concat } from 'rxjs';
import * as _ from 'underscore';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
import { Utils } from '../../utils';

@Injectable({
    providedIn: 'root',
})
export class GenericOutboundService {

    settings = this.myService.get_stage_settings();
    api_end_point = this.myService.get_API_End_Point();
    myUser = this.myService.get_loggedin_user();
    current_start_date: string;
    current_end_date: string;
    selected_agents: number[] = [];
    default_time_value: string = "00 : 00 : 00";

    lead_disposition_names: string[] = []

    channel_summary_default: CHANNEL_SUMMARY = {
        is_error: false,
        is_loading: false,
        incoming_call: 0,
        incoming_call_percent: 0,
        outgoing_call: 0,
        outgoing_call_percent: 0,
        calls_dialled: 0,
        calls_dialled_percent: 0,
        incoming_text: 0,
        incoming_text_percent: 0,
        outgoing_text: 0,
        outgoing_text_percent: 0,
        chats: 0,
        chats_precent: 0
    }

    followup_defaults: FOLLOW_UPS = {
        is_error: false,
        is_loading: false,
        followup: 0,
        followup_percent: 0,
        followup_done: 0,
        followup_done_percent: 0,
        followup_todo: 0,
        followup_todo_percent: 0,
    }

    agent_status_defaults: AGENT_STATUS = {
        is_loading: false,
        is_error: false,
        agents_total: 0,
        agents_total_percent: 0,
        agents_login: 0,
        agents_login_percent: 0,
        agents_active: 0,
        agents_active_percent: 0,
        agents_on_call: 0,
        agents_on_call_percent: 0,
        agents_on_idle: 0,
        agents_on_idle_percent: 0,
        agents_logout: 0,
        agents_logout_percent: 0
    }

    calls_summary_defaults: CALLS_SUMMARY = {
        is_loading: false,
        is_error: false,
        calls_dialled: 0,
        calls_dialled_percent: 0,
        calls_rnr: 0,
        calls_rnr_percent: 0,
        calls_contacted: 0,
        calls_contacted_percent: 0,
        calls_unique_contacted: 0,
        calls_unique_contacted_percent: 0,
        calls_new_data_dialled: 0,
        calls_new_data_dialled_percent: 0,
        calls_manual_dialled: 0,
        calls_manual_dialled_percent: 0
    }

    agent_table_defaults: AGENT_TABLE = {
        is_loading: false,
        is_error: false,
        agent_data: []
    }

    lead_dispoisition_defaults: any = {
        is_error: false,
        is_loading: false,
    }

    progress_bar_defaults: PROGRESS_BAR = {
        visible: false,
        value: 0,
        buffer_value: 0
    }

    api_error = new BehaviorSubject<boolean>(false);

    channel_summary = new BehaviorSubject(this.channel_summary_default)

    followups = new BehaviorSubject(this.followup_defaults)

    agents_status = new BehaviorSubject(this.agent_status_defaults)

    calls_summary = new BehaviorSubject(this.calls_summary_defaults)

    lead_disposition = new BehaviorSubject(this.lead_dispoisition_defaults)

    agent_table_data = new BehaviorSubject(this.agent_table_defaults);

    progress_bar = new BehaviorSubject(this.progress_bar_defaults);

    constructor(
        private myService: MyserviceService,
        private API: ApiService,
        private utils: Utils
    ) {
        this.load_lead_disposition_defaults();
        this.load_agent_table_data_defaults();
        // const obj = {"start_date":"2023-11-27T18:30:00","end_date":"2023-11-28T18:29:59","agents_list":[2062,2063,2156,3454,3455,3456,3457,3459,2358,2359,2360,2361],"user_group_id":2052}
        const obj = { "start_date": "2023-11-27T18:30:00", "end_date": "2023-11-28T18:29:59", "agents_list": [2929, 2930, 2931, 2932, 2933, 2934, 2935, 2936, 2938, 2943, 2944, 2946, 2947, 2948, 2949, 2950, 2951, 2952, 2953, 2954, 2955, 2956, 2957, 2958, 2959], "user_group_id": 2099 };
        const time_obj = { "start_date": "2023-11-27T18:30:00", "end_date": "2023-11-28T18:29:59", "users_list": [2929, 2930, 2931, 2932, 2933, 2934, 2935, 2936, 2938, 2943, 2944, 2946, 2947, 2948, 2949, 2950, 2951, 2952, 2953, 2954, 2955, 2956, 2957, 2958, 2959], "user_group_id": 2099 };
        // this.get_all_agents_data(obj);
        // this.get_all_agent_timers_data(time_obj);
        // this.get_all_agent_disposition_data(obj);
        this.myService.settings.subscribe(e => {
            if(e){
                this.settings = JSON.parse(e);
            }else{
                this.settings = null;
            }
        });
    }

    load_lead_disposition_defaults() {
        this.lead_disposition_names.length = 0;
        let defaults = { ...this.lead_dispoisition_defaults };
        console.log('stage settings', typeof this.settings, this.settings);
        if (this.settings && this.settings.hasOwnProperty('salesstages')) {
            if (this.settings['salesstages'].length) {
                this.settings['salesstages'].forEach(el => {
                    defaults[el.name] = 0
                    // { [el.name]: 0 })
                    // this.lead_dispoisition_defaults[el.name] = 0;
                    this.lead_disposition_names.push(el.name)
                })
            }
        }
        this.lead_dispoisition_defaults = { ...this.lead_dispoisition_defaults, ...defaults };
        console.log('lead disposition defaults', this.lead_dispoisition_defaults);
    }

    load_agent_disposition_defaults(agent_obj: AGENT_DATA) {
        if (this.lead_disposition_names.length) {
            this.lead_disposition_names.forEach(el => {
                agent_obj[el] = 0
            })
        }
        return agent_obj;
    }

    load_agent_defaults(user_details) {
        let agent_obj: AGENT_DATA = {
            user_id: user_details.user_id,
            name: user_details.name,
            email: user_details.email,
            notifications: 0,
            last_activity: '',
            sub_disposition: '',
            duration_now: this.default_time_value,
            last_activity_at: '',
            dialled: 0,
            unique_dialled: 0,
            contacted: 0,
            unique_contacted: 0,
            rnr: 0,
            forms: 0,
            login_time: 0,
            idle_time: 0,
            wait_time: 0,
            spoken_time: 0,
            active_time: 0,
            followup_completed: 0,
            followup_to_do: 0,
            login_time_hms: this.default_time_value,
            idle_time_hms: this.default_time_value,
            wait_time_hms: this.default_time_value,
            spoken_time_hms: this.default_time_value,
            active_time_hms: this.default_time_value,
            aht: this.default_time_value,
            audit_score: 0,
            wifi_speed: '',
            connect_status: '',
            connect_color: '',
            connectivity_src: ''
        }
        if (this.lead_disposition_names.length) {
            this.lead_disposition_names.forEach(name => {
                agent_obj[name] = 0;
            })
        }
        return this.load_agent_disposition_defaults(agent_obj)
    }

    load_agent_table_data_defaults() {
        const agents = this.get_all_agents_list();
        // console.log('agents list', agents)
        if (agents && agents.length) {
            let arr = []
            agents.forEach(agent => {
                arr.push(this.load_agent_defaults(agent));
            })
            this.agent_table_defaults = { ...this.agent_table_defaults, ...{ 'agent_data': arr } }
        }
    }

    get_all_agents_list() {
        var agents_list = [];
        for (const item of this.myService.getAllIds('user')) {
            if(this.selected_agents.indexOf(item.id) > -1){
                agents_list.push({ name: item.name, user_id: item.id, email: item.email, checked: true });
            }
        }
        return agents_list;
    }

    get_activity_status(row) {
        // console.log('Activity status row', row);
        let obj: any = {
            "first_activity": "",
            "first_activity_at": "",
            "last_activity": "",
            "last_activity_at": "",
            "duration_now": 0,
            "sub_disposition": "",
            "color": "Inherit"
        };
        try {
            if (row && row.activity && row.activity.last_activity) {
                if (row.activity.first_activity.hasOwnProperty('created_at')) {
                    obj.first_activity_at = this.utils.utcToLocal(row.activity.first_activity.created_at, 'YYYY-MM-DDTHH:mm:ss', 'LTS');
                    obj.first_activity = this.myService.renameStatusMessage(row.activity.first_activity.activity_type.trim());
                }
                if (row.activity.last_activity.hasOwnProperty('created_at')) {
                    obj.last_activity_at = this.utils.utcToLocal(row.activity.last_activity.created_at, 'YYYY-MM-DDTHH:mm:ss', 'LTS');
                    obj.last_activity = this.myService.renameStatusMessage(row.activity.last_activity.activity_type.trim());
                    obj.color = this.myService.getStatusColour(obj.last_activity);
                    if (obj.last_activity == 'Break') {
                        var breaks = ['Bio Break', 'Lunch Break', 'Coffee Break', 'Team briefing', 'Team briefing & Quality Feedback', 'End of Day Work', 'Campaign Finished'];
                        if (row.activity.last_activity.user_status && breaks.indexOf(row.activity.last_activity.user_status.trim()) > -1) {
                            obj.sub_disposition = row.activity.last_activity.user_status.trim();
                        }
                    }
                }
                if (row.activity.last_activity.hasOwnProperty('duration_now')) {
                    obj.duration_now = row.activity.last_activity.duration_now;
                }
            }
        } catch (e) {
            // this._snackBar.open("Start date should be less than end date *", 'close');
            // console.log('Error in last sync time', e);
        }
        // console.log('after act', obj);
        return obj;
    }

    get_all_agent_disposition_data(agents_data: AGENT_TABLE): Promise<AGENT_TABLE | undefined> {
        // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: true, is_error: false } });
        return new Promise((resolve, reject) => {
            try {
                const obj = {
                    "start_date": this.current_start_date,
                    "end_date": this.current_end_date,
                    "agents_list": this.selected_agents
                }
                this.API.genericCallDataDetails(obj, this.api_end_point).subscribe((res: any) => {
                    // console.log('agent disposition data', res.success)
                    if (res.success) {
                        const response = res.success;
                        if (response.hasOwnProperty('customer_data') && response['customer_data']['userid_stages']) {
                            const stages_data = response['customer_data']['userid_stages'];
                            // var agents_data = this.agent_table_data.value;
                            agents_data.agent_data.map(element => {
                                if (stages_data.hasOwnProperty(element.user_id)) {
                                    stages_data[element.user_id].forEach(stage => {
                                        element[stage.stage] = stage.data;
                                    })
                                }
                            })
                            agents_data.is_error = false;
                            agents_data.is_loading = false;
                            // this.agent_table_data.next(agents_data)
                            // console.log('after 3rd api ', JSON.stringify(this.agent_table_defaults));
                            resolve(agents_data)
                        } else {
                            reject()
                            // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: false, is_error: 'Disposition data not found.' } });
                        }
                    } else {
                        reject()
                    }
                }, (err) => {
                    reject()
                    // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: false, is_error: 'Error in the api.' } });
                })
            } catch (e) {
                reject()
                // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: false, is_error: 'agent disposition data exception' } });
            }
        })
    }

    get_all_agent_timers_data(agents_data: AGENT_TABLE): Promise<AGENT_TABLE | undefined> {
        // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: true, is_error: false } });
        return new Promise((resolve, reject) => {
            try {
                const obj = {
                    "start_date": this.current_start_date,
                    "end_date": this.current_end_date,
                    "users_list": this.selected_agents
                }
                this.API.getGenericTimersData(obj, this.api_end_point).subscribe((res: any) => {
                    if (res.success) {
                        const response = res.success;
                        if (Object.keys(response).length) {
                            // let agents_data = this.agent_table_data.value;
                            // console.log('agent_data', agents_data)
                            var arr = [];
                            agents_data.agent_data.map(element => {
                                if (response.hasOwnProperty(element.user_id)) {
                                    element = { ...element, ...this.get_activity_status(response[element.user_id]) };
                                    element.raw_data = response[element.user_id];
                                    const params = ['wrap_time', 'wait_time', 'spoken_time', 'unavailable_time', 'break_time', 'idle_time', 'active_time', 'login_time'];
                                    params.forEach(el => {
                                        if (response[element.user_id]['activity'].hasOwnProperty(el)) {
                                            if (response[element.user_id]['activity'][el] > 0) {
                                                element[el] = response[element.user_id]['activity'][el];
                                                element[`${el}_hms`] = this.utils.gethmsformat(element[el], 'clock')
                                            } else {
                                                element[el] = 0;
                                                element[`${el}_hms`] = this.default_time_value;
                                            }
                                        }
                                    })
                                    const temp = Math.floor(this.utils.convertSectoMin(Number(element.duration_now)));
                                    if (temp > 60) {
                                        element['duration_now'] = this.utils.convertSectoHrs(Number(element.duration_now));
                                    } else {
                                        element['duration_now'] = temp + ' mins';
                                    }
                                    element['sub_disposition'] = element.sub_disposition;
                                    element['activity_color'] = element.color;
                                }
                                arr.push(element)
                                return element;
                                // console.log('element', JSON.stringify(element));
                            })
                            agents_data.is_error = false;
                            agents_data.is_loading = false;
                            agents_data.agent_data = arr;
                            // this.agent_table_data.next(agents_data);
                            // console.log('after 2nd api ', JSON.stringify(this.agent_table_defaults))
                            // console.log('timers api', JSON.stringify(agents_data));
                            resolve(agents_data)
                        }

                    } else {
                        reject()
                    }
                }, (err) => {
                    reject()
                    // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: false, is_error: 'timer API error' } });
                })
            } catch (e) {
                reject()
                // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: false, is_error: 'timer API not responded' } });
            }
        })
    }

    get_all_agents_data(): Promise<AGENT_TABLE> {
        return new Promise((resolve, reject) => {
            try {
                // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: true, is_error: false } });
                const obj = {
                    "start_date": this.current_start_date,
                    "end_date": this.current_end_date,
                    "agents_list": this.selected_agents,
                    "user_group_id": this.myUser.groupid
                }
                this.API.pmDashboard(obj, this.api_end_point).subscribe(
                    (res: any) => {
                        // console.log('success', res.success)
                        if (res.success) {
                            const response = res['success'];
                            const params = ['dialled', 'rnr', 'contacted', 'unique_dialled', 'unique_connected', 'manual_dialled', 'forms_filled', 'new_data_dialled', 'new_data_contacted', 'followup_to_do', 'followup_completed', 'audit_score'];
                            if (response && Object.keys(response).length) {
                                // console.log('1st api response received');
                                // let agents_data = { ...this.agent_table_defaults };
                                let agents_data = JSON.parse(JSON.stringify(this.agent_table_defaults));
                                // console.log('agents data first call', JSON.stringify(agents_data));
                                agents_data.agent_data.map(element => {
                                    if (response.hasOwnProperty(element.user_id)) {
                                        params.forEach(parameter => {
                                            if (response[element.user_id].hasOwnProperty(parameter)) {
                                                if (response[element.user_id][parameter]) {
                                                    if (parameter == 'unique_connected') {
                                                        element['unique_contacted'] = response[element.user_id][parameter]
                                                    }
                                                    if (parameter == 'forms_filled') {
                                                        element['forms'] = response[element.user_id][parameter];
                                                    }
                                                    element[parameter] = response[element.user_id][parameter]
                                                } else {
                                                    element[parameter] = 0
                                                }
                                            }
                                        })
                                        if (response[element.user_id]['wifi_speed'] && response[element.user_id]['wifi_speed'].length) {
                                            const connect_src = response[element.user_id]['wifi_speed'].split('|');
                                            element['connectivity_src'] = connect_src[0];
                                            const wifi_result = this.utils.wifi_status(connect_src[1]);
                                            element['connect_status'] = wifi_result['connect_status'];
                                            element['connect_color'] = wifi_result['connect_color']
                                        }
                                    }
                                });
                                agents_data.is_error = false;
                                agents_data.is_loading = false;
                                // this.agent_table_data.next(agents_data)
                                // console.log('after 1st api', JSON.stringify(this.agent_table_defaults));
                                resolve(agents_data);
                            } else {
                                reject()
                            }
                        } else {
                            reject()
                        }
                    }, (err) => {
                        // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: false, is_error: 'pmdashboard api error' } });
                        reject()
                    }
                )
            } catch (err) {
                // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: false, is_error: 'pmdashboard exception error' } });
                reject()
            }
        })
    }

    prepare_channel_summary() {
        this.channel_summary.next({ ...this.channel_summary_default, ...{ is_loading: true, is_error: false } });
        return new Promise((resolve, reject) => {
            try {
                const obj = {
                    "start_date": this.current_start_date,
                    "end_date": this.current_end_date,
                    "agents_list": this.selected_agents,
                    "user_group_id": this.myUser.group_id
                };
                this.API.getGenericChannelsData(obj, this.api_end_point).subscribe((res: any) => {
                    if (res.errors) {
                        this.channel_summary.next({ ...this.channel_summary.value, ...{ is_loading: false, is_error: res.errors } });
                    }
                    if (res.success) {
                        var temp_channel_summary = JSON.parse(JSON.stringify(this.channel_summary_default));
                        temp_channel_summary.is_error = false;
                        temp_channel_summary.is_loading = false;

                        if (res.success.hasOwnProperty('incoming_call'))
                            temp_channel_summary.incoming_call = parseInt(res.success['incoming_call']);
                        if (res.success.hasOwnProperty('outgoing_call'))
                            temp_channel_summary.outgoing_call = parseInt(res.success['outgoing_call']);
                        if (res.success.hasOwnProperty('incoming_text'))
                            temp_channel_summary.incoming_text = parseInt(res.success['incoming_text']);
                        if (res.success.hasOwnProperty('outgoing_text'))
                            temp_channel_summary.outgoing_text = parseInt(res.success['outgoing_text']);
                        if (res.success.hasOwnProperty('chats')) {
                            temp_channel_summary.chats = parseInt(res.success['chats']);
                            temp_channel_summary.chats_precent = Number(this.utils.getPercentage(temp_channel_summary.chats, temp_channel_summary.chats, 1))
                        }
                        const total_calls = Number(temp_channel_summary.incoming_call + temp_channel_summary.outgoing_call);
                        const total_texts = Number(temp_channel_summary.incoming_text + temp_channel_summary.outgoing_text);

                        temp_channel_summary.incoming_call_percent = Number(this.utils.getPercentage(temp_channel_summary.incoming_call, total_calls, 1));
                        temp_channel_summary.outgoing_call_percent = Number(this.utils.getPercentage(temp_channel_summary.outgoing_call, total_calls, 1));
                        temp_channel_summary.incoming_text_percent = Number(this.utils.getPercentage(temp_channel_summary.incoming_text, total_texts, 1));
                        temp_channel_summary.outgoing_text_percent = Number(this.utils.getPercentage(temp_channel_summary.outgoing_text, total_texts, 1));
                        this.channel_summary.next(temp_channel_summary)
                        resolve(null)
                    }
                }, (err) => {
                    this.channel_summary.next({ ...this.channel_summary.value, ...{ is_loading: false, is_error: 'API error' } });
                    reject()
                })
            } catch (e) {
                this.channel_summary.next({ ...this.channel_summary.value, ...{ is_loading: false, is_error: 'Exception error' } });
                reject()
            }
        })
    }

    prepare_call_summary() {
        // this.calls_summary.next({ ...this.calls_summary_defaults, ...{ is_error: false, is_loading: true } });
        var obj: CALLS_SUMMARY = JSON.parse(JSON.stringify(this.calls_summary_defaults));
        try {
            const agents_data = this.agent_table_data.value;
            agents_data.agent_data.forEach(agent => {
                if (agent.dialled && agent.dialled > 0) {
                    obj.calls_dialled += agent.dialled;
                }
                if (agent.rnr && agent.rnr > 0) {
                    obj.calls_rnr += agent.rnr;
                }
                if (agent.contacted && agent.contacted > 0) {
                    obj.calls_contacted += agent.contacted;
                }
                if (agent.unique_contacted && agent.unique_contacted > 0) {
                    obj.calls_unique_contacted += agent.unique_contacted;
                }
                if (agent.new_data_dialled && Number(agent.new_data_dialled) > 0) {
                    obj.calls_new_data_dialled += Number(agent.new_data_dialled);
                }
                if (agent.manual_dialled && Number(agent.manual_dialled) > 0) {
                    obj.calls_manual_dialled += Number(agent.manual_dialled);
                }
            })
            obj.calls_dialled_percent = Number(this.utils.getPercentage(obj.calls_dialled, obj.calls_dialled));
            obj.calls_rnr_percent = Number(this.utils.getPercentage(obj.calls_rnr, obj.calls_dialled));
            obj.calls_contacted_percent = Number(this.utils.getPercentage(obj.calls_contacted, obj.calls_dialled));
            obj.calls_unique_contacted_percent = Number(this.utils.getPercentage(obj.calls_unique_contacted, obj.calls_dialled));
            obj.calls_new_data_dialled_percent = Number(this.utils.getPercentage(obj.calls_new_data_dialled, obj.calls_dialled));
            obj.calls_manual_dialled_percent = Number(this.utils.getPercentage(obj.calls_manual_dialled, obj.calls_dialled));
            // console.log('obj calls summary', obj);
            obj.is_error = false;
            obj.is_loading = false;
            // this.calls_summary.next({ ...obj, ...{ is_error: false, is_loading: false } });
        } catch (e) {
            // console.log('exception in calls summary', e)
            obj.is_error = 'Exception in calls summary';
            obj.is_loading = false;
            // this.calls_summary.next({ ...this.calls_summary.value, ...{ is_error: 'Exception in calls summary', is_loading: false } });
        }
        this.calls_summary.next(obj);
    }

    prepare_followup_summary() {
        var obj: FOLLOW_UPS = JSON.parse(JSON.stringify(this.followup_defaults));
        try {
            const agents_data = this.agent_table_data.value;
            agents_data.agent_data.forEach(element => {
                if (element.hasOwnProperty('followup_completed')) {
                    obj.followup += Number(element['followup_completed']);
                    obj.followup_done += Number(element['followup_completed']);
                }
                if (element.hasOwnProperty('followup_to_do')) {
                    obj.followup += Number(element['followup_to_do']);
                    obj.followup_todo += Number(element['followup_to_do']);
                }
            })
            obj.followup_percent = Number(this.utils.getPercentage(obj.followup, obj.followup, 1, false));
            obj.followup_done_percent = Number(this.utils.getPercentage(obj.followup_done, obj.followup, 1, false));
            obj.followup_todo_percent = Number(this.utils.getPercentage(obj.followup_todo, obj.followup, 1, false));
            obj.is_error = false;
            obj.is_loading = false;
        } catch (e) {
            obj.is_loading = false;
            obj.is_error = 'Exception in summary';
        }
        this.followups.next(obj);
    }

    prepare_lead_disposition_summary() {
        var obj = JSON.parse(JSON.stringify(this.lead_dispoisition_defaults));
        // console.log('lead disposition before ', JSON.stringify(obj));
        try {
            const agents_data = this.agent_table_data.value;
            agents_data.agent_data.forEach(element => {
                this.lead_disposition_names.forEach(name => {
                    // console.log('name', name)
                    if (element.hasOwnProperty(name) && Number(element[name]) > 0) {
                        obj[name] += Number(element[name])
                    }
                })
            })
        } catch (e) {
            obj.is_error = 'Exception in leads summary';
            obj.is_loading = false;
        }
        this.lead_disposition.next(obj);
    }

    prepare_agents_summary() {
        // this.agents_status.next({ ...this.agents_status.value, ...{ is_error: false, is_loading: true } });
        var obj: AGENT_STATUS = JSON.parse(JSON.stringify(this.agent_status_defaults));
        try {
            const agents_data = this.agent_table_data.value;
            // console.log('agents data', JSON.stringify(agents_data));
            obj.agents_total = agents_data.agent_data.length;
            const last_activity_group = _.groupBy(agents_data.agent_data, 'last_activity');
            const first_activity_group = _.groupBy(agents_data.agent_data, 'first_activity');
            Object.keys(first_activity_group).forEach(activity => {
                if ((activity != 'undefined') && activity.length) {
                    obj.agents_login += first_activity_group[activity].length;
                }
            })
            Object.keys(last_activity_group).forEach(activity => {
                const agents_number = last_activity_group[activity].length;
                // console.log('agents number', agents_number);
                switch (activity) {
                    case 'Active':
                        obj.agents_active += agents_number;
                        break;
                    case 'Dialing':
                    case 'Connecting':
                        obj.agents_active += agents_number;
                        obj.agents_on_call += agents_number;
                        break;
                    case 'Form':
                        obj.agents_active += agents_number;
                        break;
                    /* case 'Break':
                        break; */
                    case 'Logout':
                        obj.agents_logout = agents_number;
                        break;
                    /* case 'Login':
                        break;
                    case 'On Call':
                    case 'Taliking':
                    case 'Ringing':
                    case 'Form':
                    case 'Available':
                    case 'Barge':
                    case 'Whisper':
                        break;
                    case ' ':
                        break;
                    default:
                        break; */
                }
                obj.agents_on_idle = obj.agents_login - (obj.agents_active + obj.agents_logout);
                obj.agents_total_percent = Number(this.utils.getPercentage(obj.agents_total, obj.agents_total, 1, false));
                obj.agents_active_percent = Number(this.utils.getPercentage(obj.agents_active, obj.agents_total, 1, false));
                obj.agents_login_percent = Number(this.utils.getPercentage(obj.agents_login, obj.agents_total, 1, false));
                obj.agents_logout_percent = Number(this.utils.getPercentage(obj.agents_logout, obj.agents_total, 1, false));
                obj.agents_on_call_percent = Number(this.utils.getPercentage(obj.agents_on_call, obj.agents_total, 1, false));
                obj.agents_on_idle_percent = Number(this.utils.getPercentage(obj.agents_on_idle, obj.agents_total, 1, false));
                // console.log('agent status', JSON.stringify(obj));
                obj.is_error = false;
                obj.is_loading = false;
            })

        } catch (e) {
            obj.is_error = 'Exception in agent status';
            obj.is_loading = false;
            // this.agents_status.next({ ...this.agents_status.value, ...{ is_error: 'Exception in agent status', is_loading: false } })
        }
        this.agents_status.next(obj);
    }

    refresh_dashboard(start_date: string, end_date: string, agents_list: any) {
        console.log('input', start_date, end_date, agents_list);
        this.api_error.next(false);
        this.current_start_date = start_date;
        this.current_end_date = end_date;
        if (agents_list.length) {
            this.selected_agents = agents_list;
        } else {
            this.selected_agents = this.get_all_agents_list().map(agent => agent.user_id);
        }
        this.load_agent_table_data_defaults();
        this.call_agents_data();
    }

    set_summary_to_error() {
        this.followups.next({ ...this.followup_defaults, ...{ is_loading: false, is_error: true } });
        this.agents_status.next({ ...this.agent_status_defaults, ...{ is_loading: false, is_error: true } });
        this.calls_summary.next({ ...this.calls_summary_defaults, ...{ is_loading: false, is_error: true } });
        this.lead_disposition.next({ ...this.lead_dispoisition_defaults, ...{ is_loading: false, is_error: true } });
        this.agent_table_data.next({ ...this.agent_table_defaults, ...{ is_loading: false, is_error: true } });
        this.progress_bar.next({ ...this.progress_bar_defaults, ...{ visible: true } })
        this.api_error.next(true);
    }

    call_agents_data() {
        // console.log('callig agent data')
        this.set_all_defaults();
        // this.agent_table_data.next({ ...this.agent_table_data.value, ...{ is_loading: true, is_error: false } });
        var progress_bar_val: PROGRESS_BAR = JSON.parse(JSON.stringify(this.progress_bar_defaults));
        this.progress_bar.next({ ...progress_bar_val, ...{ visible: true, buffer_value: 25, value: 0 } })
        this.get_all_agents_data().then((agents_data) => {
            this.progress_bar.next({ ...progress_bar_val, ...{ visible: true, buffer_value: 50, value: 25 } })
            this.get_all_agent_timers_data(agents_data).then((agents_data) => {
                this.progress_bar.next({ ...progress_bar_val, ...{ visible: true, buffer_value: 75, value: 50 } });
                this.get_all_agent_disposition_data(agents_data).then((agents_data) => {
                    agents_data = { ...agents_data, ...{ is_error: false, is_loading: false } };
                    // console.group('check agent data value change');
                    // console.log('before -- defaulsts', JSON.stringify(this.agent_table_defaults));
                    this.agent_table_data.next(agents_data);
                    // console.log('after --- defaults', JSON.stringify(this.agent_table_defaults));
                    // console.groupEnd();
                    this.calculate_summary();
                    this.progress_bar.next({ ...progress_bar_val, ...{ visible: true, buffer_value: 100, value: 75 } });
                    this.prepare_channel_summary().then(() => {
                        //ToDo set progress bar.
                        this.progress_bar.next({ ...progress_bar_val, ...{ visible: true, buffer_value: 100, value: 100 } });
                        setTimeout(() => {
                            this.progress_bar.next({ ...progress_bar_val, ...{ visible: false } });
                        }, 2 * 1000, progress_bar_val)
                    }, () => {
                        this.set_summary_to_error()
                    })
                }, () => {
                    this.set_summary_to_error()
                })
            }, () => {
                this.set_summary_to_error()
            })
        }, () => {
            this.set_summary_to_error()
        })
    }

    calculate_summary() {
        this.prepare_agents_summary();
        this.prepare_call_summary();
        this.prepare_followup_summary();
        this.prepare_lead_disposition_summary();
    }

    set_all_defaults() {
        this.load_lead_disposition_defaults();
        this.load_agent_table_data_defaults();
        this.channel_summary.next({ ...this.channel_summary_default });
        this.followups.next({ ...this.followup_defaults });
        this.agents_status.next({ ...this.agent_status_defaults });
        this.calls_summary.next({ ...this.calls_summary_defaults });
        this.lead_disposition.next({ ...this.lead_dispoisition_defaults });
        this.agent_table_data.next({ ...this.agent_table_defaults });
        this.progress_bar.next({ ...this.progress_bar_defaults });
    }

}