<div class="row" >
    <div class="col-md-12">
        <mat-card style="margin: 0 10px;box-shadow: 0px 0px 4px 0px #dfdfdf;">
            <div class="row" >
                <div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
                    <table>
                        <tr>
                            <td style="vertical-align: middle;"> 
                             <img src="../assets/web_images/dataBAIcon2.png" class="dataImgXy1"/>
                            </td>
                            <td>
                                <span class="datalabel1">{{totaldialled}}</span>     <!--{{getAllTotalDialed()}}</span>-->
                                <span class="nameLabel1">Dialed</span>
                            </td>
                        </tr>
                    </table>
                    </div>
                    <div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
                        <table>
                            <tr>
                                <td style="vertical-align: middle;"> 
                                 <img src="../assets/web_images/dataBAIcon3.png" class="dataImgXy1"/>
                                </td>
                                <td>
                                    <span class="datalabel1"  style="color:#50bafd;">{{totalnewdialled}}</span>    <!--{{getAllNewDataDialed()}}</span>-->
                                    <span class="nameLabel1">New</span>
                                </td>
                            </tr>
                        </table>
                        </div>
                        <div class="col-md-3">
                            <table>
                                <tr>
                                    <td style="vertical-align: middle;"> 
                                     <img src="../assets/web_images/dataBAIcon4.png" class="dataImgXy1"/>
                                    </td>
                                    <td>
                                        <span class="datalabel1" style="color:#f3385d;">{{getAllFollowUpDialed()}}</span>
                                        <span class="nameLabel1">Followups</span>
                                    </td>
                                </tr>
                            </table>
                            </div>
                    </div>
           
        </mat-card>
        </div>
        </div>
        <div class="row " >
            <div class="col-md-12">
                <mat-card style="margin: 0 12px;" class="mt-3">
                    <h4 class="titlehead thd1 mb-4">ASM</h4>


                    <table matSort (matSortChange)="sortData($event)" class="vTableV">
                    <tr>
                      <th mat-sort-header="name">NAME</th>
                      <th mat-sort-header="dataUtilization">DATA UTILIZATION %</th>
                      <th mat-sort-header="followUpsMissed">FOLLOW UPS MISSED</th>
                      <th mat-sort-header="totalDialed">TOTAL DIALED</th>
                      <th mat-sort-header="newDataDialed">NEW DATA DIALED</th>
                      <th mat-sort-header="followUpDialed">FOLLOWUP DIALED</th>
                    </tr>
                    <tbody *ngFor="let d of sortedData | async;let i = index;let first=first;">
                      <tr>
                        <td colspan="6" style="color: #000;font-weight: bold;">{{d.title}}</td>
                      </tr>
                      <tr *ngFor="let v of d.rData;let j = index;let first=first;">
                        <td>{{v.name}}</td>
                        <td>{{v.dataUtilization}}</td>
                        <td>{{v.followUpsMissed}}</td>
                        <td>{{v.totalDialed}}</td>
                        <td>{{v.newDataDialed}}</td>
                        <td>{{v.followUpDialed}}</td>
                      </tr>
                      <tr style="background: #f9f9f9;">
                        <td><b>Total</b></td>
                        <td>{{getDataUtilization(d)| number : '1.0-0'}}</td>
                        <td>{{getFollowUpsMissed(d)| number : '1.0-0'}}</td>
                        <td>{{getTotalDialed(d)| number : '1.0-0'}}</td>
                        <td>{{getNewDataDialed(d)| number : '1.0-0'}}</td>
                        <td>{{getFollowUpDialed(d)| number : '1.0-0'}}</td>

                      </tr>
                    </tbody>
                    <tfoot>
                    <tr style="background: #f3f3f3;">
                    <td><b>All Total</b></td>
                    <td>{{getAllDataUtilization()| number : '1.0-0'}}</td>
                    <td>{{getAllFollowUpsMissed()| number : '1.0-0'}}</td>
                    <td>{{getAllTotalDialed()| number : '1.0-0'}}</td>
                    <td>{{getAllNewDataDialed()| number : '1.0-0'}}</td>
                    <td>{{getAllFollowUpDialed()| number : '1.0-0'}}</td>
                  </tr>
                    </tfoot>
                  </table>


                </mat-card>
            </div>
            </div>
