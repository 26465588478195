import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenu } from '@angular/material/menu';
import * as moment from 'moment';

import { ServicesService } from '../../dashboard/services.service';
import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';

import { Utils } from '../../utils';

export interface CampaignList{
	'campaign_list': Array <object>
}

export interface campaignTableRow{
	'sl_no': number,
	'campaign_name': string,
	'campaign_status': boolean,
	'campaign_created': string,
	'data_uploaded': number,
	'data_duplicate': number,
	'errors': number,
	'data_added': number,
	'contacted': number,
	'agency_name': string,
	'downloadReports':any
}

@Component({
  selector: 'app-campaign-tablev2',
  templateUrl: './campaign-table.component.html',
  styleUrls: ['./campaign-table.component.scss']
})

export class CampaignTableComponent implements OnInit,AfterViewInit {

	currentCamapigns: Array<campaignTableRow> = [];
	utils: any;

	displayedColumnsCampaigan: string[] = ['sl_no', 'campaign_name', 'campaign_status', 'campaign_created',
		'data_uploaded', 'data_duplicate', 'errors', 'data_added', 'contacted', 'agency_type','agency_name', 'downloadReports'];

	public dataSourceCampaign: any = new MatTableDataSource();
	search_change: any;
	api_end_point:any;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	

	constructor(
		private API: ApiService,
		private dashService: ServicesService,
		private myservice: MyserviceService,
	) { 
		this.utils = new Utils();
	}

	ngOnInit() {
		this.dataSourceCampaign.paginator = this.paginator;
		this.camapign_refresh();
		this.api_end_point = this.myservice.get_API_End_Point();
	}
	
	ngAfterViewInit(){
		this.dataSourceCampaign.paginator = this.paginator;
		this.dashService.dash_search.subscribe((d) => {
			this.search_change = d;
			// console.log('on after init search', this.search_change);
			if (this.search_change.isRecruiter) {
				this.applyFilter(this.search_change.value);
			}
		})
	}

	applyFilter(val: string) {
		this.dataSourceCampaign.filter = val.trim().toLowerCase();
	}

	camapign_refresh = function () {
		let data = null;
		this.dashService.myCampaigns.subscribe((d) => {
			data = d;
			if (data) {
				this.createCampaignTable(data);
			}
		})
	}

	check_val_type(val) {
		return typeof val;
	}

	createCampaignTable = function(data: any) {
		let arr = [];
		// debugger;
		for (var i = 0; i < data.length; i++){
			let obj = new Object();
			// console.log('printing obj',obj)
			let loc_obj = data[i];
			let details = {};
			if (loc_obj.details && loc_obj.details.length) {
				details = JSON.parse(loc_obj.details);	
			}
			
			obj['sl_no'] = i + 1;
			obj['id'] = loc_obj['id'];
			obj['campaign_name'] = loc_obj['campaign'];
			if (loc_obj['status'] === 'assigned') {
				obj['campaign_status'] = !!loc_obj['active_status'];
			} else {
				obj['campaign_status'] = loc_obj['status'];
			}
			obj['campaign_created'] = this.utils.format_to(loc_obj['created_at'],'YYYY-MM-DDTHH:mm:ss', 'DD-MMM-YYYY')  
			obj['data_uploaded'] = loc_obj['Total Pushed'];
			obj['data_duplicate'] = loc_obj['Total Repeat Drops'];
			obj['errors'] = loc_obj['Total Errors'];
			obj['data_added'] = this.utils.getValue(loc_obj['Total Pushed']);
			obj['contacted'] = this.utils.getValue(details['contacted'],'');
			obj['agency_type'] = this.utils.getValue(loc_obj['master_campaign'],'');
			obj['url'] = loc_obj.url;
			obj['created_at'] = loc_obj.created_at;
			obj['agency_name'] = loc_obj.others;
			arr.push(obj)
		}
		// console.log('printing current campaigns',arr)
		this.currentCamapigns = arr;
		this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
	}

	refreshCampaigns() {
		// debugger;
		this.camapign_refresh();
	}

	downloadCampaignStatusReport(obj:any) {
		// debugger;
		let campaign_data = obj;
		campaign_data.showLoader = true;
		let loc = {
			'campaign_id': obj['id'],
			'offset':330
		}
		this.API.downloadCampaignStatusReport(loc,this.api_end_point).subscribe(
			(res:any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
	}

	downloadCustomerStatusReport(obj: any) {
		// debugger;
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		let campaign = obj.campaign_name;

		this.API.downloadCustomerStatusReport(this.api_end_point,start_date, end_date, campaign).subscribe(
			(res: any) => {
				// debugger;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
							() => {
								obj.showLoader = false;
							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				alert('Error in the API call')
			}
		)
		// debugger;

	}

	downloadCallStatusReport(obj: any) {
		// debugger;
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		let campaign = obj.campaign_name;

		this.API.downloadCallStatusReport(start_date, end_date, campaign,this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
							() => {
								obj.showLoader = false;
							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				alert('Error in the API call')
			}
		)
	}

	downloadDataSheet(obj: any) {
		obj.showLoader = true;
		// debugger;
		this.API.downloadReportFromUrl(obj.url, true,this.api_end_point).then(
			() => {
				obj.showLoader = false;
			}
		)
	}

	campaignStatusChange(e, element) {
		/* console.log('event e', e);
		console.log('curent status', prev_status, e.checked); */
		const prev_status = !e.checked;
		element.disabled = true;
		let obj = {};
		if (e.checked) {
			obj['active_list'] = [element.campaign_name];
		} else {
			obj['inactive_list'] = [element.campaign_name]
		}

		this.API.campaignSwitch(obj,this.api_end_point).subscribe((res:any) => {
			if (res.errors) {
				alert('Camapign Switch Failed');
				e.checked = prev_status;
				element.disabled = false;
			} else {
				alert('Camapign Switch success.');
				element.disabled = false;
			}
		})
	}

}
