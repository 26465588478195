import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import * as _ from 'underscore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { RtddataService } from '../rtdData.service';
import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';

@Component({
	selector: 'app-badashboard-data-page',
	templateUrl: './badashboard-data-page.component.html',
	styleUrls: ['./badashboard-data-page.component.scss']
})
export class BadashboardDataPageComponent implements OnInit, OnDestroy {

	_fetchData: any = [];
	sortedData: BehaviorSubject<[]> = new BehaviorSubject([]);
	user: any;

	_availableData: any = null;
	//totalData$: BehaviorSubject<Number> = new BehaviorSubject(0);
	agentFeed: Subscription = null;
	groupFeed: Subscription = null;
	totalData: any=null;
	util = new Utils();
	groupid: any = null;
	group_level_data: any;
	groupdata = [];
	constructor(private rtd: RtddataService, private myService: MyserviceService) {
		this.user = this.myService.get_loggedin_user();
		this.groupid = this.myService.get_asm_groupid();
	}
	ngOnInit() {
		this.getRtdValues();
		//let _availableRecord = "0";
		//this._availableData = Number(_availableRecord).toLocaleString('en-IN');//_availableRecord.toLocaleString('en-IN')
	}

	getRtdValues() {
		this.group_level_data = this.myService.get_bidashboard();
			//this.groupid = this.myService.get_asm_groupid();
			//    console.log(JSON.stringify(this.group_level_data));
			//    this.groupdata = Object.values(this.group_level_data);
			// console.log("-1--",JSON.stringify(this.groupdata))
			this.agentFeed = this.rtd.agentFeed.subscribe(data => {
				let finaldata = [];
				//console.log('agent feed', data);

				for (let i in data) {
					//console.log(data[i])
					finaldata.push(data[i]);

				}
				//console.log(finaldata);


				let tlbase = _.groupBy(data, 'tl_name');
				console.log('tlbase ', tlbase);
	
				let arr = [];
				for (let tl in tlbase) {
					let obj: any = {};
					obj.title = tl;
					obj.rData = [];
					//console.log('tlbase ', tlbase[tl][0]['groupid']);
					this.groupid = tlbase[tl][0]['groupid'];
					tlbase[tl].forEach((agent: any) => {
						//console.log("---"+agent);
						obj.rData.push({
							"name": agent.head,
							"totalDataAvailable": (agent.rtd_data_pushed_count + agent.rtd_pd_data_pushed_count + agent.rtd_pd_national_data_pushed_count),
							"nationalCampaign": (agent.rtd_national_data_pushed_count + agent.rtd_pd_national_data_pushed_count),
							"localCampaign": ((agent.rtd_data_pushed_count - agent.rtd_national_data_pushed_count - agent.rtd_pd_national_data_pushed_count) + (agent.rtd_pd_data_pushed_count)<0?0:(agent.rtd_data_pushed_count - agent.rtd_national_data_pushed_count - agent.rtd_pd_national_data_pushed_count) + (agent.rtd_pd_data_pushed_count)),
							"referenceCustomers": agent.rtd_ref_customer_count,
							"manualDialed": agent.rtd_manual_consumed_count
						})
					})
					arr.push(obj);
				}
				//console.log('after add data', JSON.stringify(arr));
				this.formTable(arr);
			})
		
		this.groupFeed = this.rtd.groupFeed.subscribe(data => {
			console.log('group feed', data,'--',data.length);
			console.log(this.groupid,'\n',data[this.groupid]);
			if (this.groupid) {
				if (data[this.groupid]) {
					//console.log(data[this.groupid]['rtd_data_pushed_count']);
					this.totalData = data[this.groupid]['rtd_data_pushed_count'];
					//this.totalData$.next(data[this.groupid]['rtd_data_pushed_count']);
				}

			} else if (data.hasOwnProperty(this.user.groupid)) {
				if (data[this.user.groupid]) {
					this.totalData = data[this.user.groupid]['rtd_data_pushed_count']
					//this.totalData$.next(data[this.user.groupid]['rtd_data_pushed_count']);
				}

			}
		})
	}

	
	formTable(arr?) {
		this._fetchData = []
		if (arr && arr.length) {
			this._fetchData = arr;
		}
		this.sortedData.next(this._fetchData.slice());
	}
	sortData(sort: Sort) {
		const data = this._fetchData.slice();
		if (!sort.active || sort.direction === '') {
			this.sortedData.next(data);
			return;
		}

		let p = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'totalDataAvailable':
					return this.compare(a.totalDataAvailable, b.totalDataAvailable, isAsc);
				case 'nationalCampaign':
					return this.compare(a.nationalCampaign, b.nationalCampaign, isAsc);
				case 'localCampaign':
					return this.compare(a.localCampaign, b.localCampaign, isAsc);
				case 'referenceCustomers':
					return this.compare(a.referenceCustomers, b.referenceCustomers, isAsc);
				case 'manualDialed':
					return this.compare(a.manualDialed, b.manualDialed, isAsc);
				default:
					return 0;
			}
		});
		this.sortedData.next(p);
	}
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	getTotalDataAvailable(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].totalDataAvailable);
		return total;
	}
	getSummationData(variable) {
		let total = 0;
		let dataValue: any = this.sortedData.getValue();
		if (dataValue && dataValue.length) {
			for (let j = 0; j < dataValue.length; j++) {
				if (dataValue[j].rData && dataValue[j].rData.length) {
					for (let k = 0; k < dataValue[j].rData.length; k++)
						total += Number(dataValue[j].rData[k][variable]);
				}
			}
		}
		return total;
	}
	getAllTotalDataAvailable() {
		return this.getSummationData('totalDataAvailable');
	}


	getNationalCampaign(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++) {
			total += Number(d.rData[k].nationalCampaign);
		}
		return total;
	}
	getAllNationalCampaign() {
		return this.getSummationData('nationalCampaign')
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].nationalCampaign);
		}
		return total; */
	}

	getLocalCampaign(d: any) {
		let total = 0;
		for (var l = 0; l < d.rData.length; l++) {
			//console.log(d.rData[l]);
			total += Number(d.rData[l].localCampaign);
		}
		return total;
	}
	getAllLocalCampaign() {
		return this.getSummationData('localCampaign');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].localCampaign);
		}
		return total; */
	}

	getReferenceCustomers(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++) {
			total += Number(d.rData[k].referenceCustomers);
		}
		return total;
	}
	getAllReferenceCustomers() {
		return this.getSummationData('referenceCustomers');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].referenceCustomers);
		}
		return total; */
	}

	getManualDialed(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++) {
			total += Number(d.rData[k].manualDialed);
		}
		return total;
	}
	getAllManualDialed() {
		return this.getSummationData('manualDialed');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].manualDialed);
		}
		return total; */
	}

	ngOnDestroy(): void {
		this.agentFeed.unsubscribe();
		this.groupFeed.unsubscribe();
		this.sortedData.next([]);
		this.groupid=null;
	}

}


