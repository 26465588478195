<div class="row ml-0 mr-0 mt-2">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- <p class="L-head" style="font-size: 22px;">Chat Support</p> -->
    </div>
</div>


<div style="margin-top: 0px;">
    <div class="mainDiv">
        <div class="row" style="width: 100%;">
            <div class="col-lg-{{colWd}} col-md-{{colWd}} col-sm-12"> 
                
                
                <div *ngIf="widgetUser && widgetUser.external_url" class="text-right">
              
                    <a href={{widgetUser.external_url}} target="_blank" rel="noopener noreferrer"  style="margin: 10px;"><span class="d-h-label" style="font-weight: 500;color: #000;">Customer On Page </span></a>
                    <span *ngIf="colWd==6"><img src="../assets/web_images/expandOpen.svg" style="width: 34px;cursor: pointer;margin-bottom: 6px;" (click)="expandOpen()"/></span>
                    <span *ngIf="colWd==12"><img src="../assets/web_images/expandClose.svg" style="width: 34px;cursor: pointer;margin-bottom: 6px;"  (click)="expandClose()"/></span>
                </div>



                <mat-card style="height: calc(100vh - 160px);width: 100%;overflow: auto hidden;margin-bottom: 5px;" *ngIf="widgetUser && widgetUser.external_url">
                   
                    <iframe width="100%" height="100%"
                        [src]="transformUrl(widgetUser.external_url)"></iframe>

                </mat-card>
            </div>
            <div *ngIf="colWd==12" class="col-lg-6 col-md-6 col-sm-12"></div>

            <div class="col-lg-6 col-md-6 col-sm-12">
               <div class="row">
              
        <div class="col-sm-5 col-md-5 zindex" *ngIf="colWd !=12">
            <div class="leftMenu">
                <div class="leftScroll">
                    <div class="searchDiv">
                        <i class="fa fa-search iconIsearch" aria-hidden="true"></i>
                        <input type="text" placeholder="search messages" (keyup)="searchFromArray($event.target.value)" />
                    </div>

                    <div class="" *ngFor="let val of groupList;let $index=index"
                        (click)="tabkeyMenu(val.key,$index)">
                        <div class="chat_people chat_list" *ngIf="val.show"  [ngClass]="{active_chat: $index == selectedActive}">
                            <table width="100%">
                                <tr>
                                    <td style="vertical-align: initial;width: 20%;">
                                        <span> <img src="../assets/userImage.png"
                                                style="border: 1px solid #cccccc;width: 35px;height: 35px;border-radius: 50%;">
                                        </span> </td>
                                    <td>
                                        <table class="tableuser">
                                            <tr>
                                                <td style="font-size: 13px;"> <b>{{val.tab_name}}</b> </td>
                                                <td>
                                                    <span class="chat_time">
                                                        {{val.last_message_time}}
                                                        <span *ngIf="val.unread_messages > 0"
                                                            class="badgenewcs">{{val.unread_messages}}</span>
                                                    </span> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"> <span class="chat_p">{{val.last_message}} </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-7 col-md-7 DivR"  [ngClass]="colWd ==12 ? 'firstCS' : ''">
            <mat-card class="mb-2" style="margin-top: 0px;margin-bottom: -7px;"  *ngIf="colWd !=12">
                <span class="L-head" style="color: #000000;">Customer Info(CRM/ERP/WIDGET)</span>
                <br/>
                <ul class="bulletcss" style="margin-top: 6px" *ngIf="widgetUser">
                    <!-- <li>Customer Name - {{widgetUser.name}}</li> -->
                    <li *ngIf="widgetUser.product">Product - {{widgetUser.product}}</li>
                    <li *ngIf="widgetUser.state">State - {{widgetUser.state}}</li>
                    <li *ngIf="widgetUser.message">Message - {{widgetUser.message}}</li>
                 </ul>
            </mat-card>
            <div class="rightMaindiv1" style="width: 100%;">
                <!--start chat online header-->
                <div class="parentdiv" *ngIf="IsVisible" style="min-height: calc(100vh - 305px);">
                    <div class="headerPart">
                        <table width="100%">
                            <tr>
                                <td style="vertical-align: initial; width: 6%;">
                                    <span> <img src="../assets/userImage.png"
                                            style="border: 1px solid #ccc;width: 30px;height: 30px;border-radius: 50%;margin-bottom: 4px;">
                                    </span>
                                </td>
                                <td>
                                    <table class="tableuser">
                                        <tr>
                                            <td> <b>{{_dataTabName}}</b> </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="bodyPart rightScroll" id="messageBody" style="height: calc(100vh - 395px) !important;">
                        <div *ngIf="groupList[selectedActive]"> <!-- *ngIf="groupList[selectedActive]" -->
                            <div class="mesgs" *ngFor="let msg of groupList[selectedActive].messages;let $msgIndex=index">
                                <!-- *ngFor="let msg of groupList[selectedActive].messages;let $msgIndex=index" -->
                                <div class="incoming_msg" *ngIf="myUser.id != msg.from_id && msg.message_type == 'TEXT'">
                                    <div class="received_msg">
                                        <!-- <h4>29-11-2020</h4> -->
                                        <div class="received_withd_msg">
										<p *ngIf="msg.message_type == 'TEXT'"><span style="font-size:11px;display:block;color:black"> {{msg.user == 'Server' ? msg.to_id : msg.user}}
											</span>{{msg.message}} <span class="time_date"> {{msg.message_time}}
											</span></p>
										<p *ngIf="msg.message_type == 'IMAGE'"><span style="font-size:11px;display:block;color:black"> {{msg.user == 'Server' ? msg.to_id : msg.user}}
											</span><img src={{msg.message}} style="width: 100%;" /> <span class="time_date"> {{msg.message_time}}
											</span></p>
										<p *ngIf="msg.message_type == 'AUDIO'"><span style="font-size:11px;display:block;color:black"> {{msg.user == 'Server' ? msg.to_id : msg.user}}
											</span><audio style="width:100%" controls>
												<source src={{msg.message}} type="audio/ogg">
												<source src={{msg.message}} type="audio/mpeg">
											</audio> <span class="time_date"> {{msg.message_time}}
											</span></p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="outgoing_msg" *ngIf="myUser.id == msg.from_id && msg.message_type == 'TEXT'" style="margin-top: -8px;">
                                    <!-- <h4>30-11-2020</h4> -->
                                    <div class="sent_msg">
                                        <p><span style="font-size:11px;display:block;color:black"> {{msg.user == 'Server' ? msg.to_id : msg.user}}  </span>{{msg.message}} 
                                            <span class="time_date">{{msg.message_time}}</span></p>
                                    </div>
                                </div>
                                
<!--                                 <div class="outgoing_msg">
                                    <h4>30-11-2020</h4>
                                    <div class="sent_msg">
                                        <p><span style="font-size:11px;display:block;color:black">Prashant </span>
                                            <img id="imgId0" src="https://i.pinimg.com/originals/28/2d/d9/282dd970d89920a386b2f0dc8457c907.jpg"  (click)="currentImageClick(0)" class="imgWd">
                                            <span class="time_date">10:03 AM </span></p>
                                            
                                    </div>
                                </div>
                                <div class="incoming_msg">
                                    <div class="received_msg">
                                        <h4>01-12-2020</h4>
                                        <div class="received_withd_msg">
                                            <p><span style="font-size:11px;display:block;color:black">Customer
                                                </span>
                                                <img id="imgId0" src="https://www.fnp.sg/assets/images/custom/quotes/good-morning/Good-morning-wishes-07-updated-11-jan-2019.jpg" class="imgWd"
                                                    (click)="currentImageClick(0)">
                                                <span class="time_date">10:04 AM </span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="incoming_msg">
                                    <div class="received_msg">
                                        <h4>01-12-2020</h4>
                                        <div class="received_withd_msg">
                                            <p><span style="font-size:11px;display:block;color:black"> Customer
                                                </span> <audio controls>
                                                    <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-6.mp3" type="audio/mpeg">
                                                </audio>
                                                <span class="time_date">10:05 AM </span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="outgoing_msg">
                                    <h4>30-11-2020</h4>
                                    <div class="sent_msg">
                                        <p><span style="font-size:11px;display:block;color:black"> Prashant </span>
                                            <audio controls>
                                                <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-5.mp3" type="audio/mpeg">
                                            </audio>
                                            <span class="time_date">10:09 AM </span></p>
                                    </div>
                                </div> -->










                            </div>
                        </div>

                    </div>
                    <div class="footerPart">
                        <span class="reqField" *ngIf="mandatoryFiled">Required field</span>

                        <div class="row">
                            <div class="col-md col-sm">
                                <input type="text" id="textarea" (keyup)="onTextMessage()" [(ngModel)]="txtmsg" class="inputtext"
                                    placeholder="Type a message here…" required="true" [disabled]="socketConnection"
                                    (keydown.enter)="sendMessage_With_Enter(txtmsg)" autocomplete="off" style="padding: 7px 8px 7px 28px;width: 85%;"/>

                                <!-- <div class="image-upload">
                                    <label for="file-input" class="fa fa-paperclip input-icon1"></label>
                                    <input id="file-input" type="file" (change)="uploadFile($event)"
                                        file-model="myFile" />
                                </div> -->
                            </div>
                            <div class="col-md-1  col-sm-2">
                                <button class="btncs" id="send" style="right:30px;background-color: #3fb90d;" (click)="endSession()"><i
                                        class="fa fa-times" aria-hidden="true"></i></button>
                            </div>
                            <div class="col-md-1  col-sm-2">
                                <button class="btncs" style="right: 23px;background-color: #3fb90d;" id="send" (click)="sendMessage_With_Enter(txtmsg)"><i
                                        class="fa fa-send" aria-hidden="true"></i></button>
                            </div>
                        </div>


                    </div>
                </div>
                <!--end chat online header-->
            </div>
        </div>
             
    </div>
    </div>
</div>
    </div>
</div>

<div *ngIf="socketConnection" class="interNetMesg" id="closeInternetMsg">
    <p><span class="warn-cs"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
        No internet connection.<br>
        Kindly check your internet connectivity</p>
</div>

<div id="myModal" class="modalLarge">
    <span class="closeLarge" (click)="closeLarge()">&times;</span>
    <img class="modal-large-content" id="img01">
</div>