import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as highcharts from 'highcharts';
import * as Highcharts from 'highcharts';
import {Router} from '@angular/router'
import {MyserviceService} from '../../myservice.service';
import {ApiService} from "../../api.service";

const _channel_code_mapper = {
  'whatsapp_messaging':  {
    name: 'WhatsApp',
    color:'#0ec63d'//'#51aa29'
  },
  'robot_voice_call': {
    name: 'Outbound IVR',
    color:'#196eae'// '#f2bb52'
  },
  'inivr_call':  {
    name: 'Inbound IVR',
    color: '#197a65'//'#51ea28'
  },
  'outbound_call':  {
    name: 'Outbound',
    color:'#c8be1d'//'#51aa79'
  },
  'inbound_call': {
    name: 'Inbound',
    color: '#f09b26'// '#f2be52'
  },
  'sms_messaging': {
    name: 'SMS',
    color:'#1fa5c1'// '#f2cc31'
  }
};

@Component({
  selector: 'app-all-channels-dashboard',
  templateUrl: './all-channels-dashboard.component.html',
  styleUrls: ['./all-channels-dashboard.component.scss']
})
export class AllChannelsDashboardComponent implements OnInit {
  Columnchart:any = null;
  donutChartrecord:any;
  customer_name:string;
  project_name:string;
  businessType:string;
  subscribed_channels:any;
  _TotalCostByChannel:any;
  all_proj_activities: any;
  columnStackChart:any;
  current_cost:any;
  category_xaxis:any[];
  act_series:any[];
  total_cost_series:any[];
  currentLoader:boolean=false;
  api_end_point:any;
  constructor(private router:Router, private _service:MyserviceService,private API:  ApiService) {
    window.scrollTo(0,0);
  }

  ngOnInit() {
    this.currentLoader=true;
    this.api_end_point = this._service.get_API_End_Point();
    //console.log('AllChannelsDashboardComponent - ngOnInit called');

    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = String(this._service.get_current_project()).toLowerCase();
      this.businessType = String(this._service.get_loggedin_user().businessType);
    }

    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().business_process) {
      this.subscribed_channels = JSON.parse(this._service.get_loggedin_user().business_process);
    }

    let time_range = this._service.get_current_time_range();
let _dRanage=time_range.split(' ')[0]
let startDateFilter=time_range.split(' ')[1];
let endDateFilter=time_range.split(' ')[2];
if(_dRanage=="CustomDate"){
  time_range="Custom";
}
//view_type: 'CustomDate 2022-05-01T00:00:00 2022-05-11T23:59:00'
    this.API.getCjActivitiesDashboard(this.customer_name,this.project_name,time_range,startDateFilter,endDateFilter,this.api_end_point).subscribe((data: any) => {
//debugger;
      if(data.errors){
        this.currentLoader=false;
        //console.log('getCjActivitiesDashboard failed',data.errors);
        return;
      }
       else {
        this.currentLoader=false;
       // console.log('getCjActivitiesDashboard success',data);
        if (data) {
          this.all_proj_activities = data;
        }

       // console.log(this.all_proj_activities,this.subscribed_channels);

        //Prepare activities data
        this.act_series = []; this.total_cost_series=[];this.current_cost = 0; this.category_xaxis = this.all_proj_activities['xaxis'];

        delete this.all_proj_activities.start_date;
        delete this.all_proj_activities.end_date;
        delete this.all_proj_activities.yesterday_date;
        delete this.all_proj_activities.xaxis;

        if (Object.keys(this.all_proj_activities).length) {

          for (var i in this.all_proj_activities) {
            let def_values = [];

            /*Populate zeros*/
            for (var index in this.category_xaxis) {
              def_values[index] = 0;
            }

            var cost_per_channel = {};
            cost_per_channel["Channel"] = _channel_code_mapper[i].name;
            cost_per_channel["color"] = _channel_code_mapper[i].color;
            cost_per_channel["Cost"] = 0;
            for (var timeslot in this.all_proj_activities[i]) {
              //console.log('all',i,timeslot);
              def_values[this.category_xaxis.indexOf(timeslot)] = this.all_proj_activities[i][timeslot];
              cost_per_channel["Cost"] += this.all_proj_activities[i][timeslot];
            }

            cost_per_channel["Cost"] = Math.floor(cost_per_channel["Cost"] * parseFloat(this.subscribed_channels[i]));
            this.current_cost += cost_per_channel["Cost"];

            cost_per_channel["Cost"] = cost_per_channel["Cost"] + this.subscribed_channels["currency"];
            this.total_cost_series.push(cost_per_channel);

           // console.log('allvalues',i,def_values,cost_per_channel);

            this.act_series.push({
              type: undefined,
              name: _channel_code_mapper[i].name,
              data: def_values,
              color: _channel_code_mapper[i].color
            });
          }
        } else {
          let def_values = [];

          /*Populate zeros*/
          for (var index in this.category_xaxis) {
            def_values[index] = 0;
          }

          for (let i in _channel_code_mapper) {
            var cost_per_channel = {};
            cost_per_channel["Channel"] = _channel_code_mapper[i].name;
            cost_per_channel["color"] = _channel_code_mapper[i].color;
            cost_per_channel["Cost"] = 0;

            this.total_cost_series.push(cost_per_channel);
            this.act_series.push({
              type: undefined,
              name: _channel_code_mapper[i].name,
              data: def_values,
              color: _channel_code_mapper[i].color
            });
          }
        }

        //this.current_cost = this.current_cost + this.subscribed_channels["currency"];

        this._TotalCostByChannel=this.total_cost_series;
        this.overAllProject();
        this.donutChart();
      }
    });
  }
  overAllProject(){
   // console.log('overAllProject',this.act_series);
    let _Columnchart = new Chart({
      chart: {
        type: 'column',
        height: 300
      },
      title: {
        text: ''
      },

      exporting: {
        enabled: false
      },
      xAxis: {
        categories: this.category_xaxis,
        gridLineWidth: 1,
        minorGridLineWidth: 1,
        lineColor: '#c0c98c',
        gridLineColor: '#c0c98c',
        labels: {
          style: {
            color: '#868f6c',
            fontWeight:'bold'
          }
        }
      },
      yAxis: {

        title:null,
        gridLineWidth: 1,
        minorGridLineWidth: 1,
        tickInterval: 5,
        lineColor: '#c0c98c',
        gridLineColor: '#c0c98c',
        labels: {
          style: {
            color: '#868f6c',
            fontWeight:'bold'
          }
        }

      },
      legend: {
        reversed: false,
        verticalAlign: "top",
        align: "right",
        borderWidth: 0,

      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true,

        }
      },
      series: this.act_series,

      });
      this.Columnchart= _Columnchart;
  }
  donutChart(){
    //debugger;
    let chartdata = new Chart({
      title: {
        text: '<small style="color:gray">'+this.subscribed_channels["target_cost"]+'</small>',
        align: 'center',
        verticalAlign: 'middle',
        y: 18
      },
      credits: {
        enabled: false
      },

      exporting: {
        enabled: false
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          dataLabels: {
            distance:-30,
            enabled: false
          },
          showInLegend: false,
          size:'100%',

        }

      },

      series: [
        {  states: {
          inactive: {
            opacity: 1
          },
          hover: {
          enabled: false
          }
          },
          name: 'Active',
          data: [
            {
              name: 'Target',
              y: this.subscribed_channels["target_cost"],
              color:'#ebebeb'//'#fcab2d'//#ebebeb
            },
            {
              name: 'Cost',
              y: this.current_cost,
              color:'#f14f38'
            },],
          type: 'pie',
          innerSize: '55%'
        }]
    });
    this.donutChartrecord=chartdata;


  }
}
