import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import{MyserviceService}from './myservice.service'

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate {
	constructor(private _service: MyserviceService, private router: Router) {
		// Logic of checking settings and login here.
		// console.log('construct root gaurd')
	}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //return true;
    if(this._service.isLoggedIn()){
      return true;
    }
    else{
      alert("You don't have permission page");
      if(window.location.href.includes('dev.youearn.in')){
		window.location.href = 'https://dev.contiinex.com/';
	  }else{
		window.location.href = 'https://contiinex.com/';
	  }
    }
  }
  
}
