import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orchestration-email-template',
  templateUrl: './orchestration-email-template.component.html',
  styleUrls: ['./orchestration-email-template.component.scss']
})
export class OrchestrationEmailTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
