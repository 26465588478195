<div class="row">
	<div class="col-md-12">
		<mat-card style="margin: 0 10px;box-shadow: 0px 0px 4px 0px #dfdfdf;">
			<div class="row">
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon14.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1">{{getAllGenerated()}}</span>
								<span class="nameLabel1">QDE</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon15.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#50bafd;">{{getAllGenerated()}}</span>
								<span class="nameLabel1">Gen</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon16.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#f3385d;">0</span>
								<span class="nameLabel1">Approved</span>
							</td>
						</tr>
					</table>
				</div>
			</div>

		</mat-card>
	</div>
</div>
<div class="row ">
	<div class="col-md-12">
		<mat-card style="margin: 0 12px;" class="mt-3">
			<h4 class="titlehead thd1 mb-4">ASM</h4>


			<table matSort (matSortChange)="sortData($event)" class="vTableV">
				<tr>
					<th mat-sort-header="name">NAME</th>
					<th mat-sort-header="qdeGenerated"> QDE GENERATED</th>
					<th mat-sort-header="qdeRejected">QDE REJECTED</th>
					<th mat-sort-header="contactedAgent">CONTACTED / AGENT</th>
					<th mat-sort-header="qdeAgent">QDE / AGENT</th>
					<th mat-sort-header="score">SCORE</th>
				</tr>
				<tbody *ngFor="let d of sortedData | async;let i = index;let first=first;">
					<tr>
						<td colspan="6" style="color: #000;font-weight: bold;">{{d.title}}</td>
					</tr>
					<tr *ngFor="let v of d.rData;let j = index;let first=first;">
						<td>{{v.name}}</td>
						<td>{{v.qdeGenerated}}</td>
						<td>{{v.qdeRejected}}</td>
						<td>{{v.contactedAgent}}</td>
						<td>{{v.qdeAgent}}</td>
						<td>{{v.score}}</td>
					</tr>
					<tr style="background: #f9f9f9;">
						<td><b>Total</b></td>
						<td>{{getGenerated(d)}}</td>
						<td>{{getRejected(d)}}</td>
						<td>{{getContactedAgent(d)}}</td>
						<td>{{getAgent(d)}}</td>
						<td>{{getScore(d)}}</td>

					</tr>
				</tbody>
				<tfoot>
					<tr style="background: #f3f3f3;">
						<td><b>All Total</b></td>
						<td>{{getAllGenerated()}}</td>
						<td>{{getAllRejected()}}</td>
						<td>{{getAllContactedAgent()}}</td>
						<td>{{getAllAgent()}}</td>
						<td>{{getAllScore()}}</td>
					</tr>
				</tfoot>
			</table>
		</mat-card>
	</div>
</div>