import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Utils } from '../../utils';

import { ServicesService } from '../../dashboard/services.service';
import { MyserviceService } from '../../myservice.service';

@Component({
  selector: 'app-inbound',
  templateUrl: './inbound.component.html',
  styleUrls: ['./inbound.component.scss']
})
export class InboundComponent implements OnInit {

	currentLoader = false;
	today = moment().format('DD-MMM-YYYY');
	utils = new Utils();
	displayedColumns = [
		'user_id',
		'sa_id',
		'status',
		'name',
		// 'email',
		'contacted',
		'not_contacted',
		'aht',
		'login_min',
		'connected_min',
		'avg_aht',
		'audit_score',
		'ctc_score',
		'earning',
		'active_time',
		'afk'
	];

	_summary = function () {
		return {
			'calls': {
				'attempt': 0,
				'abandoned': 0,
				'avg_calls':0
			},
			'aht': {
				'total': '00:00:00',
				'avg': '00:00:00'
			},
			'status': {
				'active': 0,
				'on_break': 0,
				'not_active': 0
			},
			'agents': {
				'active': 0,
				'contacted': 0,
				'above_avg':0
			}
		}
	}
	_tableData = function () {
		return {
			'user_id': '',
			'sa_id': '',
			'status':'',
			'name': '',
			'email': '',
			'contacted': 0,
			'not_contacted': 0,
			'aht': '00:00:00',
			'login_min': 0,
			'connected_min': 0,
			'avg_aht': '00:00:00',
			'audit_score': 0,
			'ctc_score': 0,
			'earning': '0',
			'active_time': '00:00:00',
			'afk': '00:00:00'
		}
	}


	summary = this._summary();

	@ViewChild(MatPaginator) paginator: MatPaginator;
	dataSource = new MatTableDataSource();
	api_end_point:any;
	constructor(
		private dashService: ServicesService,
		private userService: MyserviceService,
		private myService: MyserviceService
	) {
		
	 }

	ngOnInit() {
		this.refreshData();
		this.api_end_point = this.myService.get_API_End_Point();
	}

	applyFilter(val: string) {
		// console.log('pm filter starts', val);
		this.dataSource.filter = val.trim().toLowerCase();
	}

	refreshData() {
	//	console.log('Table data refreshing');
		this.currentLoader = true;
		let obj:any = {};
		obj.start_date = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true);
		obj.end_date = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true);
		obj.agents_list = this.userService.getAllIds('user', 'id');
		//debugger;
		if (!(obj.agents_list && obj.agents_list.length)) {
			alert('Agents data not found');
			this.currentLoader = false;
			return;
		}
		this.dashService.getPMDashDataInbound(obj,this.api_end_point).then((resolve) => {
		//	console.log('API Called successfully');
			this.currentLoader = false;
			this.prepareTableData()
		}, (reject) => {
				alert(reject);
				this.currentLoader = false;
		})
	}

	prepareTableData() {
		this.currentLoader = true;
		this.summary = this._summary();
		let data = null;
		let arr = [];
		let aht_summary = [0,0];
		this.dashService.pm_dash_inbound_data.subscribe((d) => data = d);
		//debugger;
		if (data && data.hasOwnProperty('inboundData')) {
			this.summary.calls.attempt = data['inboundData']['ib_total_calls'];
		}
		if (data && data.hasOwnProperty('agentsData')) {
			this.summary.agents.active = data['agentsData']['Total Active Agents'];
			this.summary.agents.contacted = data['agentsData']['Contacted Agents'];
			this.summary.calls.avg_calls = data['agentsData']['Avg Contacted Calls'];
			this.summary.agents.above_avg = data['agentsData']['Agents Above Avg Connected Calls'];
			this.summary.status.active = data['agentsData']['Active'];
			this.summary.status.on_break = data['agentsData']['Not Active'];
			this.summary.status.not_active = data['agentsData']['Not Called'];
			if (data['agentsData'] && data['agentsData']['agents']) {
				let ag_data = data['agentsData']['agents'];
				if (ag_data.length) {
					for (var i in ag_data) {
						let agent = this._tableData();
						let loc_agent = ag_data[i];
						agent.user_id = loc_agent['id'];
						agent.email = loc_agent['email'];
						agent.name = loc_agent['name'];
						if (loc_agent['ib_aht_in_format']) {
							agent.aht = loc_agent['ib_aht_in_format'];
						}
						if (loc_agent['aqt_time']) {
							agent.login_min = this.utils.convertSectoMin(loc_agent['aqt_time']);
						}
						if (loc_agent['ib_received_calls']) {
							agent.contacted = loc_agent[''];
						}
						agent.status = loc_agent['color_code'];
						arr.push(agent);
						if (!isNaN(loc_agent['ib_total_aht_in_sec'])) {
							aht_summary[0] += parseInt(loc_agent['ib_total_aht_in_sec']);	
						}
					}
				}
			}
		}
	//	console.log('printing data inbound', data);
		//console.log('table data', aht_summary);
		this.summary.aht.total = moment.utc(aht_summary[0]*1000).format('HH:mm:ss');
		this.dataSource = new MatTableDataSource(arr);
		this.dataSource.paginator = this.paginator;
		this.currentLoader = false;
	}

}
