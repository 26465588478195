<app-new-contiinex-menu-page></app-new-contiinex-menu-page>
<div class="v-mainDivCO">
    <div>
        <div class="row animation-One2x vk-sec">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-center mb-4">
                    <!-- <img src="../assets/contiinex/image/subProductComExperience.png" class="comEngLogo" /> -->
                    <img src="../assets/contiinex/image/ainexLogo.png" class="v-LblHd1-Img" />
                    <span class="v-LblHd1">Quality Assurance Automation</span>
                </p>
                <h1 class="v-textH text-center v-BannerTitle">
                    Speech Automation platform, that drives analytics<br>
                    & insights from 100% of interactions across all channels.


                </h1>
                <h3 class="v-txt1 mb-2 v-exp-sub-title">
                    <!-- AI-powered interaction analytics turn valuable unstructured data
                    from customer conversations into
                    actionable insights that drive customer experience decisions and strategies to automate and improve
                    compliance and quality control, and to enhance agent performance -->
                    Your Quality Assurance Management is now AI Enabled 
                </h3>
                <p>
                    <span class="v-book-demo" title="BOOK A DEMO" (click)="bookDemoBtn()">BOOK A DEMO</span>
                </p>
            </div>
        </div>

        <div class="row bTp1">
            <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                <div class="mt-0 vOneBaner" style="background-image: url('../assets/contiinex/image/blackFullBg.png');padding-top: 24px;">
                    <div class="cSec3"></div>
                    <div class="cee-Sec-div">
                        <img src="../assets/contiinex/image/expBnrImg.png" style="width: 100%;" />
                    </div>
                    <div class="cee-Sec-div-Two mb-4">
                        <table class="text-left ceeTable3">
                            <tr>
                                <td><span class="vkX-desc text-center expBnrTxt">
                                        <!-- Witness improvements across most important business metrics and the contact
                                        center. Ainex with its homegrown natural language processing (NLP) capabilities
                                        & Rule engine to automatically recognize: -->
                                        AI-powered interaction analytics turn valuable unstructured data from customer conversations into actionable insights that drive customer experience decisions and strategies to automate and improve compliance and quality control, and to enhance agent performance
                                    </span></td>
                            </tr>
                        </table>
                    </div>
                    <div class="cSec3"> </div>
                </div>
            </div>


<!--start-->
<div class="col-md-12 col-sm-12 col-xs-12 mt-4 ">
    <h1 class="v-textH text-center v-BannerTitle mt-4"> Auto Audit </h1>
    <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;text-align: center;">
            Analyze every conversation that an agent has with the customer, audit basis an exhaustive list
            of parameters that are curated basis the process and regulatory guidelines to gain insights of
            VOC and improve call quality.
        </span></p>
</div>




<div class="col-md-12 col-sm-12 col-xs-12 mb-0">
    <div class="row vv7">
        <div class="col-md col-sm-1 col-xs-12"></div>
        <div class="col-md-4 col-sm-5 col-xs-12">
            <img src="../assets/contiinex/image/autoAuditImg.png" style="width: 100%;" />
        </div>
        <div class="col-md-5 col-sm-5 col-xs-12">
            <p class="mb-2 mt-4" style="line-height: 1.67;letter-spacing: 0.45px;">
                <span class="vytitle"> How does Contiinex Auto Audit Help?</span>
            </p>
            <p class="vkX-desc vv8" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;">
                It provides an in-depth analysis of individual calls making it easier for the managers and
                quality assurance to make decisions. Each call is measured and compared against performance
                benchmarks to provide a clear picture with an actionable insight of where the agent is
                performing and lacking and scoring basis the set metrics.
            </p>
        </div>
        <div class="col-md col-sm-1 col-xs-12"></div>
    </div>
</div>



<!--start benefit-->



<div>

    <!-- <p class="v-hd2 mt-4" style="font-weight: bold;">Benefits</p> -->
    <div class="col-md-12 col-sm-12 col-xs-12 vv7">
        <div class="row" style="align-items: center;margin:1px 0px 20px 0px;">
            <div class="col-md-1 col-sm-2 col-xs-2"></div>
            <div class="col-md-10 col-sm-8 col-xs-8">
                <p class="mb-0 text-left"><span class="bfitText ml-0"><b style="color:#000">Benefits
                        </b> </span> </p>
    
                <p class="mb-0 text-left benIm" style="border-bottom: 2px solid #249cf4;"><img
                        src="../assets/contiinex/image/hrWidImg.png" class="hrWidImg1" /></p>
    
            </div>
            <div class="col-md-1 col-sm-2 col-xs-2"></div>
        </div>
    </div>

    <div class="row text-center">
        <div class="col-md-2 col-sm-12 col-xs-12"></div>
        <div class="col-md-4 col-sm-12 col-xs-12 p-1">
            <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #ffd5b5;">
                <p  class="text-center " style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon4.png" class="v-ben-fit-img"/></p>
                <p class="mb-1"><span class="v-benefit-titlex1">Strengthen Governance</span></p>
                <p class="v-benefit-descx11">
                    Flag & Reduce any Compliance & Risk Vulnerabilities real time
                </p>

            </mat-card>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 p-1">
            <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #ffd5b5;">
                <p  class="text-center " style="height: 40px;"><img src="../assets/contiinex/image/uVoc.png" class="v-ben-fit-img" style="width: 45px;"/></p>
                <p class="mb-1"><span class="v-benefit-titlex1">Understand VOC
                </span></p>
                <p class="v-benefit-descx11">Multilingual transcription capabilities  

                </p>

            </mat-card>
        </div>
    <div class="col-md-2 col-sm-12 col-xs-12"></div>
    </div>




    <div class="row text-center">
        <div class="col-md-2 col-sm-12 col-xs-12"></div>
        <div class="col-md-4 col-sm-12 col-xs-12 p-1">
            <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #d0e2a2;">
                <p class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon1.png" class="v-ben-fit-img"/></p>
                <p class="mb-1"><span class="v-benefit-titlex1"> Scalability</span></p>
                <p class="v-benefit-descx11">
                    Automate any process or any customer interactions, eliminates regulatory blind spots, reduce & prevent future risks
                     </p>

            </mat-card>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 p-1">
            <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #d0e2a2;">
                <p  class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/bftDepth.png" class="v-ben-fit-img" style="width: 44px;"/></p>
                <p class=" mb-1"><span class="v-benefit-titlex1">
                    In-depth VOC Analysis

                </span></p>
                <p class="v-benefit-descx11">
                    Derive multi level business insights to Increase FTR, Optimize AHT, to identify buyer intent & market trends.

                </p>

            </mat-card>
        </div>
    <div class="col-md-2 col-sm-12 col-xs-12"></div>
    </div>
    <div class="row text-center">
        <div class="col-md-2 col-sm-12 col-xs-12"></div>
        <div class="col-md-4 col-sm-12 col-xs-12 p-1">
            <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #c5c0de;">
                <p class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon3.png" class="v-ben-fit-img"/></p>
                <p class="mb-1"><span class="v-benefit-titlex1"> Accuracy</span></p>
                <p class="v-benefit-descx11">
                    Ability to process and assess large & complex data sets with 97% accuracy
                     </p>

            </mat-card>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 p-1">
            <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #c5c0de;">
                <p  class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon2.png" class="v-ben-fit-img"/></p>
                <p class=" mb-1"><span class="v-benefit-titlex1">
                    Save cost
                </span></p>
                <p class="v-benefit-descx11">
                    Phase wise cost reduction to the business by 50%

                </p>

            </mat-card>
        </div>
    <div class="col-md-2 col-sm-12 col-xs-12"></div>
    </div>
    <div class="row text-center">
        <div class="col-md-2 col-sm-12 col-xs-12"></div>
        <div class="col-md-4 col-sm-12 col-xs-12 p-1">
            <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #efc5df;">
                <p class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon5.png" class="v-ben-fit-img"/></p>
                <p class="mb-1"><span class="v-benefit-titlex1"> Tailored Expertise</span></p>
                <p class="v-benefit-descx11">
                    Improved agent performance through multi level support & Parameters on Etiquette, Process, Compliance & Regulations
                     </p>

            </mat-card>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 p-1">
            <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #efc5df;">
                <p  class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon6.png" class="v-ben-fit-img"/></p>
                <p class="mb-1"><span class="v-benefit-titlex1">
                    <!-- Eliminates Fraud & Risk -->
                    Improved Agent Performance

                </span></p>
                <p class="v-benefit-descx11">
                     Improved agent performance through focused training & real-time feedback

                    </p>

            </mat-card>
        </div>
    <div class="col-md-2 col-sm-12 col-xs-12"></div>
    </div>
    <p class="text-center mt-3 vv4">
        <!-- <span class="v-view-demo text-center" title="Know More" (click)="openKnowMore_benefit()"> KNOW MORE </span> -->
        <span class="v-book-demo" title="BOOK A DEMO" (click)="bookDemoBtn_Benefit()">BOOK A DEMO</span>
    </p>


</div>













<!--end Benefit-->

















<div class="col-md-12 col-sm-12 col-xs-12 vv7">
    <div class="row" style="align-items: center;margin:1px 0px 20px 0px;">
        <div class="col-md-1 col-sm-2 col-xs-2"></div>
        <div class="col-md-10 col-sm-8 col-xs-8">
            <p class="mb-0 text-left"><span class="bfitText ml-0"><b style="color:#000">What’s the impact?
                    </b> </span> </p>

            <p class="mb-0 text-left" style="border-bottom: 2px solid #249cf4;"><img
                    src="../assets/contiinex/image/hrWidImg.png" class="hrWidImg1" /></p>

        </div>
        <div class="col-md-1 col-sm-2 col-xs-2"></div>
    </div>
</div>


<div class="col-md-12 col-sm-12 col-xs-12  ">
    <div class="row" style="align-items: center;margin: 0px 0px 0px 0px;">
        <div class="col-md-2 col-sm-12 col-xs-12"></div>
        <div class="col-md-4 col-sm-12 col-xs-12 text-center">
            <img src="../assets/contiinex/image/waveImgy.png" style="width: 100%;" />
        </div>
        <div class="col-md col-sm-12 col-xs-12">

            <table class="benefitTable">
                <tr>
                    <td><img src="../assets/contiinex/image/autoLog-1.png" class="bxImgWd1" /> </td>
                    <td>
                        <p class="vkX-desc"
                            style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                            Increased sample size by 38% </p>
                    </td>
                </tr>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td><img src="../assets/contiinex/image/autoLog-2.png" class="bxImgWd1" /> </td>
                    <td>
                        <p class="vkX-desc"
                            style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                            Feedback delivery 100% </p>
                    </td>
                </tr>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td><img src="../assets/contiinex/image/autoLog-3.png" class="bxImgWd1" /> </td>
                    <td>
                        <p class="vkX-desc"
                            style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                            Reduced per audit cost from $0.2 to $0.02</p>
                    </td>
                </tr>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td><img src="../assets/contiinex/image/autoLog-4.png" class="bxImgWd1" /> </td>
                    <td>
                        <p class="vkX-desc"
                            style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                            TNI ready effortlessly by end of every month</p>
                    </td>
                </tr>
            </table>
        </div>

        <div class="col-md-2 col-sm-12 col-xs-12"></div>
    </div>
</div>
<!--end-->





            <!--start-->
            <div class="col-md-12 col-sm-12 col-xs-12 vv7">
                <div class="row" style="align-items: center;margin:1px 0px 20px 0px;">
                    <div class="col-md-1 col-sm-2 col-xs-2"></div>
                    <div class="col-md-10 col-sm-8 col-xs-8">
                        <p class="mb-0 text-left"><span class="bfitText ml-0"><b style="color:#000">Use Cases
                                </b> </span> </p>
            
                        <p class="mb-0 text-left" style="border-bottom: 2px solid #249cf4;"><img
                                src="../assets/contiinex/image/hrWidImg.png" class="hrWidImg1" /></p>
            
                    </div>
                    <div class="col-md-1 col-sm-2 col-xs-2"></div>
                </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 mb-2 animation-One2x vk-sec vk-sec2 vkXTop3 p-0 " style="margin: 50px 0 0 0;">
                <h1 class="v-textH text-center"> Property & Auto Insurance </h1>
                <p> <span class="vkX-desc text-center" style="line-height: 1.67;letter-spacing: 0.45px;">
                        Contiinex’s can now automate your QA parameters across sales, service and claims process for
                        insurance, specifically tailored to the needs of automotive & property. Helps to Stay updated of
                        legal compliance requirements at state, federal and international levels.
                    </span></p>

                <div class="mt-0 eec-sec-Baner" style="bottom: 0PX;">
                    <div class="cee-Sec-div-One"> </div>
                    <div class="cee-Sec-divZ">
                        <!-- <img src="../assets/contiinex/image/cee-Img.png" class="ceeImg" /> -->
						<img src="../assets/contiinex/image/propertyandautoinsurance.png" class="propertyInsurance" />
						
                    </div>
                    <div class="cee-Sec-div-Two">
                        <table class="ceeTable vv3">
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>                     
                            <tr>
                                <td colspan="2" class="text-left">
                                    <!-- <span class="vk-cee-title">Parameters </span> -->
                                    <span class="v-LblHd1 pl-0">Parameter Examples</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>
                            <tr>
                                <!-- <td  style="vertical-align: baseline;">
                    <img src="../assets/contiinex/image/ceex4.png" class="v-cee-img"/> </td> -->
                                <td colspan="2"><span class="vk-cee-title">Account & Customer Verification</span> <span
                                        class="vk-cee-desc">
                                        <ul class="vkDotClsClr v-clr-Fintech">
                                            <li>Call Opening</li>
                                            <li>Addressed Customer By appropriate salutation & Last Name</li>
                                            <li>Phone numbers & Email verification </li>
                                        </ul>
                                    </span></td>
                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>
                            <tr>
                                <!-- <td style="vertical-align: baseline;">
                    <img src="../assets/contiinex/image/ceex1.png" class="v-cee-img"/> </td>  -->
                                <td colspan="2"><span class="vk-cee-title">Compliance Adherence </span> <span
                                        class="vk-cee-desc">
                                        <ul class="vkDotClsClr v-clr-Retail">
                                            <li>Complete & Accurate information provided</li>
                                            <li>Call Disposition</li>
                                            <li>Comments/call summary captured and accuracy</li>
                                        </ul>
                                    </span></td>
                            </tr>

                            <tr>
                                <td colspan="2"><br></td>
                            </tr>

                            <tr>
                                <!-- <td  style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex2.png" class="v-cee-img"/> </td>  -->
                                <td colspan="2"><span class="vk-cee-title">Soft skills</span> <span class="vk-cee-desc">
                                        <ul class="vkDotClsClr v-clr-d2c">
                                            <li>Listening Skills</li>
                                            <li>Empathy & Acknowledgement displayed on call</li>
                                            <li>Moderate Rate of Speech</li>
                                        </ul>
                                    </span></td>
                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>
                        </table>
                    </div>
                    <div class="cee-Sec-div-One"> </div>
                </div>

                <div class="bftsvk1">
                    <span class="vk-cee-title text-center">Benefits </span>
                    <span class="vk-cee-desc text-center">
                        <ul class="vkDotClsClr v-clr-d2c vkDotClsClrX1" style="display: inline-flex;">
                            <li> Reduce risk of regulatory compliance</li>
                            <li>Identify dissatisfied customer</li>
                            <li>Help increase process efficiency </li>
                            <li>Audit Every Interaction</li>
                        </ul>
                    </span>
                </div>


                <br>

                <h1 class="v-textH text-center mt-4">
                    Healthcare
                </h1>
                <p class="hCare1"> <span class="vkX-desc text-center" style="line-height: 1.67;letter-spacing: 0.45px;">
                        We help healthcare services providers to analyse their voice & non-voice conversations with
                        their customers across administrative, procedural & outcome quality parameters to highlight
                        risks associated with wrong claims, correct & conclusive diagnosis by service staff and
                        identifying anomalies in updating of medical records.
                    </span></p>




                <div class="mt-0 eec-sec-Baner eecSecBanner2 vv2" style="bottom: 4px;">
                    <div class="cee-Sec-div-One cRytDiv"> </div>
                    <div class="cee-Sec-divZ cRytDiv2 forMobileDiv">
                        <!-- <img src="../assets/contiinex/image/cee-img-2x.png" class="ceeImg" /> -->
						<img src="../assets/contiinex/image/Helathcare.png" class="healthcareCls" />
						
                    </div>
                    <div class="cee-Sec-div-Two cRytWidth vkSecondX2">
                        <table class="ceeTable ceeTable1 vv3">
                            <tr>
                                <td colspan="2" class="cRyt">
                                    <span class="v-LblHd1" style="padding-right: 35px;">Parameter Examples</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <span class="vk-cee-title cRyt">Administrative Questions</span>
                                    <span class="vk-cee-desc cRyt">
                                        <ul class="vkDotClsClr v-clr-Marketplace">
                                            <li>Member/Case ID</li>
                                            <li>Authorization, Client case platform, Claim Number </li>
                                            <li>Reporting Month for Audit</li>
                                        </ul>


                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2"><br></td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <span class="vk-cee-title cRyt"> Compliance & Process</span>
                                    <span class="vk-cee-desc cRyt">
                                        <ul class="vkDotClsClr v-clr-bpoKpo">
                                            <li>Authentication completed & documented in the record</li>
                                            <li>Was PHI process followed</li>
                                            <li>Authorization created & updated</li>


                                        </ul>
                                    </span>
                                </td>

                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <span class="vk-cee-title cRyt"> Objection Handling</span>
                                    <span class="vk-cee-desc cRyt">
                                        <ul class="vkDotClsClr v-clr-bpoKpo">
                                            <li>Managed repeat objection appropriately</li>
                                            <li>Maintained professional tone while Managing Objection</li>
                                            <li>Pacified Customer Post Objection</li>


                                        </ul>
                                    </span>
                                </td>

                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>

                        </table>
                    </div>
                    <div class="cee-Sec-divZ cRytDiv2 forWebDiv">
                        <!-- <img src="../assets/contiinex/image/cee-img-2x.png" class="ceeImg" /> -->
						<img src="../assets/contiinex/image/Helathcare.png" class="healthcareCls" />
                    </div>
                    <div class="cee-Sec-div-One"> </div>
                </div>


                <div class="bftsvk2">
                    <span class="vk-cee-title text-center">Benefits </span>
                    <span class="vk-cee-desc text-center">
                        <ul class="vkDotClsClr v-clr-d2c vkDotClsClrX1" style="display: inline-flex;">
                            <li>Tailored Claims Parameters</li>
                            <li>Audit Every Interaction</li>
                            <li>Identify Claim Anomalies</li>
                            <li>Prototyping New world conditions</li>
                        </ul>
                    </span>
                </div>
                <br>

                <h1 class="v-textH text-center mt-4">
                    Consumer Banking
                </h1>
                <p class="hCare1"> <span class="vkX-desc text-center" style="line-height: 1.67;letter-spacing: 0.45px;">
                        We help banking organisation in investigation of regulation & process adherence across varied
                        financial services such as Banking, loans collection, credit cards to deliver regulatory
                        compliance and enhance customer experience
                    </span></p>

                <div class="mt-0 eec-sec-Baner vv2" style="bottom: 12px;">
                    <div class="cee-Sec-div-One"> </div>
                    <div class="cee-Sec-divZ">
                        <!-- <img src="../assets/contiinex/image/ceeVxImg3.png" class="ceeImg" /> -->
						<img src="../assets/contiinex/image/Cosumerbanking.png" class="consumerBanklingCls" />
						
                    </div>

                    <div class="cee-Sec-div-Two">
                        <table class="ceeTable vv3">
                            <tr>
                                <td colspan="2" class="text-left">
                                    <span class="v-LblHd1 text-left pl-0">Parameter Examples</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>
                            <tr>
                                <td colspan="2"><span class="vk-cee-title">Data Security & Confidentiality </span> <span
                                        class="vk-cee-desc">
                                        <ul class="vkDotClsClr v-clr-Hospitality">
                                            <li>Did the agent follow the account verification process</li>
                                            <li>Did the agent ensure information security & confidentiality</li>

                                        </ul>


                                    </span></td>
                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>
                            <tr>
                                <td colspan="2"><span class="vk-cee-title">Process Adherence</span> <span
                                        class="vk-cee-desc">
                                        <ul class="vkDotClsClr v-clr-Logistic">
                                            <li>Did the agent provide complete and accurate account related information
                                            </li>
                                            <li>Did the agent prompt right services and self-serve options</li>
                                        </ul>
                                    </span></td>
                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>
                            <tr>
                                <td colspan="2"><span class="vk-cee-title">Professionalism </span> <span
                                        class="vk-cee-desc">
                                        <ul class="vkDotClsClr v-clr-Media">
                                            <li>Was the agent willing to help the customer </li>
                                            <li>Agent helped foster positive relationship between the business &
                                                customer</li>
                                        </ul>
                                    </span></td>
                            </tr>

                            <tr>
                                <td colspan="2"><br></td>
                            </tr>


                        </table>
                    </div>
                    <div class="cee-Sec-div-One"> </div>
                </div>
                <div class="bftsvk3">
                    <span class="vk-cee-title text-center">Benefits </span>
                    <span class="vk-cee-desc text-center">
                        <ul class="vkDotClsClr v-clr-d2c vkDotClsClrX1" style="display: inline-flex;">
                            <li> Optimize Call Handling Time</li>
                            <li>Vocal/Tonality Pattern Analysis</li>
                            <li>Develop Transcripts</li>
                            <li>Identify unknown customer issues</li>
                        </ul>
                    </span>
                </div>
                <h1 class="v-textH text-center"> Retail and Ecommerce </h1>
                <p class="hCare1"> <span class="vkX-desc text-center" style="line-height: 1.67;letter-spacing: 0.45px;">
                        Contiinex’s Machine & Deep Learning powered AI Audit are positioned to transform Retail quality
                        audits to gain valuable information about your customers, about products, returns, or
                        promotions, and market. Auto audit can extract actionable customer information to drive sales,
                        and modern AI-powered technology can empower business to boost performance.
                    </span></p>
                <div class="mt-0 eec-sec-Baner eecSecBanner2 vv2" style="bottom: 20px;">
                    <div class="cee-Sec-div-One cRytDiv"> </div>
                    <div class="cee-Sec-divZ cRytDiv2 forMobileDiv">
                        <!-- <img src="../assets/contiinex/image/cee-img-2x.png" class="ceeImg" /> -->
						<img src="../assets/contiinex/image/Retailandecommerce.png" class="Retailandecommerce" />
						
                    </div>
                    <div class="cee-Sec-div-Two cRytWidth vkSecondX2">
                        <table class="ceeTable ceeTable1 vv3">
                            <tr>
                                <td colspan="2" class="cRyt">
                                    <span class="v-LblHd1" style="padding-right: 35px;">Parameter Examples</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <span class="vk-cee-title cRyt">Verification & Authentication</span>
                                    <span class="vk-cee-desc cRyt">
                                        <ul class="vkDotClsClr v-clr-Marketplace">
                                            <li>Covers DPA/Security verification,low customer efforts</li>
                                            <li>Check previous interactions & order / interaction history </li>
                                        </ul>
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2"><br></td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <span class="vk-cee-title cRyt"> Objection Handling & Tonality</span>
                                    <span class="vk-cee-desc cRyt">
                                        <ul class="vkDotClsClr v-clr-bpoKpo">
                                            <li>Takes ownership to avoid repeat contacts</li>
                                            <li>Demonstrates 'empowerment', keeps professional tone</li>
                                        </ul>
                                    </span>
                                </td>

                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <span class="vk-cee-title cRyt">Compliance Adherence </span>
                                    <span class="vk-cee-desc cRyt">
                                        <ul class="vkDotClsClr v-clr-bpoKpo">
                                            <li>Correct & complete process followed</li>
                                            <li> Provided accurate information on TAT & SLA</li>
                                        </ul>
                                    </span>
                                </td>

                            </tr>
                            <tr>
                                <td colspan="2"><br></td>
                            </tr>

                        </table>
                    </div>
                    <div class="cee-Sec-divZ cRytDiv2 forWebDiv">
                        <!-- <img src="../assets/contiinex/image/cee-img-2x.png" class="ceeImg" /> -->
						<img src="../assets/contiinex/image/Retailandecommerce.png" class="Retailandecommerce" />
                    </div>
                    <div class="cee-Sec-div-One"> </div>
                </div>


                <div class="bftsvk4">
                    <span class="vk-cee-title text-center">Benefits </span>
                    <span class="vk-cee-desc text-center">
                        <ul class="vkDotClsClr v-clr-d2c vkDotClsClrX1" style="display: inline-flex;">
                            <li>Tailored Claims Parameters</li>
                            <li>Audit Every Interaction</li>
                            <li>Identify Claim Anomalies</li>
                            <li>Prototyping New world conditions</li>
                        </ul>
                    </span>
                </div>
                <br>
            </div>
            <!--end-->

            <div class="col-md-12 col-sm-12 col-xs-12  ">
                <div class="row" style="align-items: center;margin: 55px 0px 2px 0px;">
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                    <div class="col-md-4 col-sm-12 col-xs-12 text-center">
                        <img src="../assets/contiinex/image/withTeamImg.png" style="width: 79%;" />
                    </div>
                    <div class="col-md col-sm-12 col-xs-12">

                        <table class="benefitTable">
                            <tr>
                                <td><img src="../assets/contiinex/image/wtImg1.png" class="bxImgWd" /> </td>
                                <td><span class="bfitText">Language with various dialect </span></td>
                            </tr>
                            <tr>
                                <td><br></td>
                            </tr>
                            <tr>
                                <td><img src="../assets/contiinex/image/wtImg2.png" class="bxImgWd" /> </td>
                                <td> <span class="bfitText">Customer intent </span></td>
                            </tr>
                            <tr>
                                <td><br></td>
                            </tr>
                            <tr>
                                <td><img src="../assets/contiinex/image/wtImg3.png" class="bxImgWd" /> </td>
                                <td> <span class="bfitText">Topics & Business Keywords</span></td>
                            </tr>
                            <tr>
                                <td><br></td>
                            </tr>
                            <tr>
                                <td><img src="../assets/contiinex/image/wtImg4.png" class="bxImgWd" /> </td>
                                <td> <span class="bfitText">Customer & agent emotions & sentiment </span></td>
                            </tr>
                            <tr>
                                <td><br></td>
                            </tr>
                            <tr>
                                <td><img src="../assets/contiinex/image/wtImg5.png" class="bxImgWd" /> </td>
                                <td> <span class="bfitText">Agent behaviours & choice of words</span></td>
                            </tr>
                        </table>
                    </div>

                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                </div>
                <br> <br>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 mt-4 ">
                <h1 class="v-textH text-center v-BannerTitle"> Speech AI </h1>
                <p class="text-center mb-0">
					 <!-- <img src="../assets/contiinex/image/SpeechAiImg1.png" class="speechImg" /> -->
					 <img src="../assets/contiinex/image/speachai.png" class="speechImg" />
                </p>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12" style="position: relative;bottom: 40px;">
                <div class="speechAIDivX1">
                    <div> <img src="../assets/contiinex/image/speechAiImage1.png" class="spImg" /></div>
                    <div>
                        <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;">We
                                understand conversations and we understand how important they are for business. Making
                                customers feel important is not the only motto, we have developed our very own patented
                                product Speech AI & Analytics. By deeply considering the importance of internal audience
                                of the business too! </span></p>
                    </div>
                </div>

                <div class="forWebMenuOX">
                    <div class="speechAIDivX2">
                        <div>
                            <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;">Your
                                    workforce needs to be valued the same way as the external audience. This is the
                                    foundation of our Speech AI. </span></p>
                        </div>
                        <div> <img src="../assets/contiinex/image/speechAiImage2.png" class="spImg" /></div>
                    </div>
                </div>

                <div class="forMobileMenuOX">
                    <div class="speechAIDivX2">
                        <div> <img src="../assets/contiinex/image/speechAiImage2.png" class="spImg" /></div>
                        <div>
                            <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;">Your
                                    workforce needs to be valued the same way as the external audience. This is the
                                    foundation of our Speech AI. </span></p>
                        </div>
                    </div>

                </div>


                <div class="speechAIDivX3">
                    <div> <img src="../assets/contiinex/image/speechAiImage3.png" class="spImg" /></div>
                    <div>
                        <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;">With the
                                Contiinex AI-powered Speech-to-Text engine, you can now transcribe practically all the
                                languages with an accuracy rate of up to 96% from either voice recordings or live calls
                                real-time. </span></p>
                    </div>

                </div>


                <div class="forWebMenuOX">
                    <div class="speechAIDivX4">

                        <div>
                            <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;">The
                                    Speech engine comes with the capabilities of working both inside your firewall and
                                    online. Experience customer conversation with detail and draw valuable insights to
                                    the business. With an in-house powerful ML and NLP engine, Ainex enables businesses
                                    to automate & Optimize customer engagement and enhance customer experience. It comes
                                    with its powerful levers </span></p>
                        </div>
                        <div> <img src="../assets/contiinex/image/speechAiImage4.png" class="spImg" /></div>

                    </div>
                </div>
                <div class="forMobileMenuOX">

                    <div class="speechAIDivX4">
                        <div> <img src="../assets/contiinex/image/speechAiImage4.png" class="spImg" /></div>
                        <div>
                            <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;">The
                                    Speech engine comes with the capabilities of working both inside your firewall and
                                    online. Experience customer conversation with detail and draw valuable insights to
                                    the business. With an in-house powerful ML and NLP engine, Ainex enables businesses
                                    to automate & Optimize customer engagement and enhance customer experience. It comes
                                    with its powerful levers </span></p>
                        </div>


                    </div>
                </div>

            </div>


            <div class="col-md-12 col-sm-12 col-xs-12 mt-4 ">
                <h1 class="v-textH text-center v-BannerTitle mt-4"> Agent Assist </h1>
                <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;text-align: center;">
                        Knowledgebase that helps agents to be on top of their game every time with every new<br> process
                        update
                        and change. Help your agents to help themselves!
                    </span></p>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                <div class="row" style="align-items: center;margin: 20px 0px;">
                    <div class="col-md col-sm-1 col-xs-12"></div>
                    <div class="col-md-4 col-sm-5 col-xs-12">
                        <img src="../assets/contiinex/image/agentAssistImg.png" style="width: 100%;" />
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <p class="mb-1 text-center"><img src="../assets/contiinex/image/headMic.png"
                                style="width:30px;" /></p>
                        <p class="mb-0 text-center"><span class="bfitText"><b style="color:#000">How does </b> </span>
                        </p>
                        <p class="mb-0 text-center"><img src="../assets/contiinex/image/caaHelp.png"
                                class="caaHelpImg" /></p>
                        <p class="vkX-desc text-center"
                            style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0;">
                            It enables agents to be experts, helps them within process prompts and relevant information
                            that he/she needs to provide to the customer at any stage of the conversation in just
                            clicks. Agents can now be up to pace without training.
                        </p>
                    </div>
                    <div class="col-md col-sm-1 col-xs-12"></div>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="row vv7">
                    <div class="col-md-1 col-sm-2 col-xs-2"></div>
                    <div class="col-md-10 col-sm-8 col-xs-8">
                        <p class="mb-0 text-left"><span class="bfitText ml-0"><b style="color:#000">What’s the impact?
                                </b> </span> </p>

                        <p class="mb-0 text-left" style="border-bottom: 2px solid #249cf4;"><img
                                src="../assets/contiinex/image/hrWidImg.png" class="hrWidImg1" /></p>
                        <p class="mb-0 text-center"><img src="../assets/contiinex/image/cccGraphImg.png"
                                class="hrWidImg2" /></p>
                    </div>
                    <div class="col-md-1 col-sm-2 col-xs-2"></div>
                </div>

            </div>
        

            <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                <div class="row vv6" style="align-items: center;">
                    <div class="col-md col-sm-12 col-xs-12"></div>
                    <div class="col-md-5 col-sm-12 col-xs-12 secruityDiv">
                        <p class="mb-0"><span class="bfitText bf1"><b style="color:#000">Security </b> </span> </p>
                        <table style="margin-bottom: 15px;">
                            <tr>
                                <td>
                                    <p class="vkX-desc vv5"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Voice Biometrics</p>
                                </td>
                                <td style="border-right: 1px solid #e7e6e6;width: 18px;"> </td>
                                <td>
                                    <p class="vkX-desc vv5"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Computer Vision</p>
                                </td>
                            </tr>

                        </table>

                    </div>
                    <div class="col-md-5 col-sm-12 col-xs-12 secruityDiv">

                        <p class="mb-0"><span class="bfitText bf1"><b style="color:#000">Customer Speech Automation </b>
                            </span> </p>
                        <table style="margin-bottom: 15px;">
                            <tr>
                                <td>
                                    <p class="vkX-desc vv5"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Service Ticketing</p>
                                </td>
                                <td style="border-right: 1px solid #e7e6e6;width: 18px;"> </td>
                                <td>
                                    <p class="vkX-desc vv5"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Restaurant Kiosk</p>
                                </td>
                                <td style="border-right: 1px solid #e7e6e6;width: 18px;"> </td>
                                <td>
                                    <p class="vkX-desc vv5"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Speech Transcription</p>
                                </td>
                            </tr>

                        </table>
                    </div>
                    <div class="col-md col-sm-12 col-xs-12"></div>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 mt-4">
                <p class="mb-0 text-center mt-4 vv9"><span class="bfitText ml-0"><b style="color:#000">AI-powered
                            interaction analytics analyze the data for </b> </span> </p>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 mt-4 mb-4">

                <div class="interactionDiv mt-3 mb-4">

                    <div>
                        <table>
                            <tr>
                                <td>
                                    <p class="vkX-desc text-right"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Correlation</p>
                                </td>
                                <td>
                                    <h2 class="v-lineH2"><span></span></h2>
                                </td>
                                <td> <img src="../assets/contiinex/image/interactionImg1.png" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="vkX-desc text-right"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Trends </p>
                                </td>
                                <td>
                                    <h2 class="v-lineH2"><span></span></h2>
                                </td>
                                <td> <img src="../assets/contiinex/image/interactionImg2.png" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="vkX-desc text-right"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Categorization</p>
                                </td>
                                <td>
                                    <h2 class="v-lineH2"><span></span></h2>
                                </td>
                                <td> <img src="../assets/contiinex/image/interactionImg3.png" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="vkX-desc text-right"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Topics </p>
                                </td>
                                <td>
                                    <h2 class="v-lineH2"><span></span></h2>
                                </td>
                                <td> <img src="../assets/contiinex/image/interactionImg4.png" /></td>
                            </tr>

                            <tr>
                                <td>
                                    <p class="vkX-desc text-right"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Quality and compliance</p>
                                </td>
                                <td>
                                    <h2 class="v-lineH2"><span></span></h2>
                                </td>
                                <td> <img src="../assets/contiinex/image/interactionImg5.png" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="vkX-desc text-right"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Agent performance</p>
                                </td>
                                <td>
                                    <h2 class="v-lineH2"><span></span></h2>
                                </td>
                                <td> <img src="../assets/contiinex/image/interactionImg6.png" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="vkX-desc text-right"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Zero Tolerance attributes </p>
                                </td>
                                <td>
                                    <h2 class="v-lineH2"><span></span></h2>
                                </td>
                                <td> <img src="../assets/contiinex/image/interactionImg7.png" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="vkX-desc text-right"
                                        style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 4px 0 4px 20px;">
                                        Customer experience</p>
                                </td>
                                <td>
                                    <h2 class="v-lineH2"><span></span></h2>
                                </td>
                                <td> <img src="../assets/contiinex/image/interactionImg8.png" /></td>
                            </tr>

                        </table>
                    </div>

                </div>

            </div>

            <div class="col-md-12 col-sm-12 col-xs-12">
                <app-new-contiinex-footer-section-page></app-new-contiinex-footer-section-page>
            </div>

        </div>
    </div>