<h4 class="titlehead">Project Manager Dashboard (INBOUND)</h4>
<table class="maintable">
	<tr>
		<td>
			<div class="containecs">
				<div class="colcomn">
					<div class="card">
						<div class="card-header">INBOUND CALLS</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Attempted</td><td>{{ summary.calls.attempt }}</td></tr>
								<tr><td>Abandoned</td><td>{{ summary.calls.abandoned }}</td></tr>
								<tr><td>Avg.calls</td><td>{{ summary.calls.avg_calls }}</td></tr>
							</table> 
						</div>
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">INBOUDND AHT</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Total</td><td>{{ summary.aht.total }}</td></tr>
								<tr><td>Average</td><td>{{ summary.aht.avg }}</td></tr>
							</table> 
						</div>
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Agent Status</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Active</td><td>{{ summary.status.active }}</td></tr>
								<tr><td>On Break</td><td>{{ summary.status.on_break }}</td></tr>
								<tr><td>Not Active</td><td>{{ summary.status.not_active }}</td></tr>
							</table> 
						</div>
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Agent</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Active</td><td>{{ summary.agents.active }}</td></tr>
								<tr><td>Contacted</td><td>{{ summary.agents.contacted }}</td></tr>
								<tr><td>Above Avg.Contacted</td><td>{{ summary.agents.above_avg }}</td></tr>
							</table> 
						</div>
					</div>
				</div>
			</div>
		</td>
	</tr>
</table>
<div class="pDiv">
	<div class="divprintRt">
		<table style="width: 100%;">
			<tr>
				<td>
					<span class="recName">Project Manager</span>
					<span class="datecs">{{ today }}</span>
				</td>
				<td style="text-align: right;position: relative;right: 20px;">
					<span class="searchSpan">
					   <mat-form-field >
						  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
						  <i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
					   </mat-form-field>
					</span>
				 </td>
			</tr>
			<tr>
				<td>
					<p>
						<span class="das-pe">Print</span>
						<span class="das-pe">Export</span>
						<span class="das-pe" (click)="refreshData()">Refresh</span>
					</p>
				</td>
			</tr>
		</table>
	</div>
</div>
<div class="pDiv">
	<mat-table [dataSource]="dataSource">
		<ng-container matColumnDef="user_id">
            <mat-header-cell *matHeaderCellDef> Agent Id </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.user_id }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="sa_id">
            <mat-header-cell *matHeaderCellDef> SA Id </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.sa_id }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> Active Status</mat-header-cell>
            <mat-cell *matCellDef="let element">
				<span class="statusCircle" [ngStyle]="{'background-color': element.status }"></span>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
		</ng-container>
		<!-- <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
		</ng-container> -->
		<ng-container matColumnDef="contacted">
            <mat-header-cell *matHeaderCellDef> Contacted </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.contacted }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="not_contacted">
            <mat-header-cell *matHeaderCellDef> Abandoned </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.not_contacted }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="aht">
            <mat-header-cell *matHeaderCellDef> AHT </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.aht }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="login_min">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> Login Minutes </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.login_min }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="connected_min">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> Connected Minutes </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.connected_min }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="avg_aht">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> Avg. AHT </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.avg_aht }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="audit_score">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> Audit Score </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.audit_score }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="ctc_score">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> CTC Score </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.ctc_score }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="earning">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> Earnings </mat-header-cell>
            <mat-cell *matCellDef="let element">&#8377;&nbsp;{{ element.earning }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="active_time">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> Active Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.active_time }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="afk">
            <mat-header-cell *matHeaderCellDef class="text-wrap"> AFK </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.afk }}</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
	</mat-table>
	<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>