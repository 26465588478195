<body>
    <app-home-header></app-home-header>
    <div>
            <div class="row" style="margin: 8% auto;">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <h2 class="forgot-title">Reset Password </h2>
                    <div class="under_line"></div><br>
                        <div class="input-label-group">
                            <label>Password *</label>
                            <input type="password" class="" placeholder="Enter Password" [(ngModel)]="new_password" (keypress)="omit_special_char($event)">                        
                        </div>
                        <div class="input-label-group" style="margin-top: 20px;">
                            <label>Confirm Password *</label>
                            <input type="password" class="" placeholder="Enter Confirm Password" [(ngModel)]="confirm_password" (keypress)="omit_special_char($event)">                        
                        </div>
                        <div style="margin-top: 20px;" *ngIf="errorMsg != '' || msg != ''">
                            <small style="color:red;">{{errorMsg}}</small>
                        </div>
                        <div style="margin-top: 20px;" *ngIf="msg != ''">
                            <small style="color:green;">{{msg}}</small>
                        </div>
                        <div style="margin-top: 20px;">
                            <button class="loginbtn" type="submit" (click)="resetPasswordSubmit()">Submit</button> 
                        </div>
                </div>
                <div class="col-md-6">
                    
                </div>
            </div>
    </div>  
</body>
<div id="cover-spin" *ngIf="showLoader"></div>
