<div class="row m-3">
	<div class="col-md-12  text-center">
		<div>
			<mat-form-field style="width:100%">
				<mat-label>Title</mat-label>
				<mat-select (selectionChange)="titleDroDownChange($event.value)">
				  <mat-option [value]="v.Notification_Title" *ngFor="let v of _settingNotifcn">{{v.Notification_Title}}</mat-option>
				</mat-select>
			  </mat-form-field>
			  <br>
			
		</div>
		<!-- <div>
		<button class="btnOption1" matTooltip="Click Switch to Active"  [ngClass]="{ activeNotfcn: isActiveNotification(1) }"   (click)="clickNotificationBtn(1)">Switch To Active</button>
		<button class="btnOption1 ml-3" matTooltip="Click Custom Notification" [ngClass]="{ activeNotfcn: isActiveNotification(2) }"   (click)="clickNotificationBtn(2)">Custom Notification</button>
	</div> -->
</div>
	</div>
<div class="row m-2">
	<div class="col-md-12 mt-0" >
		<!-- *ngIf="isShowSwitchActiveNotification" -->
		<form>
			<div *ngIf="_infoAlert">
			<h4>Information</h4> 		
				<h5 class="infx1" >
					<!-- Are you really want to switch agent from break to active mode? -->
					{{_infoAlert}}
				</h5>
			</div>
				<div class="m-1">
					<textarea class="tArea1" id="textAreaValId" placeholder="Description" maxlength="255" (change)="texAreaChange()" [value]="descriptionModel">{{descriptionModel}}</textarea>
				
				</div>
				<!-- <h5 class="infx1">Get Back to Active Mode</h5>
				<h5 class="infx1">You Have New Campaign on priority to Call</h5> -->
			  <br>
			 <!-- <p class="text-right">
				<button class="okBtn1" style="margin-right: 18px;height:50px;">No</button>
				 <button class="subBtnx" style="width: 90px;">Yes</button> 
				</p>		  -->
		</form>
	</div>







<!-- 
	<div class="col-md-12 mt-4" *ngIf="isShowCustomNotification">
		<form>
			<h4>Custom Notification</h4> 
		
				<mat-form-field style="width:100%" >
				  <input matInput placeholder="Title">
				</mat-form-field>
				<br>
			  
				<mat-form-field style="width:100%" >				
				  <textarea matInput placeholder="Description2"></textarea>
				</mat-form-field>
			  <br>
		
		</form>
	</div> -->
</div>

<div class="row mt-4">
	<div class="col-md-12">
		<p class="text-right">
			<button (click)="closeDialog('false')" class="okBtn1" >Close</button>
			<button (click)="sendBtn()" class="subBtnx">Send</button> 
			<!-- *ngIf="isShowSubmit" -->
		</p>
	</div>

	</div>