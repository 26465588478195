<div class="scrollAss">
    <form [formGroup]="createdForm">
        <div class="row">
			<div class="col-md-12">
	<table class="tbl mt-3" style="width: 100%;">
		<tr style="border-bottom: 1px solid #ccc;">
			<td><h5 class="hd5">Upload New Contact</h5></td>
			<td style="text-align: right;"> 
				<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closeDialog()"></i>
			</td>
	</tr>
	<tr><td colspan="2"><br></td></tr>
	<tr>
		<td>&nbsp;</td>
		<td> 
			<span class="dnldbtn">
			<button mat-button *ngIf="!issettingComponent"  (click)="download_file()">Download Excel Template
			&nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i></button>
			<!--<a href="{{downloadfile}}" mat-button target="_blank" style="background: #3fb90d !important">Download Excel Template1
			&nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i></a>-->
			<button mat-button *ngIf="issettingComponent"  (click)="download_file()">Download Excel Template
				&nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
				</button>
			</span>
		</td>
	</tr>
	<!-- <tr><td colspan="2"><br></td></tr> -->
	<tr>
		<td><span class="upSiz">Upload New Contact<br/> (only supports xlsx file)*</span></td>
		<td>   
			<div class="upload-btn-wrapper" style="width: 105px;">
				<button class="btnbrowse">Select file</button>
			<input type="file" #fileUpload class="filecs" id="fileID" formControlName="file"  (change)="changeFile($event)"/>
			</div>
			<span>
				<button mat-button  *ngIf="!issettingComponent" style="background: #fd6c21 !important" class="upload_btn uploadSave" (click)="submitCandidate(createdForm)">Submit  </button>
				<button mat-button  *ngIf="issettingComponent" class="upload_btn uploadSave" (click)="submitCandidate(createdForm)">Submit  </button>
			</span>
		</td>
	</tr>
	<tr>
		<td></td>
		<td style="color: #bababa;">
			<p style="font-size: 15px;">{{currentFileobj.name}}</p>
		</td>
	</tr>
	</table>
			</div>
		</div>
	</form>
	<p class="succData">
		{{ fileUploadStatus }}
	</p>
	<div class="row" *ngIf="isresponse_success">
		<div class="col-md-3">
			<label> Uploaded Contacts </label>
			<p class="upSiz"> {{responseobj.Uploaded_Contacts }} </p>
		</div>
		<div class="col-md-3">
			<label> Updated Contacts </label>
			<p class="upSiz">{{responseobj.Updated_Contacts}} </p>
		</div>
		<div class="col-md-3">
			<label> Failed Contacts </label>
				<p class="upSiz"> {{responseobj.Failed_Contacts}} </p>
		</div>
		<div class="col-md-3">
			<label> Deleted Contacts </label>
				<p class="upSiz">{{responseobj.Deleted_Contacts}} </p>
		</div>
	</div>
</div>