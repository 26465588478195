import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ApiService } from '../api.service';
import { ServicesService } from '../dashboard/services.service';

import { EditIDComponent } from './edit-id/edit-id.component';
import { MyserviceService } from '../myservice.service';

import { Utils } from '../utils';

@Component({
  selector: 'app-onboard-techsupport',
  templateUrl: './onboard-techsupport.component.html',
  styleUrls: ['./onboard-techsupport.component.scss']
})
export class OnboardTechsupportComponent implements OnInit {
	currentTitle = 'onboardTechSupport';
	currentLoader = false;
	today = moment().format('DD-MMM-YYYY');
	rawData = [];
	utils = new Utils();
	
	default_summary = function () {
		return {
			'uearn': {
				'total': 0,
				'created': 0
			},
			'nitto': {
				'total': 0,
				'created': 0
			},
			'project': {
				'total': 0,
				'assigned': 0
			},
			'app': {
				'total': 0,
				'installed': 0
			}
		}
	}

	displayedColumns = [
		'applicant_id',
		'applicant_name',
		'applicant_email',
		'reg_date',
		'uearn_id',
		'nitto_id',
		'app_status',
		'edit'
	];

	@ViewChild(MatPaginator) paginator: MatPaginator;
	dataSource = new MatTableDataSource();
	api_end_point:any;
	ops_ts_summary = this.default_summary();

	constructor(
		private dashService: ServicesService,
		private API: ApiService,
		private dialog: MatDialog,
		private myservice: MyserviceService,
	) { 
		this.refreshData();
	}

	ngOnInit() {
		this.api_end_point = this.myservice.get_API_End_Point();
	}

	applyFilter(val: string) {
		this.dataSource.filter = val.trim().toLowerCase();
	}

	refreshData() {
		this.currentLoader = true;
	//	console.log('Refreshing the data');
		this.dashService.setOPSTSTableData(this.api_end_point).then(() => {
			this.dashService.ops_ts_table_data.subscribe(d => this.rawData = d);
		//	console.log('data received', this.rawData);
			this.prepareTableData();
			this.currentLoader = false;
		});
	}

	prepareTableData() {
		let table_data = this.rawData;
		let arr = [];
		if (table_data && table_data.length) {
			for (var i in table_data) {
				let loc_obj = table_data[i];
				let obj = {};
				obj['applicant_id'] = loc_obj['applicant_id'];
				obj['applicant_name'] = loc_obj['applicant_name'];
				obj['applicant_number'] = loc_obj['applicant_number'];
				obj['reg_date'] = this.utils.utcToLocal(loc_obj['applicant_reg_date'], 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY');
				obj['company'] = loc_obj['company'];
				obj['custom_id'] = loc_obj['custom_id'];
				obj['custom_id_created'] = this.utils.utcToLocal(loc_obj['custom_id_created_at'], 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY');
				obj['groupid'] = loc_obj['groupid'];
				obj['role'] = loc_obj['role'];
				obj['source_app_version'] = this.fetchAppVersion(loc_obj['source_app_version']);
				obj['app_status'] = obj['source_app_version'].length ? 'Installed' : 'Not Installed';
				obj['user_id'] = loc_obj['user_id'];
				//console.log('table data',obj);
				arr.push(obj);
			}
		}
		this.refreshTableData(arr);
		this.table_summary(arr);
	}

	table_summary(arr) {
		this.ops_ts_summary = this.default_summary();
		if (arr && arr.length) {
			for (var i in arr) {
				let loc = arr[i];
			//	console.log('printing loc', loc);
				if (loc['user_id'] && String(loc['user_id']).length) {
					this.ops_ts_summary.uearn.total += 1;
					this.ops_ts_summary.uearn.created += 1;
				}
				if (loc['custom_id'] && String(loc['custom_id']).length) {
					this.ops_ts_summary.nitto.total += 1;
					this.ops_ts_summary.nitto.created += 1;
				}
				if (loc['groupid'] && String(loc['groupid']).length) {
					this.ops_ts_summary.project.total += 1;
					this.ops_ts_summary.project.assigned += 1;
				}
				if (loc['app_status'] && (loc['app_status'] == 'Installed')) {
					this.ops_ts_summary.app.total += 1;
					this.ops_ts_summary.app.installed += 1;
				}
			}
		}
	}

	editBtnClick(event,ele) {
	//	console.log('Edit button click', ele);
		var dailogConfig = new MatDialogConfig();
		dailogConfig.width = '40%';
		dailogConfig.autoFocus = true;
		dailogConfig.data = ele;
		this.dialog.open(EditIDComponent, dailogConfig);
	}

	fetchAppVersion(obj: any) {
		let result = '';
		if (obj && obj.length) {
			try {
				let p = JSON.parse(obj);
				//console.log('version parse', p);
				if (p.hasOwnProperty('UearnApp_android')) {
					result = p['UearnApp_android'];
				}
			} catch (e) {
				
			}
		}
		return result;
	}

	statusColor(val:string) {
		let color = '#174a84';
		switch (val) {
			case 'Installed':
				color = '#44be20';
				break;
			case 'Not Installed':
				color = '#e5293e';
				break;
		}
		return color;
	}

	refreshTableData(d) {
		this.dataSource = new MatTableDataSource(d);
		this.dataSource.paginator = this.paginator;
	}
}
