<div>
    <mat-card class="pCard">
        <div *ngIf="showHidePassingMessage">
            <h4  [style.color]="_color" style="font-weight: bold;font-size: 20px;">{{passingMessage}}</h4>          
             </div>
        <form [formGroup]="createdForm">
        <div class="row">
            <div class="col-md-12">
                <h1 class="hOne">Base Price</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <label class="Lbl">Type <span>*</span></label>
                <mat-select [ngClass]="'arrowcs1'" class="dropdownCs dropcs" placeholder="Select" formControlName="basePriceType">
                            <mat-option value="per_minute">Per Minute</mat-option>
                            <mat-option value="per_login_hours">Per Login Hours</mat-option>
                            <mat-option value="per_day">Per Day</mat-option>
                            <mat-option value="per_call">Per Call</mat-option>
                            <mat-option value="per_event">Per Event</mat-option>
                        </mat-select>
            </div>
            <div class="col-md-3">
                <label class="Lbl">Rate <span>*</span></label>
                <input matInput maxlength="10" autocomplete="off"   (keypress)="decimalFilter($event)" formControlName="basePriceRate"  [ngClass]="'arrowcs'" class="dropdownCs input1" placeholder="Enter Value"/>             
            </div>
        </div>
        <br>
        <div class="row" style="display: none;">
            <div class="col-md-12">
                <h1 class="hOne">Surge Price</h1>
            </div>
        </div>
        <div class="row" style="display: none;">
            <div class="col-md-3">
                <label class="Lbl">Type</label>
                <mat-select [ngClass]="'arrowcs1'" class="dropdownCs dropcs" placeholder="Select"  formControlName="surgePriceType">
                    <mat-option>None</mat-option>
                    <mat-option value="1">Calls Taken</mat-option>
                    <mat-option value="2">Time Slot</mat-option>
                    <mat-option value="3">Audit Score</mat-option>
                    <mat-option value="4">Total Talk Time</mat-option>
                        </mat-select>
            </div>
            <div class="col-md-9">
                <div style="display: flex;flex-wrap: wrap;">
                        <div style="width:155px">
                            <label class="Lbl">Minimum Value</label>
                            <input matInput maxlength="10"  autocomplete="off"  formControlName="surgePriceMinvalue"  [ngClass]="'arrowcs1'" class="dropdownCs input1" placeholder="Enter Value"/> 
                        </div>
                        <div style="width:155px">
                            <label class="Lbl">Surge Price</label>
                            <input matInput maxlength="10"  autocomplete="off"  formControlName="surgePriceRate" [ngClass]="'arrowcs1'" class="dropdownCs input1" placeholder="Enter Value"/> 
                        </div>
                    </div>
                          
          
                         
            </div>
        </div>
        <br>
        <div class="row">
            
            <div class="col-md-12 textLR">
               <button class="reset-btn-ui" (click)="resetBtn()"> Reset </button>
               <button class="submit-btn-ui" (click)="saveBtn(createdForm)" [ngClass]="!createdForm.valid ? 'cls1' : 'cls2'"  [disabled]="!createdForm.valid" > Submit</button>
            </div>
        </div>
    </form>
    </mat-card>
</div>