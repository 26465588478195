<form [formGroup]="clientForm">
	<table class="tbl">
		<tr style="border-bottom: 1px solid #ccc;">
			<td>
				<h5 class="hd5" style="margin-top: 0px;">Upload Client status excel Sheet</h5>
			</td>
			<td style="text-align: right;"> 
				<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
			</td>
	   </tr>
	   <tr>
			<td><p [style.color]="_color">{{passingMessage}}</p></td>
			<td> 
			<span class="dnldbtn">
			<button mat-button (click)="download_file()">Download Excel Template
			&nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
			</button>
			</span>
			</td>
		</tr>
		<tr>
			<td><span>Data</span><span> (only supports xlsx file)</span></td>
			<td>
			   <!-- <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)"/> -->
			   <!-- <label for="file">Upload</label> -->
			   <div class="upload-btn-wrapper">
				  <button class="btnbrowse">Browse</button>
			   <input type="file" #fileUpload class="filecs" formControlName="upload_file" (change)="changeFile($event)"/>
				</div>
				<span>
				  <button mat-button class="upload_btn uploadSave" (click)="submit()">Submit </button>
				</span>
			</td>
		</tr>
		<tr><td><span style="color:gray;font-size:11px;">{{currentFileName}}</span></td></tr>
	</table>
</form>