import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'underscore';

@Component({
	selector: 'app-table-filter',
	templateUrl: './table-filter.component.html',
	styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit, AfterViewInit, OnChanges {

	@Input() table_data: any;
	@Input() filter_columns_obj: any;
	@Output() filtered_data = new EventEmitter<any>();

	ref_table_data: any = [];
	ref_filter_columns: any = [];
	ref_filter_columns_formControl = {};
	ref_filter_columns_dropDowns = {};
	ref_filter_columns_dropDown_select = {};
	constructor() { }

	ngOnInit(): void {
		// console.log('onInit', this.table_data);
		this.ref_filter_columns = this.filter_columns_obj;
		this.ref_table_data = this.table_data;
		this.resetColumnsFilters()
	}

	ngAfterViewInit() {
		// console.log('After view init', this.table_data);
		this.ref_filter_columns = this.filter_columns_obj;
		this.ref_table_data = this.table_data;
		this.resetColumnsFilters()
	}

	ngOnChanges(changes: SimpleChanges) {
		// changes.prop contains the old and the new value...
		// console.log('On changes', changes);
		// console.log('on changes', this.table_data);
		this.ref_filter_columns = this.filter_columns_obj;
		this.ref_table_data = this.table_data;
		this.resetColumnsFilters()
	}

	getUniquevalues(key: string) {
		let arr = [];
		let loc_table_data = this.ref_table_data.data;
		for (var i in loc_table_data) {
			if (loc_table_data[i].hasOwnProperty(key)) {
				// console.log('local data value', key, loc_table_data[i]);
				arr = arr.concat(loc_table_data[i][key]);
			}
		}
		return arr;
	}

	resetColumnsFilters() {
		this.ref_filter_columns_formControl = {};
		let loc_table_data = this.ref_table_data.data;
		if (this.ref_filter_columns && this.ref_filter_columns.length) {
			for (var i in this.ref_filter_columns) {
				this.ref_filter_columns_formControl[this.ref_filter_columns[i]['key']] = new FormControl();
				// console.log('reset filter', this.ref_filter_columns[i]['key']);
				let p = _.uniq(_.pluck(loc_table_data, this.ref_filter_columns[i]['key']));
				this.ref_filter_columns_dropDowns[this.ref_filter_columns[i]['key']] = p.sort();
			}
		}
		this.ref_filter_columns_dropDown_select = { ...this.ref_filter_columns_dropDowns };
	}

	on_type(val, column) {
		this.ref_filter_columns_dropDown_select = { ...this.ref_filter_columns_dropDowns };
		// console.log('printing input value', val);
		if (val && val.length) {
			let val_key = val.toLowerCase();
			this.ref_filter_columns_dropDown_select[column.key] = _.filter(this.ref_filter_columns_dropDowns[column.key], function (ele) { return ele && ele.toLowerCase().includes(val_key); });
		}
	}

	emit_filtered_data() {
		/* console.log('hit emit_filtered');
		console.log('filter columns', this.ref_filter_columns);
		console.log('table_data', this.ref_table_data);
		console.log('ref_table_forms', this.ref_filter_columns_formControl);
		console.log('dropdown values', this.ref_filter_columns_dropDowns); */
	}
	applyFilter() {
		console.log('Applying filter', this.ref_filter_columns_formControl);
		let obj = {};
		for (var i in this.ref_filter_columns_formControl) {
			let filter_values = _.filter(this.ref_filter_columns_formControl[i].value, (element) => {
				console.log(element, typeof element);
				return !((element == null) || (element == undefined))
			})
			if (filter_values && filter_values.length) {
				obj[i] = filter_values;
			}
		}
		// console.log('printing obj', obj);
		if (_.keys(obj).length) {
			this.filtered_data.emit(obj);
		}
	}
	resetFilter() {
		for (var i in this.ref_filter_columns_formControl) {
			this.ref_filter_columns_formControl[i].reset();
		}
		this.filtered_data.emit('NULL FILTER');
	}
}
