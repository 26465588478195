<mat-table [dataSource]="dataSource" matSort  style="width: 100%;overflow-x: scroll;">
	<ng-container matColumnDef="user_id">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Agent Id </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.user_id }}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="name">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Agent Name </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{ element.name }}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="team_lead">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Team Lead </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.team_lead }}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="level">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Level </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.level }}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="status">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Live Status </mat-header-cell>
		<mat-cell *matCellDef="let element">
			<span class="statusCircle" style="position: relative; margin:0" [ngStyle]="{'background-color': element.status_color }"></span>
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="dialled">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Dialled </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.dialled || 0 }}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="unique_dialled">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Unique Dialled </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.unique_dialled  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="contacted">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Contacted </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.contacted  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="unique_contacted">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Unique Contacted </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.unique_contacted || 0 }}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="rnr">
		<mat-header-cell *matHeaderCellDef mat-sort-header> RNR </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.rnr  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="forms">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Forms </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.forms  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="audit_score">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Audit Score </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.audit_score  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="ctc_score">
		<mat-header-cell *matHeaderCellDef mat-sort-header> CTC Score </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ element.ctc_score  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="break_time">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Break Time </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ converttoHMS(element.break_time)  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="idle_time">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Idle Time </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ converttoHMS(element.idle_time)  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="wait_time">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Wait Time </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ converttoHMS(element.wait_time)  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="spoken_time">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Spoken Time </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ converttoHMS(element.spoken_time)  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="wrap_time">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Wrap Time </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ converttoHMS(element.wrap_time)  || 0}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="login_time">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Login Hours </mat-header-cell>
		<mat-cell *matCellDef="let element">{{ converttoHMS(element.login_time)  || 0}}</mat-cell>
	</ng-container>

		<ng-container matColumnDef="more">
            <mat-header-cell *matHeaderCellDef mat-sort-header> More </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="Open Detail Timings" class="" matTooltip="Details"
                matTooltipPosition="below" (click)="openDetailActivity(element)">
                <mat-icon style="color: #fd6c21 !important;">launch</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
	
	<mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
	<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>