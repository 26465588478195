<app-menu [navigateLink]="currentTitle"></app-menu>

<div class="container-fluid main-section-layout">
<ngb-alert [type]="alert.type" class="ng-alert-dialog" *ngIf="!alertClosed"  (close)="alertClosed = true">{{ alert.message }}</ngb-alert>

    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 pb-3">
            <span style="font-size: 30px; color: #7030a0;font-weight: 600;">Outbound Calls</span>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 pr-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>New Data</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Total Data(Active & In Active)</span><hr class="level1" [style.width.%]="this.summary.data.total_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.summary.data.total_perc}}%</span>({{this.summary.data.total}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Data Dialled</span><hr class="level2" [style.width.%]="this.summary.dialled.unique_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.summary.dialled.unique_perc}}%</span>({{this.summary.dialled.unique}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Data Contacted</span><hr class="level3" [style.width.%]="this.summary.contacted.unique_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.summary.contacted.unique_perc}}%</span>({{this.summary.contacted.unique}})</span>
                       
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Data to Call</span><hr class="level4" [style.width.%]="this.summary.data.data_to_call_perc"/></span>
                       
                        <span class="tile-text"><span class="tile-percent">{{this.summary.data.data_to_call_perc}}%</span>({{this.summary.data.data_to_call}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Total Connected Calls</span><hr class="level5" [style.width.%]="this.summary.contacted.total_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.summary.contacted.total_perc}}%</span>({{this.summary.contacted.total}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Total Calls</span><hr class="level6" [style.width.%]="0"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.summary.data.total_calls}}</span></span>
                    </div>
                    
                    <!-- <div class="d-flex flex-row justify-content-between py-2">
                        <span>Data to call</span>
                        <span class="pr-2">{{ summary.data.data_to_call }}</span>
                    </div>
                    <div class="d-flex flex-row justify-content-between py-2">
                        <span>Available Data</span>
                        <span class="pr-2">{{ summary.data.available }}</span>
                    </div>
                    <div class="d-flex flex-row justify-content-between py-2">
                        <span>Followup Todo</span>
                        <span class="pr-2">{{ summary.data.followup }}</span>
                    </div> -->
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12 pr-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Follow ups</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <!-- <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Total Data(Active & In Active)</span><hr class="level1" [style.width.%]="this.summary.data.total_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.summary.data.total_perc}}%</span>({{this.summary.data.total}})</span>
                    </div> -->
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Total</span><hr class="level1" [style.width.%]="this.dashSummary.followup_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.dashSummary.followup_perc}}%</span>({{this.dashSummary.followup}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Completed</span><hr class="level2" [style.width.%]="this.dashSummary.followupdone_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.dashSummary.followupdone_perc}}%</span>({{this.dashSummary.followupdone}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">ToDo</span><hr class="level3" [style.width.%]="this.dashSummary.followuptodo_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.dashSummary.followuptodo_perc}}%</span>({{this.dashSummary.followuptodo}})</span>
                    </div>
                   <!--  <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Dialled</span><hr class="level3" [style.width.%]="50"/></span>
                        <span class="tile-text"><span class="tile-percent">50%</span>(677)</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Unique Dialled</span><hr class="level4" [style.width.%]="35"/></span>
                        <span class="tile-text"><span class="tile-percent">25%</span>(266)</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Contacted</span><hr class="level5" [style.width.%]="25"/></span>
                        <span class="tile-text"><span class="tile-percent">25%</span>(89)</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Unique Contacted</span><hr class="level6" [style.width.%]="10"/></span>
                        <span class="tile-text"><span class="tile-percent">10%</span>(20)</span>
                    </div> -->
                    <!-- <div class="d-flex flex-row justify-content-between py-2">
                        <span>Total</span>
                        <span class="pr-2">{{ summary.dialled.total }}</span>
                    </div>
                    <div class="d-flex flex-row justify-content-between py-2">
                        <span>Unique</span>
                        <span class="pr-2">{{ summary.dialled.unique }}</span>
                    </div> -->
                 
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12 pr-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Agents Status</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row py-2">
                        <div class="col-md-8">
                            <span class="tile-percent">Total Agents</span>
						</div>
						<div class="col-md-2"></div>
                        <div class="col-md-2">
                            <span class="tile-percent-primary pr-2">{{this.dashData.length}}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-8">
                            <span class="tile-percent">Today Logged IN</span>
						</div>
						<div class="col-md-2"></div>
                        <div class="col-md-2">
                            <span class="tile-percent-primary pr-2">{{this.dashData.length - this.agentCallingStatus.remaining}}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-8">
							<span class="tile-percent">Agent Calling</span>
						</div>
						<div class="col-md-2">
							<span class="statusCircle" [ngStyle]="{'background-color':'#33EB1A', border: 'none' }"></span>
						</div>
                        <div class="col-md-2">
                            <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.calling}}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-8">
							<span class="tile-percent">Agent Waiting < 10mins</span>
						</div>
						<div class="col-md-2">
							<span class="statusCircle" [ngStyle]="{'background-color':'#FF8C00', border: 'none' }"></span>
						</div>
                        <div class="col-md-2">
                            <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.wait_l_10 }}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-8">
							<span class="tile-percent">Agent Waiting > 10mins</span>
						</div>
						<div class="col-md-2">
							<span class="statusCircle" [ngStyle]="{'background-color':'#87CEFA', border: 'none' }"></span>
						</div>
                        <div class="col-md-2">
                            <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.wait_g_10 }}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-8">
							<span class="tile-percent">Agent OnBreak</span>
						</div>
						<div class="col-md-2">
							<span class="statusCircle" [ngStyle]="{'background-color':'#FF0000', border: 'none' }"></span>
						</div>
                        <div class="col-md-2">
                            <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.on_break }}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-8">
							<span class="tile-percent">Agents Not Click Submit</span>
						</div>
						<div class="col-md-2">
							<span class="statusCircle" [ngStyle]="{'background-color':'#FF1493', border: 'none' }"></span>
						</div>
                        <div class="col-md-2">
                            <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.not_submit }}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-8">
							<span class="tile-percent">Agents Logged Out</span>
						</div>
						<div class="col-md-2">
							<span class="statusCircle" [ngStyle]="{'background-color':'#D3BA80', border: 'none' }"></span>
						</div>
                        <div class="col-md-2">
                            <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.log_out }}</span>
                        </div>
                    </div>
                    <!-- <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Agents Not Login</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.remaining }}</span>
                        </div>
                    </div> -->
                   
                    <!-- <div class="d-flex flex-row justify-content-between py-2">
                        <span>Total</span>
                        <span class="pr-2">{{ summary.contacted.total }}</span>
                    </div>
                    <div class="d-flex flex-row justify-content-between py-2">
                        <span>Unique</span>
                        <span class="pr-2">{{ summary.contacted.unique }}</span>
                    </div> -->
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Total Calls</mat-card-title>
                    <button mat-icon-button aria-label="Open in new tab" class="feedback_ext"
						matTooltip="Detailed Form Dispositions" matTooltipPosition="before"
						(click)="openFeedback()">
							<mat-icon>open_in_new</mat-icon>
						  </button>
				</mat-card-header>
				<mat-card-content>
					<div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Dialled</span><hr class="level1" [style.width.%]="this.dashSummary.calls_dialled_perc"/></span>
                        <span class="tile-text"><span class="tile-percent"></span>({{this.dashSummary.calls_dialled}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">RNR</span><hr class="level2" [style.width.%]="this.dashSummary.calls_rnr_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.dashSummary.calls_rnr_perc}}%</span>({{this.dashSummary.calls_rnr}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Contacted</span><hr class="level3" [style.width.%]="this.dashSummary.calls_contacted_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{this.dashSummary.calls_contacted_perc}}%</span>({{this.dashSummary.calls_contacted}})</span>
                    </div>
				</mat-card-content>
               <!--  <mat-card-content>
                    <div class="tile-row-pie" style="justify-content: center;">
                        <div style="height: 240px; width: 240px;" [chart]="agentStatusChart"></div>
                        <div>
                            <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #e40f59;"></span><span style="padding-left: 5px;"> <span style="color: #e40f59; font-weight: 500; font-size: 20px;">{{dashSummary.forms_filled}}</span> <p class="tile-text mb-1">Forms Filled</p> </span></div>
                            <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #1B7EC7;"></span><span style="padding-left: 5px;"> <span style="color: #08566e; font-weight: 500; font-size: 20px;">{{dashSummary.forms_not_filled}}</span> <p class="tile-text mb-1">No Activity</p> </span></div>
                           <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #1b7ec7;"></span><span style="padding-left: 5px;"> <span style="color: #1b7ec7; font-weight: 500; font-size: 20px;">{{this.agentStatusChartData.calling}}</span> <p class="tile-text mb-1">Call me back</p> </span></div>
                           

                        </div>
                    </div>  
                    <-- <div class="d-flex flex-row justify-content-between py-2">
                        <span>Submitted</span>
                        <span class="pr-2">{{ summary.forms.total }}</span>
                    </div> ->

                </mat-card-content> -->
            </mat-card>
        </div>

    </div>


    <div class="md-12 sm-12 xs-12">
        <mat-card class="mat-card-table-responsive call-summary" style="padding: 0px !important;">
        <table mat-table [dataSource]="dataSourceCallSummary" class="mat-elevation-z8">

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type </th>
              <td mat-cell *matCellDef="let element" class="CR" >{{element.type}} </td>
            </ng-container>
            
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef> Active Time </th>
              <td mat-cell *matCellDef="let element" class="CR" > {{element.time}} </td>
            </ng-container>
            
            <ng-container matColumnDef="spoken_time">
              <th mat-header-cell *matHeaderCellDef> Spoken Time </th>
              <td mat-cell *matCellDef="let element" class="CR" > {{element.spoken_time}} </td>
            </ng-container>
            
            <ng-container matColumnDef="wrap_time">
              <th mat-header-cell *matHeaderCellDef> Wrap Time </th>
              <td mat-cell *matCellDef="let element" class="CR" > {{element.wrap_time}} </td>
            </ng-container>
            
            <ng-container matColumnDef="wait_time">
                <th mat-header-cell *matHeaderCellDef> Wait Time </th>
                <td mat-cell *matCellDef="let element" class="CR" > {{element.wait_time}} </td>
              </ng-container>
            
            <ng-container matColumnDef="idle_time">
                <th mat-header-cell *matHeaderCellDef> Idle Time </th>
                <td mat-cell *matCellDef="let element" class="CR" > {{element.idle_time}} </td>
              </ng-container>
             
            
            <tr mat-header-row *matHeaderRowDef="displayedColumnsCallSummary; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCallSummary;" ></tr>
            </table>
        </mat-card>
    </div>
    <div class="md-12 sm-12 xs-12">
        
        <mat-card class="mat-card-table-responsive">
            <p class="textAlertMessage" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
            <div class="row pdt filter-div  align-items-center">
                <div class="col">
                    <!-- <span class="date-label">10-Jun-2020</span> -->
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn "
                            [ngClass]="(selectedTabSts == 'call_status') ? 'btn-secondary' : 'btn-outline-secondary' "
                            (click)="changeTogleStatus('call_status')">Call Status</button>
                        <button type="button" class="btn "
                            [ngClass]="(selectedTabSts == 'slot_status') ? 'btn-secondary' : 'btn-outline-secondary' "
                            (click)="changeTogleStatus('slot_status')">Slot Status</button>
                    </div>
                </div>
                <!-- <div class="col-md-3 col-sm-2 col-xs-12">
                   


                </div> -->

                <div class="col-md-7" *ngIf="selectedTabSts == 'call_status'">
                    <mat-form-field >
                        <input matInput [matDatepicker]="frompicker"  [max]="maxDate" placeholder="From" readonly
                            (click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)" [(ngModel)]="callStartDate">
                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                        <mat-datepicker #frompicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field  class="ml-3">
                        <input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)" [(ngModel)]="callEndDate">
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                    <button mat-button class="btnAdd ml-3" (click)="refreshData()">Go</button>
                    <div ngbDropdown class="d-inline-block ml-2">
                        <span class="table-tag px-2">Select Agent</span><button class="btn btn-outline-secondary" style="border-radius: 6px;" id="dropdownForm1" ngbDropdownToggle>{{selectAll ? 'All Selected' : selectedAgentsLen+' Agents'}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                          <div class="px-4 py-3" style="max-height: 300px;overflow-y: auto;">
                            <mat-checkbox (change)="updateCheck()"
                            class="example-margin"
                            [(ngModel)]="selectAll">
                          Select All
                        </mat-checkbox> 
                            <section *ngFor="let ing of selectedAgents; let i = index" class="example-section">
                                <mat-checkbox (change)="selectChildren()"
                                   [(ngModel)]="ing.checked">
                                  {{ing.name}}
                                </mat-checkbox>
                              </section>
                               
                          </div>
                        </div>
                      </div>
                    <!-- <section *ngFor="let ing of selectedAgents; let i = index" class="example-section">
                        <mat-checkbox (change)="selectChildren()"
                           [(ngModel)]="ing.checked">
                          {{ing.name}}
                        </mat-checkbox>
                      </section>
                        <mat-checkbox (change)="updateCheck()"
                            class="example-margin"
                            [(ngModel)]="selectAll">
                          Select All
                        </mat-checkbox> -->
                       
                </div>
                
                <div class="col-md-7" *ngIf="selectedTabSts == 'slot_status'">
                    <mat-form-field >
                        <input matInput [matDatepicker]="frompicker" placeholder="From" readonly
                            (click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [(ngModel)]="slotStartDate">
                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                        <mat-datepicker #frompicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field  class="ml-3">
                        <input #fromInput matInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [(ngModel)]="slotEndDate">
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                    <button mat-button class="btnAdd ml-3" (click)="refreshDataCallSlot()">Go</button>
                    <button mat-button class="btnAdd ml-3" (click)="addSlot()"><i class="fa fa-cog" aria-hidden="true"></i></button>
                </div>


                <div class="col" style="margin-left: auto">
                    <mat-form-field class="wdh">
                        <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" />
                        <i class="fa fa-search" matSuffix aria-hidden="true"></i>
                    </mat-form-field>
                </div>
            </div>
            <div class="row pdt filter-div  align-items-center">
                <div class="col-md-12 col-sm-12 col-xs-12 ">
                    <!-- <span style="font-size: 14px;color: #445c6d; font-weight: bold;">Export</span> -->
                    <!-- <mat-form-field [ngClass]="'inputClr'">
                        <mat-select placeholder="Export" (selectionChange)="downloadReport($event.value)" >
                            <mat-option value="call">Call Report</mat-option>
                            <mat-option value="audit">Audit Report</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    
                    <div ngbDropdown class="d-inline-block ml-2" *ngIf="selectedTabSts == 'call_status'">
                        <span style="font-size: 14px;color: #445c6d; font-weight: bold;cursor: pointer;"  id="dropdownBasic1" ngbDropdownToggle>Export</span>
                        <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                        <button ngbDropdownItem (click)="downloadReportGeneral()">Call Report</button>
                        <button ngbDropdownItem (click)="downloadAuditReport()">Audit Report</button>
                        </div>
                    </div>
                </div></div>
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                    *ngIf="selectedTabSts == 'call_status'" style="box-shadow: none !important;">
                    <ng-container matColumnDef="user_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Id </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.user_id }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Name </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Live Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span class="statusCircle"
                                [ngStyle]="{'background-color': getStatusColor(element), border: 'none' }"></span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="dialled">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Dialled </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.dialled }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="unique_dialled">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Unique Dialled </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.unique_dialled }}</mat-cell>
                    </ng-container>
                   
                    <ng-container matColumnDef="contacted">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Contacted </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.contacted }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="unique_contacted">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Unique Contacted </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.unique_contacted }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="rnr">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> RNR </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.rnr }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="forms">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Forms </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.forms }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="active_time">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Active Time </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ gethmsformat(element.active_time,'clock') }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="idle_time">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Idle Time </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ gethmsformat(element.idle_time,'clock') }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="wait_time">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Wait Time </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ gethmsformat(element.wait_time,'clock') }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="wrap_time">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Wrap Time </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ gethmsformat(element.wrap_time,'clock') }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="audit_score">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Audit score </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.audit_score }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ctc_score">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> CTC score </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ctc_score }}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef=displayedColumnsCallStatus></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsCallStatus"></mat-row>
                </table>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                    *ngIf="selectedTabSts == 'slot_status'" style="box-shadow: none !important;">
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.date }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="time">
                        <mat-header-cell *matHeaderCellDef> Slot Time </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.time }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="capacity">
                        <mat-header-cell *matHeaderCellDef> Capacity </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.capacity }}</mat-cell>
                        
                    </ng-container>
                    <ng-container matColumnDef="booked">
                        <mat-header-cell *matHeaderCellDef> Booked </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.booked }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="waiting">
                        <mat-header-cell *matHeaderCellDef> Waiting </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.waiting }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="occupancy">
                        <mat-header-cell *matHeaderCellDef> Occupancy </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.occupancy }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.status }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="id" >
                        <mat-header-cell *matHeaderCellDef [style.display]="'none'"> Id </mat-header-cell>
                        <mat-cell *matCellDef="let element" [style.display]="'none'">{{ element.id }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="options">
                        <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
                        <mat-cell *matCellDef="let element" (click)="editSlot(element)"><i class="fa fa-edit CR" style="font-size: 18px;color: #526778;"></i></mat-cell>
                       
                    </ng-container>

                    <mat-header-row *matHeaderRowDef=displayedColumnsCallSlot></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsCallSlot"></mat-row>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

        </mat-card>
        
    </div>

</div>
<div id="cover-spin" *ngIf="currentLoader"></div>