import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
	selector: 'app-campaign-details-add-agent-dialog',
	templateUrl: './campaign-details-add-agent-dialog.component.html',
	styleUrls: ['./campaign-details-add-agent-dialog.component.scss']
})
export class CampaignDetailsAddAgentDialogComponent implements OnInit {

	displayedColumns: string[] = ['select', 'agentID', 'agentName', 'email', 'currentCampaign'];
	dataSource = new MatTableDataSource();

	addagent: any;
	agents_list: string[] = [];
	selected_agent: any = [];
	group_id: string;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	activeDeactiveModel: any;
	campaign_details: any;
	api_end_point: any;
	user: any = this.myservice.get_loggedin_user();
	currentLoader: boolean = false

	constructor(private API: ApiService,
		private myservice: MyserviceService,
		private router: Router,
		private _snackBar: MatSnackBar, 
		public dialogRef: MatDialogRef<CampaignDetailsAddAgentDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewAddagentDetails) { }

	ngOnInit() {
		this.api_end_point = this.myservice.get_API_End_Point();
		this.campaign_details = this.viewAddagentDetails.campaign_details;
		// console.log(this.campaign_details);
		this.showagent();
		//.agents_list
		//console.log("----"+JSON.stringify(this.campaign_details));
	}

	applySearchFilter(val: string) {
		this.dataSource.filter = val.toLowerCase().trim()
	}
	closeDialog() {
		this.dialogRef.close();
	}
	cancelBtn() {
		this.dialogRef.close();
	}
	addAgentBtn() {
		//	console.log("--agent-list-"+JSON.stringify(this.campaign_details));

		//API here
		let obj = {
			"agents_list": [],
			"campaign_name": this.campaign_details.campaign_name,
			"priority": this.campaign_details.priority,
			"incallscreen": this.campaign_details.incallscreen
		}
		//console.log(JSON.stringify(obj));
		this.selected_agent.forEach(t => {
			if (t.email)
				obj.agents_list.push(t.email)
		})
		if (obj.agents_list.length) {
			//console.log(obj);
			this.currentLoader = true;
			this.API.addAgentstoCampaign(obj, this.api_end_point).subscribe((d: any) => {
				this.currentLoader = false;
				if (d.success) {
					//alert('Agent add successfully');
					this.dialogRef.close();
					this._snackBar.open('Agent add successfully', 'close',{ 
						duration: 2000
					});
					if(window.location.href.includes('central-campaign'))
					{
						this.router.navigate(['central-campaign'])
					}
					if(window.location.href.includes('campaignV2'))
					{
						this.router.navigate(['campaignV2'])
					}
				} else if (d.errors) {
					this.dialogRef.close();
					this._snackBar.open(d.errors, 'close');
					//alert(d.errors)
				} else {
					//alert('Unknown error');
					this.dialogRef.close();
					this._snackBar.open('Unknown error', 'close');
				}
			}, (err)=>{
				this.currentLoader = false;
				this.dialogRef.close();
				this._snackBar.open('Unknown error', 'close');
			})
		} else {
			this._snackBar.open('Select atlease one agent', 'close');
		}

	}

	//table details shown.
	showagent() {
		try{
			var datapushed = [];
			var agent_list = this.campaign_details.campaign_agent_list;
			const all_my_users = this.myservice.getAllIds('user');
			for(var i in all_my_users){
				if(!agent_list.hasOwnProperty(all_my_users[i]['email'])){
					all_my_users[i]['to_selected'] = false;
					datapushed.push(all_my_users[i])
				}
			}
			// console.log('agents list', datapushed)
			this.agents_list = datapushed;
			this.addingat_table();
		}catch(e){
			console.log('Exception', e);
			this._snackBar.open('Undefined Error', 'close');
		}
	}

	addingat_table() {
		this.dataSource = new MatTableDataSource(this.agents_list);
		this.dataSource.paginator = this.paginator;
	}
	adding_agent(element, toselect) {
		element.to_selected = false
		if (toselect.checked) {
			element.to_selected = true
			this.selected_agent.push(element);
		} else {
			for (var i = 0; i < this.selected_agent.length; i++) {
				if (this.selected_agent[i].email === element.email) {
					this.selected_agent.splice(i, 1);
				}
			}
		}
	}
}


