import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-new',
  templateUrl: './faq-new.component.html',
  styleUrls: ['./faq-new.component.scss']
})
export class FaqNewComponent implements OnInit {
  getTabList: any = [];
  getCompliance: any = [];
  getRecruitmentDashboard: any = [];
  getRecruitmentDashboard_FAQ: any = [];
  getRecruitmentDashboard_Recruiter: any = [];
  getTrainingModule: any = [];
  getTrainingActivityList: any = [];
  getTrainingModule_FAQ: any = [];
  getTrainingTroubleshooting: any = [];
  getMockCallActivities: any = [];
  getMockCall_FAQ: any = [];
  getOjtProductionDashboard: any = [];
  getProductionActivities: any = [];
  getOjtProduction_FAQ: any = [];
  getPaymentModule: any = [];
  getPaymentModule_FAQ: any = [];
  getActivityDashboard: any = [];
  getAgentSlot:any=[];

  getMockCall: any = [];
  selectedItem: any;
  selectedIndex: number = 0;

  showHideComliance: boolean = true;
  showHideRecruitmentDashboard: boolean = false;
  showHideTrainingModule: boolean = false;
  showHideMockCall: boolean = false;
  showHideOJTProduction: boolean = false;
  showHidePaymentModule: boolean = false;
  showHideAgentSlot: boolean = false;
  showHideActivityDashboard: boolean = false;
  searchInputText:any="";
  constructor() { }

  ngOnInit() {

    this.getTabData();
    this.getComplianceData();
    this.getRecruitmentDashboardData();
    this.getTrainingModuleData();
    this.getMockCallData();
    this.getOJTandProductionData();
    this.getPaymentModuleData();
    this.getActivityDashboardData();
    this.getAgentSlotData();
  }

  getTabData() {
    this.getTabList = [{ name: 'Compliance Document' }, { name: 'Recruitment Dashboard' }, { name: 'Training Dashboard' }, { name: 'Mock Call Dashboard' }, { name: 'Production & OJT Dashboard' }, { name: 'Payment Module' }, { name: 'Agent Slot' }, { name: 'How to do Activity' }];
  }
  getComplianceData() {
    this.getCompliance = [
      {
        name: 'Phone', instruction: [
          { desc: 'Required a Smartphone with the Android Version of 6.0V and Onwards.' },
          { desc: 'Internal Storage (ROM) 350 MB Onwards.' },
          { desc: 'RAM 1GB & Onwards.' },
          { desc: 'True caller and other tracking related applications should be disabled.' },
          { desc: 'The registered number should be in SIM SLOT-1.' },
          { desc: '(SIM #1 supports all features of the network (2G/3G/4G/data)' },
          { desc: 'IOS devices are not preferred to run the Uearn application.' }]
      },
      {
        name: 'Network', instruction: [
          { desc: 'Preferred network should be 4G(LTE/VoLTE).' },
          { desc: '(The VoLTE, service enables wireless operators to use the data network to transmit voice services in the same way they transmit data).' },
          { desc: 'Jio Network SIM connection is not advisable for calling purpose.There are a lot of call drops due to IPV6 protocol used in Jio Services.' }
        ]
      },
      {
        name: 'Sim Signal', instruction: [
          { desc: 'The 4G LTE Signal should be greater than -58 dBm. A value of -96 dBm indicates no signal.' },
          { desc: 'Preferred network should be 4G(LTE/VoLTE).' }
        ]
      }];
  }

  getRecruitmentDashboardData() {
    this.getRecruitmentDashboard = [
      {
        labelName: 'Hiring',
        record: [{
          name: 'Campaigns (Currently Not Active) https://uearn.ai/',
          description: '',
          instruction: [
            { desc: '*we can create the campaign' },
            { desc: '*We can check total number of campaigns running, active campaign & In-Active campaign.' }
          ]
        },
        {
          name: 'Candidates',
          description: '',
          instruction: [
            { desc: '*We can view the details of the candidates' },
            { desc: '*We can send the application link to the candidates.' },
            { desc: '*We can change the status of the candidates such as On Boarding, Training.' }
          ]
        },
        {
          name: 'Recruiters',
          description: '',
          instruction: [
            { desc: 'Recruiters Performance details' },
            { desc: 'Recruiters On calls status.' }
          ]
        },
        {
          name: 'Assessment',
          description: '',
          instruction: [
            { desc: 'It will list all uploaded assessment so that recruiter can assign as required.' },
            { desc: 'Recruiter can create new assessment & upload in the current list.' }
          ]
        }
        ]
      },
      {
        labelName: 'Onboarding',
        record: [{
          name: 'L1 status',
          description: '',
          instruction: [
            { desc: 'It will show overall candidate status for L1 selection' }
          ]
        },
        {
          name: 'L2 Status',
          description: '',
          instruction: [
            { desc: 'It will show overall candidate status for L2 selection' }
          ]
        },
        {
          name: 'Client interview status',
          description: '',
          instruction: [
            { desc: 'It will show overall client interview status' }
          ]
        },
        {
          name: 'Document status',
          description: '',
          instruction: [
            { desc: 'It will show Uploaded documents status of the candidates.' }
          ]
        }
        ]
      },
      {
        labelName: 'Manage Team',
        record: [{
          name: 'Manage',
          description: '',
          instruction: [
            { desc: 'We can add the recruiter for the hiring process.' },
            { desc: 'We can remove the recruiter for the hiring process.' }
          ]
        }
        ]
      },
      {
        labelName: 'Candidate Activity',
        record: [{
          name: 'Activity A 101',
          description: 'Candidate will click on the link (campaign advertisement). https://uearn.ai/register',
          instruction: [
            { desc: 'Fills the basic details as per the form.' },
            { desc: 'Candidates will take necessary action like (typing test & other test) & he will populate the score on the form.' },
            { desc: 'Candidate will upload the resume & submits the forms.' },
            { desc: 'Step 2 : He will go to the assessment screen.' },
            { desc: 'Step 3 : Candidate will do biometric authentication.' },
            { desc: 'Step 4 : Takes the assessment.' },
            { desc: 'Fills the basic details as per the form.Step 5 : Candidate will get the score based on his assessment & also the result (he will also get the notifications about the result).' },
          ]
        },
        {
          name: 'Activity B 102',
          description: '',
          instruction: [
            { desc: 'Candidate will get the link for the onboarding process' },
            { desc: 'Candidate should login to the app & upload all his relevant documents.' },
          ]
        }
        ]
      },
      {
        labelName: 'Recruiter Activity',
        record: [{
          name: 'Activity A 103',
          description: '',
          instruction: [
            { desc: 'Candidate details & assessment score will populate on dashboard' },
            { desc: 'Recruiter will call the candidates for interview (recruiter can login to Uearn app, eligible candidates phone number will be listed as follow-up)' },
            { desc: 'Recruiter will send the link for on boarding process for eligible candidates' }
          ]
        },
        {
          name: 'Activity B 104',
          description: '',
          instruction: [
            { desc: 'Recruiter verifies all the documents & gets SA signed & elevates to training program' }
          ]
        }
        ]
      }
    ];

    this.getRecruitmentDashboard_FAQ = [
      {
        labelName: 'Unable to create campaign',
        instruction: [
          { desc: 'Logout from the dashboard.' },
          { desc: 'Close all other Tabs.' },
          { desc: 'Clear cache and History' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Unable to download excel from campaign. ',
        instruction: [
          { desc: 'Logout from the dashboard.' },
          { desc: 'Ensure Campaign template used as per the guide line.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' }
        ]
      },
      {
        labelName: 'Unable to send the Uearn application link.',
        instruction: [
          { desc: 'Refresh the page.' },
          { desc: 'Logout from the Dashboard.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support' },
        ]
      },
      {
        labelName: 'Unable to upload new Assessment using excel sheet.',
        instruction: [
          { desc: 'Refresh the page.' },
          { desc: 'Ensure Assessment template used as per the guide line.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Unable to create assessment.',
        instruction: [
          { desc: 'Check whether you filled all the necessary field.' },
          { desc: 'Refresh the page.' },
          { desc: 'Check whether excel sheet is saved with proper extension.' },
          { desc: 'Logout from the Dashboard.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support' }
        ]
      },
      {
        labelName: 'Unable to view onboarding list.',
        instruction: [
          { desc: 'Ensure that you have selected the Proper Date.' },
          { desc: 'Close all other tabs.' },
          { desc: 'Refresh the page.' },
          { desc: 'Logout from the Dashboard.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support' },
        ]
      },
      {
        labelName: 'Unable to add profile in manage team.',
        instruction: [
          { desc: 'Ensure that you have filled all the necessary field.' },
          { desc: 'Clear cache and History.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Agent not able to upload document on Uearn app.',
        instruction: [
          { desc: 'Refresh the page.' },
          { desc: 'Logout from the Uearn app.' },
          { desc: 'Check the internet connection by using speedtest.net by Okla.' },
          { desc: 'Clear cache and History.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'Please find the recommended format (ex: pdf, word, jpeg or bng)' },
          { desc: 'If still issue persist contact Technical support' },
        ]
      },
      {
        labelName: 'Agent not able to login to Uearn app',
        instruction: [
          { desc: 'Enter the correct password.' },
          { desc: 'Try the password as registered phone number/12345678.' },
          { desc: 'Contact Tech support if you are unable to reset it.' },
        ]
      },
      {
        labelName: 'Uearn App is not responding (screen stuck at one page)',
        instruction: [
          { desc: 'Clear cache and History.' },
          { desc: 'Restart the phone.' },
          { desc: 'Check with storage space.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      }



    ];

    this.getRecruitmentDashboard_Recruiter = [
      {
        labelName: 'Unable to call candidates. ',
        instruction: [
          { desc: 'Ensure the data is available in the follow-up' },
          { desc: 'Should maintain minimum balance.' },
          { desc: 'Share the details with error message.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Incorrect dashboard entries.',
        instruction: [
          { desc: 'Close all the browsers.' },
          { desc: 'Re-enter the credentials.' },
          { desc: 'If still issue persist contact Technical support' }
        ]
      },
      {
        labelName: 'Unable to create recruiter profile in manage team. ',
        instruction: [
          { desc: 'Refresh the page.' },
          { desc: 'Logout from the Dashboard.' },
          { desc: 'Check the internet connection by using speedtest.net by Okla.' },
          { desc: 'Clear cache and History.' },
          { desc: 'Please use active email &phone numbers' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },

        ]
      }];

  }
  getTrainingModuleData() {
    this.getTrainingModule = [
      {
        labelName: 'Training',
        record: [{
          name: 'Dashboard',
          instruction: [
            { desc: 'We can check total number of active agents in Production, OJT Certification and Training. We can check the total number of batches in live and its overview (Active agents).' },
          ]
        },
        {
          name: 'Assessment',
          instruction: [
            { desc: 'We can view the details of the candidates.' },
            { desc: 'Trainer can upload/create the assessment.' },
          ]
        },
        {
          name: 'Agents',
          instruction: [
            { desc: 'We can view the score of an assessment.' },
            { desc: 'We can view the details of the candidates.' }
          ]
        },
        {
          name: 'Batch',
          instruction: [
            { desc: 'Trainer can create the new Batch.' },
            { desc: 'We can view the details of the ongoing Batches.' }
          ]
        },
        {
          name: 'Trainers',
          instruction: [
            { desc: 'We can view the details of all the Trainer.' },
            { desc: 'We can view the trainers who are available on particular date.' },
            { desc: 'We can add a new trainer to a team.' },
          ]
        },
        {
          name: 'OJT',
          instruction: [
            { desc: 'We can create a new OJT Batch.' },
            { desc: 'We can View the details of on job training agents.' },
          ]
        },
        {
          name: 'Certification',
          instruction: [
            { desc: ' We can certify or non-certify the candidates.' },
            { desc: 'We can export the details of the candidates.' },
            { desc: 'We can export the details of the candidates who are eligible for next process.' },
            { desc: 'We can move the candidate to mock calling process.' },
          ]
        },
        {
          name: 'Reports',
          instruction: [
            { desc: 'We can view the Report of the candidates.' },
            { desc: 'We can download the Report of the candidates.' },
          ]
        },
        {
          name: 'Calendar',
          instruction: [
            { desc: 'We can view schedule date and timings of Batch.' },
          ]
        },
        ]
      },
      {
        labelName: 'Manage Team',
        record: [{
          name: '',
          instruction: [
            { desc: 'We can add or remove the trainer.' },
          ]
        }]
      }
    ];





    this.getTrainingActivityList = [
      {
        labelName: 'Training Admin Activity',
        record: [{
          name: 'Activity A 201',
          description: '',
          instruction: [
            { desc: 'Training Admin can create batches, allot batches, schedule batches & monitor performance. ' },
          ]
        },
        {
          name: 'Activity B 202',
          description: '',
          instruction: [
            { desc: 'Training admin can add batches to mock call platform & facilitate call audit.' },
          ]
        },
        {
          name: 'Activity C 203',
          description: '',
          instruction: [
            { desc: 'Training admin can add batches to OJT call platform & facilitate call audit.' },
          ]
        }
        ]
      },
      {
        labelName: 'Trainees Activity',
        record: [{
          name: 'Activity A 204',
          description: '',
          instruction: [
            { desc: 'Trainees can take assessment can view feedback.' },
          ]
        },
        {
          name: 'Activity B 205',
          description: '',
          instruction: [
            { desc: 'Trainees can attend their schedule classes & also other required activities.' },
          ]
        }
        ]
      },
    ];

    this.getTrainingModule_FAQ = [
      {
        labelName: 'How to check Speed of an Internet?',
        instruction: [
          { desc: 'Open Google chrome.' },
          { desc: 'Search for speedtest.net by Okla.' },
          { desc: 'Click on Go to check the signal.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to check Mobile network signal strength?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Select SIM settings.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type' },
          { desc: 'Search for SIM status and click on it.' },
          { desc: 'If Signal is more than -70dBm then signal is Excellent and so on.' },
        ],
        imgPath: '../assets/web_images/rssiImg.png'
      },
      {
        labelName: 'How to check Wi-Fi signal strength?',
        instruction: [
          { desc: 'Ensure that Wi-fi is connected.' },
          { desc: 'Open Google chrome.' },
          { desc: 'Search for speedtest.net by Okla.' },
          { desc: 'Click on Go to check the signal.' },
          { desc: 'If your Wi-fi is not stable, Restart the router and mobile' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Logout from the application?',
        instruction: [
          { desc: 'Ensure to Click on STOP whenever you want to End the session.' },
          { desc: 'Click on the Option at the top left of the application screen.' },
          { desc: 'Select the Logout option and Click on Yes.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable 4G/LTE option?',
        instruction: [
          { desc: 'Click on settings.' },
          { desc: 'Select SIM settings.' },
          { desc: 'Select SIM slot1.' },
          { desc: 'Click on Preferred Network mode.' },
          { desc: 'Select LTE/4G/3G/2G mode to activate it.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable Auto answer?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Search for Auto answer option.' },
          { desc: 'Select the option and enable it.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Disable call waiting?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Search for Call waiting option.' },
          { desc: 'Select the option and disable it.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable permissions?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Go to App Notifications.' },
          { desc: 'Select Uearn application.' },
          { desc: 'Select app permissions option.' },
          { desc: 'Enable all the permissions one by one.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Clear Cache and Data in Uearn application?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Go to App Notifications.' },
          { desc: 'Select Uearn application.' },
          { desc: 'Click on Storage.' },
          { desc: 'Click on Clear Cache and Data.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Invalid password while login.',
        instruction: [
          { desc: 'Enter the correct password.' },
          { desc: 'Try the password as registered phone number/12345678.' },
          { desc: 'If the password isn’t working even after checking then click on the forgot password to reset it.' },
          { desc: 'Contact Tech support if you are unable to reset it.' },
        ],
        imgPath: ''
      },
      {

        labelName: 'Change of Number.',
        instruction: [],
        imgPath: '../assets/web_images/nameChange.png'
      },
      {
        labelName: 'Lack of voice clarity / Customer voice not audible post the call is connected',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type.' },
          { desc: 'Check the Signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Ensure the agent is not connected to mobile hotspot.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Call Drops',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Cross connection (Agent receives second call while he/she is already on a call with the customer).',
        instruction: [
          { desc: 'Ignore the second call.' },
          { desc: 'Logout from the application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Start Call option grayed out in Uearn Application.',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Connect Mobile data while operating Uearn application.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'User receives START VOICE TEST instead of START CALL.',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Click on Sign in option instead of register to avoid the Voice test.' },
          { desc: 'Enter Credentials and Continue with Login process.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Failed to login in Uearn app.',
        instruction: [
          { desc: 'Cross Check the User_ID and Password.' },
          { desc: 'Check the Signal strength of your SIM Card.' },
          { desc: 'Enter Credentials and Continue with Login process' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Call landing like normal dialer (Landing on phone).',
        instruction: [
          { desc: 'Answer the call and click on Uearn application to again receive the current page of Uearn.' },
          { desc: 'Check the Network strength once.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Disposition Screen/SUBMIT Page not appearing on Uearn app.',
        instruction: [
          { desc: 'End the session.' },
          { desc: 'Logout from the Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Application minimizes and goes to background when the call received manually.',
        instruction: [
          { desc: 'Answer the call and bring the application to foreground by selecting Uearn application icon.' },
          { desc: 'If the application behaves the same way then Logout and clear cache and data.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Agent not able to Logout from Uearn application.',
        instruction: [
          { desc: 'Click on Home button on your mobile screen without Logout.' },
          { desc: 'Clear all the background application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Calls are Going has RNR.',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If the issue still persists then enable the flight mode and disable after few mins to get the Network stabilized. ' },
        ],
        imgPath: ''
      },

    ];
    this.getTrainingTroubleshooting = [
      {
        labelName: 'Unable to select a client(Active Process).',
        instruction: [
          { desc: 'Refresh the page.' },
          { desc: 'Logout from the dashboard.' },
          { desc: 'Close all other Tabs.' },
          { desc: 'Clear cache and History.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Unable to create assessment.',
        instruction: [
          { desc: 'Check whether you filled all the necessary field.' },
          { desc: 'Refresh the page.' },
          { desc: 'Check whether excel sheet is saved with proper extension.' },
          { desc: 'Logout from the Dashboard.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'They should use recommended template for creating new assessment.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Unable to view the agent details.',
        instruction: [
          { desc: 'Refresh the page.' },
          { desc: 'Logout from the dashboard.' },
          { desc: 'Clear cache and History.' },
          { desc: 'Follow the Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: ' Unable to create new Batch.',
        instruction: [
          { desc: 'Refresh the page.' },
          { desc: 'Logout from the Dashboard.' },
          { desc: 'Verify the agent status on recruitment dashboard, should be In training.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Unable to create new trainer.',
        instruction: [
          { desc: 'Ensure that you have filled all the necessary field.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Unable to view the available trainer List.',
        instruction: [
          { desc: 'Ensure that Trainer is added to the List or not.' },
          { desc: 'If details are not added, add the trainer and check again.' },
          { desc: 'If still issue persist contact Technical support.' },

        ]
      },
      {
        labelName: 'Unable to view the details of candidates in certification.',
        instruction: [
          { desc: 'Ensure that you have selected the Batch ID.' },
          { desc: 'Ensure that candidate completed the assessment and submitted it.' },
          { desc: 'If still issue persist contact Technical support.' },

        ]
      },
      {
        labelName: 'Agent not able to upload document on Uearn app.',
        instruction: [
          { desc: 'Refresh the page.' },
          { desc: 'Logout from the Uearn app.' },
          { desc: 'Check the internet connection by using speedtest.net by Okla.' },
          { desc: 'Clear cache and History.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'Please find the recommended format (ex: pdf)' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Agent not able to login to Uearn app',
        instruction: [
          { desc: 'Enter the correct password.' },
          { desc: 'Try the password as registered phone number.' },
          { desc: 'Contact Tech support if you are unable to reset it.' },

        ]
      },
      {
        labelName: 'Uearn App is not responding (screen stuck at one page)',
        instruction: [
          { desc: 'Clear cache and History.' },
          { desc: 'Restart the phone.' },
          { desc: 'Check with storage space.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Error: Invalid password while login',
        instruction: [
          { desc: 'Input the correct password (Please check caps lock)' },
          { desc: 'Try the password as registered Phone Number.' },
          { desc: 'If the password isn’t working then, Request for Password Reset (Smarterbiz support).' },
        ]
      },
      {
        labelName: 'Error: Lack of voice clarity / Customer voice not audible post the call is connected',
        instruction: [
          { desc: 'Logout from Uearn app.' },
          { desc: 'Clear cache & data for Uearn app from the mobile setting.' },
          { desc: 'Check the SIM signal strength and the internet bandwidth.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Login again on Uearn app' },
          { desc: 'Ensure the agent is connected to WIFI or mobile hotspot which has at least 1Mbps Speed.' },
          { desc: 'Registered SIM should be in SIM Slot 1 also ensure to activate the 3G mode on preferred network type.' },

        ]
      },
      {
        labelName: 'Error: Call Drops',
        instruction: [
          { desc: 'Logout from Uearn and Clear the cache for Uearn.' },
          { desc: 'Check the SIM signal strength and the Internet bandwidth.' },
          { desc: 'Login again on Uearn application.' },
          { desc: 'If the issue still persists then enable the flight mode and disable after few mins to get the Network stabilized.' },
          { desc: 'Ensure the agent is connected to WIFI or mobile hotspot which has atleast 1Mbps Speed.' },
          { desc: 'Registered SIM should be in SIM Slot 1 also ensure to activate the 3G mode on preferred network type.' },
        ]
      },
      {
        labelName: 'Error: Cross connection (Agent receives second call while he/she is already on a call with the customer)',
        instruction: [
          { desc: 'Ignore the second call.' },
          { desc: 'Logout from the app' },
          { desc: 'Follow the login process to login again.' },

        ]
      },
      {
        labelName: 'Start Call option grayed out in Uearn App?',
        instruction: [
          { desc: 'Connect Mobile data while operating Uearn app.' },
          { desc: 'Logout from the app.' },
          { desc: 'Clear cache & data for Uearn app from the mobile setting.' },
          { desc: 'Follow the login process to login again.' },

        ]
      },
      {
        labelName: 'Error: User receives START VOICE TEST instead of START CALL.',
        instruction: [
          { desc: 'While login into Uearn app go to sign in option not register.' },
          { desc: 'Contact Smarterbiz Tech Support.' },
        ]
      },
      {
        labelName: 'Error: Call landing like normal dialer (Landing on phone).',
        instruction: [
          { desc: 'Answer the call and click on Uearn application to again receive the current page of Uearn.' },
          { desc: 'Check the mobile network strength once' },

        ]
      }
    ];
  }

  getMockCallData() {
    this.getMockCall = [
      {
        labelName: 'Manage Team',
        record: [{
          name: 'PM Mock Test ',
          instruction: [
            { desc: 'We can add or remove the Team lead.' },
          ]
        }]
      },
      {
        labelName: 'Agent Dashboard',
        record: [{
          name: 'Total Calls',
          instruction: [
            { desc: 'We can view the total number of dialled calls.' },
            { desc: 'We can view the total percentage of RNR calls.' },
            { desc: 'We can view the total percentage of contacted calls.' },
          ]
        },
        {
          name: 'Follow ups',
          instruction: [
            { desc: 'We can view the percentage of completed Follow ups.' },
            { desc: 'We can view the percentage of pending follow ups.' },
          ]
        },

        {
          name: 'Agent Status',
          instruction: [
            { desc: 'We can view the detail of Total agents, Today logged in agents, Agents Logged out, Agent active today, Agent on call and Agent on Break.' },
          ]
        },
        {
          name: 'Agents on Break',
          instruction: [
            { desc: 'We can view the Break status of agent like Bio Break, Lunch Break, Coffee Break, Team Briefing, End of the Day Work.' },
          ]
        }, {
          name: 'Call Status',
          instruction: [
            { desc: 'We can view the Login hours, talk time, wrap time, Dial time, Idle time, Break time and Unavailable time of an agents and its average of 6 agent’s details.' },
          ]
        },
        {
          name: ' Reports:',
          instruction: [
            { desc: 'We can Download or export the Reports of Call, Audit, Agent summary and agent activity with respect to date and agent name.' },

          ]
        },

        ]
      },

    ];
    this.getMockCallActivities = [
      {
        labelName: 'Trainer Activity',
        instruction: [
          { desc: 'Trainers can upload batches to mock call activity & facilitate call audit.' },
          { desc: 'Training Admin Activity.' },
          { desc: 'Add batches into mock platform & also Add/Remove agents from mock call platform.' },
          { desc: 'Can view agent performance & their audit score, pm-mock does have special features like call merge & call barge.' },
        ]
      },
      {
        labelName: 'Candidate Activity',
        instruction: [
          { desc: 'Agents can login to the Uearn app with their credentials & they can start out-bound calling they can view their audit score on  their Uearn app dashboard.' },
        ]
      },
    ];
    this.getMockCall_FAQ = [
      {
        labelName: 'How to check Speed of an Internet?',
        instruction: [
          { desc: 'Open Google chrome.' },
          { desc: 'Search for speedtest.net by Okla.' },
          { desc: 'Click on Go to check the signal.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to check Mobile network signal strength?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Select SIM settings.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type' },
          { desc: 'Search for SIM status and click on it.' },
          { desc: 'If Signal is more than -70dBm then signal is Excellent and so on.' },
        ],
        imgPath: '../assets/web_images/rssiImg.png'
      },
      {
        labelName: 'How to check Wi-Fi signal strength?',
        instruction: [
          { desc: 'Ensure that Wi-fi is connected.' },
          { desc: 'Open Google chrome.' },
          { desc: 'Search for speedtest.net by Okla.' },
          { desc: 'Click on Go to check the signal.' },
          { desc: 'If your Wi-fi is not stable, Restart the router and mobile' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Logout from the application?',
        instruction: [
          { desc: 'Ensure to Click on STOP whenever you want to End the session.' },
          { desc: 'Click on the Option at the top left of the application screen.' },
          { desc: 'Select the Logout option and Click on Yes.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable 4G/LTE option?',
        instruction: [
          { desc: 'Click on settings.' },
          { desc: 'Select SIM settings.' },
          { desc: 'Select SIM slot1.' },
          { desc: 'Click on Preferred Network mode.' },
          { desc: 'Select LTE/4G/3G/2G mode to activate it.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable Auto answer?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Search for Auto answer option.' },
          { desc: 'Select the option and enable it.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Disable call waiting?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Search for Call waiting option.' },
          { desc: 'Select the option and disable it.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable permissions?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Go to App Notifications.' },
          { desc: 'Select Uearn application.' },
          { desc: 'Select app permissions option.' },
          { desc: 'Enable all the permissions one by one.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Clear Cache and Data in Uearn application?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Go to App Notifications.' },
          { desc: 'Select Uearn application.' },
          { desc: 'Click on Storage.' },
          { desc: 'Click on Clear Cache and Data.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Invalid password while login.',
        instruction: [
          { desc: 'Enter the correct password.' },
          { desc: 'Try the password as registered phone number/12345678.' },
          { desc: 'If the password isn’t working even after checking then click on the forgot password to reset it.' },
          { desc: 'Contact Tech support if you are unable to reset it.' },
        ],
        imgPath: ''
      },
      {

        labelName: 'Change of Number.',
        instruction: [{ desc: 'Please provide below details' },],
        imgPath: '../assets/web_images/nameChange.png'
      },
      {
        labelName: 'Lack of voice clarity / Customer voice not audible post the call is connected',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type.' },
          { desc: 'Check the Signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Ensure the agent is not connected to mobile hotspot.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Call Drops',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Cross connection (Agent receives second call while he/she is already on a call with the customer).',
        instruction: [
          { desc: 'Ignore the second call.' },
          { desc: 'Logout from the application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Start Call option grayed out in Uearn Application.',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Connect Mobile data while operating Uearn application.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'User receives START VOICE TEST instead of START CALL.',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Click on Sign in option instead of register to avoid the Voice test.' },
          { desc: 'Enter Credentials and Continue with Login process.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Failed to login in Uearn app.',
        instruction: [
          { desc: 'Cross Check the User_ID and Password.' },
          { desc: 'Check the Signal strength of your SIM Card.' },
          { desc: 'Enter Credentials and Continue with Login process' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Call landing like normal dialer (Landing on phone).',
        instruction: [
          { desc: 'Answer the call and click on Uearn application to again receive the current page of Uearn.' },
          { desc: 'Check the Network strength once.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Disposition Screen/SUBMIT Page not appearing on Uearn app.',
        instruction: [
          { desc: 'End the session.' },
          { desc: 'Logout from the Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Application minimizes and goes to background when the call received manually.',
        instruction: [
          { desc: 'Answer the call and bring the application to foreground by selecting Uearn application icon.' },
          { desc: 'If the application behaves the same way then Logout and clear cache and data.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Agent not able to Logout from Uearn application.',
        instruction: [
          { desc: 'Click on Home button on your mobile screen without Logout.' },
          { desc: 'Clear all the background application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Calls are Going has RNR.',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If the issue still persists then enable the flight mode and disable after few mins to get the Network stabilized. ' },
        ],
        imgPath: ''
      },
    ];
  }
  getOJTandProductionData() {
    this.getOjtProductionDashboard = [
      {
        labelName: 'Manage Team',
        record: [{
          name: '',
          instruction: [
            { desc: 'We can add or remove the agents and Team Lead' }
          ]
        }]
      },
      {
        labelName: 'Dashboard',
        record: [{
          name: 'Channel’s summary',
          instruction: [
            { desc: 'We can view the Percentage/Total Inbound calls and Outbound calls. ' }
          ]
        },
        {
          name: 'Follow ups',
          instruction: [
            { desc: 'We can view the percentage of Pending, Completed and Total Follow ups.' }
          ]
        },
        {
          name: 'Agent Status',
          instruction: [
            { desc: 'We can view the detail of Total agents, Today logged in agents, Agents Logged out, Agent active today, Agent on call and Agent on Break' }
          ]
        },
        {
          name: 'Customer Calls',
          instruction: [
            { desc: 'We can view the total number of calls placed, RNR and connected Calls.' }
          ]
        },
        {
          name: 'Call Status',
          instruction: [
            { desc: 'We can view the Login hours, talk time, wrap time, Dial time, Idle time, Break time and Unavailable time of an agents and its average of 5 agent’s details' }
          ]
        },
        {
          name: 'Reports',
          instruction: [
            { desc: 'We can Download or export the Reports of Call, Audit, Agent summary and agent activity with respect to date and agent name.' }
          ]
        }
        ]
      },
      {
        labelName: 'Campaigns',
        record: [{
          name: 'Campaigns',
          instruction: [
            { desc: 'We can view the total percentage of Data assigned, Data Dialled and Data Contacted.' },
          ]
        },
        {
          name: 'Total active campaigns',
          instruction: [
            { desc: 'We can view the total percentage of Data assigned, Data Dialled and Data Contacted.' },
          ]
        },
        {
          name: 'Total Inactive campaigns',
          instruction: [
            { desc: 'We can view the total percentage of Data assigned, Data Dialled and Data Contacted. ' },
          ]
        },
        {
          name: 'Channels',
          instruction: [
            { desc: 'We can view the total percentage of different channels like Email, SMS, WhatsApp, Voice bot, Agent.' },
          ]
        },
        {
          name: 'Create Campaign',
          instruction: [
            { desc: 'We can add the agents to take calls.' },
            { desc: 'We can add a customer numbers in a excel template.' },
          ]
        }
        ]
      },
    ];
    this.getProductionActivities = [
      {
        labelName: 'Agent Activity',
        record: [{
          name: 'Activity A 301',
          description: 'Agent will click on the link: https://uearn.ai/',
          instruction: [
            { desc: 'Agents can login to Uearn app & start picking sequential calls.' },
            { desc: 'Agents will also get notification about campaign status.' },
            { desc: 'Agent can transfer & merge call for further TL intervention.' }
          ]
        }]
      },
      {
        labelName: 'Pm-Activity',
        record: [{
          name: 'Activity A 402 ',
          description: '',
          instruction: [
            { desc: 'We can upload fresh campaign, allot agents for campaign, rechurn the campaign data & monitor agent performance & also campaign status.' },
          ]
        }]
      },
      {
        labelName: 'TL-Activity',
        record: [{
          name: 'Activity A 403',
          description: '',
          instruction: [
            { desc: 'TL can monitor agent performance, we can burgee & whisper calls & give real time feedback for candidate. Call merge & transfer features are available TL intervention. ' },
          ]
        }]
      },
      {
        labelName: 'Advance feature list',
        record: [{
          name: 'Call Transfer',
          description: '',
          instruction: [
            { desc: 'Team Leaders are able to transfer ongoing calls between agent-agent, agent-TL, TL-agent' },
          ]
        },
        {
          name: 'Call merge',
          description: '',
          instruction: [
            { desc: 'Allows the team leader to connect himself or several people together on a 3-way call with the agent and the customer.' },
          ]
        },
        {
          name: 'Call Barge',
          description: '',
          instruction: [
            { desc: 'Team Leaders are able to step in during on-going customer-agent calls to save the day. As a TL, you will now be able to control the outcome of every conversation, if and when it starts going downhill.' },
          ]
        },
        {
          name: 'Call Whisper',
          description: '',
          instruction: [
            { desc: 'Without making his presence known, the Team leader can speak with the agent, while the agent is on-call with a customer. The customer does not hear what the TL says to the agent during the call.' },
          ]
        },
        ]
      },
      {
        labelName: 'Audit Module',
        record: [{
          name: 'Activity A 301',
          description: '',
          instruction: [
            { desc: 'Availability of all calls’ recordings for audit purpose.' },
            { desc: 'Real-time audit score published to the agent and dashboard.' },
            { desc: 'Scoring mechanism of Audit parameters.' },
            { desc: 'We can also upload latest Audit form and download consolidated monthly report.' },


          ]
        }]
      },
    ];
    this.getOjtProduction_FAQ = [

      {
        labelName: 'Unable to create campaign',
        instruction: [
          { desc: 'Logout from the dashboard.' },
          { desc: 'Close all other Tabs.' },
          { desc: 'Clear cache and History' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Unable to download excel from campaign. ',
        instruction: [
          { desc: 'Logout from the dashboard.' },
          { desc: 'Ensure Campaign template used as per the guide line.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' }
        ],
        imgPath: ''
      },
      {
        labelName: 'Unable to add profile in manage team.',
        instruction: [
          { desc: 'Ensure that you have filled all the necessary field.' },
          { desc: 'Clear cache and History.' },
          { desc: 'If still issue persist contact Technical support.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Agent not able to login to Uearn app',
        instruction: [
          { desc: 'Enter the correct password.' },
          { desc: 'Try the password as registered phone number/12345678.' },
          { desc: 'Contact Tech support if you are unable to reset it.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Uearn App is not responding (screen stuck at one page)',
        instruction: [
          { desc: 'Clear cache and History.' },
          { desc: 'Restart the phone.' },
          { desc: 'Check with storage space.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to check Speed of an Internet?',
        instruction: [
          { desc: 'Open Google chrome.' },
          { desc: 'Search for speedtest.net by Okla.' },
          { desc: 'Click on Go to check the signal.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to check Mobile network signal strength?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Select SIM settings.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type' },
          { desc: 'Search for SIM status and click on it.' },
          { desc: 'If Signal is more than -70dBm then signal is Excellent and so on.' },
        ],
        imgPath: '../assets/web_images/rssiImg.png'
      },
      {
        labelName: 'How to check Wi-Fi signal strength?',
        instruction: [
          { desc: 'Ensure that Wi-fi is connected.' },
          { desc: 'Open Google chrome.' },
          { desc: 'Search for speedtest.net by Okla.' },
          { desc: 'Click on Go to check the signal.' },
          { desc: 'If your Wi-fi is not stable, Restart the router and mobile' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Logout from the application?',
        instruction: [
          { desc: 'Ensure to Click on STOP whenever you want to End the session.' },
          { desc: 'Click on the Option at the top left of the application screen.' },
          { desc: 'Select the Logout option and Click on Yes.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable 4G/LTE option?',
        instruction: [
          { desc: 'Click on settings.' },
          { desc: 'Select SIM settings.' },
          { desc: 'Select SIM slot1.' },
          { desc: 'Click on Preferred Network mode.' },
          { desc: 'Select LTE/4G/3G/2G mode to activate it.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable Auto answer?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Search for Auto answer option.' },
          { desc: 'Select the option and enable it.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Disable call waiting?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Search for Call waiting option.' },
          { desc: 'Select the option and disable it.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Enable permissions?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Go to App Notifications.' },
          { desc: 'Select Uearn application.' },
          { desc: 'Select app permissions option.' },
          { desc: 'Enable all the permissions one by one.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'How to Clear Cache and Data in Uearn application?',
        instruction: [
          { desc: 'Click on Settings.' },
          { desc: 'Go to App Notifications.' },
          { desc: 'Select Uearn application.' },
          { desc: 'Click on Storage.' },
          { desc: 'Click on Clear Cache and Data.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Invalid password while login.',
        instruction: [
          { desc: 'Enter the correct password.' },
          { desc: 'Try the password as registered phone number/12345678.' },
          { desc: 'If the password isn’t working even after checking then click on the forgot password to reset it.' },
          { desc: 'Contact Tech support if you are unable to reset it.' },
        ],
        imgPath: ''
      },
      {

        labelName: 'Change of Number.',
        instruction: [],
        imgPath: '../assets/web_images/nameChange.png'
      },
      {
        labelName: 'Lack of voice clarity / Customer voice not audible post the call is connected',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type.' },
          { desc: 'Check the Signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Ensure the agent is not connected to mobile hotspot.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Call Drops',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Cross connection (Agent receives second call while he/she is already on a call with the customer).',
        instruction: [
          { desc: 'Ignore the second call.' },
          { desc: 'Logout from the application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Start Call option grayed out in Uearn Application.',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Connect Mobile data while operating Uearn application.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Failed to login in Uearn app.',
        instruction: [
          { desc: 'Cross Check the User_ID and Password.' },
          { desc: 'Check the Signal strength of your SIM Card.' },
          { desc: 'Enter Credentials and Continue with Login process' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Call landing like normal dialer (Landing on phone).',
        instruction: [
          { desc: 'Answer the call and click on Uearn application to again receive the current page of Uearn.' },
          { desc: 'Check the Network strength once.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Disposition Screen/SUBMIT Page not appearing on Uearn app.',
        instruction: [
          { desc: 'End the session.' },
          { desc: 'Logout from the Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Application minimizes and goes to background when the call received manually.',
        instruction: [
          { desc: 'Answer the call and bring the application to foreground by selecting Uearn application icon.' },
          { desc: 'If the application behaves the same way then Logout and clear cache and data.' },
          { desc: 'Continue with Login Process using credentials' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Agent not able to Logout from Uearn application.',
        instruction: [
          { desc: 'Click on Home button on your mobile screen without Logout.' },
          { desc: 'Clear all the background application.' },
          { desc: 'Clear Cache and Data.' },
          { desc: 'Continue with Login Process using credentials.' },
        ],
        imgPath: ''
      },
      {
        labelName: 'Calls are Going has RNR.',
        instruction: [
          { desc: 'Logout from Uearn application.' },
          { desc: 'Close all other application.' },
          { desc: 'Clear cache and Data for Uearn application.' },
          { desc: 'Check the signal strength of your SIM Card.' },
          { desc: 'Ensure that Registered SIM should be in SIM Slot 1 and also ensure to activate the LTE/4G mode on preferred network type.' },
          { desc: 'If the mobile network is not stable then restart the mobile phone.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If the issue still persists then enable the flight mode and disable after few mins to get the Network stabilized. ' },
        ],
        imgPath: ''
      },

    ];
  }
  getPaymentModuleData() {
    this.getPaymentModule = [
      {
        labelName: 'Dashboard',
        description: 'Dashboard shows the complete list of GIG workers, their Gross Earnings/ Tax Deductions/ Opening Balance/ Net Earnings/ Closing Balance (a) As per the configure logic (b) As per data upload (Event/Sales).',
        instruction: [],
        imgPath: '../assets/web_images/paymentDashboard.PNG'
      },
      {
        labelName: 'Configure',
        description: 'Managers can configure Payment criteria as per Minute/Login Hours/Day/Call/Event (sales) which are based on GIG worker performance',
        instruction: [
          {
            desc: '(a) can configure the payment logic at the start date of payment cycle and this is automatically with draw the numbers from the existing production dashboard and it will shows the calculation Gross earning/ tax deduction/ Net earnings',
            imgPath: '../assets/web_images/paymentConfigure.jpg'
          },
          {
            desc: '(b) can configure the payment logic by uploading the data (sales or event data) at the end of the payment cycle and this will automatically shows the calculation Gross earning/ tax deduction/ Net earnings on dashboard.',
            imgPath: '../assets/web_images/paymentEvents.jpg'
          }
        ],
        imgPath: ''
      },
      {
        labelName: 'History',
        description: 'History pages shows the historic details about previous configuration',
        instruction: [],
        imgPath: '../assets/web_images/paymentHistory.jpg'
      },
      {
        labelName: 'Approval',
        description: 'Dashboard would show the Calculated payment for all GIG Workers, Same details will show on the Approval page. On Approval page, Manager will click on ',
        instruction: [
          {
            desc: 'Step (1) APPROVE ALL – All GIG workers will get notification on their app, Approval status will be In-process from Approve. ',
            imgPath: ''
          },
          {
            desc: 'Step – if GIG workers click on Approve on APP then Approval status(dashboard) will be changed to Approved. ',
            imgPath: ''
          },
          {
            desc: 'Step – if GIG workers click on Clarify for further negotiation the on APP then he can click on CHAT (4) then further chat with Manager and can get more clarification and after that he can choose Approve on the APP. ',
            imgPath: ''
          },
          {
            desc: 'Step- Manager can Download the excel file (5) then send the details to Account/Payroll department. ',
            imgPath: ''
          },
          {
            desc: 'GIG Worker will get notification of payment details which will be credited to his account.',
            imgPath: '../assets/web_images/approval2.PNG'
          },
          {
            desc: 'Passbook view – will shows all the transaction and its details message. ',
            imgPath: '../assets/web_images/approvalOne.jpg'
          }
        ],
        imgPath: '../assets/web_images/approval1.jpg'
      },
    ];
    this.getPaymentModule_FAQ = [
      {
        labelName: 'Agent not able to login to Uearn app',
        instruction: [
          { desc: 'Enter the correct password.' },
          { desc: 'Try the password as registered phone number/12345678.' },
          { desc: 'Contact Tech support if you are unable to reset it.' },
        ]
      },
      {
        labelName: 'Uearn App is not responding (screen stuck at one page)',
        instruction: [
          { desc: 'Clear cache and History.' },
          { desc: 'Restart the phone.' },
          { desc: 'Check with storage space.' },
          { desc: 'Continue with Login Process using credentials.' },
          { desc: 'If still issue persist contact Technical support.' },
        ]
      },
      {
        labelName: 'Unable to create configuration on dashboard?',
        instruction: [
          { desc: 'All Issue related to Payment Module on dashboard should be routed to 1st level Escalation.' },
        ]
      },
      {
        labelName: '4. Unable to create Slot on dashboard?',
        instruction: [
          { desc: 'All Issue related to Time Slot on dashboard should be routed to 1st level Escalation.' },
        ]
      }
    ];
  }
  getActivityDashboardData() {
    this.getActivityDashboard = [
      {
        labelName: 'Recruitment Dashboard',
        record: [{
          name: 'How to create assessment?',
          instruction: [
            { desc: 'Login to Recruitment dashboard using credentials.' },
            { desc: 'Click on assessment tab under Hiring.' },
            { desc: 'Click on Create assessment.' },
            { desc: 'Fill all the necessary field.' },
            { desc: 'Download excel template and open it.' },
            { desc: 'Create question and answer in the respective field and save it.' },
            { desc: 'Click on the browse button and open the saved excel template.' },
            { desc: 'Finally click on the submit button.' },
          ]
        },
        {

          name: 'How to create Campaign?',
          instruction: [
            { desc: 'Login to Recruitment dashboard using credentials.' },
            { desc: 'Click on campaign tab under Hiring.' },
            { desc: 'Click on Create campaign.' },
            { desc: 'Fill all the necessary field.' },
            { desc: 'Download excel template and open it.' },
            { desc: 'Fill the necessary field required and save it.' },
            { desc: 'Click on the browse button and open the saved excel template.' },
            { desc: 'Finally click on the upload campaign button.' },
          ]
        },
        {
          name: 'How to add Recruiter?',
          instruction: [
            { desc: 'Login to Recruitment dashboard using credentials.' },
            { desc: 'Click on Manage team tab.' },
            { desc: 'Select Team Lead.' },
            { desc: 'Click on add profile from the right.' },
            { desc: 'Fill all the necessary field and save it.' },
          ]
        },
        {
          name: 'How to Download Candidate Report?',
          instruction: [
            { desc: 'Login to Recruitment dashboard using credentials.' },
            { desc: 'Click on candidate tab under Hiring.' },
            { desc: 'Click on Download Report button to get it Download (All agents Information will get download).' },
          ]
        },
        {
          name: 'How to Download Candidate Report using filters?',
          instruction: [
            { desc: 'Login to Recruitment dashboard using credentials.' },
            { desc: 'Click on Candidate tab under Hiring.' },
            { desc: 'Select particular agency, Level and source from the candidate list which you have to download.' },
            { desc: 'To download individual agent report, click on Search option and type their name.' },
            { desc: 'Click on Download Report button to get it download (Selected agents Information will get download).' },
          ]
        },
        {

          name: 'How to send Uearn application to selected candidates?',
          instruction: [
            { desc: 'Login to Recruitment dashboard using credentials.' },
            { desc: 'Click on Candidate tab under Hiring.' },
            { desc: 'Select particular agency, Level and source from the candidate list for whom you have to send Uearn application link.' },
            { desc: 'To send Link individually, click on search option and type their name.' },
            { desc: 'Click on send link button.' },
          ]
        },
        {
          name: 'How to check onboarding candidate list on based on particular date?',
          instruction: [
            { desc: 'Login to Recruitment dashboard using credentials.' },
            { desc: 'Click on Onboarding tab.' },
            { desc: 'Select the particular date in onboarding list.' },
            { desc: 'Click on Button Go to get details.' },
            { desc: 'Can Export and Print the details by click on the Export/Print button in the onboarding List.' },
          ]
        },

        ]
      },
      {

        labelName: 'Training Dashboard',
        record: [{
          name: 'How to add new trainers?',
          instruction: [
            { desc: 'Login to Training dashboard using credentials.' },
            { desc: 'Click on Trainers button.' },
            { desc: 'Click on Add new Trainer button.' },
            { desc: 'Click on Add trainer button.' },
            { desc: 'Fill all the necessary field and save it.' },
          ]
        },
        {
          name: 'How to create OJT batch?',
          instruction: [
            { desc: 'Login to Training dashboard using credentials.' },
            { desc: 'Click on OJT Button.' },
            { desc: 'Click on OJT Batch.' },
            { desc: 'Click on create New OJT Batch.' },
            { desc: 'Fill all the necessary field and save it.' },
          ]
        },
        {
          name: 'How to create Training batch?',
          instruction: [
            { desc: 'Login to Training dashboard using credentials.' },
            { desc: 'Click on Batch Button.' },
            { desc: 'Click on create New Batch.' },
            { desc: 'Fill all the necessary field and save it.' },
          ]
        },
        {
          name: 'How to create training assessment?',
          instruction: [
            { desc: 'Login to Training dashboard using credentials.' },
            { desc: 'Click on Assessment Button.' },
            { desc: 'Click on upload assessment.' },
            { desc: 'Fill all the necessary field.' },
            { desc: 'Download excel template and open it.' },
            { desc: 'Create question and answer in the respective field and save it.' },
            { desc: 'Click on the browse button and open the saved excel template.' },
            { desc: 'Finally click on the submit button.' },
          ]
        },
        {
          name: 'How to Certify/Non-Certify the agent documents?',
          instruction: [
            { desc: 'Login to Training dashboard using credentials.' },
            { desc: 'Click on Certification button.' },
            { desc: 'Select Batch in the Drop Down.' },
            { desc: 'Search for an agent in a search box.' },
            { desc: 'Click on certified Drop-down menu and Select certified/Uncertfied.' },
          ]
        },
        ]
      },
      {
        labelName: 'Production & OJT',
        record: [{
          name: 'How to access reports from production dashboard?',
          instruction: [
            { desc: 'Login to Production and OJT dashboard using credentials.' },
            { desc: 'Click on Agent Dashboard.' },
            { desc: 'Click on Reports.' },
            { desc: 'Select required Report to get it download.' },
          ]
        },
        {
          name: 'How to Download reports using filters, Date?',
          instruction: [
            { desc: 'Login to Production and OJT dashboard using credentials.' },
            { desc: 'Apply Filters, Date which is necessary ie., Break, Active, Login, Logout, No Activity and None.' },
            { desc: 'To Download the individual agent report, click on search box and type their name.' },
            { desc: 'Click on reports and select required report to get particular Data download.' },
          ]
        },
        {
          name: 'How to create campaign?',
          instruction: [
            { desc: 'Login to Production and OJT dashboard using credentials.' },
            { desc: 'Click on Create campaign.' },
            { desc: 'Fill all the necessary field.' },
            { desc: 'Download excel template and open it.' },
            { desc: 'Fill the necessary field required and save it.' },
            { desc: 'Click on the browse button and open the saved excel template.' },
            { desc: 'Finally click on the upload campaign button' },
          ]
        },
        {
          name: 'How to rechurn campaign?',
          instruction: [
            { desc: 'Once the campaign is completed Project manager gets an email about campaign status.' },
            { desc: 'Restart the completed campaign, campaign will dial out follow up calls and RNR in sequential logic.' },
            { desc: 'Alternatively, we stop all campaign and group all the RNR and turn off all campaign and create a new campaign' },
            { desc: 'With grouped RNR customers. ' },
          ]
        }
        ]
      }
    ];
  }
  getAgentSlotData(){
    this.getAgentSlot = [   
      {
        labelName: 'Activity',
        name: 'Activity 1',
        instruction: [
          {
            desc: 'Manager can allocate the slots to GIG worker. Click on Settings he can view complete slots designed, then Click on More information and can book the slots.',
            imgPath: '../assets/web_images/activitySlot1.jpg',
            widthCs:'100%'
          },
          {
            desc: 'Manager can choose the slots to GIG worker. Click on Settings he can view complete slots designed, then Click on More information and can book the slots.',
            imgPath: '../assets/web_images/activitySlot2.jpg',
            widthCs:'100%'
          },
          {
            desc: 'GIG workers can choose the slots from APP/My Work Slot. Book or Cancel the slots depending on the availability. ',
            imgPath: '../assets/web_images/activitySlot3.jpg',
            widthCs:'300px'
          },
          {
            desc: 'GIG workers can choose the slots from APP . Book or Cancel the slots depending on the availability',
            imgPath: '../assets/web_images/activitySlot4.jpg',
            widthCs:'300px'
          }
        ],
        imgPath: ''
      },
    ];
  }
  setIndex(index: number, v: any) {
    this.selectedIndex = index;
    this.showHideComliance = false;
    this.showHideRecruitmentDashboard = false;
    this.showHideTrainingModule = false;
    this.showHideMockCall = false;
    this.showHideOJTProduction = false;
    this.showHidePaymentModule = false;
    this.showHideAgentSlot = false;
    this.showHideActivityDashboard = false;
    if (v.name == "Compliance Document") {
      this.showHideComliance = true;
    }
    else if (v.name == "Recruitment Dashboard") {
      this.showHideRecruitmentDashboard = true;
    }
    else if (v.name == "Training Dashboard") {
      this.showHideTrainingModule = true;
    }
    else if (v.name == "Mock Call Dashboard") {
      this.showHideMockCall = true;
    }
    else if (v.name == "Production & OJT Dashboard") {
      this.showHideOJTProduction = true;
    }
    else if (v.name == "Payment Module") {
      this.showHidePaymentModule = true;
    }
    else if (v.name == "Agent Slot") {
      this.showHideAgentSlot = true;
    }
    else if (v.name == "How to do Activity") {
      this.showHideActivityDashboard = true;
    }
  }
  highlightTextFound(searchInputText:any){
   // debugger;
    // var inputText = document.getElementById("collapseOne");
    // var innerHTML = inputText.innerHTML;
    // var index = innerHTML.indexOf(searchInputText);
    // if (index >= 0) { 
    //  innerHTML = innerHTML.substring(0,index) + "<span class='highlightClr'>" + innerHTML.substring(index,index+searchInputText.length) + "</span>" + innerHTML.substring(index + searchInputText.length);
    //  inputText.innerHTML = innerHTML;
    // }
  }
  arrowUpClick(){
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }
}
