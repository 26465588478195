import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'underscore';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Chart } from 'angular-highcharts';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { Utils } from '../../utils';

import { ApiService } from '../../api.service';

import { SEND_EMAIL_BYJSON } from '../../interface';
import {MyserviceService} from '../../myservice.service';

@Component({
	selector: 'app-agent-feed-back',
	templateUrl: './agent-feed-back.component.html',
	styleUrls: ['./agent-feed-back.component.scss']
})
export class AgentFeedBackComponent implements OnInit, OnDestroy {
	api_end_point:any;

	constructor(
		private utils: Utils,
		private API: ApiService,
		private _snackBar: MatSnackBar,
		private activateRoute: ActivatedRoute,private _service:MyserviceService) {
		console.log('sharekhan feed back component starts')
	}

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('audioPlayer') audioPlayer: ElementRef;
	@ViewChild(MatSort) sort: MatSort;

	currentTitle = "feedback";
	CHART_MAIN_FIELD = "Connected";
	CHART_MAIN_TITLE = "Connected";
	maxDate = new Date();
	StartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	EndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	audio: any = new Audio();
	selected_url: string;
	standard_columns_mapper: any = [
		{ "key": "supervisor", "title": "TL" },
		{ "key": "u_name", "title": "Agent Name" },
		{ "key": "u_email", "title": "Agent Email" },
		{ "key": "cust_name", "title": "Customer Name" },
		{ "key": "cust_number", "title": "Customer Number" },
		{ "key": "date", "title": "Call Date" },
		{ "key": "event_type", "title": "Event" }
	]
	columns_filter: any = [
		{ "key": "supervisor", "title": "TL" },
		{ "key": "u_name", "title": "Agent Name" },
		{ "key": "u_email", "title": "Agent Email" },
		{ "key": "cust_name", "title": "Customer Name" },
		{ "key": "cust_number", "title": "Customer Number" },
		{ "key": "date", "title": "Call Date" },
		{ "key": "event_type", "title": "Event" }
	]

	master_table_data: any = [];
	dataSource = new MatTableDataSource(this.master_table_data);

	displayed_columns = ['supervisor', 'u_name', 'u_email', 'cust_name', 'cust_number', 'date','start_time', 'end_time', 'event_type', 'url'];
	additional_columns = [];
	total_display_columns = this.displayed_columns;

	summary_table_columns = [];
	summary_table_data = new MatTableDataSource();

	ngOnInit(): void {
		this.api_end_point = this._service.get_API_End_Point();
		this.activateRoute.queryParams.subscribe(params => {
			// console.log('on init params', params);
			if (params.start_date) {
				this.StartDate = params['start_date']
			}
			if (params.end_date) {
				this.EndDate = params['end_date']
			}
		})
		this.refreshData();
	}
	ngOnDestroy(): void {
		this.dataSource = new MatTableDataSource();
		this.summary_table_data = new MatTableDataSource();
		this.chart = new Chart({
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			credits: {
				enabled: false
			},
			title: {
				text: 'Contacted'
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
			},
			accessibility: {
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: false
					},
					showInLegend: true
				}
			},
			series: [{
				name: 'Counts',
				colorByPoint: true,
				data: [{
					name: this.CHART_MAIN_TITLE,
					sliced: true,
					selected: true,
					y: 0
				}],
				type: undefined
			}]
		})
	}


	chart = new Chart({
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie'
		},
		credits: {
			enabled: false
		},
		title: {
			text: 'Contacted'
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: false
				},
				showInLegend: true
			}
		},
		series: [{
			name: 'Counts',
			colorByPoint: true,
			data: [{
				name: this.CHART_MAIN_TITLE,
				sliced: true,
				selected: true,
				y: 0
			}],
			type: undefined
		}]
	})

	limit_string(str: string) {
		if (str) {
			return this.utils.limitString(str);
		}
		return '';
		/* console.log(t)
		return t; */
	}

	check_table_value(obj, key) {
		if (obj && obj[key]) {
			return obj[key];
		}
		return "";
	}

	playCall(element) {
		// console.log('Play audio', element.url);
		/* this.audio.src = element.url;
		this.audio.load()
		this.audio.play(); */
		this.selected_url = element.url;
		//   this.audioPlayer.nativeElement.src = this.selected_url;
		//   this.audioPlayer.nativeElement.play();
	}

	refreshData() {
		// alert('refresh data clicked');
		// console.log('Refresh data', this.StartDate, this.EndDate);
		this.total_display_columns = this.displayed_columns;
		this.additional_columns = [];
		let loc_table_data = [];
		let start_date = this.utils.localToUTC(this.StartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		let end_date = this.utils.localToUTC(this.EndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		// console.log('utc dates', start_date, end_date);
		this.API.commonFeedbackAPI(start_date, end_date,this.api_end_point).subscribe((res: any) => {
			if (res.success) {
				// console.log('printing sucess', res.success);
				this.master_table_data = res.success;
				for (var i in res.success) {
					// this.displayed_columns = this.displayed_columns.concat(_.keys(res.success[i]));
					if (res.success[i]['event_type']) {
						res.success[i]['event_type'] = this.utils.getCallTypeNames(res.success[i]['event_type']);
					}
					if (res.success[i]['start_time']) {
						let p = res.success[i]['start_time'];
						let q = moment(p);
						res.success[i]['date'] = q.format('YYYY-MM-DD');
						res.success[i]['start_time'] = q.format('HH:mm:ss');
					}
					if (res.success[i]['end_time']) {
						let q = moment(res.success[i]['end_time']);
						res.success[i]['end_time'] = q.format('HH:mm:ss');
					}
					var extra_keys = _.difference(_.keys(res.success[i]), this.displayed_columns);
					if (extra_keys && extra_keys.length) {
						this.additional_columns = _.union(this.additional_columns, extra_keys)
					}
				}
				if (this.additional_columns.length) {
					for (var p in this.additional_columns) {
						let column_obj = { "key": this.additional_columns[p], "title": this.additional_columns[p] }
						let a = _.where(this.columns_filter, column_obj);
						if (!a || !a.length) {
							this.columns_filter.push(column_obj)
						}
						let b = _.where(this.standard_columns_mapper, column_obj);
						if (!b || !b.length) {
							this.standard_columns_mapper.push(column_obj)
						}
					}
				}
				loc_table_data = res.success;
			}
			if (res.errors) {
				this._snackBar.open(res.errors, 'close', {
					duration: 2000,
				});
			}
			this.refreshTable(loc_table_data);
		}, (err) => {
			this._snackBar.open("Error in API Call", 'close', {
				duration: 2000,
			});
		})
	}

	refreshTable(arr) {
		this.dataSource = new MatTableDataSource(arr);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.total_display_columns = _.uniq(this.total_display_columns.concat(this.additional_columns));
		// console.log('columns data', this.total_display_columns, this.additional_columns);
		this.refreshChart();
		this.refresh_summaryTable();
	}

	refreshChart() {
		let data = this.dataSource.data;
		let chart_data = _.pluck(data, this.CHART_MAIN_FIELD);
		var obj = {};
		let keys = [];
		let total = 0;
		let chart_obj = [];
		// console.log('chart data initial', chart_data)
		if (chart_data && chart_data.length) {
			for (var i in chart_data) {
				if (chart_data[i] == undefined) {
					chart_data[i] = 'undefined';
				}
				if (chart_data[i] && (keys.indexOf(chart_data[i]) < 0)) {
					obj[chart_data[i]] = 1
					keys.push(chart_data[i]);
				} else {
					obj[chart_data[i]]++;
				}
				total++;
			}
		}
		// console.log('printnig obj', obj, total);
		if (obj && _.keys(obj).length && total) {
			for (var i in obj) {
				chart_obj.push({
					"name": i,
					"y": obj[i] / total
				})
			}
		}
		if (!chart_obj.length) {
			chart_obj.push({
				"name": this.CHART_MAIN_TITLE,
				"y": 0
			})
		}
		chart_obj[0]['sliced'] = true;
		chart_obj[0]['selected'] = true;
		// console.log('chart obj', chart_obj);
		this.chart = new Chart({
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			credits: {
				enabled: false
			},
			title: {
				text: this.CHART_MAIN_TITLE
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
			},
			accessibility: {
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: false
					},
					showInLegend: true
				}
			},
			series: [{
				name: 'Counts',
				colorByPoint: true,
				data: chart_obj,
				type: undefined
			}]
		})
	}

	refresh_summaryTable() {
		let static_keys = ['supervisor', 'u_name', 'u_email'];
		let table_data = this.dataSource.data;
		let chart_keys = _.uniq(_.pluck(table_data, this.CHART_MAIN_FIELD));
		let agents = _.uniq(_.pluck(table_data, 'u_email'));
		this.summary_table_columns = _.map(_.union(static_keys, chart_keys), (element) => { return (element == undefined) ? 'undefined' : element });
		this.summary_table_columns.push('total');
		// console.log('printing chart keys', chart_keys, agents, this.summary_table_columns);
		var summary_arr = {}
		for (var i in agents) {
			summary_arr[agents[i]] = {};
			for (var k in this.summary_table_columns) {
				summary_arr[agents[i]][this.summary_table_columns[k]] = 0
			}
		}
		// console.log('before summary arr', summary_arr);
		for (var i in table_data) {
			let loc_row = table_data[i];
			if (summary_arr.hasOwnProperty(loc_row['u_email'])) {
				summary_arr[loc_row['u_email']]['u_name'] = loc_row['u_name'];
				summary_arr[loc_row['u_email']]['u_email'] = loc_row['u_email'];
				summary_arr[loc_row['u_email']]['supervisor'] = loc_row['supervisor'];
				if (loc_row.hasOwnProperty(this.CHART_MAIN_FIELD)) {
					if (!summary_arr[loc_row['u_email']].hasOwnProperty(loc_row[this.CHART_MAIN_FIELD])) {
						summary_arr[loc_row['u_email']][loc_row[this.CHART_MAIN_FIELD]] = 0;
					}
					summary_arr[loc_row['u_email']][loc_row[this.CHART_MAIN_FIELD]]++;
					summary_arr[loc_row['u_email']]['total']++;
				}
			}
		}
		let v = _.values(summary_arr);
		this.summary_table_data = new MatTableDataSource(_.values(summary_arr));
		// console.log('Summary table data', this.summary_table_data);
		// console.log('Summary columns', this.summary_table_columns);
	}

	getKeyName(val: string) {
		let p = _.findWhere(this.standard_columns_mapper, { "key": val });
		if (p) {
			return p.title;
		}
		return val;
	}

	change_date_status(key: string, event: MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
			this.StartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.StartDate).diff(moment(this.EndDate)) >= 0) {
				this.EndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			}
		} else {
			this.EndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.StartDate).diff(moment(this.EndDate)) >= 0) {
				this.EndDate = undefined;
				alert("Start date should be less than end date");
			}

		}
	}

	applySearchFilter(val: string) {
		// console.log('search filter', val);
		this.dataSource.filter = val.toLowerCase().trim()
	}

	filtered_data(val) {
		// console.log('filter value', val);
		if (val == 'NULL FILTER') {
			this.refreshTable(this.master_table_data)
		} else {
			var arr = _.filter(this.master_table_data, function (row) {
				var result = false;
				for (var i in val) {
					if (row.hasOwnProperty(i) && (val[i].indexOf(row[i]) > -1)) {
						result = true;
					}
				}
				return result;
			});
			// console.log('printing arr', arr);
			this.refreshTable(arr);
		}
	}

	exportExcelByGroup() {
		// alert('Excel download');
		let table_data: any = this.dataSource.data;
		if (table_data && table_data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Call Dispositions');
			worksheet.columns = [
				{ key: 'TL Name', width: 25},
				{ key: 'Lead Owner', width: 25},
	];
			var mainHeader = ["TL Name", "Lead Owner"]
			// worksheet.addRow(["TL Name", "Lead Owner", "Call Back", "Follow Up", "Not Interested", "Language barrier", "Account Opened", "FOS", "Others", "Total","Call Back", "Follow Up", "Not Interested", "Language barrier", "Account Opened", "FOS", "Others", "Total"]);
		let tlGroup: any = _.groupBy(table_data, function(obj){ return obj.supervisor });
		var allAttributes = {};
		for(var tlName in tlGroup){
			let userGroup:any = _.groupBy(tlGroup[tlName], function(user){ return user.u_name });
			for(var userName in userGroup){
				var attrValues =  _.countBy(userGroup[userName], function(item){ return item.Connected == 'FU - Follow-up' ? item['Follow up'] : item.Connected == 'NI - Not Interested' ? item['Reason for not interested'] : item.Connected });
	
				allAttributes = {...allAttributes,...attrValues}
			}
		}
		var finalAttributes = {};
		for(var key in allAttributes){
			mainHeader.push(key);
			
		}
		mainHeader.push("Total")
		for(var key in allAttributes){
			mainHeader.push(key+' Perc');
			
		}
		
		mainHeader.push("Total Perc")
		for(var key in allAttributes){
			finalAttributes[key+' Perc'] = 0
			finalAttributes[key] = 0;
		}
	
		finalAttributes['total'] = 0;
		finalAttributes['total Perc'] = 0;
		allAttributes['total'] = 0;
		// allAttributes['total Perc'] = 0;
		worksheet.addRow(mainHeader);
	
		var grandSumFinal:any = {}
	
		for(var tlName in tlGroup){
			
			let userGroup:any = _.groupBy(tlGroup[tlName], function(user){ return user.u_name });
			for(var key in allAttributes){
				if(key.includes('Perc')){
					delete allAttributes[key]
				}else{
					// allAttributes[key] = 0;
	
				}
				
			}
			var grandSum:any = {}
			var index = 0;
			var rowTL:any = [tlName != 'undefined' ? tlName+" total" : "General" +" total",""];
			var rowGrandTotal:any = ["Grand Total",""];
			for(var userName in userGroup){
				var rowUser = []
				index == 0 ? tlName != 'undefined' ? rowUser = [tlName,userName] : rowUser = ["General",userName] : rowUser = ["",userName] ;
				for(var key in allAttributes){
					if(key.includes('Perc')){
						delete allAttributes[key]
					}else{
						allAttributes[key] = 0;
	
					}
					
				}
				var attr:any = allAttributes
				var attrValues =  _.countBy(userGroup[userName], function(item){ return item.Connected == 'FU - Follow-up' ? item['Follow up'] : item.Connected == 'NI - Not Interested' ? item['Reason for not interested'] : item.Connected });
				attr = {...attr, ...attrValues};
	
				for(var key in attr){
					if(key != 'total')
						attr.total = attr.total + parseInt(attr[key]);
				}
				for(var key in attr){
					if(grandSum[key]){
						grandSum[key] = grandSum[key] + attr[key];
	
					}else{
						grandSum[key] = attr[key];
	
					}
				}
				
				for(var key in attr){
					if(key != 'total'){
						attr[key+'Perc'] = (attr[key]/attr.total) * 100;
						attr[key+'Perc'] = parseFloat(attr[key+'Perc']).toFixed(2) + "%";
					}
						
				}
				for(var key in attr){
					rowUser.push(attr[key]);
				}
				rowUser.push("100.00%");
				
				worksheet.addRow(rowUser);
				index = index + 1;
			}
			for(var key in grandSum){
				if(key != 'total'){
					grandSum[key+'Perc'] = (grandSum[key]/grandSum.total) * 100;
					grandSum[key+'Perc'] = parseFloat(grandSum[key+'Perc']).toFixed(2)+ "%";
				}	
			}
			for(var key in grandSum){
				rowTL.push(grandSum[key]);
			}
			rowTL.push("100.00%");
			worksheet.addRow(rowTL)
			for(var key in grandSum){
				if(grandSumFinal[key]){
					grandSumFinal[key] = grandSumFinal[key] + grandSum[key];
				}else{
					grandSumFinal[key] = grandSum[key];
				}
				
			}	
		}
		for(var key in grandSumFinal){
			if(key != 'total' && !key.includes('Perc')){
				grandSumFinal[key+'Perc'] = (grandSumFinal[key]/grandSumFinal.total) * 100;
				grandSumFinal[key+'Perc'] = parseFloat(grandSumFinal[key+'Perc']).toFixed(2)+ "%";
			}	
		}
		for(var key in grandSumFinal){
			rowGrandTotal.push(grandSumFinal[key])
		}
		rowGrandTotal.push("100.00%");
		worksheet.addRow(rowGrandTotal)
		
	
		workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'DispositionReport.xlsx');
			})
		}
	}

	exportExcel() {
		// alert('Excel download');
		let table_data: any = this.dataSource.data;
		if (table_data && table_data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Call Dispositions');
			let api_arr = [];
			let header_arr = _.pluck(this.standard_columns_mapper, 'title');
			worksheet.addRow(header_arr);
			api_arr.push(header_arr);
			table_data.forEach(d => {
				let arr = [];
				for (var i in this.standard_columns_mapper) {
					if (d.hasOwnProperty(this.standard_columns_mapper[i]['key'])) {
						arr.push(d[this.standard_columns_mapper[i]['key']])
					} else {
						arr.push("")
					}
					
				}
				let row = worksheet.addRow(arr);
				api_arr.push(arr);
				//	console.log('printing row', row);
			})
			//Generate & Save Excel File
			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'DispositionReport.xlsx');
				if (api_arr.length) {
					let api_obj: SEND_EMAIL_BYJSON = {
						"title": "DispositionReport.xlsx",
						"data_arr": api_arr
					}
					this.API.sendJSONBYEmail(api_obj,this.api_end_point).subscribe()
				}
			})
		}
	}
}
