<div class="row mt-2">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <mat-card>
      <h2 class="d-head">REPORTS<img src="../assets/underline.svg" class="linebelow"> </h2>
      <div class="row">
        <div class="col-md-2 col-sm-12 col-xs-12" style="margin-top: 15px !important;">

          <b>Date Range</b>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">

          <mat-form-field>
            <input matInput placeholder="Start Date" (focus)="startpicker.open()" [matDatepicker]="startpicker" (dateChange)="change_date_report('start_date', $event)" [(ngModel)]="startDate"
              readonly>
            <mat-datepicker-toggle style="font-size: 20px;" matSuffix [for]="startpicker"></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">
          <mat-form-field>
            <input #fromInput matInput placeholder="End Date" (focus)="endpicker.open()" [matDatepicker]="endpicker" (dateChange)="change_date_report('end_date', $event)" [(ngModel)]="endDate" readonly>
            <mat-datepicker-toggle style="font-size: 20px;" matSuffix [for]="endpicker"></mat-datepicker-toggle>
            <mat-datepicker #endpicker></mat-datepicker>
          </mat-form-field>

        </div>
        <button mat-button class="btnAdd" style="height: 40px;" (click)="downloadReport()">Download <i class="fa fa-download"
            aria-hidden="true"></i></button>
      </div>
      <br>
      <div class="row pdt" style="border-top: 2px solid #ccc;">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <mat-radio-group [(ngModel)]="selectedReport" class="r-group">
            <mat-radio-button value="Project"> Audit report (project)</mat-radio-button>
            <mat-radio-button value="Auditor">Audit report (Auditor)</mat-radio-button>
            <!-- <mat-radio-button value="Project/Auditor">Audit report (project & Auditor)</mat-radio-button> -->
            <mat-radio-button value="Costing"> Auditor Earnings </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<div class="row pdt">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="mt-2">
      <div class="searchFix">
        <mat-form-field style="font-size: 12px;">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
          <i class="fa fa-search search-icon" matSuffix></i>
        </mat-form-field>

      </div>
      <table mat-table [dataSource]="dataSource" style="margin-top:8%;" class="mat-elevation-z8">

        <ng-container matColumnDef="report_name">
          <th mat-header-cell *matHeaderCellDef>Report </th>
          <td mat-cell *matCellDef="let element">{{element.report_name}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type</th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Created </th>
          <td mat-cell *matCellDef="let element">{{element.created_at | date:'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="download">
          <th mat-header-cell *matHeaderCellDef> Download</th>
          <td mat-cell *matCellDef="let element">
            <span (click)="downloadReportFromUrl(element.url)"><i class="fa fa-download CR AMclr dwnHover" aria-hidden="true" ></i></span>
            <!-- <mat-icon>arrow_drop_down_circle</mat-icon>     -->
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [ngClass]="'auditorPage'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>