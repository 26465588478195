import { Component, OnInit,ElementRef, HostListener, } from '@angular/core';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { Title, Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SEOService } from 'src/app/canonical_service';

@Component({
  selector: 'app-new-contiinex-comnex-experience-page',
  templateUrl: './new-contiinex-comnex-experience-page.component.html',
  styleUrls: ['./new-contiinex-comnex-experience-page.component.scss']
})
export class NewContiinexComnexExperiencePageComponent implements OnInit {
  myTitle = 'Contiinex | Quality Assurance Automation';
  constructor(private seoService: SEOService,private metaTagService: Meta,private titleService:Title,private router:Router,private location: Location,private API : ApiService,protected $gaService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.seoService.updateCanonicalUrl('https://www.contiinex.com/Quality-Assurance-Automation');
    this.titleService.setTitle(this.myTitle);
    this.metaTagService.updateTag(
      { name: 'description', content: 'AI-powered interaction analytics turn valuable unstructured data from customer conversations into actionable insights that drive customer experience decisions and strategies to automate and improve compliance and quality control, and to enhance agent performance'},"name='description'"
    );
    this.metaTagService.updateTag(
      { name: 'url', content: 'https://www.contiinex.com/Quality-Assurance-Automation'},"name='url'"
    );
    this.metaTagService.updateTag(
      { property: 'og:title', content: this.myTitle },"property='og:title'"
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: 'AI-powered interaction analytics turn valuable unstructured data from customer conversations into actionable insights that drive customer experience decisions and strategies to automate and improve compliance and quality control, and to enhance agent performance'}, "property='og:description'"
    );
    this.metaTagService.updateTag(
      { property: 'og:url', content: 'https://www.contiinex.com/Quality-Assurance-Automation'}, "property='og:url'"
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: 'AI-powered interaction analytics turn valuable unstructured data from customer conversations into actionable insights that drive customer experience decisions and strategies to automate and improve compliance and quality control, and to enhance agent performance'}, "name='twitter:description'"
    );
    this.metaTagService.updateTag(
      { itemprop: 'description', content: 'AI-powered interaction analytics turn valuable unstructured data from customer conversations into actionable insights that drive customer experience decisions and strategies to automate and improve compliance and quality control, and to enhance agent performance'},"itemprop='description'"
  );
  this.metaTagService.updateTag(
      { itemprop: 'url', content: 'https://www.contiinex.com/Quality-Assurance-Automation'},"itemprop='url'"
  );
  this.metaTagService.updateTag(
      { itemprop: 'name', content: this.myTitle },"itemprop='name'"
  );
  }
  bookDemoBtn(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Contact');
  }
  
  openKnowMore_benefit(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Quality-Assurance-Automation');
   }


   bookDemoBtn_Benefit(){     
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Contact');
   }
}
