<!-- <p>Download Attachment works!</p> -->
<div>
	<div style="text-align: right;">
		<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
	</div>
    <label for="name">
      Link
    </label>
    <p><input type="text" id="username" name="username" #username></p>
	<button mat-button type="button" (click)="clickme(username.value)">DOWNLOAD</button>
  </div>
