<mat-card style="margin: 11px;border-radius: 8px;">
    <div class="row" style="padding: 20px 0px 0px 20px;">
        <div class="row col-md-12">
            <div class="m-div">
                <p><span class="s-hd">Select Process</span></p>
                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        <label class="lb-hd">Process</label>
                        <mat-form-field [ngClass]="'orchDDlOutline'" class="ddl" appearance="outline">
                            <mat-select placeholder="Select">
                                <mat-option value="Outbound">Outbound</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label class="lb-hd">Process Type</label>
                        <mat-form-field [ngClass]="'orchDDlOutline'" class="ddl" appearance="outline">
                            <mat-select placeholder="Select">
                                <mat-option value="Promotional">Promotional</mat-option>
                                <mat-option value="Transactional">Transactional</mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label class="lb-hd">Data Source</label>
                        <mat-form-field [ngClass]="'orchDDlOutline'" class="ddl" appearance="outline">
                            <mat-select placeholder="Select">
                                <mat-option value="ExcelUpload">Excel Upload</mat-option>
                                <mat-option value="CRMZoho">CRM - Zoho</mat-option>
                                <mat-option value="CRMSugar">CRM - Sugar</mat-option>
                                <mat-option value="CRMSFDC">CRM - SFDC</mat-option>
                                <mat-option value="CRMOthers">CRM - Others</mat-option>
                                <mat-option value="LMSVTiger">LMS - VTiger</mat-option>
                                <mat-option value="LMSOthers">LMS - Others</mat-option>
                                <mat-option value="Webform">Web form</mat-option>
                                <mat-option value="FormFacebook">Form - Facebook</mat-option>
                                <mat-option value="FormLinkedIn">Form - LinkedIn</mat-option>
                                <mat-option value="Dialler(Manual)">Dialler (Manual)</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label class="lb-hd">Calling Mode</label>
                        <mat-form-field [ngClass]="'orchDDlOutline'" class="ddl" appearance="outline">
                            <mat-select placeholder="Select">
                                <mat-option value="Click To Call">Click To Call</mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div>
        <div class="row pt-3">
            <div class="col-md-3 col-sm-12">
                <label class="lb-hd">Fetch From CRM</label>
               <div class="textMoveDiv scroll_Div">
            <p *ngFor="let p of _Details" (click)="onClick(p)" [ngClass]="{ 'selected': p == selectedItem }">{{p}}</p>
               </div>
            </div>
            <div class="col-md-1 col-sm-12" style="position: relative;top: 50px;">
                <mat-icon class="CR" (click)="sendToRight()" style="display: block;font-size: 90px;color:#e10f59;"> arrow_right</mat-icon>
                <mat-icon  class="CR" (click)="removeFromRight()" style="display: block;font-size: 90px;padding-top: 30px;color:#d5d5d5">arrow_left </mat-icon>
            </div>
            <div class="col-md-3 col-sm-12">
                <label class="lb-hd">Display In App</label>
                <div class="textMoveDiv scroll_Div">
                         <p *ngFor="let v of selectedCopyItem" (click)="onRemoveClick(v)"  [ngClass]="{ 'selected': v == selectedForRemoveItem }">{{v}}</p>
               </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <label class="lb-hd">Action To Take</label>
                <mat-form-field [ngClass]="'orchDDlOutline'" class="ddl" appearance="outline">
                    <mat-select placeholder="Select">
                        <mat-option value="WhatsApp">WhatsApp</mat-option>
                        <mat-option value="SMS">SMS</mat-option>
                        <mat-option value="Email">Email</mat-option>
                        <mat-option value="VoiceBot">Voice Bot</mat-option>
                        <mat-option value="DialerVoiceAgent">Dialer-VOice Agent</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-12 text-right">
                <label class="lb-hd">&nbsp;</label>
                <button class="CX-Save">Save</button>
            </div>
        </div>

    </div>

    <div class="row" style="padding: 20px 0px 0px 20px;">
        <div class="row col-md-12">
    <div class="m-div mt-5">
        <p><span class="s-hd">Template</span></p>
        <div class="row">
            <div class="col-md-3 col-sm-12">
                <label class="lb-hd">Select Template</label>
                <mat-form-field [ngClass]="'orchDDlOutline'" class="ddl" appearance="outline">
                    <mat-select placeholder="Select">
                        <mat-option value="CreateNewTemplate">+ Create New Template</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-12">
                <label class="lb-hd">Template Name</label>
                <mat-form-field [ngClass]="'orchDDlOutline'" class="ddl" appearance="outline">
                    <mat-select placeholder="Select">
                        <mat-option value="TemplateABC">Template ABC</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-12">
                <label class="lb-hd">Message Language</label>
                <mat-form-field [ngClass]="'orchDDlOutline'" class="ddl" appearance="outline">
                    <mat-select placeholder="Select">
                        <mat-option value="SelectLanguge">Select Languge</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

    <div class="col-md-12 col-sm-12 text-right">
        <button class="CX-Save">Save</button>
    </div>

    <div class="m-div mt-5">
        <p><span class="s-hd wp-ui"><img src="../../assets/WhatsApp.svg"/>WhatsApp</span></p>


   
</div> 

    </div>
</div> 
 </div>




</mat-card>