<mat-card class="mt-2" style="padding-top: 20px;margin: 1px;">
    <div style="display:flex;justify-content: center;">
        <mat-card class="vido camDiv">
            <div class="" style="margin-top: 10px">
                <video #video class="vid" autoplay style="position: absolute;top: 0;left: 0;height: 200px; width: 200px;"></video>
                <canvas #canvas  style="top: 6px;left: 24px;width: 156px;height: 190px;border-radius: 56% 45% 65% 65% / 60% 60% 60% 60%;"></canvas>
                <canvas #canvas2 class="vid" style="position: absolute"></canvas>
            </div>
        </mat-card>
    </div>
    <p *ngIf="alignFace == false" style="color:#dd133c;font-family:'poppins';font-size:13px;font-weight:bold;text-align: center;">Please align  your face to webcam</p>
    <div class="row" style="justify-content: center;" *ngIf="!showResult">
        <div class="col-md-2 col-sm-12 col-xs-2"></div>
        <div class="col-md-8 col-sm-12 col-xs-2">
            <div class="row1">
                <div class="column1"><span>Assignment</span></div>
                <div class="column2">
                    <p>
                        <mat-icon style="color: gray;vertical-align: bottom;">speed</mat-icon> <span
                            style="padding-left: 10px;color:#dd133c" *ngIf="testStatus != 'ended' && testStatus == 'started'" id="timer">{{_getMinute}}</span>
                    </p>
                </div>
                <div class="column3">                   
                        <button mat-button class="gobtn CR" (click)="startTimeClick()"
                            style="background: #3fb90d !important;" *ngIf="testStatus == 'initial'">Start Test</button>
                        <button mat-button class="gobtn CR" *ngIf="testStatus == 'started'" style="background: #3fb90d !important;" (click)="submitQuestionaire()">End Test</button>
                      
                </div>
            </div>
        </div>
        <div class="col-md-2 col-sm-12 col-xs-2"></div>
    </div>
    <div class="row mt-2" style="padding-left: 15px;">
        <div class="col-md-12 scrollQA">
            <span *ngIf="testStatus == 'started' ">
                <div *ngFor="let section of callFlowJsonSections;let $index=index">
                    <h2 class="mt-4" style="color: #3fb90d;font-size: 25px;">{{ section.title }}</h2>
                    <div *ngFor="let child of section.child;let $childIndex=index">
                        <span *ngIf="child.at == 'Single Answer'">
                            <mat-form-field [ngClass]="'inputClr'" style="width: 100%;">
                                <span>{{$childIndex + 1}}. {{child.q}}</span>
                                <mat-select [(ngModel)]="child.answer">
                                    <mat-option style="height: 5em !important;" value="{{d}}"
                                        *ngFor="let d of child.Answers">{{d}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                        <span *ngIf="child.at == 'YN'">
                            <label class="mt-2" id="radio{{$childIndex}}">{{child.q}}</label>
                            <br />
                            <mat-radio-group aria-labelledby="radio{{$childIndex}}" [(ngModel)]="child.answer"
                                class="ml-3">
                                <mat-radio-button value="Yes" [ngClass]="'radioPresent'" style="padding-right: 12px;">
                                    Yes</mat-radio-button>
                                <mat-radio-button value="No" [ngClass]="'radioAbsent'">No</mat-radio-button>
                            </mat-radio-group>
                        </span>
                        <span *ngIf="child.at == 'TF'">
                            <label class="mt-2" id="radio{{$childIndex}}">{{child.q}}</label>
                            <br />
                            <mat-radio-group aria-labelledby="radio{{$childIndex}}" [(ngModel)]="child.answer"
                                class="ml-3">
                                <mat-radio-button value="True" [ngClass]="'radioPresent'" style="padding-right: 12px;">
                                    True</mat-radio-button>
                                <mat-radio-button value="False" [ngClass]="'radioAbsent'">False</mat-radio-button>
                            </mat-radio-group>
                        </span>

                        <span *ngIf="child.at == 'Multiple Answers'">

                            <span style="font-size:13px !important">{{$childIndex + 1}}. {{child.q}}</span>
                            <br />
                            <div *ngFor="let c of child.Answers; let $mdd_index=index" class="ml-3" style="color:gray;display: inline-block;
                    flex-wrap: wrap;">
                                <mat-checkbox (change)="mddValueChange($event, $index, $childIndex, $mdd_index)"
                                    class="pl-2">{{c}}</mat-checkbox>
                            </div>
                        </span>
                    </div>
                </div>
            </span>
            <div style="display: flex;justify-content: center;text-align: center;">
                <span *ngIf="showResult">
                    <h2 style="color: #a86400;font-size: 1.52em;font-weight: 600;">Your score </h2>
                    <h2 style="font-size: 1.82em;font-weight: 600;border-radius: 4px; padding: 6px 12px;color: #fff;text-align: center !important; background-color: #3fb90d;display: inline-flex;">{{result.user_score}}</h2>
                    <p class="scoreMsg" *ngIf="result.eligible == true">{{result.message}}</p>
                    <p class="scoreMsg" *ngIf="result.eligible == false">{{result.message}}</p>
                </span>
            </div>
        </div>
    </div>
</mat-card>
<!------------------------------------------->


<!-- <mat-card class="mt-2">
    <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-2"></div>
        <div class="col-md-4 col-sm-12 col-sx-7">            
                <mat-card class="vido camDiv">
                    <div class="" style="margin-top: 10px">
                        <video #video class="vid" autoplay style="position: absolute;top: 0;left: 0;height: 200px; width: 200px;" ></video>
                        <canvas #canvas style="top: 6px;left: 24px;width: 156px;height: 190px;border-radius: 56% 45% 65% 65% / 60% 60% 60% 60%;"  [ngStyle]="{'border': '6px dotted green'}"></canvas>
                        <canvas #canvas2 class="vid" style="position: absolute" ></canvas>
                    </div>
                </mat-card>              
            </div>
            <div class="col-md-4 col-sm-12 col-xs-3 text-center">
                <br>
                <table  style="width:100%">
                    <tr>
                        <td>
                            <button mat-button class="gobtn CR" (click)="startTimeClick()" *ngIf="testStatus == 'initial'">Start Test</button>
                            <button mat-button class="gobtn CR" *ngIf="testStatus == 'started'" (click)="submitQuestionaire()">End Test</button>
                           
                        </td>  </tr>
                        <tr>    <td>
                            <p *ngIf="testStatus != 'ended' && testStatus == 'started'">Timer:  <span id="timer">{{_getMinute}}</span> minutes</p>
                            <p *ngIf="alignFace == false" style="color:red;font-family:'poppins';font-size:13px;font-weight:bold;">Please align your face to webcam</p>
                          
                        </td>
                    </tr>
                </table>
            </div>
    </div>


    <div class="row mt-2">
<div class="col-md-12 scrollQA">
    <span *ngIf="testStatus == 'started' ">
        <div *ngFor="let section of callFlowJsonSections;let $index=index" >
            <h2 class="mt-4">{{ section.title }}</h2>
            <div *ngFor="let child of section.child;let $childIndex=index">
                <span *ngIf="child.at == 'Single Answer'">
                    <mat-form-field [ngClass]="'inputClr'" style="width: 100%;"> 
                        <span>{{$childIndex + 1}}. {{child.q}}</span>    
                        <mat-select  [(ngModel)]="child.answer">
                        <mat-option style="height: 5em !important;" value="{{d}}" *ngFor="let d of child.Answers">{{d}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>
                <span *ngIf="child.at == 'YN'">
                    <label class="mt-2" id="radio{{$childIndex}}">{{child.q}}</label>
                    <br/>
                    <mat-radio-group aria-labelledby="radio{{$childIndex}}" [(ngModel)]="child.answer" class="ml-3">
                        <mat-radio-button value="Yes" [ngClass]="'radioPresent'" style="padding-right: 12px;">Yes</mat-radio-button>
                        <mat-radio-button value="No" [ngClass]="'radioAbsent'">No</mat-radio-button>
                      </mat-radio-group> 
                </span>
                <span *ngIf="child.at == 'TF'">
                    <label class="mt-2" id="radio{{$childIndex}}">{{child.q}}</label>
                    <br/>
                    <mat-radio-group aria-labelledby="radio{{$childIndex}}" [(ngModel)]="child.answer" class="ml-3">
                        <mat-radio-button value="True" [ngClass]="'radioPresent'" style="padding-right: 12px;">True</mat-radio-button>
                        <mat-radio-button value="False" [ngClass]="'radioAbsent'">False</mat-radio-button>
                      </mat-radio-group> 
                </span>
              
                <span *ngIf="child.at == 'Multiple Answers'">
                    
                    <span style="font-size:13px !important">{{$childIndex + 1}}. {{child.q}}</span>
                    <br/>
                    <div *ngFor="let c of child.Answers; let $mdd_index=index" class="ml-3" style="color:gray;display: inline-block;
                    flex-wrap: wrap;">
                        <mat-checkbox (change)="mddValueChange($event, $index, $childIndex, $mdd_index)" class="pl-2">{{c}}</mat-checkbox>
                    </div>
                </span>
              
            </div>
        </div>
        
    </span>
    <span *ngIf="showResult">
        <h2>Your score : {{result.user_score}}</h2>
        <p *ngIf="result.eligible == true">{{result.message}}</p>
        <p *ngIf="result.eligible == false">{{result.message}}</p>
    </span>
</div>

    </div>
    <br>
</mat-card> -->
<!-- <div  class="col-md-4">
    
    <button mat-button class="gobtn CR" (click)="startTimeClick()" *ngIf="testStatus == 'initial'">Start Test</button>
    <button mat-button class="gobtn CR" *ngIf="testStatus == 'started'" (click)="submitQuestionaire()">End Test</button>
    <br/>
    <br>
    <mat-card class="vido camDiv">
        <div class="" style="margin-top: 10px">
            <video #video class="vid" autoplay style="position: absolute;top: 0;left: 0;" ></video>
            <canvas #canvas style="top: 35px;left: 62px;width: 170px;height: 230px;border-radius: 56% 45% 65% 65% / 60% 60% 60% 60%;"  [ngStyle]="{'border': '6px dotted #e91e63'}"></canvas>
            <canvas #canvas2 class="vid" style="position: absolute" ></canvas>
        </div>
    </mat-card>
  
    
    <p *ngIf="testStatus != 'ended' && testStatus == 'started'">Timer:  <span id="timer">{{_getMinute}}</span> minutes</p>
    <p *ngIf="alignFace == false" style="color:red;font-family:'poppins';font-size:13px;font-weight:bold;">Please align your face to webcam</p>
  
    
</div> -->
<!-- <div  class="col-md-1"></div> -->
<!-- <div class="col-md-12" *ngIf="showResult">
<h2>Your score : {{result.user_score}}</h2>
<p *ngIf="result.eligible == true">You are eligible for further process</p>
<p *ngIf="result.eligible == false">You are not eligible for further process</p>
</div> -->