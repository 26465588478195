import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-view-details-dialog1',
  templateUrl: './view-details-dialog1.component.html',
  styleUrls: ['./view-details-dialog1.component.scss']
})
export class ViewDetailsDialog1Component implements OnInit {
  elementData:any;
  _channelType:any;
  showHideWhatsapp:boolean=false;
  showHideIVR:boolean=false;
  constructor( public dialogRef: MatDialogRef<ViewDetailsDialog1Component>,
    @Inject(MAT_DIALOG_DATA) public Data) { }

  ngOnInit() {
    this.elementData=this.Data.element;
    this._channelType=this.Data.channelType;
    if(this._channelType=="whatsapp" || this._channelType=="Whatsapp"){
      this.showHideWhatsapp=true;
      this.showHideIVR=false;
    }
    if(this._channelType=="outboundIvr" || this._channelType=="IVR"){
      this.showHideWhatsapp=false;
      this.showHideIVR=true;
    }
    if(this.elementData.content){
      this.elementData.content = this.elementData.content.replaceAll('\\n\\n', '<br>')
    }
    if(this.elementData.call_url){
      setTimeout(()=>{
      document.getElementById('callurl').innerHTML = '<audio controls><source src="'+this.elementData.call_url+'" type="audio/mpeg"></audio>';
    },2000)
    }
    
    
   // console.log(this.elementData);
    
  }

  getEventName(val){
    var event = 'Call Missed';
    switch(val){
      case 'ivr_out_completed':
        event = 'Call Connected';
        break;
      default:
          break;
    }
    return event;
  }
  closeinfo(){
    this.dialogRef.close();
      }
      convertDTime (isoTime) {
        if(isoTime !=null){
        let a=isoTime.split('T')
        let b=a[0];
        let c=a[1];
          var hours   = parseInt(c.substring(0, 2), 10),
              minutes = parseInt(c.substring(3, 5), 10),
              ampm    = 'am';
        
          if (hours == 12) {
            ampm = 'pm';
          } else if (hours == 0) {
            hours = 12;
          } else if (hours > 12) {
            hours -= 12;
            ampm = 'pm';
          }
        
       // return  b + " / " + hours + ':' + minutes + ' ' + ampm;
        var isoDateTime = new Date(isoTime);
    let localDateTime = b  + " / " + isoDateTime.toLocaleTimeString();// isoDateTime.toLocaleDateString() +
    return localDateTime;
        }
        else{
          return "";
        }
        }
}
