<mat-card class="pCard">
  <div class="row rowBdrBtm" *ngIf="showHideNoHistotyMsg">
    <div class="col-md-12 mt-2"><span class="recName1"
        style="color:red;font-size:13px;font-weight:500">{{NoHistoty}}</span> </div>
  </div>
  <div class="row rowBdrBtm">
    <div class="col-md-4 mt-2"><span class="recName1">Summary View</span> </div>
    <div class="col-md-3" style="text-align: end;">
      <mat-form-field class="mt-2">
        <input matInput #searchInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
        <i matSuffix class="fa fa-search searchicon" style="color: #d5d5d5;" aria-hidden="true"></i>
      </mat-form-field>
    </div>
    <div class="col-md-3 mt-2">
<!--<mat-select (selectionChange)="changeYear($event.value)" [ngClass]="'arrowcsDDL'" class="dropdownDDL"
        placeholder="Select" [(value)]="selectedCurYear">
        <mat-option value="2021">2021</mat-option>
        <mat-option value="2020">2020</mat-option>
        <mat-option value="2019">2019</mat-option>
        <mat-option value="2018">2018</mat-option>
        <mat-option value="2017">2017</mat-option>
        <mat-option value="2016">2016</mat-option>
        <mat-option value="2015">2015</mat-option>
      </mat-select>

      <mat-select (selectionChange)="changeMonth($event.value)" [ngClass]="'arrowcsDDL'" class="dropdownDDL"
        placeholder="Select" [(value)]="selectedMonth">
        <mat-option value="Jan">Jan</mat-option>
        <mat-option value="Feb">Feb</mat-option>
        <mat-option value="Mar">Mar</mat-option>
        <mat-option value="Apr">Apr</mat-option>
        <mat-option value="May">May</mat-option>
        <mat-option value="Jun">Jun</mat-option>
        <mat-option value="Jul">Jul</mat-option>
        <mat-option value="Aug">Aug</mat-option>
        <mat-option value="Sep">Sep</mat-option>
        <mat-option value="Oct">Oct</mat-option>
        <mat-option value="Nov">Nov</mat-option>
        <mat-option value="Dec">Dec</mat-option>
      </mat-select>

	  <mat-form-field appearance="fill">
		<mat-label>Choose a date</mat-label>
		<input matInput [matDatepicker]="picker">
		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker startView="year" [startAt]="startDate"></mat-datepicker>
	  </mat-form-field>--->
	  
	  <mat-form-field>
		<input matInput [matDatepicker]="dp" placeholder="Month and Year" [formControl]="date">
		<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
		<mat-datepicker #dp
						startView="multi-year"
						(yearSelected)="chosenYearHandler($event)"
						(monthSelected)="chosenMonthHandler($event, dp)"
						panelClass="example-month-picker">
		</mat-datepicker>
	  </mat-form-field>
	  
    </div>
	<div class="col-md-2 mt-2">
		<button class="approval-btn-ui" style="background: #f9f8f8;color: #fd6c21;" (click)="refreshBtn()">Refresh</button> 
	</div>
  </div>
  <div class="row rowBdrBtm">
	<button class="excel_export p-4" (click)="exportExcel()">Export Transactions</button>
  </div>
  <div>
    <mat-table class="matTableCs" [dataSource]="dataSource">
      <ng-container matColumnDef="gig_id">
        <mat-header-cell *matHeaderCellDef> Gig ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.gig_id}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="user_id">
        <mat-header-cell *matHeaderCellDef> Agent ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.user_id}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Agent Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Agent Email </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
      </ng-container>


      <ng-container matColumnDef="actual_earnings">
        <mat-header-cell *matHeaderCellDef> Gross Earnings </mat-header-cell>
        <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}}{{element.actual_earnings | number:'1.2-2'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="total_deductions">
        <mat-header-cell *matHeaderCellDef>Tax Deductions </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{currency_code| currencySymbol}}{{element.total_deductions | number:'1.2-2'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="opening_balance">
        <mat-header-cell *matHeaderCellDef> Opening Balance </mat-header-cell>
        <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}}{{element.opening_balance | number:'1.2-2'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total_earnings">
        <mat-header-cell *matHeaderCellDef>Net Earnings </mat-header-cell>
        <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}}{{element.total_earnings | number:'1.2-2'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="closing_balance">
        <mat-header-cell *matHeaderCellDef> Closing Balance </mat-header-cell>
        <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}}{{element.closing_balance | number:'1.2-2'}} </mat-cell>
      </ng-container>


      <ng-container matColumnDef="details">
        <mat-header-cell *matHeaderCellDef style="flex:0 0 120px;"> Details </mat-header-cell>
        <mat-cell *matCellDef="let element" style="flex:0 0 120px;">
          <span class="viewMore" (click)="viewMore(element)">View More</span>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
  <!-- <button class="app-btn-ui" (click)="uploadExcelBtn()"
    style="width:auto;padding: 3px 15px;border: 1.6px solid #3fb90d;"><img
      src="../assets/web_images/uploadExcelIcon.png" /> Add Earnings For Events </button> -->
</mat-card>
	<div id="cover-spin" *ngIf="currentLoader">
	<!--<mat-spinner strokeWidth="3" [diameter]="100"></mat-spinner>-->	
</div>
