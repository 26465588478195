import { Component, OnInit, Input } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-call-flow-form',
  templateUrl: './call-flow-form.component.html',
  styleUrls: ['./call-flow-form.component.scss']
})
export class CallFlowFormComponent implements OnInit {
  tabNameList:any;
    @Input()
  public removeSubSection: Function;
  @Input()
  public untitleToggle: Function;
  @Input() selectedIndex: string;
@Input() selectedChildIndex: string;
  @Input() selectedLevel: any;
  @Input() radioIndex: any;
@Input() checked: any;

  constructor(private API: ApiService, private router: Router, private dialog: MatDialog, private service: MyserviceService) {  }

   ngOnInit() {
     let _pathurl=window.location.pathname;
  if(_pathurl=="/call-flow-form/call-flow-create"){
    this.router.navigateByUrl('/call-flow-form');
  }
    this.tabNameList=[
      {name:'account',iconName:'account_circle',routerLinkUrl:'./auditor-help', active:'active'},
      {name:'add',iconName:'add_circle_outline',routerLinkUrl:'./call-flow-create', active:'active'},
      {name:'grid',iconName:'grid_on',routerLinkUrl:'./auditor-help', active:'active'},
      {name:'setting',iconName:'settings',routerLinkUrl:'./auditor-help', active:'active'},
      {name:'help',iconName:'help_outline',routerLinkUrl:'./auditor-help', active:'active'},
    ];   
  }
  clickTab(name:any){

  }
  trigerRemoveSubSection(){
   // console.log(this.selectedIndex)
    this.removeSubSection(this.selectedIndex,this.selectedChildIndex, this.selectedLevel,this.radioIndex)
  }
  triggerToggle(event){
   // console.log(this.selectedIndex)
    this.untitleToggle(this.selectedIndex,event, this.selectedLevel,this.selectedChildIndex, this.radioIndex)
  }
  publishForm(){
    var myUser = this.service.get_loggedin_user();
    var callFlowJsonSections = this.service.getCallForm();
    var finalJson = [];
    for(let i in callFlowJsonSections){
      if(parseInt(i) > 0){
        break;
      }
      var module = [];
      for(let obj of callFlowJsonSections[i].child){
        module.push(obj)
      }
      finalJson = module;
    }
    var obj = {
      production_group: myUser.id,
      form_name:callFlowJsonSections[0].title,
      form_object: JSON.stringify(callFlowJsonSections),
      form_object_compat: JSON.stringify(finalJson),
      campaign_name: ""
    }
    this.API.saveCallForm(obj).subscribe((response: any) => {
        if(response && response.success){
          alert("Form saved successfully....");
          window.location.reload();
        }
      });
  }
}
