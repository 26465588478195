import { Injectable, Injector, Inject } from '@angular/core';
import { Subject, BehaviorSubject, observable, Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as _ from 'underscore';

import { ApiService } from '../app/api.service';

@Injectable({
	providedIn: 'root'
})
export class MyserviceService {
	public access_token: string = null;
	public login_user: any;
	public call_status: any;
	public call_session: any = {};
	public chat_status: any;

	public team_details: any;
	public CENTRAL_TEAM_RELATION = new BehaviorSubject({});
	public CENTRAL_BI_ROLES = new BehaviorSubject([]);
	public SELECTED_BI_ROLE = new BehaviorSubject('');
	ddlSelection = new BehaviorSubject({ 'zsm': {}, 'rsm': {}, 'asm': {} });
	public SUPERVISOROBJ = new BehaviorSubject<any>(null);
	private MY_TEAM_DATA = new BehaviorSubject<any>('');
	private FULL_TEAM_DATA = new BehaviorSubject({});
	public full_team = this.FULL_TEAM_DATA.asObservable();
	public APPACTIVATE = new BehaviorSubject(false);
	public isAppActive = this.APPACTIVATE.asObservable();
	private SETTINGS = new BehaviorSubject('');
	public settings = this.SETTINGS.asObservable();
	private CALL_STATUS = new Subject<any>();
	public callStatus = this.CALL_STATUS.asObservable();
	private CALL_SESSION = new Subject<any>();
	public callSession = this.CALL_SESSION.asObservable();
	private CHAT_STATUS = new Subject<any>();
	public chatStatus = this.CHAT_STATUS.asObservable();
	private NOTIFICATION_STATUS = new Subject<any>();
	private MENU_COLLAPSE_STATUS = new BehaviorSubject<boolean>(false);
	public notificationStatus = this.CALL_STATUS.asObservable();
	public web_settings = null;
	private selected_campaign_details: string = null;
	public DESKTOP_CALL_BUTTON_STATUS = new BehaviorSubject<number>(1)
	public desktop_call_button_status = this.DESKTOP_CALL_BUTTON_STATUS.asObservable()

	public DESKTOP_CALL_BUTTON_STATUS_SOCKET = new BehaviorSubject<boolean>(false)
	public desktop_call_button_status_socket = this.DESKTOP_CALL_BUTTON_STATUS_SOCKET.asObservable()

	public DESKTOP_INBOUND_BUTTON_STATUS = new BehaviorSubject<boolean>(false)
	public desktop_inbound_button_status = this.DESKTOP_INBOUND_BUTTON_STATUS.asObservable()

	private ainex_demo_users = [
		{
			"name": "Demo audit",
			"user_name": "demo-audit@contiinex.com",
			"password": "12345678",
			"group_id": "10001",
			"page": "demo-audit"
		},
		{
			"name": "Demo assist",
			"user_name": "demo-assist@contiinex.com",
			"password": "12345678",
			"group_id": "10002",
			"page": "demo-assist"
		},
		{
			"name": "Demo update",
			"user_name": "demo-update@contiinex.com",
			"password": "12345678",
			"group_id": "10003",
			"page": "demo-update"
		},
		{
			"name": "IIB",
			"user_name": "iib191@contiinex.com",
			"password": "12345678",
			"group_id": "10004",
			"page": "semantic-search"
		},
		{
			"name": "IIB",
			"user_name": "iib192@contiinex.com",
			"password": "12345678",
			"group_id": "10005",
			"page": "semantic-search"
		},
		{
			"name": "IIB",
			"user_name": "iib193@contiinex.com",
			"password": "12345678",
			"group_id": "10006",
			"page": "semantic-search"
		},
		{
			"name": "Quest audit",
			"user_name": "quest-audit@contiinex.com",
			"password": "12345678",
			"group_id": "10009",
			"page": "demo-audit"
		},
		{
			"name": "Quest assist",
			"user_name": "quest-assist@contiinex.com",
			"password": "12345678",
			"group_id": "10010",
			"page": "demo-assist"
		},
		{
			"name": "Auto audit",
			"user_name": "autoaudit@contiinex.com",
			"password": "12345678",
			"group_id": "10011",
			"page": "demo-audit"
		},
		{
			"name": "Agent assist",
			"user_name": "agentassist@contiinex.com",
			"password": "12345678",
			"group_id": "10012",
			"page": "demo-assist"
		},
		{
			"name": "Partner1 Audit",
			"user_name": "partner1audit@contiinex.com",
			"password": "12345678",
			"group_id": "10013",
			"page": "demo-audit"
		},
		{
			"name": "Partner2 Audit",
			"user_name": "partner2audit@contiinex.com",
			"password": "12345678",
			"group_id": "10014",
			"page": "demo-audit"
		},
		{
			"name": "Partner3 Audit",
			"user_name": "partner3audit@contiinex.com",
			"password": "12345678",
			"group_id": "10015",
			"page": "demo-audit"
		},
		{
			"name": "Partner4 Audit",
			"user_name": "partner4audit@contiinex.com",
			"password": "12345678",
			"group_id": "10016",
			"page": "demo-audit"
		},
		{
			"name": "Partner5 Audit",
			"user_name": "partner5audit@contiinex.com",
			"password": "12345678",
			"group_id": "10017",
			"page": "demo-audit"
		},
		{
			"name": "Partner6 Audit",
			"user_name": "partner6audit@contiinex.com",
			"password": "12345678",
			"group_id": "10018",
			"page": "demo-audit"
		},
		{
			"name": "Partner7 Audit",
			"user_name": "partner7audit@contiinex.com",
			"password": "12345678",
			"group_id": "10019",
			"page": "demo-audit"
		},
		{
			"name": "Partner8 Audit",
			"user_name": "partner8audit@contiinex.com",
			"password": "12345678",
			"group_id": "10020",
			"page": "demo-audit"
		},
		{
			"name": "Partner9 Audit",
			"user_name": "partner9audit@contiinex.com",
			"password": "12345678",
			"group_id": "10021",
			"page": "demo-audit"
		},
		{
			"name": "Partner10 Audit",
			"user_name": "partner10audit@contiinex.com",
			"password": "12345678",
			"group_id": "10022",
			"page": "demo-audit"
		},
		{
			"name": "Partner1 Assist",
			"user_name": "partner1assist@contiinex.com",
			"password": "12345678",
			"group_id": "10023",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner2 Assist",
			"user_name": "partner2assist@contiinex.com",
			"password": "12345678",
			"group_id": "10024",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner3 Assist",
			"user_name": "partner3assist@contiinex.com",
			"password": "12345678",
			"group_id": "10025",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner4 Assist",
			"user_name": "partner4assist@contiinex.com",
			"password": "12345678",
			"group_id": "10026",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner5 Assist",
			"user_name": "partner5assist@contiinex.com",
			"password": "12345678",
			"group_id": "10027",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner6 Assist",
			"user_name": "partner6assist@contiinex.com",
			"password": "12345678",
			"group_id": "10028",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner7 Assist",
			"user_name": "partner7assist@contiinex.com",
			"password": "12345678",
			"group_id": "10029",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner8 Assist",
			"user_name": "partner8assist@contiinex.com",
			"password": "12345678",
			"group_id": "10030",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner9 Assist",
			"user_name": "partner9assist@contiinex.com",
			"password": "12345678",
			"group_id": "10031",
			"page": "AgentNex-Demo"
		},
		{
			"name": "Partner10 Assist",
			"user_name": "partner10assist@contiinex.com",
			"password": "12345678",
			"group_id": "10032",
			"page": "AgentNex-Demo"
		},
		{
			"name": "AgentNex-Demo",
			"user_name": "agentnexdemo@contiinex.com",
			"password": "12345678",
			"group_id": "10033",
			"page": "AgentNex-Demo"
		},
		{
			"name": "AuditNex-Demo",
			"user_name": "auditnexdemo@contiinex.com",
			"password": "12345678",
			"group_id": "10034",
			"page": "demo-audit"
		},
		{
			"name": "AiNex-Demo",
			"user_name": "ainexdemo@contiinex.com",
			"password": "12345678",
			"group_id": "10035",
			"page": "semantic-search"
		}
	]

	public current_project: string = null;
	public total_pages = [
		{
			"data_id": 'dashboard-recruiter',
			"path": "/dashboard-recruiter",
			"name": 'Hiring',
			"isAccessed": false,
			"icon": "../assets/web_images/hiring.svg"//"av_timer"
		},
		{
			"data_id": 'agency',
			"name": 'Agency',
			"path": "/agency",
			"isAccessed": false
		},
		{
			"data_id": 'pmdashboard',
			"name": 'Project Manager',
			"path": "/pmdashboard",
			"isAccessed": false
		},
		{
			"data_id": 'opsdashboard',
			"name": 'Onboarding',
			"path": "/opsdashboard",
			"isAccessed": false,
			"icon": "../assets/web_images/onboarding.svg"//"group"
		},
		{
			"data_id": 'onboardTechSupport',
			"name": 'Onbarding TS',
			"path": "/onboardTechSupport",
			"isAccessed": false
		},
		{
			"data_id": 'training',
			"name": 'Training',
			"path": "/training",
			"isAccessed": false,
			"icon": "../assets/web_images/training.svg"
		},
		{
			"data_id": 'managementTeam',
			"name": 'Manage Team',
			"path": "/managementTeam",
			"isAccessed": false,
			"icon": "../assets/web_images/managementTeam.svg",//"group_add",
			"mat_icon": "groups"
		},
		{
			"data_id": 'pmdash',
			"name": 'Agent Dashboard',
			"path": "/pmdash",
			"isAccessed": false
		},
		{
			"data_id": 'outbound',
			"name": 'Dashboard',//'Outbound',
			"path": "/view/outbound",
			"isAccessed": false,
			"mat_icon": "dashboard",
			"icon": "../assets/web_images/outboundicon1.svg"
		},
		{
			"data_id": 'forms',
			"name": 'Forms',
			"path": "/forms",
			"isAccessed": false
		},
		{
			"data_id": 'sharekhan',
			"name": 'Agent Dashboard',
			"path": "/sharekhan",
			"isAccessed": false
		},
		{
			"data_id": 'voiceCallAudit',
			"name": 'Call Audit',
			"path": "/voiceCallAudit",
			"isAccessed": false,
			"icon": "../assets/web_images/call-audit.svg"
		},
		{
			"data_id": 'uploadAudio',
			"name": 'Upload Audio',
			"path": "/uploadAudio",
			"isAccessed": false,
			"icon": "../assets/web_images/upload-audio.svg"
		},
		{
			"data_id": 'voiceBiometric',
			"name": 'Voice Biometric',
			"path": "/voiceBiometric",
			"isAccessed": false,
			"icon": "../assets/web_images/speaking.svg"
		},
		{
			"data_id": 'fk_service_dash',
			"name": 'FK-Dashboard',
			"path": "/fk_service_dash",
			"isAccessed": false
		},
		{
			"data_id": 'campaignV2',
			"path": "/campaignV2",
			"name": 'Campaigns',
			"mat_icon": "campaign",
			"isAccessed": false,
			"icon": "../assets/web_images/campaignicon.svg"
		},
		{
			"data_id": 'central-campaign',
			"name": 'Central Campaign',
			"path": "/central-campaign",
			"isAccessed": false,
			"icon": "../assets/web_images/campaignicon.svg"
		},
		// {
		// 	"data_id": 'campaignDetails',
		// 	"name": 'campaignDetails',
		// 	"path": "/campaignDetails",
		// 	"isAccessed": false
		// },
		{
			"data_id": 'inbound',
			"name": 'Dashboard',// 'Inbound Dashboard',
			"path": "/dashboard-inbound",
			"isAccessed": false,
			"mat_icon": "dashboard",
			"icon": "../assets/web_images/outboundicon1.svg"
		},
		{
			"data_id": 'whatsapp',
			"name": 'Dashboard',//'Whatsapp',
			"path": "/whatsapp-dashboard",
			"isAccessed": false,
			"icon": "../assets/web_images/outboundicon1.svg"
		},
		{
			"data_id": 'auditor',
			"name": 'Auditor Dashboard',
			"path": "/auditor",
			"isAccessed": false
		},
		{
			"data_id": 'orchestrator',
			"name": 'Orchestrator',
			"path": "/orchestrator",
			"isAccessed": false
		},
		{
			"data_id": 'feedback',
			"name": 'Feedback',
			"path": "/feedback",
			"isAccessed": false
		},
		{
			"data_id": 'callrecords',
			"name": 'Call Records',
			"path": "/callrecords",
			"isAccessed": false
		},
		/* {
			"data_id": 'settings',
			"name": 'Settings',
			"path": "/Stagesettings",
			"isAccessed": false,
			"icon": "../assets/web_images/callsetting.svg"
		}, */
		{
			"data_id": 'payment',
			"name": 'Payments',
			"path": "/Payment",
			"isAccessed": false,
			"icon": "../assets/web_images/iconPayment.svg"
		},
		{
			"data_id": 'BIdashboard',
			"name": 'BI Dashboard',
			"path": "/BI-Dashboard",
			"isAccessed": false,
			"icon": "../assets/web_images/campaignicon.svg"
		},
		{
			"data_id": 'central-dashboard',
			"name": 'Central Dashboard',
			"path": "/central-dashboard",
			"isAccessed": false,
			"icon": "../assets/web_images/campaignicon.svg"
		},
		///comnex url start
		{
			"data_id": "Dashboard",
			"icon": "../assets/contiinex/image/vertical_distributeIcon1.svg",
			"isAccessed": false,
			"name": "Dashboard",
			"path": "/Dashboard",
			"mat_icon": "dashboard",
			"project": "comnex"
		}
		, {

			"data_id": "funnel-dashboard",
			"icon": "../assets/contiinex/image/dashboardShopifyIcon1.svg",
			"isAccessed": false,
			"name": "Funnel Dashboard",
			"path": "/Funnel-Dashboard",
			"project": "comnex"

		}
		, {
			"data_id": "details-view",
			"icon": "../assets/contiinex/image/teamShopifyIcon1.svg",
			"isAccessed": false,
			"name": "Details View",
			"path": "/Details-View",
			"project": "comnex"

		}, {
			"data_id": "processFlowBuilder",
			"icon": "../assets/contiinex/image/processFlowBuilderIcon1.svg",
			"isAccessed": false,
			"name": "Process Flow Builder",
			"path": "/ProcessFlowBuilder",
			"project": "comnex"
		},
		{
			"data_id": "Reports",
			"icon": "../assets/contiinex/image/articleIcon1.svg",
			"isAccessed": false,
			"name": "Reports",
			"path": "/Contiinex-Reports",
			"mat_icon": "article",
			"project": "comnex"
		},
		{
			"data_id": "Profile-Settings",
			"icon": "../assets/contiinex/image/badgeIcon1.svg",
			"isAccessed": false,
			"name": "Profile Settings",
			"path": "/Profile-Settings",
			"mat_icon": "manage_accounts",
			"project": "comnex"
		},
		{
			"data_id": "Message-Template",
			"icon": "../assets/contiinex/image/ballotIcon1.svg",
			"isAccessed": false,
			"name": "Message Template",
			"path": "/Message-Template",
			"mat_icon": "message",
			"project": "comnex"

		},
		{
			"data_id": "Contiinex-sandbox",
			"icon": "../assets/contiinex/image/viewInArIcon1.svg",
			"isAccessed": false,
			"name": "Sandbox",
			"path": "/Contiinex-sandbox",
			"project": "comnex"
		},
		{
			"data_id": "contiinex-campaign",
			"icon": "../assets/contiinex/image/campaignIcon1.svg",
			"isAccessed": false,
			"name": "Campaign",
			"path": "/contiinex-campaign",
			"mat_icon": "campaign",
			"project": "comnex"
		}, {
			"data_id": "shopify-dashboard",
			"icon": "../assets/contiinex/image/dashboardShopifyIcon1.svg",
			"isAccessed": false,
			"name": "Dashboard",
			"path": "/Shopify-Dashboard",
			"project": "shopify"
		}
		, {
			"data_id": "shopify-team",
			"icon": "../assets/contiinex/image/teamShopifyIcon1.svg",
			"isAccessed": false,
			"name": "Team",
			"path": "/Shopify-Team",
			"project": "shopify"
		}, {
			"data_id": "shopify-billing",
			"icon": "../assets/contiinex/image/billingShopifyIcon1.svg",
			"isAccessed": false,
			"name": "Billing",
			"path": "/Shopify-Billing",
			"project": "shopify"
		}, {
			"data_id": "shopify-setting",
			"icon": "../assets/contiinex/image/badgeIcon1.svg",
			"isAccessed": false,
			"name": "Setting",
			"path": "/Shopify-Settings",
			"project": "shopify"
		},
		{
			"data_id": 'LC-Coordinator',
			"name": 'Fulfillment Co-ordinator',
			"path": "/LC-Coordinator",
			"isAccessed": false,
			"mat_icon": "admin_panel_settings",
			"icon": "../assets/web_images/callsetting.svg"//"group_add"
		},
		{
			"data_id": 'settings',
			"name": 'Settings',
			"path": "/settings",
			"isAccessed": false,
			"mat_icon": "settings",
			"icon": "../assets/web_images/gear-solid.svg"//"group_add"
		},
		{
			"data_id": 'settings.api_rules',
			"parent_id": "settings",
			"name": 'API Rules',
			"path": "/settings/api_rules",
			"mat_icon": "api",
			"isAccessed": false,
			"icon": "../assets/web_images/gear-solid.svg"//"group_add"
		}


		//comnext url end 
	]
	public auditClientData: any = {
		all: true,
		production_group: "",
		client_id: ""
	}

	public campaigndetails: any;

	local_storage_variables: string[] = ['access_token', 'my_team', 'full_team', 'settings', 'user', 'my_pages', 'call_form', 'api_end_point', 'project_r', 'project', 'current_time_range'];

	localVarStore: string[] = ['platformApi'];

	speicalpermission_users: any = [44543];

	local_bidashboard: string[] = ['dashbaord', 'groupid', 'id'];

	public current_time_range: any;
	public current_business_phone: string = null;
	constructor(
		private router: Router,
		private API: ApiService
	) { }
	isPermission(): boolean {
		return true;
	}
	/* ainex demo users login started */
	is_ainex_demo_user(email: string, password: string) {
		const demo_user = this.ainex_demo_users.filter(el => el.user_name == email);
		// console.log('user exists', demo_user)
		if (demo_user && demo_user.length && demo_user[0]['password'] == password) {
			return demo_user[0];
		}
		return;
	}
	/* ainex demo users login ende */
	/* Local Storage Functions starts */
	setStorageItem(key: string, val: any) {
		try {
			sessionStorage.setItem(key, val);
		} catch (e) {
			console.log('Error in storing the value in the settings', e);
		}
	}
	getStorageItem(key: string) {
		try {
			let item = sessionStorage.getItem(key);
			if (item) {
				return item;
			}
			return false;
		} catch (e) {
			// debugger;
			return false;
		}
	}
	clearStorageItem(key: string) {
		sessionStorage.removeItem(key)
	}
	clearAllItems() {
		sessionStorage.clear();
		// localStorage.clear();
		for (var i in this.local_storage_variables) {
			this.clearStorageItem(this.local_storage_variables[i]);
		}
	}
	/* Local Storage Functions ends */
	/* Check Each data value */
	check_access_token(): boolean {
		let token = this.getStorageItem(this.local_storage_variables[0]);
		if (token) {
			return true;
		}
		return false;
	}
	check_my_team(): boolean {
		// let team = this.getStorageItem(this.local_storage_variables[1]);
		let team = this.get_my_team();
		if (team) {
			return true;
		} else {
			//console.log('calling service team api');
			if (this.isLoggedIn()) {
				var api_end_point = this.get_API_End_Point();
				this.API.teamAPI(api_end_point).subscribe((res: any) => {
					// this.setStorageItem(this.local_storage_variables[1], res);
					console.log('setting my team', res);
					this.set_my_team(res);
					return true;
				}, (err) => {
					alert('Team details not found');
					return false;
				})
			}
		}
		return false;
	}
	check_full_team(): boolean {
		let team = this.getFullTeam();
		if (team) {
			// console.log('checking full team exists', team);
			return true;
		} else {
			// console.log('calling service full team api', team);
			if (this.isLoggedIn()) {
				var api_end_point = this.get_API_End_Point();
				this.API.getFullTeamData(api_end_point).subscribe((res: any) => {
					if (res.success) {
						let p = res.success;
						// console.log('p team', p);
						this.set_Full_team(p);
						// this.setStorageItem(this.local_storage_variables[2], JSON.stringify(_.groupBy(p, 'id')));
						return true;
					} else {
						return false;
					}
				}, (err) => {
					alert('Team details not found');
					return false;
				})
			}
		}
		return false;
	}
	getSettings(key?: string) {
		// var p = this.getStorageItem(this.local_storage_variables[3]);
		// var p:string = this.get_stage_settings();
		var q = this.get_stage_settings();
		// console.log('settings read', p);
		try {
			if (key && q.hasOwnProperty(key)) {
				return q[key];
			}
			return null;
		} catch (e) {
			return null;
		}
	}
	getSettings_by_type_name(type: string, name: string) {
		try {
			// var p = this.getStorageItem(this.local_storage_variables[3]);
			var q = this.get_stage_settings();
			if (q.hasOwnProperty(type)) {
				const val = q[type].filter(el => el.name == name);
				if (val && val.length) {
					return val[0]
				}
			}
			return null;
		} catch (e) {
			return null
		}
	}
	getSettingsWithKeyValue(type: string, key: string, val: string) {
		// var p = this.getStorageItem(this.local_storage_variables[3]);
		var q = this.get_stage_settings();
		// console.log('settings received', p);
		try {
			if (q.hasOwnProperty(type)) {
				let v = _.groupBy(q[type], key);
				if (v.hasOwnProperty(val)) {
					return v[val][0];
				}
			}
			return null;
		} catch (e) {
			return null;
		}
	}
	check_settings(): boolean {
		// let settings = this.getStorageItem(this.local_storage_variables[3]);
		let settings = this.get_stage_settings();
		// console.log('settings in sessionstorage', settings)
		if (settings) {
			return true;
		} else {
			//console.log('calling settings api');
			if (this.isLoggedIn()) {
				var api_end_point = this.get_API_End_Point();
				this.API.settingsAPI(api_end_point).subscribe((res: any) => {
					if (res) {
						if (res.success) {
							//console.log('settings update instant', res);
							this.web_settings = res;
							this.set_stage_settings(res.success);
							// this.setStorageItem(this.local_storage_variables[3], JSON.stringify(res.success));
							return true;
						} else {
							return false;
						}
					} else {
						return false;
					}
				}, (err) => {
					alert('Settings not found');
					return false;
				})
			}
		}
		return false;
	}
	check_user(): boolean {
		let user = this.getStorageItem(this.local_storage_variables[4]);
		if (user) {
			return true;
		}
		return false;
	}
	check_mypages(): boolean {
		let pages = this.getStorageItem(this.local_storage_variables[5]);
		if (pages) {
			return true;
		} else {
			return false;
		}
	}
	/* Checking each data value ends */
	/* 
	  Token details methods
	*/
	set_access_token(token: string) {
		// this.access_token = token;
		this.setStorageItem(this.local_storage_variables[0], token);
	}
	get_access_token() {
		let loc_token = this.getStorageItem(this.local_storage_variables[0]);
		if (loc_token) {
			return loc_token
		} else if (this.access_token) {
			return this.access_token;
		} else {
			alert('Your session got expired. Please login again.');
			this.router.navigate(['/Login']);
			return;
		}
	}


	isLoggedIn() {
		return !!this.getStorageItem(this.local_storage_variables[0]);
	}

	/* 
	  LoggedIn User details
	*/
	set_loggedin_user(obj: any) {
		this.setStorageItem(this.local_storage_variables[4], JSON.stringify(obj))
		this.login_user = obj;
	}
	get_loggedin_user() {
		let p = this.getStorageItem(this.local_storage_variables[4]);
		if (p) {
			return JSON.parse(p)
		}
	}

	/* 
	  Team details caputre
	*/
	set_my_team(obj: any) {
		if(obj){
			this.team_details = obj;
			this.MY_TEAM_DATA.next(JSON.stringify(obj));
		}
		/* try {
			this.setStorageItem(this.local_storage_variables[1], obj);
			console.log('team storage completed')
		} catch (e) {
			console.log('Local storage error teams', e);
		} */
	}
	get_my_team() {
		return this.MY_TEAM_DATA.getValue();
		// return this.getStorageItem(this.local_storage_variables[1]);
	}

	getSupervisorsData(api_end_point) {
		return new Promise((resolve, reject) => {
			//var api_end_point = this.get_API_End_Point();
			console.log(api_end_point);
			this.API.getSupervisors(api_end_point).subscribe((res: any) => {
				if (res.errors) {
					console.log('Error in the API response', res.errors)
				}
				if (res.success) {
					console.log(JSON.stringify(res.success));
					//this.SUPERVISOROBJ =null;
					this.SUPERVISOROBJ.next(res.success);
				}
				resolve(true)
			})
		})
	}
	groupwise_getSupervisorsData(groupid, api_end_point) {
		return new Promise((resolve, reject) => {
			var api_end_point = this.get_API_End_Point();
			//console.log(api_end_point);
			let groupid = this.get_asm_groupid();
			let group_id = {
				for_group: groupid
			}

			//console.log(group_id.for_group);
			this.API.getSupervisors_for_group(group_id, api_end_point).subscribe((res: any) => {
				if (res.errors) {
					console.log('Error in the API response', res.errors)
					reject(true);
				}
				if (res.success) {
					//this.set_asm_groupdata(group_data);
					//console.log(JSON.stringify(res.success));
					this.SUPERVISOROBJ.next(res.success)

					resolve(true)
				}

			})
		})
	}
	/* 
	 Settings details capture
	*/
	set_stage_settings(obj: any) {
		try {
			this.web_settings = obj;
			this.SETTINGS.next(JSON.stringify(obj));
			// this.setStorageItem(this.local_storage_variables[3], JSON.stringify(obj));
			this.setMyPages(obj);
			// console.log('storing settings', obj);
		} catch (e) {

		}
	}
	get_stage_settings():string {
		const settings:string = this.SETTINGS.getValue();
		if(settings){
			return JSON.parse(settings);
		}
		return null;
		/* let s = null;
		this.settings.subscribe(
			(d) => {
				s = d;
			}
		)
		if (Object.keys(s).length == 0 || s == null) {
			s = this.getStorageItem(this.local_storage_variables[3]);
			s = s == null ? {} : JSON.parse(s);
		}
		return s; */
	}

	get_swift_status(): any {
		try {
			const settings:any = this.get_stage_settings();
			if (!settings || !settings.extraoptions) {
				throw new Error('Settings not found');
			}
			const swit_status = settings.extraoptions.find(e => e.name == 'swift_status');
			if (!swit_status) {
				throw new Error('Swift Status not found');
			}
			return JSON.parse(swit_status.definition);
		} catch (e) {
			console.log('Error in fetching swift status', e);
			return
		}
	}

	/*  Setting full team data group by id */
	set_Full_team(obj: any) {
		let a = _.groupBy(obj, 'id');
		// console.log('full team a', a);
		this.FULL_TEAM_DATA.next(a);
		// this.setStorageItem(this.local_storage_variables[2], JSON.stringify(a));
		// debugger;
	}

	getFullTeam() {
		return this.FULL_TEAM_DATA.getValue();
		/* let p = this.getStorageItem(this.local_storage_variables[2]);
		if (p) {
			return JSON.parse(p)
		} else {
			return false;
		} */
	}

	getAgentObject(id) {
		var r = null;
		let d = this.getFullTeam();
		// let d = this.FULL_TEAM_DATA.getValue();
		if (d) {
			if (d.hasOwnProperty(String(id))) {
				r = d[String(id)][0];
			}
		}
		// debugger;
		return r;
	}

	getOnlyMyTeamIds() {
		//debugger;
		var r = [];
		let d: any = this.get_my_team();
		//console.log(d.success);
		// if (typeof d == 'string') {
		// 	d = JSON.parse(d);
		// 	console.log(d);
		// }
		//console.log(d);
		if (d.success && d.success.groups && d.success.groups.length) {
			var obj = {};
			for (var i in d.groups) {
				r.push({
					"id": d.success.groups[i]['id'],
					"email": d.success.groups[i]['email'],
					"name": d.success.groups[i]['name'],
					"phone": d.success.groups[i]['phone'],
					"role": d.success.groups[i]['role'],
					"work_option": d.success.groups[i]['work_option']
				})
			}
		}
		return r;
	}

	getMyDetails() {
		let myUser = this.get_loggedin_user();
		if (myUser) {
			if (typeof myUser === 'string') {
				myUser = JSON.parse(myUser);
			}
			return myUser;
		}
		return null;
	}



	getAllIds(role?: string, key?: string, includeMe?: boolean) {
		var r = [];
		let myUser = this.get_loggedin_user();
		let myTeam: any = this.get_my_team();
		// console.log('myTeam', typeof myTeam, myTeam);
		if (typeof myTeam == 'string' && myTeam.length) {
			myTeam = JSON.parse(myTeam);
		}
		if (myTeam.groups && myTeam.groups.length == 0) {
			return [];
		}

		function getSubTeam(team_group) {
			for (const loc of team_group) {
				if (role) {
					if (loc.role == role) {
						r.push((key) ? loc[key] : loc);
					}
				} else {
					r.push((key) ? loc[key] : loc);
				}
				if (loc.groups && loc.groups.length) {
					getSubTeam(loc.groups);
				}
			}
		}
		// debugger;
		if (includeMe) {
			if (role) {
				if (myUser.role == role) {
					r.push((key) ? myUser[key] : myUser);
				}
			} else {
				r.push((key) ? myUser[key] : myUser);
			}
		}
		if (myTeam && myTeam.success && myTeam.success.groups && myTeam.success.groups.length) {
			try {
				getSubTeam(myTeam.success.groups);
			} catch (e) {
				return r;
			}
		}
		return r;
	}
	getAllAgentsByLevel(level: string, key?: string) {
		var r = [];
		let d = this.getFullTeam();
		if (d) {
			for (var i in d) {
				let loc = d[i][0];
				if (loc['project'] == level) {
					if (key) {
						r.push(loc[key])
					} else {
						r.push(loc);
					}
				}
			}
		}
		return r;
	}

	checkGroupsandsubgroups(obj) {
		if (obj.groups && obj.groups.length) {
			return true;
		} else {
			return false;
		}
	}

	getendUserObject(obj, arr) {
		// console.log('method called');
		if (this.checkGroupsandsubgroups(obj)) {
			for (var i in obj.groups) {
				if (obj.groups[i]['role'] == 'user') {
					arr.push(obj);
					break;
				} else {
					if (this.checkGroupsandsubgroups(obj.groups[i])) {
						this.getendUserObject(obj.groups[i], arr)
					}
				}
			}
		}
		return arr;
	}

	getTLandUsers() {
		let r = [];
		let d: any = this.get_my_team();
		if (typeof d == 'string') {
			d = JSON.parse(d);
		}
		if (d && d.success && d.success.groups) {
			try {
				return this.getendUserObject(d.success, r);
			} catch (e) {
				return r;
			}
		}
	}
	prepareTreeNodeObj(data_obj) {
		var obj = {
			"name": data_obj['name'],
			"id": data_obj['id'],
			"email": data_obj['email'],
			"phone": data_obj['phone'],
			"groupid": data_obj['groupid'],
			"role": data_obj['role'],
			"work_option": data_obj['work_option'],
			"active_status": data_obj['active_status'],
			"business_process": data_obj['business_process'],
			"active_campaign": data_obj['active_campaign'],
			"teamid": data_obj['teamid']
		};
		if (data_obj['groups'] && data_obj['groups'].length) {
			obj['children'] = []
			for (var i in data_obj['groups']) {
				let p = data_obj['groups'][i];
				// console.log('p', p);
				obj['children'].push(this.prepareTreeNodeObj(p));
			}
		}
		return obj;
	}

	getUserGroupTreeNode() {
		let d = this.get_my_team();
		//console.log(JSON.stringify(d));
		if (typeof d == 'string') {
			d = JSON.parse(d);
		}
		console.log('printing team', d);
		let r = [];
		if (d && d.hasOwnProperty('success')) {
			var obj = d['success']['me'];
			obj.groups = d['success']['groups'];
			return this.prepareTreeNodeObj(obj);
		} else {
			return null;
		}
	}

	/* Access Web Pages starts */
	setAccessPages(s: any) {
		this.setStorageItem(this.local_storage_variables[5], JSON.stringify(s));
	}
	getAccessPages() {
		let p = this.getStorageItem(this.local_storage_variables[5]);
		if (p) {
			return JSON.parse(p)
		} else {
			return false;
		}
	}
	setMyPages(settings?) {
		//	debugger;
		// let settings: any = this.getStorageItem(this.local_storage_variables[3]);
		// let settings:any = this.get_stage_settings();
		if(!settings){
			settings = this.get_stage_settings();
		}
		console.log('settings', typeof settings, settings);
		let my_pages = null;
		if (settings) {
			try {
				// settings = JSON.parse(settings);
				//console.log('settings rootgaurd', settings);
				if (settings && settings.hasOwnProperty('web')) {
					for (var i in settings['web']) {
						if (settings.web[i]['name'] == 'pages') {
							if (settings.web[i]['project'] == 'comnex') {
								my_pages = settings.web[i]['definition'].split(',');
								//console.log('total pages', this.total_pages);
								//console.log('my pages', my_pages);
								for (var i in this.total_pages) {
									if (my_pages.indexOf(this.total_pages[i]['data_id']) > -1 && this.total_pages[i]['project'] == 'comnex') {
										this.total_pages[i]['isAccessed'] = true;
									}
								}
								break;
							}
							else if (settings.web[i]['project'] == 'shopify') {
								my_pages = settings.web[i]['definition'].split(',');
								//console.log('total pages', this.total_pages);
								//console.log('my pages', my_pages);
								for (var i in this.total_pages) {
									if (my_pages.indexOf(this.total_pages[i]['data_id']) > -1 && this.total_pages[i]['project'] == 'shopify') {
										this.total_pages[i]['isAccessed'] = true;
									}
								}
								break;
							}
							else {
								my_pages = settings.web[i]['definition'].split(',');
								//console.log('total pages', this.total_pages);
								//console.log('my pages', my_pages);
								for (var i in this.total_pages) {
									if (my_pages.indexOf(this.total_pages[i]['data_id']) > -1) {
										this.total_pages[i]['isAccessed'] = true;
									}
								}
								break;
							}
						}
					}
					// console.log('store total pages', this.total_pages);
					this.setAccessPages(this.total_pages.filter(e => e.isAccessed));
					//console.log('my pages', my_pages);
					//console.log('total pages', this.total_pages);
				}
			} catch (e) {
				console.log('error in root garud', e);
			}
			return this.total_pages.filter(page => page.isAccessed);
		}else{
			return null
		}		
	}
	update_stage_settings_work_hours(data) {
		let obj = null;
		// obj = this.getStorageItem(this.local_storage_variables[3]);
		obj = this.get_stage_settings()
		obj = JSON.parse(obj);
		//console.log(obj)
		if (obj) {
			obj.workhours = data;
			// this.setStorageItem(this.local_storage_variables[3], JSON.stringify(obj));
			this.set_stage_settings(obj);
		}
		return obj;
	}
	/* Access Web pages ends */
	setSelectedAuditClient(obj) {
		this.auditClientData = obj;
	}
	getSelectedAuditCLient() {
		return this.auditClientData
	}

	auditClientGetRequestParam() {
		//console.log(this.auditClientData)
		var data = this.auditClientData;
		let req = "?";
		req = req + `production_group=${this.auditClientData.production_group}&superadmin_id=${this.auditClientData.superadmin_id}`
		if (this.auditClientData.all == false) {
			req = req + `&client_id=${this.auditClientData.client_id}`
		}
		//console.log(req)
		return req;
	}

	getCallForm(): any {
		let form = this.getStorageItem(this.local_storage_variables[6]);
		if (form) {
			return JSON.parse(form);
		}
		return null;
	}

	saveCallForm(obj): any {
		this.setStorageItem(this.local_storage_variables[6], JSON.stringify(obj));
	}

	updateCallStatus(obj: any) {
		this.call_status = obj;
		this.CALL_STATUS.next(obj);
	}

	clearCallStatus() {
		//console.log("home--", "clearing")
		this.call_status = {};
		this.CALL_STATUS.next({});
	}

	getLastCallStatus() {
		return this.call_status;
	}

	getCallStatus(): Observable<any> {
		return this.CALL_STATUS.asObservable();
	}

	updateNotificationStatus(obj: any) {
		this.NOTIFICATION_STATUS.next(obj);
	}

	clearNotificationStatus() {
		this.NOTIFICATION_STATUS.next({});
	}

	getNotificationStatus(): Observable<any> {
		return this.NOTIFICATION_STATUS.asObservable();
	}
	/* Side Menu collapsed status */
	update_collapse_status(val: boolean) {
		this.MENU_COLLAPSE_STATUS.next(val);
	}
	get_collapse_status(): boolean {
		return this.MENU_COLLAPSE_STATUS.getValue();
	}
	renameStatusMessage(msg: string) {
		let p = "";
		if (msg) {
			msg = msg.trim();
		}
		switch (msg) {
			case 'signin':
				p = 'Login';
				break;
			case 'signout':
				p = 'Logout';
				break;
			case 'radstarted':
				p = 'Active';
				break;
			case 'radstopped':
				p = 'Break';
				break;
			case 'DIAL_REQUEST':
				p = "Dialing"
				break;
			case 'CALL_CONNECT':
				p = "Connecting";
				break;
			case 'CALL_PATCH':
				p = "Talking"
				break;
			case 'CALL_DISCONNECT':
				p = "Form";
				break;
			case 'FORM_SUBMIT':
				p = "Active"
				break;
			case 'BARGE':
				p = "Barge"
				break;
			case 'WHISPER':
				p = "Whisper"
				break;
			default:
				break;
		}
		return p;
	}
	renameCallStatusMessage(msg: string) {
		let p = "";
		switch (msg) {
			case 'DIAL_REQUEST':
				p = "Ringing"
				break;
			case 'CALL_CONNECT':
			case 'CALL_PATCH':
				p = "Talking"
				break;
			case 'CALL_DISCONNECT':
				p = "Form";
				break;
			case 'FORM_SUBMIT':
				p = "Available"
				break;
			case 'BARGE':
				p = "Barge"
				break;
			case 'WHISPER':
				p = "Whisper"
				break;
			default:
				break;
		}
		return p;
	}
	getStatusColour(msg: string) {
		let p = "Inherit";
		switch (msg) {
			case 'Login':
				p = '#146ED0'
				break;
			case 'Logout':
				p = "#D5A20B"
				break;
			case 'Active':
				p = "#3B8F4E";
				break;
			case 'Break':
				p = "#C74450"
				break;
			case 'On Call':
			case 'Taliking':
			case 'Ringing':
			case 'Form':
			case 'Available':
			case 'Barge':
			case 'Whisper':
				p = "#8B008B";
				break;
		}
		return p;
	}

	set_current_project(obj: any) {
		this.setStorageItem(this.local_storage_variables[8], JSON.stringify(obj))
		this.current_project = obj;
	}

	get_current_project() {
		let p = this.getStorageItem(this.local_storage_variables[8]);
		if (p) {
			return JSON.parse(p)
		}
	}
	/* 
	Set API End Point
	*/
	set_API_End_Point(obj: any) {
		//debugger;
		this.setStorageItem(this.local_storage_variables[7], JSON.stringify(obj))
	}
	get_API_End_Point() {
		let p = this.getStorageItem(this.local_storage_variables[7]);
		if (p) {
			return JSON.parse(p)
		}
	}



	set_API_Platform1(obj: any) {
		//debugger;
		this.setStorageItem(this.localVarStore[0], JSON.stringify(obj))
	}
	get_API_Platform1() {
		let p = this.getStorageItem(this.localVarStore[0]);
		if (p) {
			return JSON.parse(p)
		}
	}
	/* 
	  Set API End Point
	*/

	endSession() {
		//	debugger;
		var myUser = this.get_loggedin_user();
		if (myUser) {
			this.API.remoteDialStop(myUser.id, myUser.server_url, "").subscribe(
				(res: any) => {
					//debugger;
					this.clearAllItems();
					window.sessionStorage.clear();
					if (window.location.href.includes('dev.youearn.in')) {
						window.location.href = 'https://dev.contiinex.com/';
					} else {
						window.location.href = 'https://contiinex.com/';
					}

					// window.localStorage.clear();
				}, (err) => {
					this.clearAllItems();
					window.sessionStorage.clear();
					if (window.location.href.includes('dev.youearn.in')) {
						window.location.href = 'https://dev.contiinex.com/';
					} else {
						window.location.href = 'https://contiinex.com/';
					}
				}
			)
		} else {
			if (window.location.pathname == "/home" || window.location.pathname == "/Contactus" || window.location.pathname == "/Comnex" || window.location.pathname == "/Gignex" || window.location.pathname == "/Ainex" || window.location.pathname == "/signin") {
				return;
			}
			this.clearAllItems();
			window.sessionStorage.clear();
			if (window.location.href.includes('dev.youearn.in')) {
				window.location.href = 'https://dev.contiinex.com/';// 'https://dev.contiinex.com/Contiinex/signin';
			} else {
				window.location.href = 'https://contiinex.com/';// 'https://contiinex.com/Contiinex/signin';
			}
			// window.localStorage.clear();
		}

	}

	set_campaign_detail(obj: any) {
		//console.log(JSON.stringify(obj));
		//this.campaigndetails=obj;
		// this.setStorageItem(this.local_storage_variables[8], JSON.stringify(obj))
		this.selected_campaign_details = JSON.stringify(obj);
	}
	clear_campaign_details() {
		// this.clearStorageItem(this.local_storage_variables[8]);
		this.selected_campaign_details = null;
	}
	get_campaign_detail() {
		// let campaign_detail = this.campaigndetails;
		// console.log("--got-the data-"+campaign_detail);
		/* let p = this.getStorageItem(this.local_storage_variables[8]);
		if (p) {
			return JSON.parse(p)
		} */
		if (this.selected_campaign_details) {
			return JSON.parse(this.selected_campaign_details)
		}
		return null;
	}

	setCentralTeamRelation(obj) {
		//console.log('centralteam relation', obj);
		if (obj) {
			this.CENTRAL_TEAM_RELATION.next(obj);
		}
	}
	getCentralTeamRelation() {
		return this.CENTRAL_TEAM_RELATION.asObservable();
	}

	setCentralRoles() {
		const loginUser = this.get_loggedin_user();
		if (loginUser.role == 'superadmin') {
			this.CENTRAL_BI_ROLES.next(['ZSM', 'RSM', 'ASM']);
			this.SELECTED_BI_ROLE.next('ZSM');
		} else if (loginUser.role == 'admin') {
			this.CENTRAL_BI_ROLES.next(['RSM', 'ASM']);
			this.SELECTED_BI_ROLE.next('RSM');
		} else {
			this.CENTRAL_BI_ROLES.next(['ASM']);
			this.SELECTED_BI_ROLE.next('ASM');
		}
	}
	initddlSelection() {
		let relation = this.CENTRAL_TEAM_RELATION.getValue();
		let currentDD = this.ddlSelection.getValue();
		let zsmGroup = _.groupBy(relation, 'zsm_id');
		let rsmGroup = _.groupBy(relation, 'rsm_id');
		let asmGroup = _.groupBy(relation, 'asm_id');
		Object.keys(zsmGroup).forEach(zsmId => {
			currentDD.zsm[zsmId] = { id: zsmId, visible: true, name: zsmGroup[zsmId][0]['zsm_name'] };
		});
		Object.keys(rsmGroup).forEach(rsmId => {
			currentDD.rsm[rsmId] = { id: rsmId, visible: true, name: rsmGroup[rsmId][0]['rsm_name'] };
		});
		Object.keys(asmGroup).forEach(asmId => {
			currentDD.asm[asmId] = { id: asmId, visible: true, name: asmGroup[asmId][0]['asm_name'] };
		});
		this.ddlSelection.next(currentDD);
	}

	async updateddlSelection(id, role: string, value: boolean) {
		let relation = this.CENTRAL_TEAM_RELATION.getValue();
		let currentDD = this.ddlSelection.getValue();
		currentDD = await this.updateSelection(currentDD, relation, id, role, value);
		this.ddlSelection.next(currentDD);
	}

	updateSelection(currentDD, relation, id, role: string, value: boolean) {
		switch (role) {
			case 'ZSM':
				if (currentDD.zsm[id]) {
					let group: any = _.groupBy(relation, 'zsm_id');
					currentDD.zsm[id].visible = value;
					let rsm_group = _.groupBy(group[id], 'rsm_id');
					Object.keys(rsm_group).forEach(rsm => {
						this.updateSelection(currentDD, relation, rsm, 'RSM', value);
					});
				}
				break;
			case 'RSM':
				if (currentDD.rsm[id]) {
					let group: any = _.groupBy(relation, 'rsm_id');
					currentDD.rsm[id].visible = value;
					Object.keys(group[id]).forEach(asm => {
						this.updateSelection(currentDD, relation, asm, 'ASM', value);
					});
				}
				break;
			case 'ASM':
				if (currentDD.asm[id]) {
					currentDD.asm[id].visible = value;
				}
				break;
		}
		return currentDD;
	}

	setdevcomnexPages(obj: any) {
		// this.setStorageItem(this.local_storage_variables[3], JSON.stringify(obj))
		return;
	}
	getdevcomnexPages() {
		// let p = this.getStorageItem(this.local_storage_variables[3]);
		var p = this.get_stage_settings();
		if (p) {
			return JSON.parse(p)
		}
	}

	set_current_time_range(obj: any) {
		this.setStorageItem(this.local_storage_variables[11], JSON.stringify(obj))
		this.current_time_range = obj;
	}
	set_current_business_phone(obj: any) {
		this.setStorageItem(this.local_storage_variables[10], JSON.stringify(obj))
		this.current_business_phone = obj;
	}

	get_current_business_phone() {
		let p = this.getStorageItem(this.local_storage_variables[10]);
		if (p) {
			return JSON.parse(p)
		}
	}
	get_current_time_range() {
		let p = this.getStorageItem(this.local_storage_variables[11]);
		if (p) {
			return JSON.parse(p)
		}
	}

	set_speical_access_users(obj: any) {
		this.setStorageItem(this.speicalpermission_users[0], JSON.stringify(obj))
	}
	get_speical_access_users() {
		let p = this.getStorageItem(this.speicalpermission_users[0]);
		if (p) {
			return JSON.parse(p)
		}
	}





	updateCallSession(obj: any) {
		this.call_session = obj;
		this.CALL_SESSION.next(obj);
	}

	clearCallSession() {
		console.log("home--", "clearing")
		this.call_session = {};
		this.CALL_SESSION.next({});
	}

	getLastCallSession() {
		return this.call_session;
	}

	getCallSession(): Observable<any> {
		return this.CALL_SESSION.asObservable();
	}



	updateChatStatus(obj: any) {
		this.chat_status = obj;
		this.CHAT_STATUS.next(obj);
	}

	clearChatStatus() {
		console.log("chat--", "clearing")
		this.chat_status = {};
		this.CHAT_STATUS.next({});
	}

	getLastChatStatus() {
		return this.chat_status;
	}

	getChatStatus(): Observable<any> {
		return this.CHAT_STATUS.asObservable();
	}

	set_bidashboard(obj: any) {
		this.setStorageItem(this.local_bidashboard[0], JSON.stringify(obj))
	}
	get_bidashboard() {
		let p = this.getStorageItem(this.local_bidashboard[0]);
		if (p) {
			return JSON.parse(p)
		}
	}
	set_asm_groupid(obj: any) {
		this.setStorageItem(this.local_bidashboard[2], JSON.stringify(obj))
	}
	get_asm_groupid() {
		let p = this.getStorageItem(this.local_bidashboard[2]);
		if (p) {
			return JSON.parse(p)
		}
	}
	set_asm_groupdata(obj: any) {
		this.setStorageItem(this.local_bidashboard[1], JSON.stringify(obj))
	}
	get_asm_groupdata() {
		let p = this.getStorageItem(this.local_bidashboard[1]);
		if (p) {
			return JSON.parse(p)
		}
	}
	remove_previous_groupdata() {
		//localStorage.removeItem(this.local_bidashboard[1]);
		sessionStorage.removeItem(this.local_bidashboard[1]);
	}
	remove_previous_groupid() {
		sessionStorage.removeItem(this.local_bidashboard[2]);
	}
}
