import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
	selector: 'app-generic-outbound-notification-dialog',
	templateUrl: './generic-outbound-notification-dialog.component.html',
	styleUrls: ['./generic-outbound-notification-dialog.component.scss']
})
export class GenericOutboundNotificationDialogComponent implements OnInit {

	viewRecord: any = [];
	tab: any = 0
	isShowCustomNotification: boolean = false;
	//isShowSwitchActiveNotification: boolean = false;
	//isShowSubmit: boolean = false;
	settings: any = "";
	myUser:any;
	_settingNotifcn: any;
	_infoAlert: any = "";
	descriptionModel: any = "";
	_titleModelValue:any;
	access_token:any;
	api_end_point: any;
	constructor(public dialogRef: MatDialogRef<GenericOutboundNotificationDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData, private myService: MyserviceService,	private API: ApiService,private _snackBar: MatSnackBar,) { }

	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		this.settings = this.myService.get_stage_settings();
		this.myUser = this.myService.get_loggedin_user();
		this.access_token = this.myService.get_access_token();
		this._settingNotifcn = [];
		this.getNotificationDataFromSettings();
		this.viewRecord = this.viewData;

	}
	getNotificationDataFromSettings() {
		let _filterNotifcn = [];
		let _settingForNotification = this.settings;
		if (this.settings && this.settings.extraoptions) {
			_filterNotifcn = _settingForNotification.extraoptions.filter(v => { return v.name == 'notifications_data' });
			this._settingNotifcn = JSON.parse(_filterNotifcn[0].definition);
		}
	}

	titleDroDownChange(val: any) {
		this._titleModelValue="";
		this._titleModelValue=val;
		this._infoAlert = "";
		this.descriptionModel = "";
		let _switchToActive = [];
		_switchToActive = this._settingNotifcn.filter(a => { return a.Notification_Title == val });
		this._infoAlert = _switchToActive[0].Alert_Information;
		setTimeout(()=>{
			this.descriptionModel = _switchToActive[0].Notification_Information;
		},200)
		
	
	}
	sendBtn(){
		let _objModel={
		"for_user":this.viewRecord.user_id,
		"siopush":true,
		"source":this.myUser.name,
		"payload": {
			"message": this._titleModelValue,
			"data": {
				"store_notification": true,
				"message": this.descriptionModel,
				"title":this._titleModelValue
			}
		}
	}

	this.API.sendPushNotification(_objModel,this.access_token,this.api_end_point).subscribe((data:any)=>{
		if(data && data.success){
			this.dialogRef.close('true');
			this._snackBar.open(data.success, "close", {
				duration: 4000,
			});
		}
	});
	
	}
	texAreaChange(){		
		let val = (document.getElementById('textAreaValId') as HTMLFormElement).value;
		this.descriptionModel=val;
	}
	closeDialog(type) {
		this.dialogRef.close(type);
	}
	// clickNotificationBtn(type) {
	// 	this.tab = type;
	// 	this.isShowSubmit = true;
	// 	if (type == 1) {
	// 		this.isShowCustomNotification = false;
	// 		this.isShowSwitchActiveNotification = true;
	// 	}
	// 	if (type == 2) {
	// 		this.isShowCustomNotification = true;
	// 		this.isShowSwitchActiveNotification = false;;
	// 	}

	// }


	// isActiveNotification(tabId): boolean {
	// 	return this.tab === tabId;
	// }

}
