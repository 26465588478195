import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { Observable, BehaviorSubject, Subject, Subscription } from "rxjs";
import { IStreamState } from '../../interface/stream-state.interface';
import { IAudioDialogData } from '../../interface/audio-dialog-data.interface';
import { AudioserviceService } from '../../audioservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface StreamState {
	playing: boolean;
	readableCurrentTime: string;
	readableDuration: string;
	duration: number | undefined;
	currentTime: number | undefined;
	canplay: boolean;
	error: boolean;
	volume: number;
	isMute: boolean;
}
@Component({
	selector: 'app-audio-followup-dialog',
	templateUrl: './audio-followup-dialog.component.html',
	styleUrls: ['./audio-followup-dialog.component.scss']
})
export class AudioFollowupDialogComponent implements OnInit {
	private _subscription = new Subscription();
	state: IStreamState;
	selectedday: any;
	daywise: any = [];
	audioplay:any;
	playaudio: boolean = false;
	dayWiseThreeCalls:any=[];
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IAudioDialogData,
		private _dialogRef: MatDialogRef<AudioFollowupDialogComponent>,
		private _audioService: AudioserviceService, private _snackBar: MatSnackBar
	) { 
		this._subscribePlayStream("");
		this._subscribeAudioState();
		this._audioService._resetState();
	}

	ngOnInit(): void {
		this._subscribeAudioState();
		//console.log(this.data)
		this.daywise = this.data;
		if(this.daywise){
		  this.dayWiseThreeCalls=  this.daywise.threecalls;
		  if(this.dayWiseThreeCalls=="No Data found"){
			this.dayWiseThreeCalls=[];
		  }
		  else{
			this.selectedday=   this.dayWiseThreeCalls[0].value;
			this.onDaySelection()
		  }
		}
		this.audioplay = this.data.url;
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

	onSliderChangeEnd(change: MatSliderChange): void {
		this._audioService.seekTo(change.value);
	}

	play(): void {
		// this._subscribePlayStream(this.data.url);
		// this._subscribeAudioState();
		if (this.selectedday == "") {
			this._snackBar.open("select", "Close");
		} else {
			this._audioService.play();			
		}
	}

	pause(): void {
		this._audioService.pause();
	}

	close(): void {
		this._audioService.stop();
		this._dialogRef.close({ foo: '123' });
	}
	private _subscribePlayStream(url: string): void {
		const subscription = this._audioService.playStream(url).subscribe();

		this._subscription.add(subscription);
	}

	private _subscribeAudioState(): void {
		const subscription = this._audioService.getState().subscribe((state) => {
			this.state = state;
		});

		this._subscription.add(subscription);
	}
	onDaySelection() {
		//console.log(this.selectedday);
		this.playaudio = true;
		this._subscribePlayStream(this.selectedday);
		this._subscribeAudioState();
	}
}