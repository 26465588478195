<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <app-menu  [navigateLink]="currentTitle" ></app-menu>
    </mat-drawer>
<div class="CN-Main sbPad">
    <p class="CN-Tab-head">Sandbox</p>
    <div>
        <div class="row sandboxTabList">
            <div class="col-md-12" style="padding: 0px 3.5px;">
                <mat-button-toggle-group class="dashSandboxcs" #group="matButtonToggleGroup"
                    [value]="selectedButtonToggleVal" (change)="onToggleButtonValChange(group.value)">
                    <mat-button-toggle value="apiTab">API</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
<div class="mt-4">
<mat-card style="border: 1px solid #ccc;">
    <div class="row">
        <div class="col-md-12" style="padding: 5px;">
            <iframe [src]="urlSandBox" style="height: 400px; width: 100%;height: calc(100vh - 300px); " frameborder="0" allowfullscreen></iframe>
</div>
    </div>
   
</mat-card>
</div>
    </div>
</div>
</mat-drawer-container>

<div id="cover-spin" *ngIf="currentLoader"></div>