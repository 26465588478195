<div class="row">
	<div class="col-md-1 col-sm-1 col-xs-12"></div>
	<div class="col-md-6 col-sm-6 col-xs-12"><img src="../assets/image/contiinexLogoImg.png" class="loginLogoImg" />
	</div>
	<div class="col-md-4 col-sm-4 col-xs-12">
		<p><span class="backs" (click)="backBtn()"> <span> <i class="fa fa-arrow-left" aria-hidden="true"></i>
					&nbsp;&nbsp;</span>Back to Home</span></p>
	</div>
	<div class="col-md-1 col-sm-1 col-xs-12"></div>
</div>
<div class="lgDiv" *ngIf="showHideLoginPageUI">
	<div class="row">
		<div class="col-md-1 col-sm-1 col-xs-12"></div>
		<div class="col-md-5 col-sm-5 col-xs-12">
			<img src="../assets/image/loginBg.png" style="width:100%;position: absolute;bottom: 0;" />
		</div>
		<div class="col-md-4 col-sm-4 col-xs-12">
			<div class="mainLiginDiv">
				<div>
					<mat-radio-group [ngClass]="'radioGigComAinex'" [(ngModel)]="selectedGig"
						(change)="radioChangeGig($event.value)">
						<mat-radio-button [style.backgroundColor]="selectedGig=== 'Gignex' ? '#ff5c53': '#ed372d'"
							value="Gignex" labelPosition="before"><span><img
									style="width:37px;display:block;padding-left: 8px;"
									src="../assets/image/gignexLogo.png" /></span><span class="spanNam"
								style="left:6px;">Gignex</span></mat-radio-button>

						<mat-radio-button [style.backgroundColor]="selectedGig=== 'Comnex' ? '#ff5c53': '#ed372d'"
							value="Comnex" labelPosition="before"><span><img
									style="width:37px;display:block;padding-left: 8px;"
									src="../assets/image/ComnixLogo.png" /></span><span class="spanNam"
								style="left:12px;">Comnex</span></mat-radio-button>

						<mat-radio-button [style.backgroundColor]="selectedGig=== 'Ainex' ? '#ff5c53': '#ed372d'"
							value="Ainex" labelPosition="before"><span><img
									style="width:37px;display:block;padding-left: 8px;"
									src="../assets/image/ainexLogo.png" /></span><span class="spanNam"
								style="left:2px;">Ainex</span></mat-radio-button>
					</mat-radio-group>
				</div>
				<br>
				<div *ngIf="idpassword">
					<p class="login-hd">{{selectedGig}} LOGIN</p>
					<form [formGroup]="createdForm">
						<p class="blankMsg" *ngIf="showHideErrorMsg">{{passMsgText}}</p>
						<p>
						  <span class="login-label"> USER NAME</span>
						  <span> <input class="login-input-text" type="email" placeholder="Login Email ID" [(ngModel)]="loginname" required  formControlName="email" autofocus (keyup.enter)="LoginBtn(createdForm)"/></span>
						</p>
						<p class="mb-0">
						  <span class="login-label"> PASSWORD</span>
						  <span> <input class="login-input-text"  type="password" placeholder="Password" required  formControlName="password" (keyup.enter)="LoginBtn(createdForm)" autocomplete="off"/></span>
						</p>
					<p class="vk-forgotPwd" (click)="forgotPasswordBtn()">Forgot Password</p>
						<p> <input type="checkbox" id="rememberMe" name="rememberme" value="RememberMe">
						  <label for="rememberme" class="remember-label">&nbsp;&nbsp;Remember Me</label>
						</p>
						<p class="text-right">
						  <span class="l-btn" (click)="LoginBtn(createdForm)" (keyup.enter)="LoginBtn(createdForm)">LOGIN <i class="fa fa-arrow-right" style="padding-left: 10px;"></i></span>
						 
						</p><br>
					  </form>
				</div>
				
				<div *ngIf="otpvalidation">
					<p class="login-hd"> OTP</p>
					<form [formGroup]="otpForm">
					<p class="blankMsg" *ngIf="showHideErrorMsg">{{passMsgText}}</p>
					
						<span class="login-label1">Enter One Time Password sent to your Email</span>
						<span> <input class="login-input-text" type="text" id="otp" placeholder="One Time Password" formControlName="otp" required /></span>
						<div *ngIf="f.otp.touched && f.otp.invalid" class="alert alert-danger">
							<div *ngIf="f.otp.errors.required">OTP is required.</div>
							<div *ngIf="f.otp.errors.pattern">Numberic Value Only</div>
							<div *ngIf="f.otp.errors.minlength"> 
								Enter Seven Digit OTP.
							  </div>
							  <div *ngIf="f.otp.errors.maxlength"> 
							  Enter Seven Digit OTP.
							</div>
						</div>
					
					<div>
						<table style="width: 100%;">
							<tr>
								<td>
									<span class="text-left timer">{{clockTimer}}</span>
								</td>
								<td>
									<span class="text-right timersubmit">
										<span class="l-btn1" (click)="otpsbumit(otpForm)">Submit
											<i class="fa fa-arrow-right" style="padding-left: 10px;"></i>
										</span>
									</span>
								</td>
							</tr>
						</table>
					</div>
					<br>
					<p class="login-label1 text-center">If not Received OTP</p>
					<p class="text-center">
						<span class="l-btn2" (click)="resend_otp()">click here to resend the OTP
							<i class="fa fa-arrow-right" style="padding-left: 10px;"></i></span>

					</p><br>
					</form>
				</div>
				<br>
			</div>
		</div>
		<div class="col-md-2 col-sm-2 col-xs-12"></div>
	</div>
</div>

<!--start forgot password ui-->
<div class="row mt-4" *ngIf="showHideForgotPageUI">
	<div class="col-md-12">
		<div class="vk-mainForgotDiv mt-4 text-center">
			<p class="pt-3"><img src="../assets/image/forgotPwdLogo.png" /></p>
			<p class="vk-fwText mb-1">Forgot Password</p>
			<p class="vk-fwDesc">Enter your email and we’ll send you a link<br>
				To reset your password.</p>
			<p class="pt-3"><input type="text" class="vk-fwd-input" (keyup)="validateForgotEmail()"
					placeholder="Enter your email" [(ngModel)]="forgotEmail" /> </p>
			<p *ngIf="showHideForgotMsg"><span class="vk-ercs">{{forgotMsgError}} </span></p>
			<p><button class="vk-fwBtn" (click)="sendForgotEmail()">SUBMIT</button></p>
			<p class="vk-fwBLog"><span (click)="backToLoginBtn()">
					<mat-icon style="vertical-align: bottom;">chevron_left</mat-icon>Back to login
				</span></p>
			<p class="mt-4"><img src="../assets/image/forgotFooterImg.png" style="width: 100%;" /></p>
		</div>
	</div>
</div>

<div class="row mt-4" *ngIf="showHideResetPageUI">
	<div class="col-md-12">
		<div class="vk-mainForgotDiv mt-4 text-center">
			<p class="pt-3"><img src="../assets/image/resetLinkMailImg.png"
					style="width: 120px;position: relative;top: 60px;" /></p>
			<br>
			<p class="vk-fwDesc" style="margin:60px 40px;">Reset link has been sent to your email<br>
				Please check your mail.</p>

		</div>
	</div>
</div>



<!--end forgot password ui-->