import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
//import { SocketioService } from '../../socketio.service'
import { Router } from '@angular/router';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
//import * as CryptoJS from 'crypto-js';
//import {io} from 'socket.io-client/build/index';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import * as io from 'socket.io-client';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'underscore';
import { MessagingService } from '../../messaging.service';
import * as RecordRTC from 'recordrtc';
@Component({
  selector: 'app-chat-desktop-calling',
  templateUrl: './chat-desktop-calling.component.html',
  styleUrls: ['./chat-desktop-calling.component.scss']
})
export class ChatDesktopCallingComponent implements OnInit {
	@HostListener('scroll', ['$event'])
	isNoInternetPopup: boolean = false;
	mandatoryFiled: boolean = false;
	socketConnection: boolean = false;
	messages: any = [];
	selectedActive: any = -1;
	username: any = '';
	role: any = '';
	IsVisible: boolean = true;
	_dataTabName: any;
	_dataToId: any;
	filterChannel: any;
	txtmsg: any;
	txtmsg2: any;
	txtmsg3: any;
	myUser: any;
	groupList: any[] = [];
	groupListV2: any[] = [];
	channels:any = "";
	selectedChannel: any = "";
	allData:any="";
	socket:any="";
	selectedFile:any;
	msgType: string = "TEXT";
	socketId:any="";
	socketStatusBeforeCheck:any="";
	uniqueAgents:any [] = [];
	widgetUser:any;
	userInterval:any;
	isTextComponent:boolean = false;
	searchmsgValue: any;
	currentFileobj: any = {};
	isWhatsappComponent:boolean = false;
	_gplist:any=[];
	callStatus;
	chatStatus;
	selectedFileUrl;
	record;
	showHideRecordNow: boolean = false;
	showHideStartMic1: boolean = true;
	showHideStartMic2: boolean = true;
	showHideStartMic3: boolean = true;
	showHideStopMic1: boolean = false;
	showHideStopMic2: boolean = false;
	showHideStopMic3: boolean = false;
	selectedRecordSlotIndex;
	api_end_point:any;
	  constructor(private _service: MyserviceService, private API: ApiService, private router: Router, private http: HttpClient, protected _sanitizer: DomSanitizer, private messagingService: MessagingService) {
	  this.myUser = this._service.get_loggedin_user();
	  //this.socket = io('https://devchat.youearn.in',{"transports":["polling"]});
	}
  
	ngOnInit() {
		this.api_end_point = this._service.get_API_End_Point();
		let stateSettings: any = this._service.get_stage_settings();
		// if (stateSettings && stateSettings.extraoptions) {
		// 	for (let opt of stateSettings.extraoptions) {
		// 		if (opt.name == "chatboturl") {
		// 			this.socket = io(opt.definition, { "transports": ["polling"] });
					
		// 		}
		// 	}
		// }
		this.callStatus = this._service.getLastCallStatus();
		this.chatStatus = this._service.getLastChatStatus();
	  //   this.messagingService.requestPermission(this.myUser.id)
		this.messagingService.receiveMessage();
		this.messagingService.getMessage().subscribe(obj => {
  
			if (obj != null) {
				console.log("recccccc2", obj.data.data)
				var notifData = JSON.parse(obj.data.data);
				if (notifData.title == 'Customer Page URL UPDATE'){
					this.API.getWidgetUserByPhone('' + notifData.customer_number,this.api_end_point).subscribe((data: any) => {
						let _response = data;
						if (_response && _response.success) {
  
							if (this.groupList[this.selectedActive].messages[0].phone == _response.success.phone) {
								this.widgetUser = _response.success;
								this.counter = 0;
							}
						}
						else {
  
						}
					});
				}
				
			}
		  });
		this._service.getChatStatus().subscribe(obj => {
			console.log("chat-plugin", obj);
			this.chatStatus = obj
			if (obj.groupList){
				// this.groupList = obj.groupList;
			}
			
			var same = false;
			if (obj.fromMenu){
				console.log(this.selectedActive+"-"+ obj.selectedActive)
				this.selectedActive = obj.selectedActive;
				setTimeout(() => {
					this.scrollToBottom(obj.currentSlotIndex);
				}, 500)
				this.sendMessageSeenStatus(this.selectedActive)
				obj.fromMenu = false;
				obj.groupList[this.selectedActive].unread_messages = 0;
				this._service.updateChatStatus(obj);
			}
			

		});
		this._service.getCallStatus().subscribe(obj => {
		  console.log("calling-plugin", obj);
		  // this.callStatus = obj;
		  // if(this.callStatus && this.callStatus.widgetUser){
		  // 	if (this.groupList[this.selectedActive].messages[0].phone == this.callStatus.widgetUser.phone){
		  // 		this.widgetUser = this.callStatus.widgetUser;
		  // 		this.counter = 0;
		  // 	}
			  
		  // }
  
	  })
	  // var interval_id = window.setInterval(()=>{}, 99999);
	  // for (var i = 0; i < interval_id; i++){
	  //   window.clearInterval(i);
	  // }
		
	  this.groupList=[];
	  this.subscribe();
	  //this.initSocket()
	  if(window.location.pathname.includes('text')){
		this.isTextComponent = true;
	  }
	  if(window.location.pathname.includes('whatsapp')){
		this.isWhatsappComponent = true;
	  }
  
	  
	  // this.setupSocketConnection("CUSTOMER_CHANNEL")
	}

	onScroll(event) {
		console.log(event.srcElement.scrollTop)
		if (event.srcElement.scrollTop == 0){
			this.fetchOldMessages(this.chatStatus.slot1)
		}
	}
	onScroll2(event) {
		console.log(event.srcElement.scrollTop)
		if (event.srcElement.scrollTop == 0){
			this.fetchOldMessages(this.chatStatus.slot2)
		}
	}
	onScroll3(event) {
		console.log(event.srcElement.scrollTop)
		if (event.srcElement.scrollTop == 0){
			this.fetchOldMessages(this.chatStatus.slot3)
		}
	}
	
	sendMessageSeenStatus(slotIndex){
		this.chatStatus.groupList[slotIndex].messages.map((msg,index)=>{
			if (!msg[this.myUser.id + "_seen"]) {
				console.log("confirming")
				this.sendMessageSeenConfirmation(msg);
			}
			if (!msg[this.myUser.id + "_delivered"]) {
				console.log("delivering")
				this.sendMessageAcknowledgment(msg);
			}
		})
		
		
	}

	startRecordingBtn(index) {
		this['showHideStartMic'+index] = false;
		this['showHideStopMic'+index] = true;
		this.selectedRecordSlotIndex = index;
		// this.recording = true;
		let mediaConstraints = {
			video: false,
			audio: true
		};
		navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));

	}

	stopRecordingBtn(index) {
		this['showHideStartMic' + index]= true;
		this['showHideStopMic' + index] = false;
		// this.record.stop();
		setTimeout(() => { this.record.stop(this.processRecording.bind(this)) }, 3000)

	}

	checkIfYoutubeLink(msg){
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
		var match = msg.match(regExp);
		var id = (match && match[7].length == 11) ? match[7] : false
		console.log(id ? 'https://www.youtube.com/embed/' + id : false)
		return id ? true : false;
	}

	youtube_parser(url) {
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
		var match = url.match(regExp);
		var id = (match && match[7].length == 11) ? match[7] : false
		console.log(id ? 'https://www.youtube.com/embed/' + id : false)
		return id ? 'https://www.youtube.com/embed/'+id : false;
	}

	processRecording(blob) {
		
	}

	errorCallback(error) {
		// this.error = 'Can not play audio in your browser';
		console.log(error)
	}

	successCallback(stream) {
		var this2 = this;
		var options = {
			mimeType: "audio/wav",
			numberOfAudioChannels: 1,
			timeSlice: 3000,
			ondataavailable: function (blob) { this2.processRecordingSlice(blob) },
			// sampleRate: 16000,
		};
		//Start Actuall Recording
		var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
		this.record = new StereoAudioRecorder(stream, options);
		this.record.record();
	}

	processRecordingSlice(blob) {
		let formData = new FormData();
		var name = `recording_${new Date().getTime()}.mp3`
		formData.append('audio', blob, name);
		if (this.selectedRecordSlotIndex == 1) {
			this.txtmsg = name;
		}
		if (this.selectedRecordSlotIndex == 2) {
			this.txtmsg2 = name;
		}
		if (this.selectedRecordSlotIndex == 3) {
			this.txtmsg3 = name;
		}
		this.API.uploadChatFile(formData, this.api_end_point).subscribe(
			(res: any) => {
				// this.callFlowJsonSections[0].child[index].answer = res.url;
				this.selectedFileUrl = res.url
			}, (err) => {
				console.log(err)
			}
		)
	}

	fetchOldMessages(slot) {
		var _obj = {
			"username": this.myUser.name,
			"userid": this.myUser.id,
			"start_index": slot.start_index,
			"chat_limit": 25,
			"client": "WEB",
			"channel": slot.channel,
			"to_id": slot.to_id_2, 
			"groupid": this.myUser.groupid, 
			"channel_type": slot.channel_type ? slot.channel_type : ""
		};
		var token = btoa(JSON.stringify(_obj));
		console.log(_obj)
		this.API.subscribeChatv2({ token })
			.subscribe(
				(response: any) => {
					console.log(window.atob(response))
					response = window.atob(response);
					response = JSON.parse(response);
					console.log(response)
					if (response && response.message === "Old Messages") {
						var prev_messages = response.data;
						(prev_messages).map((obj, index) => {
							if ((obj.message_type == "TEXT" || obj.message_type == "IMAGE" || obj.message_type == "AUDIO" || obj.message_type == "DOCUMENT") && !obj.decoded) {
								prev_messages[index].message = decodeURIComponent(escape(window.atob(obj.message)))
								prev_messages[index].decoded = true;
							}

						})
						var updateObj = this.chatStatus || {};
						var allMsgs = [...prev_messages,...updateObj.groupList[slot.index].messages];
						console.log(allMsgs)
						updateObj.groupList[slot.index].messages = allMsgs;
						var key = "slot" + "" + slot.slotNo;
						updateObj[key].start_index = slot.start_index + 25;
						
						this._service.updateChatStatus(updateObj);
					}
				});
	}

	subscribe() {
	  var _obj = {
		"username": this.myUser.name,
		"userid": this.myUser.id,
		"client": "WEB"
	  };
		var token = btoa(JSON.stringify(_obj))
	  this.API.subscribeChatv2({token})
		.subscribe(
		  (response: any) => {
				console.log(window.atob(response))
				response = window.atob(response);
				response = JSON.parse(response);
			if (response && response.message === "Subscription Done") {
				this.channels = response.channels;
				this.extractGroupListV2(response.channels)
				if (response.message_status_channel){
					this.setupSocketConnectionMessageStatus(response.message_status_channel);
				}
			}
		  });
	//   this.API.subscribe(_obj)
	// 	.subscribe(
	// 	  (response: any) => {
	// 		if (response && response.message === "Subscription Done") {
	// 		  this.channels = response.channels;
	// 		  this.allData = response;
	// 		//   this.extractGroupList(response.channels)
	// 		  var updateObj = this.chatStatus || {};
	// 	      updateObj.channels = response.channels;
	// 		  updateObj.allData = response;
	// 		  this._service.updateChatStatus(updateObj);
	// 		}
	// 	  });
	}
  
	extractGroupListV2(data: any) {
		var groupList = [];
		for (let key in data) {
			this.setupSocketConnectionV2(data[key].channel);
			if (key == 'CUSTOMER'){
				data[key].messages.map((person, index) => {
					var msg: any = "";
					if (person.messages && person.messages.length > 0) {
						msg = person.messages[person.messages.length - 1];
						if (msg && !msg.decoded) {
							msg.message = decodeURIComponent(escape(window.atob(msg.message)))
							msg.decoded = true;
							if (!msg[this.myUser.id + "_delivered"]) {
								console.log("delivering")
								this.sendMessageAcknowledgment(msg);
							}
						}
						(person.messages).map((obj, index) => {
							if ((obj.message_type == "TEXT" || obj.message_type == "IMAGE" || obj.message_type == "AUDIO" || obj.message_type == "DOCUMENT") && !obj.decoded) {
								person.messages[index].message = decodeURIComponent(escape(window.atob(obj.message)))
								person.messages[index].decoded = true;
							}

						})
					}
					var obj: any = {
						id: person.user_key,
						userid: person.userid,
						key: key,
						to_id: person.user_key,
						messages: person.messages,
						show: true,
						tab_name: person.user_name,
						unread_messages: person.unread_messages,
						channel: person.channel,
						to_id_2: person.userid,
						channel_type: person.channel_type,
					}
					
					if (msg) {
						obj.last_message = msg.message_type == 'TEXT' ? msg.message.substr(0, 20) : msg.message_type == 'AUDIO' ? "AUDIO" : msg.message_type == 'IMAGE' ? "IMAGE" : msg.document_type ? msg.document_type : "FILE";
						obj.last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
							hour: '2-digit',
							minute: '2-digit',
						})
						obj.lmt = new Date(msg.date).getTime()
					}
					this.uniqueAgents.push(person.user_key)
					groupList.push(obj);
				})
			}else{
				var person = data[key];
				var msg: any = "";
				if (person.messages && person.messages.length > 0) {
					msg = person.messages[person.messages.length - 1];
					if (msg && !msg.decoded) {
						msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
						msg.decoded = true;
					}
					(person.messages).map((obj, index) => {
						if (!obj.decoded) {
							person.messages[index].message = msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
							person.messages[index].decoded = true;
						}

					})
				}
				var obj: any = {
					id: person.user_key,
					to_id: person.user_key,
					userid: person.userid,
					key: key,
					messages: person.messages,
					show: true,
					tab_name: person.user_name,
					unread_messages: person.unread_messages,
					to_id_2: person.userid,
					channel_type: person.channel_type,
				}
				if (msg) {
					obj.last_message = msg.message_type == 'TEXT' ? msg.message.substr(0, 20) : msg.message_type == 'AUDIO' ? "AUDIO" : msg.message_type == 'IMAGE' ? "IMAGE" : msg.document_type ? msg.document_type : "FILE";;
					obj.last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
					})
					obj.lmt = new Date(msg.date).getTime()
				}
				this.uniqueAgents.push(person.user_key)
				groupList.push(obj);
			}
			
		}
			
		
		// this.groupList = groupList;
		this.groupList = _.sortBy(groupList, function (o) { return -o.lmt; });
		var updateObj = this.chatStatus || {};
		updateObj.groupList = this.groupList;
		this._service.updateChatStatus(updateObj);
		
		console.log("chat-menu-page",groupList)
	}
	extractGroupList(data: any) {
	  
	  // var uniqueAgents:any [] = [];
	  for (let key in data) {
		console.log(key)
		if(key == 'CUSTOMER'){
		  this.selectedChannel = this.channels[key];
		}
	  }
		let value = data['CUSTOMER'];
		if(value.messages && value.messages.length){
		  for(let msgKey in value.messages){
			var msg = value.messages[msgKey];
			const agentIndex = this.uniqueAgents.indexOf(msg.from_id);
			const agentIndex2 = this.uniqueAgents.indexOf(msg.to_id);
			
			  if((msg["channel_type"] && msg["channel_type"] == "WHATSAPP") || msg.channel == "CUSTOMER_CHANNEL"){
				console.log(msg.message,msg.decrypt)
				if(msg.channel == "CUSTOMER_CHANNEL" && !msg.decrypt){
				  msg.message = decodeURIComponent(escape(window.atob(msg.message)))
				}else{
				  msg.message = msg.message;
				}
				
				
			  }else{
				  msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
			  }
			console.log(msg.message)
			// msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
			msg.decrypt = true;
			if(this.isTextComponent){
			  if(msg.channel_type == 'SMS'){
				if(this.myUser.id != msg.from_id){
				  this.appendMessageToGroup('CUSTOMER',msg,agentIndex)
				}else{
				  this.appendMessageToGroup('CUSTOMER',msg,agentIndex2)
				}
			  }
			  
			}
			
			else if (msg.channel_type == 'WHATSAPP'){
			  if(msg.channel_type == 'WHATSAPP'){
				if(this.myUser.id != msg.from_id){
					this.appendMessageToGroup('CUSTOMER', msg, agentIndex, msg[msg.to_id + "_seen"] ? false : true)
				}else{
					this.appendMessageToGroup('CUSTOMER', msg, agentIndex2, msg[msg.to_id + "_seen"] ? false : true, false, msg.to_id)
				}
			  }            
			}
  
			
			else{
				if (this.myUser.id != msg.from_id && msg.channel_type != 'SMS' && msg.channel_type != 'WHATSAPP'){
					this.appendMessageToGroup('CUSTOMER', msg, agentIndex, msg[msg.to_id + "_seen"] ? false : true)
				} else if (msg.channel_type != 'SMS' && msg.channel_type != 'WHATSAPP'){
					this.appendMessageToGroup('CUSTOMER', msg, agentIndex2, msg[msg.to_id + "_seen"] ? false : true)
			  }
			}
			
			
		  }
		  this.sortByTime();
		}
	  
	  console.log(this.uniqueAgents)
	  console.log(this.groupList)
	  console.log(this.selectedChannel.channel)
	  this.setupSocketConnection(this.selectedChannel.channel);
  
	}
  
	initSocket(){
	  
	  this.socket.on('connect', () => {
		console.log(this.socket);
		this.socketId = this.socket.id;
		console.log("Connecting -- ",this.socket.id)
		  this.socket.emit("SOCKET_CONNECTION", {
			userid: this.myUser.id,
			socketid: this.socketId
		});
		
	  });
	  
	//   this.socket.emit("SOCKET_CONNECTION", {
	//     userid: this.myUser.id,
	//     socketid: this.socket.id
	// });
	  this.socket.on("TEST_CONNECTION", (msg: any) => {
		// console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
		this.socketStatusBeforeCheck = msg.status;
		console.log(this.socketStatusBeforeCheck);
	});
	  this.username = this.myUser.name;
	  this.role =  this.myUser.role;
	  
	}
  
	fetchUser(phone){
	  // this.widgetUser = ""
	  this.API.getWidgetUserByPhone(phone.includes('+') ? phone : '+'+phone,this.api_end_point).subscribe((data: any) => {
		let _response = data;
		if (_response.success) {
		  if(!this.widgetUser){
			this.widgetUser = _response.success;
		  }else if(_response.success.external_url != this.widgetUser.external_url || _response.success.state != this.widgetUser.state || _response.success.name != this.widgetUser.name){
			this.widgetUser.external_url = _response.success.external_url;
			this.widgetUser.state = _response.success.state;
			this.widgetUser.name = _response.success.name;
			this.widgetUser.message = _response.success.message;
			this.counter = 0;
		  }
		   
		}
		else{
  
		}
	  });
	}
  
	counter:any=0;
	transformUrl(url: string){ 
	  if(this.counter==0){
		this.counter++;
	  console.log(this._sanitizer.bypassSecurityTrustResourceUrl(url))
	  return this._sanitizer.bypassSecurityTrustResourceUrl(url);
	  }
	}
	
	sendMessage_With_Enter(messageToSend:any, slotIndex:any, slotId: any){
	  
	//   if(this.txtmsg=="" || messageToSend==undefined){
	// 	this.mandatoryFiled=true;
	// 	return;
	//   }
		console.log("You entered: ", messageToSend, slotIndex, slotId);
		if (!messageToSend){
			return;
		}
		
	  let msg = {
		type: "CHAT_MESSAGE",
		message_type:this.msgType,
		channel: "CUSTOMER_CHANNEL",
		groupid:  this.myUser.groupid,
		from_id: this.myUser.id,
		to_id: this.groupList[slotIndex].userid,
		user: this.myUser.name,
		phone: this.myUser.phone,
		session_id:"",
		// message: mesg //.trim()
		// message: $scope.encryptMessage($scope.userid, $scope.groupid, mesg)
	} as any;
	//debugger;
	if(this.groupList[slotIndex].messages && this.groupList[slotIndex].messages.length && this.groupList[slotIndex].messages[this.groupList[slotIndex].messages.length-1].session_id){
	  msg["session_id"] = this.groupList[slotIndex].messages[this.groupList[slotIndex].messages.length-1].session_id;
	}
	if(this.groupList[slotIndex].messages[0].channel_type == "WHATSAPP"){
	  msg["channel_type"] = "WHATSAPP";
	}
	if(this.selectedFileUrl){
		msg["message"] = btoa(unescape(encodeURIComponent(this.selectedFileUrl)));
		if (this.selectedFileUrl.includes('mp3')){
			msg["message_type"] = "AUDIO";
		} else if (this.checkIfImage(this.selectedFileUrl)){
			msg["message_type"] = "IMAGE";
		} else {
			msg["message_type"] = "DOCUMENT";
			msg["document_type"] = this.selectedFileUrl.split('.').pop().toUpperCase();
		}
		
	}else{
		msg["message"] = btoa(unescape(encodeURIComponent(messageToSend)));
	}
	
	if(this.groupList[slotIndex].messages[0].channel_type == 'SMS'){
	  msg['channel_type'] = 'SMS';
	}
	  // msg["message"] = this.encryptMessage(this.myUser.id, this.allData.groupid, messageToSend);
		this.selectedFileUrl = "";
		this.currentFileobj = {};
	  this.API.message(msg)
		.subscribe(
		  (response: any) => {
			console.log(response);
			if(slotId == 1){
				this.txtmsg = "";
			} else if (slotId == 2) {
				this.txtmsg2 = "";
			} else if (slotId == 3) {
				this.txtmsg3 = "";
			}
			
		//     var obj = {} as any;
		//     obj.message = this.txtmsg;
		//     obj.date = new Date();
		//     obj.from_id = this.myUser.id;
		//     obj.user = this.myUser.name;
		//     obj.message_type = this.msgType;
		//     obj.session_id = msg.session_id;
		//     obj.message_time = (new Date()).toLocaleTimeString([], {
		//       hour: '2-digit',
		//       minute: '2-digit'
		//     });
		//     this.groupList[this.selectedActive].last_message = obj.message_type == 'TEXT' ? obj.message : obj.message_type == 'AUDIO'?"AUDIO":"IMAGE";
		// this.groupList[this.selectedActive].last_message_time = (new Date(obj.date)).toLocaleTimeString([], {
		//   hour: '2-digit',
		//   minute: '2-digit'
		// });
		// this.groupList[this.selectedActive].lmt = this.groupList[this.selectedActive].last_message_time.replace(':','');
		//     this.groupList[this.selectedActive].messages.push(obj)
		//     this.txtmsg = "";
		//     this.scrollToBottom();
		//     this.mandatoryFiled=false;
		//     if (!msg[this.myUser.id + "_delivered"]) {
		//       console.log("delivering")
		//       this.sendMessageAcknowledgment(msg);
		//     }
		  });
	}
  
	setupSocketConnectionMessageStatus(channel: any) {
		this.socket.on(channel, (msg: any) => {
			console.log(msg);
			var toCheckId = msg.to_id, groupList = this.chatStatus.groupList;
			if (msg.channel_type && (msg.channel_type == "CUSTOMER" || msg.channel_type == "WHATSAPP")) {
				toCheckId = (msg.to_id == this.myUser.id ? msg.to_id : msg.to_id) + ('_' + msg.channel_type);
			} else if (msg.channel_type && msg.channel_type != "CUSTOMER") {
				toCheckId = msg.channel_type;
			}
			console.log(toCheckId);
			groupList.map((person, index) => {


				if (person.id == toCheckId) {
					
					Object.keys(msg).map(key=>{
						if (key.includes('_failed') || key.includes('_seen') || key.includes('_delivered')){
							person.messages.map((objmsg, index2)=>{
								if (objmsg.session_id == msg.session_id){
									
									groupList[index].messages[index2][key] = msg[key];
									console.log("got", groupList[index].messages[index2])
								}
							})
						}
					})
					
				}
			})
			var updateObj = this.chatStatus || {};
			updateObj.groupList = groupList;
			this._service.updateChatStatus(updateObj);
		})
	}
	setupSocketConnectionV2(channel: any) {
	//	console.log("listening==>", channel)
		var indexToScroll;
		this.socket.on(channel, (msg: any) => {
			console.log(msg)
			console.log(this.chatStatus.groupList);
			var updateObj = this.chatStatus || {};
			this.groupList = updateObj.groupList;
			
			var found = false;
			var toCheckId = msg.from_id;
			if(msg.from_id == this.myUser.id){
				toCheckId = msg.to_id;
				console.log("seen")
				this.sendMessageSeenConfirmation(msg);
			}
			if (!msg[this.myUser.id + "_delivered"]) {
				console.log("delivering")
				this.sendMessageAcknowledgment(msg);
			}
			if (!msg.decoded) {
				msg.message = decodeURIComponent(escape(window.atob(msg.message)))
				msg.decoded = true;
			}
			if (msg.channel_type && (msg.channel_type == "CUSTOMER" || msg.channel_type == "WHATSAPP")) {
				toCheckId = (msg.from_id == this.myUser.id ? msg.to_id : msg.from_id) + ('_' + msg.channel_type);
			} else if (msg.channel_type && msg.channel_type != "CUSTOMER") {
				toCheckId = msg.channel_type;
			}
			console.log(toCheckId)
			this.chatStatus.groupList.map((person,index)=>{
				
				
				if (person.id == toCheckId){
					indexToScroll = this.findSlotIndex(person.id, msg.to_id);
					indexToScroll = indexToScroll.slotNo;
					found = true;
					
					person.messages.push(msg);
					person.last_message = msg.message_type == 'TEXT' ? msg.message.substr(0, 20) : msg.message_type == 'AUDIO' ? "AUDIO" : msg.message_type == 'IMAGE' ? "IMAGE" : msg.document_type ? msg.document_type : "FILE";;
					person.last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
					})
					person.lmt = new Date(msg.date).getTime();
					person.unread_messages = indexToScroll ? person.unread_messages : person.unread_messages + 1;
					// person.show = true;
				}
			})

			if(!found){
				var obj = {
					id: toCheckId,
					key: "CUSTOMER",
					last_message: msg.message_type == 'TEXT' ? msg.message : msg.message_type == 'AUDIO' ? "AUDIO" : "FILE",
					last_message_time: (new Date(msg.date)).toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
					}),
					lmt: new Date(msg.date).getTime(),
					messages: [msg],
					show: true,
					tab_name: msg.user,
					userid: msg.from_id,
					unread_messages: 1,
					to_id_2: msg.userid,
					channel_type: msg.channel_type,
				}
				this.uniqueAgents.push(toCheckId)
				this.groupList.push(obj);
				
				
				
			}
			this.groupList = _.sortBy(this.groupList, function (o) { return -o.lmt; });

			updateObj.groupList = this.groupList;
			updateObj = this.updateSlotAfterSort(updateObj);
			this._service.updateChatStatus(updateObj);
			if (indexToScroll) {
				setTimeout(() => {
					this.scrollToBottom(indexToScroll);
				}, 500)
			}
			
		})
	}

	updateSlotAfterSort(updateObj){
		
		this.groupList.map((person, index) => {
			if (updateObj.slot1 && updateObj.slot1.from_id == person.id && updateObj.slot1.index != index){
				updateObj.slot1.index = index;
			}
			if (updateObj.slot2 && updateObj.slot2.from_id == person.id && updateObj.slot2.index != index) {
				updateObj.slot2.index = index;
			}
			if (updateObj.slot3 && updateObj.slot3.from_id == person.id && updateObj.slot3.index != index) {
				updateObj.slot3.index = index;
			}

			
		});
		return updateObj;

	}

	checkIfImage(fileUrl){
		return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(fileUrl)
	}
	checkIfFile(fileUrl) {
		return (/\.(doc|DOC|pdf|PDF)$/i).test(fileUrl)
	}

	checkSeenOutgoing(msg){
		if(msg[msg.to_id+'_seen']){
			return 'green';
		}else if (msg[msg.to_id + '_delivered']) {
			return 'gray';
		}
		return 'transparent';
	}
	changeFile(event, index) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			console.log(event.target.files);
			this.currentFileobj['slot'+index] = event.target.files[0];
			if(index == 1){
				this.txtmsg = event.target.files[0].name
			}
			if (index == 2) {
				this.txtmsg2 = event.target.files[0].name
			}
			if (index == 3) {
				this.txtmsg3 = event.target.files[0].name
			}
			
			let formData = new FormData();
			console.log(event.target.files[0].name, event.target.files[0].name.replace(/[^a-zA-Z0-9.]/g, ""))
			formData.append('image', event.target.files[0], event.target.files[0].name.replace(/[^a-zA-Z0-9.]/g, ""));
			this.API.uploadChatFile(formData, this.api_end_point).subscribe(
				(res: any) => {
					// this.callFlowJsonSections[0].child[index].answer = res.url;
					this.selectedFileUrl = res.url
				}, (err) => {
					console.log(err)
				}
			)
		}
	}

	setupSocketConnection(channel: any) {
	  
	  // this.socket.emit('my message', 'Hello there from Angular.');
	  console.log(channel);
	  
	  this.socket.on(channel, (msg: any) => {
		console.log(msg);
		
		  if((msg["channel_type"] && msg["channel_type"] == "WHATSAPP") || msg.channel == "CUSTOMER_CHANNEL"){
			if(msg.channel == "CUSTOMER_CHANNEL"){
			  msg.message = decodeURIComponent(escape(window.atob(msg.message)));
			}else{
			  msg.message = msg.message;
			}
			
		  }else{
			msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
		  }
		
		// msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
		msg.decrypt = true;
		var agentIndex = -1;
		console.log(agentIndex)
	   
  
		if(msg.from_id != this.myUser.id){
		  agentIndex = this.groupList.findIndex(group=>group.id == msg.from_id);
		  if (msg.channel_type == 'SMS'){
			if(msg.channel_type == 'SMS'){
			  this.appendMessageToGroup("TL",msg,agentIndex, true, true)
			}
		  }
		  
		  else if (msg.channel_type == 'WHATSAPP'){
			if(msg.channel_type == 'WHATSAPP'){
			  this.appendMessageToGroup("TL",msg,agentIndex, true, true)
			}
		  }
		  
		  
		  else if(msg.channel_type != 'SMS'){
			this.appendMessageToGroup("TL",msg,agentIndex, true, true)
		  }
		  
		}else{
		  agentIndex = this.groupList.findIndex(group=>group.id == msg.to_id);
		  if(msg.channel_type == 'SMS'){
			if(msg.channel_type == 'SMS' && agentIndex == -1){
			  this.appendMessageToGroup("TL",msg,agentIndex, true, true)
			}
		  }
		  else if (msg.channel_type == 'WHATSAPP'){
			if(msg.channel_type == 'WHATSAPP'){
			  this.appendMessageToGroup("TL",msg,agentIndex, true, true)
			}
		  }
		  
		  
		  else if(msg.channel_type != 'SMS'){
			this.appendMessageToGroup("TL",msg,agentIndex, true, true)
		  }
		  
		}
		this.sortByTime();
		// if(this.selectedActive == 0){
		//   this.groupList[this.selectedActive].messages.push(msg);
		// }
		
		// setTimeout(()=>{
		//   this.scrollToBottom();
		// },500)
	  });
	}

	findSlotIndex(from_id, to_id){
		if (this.chatStatus.slot1 && this.chatStatus.slot1.from_id == from_id){
			return this.chatStatus.slot1;
		} else if (this.chatStatus.slot2 && this.chatStatus.slot2.from_id == from_id) {
			return this.chatStatus.slot2;
		} else if (this.chatStatus.slot3 && this.chatStatus.slot3.from_id == from_id) {
			return this.chatStatus.slot3;
		}

		if (this.chatStatus.slot1 && this.chatStatus.slot1.from_id == to_id) {
			return this.chatStatus.slot1;
		} else if (this.chatStatus.slot2 && this.chatStatus.slot2.from_id == to_id) {
			return this.chatStatus.slot2;
		} else if (this.chatStatus.slot3 && this.chatStatus.slot3.from_id == to_id) {
			return this.chatStatus.slot3;
		}
		return -1;
	}
  
	appendMessageToGroup(key: any,msg: any,agentIndex: any, checkUnread: any="", fromSocket: any = false, toConsiderId: any = ''){
		var selectedActive;
		if (this.chatStatus == undefined || (this.chatStatus.slot1 == undefined && this.chatStatus.slot2 == undefined && this.chatStatus.slot3 == undefined)){
			selectedActive = this.selectedActive;
		}else{
			selectedActive= this.findSlotIndex(msg.from_id,msg.to_id);
		}	
	  if(agentIndex == -1 ){
		  
		this.uniqueAgents.push(toConsiderId ? toConsiderId : msg.from_id);
		console.log("inserting")
		var group = {
		  key,
		  id: msg.channel_type == 'WHATSAPP' ? msg.to_id :  msg.from_id,
		  tab_name:  msg.user == 'Server' ? msg.to_id : msg.user,
		  last_message: '',
		  last_message_time: '11:11',
		  lmt: '1111',
		  unread_messages: 0,
		  messages: []
		} as any;
		
		group.last_message = msg.message_type == 'TEXT' ? msg.message : msg.message_type == 'AUDIO'?"AUDIO":"FILE";
		group.last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
		  hour: '2-digit',
		  minute: '2-digit'
		});
		group.lmt = new Date(msg.date).getTime();
		if(checkUnread){
		  if(selectedActive != -1 && this.groupList[selectedActive].id == msg.from_id){
		  }else{
			if(msg.from_id != this.myUser.id){
			  group.unread_messages = group.unread_messages + 1;
			}
		   
		  }
		}
		
		group.messages.push(msg);
		group.show = true
		this.groupList.push(group);
		this._gplist= this.groupList;
	  }else {
		this.groupList[agentIndex].last_message = msg.message_type == 'TEXT' ? msg.message : msg.message_type == 'AUDIO'?"AUDIO":"FILE";
		this.groupList[agentIndex].last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
		  hour: '2-digit',
		  minute: '2-digit'
		});
		this.groupList[agentIndex].lmt = new Date(msg.date).getTime();
		if(checkUnread){
		  if(selectedActive != -1 && this.groupList[selectedActive].id == msg.from_id){
		  }else{
			if(msg.from_id != this.myUser.id){
			  this.groupList[agentIndex].unread_messages = this.groupList[agentIndex].unread_messages + 1;
			}
			
		  }
		}
		
		this.groupList[agentIndex].messages.push(msg);
	  }
	  if (!msg[this.myUser.id + "_seen"] && selectedActive != -1 && this.groupList[selectedActive].id == msg.from_id) {
		console.log("confirming")
		this.sendMessageSeenConfirmation(msg);
	  }
	  if (!msg[this.myUser.id + "_delivered"]) {
		console.log("delivering")
		this.sendMessageAcknowledgment(msg);
	  }
  
	  
		
  
	}
  
	sortByTime = () => {
	  //  this.groupList = this.groupList.sort(function(a,b){return b.lmt - a.lmt});
	  var prevSelected:any;
	  if(this.selectedActive != -1){
		prevSelected = this.groupList[this.selectedActive];
	  }
	  
	  console.log(this.groupList)
	//    this.groupList = _.sortBy(this.groupList, function(o) { return -o.lmt; });
		var updateObj = this.chatStatus || {};
		updateObj.groupList = this.groupList;
		this._service.updateChatStatus(updateObj);
	//    if(this.selectedActive != -1){
	// 	this.selectedActive = _.findIndex(this.groupList , function(obj) { return obj.id == prevSelected.id })
	//   }
	  
	  // console.log(this.groupList.sort(function(a,b){console.log(a); return b.lmt - a.lmt}));
	}
  
	removeSlot(slotIndex){
		var updateObj = this.chatStatus || {};
		console.log(this.selectedActive, this.chatStatus.selectedActive)
		if (slotIndex == 1 ){
			updateObj.slot1 = undefined;
		}
		if (slotIndex == 2) {
			updateObj.slot2 = undefined;
		}
		if (slotIndex == 3) {
			updateObj.slot3 = undefined;
		}
		updateObj.fromMenu = false;
		this._service.updateChatStatus(updateObj);
	}

	startSession(){
	  let msg = {
		type: "SESSION_STARTS",
		message_type:this.msgType,
		channel: "CUSTOMER_CHANNEL",
		groupid:  this.myUser.groupid,
		from_id: this.myUser.id,
		to_id: this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length ? this.groupList[this.selectedActive].messages[0].from_id : this.myUser.groupid,
		user: this.myUser.name,
		username: this.myUser.name,
		phone: this.myUser.phone,
		session_id:"",
		message:"",
	} as any;
	  this.API.message(msg)
		.subscribe(
		  (response: any) => {
			
  
		  });
	}
  
	endSession(){
	  let msg = {
		type: "SESSION_ENDS",
		message_type:this.msgType,
		channel: "CUSTOMER_CHANNEL",
		groupid:  this.myUser.groupid,
		from_id: this.myUser.id,
		to_id: this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length ? this.groupList[this.selectedActive].messages[0].from_id : this.myUser.groupid,
		user: this.myUser.name,
		username: this.myUser.name,
		phone: this.myUser.phone,
		session_id:"",
		message:"",
	} as any;
	if(this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length && this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length-1].session_id){
	  msg["session_id"] = this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length-1].session_id;
	}
	  this.API.message(msg)
		.subscribe(
		  (response: any) => {
			alert("Session ended")
  
		  });
	}
  
	sendMessageSeenConfirmation(message: any){
	  this.socket.emit("MESSAGE_SEEN", {
		  id: message.id,
		  session_id: message.session_id,
		  channel: message.channel,
		  userid: this.myUser.id
	  });
  }
  
  sendMessageAcknowledgment(message: any){
	this.socket.emit("MESSAGE_DELIVERY", {
		id: message.id,
		session_id: message.session_id,
		channel: message.channel,
		userid: this.myUser.id
	});
  }
  
	generateAESKey(value: string = "", type: string = "") {
	  // return CryptoJS.enc.Base64.parse("SMB_CHAT_SERVICE_"+inputValue);
	  if (type == "KEY") {
		return CryptoJS.enc.Base64.parse('u/Gu5posvwDsXUnV5Zaq4g==');
	  } else {
		return CryptoJS.enc.Base64.parse('5D9r9ZVzEYYgha93/aUK2w==');
	  }
	}
  
	encryptMessage(senderId: any, groupId: any, plainText: any){
	  var key = this.generateAESKey(senderId, "KEY");
		  var iv = this.generateAESKey(groupId, "IV");
		  let encryptedMessage = CryptoJS.AES.encrypt(
			  plainText,
			  key, {
				  mode: CryptoJS.mode.CBC,
				  padding: CryptoJS.pad.Pkcs7,
				  iv: iv
			  }
		  );
		  let cipherText = encryptedMessage.toString();
		  return cipherText;
	}
	decryptMessage(senderId: any, groupId: any, cipherText: string){
	  var key = this.generateAESKey(senderId, "KEY");
		  var iv = this.generateAESKey(groupId, "IV");
		  var decodeCipherText = CryptoJS.enc.Base64.parse(cipherText);
		  // console.log("decodeCipherText : ", decodeCipherText);
		  let decryptedMessage = CryptoJS.AES.decrypt({
				  ciphertext: decodeCipherText
			  } as any,
			  key, {
				  mode: CryptoJS.mode.CBC,
				  padding: CryptoJS.pad.Pkcs7,
				  iv: iv
			  }
		  );
		  // console.log("decryptedMessage : ", decryptedMessage);
		  let plainText = CryptoJS.enc.Utf8.stringify(decryptedMessage);
		  // console.log("plainText : ", plainText);
		  return plainText;
	}
  
	tabkeyMenu(key: any, index: any) {
	  // clearInterval(this.userInterval);
	  // var interval_id = window.setInterval(()=>{}, 99999);
	  // for (var i = 0; i < interval_id; i++){
	  //   window.clearInterval(i);
	  // }
	  // this.widgetUser = ""
	  this.counter = 0;
	  this.selectedActive = index;
	  this._dataTabName = this.groupList[index].tab_name;
	  this.groupList[index].unread_messages = 0;
	  console.log(this.groupList[index])
	  this.fetchUser(this.groupList[index].messages[0].phone)
		for (let msg of this.groupList[this.selectedActive].messages) {
			if (!msg[this.myUser.id + "_seen"] && this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id) {
				console.log("confirming")
				this.sendMessageSeenConfirmation(msg);
			}
		}
		// setTimeout(() => {
		// 	this.scrollToBottom();
		// }, 500)
	  // this.userInterval = setInterval(()=>{
	  //   this.fetchUser(this.groupList[index].messages[0].phone)
	  // },3000)
	  
	  // this.startSession();
	}
   
  
	scrollToBottom(index) {    
	  let messageBody:any = document.querySelector('#messageBody'+index);
	  messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
  }
  currentImageClick(index:any){     
	var img:any = document.getElementById("imgId"+index);
	var modal:any = document.getElementById("myModal");
	var modalImg:any = document.getElementById("img01");   
	modal.style.display = "block";
	modalImg.src = img.src;
  }
  
  closeLarge() { 
  var modal:any = document.getElementById("myModal");
  modal.style.display = "none";
  }
  uploadFile(event:any){
	
  }
  onTextMessage1(){
	if(this.txtmsg.length>0){
  this.mandatoryFiled=false;
	}
	else if(this.currentFileobj){
		this.mandatoryFiled = true;
		this.selectedFileUrl = "";
		this.currentFileobj = {};
	}
  }
	onTextMessage2() {
		if (this.txtmsg2.length > 0) {
			this.mandatoryFiled = false;
		}
		else if (this.currentFileobj) {
			this.mandatoryFiled = true;
			this.selectedFileUrl = "";
			this.currentFileobj = {};
		}
	}
	onTextMessage3() {
		if (this.txtmsg3.length > 0) {
			this.mandatoryFiled = false;
		}
		else if (this.currentFileobj) {
			this.mandatoryFiled = true;
			this.selectedFileUrl = "";
			this.currentFileobj = {};
		}
	}
  
  
	colWd:any=6;
	expandOpen(){
  this.colWd=12;
	}
	expandClose(){
	  this.colWd=6;
	}
  }
