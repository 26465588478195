import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, FormControl,FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { ApiService } from '../../api.service';
import {MyserviceService} from '../../myservice.service'
@Component({
  selector: 'app-demo-portal-email',
  templateUrl: './demo-portal-email.component.html',
  styleUrls: ['./demo-portal-email.component.scss']
})
export class DemoPortalEmailComponent implements OnInit {

   showHideVisible:boolean=true;
  showHideVisibleSuccess:boolean=false;
  createdForm: FormGroup;
  _email_id:any="";
  _message:any="";
  api_end_point:any;
  constructor(public router :Router,private API: ApiService,
		private formbuilder: FormBuilder,private _service:MyserviceService) {
      this.createdForm = this.formbuilder.group({
				"template": [''],
				"mailSubject": ['', Validators.required],
        "mailBody": ['', Validators.required],
        "customers_list": this.formbuilder.array([this.formbuilder.group({"name":'',"email":'',"link":''})])
			  })
     }

  ngOnInit(): void {
	this.api_end_point = this._service.get_API_End_Point();
  }
  submitBtn(createdForm:any){  
    let _template=createdForm.controls.template.value;
    let _mailSubject=createdForm.controls.mailSubject.value;
    let  _mailBody=createdForm.controls.mailBody.value;
    if(_template=="" || _mailSubject=="" || _mailBody ==""){
        alert("Please enter all filelds");
        return false;
    }

    let _obj={
      "template":createdForm.controls.template.value,
      "mail_subject":createdForm.controls.mailSubject.value,
      "cmail_body":createdForm.controls.mailBody.value,
      "customers_list":createdForm.value.customers_list,
  }
    this.API.emailCallApiSave(_obj,this.api_end_point).subscribe((response:any) => {
//debugger;
if(response.success){
 this._email_id= response.success;
 this._message= "Created successfully.";// response.message;
  this.showHideVisible=false;
this.showHideVisibleSuccess=true;
}
else{
  alert(response.status);
} });
  }
  backDashboard(){
    this.router.navigateByUrl('/demo-portal');
    setTimeout(()=>{
      this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
    },100);

    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
  cancelBtn(){
    this.showHideVisible=true;
    this.showHideVisibleSuccess=false;
    this.createdForm.reset();
  }
  okBtn(){
    window.location.reload();
    this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
  get customersList() {
    return this.createdForm.get('customers_list') as FormArray;
  }
  addrow() {
    this.customersList.push(this.formbuilder.group({"name":'',"email":'',"link":''}));
  }

  deleterow(index) {
    this.customersList.removeAt(index);
  }
}
