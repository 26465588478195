import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MyserviceService } from 'src/app/myservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { EndSessionDialogComponent } from '../desktop-calling/end-session-dialog/end-session-dialog.component';
import { DesktopContactDetailsPageComponent } from '../desktop-contact-details-page/desktop-contact-details-page.component';
import { ContactListCampaignContactDialogPageComponent } from '../desktop-calling/contact-list-campaign-contact-dialog-page/contact-list-campaign-contact-dialog-page.component';


import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DesktopContactListWhatsappUiDialogComponent } from '../desktop-calling/desktop-contact-list-whatsapp-ui-dialog/desktop-contact-list-whatsapp-ui-dialog.component';
import { forEach } from 'underscore';
@Component({
  selector: 'app-desktop-contact-list-page',
  templateUrl: './desktop-contact-list-page.component.html',
  styleUrls: ['./desktop-contact-list-page.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DesktopContactListPageComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();

  color11: any = 'red !important';
  color12: any = 'white !important';
  showHideStart: boolean = true;
  showHideStop: boolean = false;
  _flagStartStop: any = "StopFlag";

  displayedColumns = ['alphabetStart', 'Name', 'action'];
  dataSource: MatTableDataSource<any>;
  elementData: Element[];
  tempElementData: any;
  myUser: any;
  isExpansionDetailRow = (i: number, row: Object) => {
    return row.hasOwnProperty('detailRow');
  }
  expandedElement: any;

  _storeRowElement: any = "";
  _storeRowIndex: any;
  selectedRow;
  _nameUser: any = "";
  _psType: any = "";
  showHideWhatsapp: boolean = false;
  showHidePhone: boolean = false;
  showHideText: boolean = false;
  selectedButton: any = "";
  _ddlListExtraOption: any = [];
  user: any = this.myservice.get_loggedin_user();
  stateSettings: any = this.myservice.get_stage_settings();
  _paraMessage: any = "";
  _rowExtraOptionData: any = [];
  templateddlValue: any;
  dateSelect: any;
  phoneNoModel: any;
  trackingLinkModel: any;
  relationshipManagerModel: any;
  Product_Name: any;
  Product_Link: any;
  selectedWhatsappTemplate: any;

  _contactOptions: any;
  selectedContact: any;
  _wTemplate: any = { options: [] };
  _caller_id: string = "";
  c2cEndPoint: string = "";
  subscriptionDial: Subscription;
  subscription: Subscription;
  callStatus: any;
  minDate: any = new Date();
  templatepart1: any;
  templatepart2: any;
  @ViewChild('fromInput', {
    read: MatInput
  }) fromInput: MatInput;
  Publisher_Name: any;
  Reject_Key: any;
  Accept_Key: any;
  Service_Station: any;
  Vehicle_Number: any;
  Service_Name: any;
  Invoice_Number: any;
  Web_Link: any;
  media: any;
  ismedia: boolean = false;
  showHidePopup: boolean = false;
  breakoption: any = "breakoption";
  customer_name_entered: any = "";
  api_end_point: any;

  showHideCampDetails: boolean = false;
  campaignActiveListData: any = [];
  jsonData: any = [];

  tab = 0;
  vTable: any = [];
  AllvTable: any = [];
  warningMsg: any = "";
  stopDialogCounter: any = 1;
  keypressed: any;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keypressed = event.keyCode;
    if (this.keypressed == 116 || (event.ctrlKey && this.keypressed == 116)) {
      this._snackBar.open("Press F5 for Refresh, Not Allowed", "close", {
        duration: 3000, verticalPosition: 'top'
      });
      event.preventDefault();
    }
    if (event.ctrlKey && this.keypressed == 82) {
      this._snackBar.open("Press Ctrl + R for Refresh, Not Allowed", "close", {
        duration: 3000, verticalPosition: 'top'
      });
      event.preventDefault();
      event.stopPropagation();
    }

  }
  NoRequiredStartBtn: any = "";
  isStageDial: boolean = false;
  isTruepredicitive: boolean = false;
  isStayAtHome: boolean = false;
  sessionStarted: boolean = false;
  _findteleco: any;
  _setDialPad:any;
  showHideDiallerPage:boolean =true;
  _projectTypeSBICC: any;
  isCheckProjectTypeSBICC: boolean = true;
  setPredictiveOnOff:any=[];
  constructor(private dialog: MatDialog, public router: Router, private API: ApiService, private myservice: MyserviceService, private _snackBar: MatSnackBar) {

    if (sessionStorage.getItem('sessionStarted') == "true") {
      //console.log("---1---");
      // this.showHideStopPage = true;
      // this.showHideStartPage = false;
      this.showHideStop = false;
      this.showHideStart = true;
      this.sessionStarted = true;
      this.check_dialler_setting();
    }
    if(sessionStorage.getItem('sessionStarted') == 'false' )
      {
        //this.showHideStopPage = false;
        localStorage.setItem('sessionStarted', 'false');
        sessionStorage.setItem('sessionStarted', 'false');
        this.sessionStarted = false;
        this._flagStartStop = "StartFlag";
        this.showHideStop = false;
        this.showHideStart = true;
        this.sessionStarted = true;
        this.showHideDiallerPage=false;
        this.check_dialler_setting();
        console.log("--run this script--2");

      }
    let stateSettings: any = this.myservice.get_stage_settings();
    this.callStatus = this.myservice.getLastCallStatus();
    let _inNumberOneDefinition = stateSettings.cloudtelephonyout.filter(v => { return v.name == "innumber1" });
    this._caller_id = _inNumberOneDefinition[0].definition;
    //console.log(this._caller_id);


    if (stateSettings && stateSettings.extraoptions) {
      for (let opt of stateSettings.extraoptions) {
        if (opt.name == "c2cendpoint") {
          //console.log(opt);
          this.c2cEndPoint = opt.definition;
        }
      }
    }
    this.subscriptionDial = this.myservice.getCallStatus().subscribe(obj => {
      //console.log("home-call-history", obj);
      //debugger;
      this.callStatus = obj;
      if (this.callStatus.status == "terminated" || this.callStatus.status == 'end_call_agent') {
        if (document.getElementById('codeDialPad') as HTMLFormElement) {
          (document.getElementById('codeDialPad') as HTMLFormElement).value = "";
        }
      }
      if ((this.callStatus.status == undefined && sessionStorage.getItem('sessionStarted') == 'false')) {
        if (document.getElementById('codeDialPad') as HTMLFormElement) {
          (document.getElementById('codeDialPad') as HTMLFormElement).value = "";
        }
        this.showHideStop = false;
        this.showHideStart = true;
        this.sessionStarted = false;
        this._flagStartStop = "StopFlag";
        this.check_dialler_setting();
      }

  if(Object.keys(obj).length==0 && sessionStorage.getItem('nextPreviewDialingPopup')=='true'){
      if (document.getElementById('codeDialPad') as HTMLFormElement) {
        (document.getElementById('codeDialPad') as HTMLFormElement).value = "";
      }
      this.showHideStop = false;
      this.showHideStart = true;
      this.sessionStarted = false;
      this.showHideDiallerPage=false;
      this._flagStartStop = "StopFlag";
      this.check_dialler_setting();
    }


    })
    //this.getTabList();
    this.getDropdownList();
  }

  ngOnInit() {
    this.getTabList();
    history.pushState(null, '');
    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
        // alert("NO back from Contact list");
        this._snackBar.open("Back button not allow from browser", "close", {
          duration: 3000, verticalPosition: 'top'
        });
        return false;
      });

    //debugger;

    setTimeout(() => {
      this.showHideCampDetails = true;
    }, 100);
    // debugger;
    this.api_end_point = this.myservice.get_API_End_Point();
    this.myUser = this.myservice.get_loggedin_user();
    let stateSettings: any = this.myservice.get_stage_settings();
    this._findteleco = stateSettings.cloudtelephonyout.filter(v => { return v.name == "telco_operator" })
    this.getRows();
    // this.getCLTableData();
    if (document.getElementById('codeDialPad') as HTMLFormElement) {
      (document.getElementById('codeDialPad') as HTMLFormElement).value = "";
    }
    const callSession = this.myservice.getLastCallSession();
    if (callSession.sessionStarted) {
      this.showHideStart = true;
      this.showHideStop = false;
      this._flagStartStop = "StartFlag";
      this.check_dialler_setting();

    }
    //debugger;
    let stateSettingsChat: any = this.myservice.get_stage_settings();
    let _setFilterDial = stateSettingsChat.extraoptions.filter(v => { return v.name == 'unscheduledcall' });
    if (_setFilterDial && _setFilterDial.length > 0) {
      this.isStageDial = false;
      if (_setFilterDial[0].definition == "off") {
        this.isStageDial = false;
      } else {
        this.isStageDial = true;
        this.showHideDiallerPage=false;
      }

      console.log(_setFilterDial);
    } else {
      this.isStageDial = true;
    }
//start added by praveen
    // let _setDialPad = stateSettingsChat.extraoptions.filter(v=>{return v.name=='manualdial'});
    // if(_setDialPad && _setDialPad.length > 0){
    //   this.isStageDial = false;
    //   if(_setDialPad[0].definition == "off"){
    //     this.isStageDial = false;
    //   }else{
    //     this.isStageDial = true;
    //   }

    //   console.log(_setDialPad);
    // }else{
    //   this.isStageDial = true;
    // }
//end added by praveen
this.setPredictiveOnOff=[];
    let _setFilterTruepredictive = stateSettingsChat.extraoptions.filter(v => { return v.name == 'truepredictive' });
    this.setPredictiveOnOff=_setFilterTruepredictive ;

    if (_setFilterTruepredictive && _setFilterTruepredictive.length > 0) {
      this.isTruepredicitive = false;
      if (_setFilterTruepredictive[0].definition == "off") {
        this.isTruepredicitive = false;
      } else {
        this.isTruepredicitive = true;
      }
    } else {
      this.isTruepredicitive = false;
    }


    let _setFilterStayHome = stateSettingsChat.extraoptions.filter(v => { return v.name == 'stayathome' });
    if (_setFilterStayHome && _setFilterStayHome.length > 0) {
      this.isStayAtHome = false;
      if (_setFilterStayHome[0].definition == "off") {
        this.isStayAtHome = false;
      } else {
        this.isStayAtHome = true;
      }

    } else {
      this.isStayAtHome = false;
    }
    this.isCheckProjectTypeSBICC = true;
    let _checkProjectTypeSBICC = stateSettingsChat.extraoptions.filter((v) => { return v.name == 'project_type'; });
    if (_checkProjectTypeSBICC && _checkProjectTypeSBICC.length == 1) {
      this._projectTypeSBICC = _checkProjectTypeSBICC[0].definition;
      if (_checkProjectTypeSBICC[0].definition == "SBICC") {
        this.isCheckProjectTypeSBICC = false;
      }
      else {
        this.isCheckProjectTypeSBICC = true;
      }
    }
    let stateSettings1: any = this.myservice.get_stage_settings();
    let _checkAllowinBound = stateSettings1.extraoptions.filter(v => { return v.name == 'allowinbound' });
    if (_checkAllowinBound && _checkAllowinBound.length > 0 && _checkAllowinBound[0].definition == "on") {
    this.isStageDial = false;
    }
    else{
      this.isStageDial = true;
    }
  }
  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  rowsAllData: any = [];
  getTabList() {

    var obj = {
      user_id: this.user.id,
    } as any;
    //this.user.server_url
    this.api_end_point = this.myservice.get_API_End_Point();
    console.log(this.api_end_point);
    this.API.getContactsWhtasapp(obj, this.api_end_point).subscribe((res: any) => {
      if (res.success) {
        //debugger;
        //console.log(JSON.stringify(res.success));
        let ELEMENT_DATA = res.success;
        //console.log("---",ELEMENT_DATA);
        this.campaignActiveListData = [];
        this.campaignActiveListData = ELEMENT_DATA;
        //console.log("---",JSON.stringify(this.campaignActiveListData));
        this.clistPageData();
        let _eleData = [];
        _eleData = res.success;
        this.API.sendCustomerDetailsList(_eleData);
        var rows = [];
        ELEMENT_DATA.map((obj, index) => {
          ELEMENT_DATA[index].show = false;
        })
        ELEMENT_DATA.forEach(element => rows.push(element, { detailRow: true, element }));
        console.log("chaitanya1-",JSON.stringify(rows));
        //debugger;
        this.rowsAllData = rows;
        this.dataSource = new MatTableDataSource(rows);
        this.dataSource.filterPredicate = function (data, filter: any): boolean {
          // console.log(data.Name, data.Name.toString().toLowerCase().includes(filter))
          return (data.Name && data.Name.toString().toLowerCase().includes(filter)) || (data.element !== undefined && data.element.Name.toString().toLowerCase().includes(filter))
        };

      }
      else if (res.errors == "No Data Found") {
        this._snackBar.open(res.errors, "close", {
          duration: 3000, verticalPosition: 'top'
        });

      }
    }, (err) => {
      console.log(err)
    }
    )
  }
  getDropdownList() {
    // let _contactOptions = [];

    if (this.stateSettings && this.stateSettings.extraoptions) {

      this._contactOptions = this.stateSettings.extraoptions.filter(a => { return a.name == "contacts_options" });
      this._wTemplate = JSON.parse(this._contactOptions[0].definition);

      this._ddlListExtraOption = this._wTemplate.whatsapp_template_data;

    }
  }
  changeWhatsappTemplate(v) {
    this._rowExtraOptionData = [];
    let _rowExtraOptionData1 = [];

    this.media = '';
    this.ismedia = false;

    this._paraMessage = "";
    _rowExtraOptionData1 = this._ddlListExtraOption.filter(d => { return d.template_name == v });
    //console.log(_rowExtraOptionData1[0]);
    this.templatepart1 = _rowExtraOptionData1[0].message;
    // //let message_firstpart = _rowExtraOptionData1[0].message;
    // let part1 = message_firstpart[0]   //_rowExtraOptionData1[0].substring( 0, message_firstpart );
    // let part2 = message_firstpart[1]  //_rowExtraOptionData1[0].substring( message_firstpart + 1 );

    // this.templatepart1 = part1;
    // this.templatepart2 = part2;
    if (_rowExtraOptionData1[0].media_url) {
      this.ismedia = true;
      this.media = _rowExtraOptionData1[0].media_url;
    }
    this._paraMessage = _rowExtraOptionData1[0].message;

    this.selectedWhatsappTemplate = _rowExtraOptionData1[0];


    this._rowExtraOptionData = _rowExtraOptionData1[0].required_values;//_rowExtraOptionData.Next_Update_Time.type;

    this.dateSelect = '';
    //this.fromInput.value = '';
  }

  startCallLoading() {
    const callSession = this.myservice.getLastCallSession();
    // if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
    //   alert("Please Press Start for Calling");
    //   return;
    // }
    if (callSession && callSession.internet_speed && callSession.internet_speed < 2) {
      alert("Internet speed slow try later");
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert("No Internet Network Connection available");
      return;
    }
    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later");
    // 	return;
    // }
    if (this._findteleco[0].definition == "KNOWLARITY") {
      if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
        alert("Sip connection not available try later");
        return;
      }
    } else if (this._findteleco[0].definition == "Twilio") {

    }
    else if (this._findteleco[0].definition == "TCL") {

    }
    else if (this._findteleco[0].definition == "TTS") {

    }
    //localStorage.setItem("c2cCallPresent", "availableC2C");

    //debugger;
    //this.showHideCallPad = true;


    // setTimeout( () => {
    //    this.showHideConnecting=false;
    //    this.showHideStartCallButton=false;
    //    this.showHideStartCall=false;


    //   //  setTimeout( () => {
    //   //  this.showHide_Connected=true;
    //   //  this.showHideConnecting=false;
    //   //  this.showHideStartCallButton=false;
    //   //  this.showHideStartCall=false;
    //   // }, 8000 )

    //   }, 6000 )
    var updateObj = this.callStatus;
    // if (this.callStatus && this.callStatus.dialing && this.callStatus.dialing == 1) {
    // 	this.showHideStopPage = false;
    // 	this.showHideStartPage = false;
    // 	this.showHideStopPage = true;
    // 	updateObj.dialing = 2;
    // 	console.log("dd-debug27", updateObj)
    // 	this.myservice.updateCallStatus(updateObj);
    // }
    if (updateObj.status == undefined) {
      updateObj.dialing = 1;
      if ((document.getElementById('codeDialPad') as HTMLFormElement)) {
        updateObj.dialNum = (document.getElementById('codeDialPad') as HTMLFormElement).value;
      }
      else {
        updateObj.dialNum = "";
      }
      this.myservice.updateCallStatus(updateObj);
      updateObj = this.callStatus;
      updateObj.dialing = 2;
      if (this.customer_name_entered) {
        updateObj.dialName = this.customer_name_entered;
      }
      this.myservice.updateCallStatus(updateObj);
      this.customer_name_entered = '';
      this.showHidePopup = false;
    }


  }
  startCallLoadingUpdate() {
    this.cancelUpdateBtn();
    const callSession = this.myservice.getLastCallSession();
    // if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
    //   alert("Please Press Start for Calling");
    //   return;
    // }
    if (callSession && callSession.internet_speed && callSession.internet_speed < 2) {
      alert("Internet speed slow try later");
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert("No Internet Network Connection available");
      return;
    }
    if (this._findteleco[0].definition == "KNOWLARITY") {
      if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
        alert("Sip connection not available try later");
        return;
      }
    } else if (this._findteleco[0].definition == "Twilio") {

    }
    else if (this._findteleco[0].definition == "TCL") {

    }
    else if (this._findteleco[0].definition == "TTS") {

    }
    localStorage.setItem("c2cCallPresent", "availableC2C");

    var updateObj = this.callStatus;
    console.log(updateObj.status);
    //alert(updateObj.status);
    if (updateObj.status == undefined) {
      updateObj.dialing = 1;
      if ((document.getElementById('codeDialPad') as HTMLFormElement)) {
        updateObj.dialNum = (document.getElementById('codeDialPad') as HTMLFormElement).value;
      }
      else {
        updateObj.dialNum = "";
      }
      this.myservice.updateCallStatus(updateObj);
      updateObj = this.callStatus;
      updateObj.dialing = 2;
      if (this.customer_name_entered) {
        updateObj.dialName = this.customer_name_entered;
      }

      let manualObj={
        "user_id":this.user.id,
        "id_group":this.user.groupid,
        "number":"+91"+ updateObj.dialNum,
        "name": this.customer_name_entered,
        "call_type":"Manual_dial"
       }

     this.API.TTS_c2c_API(manualObj,this.api_end_point).subscribe((res: any) => {
     console.log(res);
     let _response={};
     if(res && res.success){
      _response=res.success;
      this.myservice.updateCallStatus(updateObj);
      this.customer_name_entered = '';
      this.showHidePopup = false;
     }
     else{

     }

   })

    }


  }

  cancelUpdateBtn() {
    localStorage.removeItem("c2cCallPresent");
    this.showHidePopup = false;
    this.myservice.clearCallStatus();
  }
  clearBtn() {
    this.customer_name_entered = '';
  }

  checkCustomerName() {
    const callSession1 = this.myservice.getLastCallSession();
    if (callSession1 && (callSession1.internet_speed == 0 || callSession1.internet_speed == undefined)) {
      alert("Please check your network or wifi connection.");
      return;
    }
    this.API.sendRowContactList('');
    const callSession = this.myservice.getLastCallSession();
    // if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
    //   alert("Please Press Start for Calling");
    //   return;
    // }
    if (callSession && callSession.internet_speed && callSession.internet_speed < 2) {
      alert("Internet speed slow try later");
      return;
    }
    if (callSession && callSession.internet_speed == 0) {
      alert("No Internet Network Connection available");
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert("No Internet Network Connection available");
      return;
    }
    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later");
    // 	return;
    // }
    if (this._findteleco[0].definition == "KNOWLARITY") {
      if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
        alert("Sip connection not available try later");
        return;
      }
    } else if (this._findteleco[0].definition == "Twilio") {

    }
    else if (this._findteleco[0].definition == "TCL") {

    }
    else if (this._findteleco[0].definition == "TTS") {

    }
    let _numLength = ((document.getElementById('codeDialPad') as HTMLFormElement).value).length;
    if (_numLength < 8) {
      alert("Enter proper number");
      return false;
    }
    this.showHidePopup = true;
  }

  nameRowRecord(element) {
    this._nameUser = element.Name;
    this._psType = element.type;
    this.selectedContact = element;
    this.relationshipManagerModel = '';
    this.trackingLinkModel = '';
    this.phoneNoModel = '';
    this.Product_Name = '';
    this.Publisher_Name = '';
    this.Reject_Key = '';
    this.Accept_Key = '';
    this.Product_Link = '';
    this.Service_Station = '';
    this.Vehicle_Number = '';
    this.Service_Name = '';
    this.Web_Link = '';
    this.templateddlValue = '';
    this.templatepart1 = '';
    this.templatepart2 = '';
    this.ismedia = false;
    this.media = '';
    this._rowExtraOptionData = [];
    // this.dateSelect = new Date();
  }

  selectRow(row) {
    this.selectedRow = row;
  }
  CallChatPhoneClick(type: any, element: any = {}) {
    debugger;
    console.log(element)
    this.selectedRow = element;
    this._nameUser = element.Name;
    this._psType = element.type;
    this.selectedContact = element;
    if (this.selectedRow == undefined || this.selectedRow == null) {
      //  alert("please select agent user");
      this._snackBar.open("Please select agent user", 'close', {
        duration: 6000,
      });
      return;
    }
    this.selectedButton = "";
    this.showHideWhatsapp = false;
    this.showHidePhone = false;
    this.showHideText = false;
    this.selectedButton = type;

    //console.log(JSON.stringify(element));

    if (type == "phone") {
      this.showHidePhone = true;
      this.callCustomer(element);
    }
    else if (type == "whatsapp") {
      this.showHideWhatsapp = true;
    }
    else if (type == "text") {
      this.showHideText = true;
    }


  }
  saveBtn() {
    //debugger;
    let _date = moment(this.dateSelect).format('YYYY-MM-DD');
    let _templateName = this.templateddlValue;
    let _msg = this._paraMessage;
    let _uid = this.user.id;
    let _agentid = this.user.agentid;
    let _name = this.user.name;
    let _phone = this.user.phone;
    let _address = this.user.address;
    let _company = this.user.company;
    let _email = this.user.email;
    let _msgType = "TEXT";
    let _isHSM = true;
    let _type;
    let _modelObj: any = {};
    var checkEmpty = false;
    let a = this._rowExtraOptionData;
    //console.log(a.length);
    if (a.Next_Update_Time) {

      _type = a.Next_Update_Time.type;
      if (_date) {
        checkEmpty = true;
      }
      _modelObj = {
        "Next_Update_Time": {
          "value": _date,
          "type": _type
        }
      }
    }
    if (a.Phone_Number) {
      _type = a.Phone_Number.type;
      if (this.phoneNoModel) {
        checkEmpty = true;
      }
      _modelObj = {
        "Phone_Number": {
          "value": this.phoneNoModel,
          "type": _type
        }
      }
    }
    if (a.Tracking_Link) {
      _type = a.Tracking_Link.type;
      if (this.trackingLinkModel) {
        checkEmpty = true;
      }
      _modelObj = {
        "Tracking_Link": {
          "value": this.trackingLinkModel,
          "type": _type
        }
      }
    }
    if (a.Web_Link) {
      let type1 = a.Web_Link.type;
      if (this.Web_Link) {
        checkEmpty = true;
      }
      _modelObj = {
        "Tracking_Link": {
          "value": this.Web_Link,
          "type": type1
        }
      }
    }
    if (a.Accept_Key && a.Reject_Key && a.Publisher_Name) {
      let type1, type2, type3;
      //_type = a.Tracking_Link.type;
      type1 = a.Accept_Key.type;
      type2 = a.Reject_Key.type;
      type3 = a.Publisher_Name.type;

      if (this.Accept_Key && this.Reject_Key && this.Publisher_Name) {
        checkEmpty = true;
      }
      _modelObj = {
        "Accept_Key": {
          "value": this.Accept_Key,
          "type": type1
        },
        "Reject_Key": {
          "value": this.Reject_Key,
          "type": type2
        },
        "Publisher_Name": {
          "value": this.Publisher_Name,
          "type": type3
        }
      }
    }
    if (a.Service_Station && a.Vehicle_Number && a.Service_Name) {
      let type1, type2, type3;
      //_type = a.Tracking_Link.type;
      type1 = a.Service_Station.type;
      type2 = a.Vehicle_Number.type;
      type3 = a.Service_Name.type;

      if (this.Service_Station && this.Vehicle_Number && this.Service_Name) {
        checkEmpty = true;
      }

      _modelObj = {
        "Service_Station": {
          "value": this.Service_Station,
          "type": type1
        },
        "Vehicle_Number": {
          "value": this.Vehicle_Number,
          "type": type2
        },
        "Service_Name": {
          "value": this.Service_Name,
          "type": type3
        }
      }
    } else if (a.Vehicle_Number && a.Invoice_Number) {
      let type1 = a.Vehicle_Number.type;
      let type2 = a.Invoice_Number.type
      if (this.Vehicle_Number && this.Invoice_Number) {
        checkEmpty = true;
      }
      _modelObj = {
        "Vehicle_Number": {
          "value": this.Vehicle_Number,
          "type": type1
        },
        "Invoice_Number": {
          "value": this.Invoice_Number,
          "type": type2
        }
      }
    }
    if (a.Product_Name && a.Relationship_Manager) {
      let type1 = a.Product_Name.type;
      let type2 = a.Relationship_Manager.type
      if (this.Product_Name && this.relationshipManagerModel) {
        checkEmpty = true;
      }
      _modelObj = {
        "Product_Name": {
          "value": this.Product_Name,
          "type": type1
        },
        "Relationship_Manager": {
          "value": this.relationshipManagerModel,
          "type": type2
        }
      }
    }
    else if (a.Product_Name && a.Product_Link) {
      let type1 = a.Product_Name.type;
      let type2 = a.Product_Link.type;
      if (this.Product_Name && this.Product_Link) {
        checkEmpty = true;
      }
      _modelObj = {
        "Product_Name": {
          "value": this.Product_Name,
          "type": type1
        },
        "Product_Link": {
          "value": this.Product_Link,
          "type": type2
        }
      }
    }
    else if (a.Relationship_Manager) {
      let type1 = a.Relationship_Manager.type;
      if (this.relationshipManagerModel) {
        checkEmpty = true;
      }
      _modelObj = {
        "Relationship_Manager": {
          "value": this.relationshipManagerModel,
          "type": type1
        }
      }
    }

    let _obj = {
      "user_id": _uid,
      "customer_details": this.selectedContact,
      "template_data": this.selectedWhatsappTemplate,
    }
    //console.log(this.selectedWhatsappTemplate)
    _obj['template_data']['required_values'] = _modelObj;

    if (checkEmpty == false && Object.keys(this.selectedWhatsappTemplate.required_values).length > 0) {
      this._snackBar.open("Fill required details", 'close', {
        duration: 5000,
      });
      return;
    }
    //	console.log(_obj);
    //this.user.server_url
    this.API.saveContactsWhatsapp(_obj, this.api_end_point).subscribe((res: any) => {
      this.relationshipManagerModel = '';
      this.trackingLinkModel = '';
      this.phoneNoModel = '';
      this.Product_Name = '';
      this.Publisher_Name = '';
      this.Reject_Key = '';
      this.Accept_Key = '';
      this.Product_Link = '';
      this.Service_Station = '';
      this.Vehicle_Number = '';
      this.Service_Name = '';
      this.Web_Link = '';
      if (res.status == "success") {
        this.showHideWhatsapp = false;
        this._snackBar.open(res.message, 'close', {
          duration: 2000,
        });
        this.ngOnInit();
      }
    });

  }

  onlyNumberValidation(event) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

  callCustomer(obj) {
    //debugger;
    const callSession = this.myservice.getLastCallSession();
    if (this.NoRequiredStartBtn == "NoNeedStartForC2C") {

    }
    else {
      // if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
      // 	alert("Please Press Start for Calling");
      // 	return;
      // }
    }
    if (callSession && callSession.internet_speed && callSession.internet_speed < 2) {
      alert("Internet speed slow try later");
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert("No Internet Network Connection available");
      return;
    }
    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later");
    // 	return;
    // }
    let dataGsm: any = {
      "customer_number": obj.Number,
      "customer_name": obj.Name,
      "user_id": this.user.id,
      "caller_id": this._caller_id,
      "networktype": "WIFI",
      "pingdelay": "45"
    }
    if (this._findteleco[0].definition == "KNOWLARITY") {
      if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
        alert("Sip connection not available try later");
        return;
      }
    } else if (this._findteleco[0].definition == "Twilio") {

    }
    else if (this._findteleco[0].definition == "TCL") {

    }
    else if (this._findteleco[0].definition == "TTS") {
      dataGsm.telco_partner = "TTS";
      dataGsm.agent_number = this.myUser.TTS_extension;
      dataGsm.cli = this._caller_id;
      // this.API.siptogsmcall(dataGsm, this.api_end_point).subscribe(
      //   (res: any) => {
      // 	console.log(res)

      //   }, (err) => {
      // 	console.log(err)
      //   }
      // )
    }
    console.log(this.callStatus)
    let updateObj: any = {};
    if (updateObj.status == undefined) {
      updateObj.telco_partner = "TTS";
      updateObj.agent_number = this.myUser.TTS_extension;
      updateObj.cli = this._caller_id;
      this.myservice.updateCallStatus(updateObj);
      updateObj.dialNum = this.selectedContact.Number;
      updateObj.dialing = 1;
      this.myservice.updateCallStatus(updateObj);
      updateObj.customerInfo = {
        'customer_name': obj.Name,
        'customer_number': obj.Number,
        'updated_at': obj.updated_at,
        'customkvs': obj.customkvs,
        'transactionid': obj.transactionid,
        'checkC2C': obj.checkC2C,
        'dial_mode':'direct_dial',
        'campignType':obj.campignType
      }
      //'establishing';
      updateObj.status ='establishing';
      updateObj.dialing = 2;
      this.myservice.updateCallStatus(updateObj);
    }

    // var data: any = {
    // 	"customer_number": this.selectedContact.Number,
    // 	"agent_number": this.user.phone,
    // 	"user_id": this.user.id,
    // 	"caller_id": this._caller_id,
    // 	"networktype": "WIFI",
    // 	"pingdelay": "45"
    // }
    // if (this.user.sip_id) {
    // 	data.sip_id = this.user.sip_id
    // }
    // this.API.sipOutboundCall(data, this.user.server_url, this.c2cEndPoint).subscribe((res: any) => {
    // 	debugger;
    // 	console.log(res)

    // }, (err) => {
    // 	console.log(err)
    // }
    // )

  }


  startBtnCall() {
 //debugger;
 const callSession1 = this.myservice.getLastCallSession();
 if (callSession1 && (callSession1.internet_speed == 0 || callSession1.internet_speed == undefined)) {
   alert("Please check your network or wifi connection.");
   return;
 }
    if (this._projectTypeSBICC == "SBICC") {

      if ( ((this.setPredictiveOnOff.length == 0) || (this.setPredictiveOnOff && this.setPredictiveOnOff.length > 0 && this.setPredictiveOnOff[0].definition == 'off'))){
        sessionStorage.setItem('nextPreviewDialingPopup', 'true');
            }
            else{
              sessionStorage.removeItem('nextPreviewDialingPopup');
            }

      this.check_dialler_setting();
      this.showHideDiallerPage =true;

    //debugger;
    localStorage.removeItem("c2cCallPresent");
    const callSession = this.myservice.getLastCallSession();
    // if (callSession && callSession.internet_speed == 0) {
    //   alert("Please check your network or wifi connection.");
    //   return;
    // }

    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later");
    // 	return;
    // }
    if (this._findteleco[0].definition == "KNOWLARITY") {
      if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
        alert("Sip connection not available try later");
        return;
      }
    } else if (this._findteleco[0].definition == "Twilio") {

    }
    else if (this._findteleco[0].definition == "TCL") {

    }
    else if (this._findteleco[0].definition == "TTS") {

    }
    var updateObjSession = this.myservice.getLastCallSession();
    // if (updateObjSession && (!updateObjSession.internet_speed || updateObjSession.internet_speed < 2)) {
    // 	alert("Internet speed slow try later");
    // 	return;
    // }
    // if (updateObjSession && !updateObjSession.isAppAgent && updateObjSession.sip_conn != true) {
    // 	alert("Sip connection not available try later")
    // 	return;
    // }
    updateObjSession.sessionStarted = true;
    // this.callSession = updateObjSession;
    this.myservice.updateCallSession(updateObjSession);
    // sessionStorage.setItem('sessionStarted', 'true');
    this.showHideStart = true //false;
    this.showHideStop = false; //true;
    this._flagStartStop = "StartFlag";
    if ((document.getElementById('codeDialPad') as HTMLFormElement)) {
      (document.getElementById('codeDialPad') as HTMLFormElement).value = "";
    }
    this.myservice.clearCallStatus();
    //debugger;
    this.API.remoteDialStart(this.user.id, this.api_end_point).subscribe(
      (res: any) => {
        // console.log(res);
        // debugger;
        this.sessionStarted = true;
        if (this.isStayAtHome) {
          //this.showHidePopup=false;
          localStorage.removeItem("noExitC2CInStartBtn");
        }
        else {
          //this.showHidePopup=true;
          //console.log("---100---");
          this.showHideStop = false;//true;
          this.showHideStart = true; //false;
          this.check_dialler_setting();
          localStorage.removeItem("noDialPad");
          localStorage.setItem("noDialPad", "NoDial");

          localStorage.removeItem("noExitC2CInStartBtn");
          localStorage.setItem("noExitC2CInStartBtn", "c2cNotThere");
          setTimeout(() => {

            this.startCallLoading();
          }, 100);
        }
      }, (err) => {
        console.log(err)
      }
    )
  }
  else{
    //debugger;
    localStorage.removeItem("c2cCallPresent");
    const callSession = this.myservice.getLastCallSession();
    // if (callSession && callSession.internet_speed == 0) {
    //   alert("Please check your network or wifi connection.");
    //   return;
    // }

    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later");
    // 	return;
    // }
    if (this._findteleco[0].definition == "KNOWLARITY") {
      if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
        alert("Sip connection not available try later");
        return;
      }
    } else if (this._findteleco[0].definition == "Twilio") {

    }
    else if (this._findteleco[0].definition == "TCL") {

    }
    else if (this._findteleco[0].definition == "TTS") {

    }
    var updateObjSession = this.myservice.getLastCallSession();
    // if (updateObjSession && (!updateObjSession.internet_speed || updateObjSession.internet_speed < 2)) {
    // 	alert("Internet speed slow try later");
    // 	return;
    // }
    // if (updateObjSession && !updateObjSession.isAppAgent && updateObjSession.sip_conn != true) {
    // 	alert("Sip connection not available try later")
    // 	return;
    // }
    updateObjSession.sessionStarted = true;
    // this.callSession = updateObjSession;
    this.myservice.updateCallSession(updateObjSession);
    sessionStorage.setItem('sessionStarted', 'true');
    // this.showHideStart = false;
    // this.showHideStop = true;
    this.showHideStart = true;
    this.showHideStop = false;
    this._flagStartStop = "StartFlag";
    if ((document.getElementById('codeDialPad') as HTMLFormElement)) {
      (document.getElementById('codeDialPad') as HTMLFormElement).value = "";
    }
    this.myservice.clearCallStatus();
    //debugger;
    this.API.remoteDialStart(this.user.id, this.api_end_point).subscribe(
      (res: any) => {
        // console.log(res);
        // debugger;
        this.sessionStarted = true;
        if (this.isStayAtHome) {
          //this.showHidePopup=false;
          localStorage.removeItem("noExitC2CInStartBtn");
        }
        else {
          //this.showHidePopup=true;
          //console.log("---100---");
          this.showHideStop = false;
          this.showHideStart = true;
          localStorage.removeItem("noDialPad");
          localStorage.setItem("noDialPad", "NoDial");

          localStorage.removeItem("noExitC2CInStartBtn");
          localStorage.setItem("noExitC2CInStartBtn", "c2cNotThere");
          setTimeout(() => {

            this.startCallLoading();
          }, 100);
        }
      }, (err) => {
        console.log(err)
      }
    )
  }
}

  endSessionCall() {
    //debugger;
    //console.log(this.myUser);
    var dialogRef = this.dialog.open(EndSessionDialogComponent, {
      width: '40%',
      disableClose: true,
      data: this.myUser,
    });
    dialogRef.afterClosed().subscribe(result => {
      //debugger;
      //console.log(result);
      if (result == "true") {
        this.check_dialler_setting();
        this.sessionStarted = false;
        this.showHideStart = true;
        this.showHideStop = false;
        this._flagStartStop = "StopFlag";
        // this.sessionStarted = false;
        // this.removeNum();
        this.myservice.clearCallStatus();
        var updateObjSession = this.myservice.getLastCallSession();
        updateObjSession.sessionStarted = false;
        // this.callSession = updateObjSession;
        this.myservice.updateCallSession(updateObjSession);
        sessionStorage.setItem('sessionStarted', 'false');
        this.stopDialogCounter = 1;
      }
      else {
        this.stopDialogCounter = 1;
      }
    });
  }


  stopBtnCall() {
    // debugger;
    const callSession = this.myservice.getLastCallSession();
    if (callSession && callSession.internet_speed == 0) {
      this.sessionStarted = false;
   this.showHideStart = true;
   this.showHideStop = false;
   this._flagStartStop = "StopFlag";
   sessionStorage.setItem('sessionStarted', 'false');
   if((document.getElementById('codeDialPad') as HTMLFormElement)){
     (document.getElementById('codeDialPad') as HTMLFormElement).value = "";
   }

return;

}

    localStorage.removeItem("noExitC2CInStartBtn");
    localStorage.removeItem("c2cCallPresent");
    if (this.stopDialogCounter == 1) {
      this.stopDialogCounter++;
      this.myservice.clearCallStatus();


      this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
        //console.log(data);
        //	console.log(data.success.extraoptions);
        let setting = data.success.extraoptions
        let stopsetting = setting.filter(v => {
          // console.log(v);
          return (v.name == "remoteautodialling")
        })
        //console.log(stopsetting[0].definition);
        if (stopsetting[0].definition == "onsolicit") {
          this.endSessionCall();
        } else {
          this.API.remoteDialStop(this.user.id, this.api_end_point, this.breakoption).subscribe(
            (res: any) => {
              this.sessionStarted = false;
              //console.log(res)
              // this.sessionStarted = false;
              // this.removeNum();
              this.myservice.clearCallStatus();
              var updateObjSession = this.myservice.getLastCallSession();
              updateObjSession.sessionStarted = false;
              // this.callSession = updateObjSession;
              this.myservice.updateCallSession(updateObjSession);
              sessionStorage.setItem('sessionStarted', 'false');
              this.showHideStart = true;
              this.showHideStop = false;
              this._flagStartStop = "StopFlag";
              (document.getElementById('codeDialPad') as HTMLFormElement).value = "";
            }, (err) => {
              console.log(err)
            }
          )
        }
      })

    }

    //   this.dataSource = new MatTableDataSource(this.getRows());

  }
  removeNum() {
    let _num = (document.getElementById('codeDialPad') as HTMLFormElement).value;
    _num = _num.substring(0, _num.length - 1);
    (document.getElementById('codeDialPad') as HTMLFormElement).value = _num;
  }
  clickNumBtn(num: any) {

    let _numLength = ((document.getElementById('codeDialPad') as HTMLFormElement).value).length;
    if (_numLength == 10) {
      (document.getElementById('codeDialPad') as HTMLFormElement).value;
      return false;
    }
    (document.getElementById('codeDialPad') as HTMLFormElement).value = (document.getElementById('codeDialPad') as HTMLFormElement).value + num.toString();
    //console.log((document.getElementById('codeDialPad') as HTMLFormElement).value);


  }

  openPopupDialog() {

  }
  getCLTableData() {
    this.dataSource = new MatTableDataSource(this.getRows());
    this.dataSource.sortingDataAccessor = (item, property) => {
      let newItem;
      if (item.element !== undefined)
        newItem = item.element;
      else
        newItem = item;
      //console.log(this.tempElementData);
      let foundElement;
      if (item.element !== undefined)
        foundElement = this.tempElementData.find(i => i.element !== undefined && item.element.name === i.element.name);
      else
        foundElement = this.tempElementData.find(i => item.name === i.name);

      let index = this.tempElementData.indexOf(foundElement);
      //console.log("foundElement: " + JSON.stringify(item) + " " + +index);
      return +index;
    }
  }

  sortFilteredData(property) {
    let unsortedData = this.dataSource.filteredData.map(g => Object.assign({}, g));
    if (property === "name") {
      unsortedData.sort(this.nameComparer);
    }
    //console.log(unsortedData);
    let sortedData = new Array();
    for (var i = 0, j = 0; i < unsortedData.length; i++) {
      if (unsortedData[i].element === undefined) {
        sortedData[j] = unsortedData[i];
        j += 2;
      }
    }
    // console.log(sortedData);
    unsortedData = unsortedData.filter(course => course.element !== undefined);
    for (var i = 0; i < sortedData.length; i += 2) {
      let name = unsortedData.find(course => course.element.name ===
        sortedData[i].name);
      sortedData[i + 1] = name;
    }
    //console.log(sortedData);
    this.tempElementData = sortedData;
  }

  nameComparer(nameA, nameB) {
    if (nameA.element !== undefined)
      nameA = nameA.element;

    if (nameB.element !== undefined)
      nameB = nameB.element;

    nameA = nameA.name;
    nameB = nameB.name;

    if (nameA === nameB)
      return 0;

    if (nameA < nameB)
      return -1;

    return 1;
  }

  getRows() {
    const rows = [];
    const data: Element[] = [
      { name: 'Ajay', show: false },
      { name: 'Arwind', show: false },
      { name: 'Binay', show: false },
      { name: 'Krunal', show: false },
      { name: 'Omkar', show: false },
      { name: 'Prashant', show: false },
      { name: 'Veenit', show: false },
    ];
    data.forEach(element => rows.push(element, { detailRow: true, element }));
    //console.log("krunal2-",rows);
    return rows;
  }

  highlightedRows: any = [];
  toggleRow(value: any) {
    //debugger;
    this._rowExtraOptionData = [];
    let _rowExtraOptionData1 = [];
    this._paraMessage = "";
    this.templateddlValue = '';

    this.dateSelect = '';
    this.highlightedRows = value;
    this._storeRowElement = value;
    if (this._flagStartStop == 'StopFlag') {
      return;
    }
    else {
      const foundElement = this.dataSource.data.find(elem => elem.element !== undefined && elem.element.Name === value.Name);
      //console.log("The found element is " + JSON.stringify(foundElement));
      const index = this.dataSource.data.indexOf(foundElement);
      this._storeRowIndex = index;
      this.dataSource.data.map(x => {
        if (x.element && x['element']['Number'] != this.dataSource.data[index]['element']['Number']) {
          x.element.show = false;
        }

      });
      this.selectedContact = this.dataSource.data[index - 1];
      this.dataSource.data[index].element.show = !this.dataSource.data[index].element.show;
    }
  }
  applyFilter(filterValue: any) {
    //console.log(filterValue)
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  contactDataDetails(element) {
    var dialogRef = this.dialog.open(DesktopContactDetailsPageComponent, {
      width: '78%',
      minHeight: 'auto',
      maxHeight: '95%',
      disableClose: true,
      autoFocus: false,
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
    });
  }


  clistPageData() {
    this.jsonData = [];
    this.vTable = [];
    this.AllvTable = [];
    // this.jsonData=[
    // 	{
    // 		CampaignName:'Campaign-1',
    // 		priority:'P2',
    // 		Available:4,
    // 		Completed:5,
    // 		campaignType:'Preview',
    // 		createdDate:'2nd July 2022 12:30 PM',
    // 		totalData:'20',
    // 		assignedData:'8',
    // 		calledData:'16',
    // 		availableData:'4',
    // 		tableData:[
    // 			{Name:'Praveen',Number:'+919739656227',Email:'praveen@smarterbiz.ai',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Prateek',Number:'+919886119774',Email:'prateek@smarterbiz.ai',callStatus:'Available',updatedAt:'4th July 2022 4:30 PM'},
    // 			{Name:'Veenit',Number:'+918618173026',Email:'veenit@smarterbiz.ai',callStatus:'Available',updatedAt:'4th July 2022 5:30 PM'},
    // 			{Name:'Prashant',Number:'+919743326277',Email:'prashanth@smarterbiz.ai',callStatus:'Dialled',updatedAt:'4th July 2022 4:30 PM'}
    // 		]
    // 	},
    // 	{
    // 		CampaignName:'Campaign-2',
    // 		priority:'P1',
    // 		Available:3,
    // 		Completed:4,
    // 		campaignType:'Preview',
    // 		createdDate:'3rd July 2022 2:30 PM',
    // 		totalData:'22',
    // 		assignedData:'5',
    // 		calledData:'14',
    // 		availableData:'5',
    // 		tableData:[
    // 			{Name:'Vivek',Number:'+91 9118989876',Email:'pravven@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Patel',Number:'+91 934788876',Email:'prashant@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Krunal',Number:'+91 676232232',Email:'veen@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'}
    // 		]
    // 	},
    // 	{
    // 		CampaignName:'Campaign-3',
    // 		priority:'P3',
    // 		Available:6,
    // 		Completed:5,
    // 		campaignType:'Preview',
    // 		createdDate:'4th July 2022 10:30 AM',
    // 		totalData:'30',
    // 		assignedData:'6',
    // 		calledData:'12',
    // 		availableData:'6',
    // 		tableData:[
    // 			{Name:'Praveen',Number:'+91 9898989876',Email:'pravven@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Prakash',Number:'+91 987788876',Email:'prakash@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Omkar',Number:'+91 6776637634',Email:'omkar@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Pradeep',Number:'+91 878686776',Email:'pradeep@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'}
    // 		]
    // 	},
    // 	{
    // 		CampaignName:'Campaign-4',
    // 		priority:'P5',
    // 		Available:3,
    // 		Completed:3,
    // 		campaignType:'Preview',
    // 		createdDate:'4th July 2022 05:30 PM',
    // 		totalData:'15',
    // 		assignedData:'7',
    // 		calledData:'11',
    // 		availableData:'8',
    // 		tableData:[
    // 			{Name:'Prashant',Number:'+91 9898989876',Email:'prashant@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Lal',Number:'+91 987788876',Email:'lal@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Dkay',Number:'+91 6776637634',Email:'dkay@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Krunal',Number:'+91 637266334',Email:'kr@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'}
    // 		]
    // 	},
    // 	{
    // 		CampaignName:'Campaign-5',
    // 		priority:'P4',
    // 		Available:4,
    // 		Completed:3,
    // 		campaignType:'Predictive',
    // 		createdDate:'5th July 2022 10:00 AM',
    // 		totalData:'12',
    // 		assignedData:'4',
    // 		calledData:'6',
    // 		availableData:'4',
    // 		tableData:[
    // 			{Name:'Praveen',Number:'+91 9898989876',Email:'pravven@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Prakash',Number:'+91 987788876',Email:'prakash@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Omkar',Number:'+91 6776637634',Email:'omkar@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
    // 			{Name:'Vinay',Number:'+91 6776637634',Email:'vinay@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'}
    // 		]
    // 	}
    // ];


    this.jsonData =  this.campaignActiveListData; //this.jsonData[0];

    let jsonData1=[];
  jsonData1= this.jsonData[0].customer_details.filter(v => { return  v.stage == "NEW DATA" && v.rnrcount == 0 })
  this.warningMsg = "";
    if (jsonData1 && jsonData1.length == 0) {
      this.warningMsg = "No Data Found";
    }
else{

    this.vTable = jsonData1;//this.jsonData[0].customer_details;
    this.AllvTable = this.jsonData[0].customer_details;
    this.warningMsg = "";
    if (this.vTable && this.vTable.length == 0) {
      this.warningMsg = "No Dedicated Customers Data For Predictive";
    }
  }
  }

  isActive(tabId): boolean {
    return this.tab === tabId;
  }

  openCardTab(tabId, v) {
    this.tab = tabId;
    this.vTable = [];
    this.warningMsg = "";
    if (v.campaign_type == "Predictive" || v.campaign_type == "PREDICTIVE") {
      this.vTable = [];
      setTimeout(() => {
        this.warningMsg = "No Dedicated Customers Data For Predictive";
      }, 200);

    }
    else {
      let jsonData1=[];
      jsonData1= v.customer_details.filter(v => { return  v.stage == "NEW DATA" && v.rnrcount == 0 })
      this.warningMsg = "";
        if (jsonData1 && jsonData1.length == 0) {
          this.warningMsg = "No Data Found";
        }
    else{
      this.vTable =jsonData1;// v.customer_details;
      this.AllvTable = v.customer_details;
    }
    }
  }
  contactNameDetails(data: any) {
    //debugger;
    console.log("---",JSON.stringify(data));
    const dialogRef = this.dialog.open(ContactListCampaignContactDialogPageComponent, {
      width: '70%',
      disableClose: true,
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'true') {
        let _ele = {
          Name: data.name,
          Number: data.number,
          call_status: data.call_status,
          updated_at: data.updated_at,
          customkvs: data.customkvs,
          transactionid: data.transactionid,
          checkC2C: "c2cCallThere"
        }
        this.API.sendRowContactList(_ele);
        this.NoRequiredStartBtn = "NoNeedStartForC2C";
        this.CallChatPhoneClick('phone', _ele);
      }
      else if (result == 'false') {
        //alert("close");
        this.NoRequiredStartBtn = "";
      }


    });
  }
  callButtonClick(a) {
    //debugger;
    localStorage.setItem("c2cCallPresent", "availableC2C");
    localStorage.removeItem("noExitC2CInStartBtn");
    this.NoRequiredStartBtn = "NoNeedStartForC2C";
    const callSession = this.myservice.getLastCallSession();
    // if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
    // 	alert("Please Press Start for Calling");
    // 	return;
    // }
    if (callSession && callSession.internet_speed && callSession.internet_speed < 2) {
      alert("Internet speed slow try later");
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert("No Internet Network Connection available");
      return;
    }
    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later");
    // 	return;
    // }
    if (this._findteleco[0].definition == "KNOWLARITY") {
      if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
        alert("Sip connection not available try later");
        return;
      }
    } else if (this._findteleco[0].definition == "Twilio") {

    }
    else if (this._findteleco[0].definition == "TCL") {

    }
    else if (this._findteleco[0].definition == "TTS") {

    }
    let _typeSoftSSL='';
    if(a.campaign_type=="STP" || a.campaign_type=="E_APPLY"){
     _typeSoftSSL='SSL';
    }
    else{
     _typeSoftSSL='SSL';
    }
    let _ele = {
      Name: a.name,
      Number: a.number,
      call_status: a.call_status,
      updated_at: a.updated_at,
      customkvs: a.customkvs,
      transactionid: a.transactionid,
      checkC2C: "c2cCallThere",
      campignType:_typeSoftSSL
    }

    this.API.sendRowContactList(_ele);

    this.CallChatPhoneClick('phone', _ele);
  }
  statusFilterClick(type: any) {
    this.vTable = [];
    if (type == "NEW-DATA") {
      this.vTable = this.AllvTable.filter(v => { return v.stage == 'NEW DATA' });
    }
    else if (type == "FOLLOW-UP") {
      this.vTable = this.AllvTable.filter(v => { return (v.stage == 'FOLLOW-UP' || v.stage == 'FOLLOW-UP') });
    }
    else {
      this.vTable = this.AllvTable;
    }

  }
  phoneInputPress(event: any) {
    let _val;

    if ((document.getElementById('codeDialPad') as HTMLFormElement)) {
      _val = (document.getElementById('codeDialPad') as HTMLFormElement).value;
    }
    if (_val && _val.length >= 10) {
      event.preventDefault();
    }
    return event.charCode >= 48 && event.charCode <= 57;

  }
  onlyNumAlphabetValidation(event) {
    let inputValue = event.charCode;
    if (!(inputValue >= 65 && inputValue <= 121) && (inputValue != 32 && inputValue != 0) && !(inputValue >= 48 && inputValue <= 57)) {
      event.preventDefault();
    }
  }
  pasteTextPhone(event: ClipboardEvent) {
    event.preventDefault();
  }
  callWhatsapp(type, element) {
    let _obj = {
      "_ddlListExtraOption": this._ddlListExtraOption,
      "element": element
    }

    const dialogRef = this.dialog.open(DesktopContactListWhatsappUiDialogComponent, {
      width: '70%',
      height: '90%',
      disableClose: true,
      data: _obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

    });
  }
  check_dialler_setting() {
    let stateSettingsChat: any = this.myservice.get_stage_settings();
    let _setFilterDial = stateSettingsChat.extraoptions.filter(v => { return v.name == 'unscheduledcall' });
    if (_setFilterDial && _setFilterDial.length > 0) {
      this.isStageDial = false;
      if (_setFilterDial[0].definition == "off") {
        this.isStageDial = false;
      } else {
        this.isStageDial = true;
        //dialler enbale setting
          this.showHideStop = false;
          this.showHideStart = true;
        this.showHideDiallerPage=false;
        //this.dialleron_setting();
      }
      console.log(_setFilterDial);
    }
  }
}



export interface Element {
  name: string;
  show: boolean;
}



