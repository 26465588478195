<div class="row" style="padding: 31px 12px 31px 12px;" >
    <div class="col-md-12">
        <div class="m-div" style="height:auto">
            <p><span class="s-hd">Automatic WhatsApp</span></p>
        
        
            <div class="row">
                <div class="col-md-12 ">
                    <label class="lb2">Content</label><br>
                    <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline"  class="pb-0" >
                        <textarea matInput cdkTextareaAutosize
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="50"></textarea>
                        </mat-form-field>
                    </div>
            </div>
          
            <div class="row">
                <div class="col-md-12 text-right" style="position: relative;
                right: 20px;">
                <button class="saveSubmit">SAVE</button>
            </div>
        </div>
        <br>

            </div>
        </div>
        </div>
      

