<br>
<div  style="background: #f6f6f6;">
<ngb-alert [type]="alert.type" class="ng-alert-dialog" *ngIf="!alertClosed"  (close)="alertClosed = true">{{ alert.message }}</ngb-alert>
    <div class="row">

        <div class="col-md-4 col-sm-12 col-xs-12 pr-0">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Inbound Data</mat-card-title>
                </mat-card-header>
                <mat-card-content>

                    <table class="Cam-table">
                        <tr><td><span> Data</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="inboundDashboardData.total_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData1"><span>{{inboundDashboardData.total_calls_perc}}% ({{inboundDashboardData.total_calls}})</span></td>
                        </tr>
                        <tr><td><span>Received Calls</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="inboundDashboardData.received_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData2"><span>{{inboundDashboardData.received_calls_perc}}% ({{inboundDashboardData.received_calls}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Abondoned Calls</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="inboundDashboardData.abonden_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData3"><span>{{inboundDashboardData.abonden_calls_perc}}% ({{inboundDashboardData.abonden_calls}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Total AHT</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-four'" [value]="inboundDashboardData.total_aht_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData4"><span>{{inboundDashboardData.total_aht_perc}}% ({{inboundDashboardData.total_aht_label}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Avg AHT</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-five'" [value]="inboundDashboardData.avg_aht_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData5"><span>{{inboundDashboardData.avg_aht_perc}}% ({{inboundDashboardData.avg_aht_label}})</span>
                         </td>
                        </tr>
                      </table>
                </mat-card-content>
            </mat-card>
        </div>



        <div class="col-md-4 col-sm-12 col-xs-12 pr-0">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Agents Status</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Total Agents</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{inboundAgentsData['agents'].length}}   </span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Today Active</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.inboundAgentsData['Total Active Agents']}}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Contacted</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.inboundAgentsData['Contacted Agents']}}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Avg Connected Calls</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.inboundAgentsData['Avg Contacted Calls'] }}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Above Avg Connected Calls</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.inboundAgentsData['Agents Above Avg Connected Calls'] }}</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-md-4 col-sm-12 col-xs-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Call Status</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="tile-row-pie" style="justify-content: center;">
                        <div style="height: 240px; width: 240px;" [chart]="agentStatusChart"></div>
                        <div>
                            <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #e40f59;"></span><span style="padding-left: 5px;"> <span style="color: #e40f59; font-weight: 500; font-size: 20px;">{{inboundAgentsData['0-5 Min']}}</span> <p class="tile-text mb-1">0-5 Mins</p> </span></div>
                            <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #08566e;"></span><span style="padding-left: 5px;"> <span style="color: #08566e; font-weight: 500; font-size: 20px;">{{inboundAgentsData['5-30 Min']}}</span> <p class="tile-text mb-1">5-30 Mins</p> </span></div>
                            <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #1b7ec7;"></span><span style="padding-left: 5px;"> <span style="color: #1b7ec7; font-weight: 500; font-size: 20px;">{{inboundAgentsData['>30 Min']}}</span> <p class="tile-text mb-1">> 30 Mins</p> </span></div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-12 pr-0">
            <mat-card style="margin-top: 25px;min-height: 331px;">
              <mat-card-header>
                <mat-card-title>Calls Summary</mat-card-title>
              </mat-card-header>
              <mat-card-content>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div style="min-width: 240px; height: 240px; max-width: 240px;"
                       [chart]="donutChartOutbound">
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-4 text-right">
                  <p class="CN-Select">{{inboundDashboardData.received_calls}}<br> <span class="cn-sub-title">Connected</span> </p>
                  <p class="CN-Missed">{{inboundDashboardData.abonden_calls}}<br> <span class="cn-sub-title">Abandoned</span> </p>
                </div>
              </div>
              </mat-card-content>
            </mat-card>
        </div>
      <div class="col-md-8 col-sm-12 col-xs-12">
        <mat-card class="mat-card-table-responsive call-summary" style="padding: 0px !important;">
          <mat-card-header>
            <mat-card-title>Connected Vs Abandoned</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div [chart]="totalConnectedAbandonedChart"> </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="md-12 sm-12 xs-12">
        <mat-card class="mat-card-table-responsive">
            <div class="row pdt filter-div  align-items-center">
                <div class="col-md-2 col-sm-12 col-xs-12 ">
                    <button mat-button [matMenuTriggerFor]="export_menu" style="height: 38px;font-size: 17px;background: #dd133c !important;" aria-label="Example icon-button with a menu">
                        Reports
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                      <mat-menu #export_menu="matMenu">
                        <button mat-menu-item (click)="downloadInboundcallsReport()">Call Report</button>
                        <button mat-menu-item (click)="downloadAgentActivityReport()">Agent Activity Report</button>
                        <button mat-menu-item (click)="downloadAgentLoginReport()">Agent Login Report</button>
                      </mat-menu>
                </div>

                <div class="col-md-7" *ngIf="selectedTabSts == 'call_status'">
                                      <div ngbDropdown class="d-inline-block ml-2">
                        <button class="btn btn-outline-secondary" style="border-radius: 6px;" id="dropdownForm1" ngbDropdownToggle>{{selectAll ? 'All Selected' : selectedAgentsLen+' Agents'}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                          <div class="px-4 py-3" style="height: 300px;overflow-y: auto;">
                            <mat-checkbox (change)="updateCheck()"
                            class="example-margin"
                            [(ngModel)]="selectAll">
                          Select All
                        </mat-checkbox>
                            <section *ngFor="let ing of selectedAgents; let i = index" class="example-section">
                                <mat-checkbox (change)="selectChildren()"
                                   [(ngModel)]="ing.checked">
                                  {{ing.name}}
                                </mat-checkbox>
                              </section>

                          </div>
                        </div>

                      </div>

                      <mat-form-field class="ml-4"  style="width:90px">
                        <input matInput [matDatepicker]="frompicker" [max]="maxDate"  placeholder="From" readonly
                            (click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)" [(ngModel)]="callStartDate">
                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                        <mat-datepicker #frompicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field style="width:90px"  class="ml-3">
                        <input #fromInput matInput [matDatepicker]="topicker" placeholder="To" [max]="maxDate" readonly (click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)" [(ngModel)]="callEndDate">
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                    <button mat-button class="btnAdd ml-3" style="background: #dd133c !important;" (click)="refreshData()">Go</button>

                </div>


                <div class="col-md-5" *ngIf="selectedTabSts == 'slot_status'">
                    <!-- <mat-form-field >
                        <input matInput [matDatepicker]="frompicker" placeholder="From" readonly
                            (click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [(ngModel)]="slotStartDate">
                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                        <mat-datepicker #frompicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field  class="ml-3">
                        <input #fromInput matInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [(ngModel)]="slotEndDate">
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                    <button mat-button class="btnAdd ml-3" (click)="refreshDataCallSlot()">Go</button> -->
                    <button mat-button class="btnAdd ml-3" (click)="addSlot()"><i class="fa fa-cog" aria-hidden="true"></i></button>
                </div>

                <div class="col-md-3">
                    <mat-form-field style="width:100%">
                        <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" />
                        <i class="fa fa-search" matSuffix aria-hidden="true"></i>
                    </mat-form-field>
                </div>
            </div>

            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                    *ngIf="selectedTabSts == 'call_status'" style="box-shadow: none !important;">
                    <ng-container matColumnDef="user_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Id </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Name </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Live Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span class="statusCircle" style="position: relative;margin: 0;"
                                [ngStyle]="{'background-color': element.color_code, border: 'none' }"></span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ib_total_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> IB Calls Offered </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ib_total_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ib_received_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>IB Calls Answered </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ib_received_calls || 0 }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ib_abonden_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> IB Calls Abandoned </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.ib_abonden_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ib_total_aht_in_sec">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> IB AHT </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ getDivision((element.ib_total_aht_in_sec || 0),60,2) }} <br/>[{{ element.ib_aht_in_format || "0:0:0" }}]</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ob_total_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> OB Calls Dialled </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ob_total_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ob_received_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> OB Calls Connected </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ob_received_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ob_abonden_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> OB Calls Not Connected </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ob_abonden_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ob_total_aht_in_sec">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> OB AHT </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ getDivision((element.ob_total_aht_in_sec || 0),60,2) }}<br/>[{{ element.ob_aht_in_format || "0:0:0" }}]</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="login_hours">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Overall Login Hrs </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.login_hours || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="audited_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Audited Calls </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.audited_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="avg_score">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Avg. Audited Score </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.avg_score || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ctc_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>C-T-C Audited </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ctc_calls || 0 }}</mat-cell>
                    </ng-container><ng-container matColumnDef="ctc_score">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Avg. C-T-C Score </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ctc_score || 0 }}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef=displayedColumnsCallStatus></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsCallStatus"></mat-row>
                </table>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                    *ngIf="selectedTabSts == 'slot_status'" style="box-shadow: none !important;">
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.date }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="time">
                        <mat-header-cell *matHeaderCellDef> Slot Time </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.time }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="capacity">
                        <mat-header-cell *matHeaderCellDef> Capacity </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.capacity }}</mat-cell>

                    </ng-container>
                    <ng-container matColumnDef="booked">
                        <mat-header-cell *matHeaderCellDef> Booked </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.booked }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="waiting">
                        <mat-header-cell *matHeaderCellDef> Waiting </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.waiting }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="occupancy">
                        <mat-header-cell *matHeaderCellDef> Occupancy </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.occupancy }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.status }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="id" >
                        <mat-header-cell *matHeaderCellDef [style.display]="'none'"> Id </mat-header-cell>
                        <mat-cell *matCellDef="let element" [style.display]="'none'">{{ element.id }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="options">
                        <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
                        <mat-cell *matCellDef="let element" (click)="editSlot(element)"><i class="fa fa-edit CR" style="font-size: 18px;color: #526778;"></i></mat-cell>

                    </ng-container>

                    <mat-header-row *matHeaderRowDef=displayedColumnsCallSlot></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsCallSlot"></mat-row>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

        </mat-card>

    </div>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>
