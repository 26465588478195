<mat-card style="box-shadow: none;overflow-x: hidden;overflow-y: auto;height: 90%;">
  <mat-card-header>
    <!-- [{{ agent_obj.date }}] Activity Timings - <span class="agent_name">{{ agent_obj.name }}</span>
    <button mat-icon-button aria-label="Close Popup" class="close_button" (click)="closeWindow()">
      <mat-icon>cancel</mat-icon>
	</button> -->
	<button mat-icon-button aria-label="Close Popup" class="close_button" style="position: absolute;right: 0px;top: 0px;" (click)="closeWindow()">
		<mat-icon>cancel</mat-icon>
	  </button>
	<table class="full_table">
		<tr>
			<td colspan="2">
				<div class="element_div">
					<div class="inline_div date_key">Date: </div>
					<div class="inline_div date_value">{{ agent_obj.date }}</div>
				</div>
			</td>
		</tr>
		<tr>
			<td>
				<div class="element_div">
					<div class="inline_div key_div">Agent Name: </div>
					<div class="inline_div value_div">{{ agent_obj.name }}</div>
				</div>
			</td>
			<td>
				<div class="element_div">
					<div class="inline_div key_div">Agent Email: </div>
					<div class="inline_div value_div">{{ agent_obj.email }}</div>
				</div>
			</td>
		</tr>
		<tr>
			<td>
				<div class="element_div">
					<div class="inline_div key_div">Login Time: </div>
					<div class="inline_div value_div">{{ agent_obj.login_time }}</div>
				</div>
			</td>
			<td>
				<div class="element_div">
					<div class="inline_div key_div">Logout Time: </div>
					<div class="inline_div value_div">{{ agent_obj.logout_time }}</div>
				</div>
			</td>
		</tr>
		<tr>
			<td>
				<div class="element_div">
					<div class="inline_div key_div">First Activity </div>
					<div class="inline_div value_div">{{ firstActivity_msg() }}</div>
				</div>
			</td>
			<td>
				<div class="element_div">
					<div class="inline_div key_div">Last Activity </div>
					<div class="inline_div value_div">{{ lastActivity_msg() }}</div>
				</div>
			</td>
		</tr>
	</table>
  </mat-card-header>
  <mat-card-content>
	  <div *ngIf="!ERR_MSG">
		<table mat-table [dataSource]="activity_table" class="mat-elevation-z8">
			<ng-container matColumnDef="type">
				<th mat-header-cell *matHeaderCellDef>Type </th>
				<td mat-cell *matCellDef="let element" class="CR">{{element.type}} </td>
			  </ng-container>
			  <ng-container matColumnDef="reason">
				<th mat-header-cell *matHeaderCellDef>Reason </th>
				<td mat-cell *matCellDef="let element" class="CR">{{element.reason}} </td>
			  </ng-container>
			  <ng-container matColumnDef="start_time">
				<th mat-header-cell *matHeaderCellDef> Start Time </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.start_time}} </td>
			  </ng-container>
	
			  <ng-container matColumnDef="end_time">
				<th mat-header-cell *matHeaderCellDef> End Time </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.end_time}} </td>
			  </ng-container>
	
			  <ng-container matColumnDef="duration">
				<th mat-header-cell *matHeaderCellDef> Duration </th>
				<td mat-cell *matCellDef="let element" class="CR"> {{element.duration}} </td>
			  </ng-container>
	
			  <tr mat-header-row *matHeaderRowDef="activity_table_columns; sticky: true"></tr>
			  <tr mat-row *matRowDef="let row; columns: activity_table_columns;"></tr>
		</table>
	  </div>
	  <div *ngIf="ERR_MSG">{{ERR_MSG}}</div>
  </mat-card-content>
  <mat-card-actions>
	<button 
		mat-icon-button aria-label="Export excel" 
		matTooltip="Export in excel" 
		matTooltipPosition="above" 
		(click)="exportExcel()">
		Export 
		<mat-icon>file_download</mat-icon>
	</button>
  </mat-card-actions>
</mat-card>