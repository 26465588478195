<div class="TA-adjust-Top">


<div class="row pdt">
  <div class="col-md-3 col-sm-12 col-xs-12"></div>
    <div class="col-md-6 col-sm-12 col-xs-12">
      <p *ngIf="showHidePassingMessage" [style.color]="_color" style="color: green;font-weight: bold;font-size: 20px;">{{passingMessage}}</p>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12">
    <mat-form-field style="font-size: 12px;" appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH A TRAINER" [(ngModel)]="removeSearchValue">
        <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
      </mat-form-field>

    </div>
</div>
<div class="row" style="margin-top: -10px;">
    <div class="col-md-3 col-sm-12 col-xs-12">

        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">
            <mat-form-field  [ngClass]="'inputClr'">
                <input matInput [matDatepicker]="frompicker" [min]="todayDate"  (dateChange)="change_date('start_date', $event)" placeholder="From" readonly (click)="frompicker.open()">
                <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                <mat-datepicker #frompicker></mat-datepicker>
              </mat-form-field>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">
            <mat-form-field  [ngClass]="'inputClr'">
                <input matInput [matDatepicker]="topicker" [min]="todayDate"  placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)">
                <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                <mat-datepicker #topicker></mat-datepicker>
              </mat-form-field>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">
            <button mat-button class="T-createbtn" (click)="checkAvailibilty()">CHECK AVAILABILITY</button>
        </div>
        </div>
    </div>

<br>
<div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

<ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef>NAME </th>
  <td mat-cell *matCellDef="let element">{{element.name}} </td>
</ng-container>

<ng-container matColumnDef="batch_code">
  <th mat-header-cell *matHeaderCellDef> CURRENT BATCH CODES </th>
  <td mat-cell *matCellDef="let element"> {{element.batch_code}} </td>
</ng-container>

<ng-container matColumnDef="availability">
  <th mat-header-cell *matHeaderCellDef>AVAILABILITY </th>
  <td mat-cell *matCellDef="let element"  [ngStyle]="getAvailibiltyColor(element.availiability)"> {{element.availability}} </td>
</ng-container>

  <ng-container matColumnDef="assign">
    <th mat-header-cell *matHeaderCellDef> ASSIGN </th>
    <td mat-cell *matCellDef="let element"> <span class="detailsCss" (click)="assignBatch(element)">Assign Batch</span> </td>
  </ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator  [ngClass]="'paginator-training'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>




