<mat-card style="margin-top:1.5%">
  <div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">

      <h2 class="d-head">SEND MESSAGE<img src="../assets/underline.svg" class="linebelow"> </h2>
    </div>
  </div>
  <div class="searchFix" *ngIf="showHideBatchButton">
    <mat-form-field style="font-size: 12px;" appearance="outline">
      <input #searchInput matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH BATCH">
      <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
    </mat-form-field>
  </div>
  <br>
  <div>
    <mat-button-toggle-group [ngClass]="'tgGroupClass'" style="z-index: 9;" name="fontStyle" aria-label="Font Style"
      (change)="changeTogleTrainer($event.value)">
      <mat-button-toggle [ngClass]="'tgClass'" value="batchBtn" checked>Batch</mat-button-toggle>
      <mat-button-toggle [ngClass]="'tgClass'" value="agentBtn">Agent</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <br>

  <div *ngIf="showHideBatchButton">
    <div class="scrollTable" style="height: 700px;">

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef>

          </th>
          <td mat-cell *matCellDef="let element; let i = index;">

            <mat-checkbox [(ngModel)]="element.isSelected" [checked]="element.isSelected" (change)="selectForMessageBatch( $event, i, element.id )"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="batchCode">
          <th mat-header-cell *matHeaderCellDef>BATCH CODE </th>
          <td mat-cell *matCellDef="let element">{{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="batchStarts">
          <th mat-header-cell *matHeaderCellDef> BATCH STARTS </th>
          <td mat-cell *matCellDef="let element"> {{element.start_date | date:'yyyy-MM-dd'}} </td>
        </ng-container>
        <ng-container matColumnDef="batchEnds">
          <th mat-header-cell *matHeaderCellDef> BATCH ENDS </th>
          <td mat-cell *matCellDef="let element"> {{element.end_date  | date:'yyyy-MM-dd'}} </td>
        </ng-container>

        <ng-container matColumnDef="trainer">
          <th mat-header-cell *matHeaderCellDef> TRAINER </th>
          <td mat-cell *matCellDef="let element"> {{element.trainer_name}} </td>
        </ng-container>
        <ng-container matColumnDef="timeScedule">
          <th mat-header-cell *matHeaderCellDef> TIME SCEDULE </th>
          <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <ng-container matColumnDef="groupHeader">
          <td colspan="999" mat-cell *matCellDef="let groupBy" style="color: #3fb90d;"><strong>{{groupBy.date | date:'yyyy-MM-dd'}}</strong></td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
      </table>

    </div>
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
    <br>
    <div class="row pdt">

      <div class="col-md-12 col-sm-12 col-xs-12 rightside">
        <button mat-button class="cancelbtn" (click)="resetIds()">Cancel</button>
        <button mat-button class="submitbtn" (click)="sendNotificationDialog()">SEND NOTIFICATION</button>
      </div>

    </div>
    <br><br>
  </div>


  <div *ngIf="showHideAgentButton">
    <app-send-message-agent></app-send-message-agent>
  </div>

</mat-card>