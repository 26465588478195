<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
  <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
    <app-menu [navigateLink]="currentTitle"></app-menu>
  </mat-drawer>
  <app-logout></app-logout>
  <div class="row">
    <div class="col-lg-12 col-md-12" style="background-color: #f6f6f6 !important;">
      <h4 class="titlehead" style="margin-top: 20px; padding-left: 20px; position: relative; z-index: 9;">{{get_dashboard_name()}} Dashboard</h4>
      <div class="container-fluid main-section-layout">
        <!-- <ngb-alert [type]="alert.type" class="ng-alert-dialog" *ngIf="!alertClosed" (close)="alertClosed = true">
          {{ alert.message }}</ngb-alert> -->
        <div class="row">
          <mat-card class="CN-Card wd-100" style="box-shadow: 0 2px 15px #d2d5d95e;">
            <div class="row">
              <div class="col-md-3 col-sm-12 col-xs-12">
                <span>
                  <mat-select [ngClass]="'arrowDDLcs'" class="dropdownCs" [(ngModel)]="selectedProject">
                    <mat-option [value]="selectedProject">{{selectedProject}}</mat-option>
                  </mat-select>
                </span>
              </div>
              <div class="col-md-3 col-sm-12 col-xs-12">
                <mat-select [ngClass]="'arrowDDLcs'" class="dropdownCs" [(value)]="selectedChannel"
                  (selectionChange)="navigateTo($event.value)">
                  <mat-option value="view/outbound"
                    *ngIf="settings.web && settings.web[0].definition.includes('outbound')==true">Outbound</mat-option>
                  <mat-option value="dashboard-inbound"
                    *ngIf="settings.web && settings.web[0].definition.includes('inbound')==true">Inbound</mat-option>
                  <mat-option value="whatsapp-dashboard"
                    *ngIf="settings.web && settings.web[0].definition.includes('whatsapp')==true">Whatsapp</mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12" style="position: relative;top: -9px;">
                <mat-form-field style="width:110px">
                  <input matInput [matDatepicker]="frompicker" [max]="maxDate" placeholder="From" readonly
                    (click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)"
                    [(ngModel)]="callStartDate">
                  <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                  <mat-datepicker #frompicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="ml-3" style="width:110px">
                  <input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate" placeholder="To" readonly
                    (click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)"
                    [(ngModel)]="callEndDate">
                  <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                  <mat-datepicker #topicker></mat-datepicker>
                </mat-form-field>
                <button mat-button class="action_btn ml-3" (click)="open_agents()">
                  SELECT AGENTS
                </button>
                <button mat-button class="btnAdd ml-3" (click)="refresh_dashboard()">GO</button> <!--*ngIf="!isToday"-->

                <!-- <button mat-button class="btnAdd ml-3" (click)="refreshDatafortoday()">Today</button> -->
              </div>
              <!-- <div class="col-md-3 col-sm-12 col-xs-12 cn-dat">{{getCurrentDate()}}</div> -->
            </div>
          </mat-card>
        </div>

        <div class="row">
          <mat-progress-bar *ngIf="progress_bar$.visible" mode="buffer" color="primary" [value]="progress_bar$.value"
            [bufferValue]="progress_bar$.buffer_value">
          </mat-progress-bar>
          <div class="flex-tile-container wd-100 summary_tiles">
            <div class="flex-tile-item wd-100">
              <mat-card>
                <mat-card-header>
                  <mat-card-title class="card-title">Channels
                    <!-- <small *ngIf="channel_summary$.is_loading"><i class="fas fa-sync-alt fa-spin" matSuffix
                        aria-hidden="true"></i></small>
                    <small *ngIf="channel_summary$.is_error"><i class="fas fa-exclamation-triangle"></i></small> -->
                  </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                  <table class="Cam-table">
                    <tr>
                      <td><span>Outbound Calls</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-two'"
                          [value]="channel_summary$.outgoing_call_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData2 value-box"><span>{{channel_summary$.outgoing_call | number}}
                          ({{this.utils.get_decimal_val(channel_summary$.outgoing_call_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span> Inbound Calls</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                          [value]="channel_summary$.incoming_call_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData1 value-box"><span>{{channel_summary$.incoming_call | number}}
                          ({{this.utils.get_decimal_val(channel_summary$.incoming_call_percent)}}%)</span></td>
                    </tr>
                    <tr>
                      <td><span>Incoming Text</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-three'"
                          [value]="channel_summary$.incoming_text_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData3 value-box"><span>{{channel_summary$.incoming_text | number}}
                          ({{this.utils.get_decimal_val(channel_summary$.incoming_text_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span>Outgoing Text</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-four'"
                          [value]="channel_summary$.outgoing_text_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData4 value-box"><span>{{channel_summary$.outgoing_text | number}}
                          ({{this.utils.get_decimal_val(channel_summary$.outgoing_text_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span>Chat</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-five'"
                          [value]="channel_summary$.chats_precent"></mat-progress-bar>
                      </td>
                      <td class="tdData5 value-box"><span>{{channel_summary$.chats | number}}
                          ({{this.utils.get_decimal_val(channel_summary$.chats_precent)}}%)</span>
                      </td>
                    </tr>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="flex-tile-item wd-100">
              <mat-card>
                <mat-card-header>
                  <mat-card-title class="card-title">Follow Ups
                    <!-- <small *ngIf="followups$.is_loading"><i class="fas fa-sync-alt fa-spin" matSuffix
                        aria-hidden="true"></i></small>
                    <small *ngIf="followups$.is_error"><i class="fas fa-exclamation-triangle"></i></small> -->
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>

                  <table class="Cam-table">
                    <tr>
                      <td><span>Total</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                          [value]="followups$.followup_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData1 value-box"><span>{{followups$.followup | number}}
                          ({{this.utils.get_decimal_val(followups$.followup_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span>Completed</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-two'"
                          [value]="followups$.followup_done_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData2 value-box"><span>{{followups$.followup_done | number}}
                          ({{this.utils.get_decimal_val(followups$.followup_done_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span>To Do</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-three'"
                          [value]="followups$.followup_todo_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData3 value-box"><span>{{followups$.followup_todo | number}}
                          ({{this.utils.get_decimal_val(followups$.followup_todo_percent)}}%)</span>
                      </td>
                    </tr>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="flex-tile-item wd-100">
              <mat-card>
                <mat-card-header>
                  <mat-card-title class="card-title">Agents Status
                    <!-- <small *ngIf="agents_status$.is_loading"><i class="fas fa-sync-alt fa-spin" matSuffix
                        aria-hidden="true"></i></small>
                    <small *ngIf="agents_status$.is_error"><i class="fas fa-exclamation-triangle"></i></small> -->
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content class="big_summary_card">
                  <table class="Cam-table">
                    <tr #total_agents>
                      <td><span>Total Agents</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                          [value]="agents_status$.agents_total_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData1 value-box"><span>{{agents_status$.agents_total | number}}</span>
                      </td>
                    </tr>
                    <tr #logged_in>
                      <td><span>Today Logged In</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-two'"
                          [value]="agents_status$.agents_login_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData2 value-box"><span>{{agents_status$.agents_login | number}}</span>
                      </td>
                    </tr>
                    <tr #agent_active>
                      <td><span>Agents Active</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-three'"
                          [value]="agents_status$.agents_active_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData3 value-box"><span>{{agents_status$.agents_active | number}}</span>
                      </td>
                    </tr>
                    <tr #agent_on_call>
                      <td><span>Agents On Call</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-four'"
                          [value]="agents_status$.agents_on_call_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData4 value-box"><span>{{agents_status$.agents_on_call | number}}</span>
                      </td>
                    </tr>
                    <tr #agent_on_idle>
                      <td><span>Agents Idle</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-five'"
                          [value]="agents_status$.agents_on_idle_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData5 value-box"><span>{{agents_status$.agents_on_idle | number}}</span>
                      </td>
                    </tr>
                    <tr #agent_logout>
                      <td><span>Agents Logged Out</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                          [value]="agents_status$.agents_logout_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData1 value-box"><span>{{agents_status$.agents_logout | number}}</span>
                      </td>
                    </tr>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="flex-tile-item wd-100">
              <mat-card>
                <mat-card-header>
                  <mat-card-title class="card-title">
                    <!-- Total Calls -  -->
                    Customer Calls
                    <!-- <small *ngIf="calls_summary$.is_loading"><i class="fas fa-sync-alt fa-spin" matSuffix
                        aria-hidden="true"></i></small>
                    <small *ngIf="calls_summary$.is_error"><i class="fas fa-exclamation-triangle"></i></small> -->
                  </mat-card-title>
                  <button mat-icon-button aria-label="Open in new tab" class="feedback_ext"
                    matTooltip="Detailed Form Dispositions" matTooltipPosition="before" (click)="openFeedback()">
                    <mat-icon style="color:#000000">open_in_new</mat-icon>
                  </button>
                </mat-card-header>
                <mat-card-content class="big_summary_card">

                  <table class="Cam-table">
                    <tr>
                      <td><span>Calls Placed</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                          [value]="this.calls_summary$.calls_dialled_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData1 value-box"><span>{{this.calls_summary$.calls_dialled | number}}
                          ({{this.utils.get_decimal_val(this.calls_summary$.calls_dialled_percent)}}%)</span></td>
                    </tr>
                    <tr>
                      <td><span>RNR</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-two'"
                          [value]="this.calls_summary$.calls_rnr_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData2 value-box"><span>{{this.calls_summary$.calls_rnr | number}}
                          ({{this.utils.get_decimal_val(this.calls_summary$.calls_rnr_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span>Contacted</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-three'"
                          [value]="this.calls_summary$.calls_contacted_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData3 value-box"><span>{{this.calls_summary$.calls_contacted | number}}
                          ({{this.utils.get_decimal_val(this.calls_summary$.calls_contacted_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span>Unique Contacted</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-four'"
                          [value]="this.calls_summary$.calls_unique_contacted_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData4 value-box"><span>{{this.calls_summary$.calls_unique_contacted | number}}
                          ({{this.utils.get_decimal_val(this.calls_summary$.calls_unique_contacted_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span>New Data Dialled</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-five'"
                          [value]="this.calls_summary$.calls_new_data_dialled_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData5 value-box"><span>{{this.calls_summary$.calls_new_data_dialled | number}}
                          ({{this.utils.get_decimal_val(this.calls_summary$.calls_new_data_dialled_percent)}}%)</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span>Manual Data Dialled</span>
                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'"
                          [value]="this.calls_summary$.calls_manual_dialled_percent"></mat-progress-bar>
                      </td>
                      <td class="tdData1 value-box"><span>{{this.calls_summary$.calls_manual_dialled | number}}
                          ({{this.utils.get_decimal_val(this.calls_summary$.calls_manual_dialled_percent)}}%)</span>
                      </td>
                    </tr>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="flex-tile-item wd-100">
              <mat-card>
                <mat-card-header>
                  <mat-card-title class="card-title">Lead Dispositions
                    <!-- <small *ngIf="lead_disposition$.is_loading"><i class="fas fa-sync-alt fa-spin" matSuffix
                        aria-hidden="true"></i></small>
                    <small *ngIf="lead_disposition$.is_error"><i class="fas fa-exclamation-triangle"></i></small> -->
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content class="big_summary_card">
                  <table class="Cam-table">
                    <ng-container *ngFor="let stage of order_change_stages(); let i=index">
                      <tr #stage_cal [calc]="get_stage_calculation(stage)" [color]="get_progress_color(i)">
                        <td><span>{{display_stage_name(stage)}}</span>
                          <mat-progress-bar mode="determinate" [ngClass]="stage_cal.color[0]"
                            [value]="stage_cal.calc.percentage"></mat-progress-bar>
                        </td>
                        <td [ngClass]="stage_cal.color[1]" class="value-box"><span>{{stage_cal.calc.value | number}}
                            ({{this.utils.get_decimal_val(stage_cal.calc.percentage)}}%)</span>
                        </td>
                      </tr>
                    </ng-container>
                    <tr></tr>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>

        <div class="summary_table">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Time Summary</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-table [dataSource]="dataSourceCallSummary">
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.type}}</mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="login_time">
                  <mat-header-cell *matHeaderCellDef> Login Hours </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.login_time}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="spoken_time">
                  <mat-header-cell *matHeaderCellDef> Talk Time </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.spoken_time}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="wrap_time">
                  <mat-header-cell *matHeaderCellDef> Wrap Time </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.wrap_time}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="wait_time">
                  <mat-header-cell *matHeaderCellDef> Wait Time </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.wait_time}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="idle_time">
                  <mat-header-cell *matHeaderCellDef> Idle Time </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.idle_time}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="active_time">
                  <mat-header-cell *matHeaderCellDef> Active Time </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.active_time}} </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumnsCallSummary"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsCallSummary;"></mat-row>
              </mat-table>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="summary_table">
          <mat-card >
            <mat-card-header>
              <mat-card-title>Supervisor Summary</mat-card-title>
            </mat-card-header>
            <mat-card-content class="mat_card_summary">
              <app-tl-table-counts [table_data]="dataSource"></app-tl-table-counts>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="summary_table">
          <mat-card>
            <p class="textAlertMessage" style="font-size: 18px;" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
            <mat-card-header>
              <mat-card-title>
                Agents Summary
              </mat-card-title>
            </mat-card-header>
            <div class="row pdt filter-div head-bar">
              <div class="head-item">
                <!-- <span class="date-label">10-Jun-2020</span> -->
                <button mat-button class="no-hover" [matMenuTriggerFor]="export_menu" aria-label="Example icon-button with a menu">
                  REPORTS
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #export_menu="matMenu">
                  <!-- <button mat-menu-item (click)="downloadReportGeneral()">Call Report</button> -->
                  <button mat-menu-item (click)="downloadReportGeneral()">Call Report</button>
                  <button mat-menu-item (click)="downloadReportGeneral('Archived')">Call Report(Archived)</button>
                  <button mat-menu-item (click)="downloadAuditReport()">Audit Report</button>
                  <button mat-menu-item (click)="agentSummaryReport()">Agents Summary Report</button>
                  <button mat-menu-item (click)="exportAgentActivity()">Agents Activity Report</button>
                </mat-menu>
              </div>
              <!-- Sort by activity starts -->
              <div class="head-item">
                <button mat-button class="no-hover" [matMenuTriggerFor]="sortBy_menu" aria-label="Filter By">
                  FILTER
                  <mat-icon>filter_list</mat-icon>
                </button>
                <mat-menu #sortBy_menu="matMenu">
                  <button mat-menu-item (click)="resetTableDataByActivityFilter()">None</button>
                  <button mat-menu-item (click)="filterTableDataByActivity('Active')">Active</button>
                  <button mat-menu-item (click)="filterTableDataByActivity('Break')">Break</button>
                  <button mat-menu-item (click)="filterTableDataByActivity('Login')">Login</button>
                  <button mat-menu-item (click)="filterTableDataByActivity('Logout')">Logout</button>
                  <button mat-menu-item (click)="filterTableDataByActivity('null')">No Activity</button>
                </mat-menu>
              </div>
              <div class="head-item">
                <mat-form-field class="wdh search-inputbox">
                  <input matInput placeholder="Search" #_searchValue (keyup)="applyFilter($event.target.value)" />
                  <i class="fa fa-search" matSuffix aria-hidden="true"></i>
                </mat-form-field>
              </div>
            </div>

            <div class="table-responsive">
              <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 das-table"
                *ngIf="selectedTabSts == 'call_status'" style="box-shadow: none !important;">
                <ng-container matColumnDef="user_id">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Id </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.user_id }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Name </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="notification">
                  <mat-header-cell *matHeaderCellDef>Notification </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <mat-icon class="notification_icon">notifications_outline</mat-icon>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span class="actionBtnV1" *ngIf="getBargeinBtn(element)"
                      (click)="actionBergewishper(element,'BI')"><span class="spanV1">Click To </span> <br><span
                        class="spanV2"> Barge IN</span></span>
                    <span class="actionBtnV1" *ngIf="getBargeToWishperBtn(element)"
                      (click)="actionBergewishper(element,'BW')"><span class="spanV1">Barge To </span><br><span
                        class="spanV2"> Whisper</span> </span>
                    <span class="actionBtnV1" *ngIf="getWishperToBargeBtn(element)"
                      (click)="actionBergewishper(element,'WB')"><span class="spanV1"> Whisper To</span><br><span
                        class="spanV2"> Barge</span> </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="live_status">
                  <mat-header-cell *matHeaderCellDef> Latest Status </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div *ngIf="isToday" style="display: grid;">
                      <span style="font-weight: bold;" [ngStyle]="{'color': element.activity_color}">{{
                        element.last_activity }}</span>
                      <span>{{element.sub_disposition}}</span>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef> Latest Activity At </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div *ngIf="isToday" style="display: grid;">
                      <!-- <span style="font-weight: bold;" [ngStyle]="{'color': element.activity_color}">{{ element.last_activity }}</span> -->
                      <span>{{element.duration_now}}</span>
                      <span style="font-size: 10px;" matTooltip="Last Sync At - {{element.last_activity_at}}"
                        matTooltipPosition="above">{{element.last_activity_at}}</span>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="dialled">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Calls Placed
                    <!-- Dialled  -->
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.dialled }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="unique_dialled">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <!-- Unique Dialled  -->
                    Unique Calls Placed
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.unique_dialled }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="contacted">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Contacted </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.contacted }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="unique_contacted">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Unique Contacted </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.unique_contacted }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="rnr">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> RNR </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.rnr }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="forms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Forms </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.forms }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="login_time_hms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Login Hours </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.login_time_hms }}</mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="unavailable_time_hms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Unavailable Time </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.unavailable_time_hms }}</mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="idle_time_hms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Idle Time </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.idle_time_hms }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="wait_time_hms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Wait Time </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.wait_time_hms }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="spoken_time_hms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Talk Time </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.spoken_time_hms }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="wrap_time_hms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Wrap Time </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.wrap_time_hms }}</mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="break_time_hms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Break Time </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.break_time_hms }}</mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="active_time_hms">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Active Time </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.active_time_hms }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="aht">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> AHT </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.aht }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="audit_score">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Audit score </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.audit_score }}</mat-cell>
                </ng-container>
                <!-- Dynamic Assigning of sales stages to the table -->
                <ng-container *ngIf="lead_disposition_names.length">
                  <ng-container *ngFor="let stage of lead_disposition_names; let i = index" [matColumnDef]="stage">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{display_stage_name(stage)}} </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element[stage] }}</mat-cell>
                  </ng-container>
                </ng-container>

                <ng-container matColumnDef="ctc_score">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> CTC score </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.ctc_score }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="connectivity">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Connectivity </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div *ngIf="isToday" style="display: grid;">
                      <span>{{element.connectivity_src}}</span>
                      <span style="font-weight: bold;" [ngStyle]="{'color': element.connect_color}">{{
                        element.connect_status }}</span>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="more">
                  <mat-header-cell *matHeaderCellDef> More </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="Open Detail Timings" class="" matTooltip="Details"
                      matTooltipPosition="below" (click)="openDetailActivity(element)">
                      <mat-icon style="color: #fd6c21 !important;">launch</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef=displayedColumnsCallStatus></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsCallStatus"></mat-row>
              </mat-table>
            </div>
            <mat-paginator [pageSizeOptions]="[5,10, 20, 30, 50]" showFirstLastButtons></mat-paginator>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div id="cover-spin" *ngIf="currentLoader"></div>
</mat-drawer-container>