import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import * as _ from 'underscore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MyserviceService } from '../myservice.service';
import { RtddataService } from '../rtdData.service';

@Component({
	selector: 'app-badashboard-qde-page',
	templateUrl: './badashboard-qde-page.component.html',
	styleUrls: ['./badashboard-qde-page.component.scss']
})
export class BadashboardQdePageComponent implements OnInit, OnDestroy {

	_fetchData: any = [];
	agentFeed:Subscription;
	sortedData: BehaviorSubject<[]> = new BehaviorSubject([]);
	groupid:any;
	group_level_data:any;
	groupdata=[];
	
	constructor(private rtd: RtddataService, private myService: MyserviceService) {

	}
	ngOnInit() {
		this.getRtdValues();
	}

	formTable(arr?){
		this._fetchData = []
		if(arr && arr.length){
			this._fetchData = arr;
		}
		this.sortedData.next(this._fetchData.slice());
	}

	getRtdValues() {
		this.group_level_data = this.myService.get_bidashboard();
		this.groupid = this.myService.get_asm_groupid();
		   console.log(JSON.stringify(this.group_level_data));
		   this.groupdata = Object.values(this.group_level_data);
		this.agentFeed = this.rtd.agentFeed.subscribe(data => {
			//console.log('agent feed', data);
			// let finaldata =[];
			// if(this.groupid)
			// {
			// 	console.log('agent feed', data);
			// 	for(let i in data)
			// 	{
			// 		//console.log(data[i]);
			// 		if(data[i].groupid == this.groupid)
			// 		{
			// 			finaldata.push(data[i]);
			// 		}
			// 	}
			// 	console.log(finaldata);
			// }
			// else{
			// 	console.log('agent feed', data);
				
			// 	for(let i in data)
			// 	{
			// 		//console.log(data[i])
			// 			finaldata.push(data[i]);
					
			// 	}
			// 	console.log(finaldata);
			// }
			// console.log(finaldata);
			let tlbase = _.groupBy(data, 'tl_name');
			// console.log('tlbase', tlbase);
			let arr = [];
			for(let tl in tlbase){
				let obj:any = {};
				obj.title = tl;
				obj.rData = [];
				tlbase[tl].forEach((agent:any) => {
					let contactedAgentcount = this.get_division(agent.rtd_contacted_count,agent.rtd_signedin_count);
					let qdeAgentcount = this.get_division(agent.rtd_qdegen_count,agent.rtd_signedin_count);
					obj.rData.push({
						name: agent.head, 
						qdeGenerated: agent.rtd_qdegen_count, 
						qdeRejected: agent.rtd_qderej_count, 
						contactedAgent: contactedAgentcount, 
						qdeAgent: qdeAgentcount,
						score: ((0.3 * Number(contactedAgentcount)) + (0.7 * Number(qdeAgentcount))).toFixed(2)
					})
				})
				arr.push(obj);
			}
			// console.log('after add data', arr);
			this.formTable(arr);
		})
	}

	sortData(sort: Sort) {
		const data = this._fetchData.slice();
		if (!sort.active || sort.direction === '') {
			this.sortedData = data;
			return;
		}

		this.sortedData = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'qdeGenerated':
					return this.compare(a.qdeGenerated, b.qdeGenerated, isAsc);
				case 'qdeRejected':
					return this.compare(a.qdeRejected, b.qdeRejected, isAsc);
				case 'contactedAgent':
					return this.compare(a.contactedAgent, b.contactedAgent, isAsc);
				case 'qdeAgent':
					return this.compare(a.qdeAgent, b.qdeAgent, isAsc);
				case 'score':
					return this.compare(a.score, b.score, isAsc);
				default:
					return 0;
			}
		});
	}
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	getSummationData(variable){
		let total = 0;
		let dataValue:any = this.sortedData.getValue();
		if(dataValue && dataValue.length){
			for (let j = 0; j < dataValue.length; j++) {
				if(dataValue[j].rData && dataValue[j].rData.length){
					for (let k = 0; k < dataValue[j].rData.length; k++)
						total += Number(dataValue[j].rData[k][variable]);
				}
			}
		}
		return total;
	}
	getGenerated(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].qdeGenerated);
		return total;
	}
	getAllGenerated() {
		return this.getSummationData('qdeGenerated');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].qdeGenerated);
		}
		return total; */
	}


	getRejected(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].qdeRejected);
		return total;
	}
	getAllRejected() {
		return this.getSummationData('qdeRejected');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].qdeRejected);
		}
		return total; */
	}

	getContactedAgent(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].contactedAgent);
		return total;
	}
	getAllContactedAgent() {
		return this.getSummationData('contactedAgent');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].contactedAgent);
		}
		return total; */
	}

	getAgent(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].qdeAgent);
		return total;
	}
	getAllAgent() {
		return this.getSummationData('qdeAgent');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].qdeAgent);
		}
		return total; */
	}

	getScore(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].score);
		return total;
	}
	getAllScore() {
		return this.getSummationData('score');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].score);
		}
		return total; */
	}
	// get_division(num, denm,decimals){
	// 	if(denm > 0){
    //         if(num > 0){
    //             if(decimals){
    //                 //console.log('printing decimal',((num/denm)*100).toFixed(decimals));
    //                 return parseFloat(((num/denm)).toFixed(0));
    //             }else{
    //                 return parseFloat(((num/denm)).toFixed(1));
    //             }
    //         }else{
    //             return (0);
    //         }
    //     }else{
    //         return (0);
    //     }
	// }

	get_division(num,denm){
        if(!num || !denm){
            return 0;
        }
        if(!denm || (denm == 0)){
            return 0;
        }else{
            let val = Math.abs(num/denm);
            // if(type == 'percentage'){
               // return Math.abs(num*100/denm).toFixed(0)
            // }else{
            //     if((delim != undefined) && (delim > -1) && (!isNaN(delim))){
            //         return val.toFixed(parseInt(delim));
            //     }else{
                     return val.toFixed(1);
            //     }
            }
        }

	ngOnDestroy() {
		this.agentFeed.unsubscribe();
	}
}


