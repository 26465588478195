import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import jwt_decode from 'jwt-decode';

@Component({
	selector: 'app-contiinex-web-forgot-reset-password',
	templateUrl: './contiinex-web-forgot-reset-password.component.html',
	styleUrls: ['./contiinex-web-forgot-reset-password.component.scss']
})
export class ContiinexWebForgotResetPasswordComponent implements OnInit {
	forgotMsgError: any = "";
	showHideForgotMsg: boolean = false;
	newPassword: any = null;
	confirmPassword: any = null;
	tokeninfo:any="";
	token:any="";
	currentLoader:boolean = false
	showHideUpdatedPasswordPageUI: boolean = false;

	showHideforgotResetPageUI: boolean = true;
	showHideSetResetPageUI: boolean = false;
	constructor(private activeRoute: ActivatedRoute, private router: Router, private http: HttpClient, private fb: FormBuilder, private API: ApiService, private _service: MyserviceService) { }

	ngOnInit(){
	//	debugger;
		let token;
		token= (window.location.href).split("token=")[1]; 
		this.token = token;
		this.tokeninfo = this.getDecodedAccessToken(token);
		//console.log(JSON.stringify(this.tokeninfo));
		this.showHideUpdatedPasswordPageUI=false;
	}

	reseBtnClick() {
		this.showHideforgotResetPageUI = false;
		this.showHideSetResetPageUI = true;
	}
	resetPasswordBtn() {
		//debugger;
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;
		console.log('passwords', this.newPassword, this.confirmPassword)
		if(!this.newPassword || (this.newPassword && this.newPassword.length < 8)){
			this.forgotMsgError = 'New password must be atleast 8 characters';
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 4000)
			return false;
		}
		if(!this.confirmPassword){
			this.forgotMsgError = 'Please Enter the Confirm password';
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 4000)
			return false;
		}
	
		if (this.newPassword != this.confirmPassword) {
			this.forgotMsgError = "Password did not match: Please try again...";
			this.showHideForgotMsg = true;
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 4000)
			return false;
		}
		let obj = {
			"password": this.newPassword,
			"email": this.tokeninfo.email,
			"token":this.token
		}
		//console.log(obj);

		//return;
		this.currentLoader = true;
		this.API.resetPassword(obj).subscribe(
			(res: any) => {
				this.currentLoader = false
				if (res.success) {
					this.forgotMsgError = "";
					this.showHideForgotMsg = false;
					this.showHideSetResetPageUI = false;
					this.showHideUpdatedPasswordPageUI = true;
					setTimeout(() => {
						this.backToLoginBtn();
					}, 4000)
				} else {
					if (res.errors) {
					//	console.log(res.errors)
					}
				}
			}, (err)=>{
				this.currentLoader = false
				this.forgotMsgError = 'Fatal Error - Invalid response'
				setTimeout(() => {
					this.forgotMsgError = "";
					this.showHideForgotMsg = false;
				}, 4000)
			})
	}
	backToLoginBtn() {
		this.router.navigateByUrl('/signin');
	}

	// decode token
	getDecodedAccessToken(token: string): any {
		try{
			return jwt_decode(token);
		}
		catch(Error){
			return null;
		}
	  }
}
