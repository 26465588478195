
<div class="v-maindivFooter">
    <div class="row">
        <div class="col-md-2">
            <span class="v-footer-title"> Products</span>
            <ul class="footer-ul">
                <li (click)="linkingBtnProduct('comnexExperience')">Quality Assurance Automation</li>
                <li (click)="linkingBtnProduct('comnexEngage')">Unified Communication as a Service</li>
                <!-- <li (click)="linkingBtnProduct('comnexEnable')">Comnex Enable</li> -->
                <li (click)="linkingBtnWaba()">WhatsApp Business API</li>
            </ul>
    
        </div>
        <div class="col-md-3">
            <span class="v-footer-title"> Solutions</span>
            <ul class="footer-ul">
                <li (click)="linkingBtnProduct('comnexExperience')">Auto Audit</li>
                <li (click)="linkingBtnProduct('comnexExperience')">Speech Analytics</li>
                <li (click)="linkingBtnProduct('comnexEngage')">Ecommerce Automation</li>
                <li (click)="linkingBtnProduct('comnexEngage')">Outbound Sales Automation</li>
                <!-- <li (click)="linkingBtn('VRS')">Virtual Relationship Solution</li> -->
                <li (click)="linkingBtnProduct('comnexEngage')">Conversational Bot, WA Bots & Voice Bots</li>
                <li (click)="linkingBtnProduct('comnexEngage')">Process Flow Builder</li>
            </ul>
    
        </div>
    
        <div class="col-md">
            <span class="v-footer-title"> Partnership</span>
            <ul class="footer-ul">
                <li (click)="linkingBtn('globalAnalytics')">Global Analytics  </li>
                <li (click)="linkingBtn('digitalAnalytics')">Digital Solutions </li>
                <!-- <li (click)="linkingBtn('MTECH')"> MarTech</li> -->
                <li (click)="linkingBtn('EEnable')">Ecommerce Enablers </li>
                <!-- <li (click)="linkingBtn('MPlace')"> Marketplaces</li> -->
                <li (click)="linkingBtn('CCenter')"> Contact Centers</li>
                <li (click)="linkingBtn('CRMLMS')"> CRM’s & LMS companies</li>
            </ul>
    
        </div>
    
        <div class="col-md">
            <span class="v-footer-title"> About Us</span>
            <ul class="footer-ul">
                <li (click)="linkingBtnHome()">Contiinex </li>
                <li (click)="linkingCompanyBtn('OurCompany')">About Us</li>
                <li (click)="linkingBtnContactus()">Contact Us </li>
            </ul>
    
        </div>
    
        <div class="col-md">
            <span class="v-footer-title"> Pricing</span>
            <ul class="footer-ul">
                <li (click)="linkingBtn('QAA')">Quality Assurance Automation</li>
                <li (click)="linkingBtn('CBots')">Conversational Bots </li>
               
                <!-- <li (click)="linkingBtn('CallCenter')">Call center Automation </li> -->
                <!-- <li (click)="linkingBtn('Speech')">Speech </li> -->
            </ul>
    
        </div>
    
    </div>
    
    <div class="row">
        <div class="col-md-5 pt-4">
            <span class="v-info" style="display: none;">Subscribe for info</span>
            <div class="v-inputContainer" style="display: none;">
                <input class="v-inputfield " type="text" placeholder="Drop your email in here..">
                <span class="v-iconText">Subscribe</span>
              </div>
    
           
              <!-- <div class="txtMedia">
                <a  href="https://www.facebook.com/Contiinex/" target="_blank"  class="fa fa-facebook fa-v v-fcbk"></a>
                <a href="https://www.linkedin.com/company/contiinex-com/" target="_blank"  class="fa fa-linkedin fa-v v-linkedin"></a>
                <a href="https://twitter.com/contiinex"  target="_blank" class="fa fa-twitter fa-v v-twtr"></a>
 
              </div> -->
              <br>
              <span class="address1">Address</span><br>
              <p class="add2">
                  SMARTER BIZ TECHNOLOGIES PRIVATE LIMITED,<br>
    Indiqube-Orion, 24th Main Road,<br>
    2nd Sector, HSR Layout,<br>
    Bengaluru, Karnataka, 560102<br>
              </p>
              <div class="txtMedia text-left">
                <a  href="https://www.facebook.com/Contiinex/" target="_blank">
                <img src="../assets/contiinex/image/fcbkFooterIcon.png" style="width: 40px;margin: 15px;"/>
                </a>
                <a href="https://www.linkedin.com/company/contiinex-com/" target="_blank">
                    <img src="../assets/contiinex/image/linkedinFooterIcon.png" style="width: 40px;margin: 15px;"/>
                </a>
                <a href="https://twitter.com/contiinex"  target="_blank">
                    <img src="../assets/contiinex/image/twitterFooterIcon.png" style="width: 40px;margin: 15px;"/>
                </a>
                <!-- <a href="https://twitter.com/contiinex"  target="_blank">
                    <img src="../assets/contiinex/image/youtubeFooterIcon.png" style="width: 40px;margin: 15px;"/>
                </a> -->
              </div>
            </div>
            <div class="col-md">
                <table class="tblScanBar">
                    <tr class="forWeb1">
                        <td><img src="../assets/contiinex/image/scanBarQ1.png" style="width:130px"/> </td>
                    <td>   <p class="add2">
                        Want to experience a conversational Bot <br>
                        over WhatsApp now?
                    </p>
                    <span class="v-info" style="position: relative;left: 25px;bottom: 12px;">Scan The QR code</span>
    
                </td>
                    </tr>
    
    
                    <tr class="forMob1">
                        <td><img src="../assets/contiinex/image/scanBarQ1.png" style="width:130px"/> </td></tr>
                    <tr class="forMob1">
                        <td> <p class="add2 mb-0">
                            Want to experience a conversational Bot <br>
                            over WhatsApp now?
                        </p>
                        <span class="v-info bar1z" style="bottom: 0;">Scan The QR code</span><br>
                        <span class="v-info bar1z"  style="bottom: 5px;"> Or </span><br>
                        <span class="v-info bar1z"><a href="https://wa.me/message/T7QY3EIHCOE5P1" style="color: #fdbc5a;">Click Me</a></span>
    
                    </td>
                    </tr>
                </table>
                <br>
                <div class="copyTextDiv">
                    <p class="txtMedia mb-0 mt-4">
                        <mat-icon style="right: 10px;font-size: 45px;color: #a3a3a3;position: relative;top: 16px;
                        "> expand_less</mat-icon><br><span class="backTop" (click)="backToTopBtn()">Back to Top</span>
                                </p>
                        <p class="txtMedia mb-0 mt-2">
                        <span class="v-info vinfo1" (click)="clickPrivacyPolicy()">  <a class="tmPolicy" style="color:#fff">Terms of Services</a> </span>
                        <span class="v-info ml-3 mr-3">  |  </span>
                        <span class="v-info vinfo1" (click)="clickPrivacyPolicy()">   <a class="tmPolicy" style="color:#fff"> Privacy Policy</a></span>
    
                        </p>
                        <p class="txtMedia"><span class="v-info" style="font-size: 12px;position: relative;
                            bottom: 10px;">Copyright to SmarterBiz Technologies</span></p>
                </div>
    
            </div>
            </div>
    
    
    
    </div>
    