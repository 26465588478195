import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { ApiService } from '../../api.service';
import {MyserviceService} from '../../myservice.service';
@Component({
  selector: 'app-certification-score-dialog',
  templateUrl: './certification-score-dialog.component.html',
  styleUrls: ['./certification-score-dialog.component.scss']
})
export class CertificationScoreDialogComponent implements OnInit {

 	viewScoreData: any;
   _score:any;
   allTest:any = [];
   api_end_point:any;
   
  constructor(private API: ApiService,
		public dialogRef: MatDialogRef<CertificationScoreDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public viewData,private _service:MyserviceService) { 
			this.viewScoreData = viewData.data;
		}

  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
  this._score=this.viewScoreData.score;
  if(this._score){
    const dataP = this._score.split(',');
    for(let obj of dataP){
      const dataC = obj.split(';');
      if(dataC.length){
        this.allTest.push({name: dataC[0], score: dataC.length > 0 ? dataC[1] : '', eligible: dataC.length > 1 ? dataC[2] : ''})
      }
    }
  }
  // this.getScoreHistory();
	}
  getScoreHistory(){   
    
   this.API.getAssessmentHistory(this.viewScoreData.id,2,this.api_end_point).subscribe((data:any)=>{
     let _response=data.success;
     if(_response){       
      this.allTest = _response
    
     }
   });
  }
  closePopup() {
		this.dialogRef.close();
	}

}
