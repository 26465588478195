import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-read',
  templateUrl: './notification-read.component.html',
  styleUrls: ['./notification-read.component.scss']
})
export class NotificationReadComponent implements OnInit {
 readdata:any;
	constructor(
		@Inject(MAT_DIALOG_DATA) public viewData,
		private _dialogRef: MatDialogRef<NotificationReadComponent>,
	  ) {}
	
	  ngOnInit(): void {
		  console.log(this.viewData.obj);
		  this.readdata = this.viewData.obj;
	  }
	  close(): void {
		this._dialogRef.close('true');
	  }

}
