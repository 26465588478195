import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-call-dialog',
  templateUrl: './call-dialog.component.html',
  styleUrls: ['./call-dialog.component.scss']
})
export class CallDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CallDialogComponent>) { }

  ngOnInit(): void {
	  console.log(this.dialogRef);
  }

  closePopup() {
	//this.campaignForm.reset();
	this.dialogRef.close();
}
}
