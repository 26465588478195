
<!-- <div class="row ml-0 mr-0">
    <div class="col-md-12 col-sm-12">
        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
            <input matInput placeholder="Select languages you are best at ?" readonly>
        </mat-form-field>
    </div>
</div> -->
<div  *ngFor="let dynamic of dynamicArray; let i = index;trackBy: customTrackBy">
<div class="row ml-0 mr-0">
    <div class="col-md-12 col-sm-12">
        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
            <input matInput placeholder=""  [(ngModel)]="dynamicArray[i]"  (ngModelChange)="modelChange($event, i)"  >
            <mat-icon class="CR" matSuffix  (click)="deleteRow(i)">close</mat-icon>
        </mat-form-field>
    </div>      
</div>

</div>
<div class="row ml-0 mr-0">
    <div class="col-md-12 col-sm-12">
     <p (click)="addRow()" style="color: gray;border-bottom: 1px solid #ccc;line-height: 40px;">+ Add Button</p>
    </div>
</div>
<div>
    <div class="row">
        <div class="col-md-4">
            &nbsp;
        </div>
        <div class="col-md-4">
            <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">     
                <mat-select placeholder="Select Action Type" (selectionChange)="actionType($event.value)">
                  <mat-option>None</mat-option>
                  <mat-option value="1"> Action 1</mat-option>
                  <mat-option value="2">Action 2</mat-option>
                  <mat-option value="3">Action 3</mat-option>
           
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-md-4">
            <span class="callReq"> Required&nbsp;&nbsp;
                <mat-slide-toggle class="callReq" [checked]="checked" (change)="triggerToggle($event)"></mat-slide-toggle></span>
            <span class="call-del" (click)="trigerRemoveSubSection()">
                <mat-icon style="font-size: 35px;"  >delete_forever</mat-icon>
              
            </span>
        </div>
    </div>
</div>


