<div>
    <table class="tbl">
        <tr style="border-bottom: 1px solid #ccc;"><td>
            <h5 class="hd5">Create NIITO ID</h5>
        </td>
    <td style="text-align: right;"> 
        <i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
    </td>
    </tr>
        <tr>
            <td><span> Name</span></td>
            <td> <mat-form-field>
                <input matInput placeholder="Name" >
            </mat-form-field></td>
        </tr>
        <tr>
            <td><span> Agent ID</span></td>
            <td> <mat-form-field>
                <input matInput placeholder="Agent ID" >
            </mat-form-field></td>
        </tr>
     
        <tr>
            <td><span>Support </span></td>
            <td>
                <mat-form-field>
                    <mat-label>Select</mat-label>
                    <mat-select>
                      <mat-option>None</mat-option>
                      <mat-option value="option1">Option 1</mat-option>
                      <mat-option value="option2">Option 2</mat-option>
                      <mat-option value="option3">Option 3</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>       
        <tr>
            <td>&nbsp;</td>
            <td>
				<span>
					<button mat-button >Save </button>
				</span>&nbsp;&nbsp;
				<span>
					<button mat-button >Cancel </button>
				</span>
            </td>
        </tr>
    </table>  
</div>
