import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatInput } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ExcelService } from '../reportExcel.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Utils } from '../utils';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MyserviceService}from '../myservice.service';

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
	displayedColumns = ['name', 'user_id', 'batch_code', 'training_type', 'training_days', 'ojt_days', 'total_call_in_ojt', 'audit_score'];
	dataSource = new MatTableDataSource();
	isToday: boolean = true;
	callStartDate = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
	callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	maxDate = moment().subtract(0, 'day').toISOString();
	selectedFilter: any = "All";
	_allData: any;
	table_data: any = [];
	_regularData: any;
	_inojtData: any;
	searchFilter = '';

	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('searchInput', {
		read: MatInput
	}) searchInput: MatInput;
	api_end_point:any;
	currentLoader:boolean = false;
	constructor(public router: Router, private API: ApiService, public excelService: ExcelService, private utils: Utils, private _snackBar: MatSnackBar,private _service:MyserviceService) { }

	ngOnInit() {
		this.api_end_point = this._service.get_API_End_Point();
		this.refreshData(false);
	}



	change_date_call_status(key: string, event: MatDatepickerInputEvent<Date>) {
		this.isToday = false;
		if (key == 'start_date') {
			this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				//this.endDateFilter = "";
				//alert("Start date should be less than end date");
				//return;
			}
			/* if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			} */
		} else {
			this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this._snackBar.open("Start date should be less than end date *", 'close');

			}

		}
	}

	refreshData(isHistory?: boolean) {
		//debugger;
		if (!this.callStartDate || !this.callEndDate) {
			return;
		}
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.paginator = this.paginator;
		this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
			return data.name.toString().toLowerCase().includes(filter)
		};
		let obj: any = {};
		obj.for_group = 2053;
		obj.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');

		//console.log(obj);
		this.currentLoader=true;
		this.API.getReportList(obj,this.api_end_point).subscribe((data: any) => {
			this.currentLoader=false;
			if(data.errors== "No Data Found"){
				this._snackBar.open(data.errors, 'close',{"duration": 3000});
			}
			let _response = data.success;
			//console.log(_response);
			//debugger;
			if (_response) {
				let ELEMENT_DATA = _response;
				this.table_data = ELEMENT_DATA;
				this.dataSource = new MatTableDataSource(ELEMENT_DATA);
				this.dataSource.paginator = this.paginator;
				this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
					return data.name.toString().toLowerCase().includes(filter)
				};

			}
		});


	}
	changeFilter(val: any) {
		this.searchInput.value = '';
		// this.currentLoader = true;
		this.selectedFilter = val;
		// this.refreshTable();
		this.refreshTable();
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}
	downLoadReport() {
		this.currentLoader=true;
		if(this.dataSource.data.length == 0){
			this._snackBar.open("No data found*", 'close');
		}
		//debugger;
		let _data = this.dataSource.data;
		this.excelService.exportAsExcelFile(_data, 'report');
		this.currentLoader=false;
	}
	refreshTable() {
		// console.log('table refreshed');
		if (this.selectedFilter == "All") {
			this._allData = this.table_data;
			this.dataSource = new MatTableDataSource(this._allData);
		}
		else if (this.selectedFilter == "Regular") {
			this._regularData = this.table_data.filter(v => { return v.training_type == "Regular" });

			this.dataSource = new MatTableDataSource(this._regularData);

		}
		else if (this.selectedFilter == "OJT") {
			this._inojtData = this.table_data.filter(v => { return v.training_type == "OJT" });

			this.dataSource = new MatTableDataSource(this._inojtData);

		} else {
			this.dataSource = new MatTableDataSource(this.table_data);
		}
		this.dataSource.paginator = this.paginator;
		// console.log('search filter', this.searchFilter);
		if (this.searchFilter && this.searchFilter.length) {
			this.dataSource.filter = this.searchFilter.trim().toLowerCase();
		}
		// this.dataSource.data.pushValue
		// 
		// this.table.renderRows();
	}
}

