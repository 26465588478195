<!-- Audio Player starts -->
<div class="spacer"></div>
  <div class="media-footer">
    <mat-toolbar>
      <mat-toolbar-row>
         {{ state?.readableCurrentTime }}
         <mat-slider color="primary" class="time-slider" min="0" [max]="state?.duration" step="1" [value]="state?.currentTime" (input)="onSliderChangeEnd($event)" [disabled]="state?.error"></mat-slider>
         {{ state?.readableDuration }}
      </mat-toolbar-row>
      <mat-toolbar-row cols="2" class="media-action-bar">
 
          <button mat-icon-button matTooltip="10-" (click)="previous()">
            <mat-icon mat-list-icon>replay_10</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Play" (click)="play()" [disabled]="state?.error" *ngIf="!state?.playing">
            <mat-icon mat-list-icon>play_circle_filled</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Pause" (click)="pause()" *ngIf="state?.playing" [disabled]="state?.error">
            <mat-icon mat-list-icon>pause</mat-icon>
          </button>
          <button  mat-icon-button matTooltip="10+" (click)="next()" [disabled]="state?.error">
            <mat-icon mat-list-icon>forward_10</mat-icon>
          </button>
		  <button  mat-icon-button matTooltip="10+" (click)="download()" [disabled]="state?.error">
            <mat-icon mat-list-icon>download</mat-icon>
          </button>
 
        <span class="spacer"></span>
        <mat-icon class="volume_icon" matTooltip="Volume" *ngIf="!state?.isMute" (click)="setMute()">volume_up</mat-icon>
        <mat-icon class="volume_icon" matTooltip="Volume" *ngIf="state?.isMute" (click)="setUnmute()">volume_off</mat-icon>
        <mat-slider color="primary" class="time-slider" min="0" max="1" step="0.01" [value]="state?.volume" (input)="onVolumeChange($event)"></mat-slider>
       
      </mat-toolbar-row>
    </mat-toolbar>
    <span *ngIf="state?.error">
      Not a valid file.
    </span>
  </div>
<!-- Audio Player ends -->