
<br>
<div *ngIf="showHideVisible"> 
    <form  [formGroup]="createdForm"> 
<div class="row mt-2">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left item">
        <img src="../assets/crcImg.png">&nbsp;&nbsp;&nbsp;
        <span>IVR/OBD</span>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Blob File</span><br>
            <mat-radio-group formControlName="blobfile">
                <mat-radio-button value="true" checked>True&nbsp;&nbsp;</mat-radio-button>
                <mat-radio-button value="false">False</mat-radio-button>
              </mat-radio-group>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">User ID</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off"  formControlName="userid" required maxlength="9"/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Campaign Name</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off"  formControlName="campaignName" required maxlength="150"/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Group ID</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="groupid" required/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">User Email</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="userEmail" required maxlength="100"/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Company</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="company" required maxlength="100"/>
            </mat-form-field>
</div>
</div>

<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">DTMFREQ</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="DtmFReq" required maxlength="50"/>
            </mat-form-field>
</div>
</div>

<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Voice Script</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="voiceScript" required/>
            </mat-form-field>
</div>
</div>

<div formArrayName="customers_list">
    <span class="row mt-2 rowx party">Customer List</span><br>
<div class="row mt-2 rowx" *ngFor="let v of customersList.controls; let i=index;let last=last" [formGroupName]="i">            
    <div class="col-md-3 col-sm-3 col-xs-12 text-left">
        <mat-form-field style="width: 100%;">
            <input matInput autocomplete="off" placeholder="Name" formControlName="name"  maxlength="150"/>
            </mat-form-field>
</div>
<div class="col-md-3 col-sm-3 col-xs-12 text-left">
    <mat-form-field style="width: 100%;">
        <input matInput autocomplete="off" placeholder="Phone" formControlName="phone" maxlength="13"/>
        </mat-form-field>
</div>
<div class="col-md-3 col-sm-3 col-xs-12 text-left"><br>
    <mat-icon class="icon-cs" *ngIf="i !=0" (click)="deleterow(i)">remove_circle_outline</mat-icon>
    <mat-icon class="icon-cs" *ngIf="last" (click)="addrow()">add_circle_outline</mat-icon>
</div>
</div>

</div>
<br>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <button class="demo-back" (click)="backDashboard()">BACK</button>
        <button class="demo-cancel" (click)="cancelBtn()">CANCEL</button>
        <button class="demo-save" (click)="submitBtn(createdForm)">SUBMIT</button>
</div>
</div>
</form>
</div>


<div  *ngIf="showHideVisibleSuccess">
    <div class="row mt-2">            
        <div class="col-md-4 col-sm-4 col-xs-12 item" style="text-align: center;
        margin: 10% auto;">
            <span>ID : {{_obd_id}}</span><br>
            <span>{{_message}} </span><br>
            <button class="demo-save" (click)="okBtn()">OK</button>
    </div>
    </div>
</div>
<br>
<br>
<br>