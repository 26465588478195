import { Component, OnInit,Inject,ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { MatInput } from '@angular/material/input';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-assign-batch',
  templateUrl: './assign-batch.component.html',
  styleUrls: ['./assign-batch.component.scss']
})
export class AssignBatchComponent implements OnInit {
  createdForm:FormGroup;
  isreadonly:any=true;
  agentsDDL:any=[];
  trainerDDL:any=[];
  todayDate=new Date();
  _color:any="green";
  showHidePassingMessage;
  passingMessage;
  minDate:any=new Date();
  @ViewChild('toInput', {
    read: MatInput
  }) toInput: MatInput;
  api_end_point:any;
  constructor(public dialogRef: MatDialogRef<AssignBatchComponent>, @Inject(MAT_DIALOG_DATA) public viewAssignData,
  public formbuilder:FormBuilder,private API: ApiService,private myService: MyserviceService) {
    this.createdForm = this.formbuilder.group({
      trainer:[''],
      // availablepicker:[''],
      // availableTiming:[''],
      // batchcode:[''],
      // batchDate:[''],
      // batchTime:[''],
      // agents:['']
      batchType:[''],
      batchCode:[''],
      startBatch:[''],
      endBatch:[''],
      batchTime:[''],
      duration:[''],
      capacity:[''],
      agents:[''],
      location:['']
    })
   }

  ngOnInit() {
    if(this.viewAssignData){ 
      this.createdForm =  this.formbuilder.group({
        trainer: [this.viewAssignData.name],
        batchType:[''],
        batchCode: [''],
        startBatch: [''],
        endBatch: [''],
        batchDate: [''],
        batchTime: [''],       
        duration:[''],
        capacity:[''],
        agents:[''],
        location:['']
     
     })
    }
    this.onFormChanges();
	this.api_end_point = this.myService.get_API_End_Point();
  }
  agentsForDrodown(edit: boolean = false, batchCode: number = 0, status: string ="Training", isOjt){
    this.agentsDDL = [];
    this.createdForm.controls.agents.setValue('');
    this.API.getAgentList(edit ? "" : status, batchCode, isOjt, "&newBatch=true",this.api_end_point).subscribe((response:any)=>{
      if(response.success){
         this.agentsDDL=  response.success;
      }  
    })
  }
  closeDialog(){
    this.dialogRef.close();
  }
  cancelAssign(){
    this.createdForm= new FormGroup({
      batchType: new FormControl(''),
      startBatch: new FormControl(''),
      endBatch:new FormControl(''),
        batchDate: new FormControl(''),
        batchTime: new FormControl(''),
        agents: new FormControl(''),
        duration: new FormControl(''),
        capacity : new FormControl(''),
        location : new FormControl(''),
    });
  }
  onFormChanges(): void {
    this.createdForm.valueChanges.subscribe(val => {
         this.minDate=val.startBatch;
         if(val.startBatch && val.endBatch){
          const _startBatch=moment(val.startBatch);
          const _endBatch=moment(val.endBatch);
          console.log(moment(val.startBatch).diff(moment(val.endBatch)))
          if(_startBatch.diff(_endBatch) >= 1 ){
           // alert("Start date should be less than end date");
            setTimeout(()=>{
              this.toInput.value = '';
              this.createdForm.controls['endBatch'].reset();
              this.createdForm.controls.endBatch.setValue('');
              this.passingMessage="* Start date should be less than end date";
              this.showHidePassingMessage=true;
              this._color='red';
            },500)
           
          }
          else{
            this.showHidePassingMessage=false;
          }
         }
         

    });
  }
  formatTime(){
    let timePattern = new RegExp('([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]');
    let _batchTime=this.createdForm.controls.batchTime.value;
  if(timePattern.test(_batchTime)==false){
    // alert("Please enter time 'hh:mm:ss' format.")
    this.passingMessage="Please enter time 'hh:mm:ss' format.";
    this.showHidePassingMessage = true;
    setTimeout(()=>{
      this.passingMessage="";
      this.showHidePassingMessage = false;
    },3000)
  return false;
  }
  }
  changeBatchType(value:any){
    console.log(value)
    this.agentsForDrodown(false,0,value == 'Regular' ?  JSON.stringify(["Training","Training Reject"]) : JSON.stringify(["On Board","OJT Reject"]),value == 'Regular' ? '' : "OJT");
  }
  assignBtn(createdForm:any){
    this.passingMessage = "";
    this._color="";
    this.showHidePassingMessage=false;
    let _trainer=createdForm.controls.trainer.value;
    let _batchType=createdForm.controls.batchType.value;
    let timePattern = new RegExp('([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]');
    //let _startBatch=createdForm.controls.startBatch.value; 
    //let _endBatch=createdForm.controls.endBatch.value;
    
    let _startBatch =this.checkStartDateToday(createdForm.controls.startBatch.value);
    let _endBatch=moment(createdForm.controls.endBatch.value).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');

    let _batchTime=createdForm.controls.batchTime.value; 
    let _duration=createdForm.controls.duration.value; 
    let _batchCapacity=createdForm.controls.capacity.value;
    let _agentsArray=(createdForm.controls.agents.value);
    
    let _location=createdForm.controls.location.value;
    if(_batchType==""){
      this.passingMessage="Please select batch type";
      this.showHidePassingMessage=true;
      this._color='red';
    return false;
    }
    if(createdForm.controls.startBatch.value==""){
      this.passingMessage="Please select batch starts on";
      this.showHidePassingMessage=true;
      this._color='red';
    return false;
    }
    if(createdForm.controls.endBatch.value==""){
      this.passingMessage="Please select batch ends on";
      this.showHidePassingMessage=true;
      this._color='red';
    return false;
    }
  if(timePattern.test(_batchTime)==false){
   // alert("Please enter time 'hh:mm:ss' format.");
    this.passingMessage="Please enter batch time 'hh:mm:ss' format.";
    this.showHidePassingMessage=true;
    this._color='red';
  return false;
  }

  if(moment(moment(createdForm.controls.startBatch.value)).isSame(moment(), 'day')){
    var currDate = new Date();
    const h1 = currDate.getHours(), m1 = currDate.getMinutes(),h2 =  parseInt(_batchTime.split(':')[0]), m2 =parseInt(_batchTime.split(':')[1]);
    console.log(h1,h2,m1,m2)
    if(h2 < h1){
      this.passingMessage="* Select time greater than current time";
      this.showHidePassingMessage=true;
      this._color='red';
      return false;
    }
    if(h2 == h1){
      if(m2 < m1){
        this.passingMessage="* Select time greater than current time";
        this.showHidePassingMessage=true;
        this._color='red';
        return false;
      }
    }
  }

  if(_agentsArray.length==0 ||_agentsArray==""){
    //alert("Please Select Atleast One Agents");
    this.passingMessage="* Please Select Atleast One Agents.";
    this.showHidePassingMessage=true;
    this._color='red';
    
    return false;
      }

    let _agents= _agentsArray.map(i=>Number(i));    

    let _modelObject={
    "training_type":_batchType,
    "for_group":2053,
    "trainer_id":Number(this.viewAssignData.trainer_id),
    "start_date":_startBatch,
    "end_date":_endBatch,
    "start_time":_batchTime,
    "duration":Number(_duration),
    "capacity":Number(_batchCapacity),
    "agents_list":_agents,
    "training_location":_location
    }
   this.API.saveBatch(_modelObject,this.api_end_point).subscribe((data:any)=>{
    if(data.success){
      if(data.success.message){
        // alert("Assign Batch Created Successfully");
        this.passingMessage="Batch Assigned Successfully";
        this.showHidePassingMessage = true;
        this._color='green';
        setTimeout(()=>{
          this.passingMessage="";
          this.showHidePassingMessage = false;
          this.createdForm.reset();
          this.dialogRef.close();
        },3000)
        
      }
    }else if(data.errors){
      //  alert(data.errors);
       this.passingMessage=data.errors;
       this._color='red';
        this.showHidePassingMessage = true;
        setTimeout(()=>{
          this.passingMessage="";
          this.showHidePassingMessage = false;
        },3000)
    }
  });
  }
  checkStartDateToday(startDate: any){
  
    const isToday = moment(moment(startDate)).isSame(moment(), 'day')
    if(isToday){
      return moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
    }
    return moment(startDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
  }
}
