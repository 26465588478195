import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-audit',
  templateUrl: './chat-audit.component.html',
  styleUrls: ['./chat-audit.component.scss']
})
export class ChatAuditComponent implements OnInit {

  QuestionAnswer=[
    {question:'Booking-1 / 1 Customer status?',answer:'Cancelled'},
      {question:'Booking-1 / 1 Reason for cancellation?',answer:'Booking created by mistake'},
        {question:'Refund requested?',answer:'Yes'},
          {question:'Mode of refund points to add account or direct bank?',answer:'Direct to bank'}
    
    ]
  CallAuditingscore=[
    {label:' Chat response',data:[{title:'Call opening / Closing',select:'yes'},
      {title:'Call lag',select:'yes'},
        {title:' Call opening / Closing',select:'yes'}]},
        
           {label:'Professionalism',data:[{title:'Checked In time asked',select:'yes'}]},
           
                {label:'Critical Error',data:[{title:' Critical Error',select:'yes'}]},
    ]
  constructor() { }

  ngOnInit(): void {
  }

}

