import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ApiService } from 'src/app/api.service';
import * as highcharts from 'highcharts';
import * as Highcharts from 'highcharts';
import {MyserviceService} from "../../myservice.service";

import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {FormGroup, FormControl} from '@angular/forms';
interface TemplateStatusNode {
  name: any;
  val:any;
  children?: TemplateStatusNode[];
}

@Component({
  selector: 'app-whatsapp-dashboard1',
  templateUrl: './whatsapp-dashboard1.component.html',
  styleUrls: ['./whatsapp-dashboard1.component.scss']
})
export class WhatsappDashboard1Component implements OnInit {

  sentDeliveredReceivedChart:any;
  donutChartrecord:any;
  category_xaxis:any[];
  wa_total_today:number;
  customer_name:string;
  project_name:string;
  selected_time_range:string;
  wa_total_yesterday:number;
  default_sent_axis:any=[];
  default_delivered_axis:any=[];
  default_received_axis:any=[];

default_scheduled_axis:any=[];

  selectedIndex: number = 0;
  wa_today:number[] = [0,0,0,0,0];
  wa_yesterday:number[] = [0,0,0,0,0];
  currentLoader:boolean=false;
  showHideTemplateStatus:boolean=false;
  _templatetatusList:any=[];
  templates_count_data:any=[];
  treeControl = new NestedTreeControl<TemplateStatusNode>(node => node.children);
  dataSourceTree = new MatTreeNestedDataSource<TemplateStatusNode>();
  hasChild:any = (_: number, node: TemplateStatusNode) => !!node.children && node.children.length > 0;

  api_end_point:any;

  conversation_today:number[] = [0,0,0];
  conversation_yesterday:number[] = [0,0,0];
  businessInitiatedvsUserInitiatedChart:any;

  conversation_xaxis:any[];
  default_business_initiated_axis:any=[];
  default_user_initiated_axis:any=[];
  constructor(private API:  ApiService, private _service:MyserviceService) {
    window.scrollTo(0,0);
   }

   ngOnInit() {
    this.currentLoader=true;
    this.api_end_point = this._service.get_API_End_Point();

    this.default_sent_axis = [];
    this.default_delivered_axis = [];
    this.default_received_axis = [];
    this.default_scheduled_axis=[];

    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = String(this._service.get_current_project()).toLowerCase();
    }

    let business_phone =  this._service.get_current_business_phone();

     if (business_phone) {
       encodeURIComponent(business_phone);
     }

   // console.log('WhatsappDashboardComponent - ngOnInit',business_phone,this.customer_name,this.project_name);

    this.selected_time_range = this._service.get_current_time_range();

    let _splitCustom=this._service.current_time_range; 
    let splitCustom=_splitCustom.split(" "); 
    if(splitCustom[0]== "CustomDate"){
      this.selected_time_range="Custom Date";
      let  _obj={
          "startDate": splitCustom[1],        
          "endDate":splitCustom[2],
          "view_type":'Custom',
          "project":this.project_name,
          "customer": this.customer_name
        }
        this.API.getWABADashboardApi1(_obj,business_phone, this.api_end_point).subscribe((data: any) => {
          //debugger;
          if(data.errors){
            this.currentLoader=false;
            console.log('getShopifyDashboard failed',data.errors);
          }
          else {
            //console.log('getWaDashboard success',data);
            this.callDashFunction(data); 
          }   
          this.getSentVsDelivered();
          this.donutChart();
      
            setTimeout(()=>{
              this.getBusinessInitiatedvsUserInitiatedChart();
            },1000);   
     
  
        });

    }
    else{
      this.API.getWaDashboard1(this.customer_name,this.project_name,this.selected_time_range,business_phone,this.api_end_point).subscribe((data: any) => {
        if(data.errors){
          this.currentLoader=false;
          console.log('getWaDashboard failed',data.errors);
        }
        else {
          this.callDashFunction(data);
            }
         
        this.getSentVsDelivered();
        this.donutChart();
        setTimeout(()=>{
          this.getBusinessInitiatedvsUserInitiatedChart();
        },1000);
      });
    }



  }

  callDashFunction(data){
   // console.log('getWaDashboard success',data);
    this.currentLoader=false;
    this.category_xaxis = data.xaxis;
    this.conversation_xaxis=data.xaxis;

    if (data.counts && data.counts['today']) {
      if (data.counts['today']['SENT']) {
        this.wa_today[0] = data.counts['today']['SENT']
      }
      if (data.counts['today']['DELIVERED']) {
        this.wa_today[1] = data.counts['today']['DELIVERED']
      }
      if (data.counts['today']['RECEIVED']) {
        this.wa_today[2] = data.counts['today']['RECEIVED']
      }
      if (data.counts['today']['FAILED']) {
        this.wa_today[3] = data.counts['today']['FAILED']
      }
      if (data.counts['today']['SCHEDULED']) {
        this.wa_today[4] = data.counts['today']['SCHEDULED']
      }
    }

    if (data.counts && data.counts['yesterday']) {
      if (data.counts['yesterday']['SENT']) {
        this.wa_yesterday[0] = data.counts['yesterday']['SENT']
      }
      if (data.counts['yesterday']['DELIVERED']) {
        this.wa_yesterday[1] = data.counts['yesterday']['DELIVERED']
      }
      if (data.counts['yesterday']['RECEIVED']) {
        this.wa_yesterday[2] = data.counts['yesterday']['RECEIVED']
      }
      if (data.counts['yesterday']['FAILED']) {
        this.wa_yesterday[3] = data.counts['yesterday']['FAILED']
      }
      if (data.counts['yesterday']['SCHEDULED']) {
        this.wa_yesterday[4] = data.counts['yesterday']['SCHEDULED']
      }
    }

    for (var i in this.category_xaxis) {
      this.default_sent_axis[i] = 0;
      this.default_delivered_axis[i] = 0;
      this.default_received_axis[i] = 0;
      this.default_scheduled_axis[i] = 0;
    }

    for (var i in data.sent_axis) {
      this.default_sent_axis[this.category_xaxis.indexOf(i)] = data.sent_axis[i];
    }

    for (var i in data.delivered_axis) {
      this.default_delivered_axis[this.category_xaxis.indexOf(i)] = data.delivered_axis[i];
    }

    for (var i in data.received_axis) {
      this.default_received_axis[this.category_xaxis.indexOf(i)] = data.received_axis[i];
    }

    for (var i in data.scheduled_axis) {
      this.default_scheduled_axis[this.category_xaxis.indexOf(i)] = data.scheduled_axis[i];
    }

    this.wa_total_today = this.wa_today.reduce((a, b) => a+b, 0);
    this.wa_total_yesterday = this.wa_yesterday.reduce((a, b) => a+b, 0);

    if (data.templates_count) {
      this.templates_count_data = data.templates_count;
    }

    //console.log(this.default_sent_axis,this.default_delivered_axis,this.default_received_axis);
//start conversation
    if (data.conversation_data && data.conversation_data['today_counts']) {
      if (data.conversation_data['today_counts']['total_counts']) {
        this.conversation_today[0] = data.conversation_data['today_counts']['total_counts']
      }
    }
    if (data.conversation_data && data.conversation_data['yesterday_counts']) {
      if (data.conversation_data['yesterday_counts']['total_counts']) {
        this.conversation_yesterday[0] = data.conversation_data['yesterday_counts']['total_counts']
      }
    }

    if (data.conversation_data && data.conversation_data['today_counts']) {
      if (data.conversation_data['today_counts']['business_initiated_counts']) {
        this.conversation_today[1] = data.conversation_data['today_counts']['business_initiated_counts']
      }
    }
    if (data.conversation_data && data.conversation_data['yesterday_counts']) {
      if (data.conversation_data['yesterday_counts']['business_initiated_counts']) {
        this.conversation_yesterday[1] = data.conversation_data['yesterday_counts']['business_initiated_counts']
      }
    }

    if (data.conversation_data && data.conversation_data['today_counts']) {
      if (data.conversation_data['today_counts']['user_initiated_counts']) {
        this.conversation_today[2] = data.conversation_data['today_counts']['user_initiated_counts']
      }
    }
    if (data.conversation_data && data.conversation_data['yesterday_counts']) {
      if (data.conversation_data['yesterday_counts']['user_initiated_counts']) {
        this.conversation_yesterday[2] = data.conversation_data['yesterday_counts']['user_initiated_counts']
      }
    }





    for (var i in this.conversation_xaxis) {
      this.default_business_initiated_axis[i] = 0;
      this.default_user_initiated_axis[i] = 0;
    }

    for (var i in data.conversation_data['business_initiated_axis']) {
      this.default_business_initiated_axis[this.conversation_xaxis.indexOf(i)] = data.conversation_data.business_initiated_axis[i];
    }

    for (var i in data.conversation_data['user_initiated_axis']) {
      this.default_user_initiated_axis[this.conversation_xaxis.indexOf(i)] = data.conversation_data.user_initiated_axis[i];
    }
    //end conversation

  }

  getSentVsDelivered(){
    this.sentDeliveredReceivedChart = new Chart({
      chart: {
        type: 'line',
        height: 300
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },

      exporting: {
        enabled: false
      },
      xAxis: {
        categories: this.category_xaxis,
        gridLineWidth: 1,
        minorGridLineWidth: 1
      },
      yAxis: {
        title:null,
        gridLineWidth: 1,
        minorGridLineWidth: 1,

      },
      legend: {
        reversed: true,
        verticalAlign: "top",
        align: "right",
        borderWidth: 1,
      },
      plotOptions: {
        line: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },

      },
      series: [
        {
          type:undefined,
          name: "Sent",
          data: this.default_sent_axis,
          color: "#11ac35"// "#f1a627"
        },
        {
          type:undefined,
          name: "Delivered",
          data: this.default_delivered_axis,
          color:"#41acb2"// "#ed3a31"
        },
        {
          type:undefined,
          name: "Received",
          data: this.default_received_axis,
          color:"#be4d91"// "#606e36"
        },
        {
          type:undefined,
          name: "Scheduled",
          data: this.default_scheduled_axis,
          color: "#ff6c00"//"#ff6c00"
        }
      ]
    });
    this.getTemplateStatus();
}

donutChart(){
  let chartdata = new Chart({

      title: {
        text: '<strong>'+this.wa_today[0]+'</strong><br>Sent',
        align: 'center',
        verticalAlign: 'middle',
        y: 18,

      },

    credits: {
      enabled: false
    },

    exporting: {
      enabled: false
    },
    plotOptions: {
      pie: {

        cursor: 'pointer',
        dataLabels: {
          distance:-30,
          enabled: false
        },
        showInLegend: false,

      }
    },

    series: [
      {


        states: {
        inactive: {
          opacity: 1
        },
        hover: {
        enabled: false
        }
        },
        name: 'Active',
        data: [
          {
            borderColor:'gray',
            name: 'Yesterday',
            y: this.wa_total_yesterday,
            color:'white'//'#e6e6e6'
          },
          {
            borderColor:'black',
            name: 'Today',
            y: this.wa_total_today,
            color:'#eeeeee'//'black'
          },],
        type: 'pie',
        innerSize: '80%',
      }]
  });
  this.donutChartrecord=chartdata;
}
getTemplateStatus(){
  if (this.templates_count_data && this.templates_count_data.length) {
    this.showHideTemplateStatus = true;
    let summary_data:any = this.templates_count_data;
    var treeData = summary_data;
    const TREE_DATA:TemplateStatusNode[] = treeData;
    this._templatetatusList = TREE_DATA;
    this.dataSourceTree.data = this._templatetatusList;
  }
}


getBusinessInitiatedvsUserInitiatedChart(){
  this.businessInitiatedvsUserInitiatedChart = new Chart({
    chart: {
      type: 'line',
      height: 300
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },

    exporting: {
      enabled: false
    },
    xAxis: {
      categories: this.category_xaxis,
      gridLineWidth: 1,
      minorGridLineWidth: 1
    },
    yAxis: {
      title:null,
      gridLineWidth: 1,
      minorGridLineWidth: 1,

    },
    legend: {
      reversed: true,
      verticalAlign: "top", 
      align: "right",
      borderWidth: 1,
    },
    plotOptions: {
      line: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      },

    },
    series: [
      {
        type:undefined,
        name: "Business Initiated",
        data: this.default_business_initiated_axis,
        color: "#41acb2"
      },
      {
        type:undefined,
        name: "User Initiated",
        data: this.default_user_initiated_axis,
        color: "#ff6c00"
      }
    ]
  });

}
selectedsetValue(index:any){
  this.currentLoader=true;
  if(index==1){
    setTimeout(()=>{
      this.getBusinessInitiatedvsUserInitiatedChart();
      this.currentLoader=false;
    },1000)
  }
  else{
    setTimeout(()=>{
      this.currentLoader=false;
    },1000)
  }
 

}
}
