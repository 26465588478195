import { Component, OnInit,ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { ApiService } from '../api.service';
import { Utils } from '../utils';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-dashboard-traineravailibity-table',
  templateUrl: './dashboard-traineravailibity-table.component.html',
  styleUrls: ['./dashboard-traineravailibity-table.component.scss']
})
export class DashboardTraineravailibityTableComponent implements OnInit {
  utils:any;
  displayedColumns = ['name', 'rating', 'end_date', 'availability']
 
  dataSource:any = new MatTableDataSource();
  api_end_point:any;
  constructor(private API: ApiService,private myService: MyserviceService) { 
      this.utils = new Utils();
    }

  ngOnInit() {

    this.getTabledata();
	this.api_end_point = this.myService.get_API_End_Point();
  }
  getTabledata(){
    this.API.getTrainingDashboard(this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){
        if(data.success["Trainers Availability"]){
        let elementData=_response["Trainers Availability"];
        this.dataSource = new MatTableDataSource(elementData);
        this.dataSource.filterPredicate = function(data, filter: string): boolean {
          return data.name.toString().toLowerCase().includes(filter) || data.rating.toString().toLowerCase().includes(filter) || data.end_date.toString().toLowerCase().includes(filter) || data.availability.toString().toLowerCase().includes(filter)
        };
        }
      }
    }
    )
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  getAvailiabilityColor(val) {
		let obj = {
			'color': 'Inherit'
		}
		switch (val) {
			case 'Available':
				obj.color = 'green';
				break;
		
		}
		return obj;
	}


}