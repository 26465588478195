import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-employeeview',
	templateUrl: './employeeview.component.html',
	styleUrls: ['./employeeview.component.scss']
})
export class EmployeeviewComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<EmployeeviewComponent>,
		@Inject(MAT_DIALOG_DATA) public inputObj) {
		// console.log('load', this.inputObj);
	 }

	ngOnInit(): void {
		
	}

	closedialog() {
		this.dialogRef.close();
	}

}
