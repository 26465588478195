<h3 class="sBiz-title">SmarterBiz</h3><br>
<div class="text-center">
    <p  class="hdtext"><span> On-Boarding Process</span></p>
    <p class="LText">Boost your operational efficiency with realtime<br>
        customer journey orchestration across channels.</p>
   
</div>

<div>
<mat-card class="m-card">
    <p *ngIf="showHideMessage" style="font-weight: bold;" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
    <form  [formGroup]="createdForm">
    <div class="row bgText">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            TELCO INFRA PLANNING REQUIREMENT (INBOUND)
        </div>
    </div>
    <div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Agent Hunting <span class="starMandatory">*</span> </span>
        <br>
        <mat-checkbox  formControlName="agentHuntingActive"  class="mcbx">Agent will be always active on Leg A & customer will get patched</mat-checkbox>
        <mat-checkbox  formControlName="agentHuntingAvailable"  class="mcbx">Agent status will be available & customer will get patched once agent 
            answers the Leg A</mat-checkbox>
        <mat-checkbox class="mcbx"  formControlName="agentHuntingOther" >Other
           <mat-form-field class="otherWd">
               <input matInput  formControlName="agentHuntingOtherDesc" />
               </mat-form-field>
        </mat-checkbox>
    </div>
</div>

<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">CLI number configuration </span>
        <br>
       <mat-checkbox class="mcbx"  formControlName="cliSBTeam" >will be provided by smarter biz team</mat-checkbox>
       <mat-checkbox class="mcbx"  formControlName="cliCustomer" >will be provided by customer</mat-checkbox>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Calling Type </span>
        <br>
       <mat-checkbox class="mcbx"  formControlName="callingSIP" >SIP calling</mat-checkbox>
       <mat-checkbox class="mcbx"  formControlName="callingSim" >Sim network calling</mat-checkbox>
    </div>
</div>
<br><br>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
       <button class="onboard-submit" (click)="onNextBtn(createdForm)">NEXT</button>
    </div>
</div>
<br><br>
</form>
</mat-card>
</div> 


