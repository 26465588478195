<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12">
      <span  style="position: absolute;top: -35px;right: 9px;z-index: 2;cursor: pointer;">
          <img src="../assets/image/close-circle1.svg" style="position: fixed;width: 32px;background: #fff;border-radius: 50%;" (click)="closeDialog()"/></span>
      </div>
</div>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h4 class="titlehead hdTitle">Questionnaire Response</h4>
    </div>	
  </div>
<div style="padding: 6px 13px;">
  <div *ngFor="let v of QuestionAnswer;let i=index">
    <p style="margin-bottom:3px"> <span class="qqcs"> Q.&nbsp;&nbsp;{{i+1}}</span><span class="qcs">{{v.question}}</span>  </p>
    <p><span class="qqcs"> A.</span><span class="acs">{{v.answer}}</span> </p>
  </div>
</div>
 