import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-contiinex-process-import-dialog',
  templateUrl: './contiinex-process-import-dialog.component.html',
  styleUrls: ['./contiinex-process-import-dialog.component.scss']
})
export class ContiinexProcessImportDialogComponent implements OnInit {

  constructor(	public dialogRef: MatDialogRef<ContiinexProcessImportDialogComponent>,) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close()
  }
  submitBtn(){
    this.dialogRef.close()
  }
}
