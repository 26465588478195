<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	  
	  
	</mat-drawer>
<div style="background: #f6f6f6;">
<div class="row">
 
  <div class="col-md-12">
    <h4 class="titlehead" style="padding-left: 30px;position: relative;top: 35px;z-index: 9;">Voice Biometric</h4>
  </div>	
</div>
<br><br>

<div class="row" style="padding-top:60px">
 
  <div class="col-md-12">


<div class="upload-btn-wrapper">
  <button class="btnSample"> <span>Sample 1</span></button>
  <input type="file" id="fileID1" (change)="changeFile($event,1)"/>
  <div style="display: inline-flex; flex-direction: column; align-items: center;">
    <span  class="text-center" id="sample1" style="position: relative; top: 15px; left: 20px;"></span>
    <span *ngIf="sample1" style="margin-left: 20px;margin-top:15px;">{{sample1.name}}</span>
    <span *ngIf="showWait1" style="margin-left: 20px;margin-top:15px;">Please wait ...</span>
    
  </div>
  
</div>
<div class="upload-btn-wrapper">
  <button class="btnSample"> <span>Sample 2</span></button>
  <input type="file" id="fileID2" (change)="changeFile($event,2)"/>
  <div style="display: inline-flex; flex-direction: column; align-items: center;">
  <span  class="text-center" id="sample2" style="position: relative; top: 15px; left: 20px;"></span>
    <span *ngIf="sample2" style="margin-left: 20px;margin-top:15px;">{{sample2.name}}</span>
    <span *ngIf="showWait2" style="margin-left: 20px;margin-top:15px;">Please wait ...</span>
  </div>
</div>
<div class="upload-btn-wrapper">
  <button class="btnSample"> <span>Sample 3</span></button>
  <input type="file" id="fileID3" (change)="changeFile($event,3)"/>
  <div style="display: inline-flex; flex-direction: column; align-items: center;">
  <span  class="text-center" id="sample3" style="position: relative; top: 15px; left: 20px;"></span>
    <span *ngIf="sample3" style="margin-left: 20px;margin-top:15px;">{{sample3.name}}</span>
    <span *ngIf="showWait3" style="margin-left: 20px;margin-top:15px;">Please wait ...</span>
  </div>
</div><br><br>
<!-- <p class="sampleText">

    <span>Sample 1</span>
    <span>Sample 2</span>
    <span>Sample 3</span>
</p> -->
<p> <button class="fileSubmit" (click)="uploadtBtn()" *ngIf="!uploading">Upload Samples</button>
  <button class="fileSubmit"  *ngIf="uploading">
    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;&nbsp;Uploading...</button>
<span class="U-Success" *ngIf="showHideSuccessMsg">Upload Successfully..!!</span>
</p>
<div *ngIf="showHideSuccessMsg">
<p class="lvlTxt" >Agent Check</p>
  <div class="upload-btn-wrapper">
    <button class="btn" style="margin: 18px 0px 26px 26px;">Select File</button>
    <input type="file" id="fileID" (change)="changeFile($event,4)"/>
    <!-- <span *ngIf="currentFileobj">{{currentFileobj.name}}</span>
    <span *ngIf="showWait4" style="margin-left: 20px;">Please wait ...</span> -->
    <div style="display: inline-flex; flex-direction: column; align-items: center;">
      <span  class="text-center" id="sample4" style="position: relative; top: 15px; left: 20px;"></span>
        <span *ngIf="currentFileobj" style="margin-left: 20px;margin-top:15px;">{{currentFileobj.name}}</span>
        <span *ngIf="showWait4" style="margin-left: 20px;margin-top:15px;">Please wait ...</span>
      </div>
    <br/>
    <button class="fileSubmit" (click)="validateVoice()" *ngIf="!validating">Validate </button>
    <button class="fileSubmit"  *ngIf="validating">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp;&nbsp;Validating...</button>
  </div>

  <p style="    font-family: 'Poppins';
  margin-left: 26px;margin-top: 15px;" *ngIf="validationResult && validationResult.status == 'success'">
      Status: {{validationResult.auth_status}} &nbsp;&nbsp;&nbsp;&nbsp; Confidence Level: {{validationResult.confidence}}
  </p>
  <p style="    font-family: 'Poppins';
  margin-left: 26px;margin-top: 15px;" *ngIf="validationResult && validationResult.status == 'failed'">
      Status: {{validationResult.status}} &nbsp;&nbsp;&nbsp;&nbsp; Messagel: {{validationResult.message}}
  </p>
</div>
   
</div>	
</div>



</div>
</mat-drawer-container>