import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as _ from 'underscore';
import { HttpResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, Validators, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Chart } from 'angular-highcharts';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Location } from '@angular/common';
import { Utils } from '../../utils';
import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import { filter } from 'rxjs/operators';

export function timeValidator(control: AbstractControl): { [key: string]: any; } | null {
	var hr = moment().format('HH');
	var min = moment().format('mm');
	if (control.value) {
		var [c_hr, c_min] = control.value.split(':');
		if (parseInt(c_hr) >= parseInt(hr)) {
			if (parseInt(c_min) >= parseInt(min)) {
				return;
			}
		}
		return { 'timeError': 'Invalid time. Choose curent time of future time' };
	}
}

@Component({
	selector: 'app-campaign-central-create-page',
	templateUrl: './campaign-central-create-page.component.html',
	styleUrls: ['./campaign-central-create-page.component.scss']
})

export class CampaignCentralCreatePageComponent implements OnInit {
	currentTitle = "central-campaign";
	campaignType: string = 'others';
	myUser: any;
	pd_dial_ratio: number;
	campaignForm: FormGroup;
	campaign_type_field = {
		'input': "FT",
		'values': this.campaignType,
		'default_value': this.campaignType
	}
	dial_mode: any;
	incallscreen: any;
	push_calltype:any;
	currentFileobj: any = {};
	current_time = moment().format('HH:mm');
	campaignUpload_msg: string = '';
	_formlist: any = [];
	_data: any;
	searchedKeyword: any = "";
	selectedagent: any = [];

	email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	num_pattern = /^(\+91)?(91)?(\d{10})$/;
	name_pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
	campaign_work_mode:any=["WFO","WFH"];
	templateddownloadurl: any="";
	api_end_point:any;
	showLoader:boolean=false;
	constructor(private dialog: MatDialog,
		private API: ApiService,
		private utils: Utils,
		private myservice: MyserviceService,
		private router: Router,
		private formbuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private _snackBar: MatSnackBar, private cd: ChangeDetectorRef) {
		// let p: any = this.myservice.get_stage_settings();
		//console.log('print settings', p);
		// if (p && p['web'] && p['web'].length) {
		// 	let name: any = _.groupBy(p['web'], 'name');
		// 	// debugger;
		// 	if (name && name['campaign_type']) {
		// 		try {
		// 			let q: any = JSON.parse(name['campaign_type'][0]['definition']);
		// 			this.campaign_type_field = q['campaign_type'];
		// 			this.campaign_type_field.default_value = q['campaign_type']['values'][0];
		// 		} catch (e) {

		// 		}
		// 	}
		// }

	}


	ngOnInit() {
		this.api_end_point = this.myservice.get_API_End_Point();
		this._data = {};
		this._data.isAllSelected = true;
		this._data.isAllCollapsed = true;
		this.myUser = this.myservice.get_loggedin_user();
		let centraldata;
		this._data.ParentChildchecklist = [];
		//console.log("----"+JSON.stringify(this.myUser));
		var obj = {
			role: this.myUser.role,
			role_id: this.myUser.groupid
		}
		//console.log("--2-" + JSON.stringify(obj))
		this.showLoader=true;
		this.API.getcenteralgroupid(obj,this.api_end_point).subscribe((res: any) => {
			this.showLoader=false;
			if (res.errors) {
				//console.log(res.errors);
			} else {
				//console.log("--3-" + JSON.stringify(res.success))
				let centraldata = res.success;

				for (var c in centraldata) {
					//console.log(centraldata[c].center_name);
					var obj;
					if (centraldata[c].user_count == null) {
						obj = {
							id: centraldata[c].id, groupid: centraldata[c].groupid, center_name: centraldata[c].center_name, rsm: centraldata[c].rsm_name, teamSize: 0, isSelected: false, isClosed: true, childList: [], total: 0
						}
						this._data.ParentChildchecklist.push(obj);
					} else {
						obj = {
							id: centraldata[c].id, groupid: centraldata[c].groupid, center_name: centraldata[c].center_name, rsm: centraldata[c].rsm_name, teamSize: centraldata[c].user_count, isSelected: false, isClosed: true, childList: [], total: 0
						}
						this._data.ParentChildchecklist.push(obj);
					}
				}
			}
		})


		//List object having hierarchy of parents and its children
		// this._data.ParentChildchecklist = [
		// 	{
		// 		id: 1, name: 'Ziaul Shaikh', position: 'Project Manager', teamSize: '27', isSelected: false, isClosed: true,
		// 		childList: [
		// 			{
		// 				id: 1, parent_id: 1, name: 'Lloyd Fernandes', isSelected: false
		// 			},
		// 			{
		// 				id: 2, parent_id: 1, name: 'Nelson Davis', isSelected: false
		// 			},
		// 			{
		// 				id: 3, parent_id: 1, name: 'Siddharth Jaiswa', isSelected: false
		// 			}
		// 		]
		// 	},
		// 	{
		// 		id: 2, name: 'Lloyd Fernandes', position: 'Finance Manager', teamSize: '25', isSelected: false, isClosed: true, 
		// 		childList: [
		// 			{
		// 				id: 1, parent_id: 1, name: 'child 1', isSelected: false
		// 			},
		// 			{
		// 				id: 2, parent_id: 1, name: 'child 2', isSelected: false
		// 			}
		// 		]
		// 	},
		// 	{
		// 		id: 3, name: 'Suraj Mishra', position: 'Team Manager', teamSize: '20', isSelected: false, isClosed: true,
		// 		childList: [
		// 			{
		// 				id: 1, parent_id: 1, name: 'child 1', isSelected: false
		// 			},
		// 			{
		// 				id: 2, parent_id: 1, name: 'child 2', isSelected: false
		// 			}
		// 		]
		// 	}
		// ];

		this.pd_dial_ratio = 2;
		this.campaignForm = this.formbuilder.group({
			"name": ['', Validators.required],
			"date": [new Date(), Validators.required],
			"time": [this.current_time, [Validators.required, timeValidator]],
			"campaign_type": ['', Validators.required],
			"formidName": [''],
			"screenname": ['', Validators.required],
			"priority": ['Server', Validators.required],
			"file": ['', Validators.required],
			"fileSource": ['', Validators.required],
			"parentname": ['', Validators.required],
			"childname": [[], Validators.required],
			"campaign_mode":['WFO']
		})
		this.myUser = this.myservice.get_loggedin_user();
		this.campaignUpload_msg = '';
		this.getFormList();
		var Stagesettings_details, incallscreen,push_calltype;
		Stagesettings_details = this.myservice.getSettingsWithKeyValue('web', 'name', 'campaign_push');
		incallscreen = this.myservice.getSettingsWithKeyValue('web', 'name', 'incallscreen');
		push_calltype = this.myservice.getSettingsWithKeyValue('web', 'name', 'campaign_push_calltype');
		//console.log("-----",incallscreen);
		this.dial_mode = JSON.parse(Stagesettings_details.definition);
		this.incallscreen = JSON.parse(incallscreen.definition);
		this.push_calltype = JSON.parse(push_calltype.definition);
		
		//console.log("-----" + this.push_calltype);
		//console.log(this.dial_mode + "-----" + this.incallscreen);
	}

	dialcheck_mode() {
		for (let i in this.dial_mode.dial_modes) {
			if (this.dial_mode.dial_modes[i].name == "Predictive") {
				//console.log(this.dial_mode.dial_modes[i].name);
			}

		}
	}

	backToCampaignDashboard() {
		this.router.navigateByUrl('/central-campaign');
	}
	cancelBtn() {
		this.router.navigateByUrl('/central-campaign');
	}


	//Click event on parent checkbox  
	parentCheck(parentObj, status_parent) {
		//if checked
		let groupid = parentObj.groupid;
		//console.log("----" + groupid + "--" + status_parent);
		if (status_parent) {
			let apidata;

			//console.log(parentObj.childList.length + "----" + groupid + "--" + status_parent);
			let api = {
				where_gid: parentObj.groupid
			}
			if (parentObj.childList.length > 0) {
				apidata = parentObj.childList;
				for (var d in apidata) {
					var allagents = { name: apidata[d].name, id: apidata[d].id, email: apidata[d].email, phone: apidata[d].phone, groupid: apidata[d].groupid, role: "user" }
					this.selectedagent.push(allagents);
				}
				for (var i = 0; i < this._data.ParentChildchecklist.length; i++) {
					if (this._data.ParentChildchecklist[i].id == parentObj.id) {
						this._data.ParentChildchecklist[i].total = this._data.ParentChildchecklist[i].teamSize;
					}
				}

			} else {

				this.API.getalluser(api,this.api_end_point).subscribe((res: any) => {
					if (res.errors) {
					//	console.log("---" + res.errors);
					} else {
						//console.log("--res-"+JSON.stringify(res.agents_list));
						apidata = res.agents_list;

						var obj = {}
						obj[groupid] = [];
						for (var d in apidata) {
							var allagents = { name: apidata[d].name, id: apidata[d].id, email: apidata[d].email, phone: apidata[d].phone, groupid: apidata[d].groupid, role: "user" }
							this.selectedagent.push(allagents);
							obj[groupid].push(allagents);
						}

						for (var i = 0; i < this._data.ParentChildchecklist.length; i++) {
							if (this._data.ParentChildchecklist[i].id == parentObj.id) {
								this._data.ParentChildchecklist[i].total = this._data.ParentChildchecklist[i].teamSize;
							}
						}


					}
				})

			}

			for (var i = 0; i < parentObj.childList.length; i++) {
				parentObj.childList[i].isSelected = parentObj.isSelected;
			}
		}
		//if unchecked 
		else {
			//console.log(JSON.stringify(this.selectedagent))
			for (var i = 0; i < this.selectedagent.length; i++) {
				if (this.selectedagent[i].groupid == groupid) {
					const userindex = this.selectedagent.findIndex(x => x.groupid == groupid)
					//console.log(JSON.stringify(userindex));
					if (userindex > -1) {
						this.selectedagent.splice(userindex, parentObj.teamSize)
					}
				}
			}
			for (var i = 0; i < this._data.ParentChildchecklist.length; i++) {
				if (this._data.ParentChildchecklist[i].id == parentObj.id) {
					this._data.ParentChildchecklist[i].total = 0;
					for(var e in this._data.ParentChildchecklist[i].childList)
					{
						this._data.ParentChildchecklist[i].childList[e].isSelected =false;
					}
				}
			}
			//const userindex = this.selectedagent.findIndex(x => x.groupid == groupid)
			//console.log(JSON.stringify(this.selectedagent));
		}

	}
	//Click event on child checkbox  
	childCheck(parentObj, childObj, child_status) {

		let groupid = parentObj.groupid;
		//console.log(child_status);
		if (child_status) {
			var allagents = { name: childObj.name, id: childObj.id, email: childObj.email, phone: childObj.phone, groupid: childObj.groupid, role: "user", active_campaign: null, work_option: "WFO", selected: true, campaign_status: true }
			//obj[groupid].push(allagents);
			this.selectedagent.push(allagents);

			//console.log(JSON.stringify(obj));
			//this.selectedagent.push(obj);
			//console.log(this._data.ParentChildchecklist.length);
			for (var i = 0; i < this._data.ParentChildchecklist.length; i++) {
				if (this._data.ParentChildchecklist[i].id == parentObj.id) {
					this._data.ParentChildchecklist[i].total = this._data.ParentChildchecklist[i].total + 1;
				}
			}
			//console.log("---"+this.selectedagent.length);
		}
		else {
			//console.log(JSON.stringify(this.selectedagent))
			for (var i = 0; i < this.selectedagent.length; i++) {
				//console.log(this.selectedagent[i].id+"---"+childObj.id)
				if (this.selectedagent[i].id == childObj.id) {
					const userindex = this.selectedagent.findIndex(x => x.id == childObj.id)
					//console.log(JSON.stringify(userindex));
					
						if (this.selectedagent.length == (userindex + 1)) {
							//this.selectedagent.pop();
							for (var i = 0; i < this._data.ParentChildchecklist.length; i++) {
								if (this._data.ParentChildchecklist[i].id == parentObj.id) {
									this._data.ParentChildchecklist[i].total = this._data.ParentChildchecklist[i].total - 1;
									this.selectedagent.splice(userindex, 1)
								}
							}
							//console.log("--match found"+(userindex + 1));
							
						} else {
							for (var i = 0; i < this._data.ParentChildchecklist.length; i++) {
								if (this._data.ParentChildchecklist[i].id == parentObj.id) {
									this._data.ParentChildchecklist[i].total = this._data.ParentChildchecklist[i].total - 1;
									this.selectedagent.splice(userindex, 1)
								}
							}
							
						}
				}
			}
			//const userindex = this.selectedagent.findIndex(x => x.groupid == groupid)
			//console.log(JSON.stringify(this.selectedagent));
		}
	}
	//Click event on master select
	selectUnselectAll(obj) {
		obj.isAllSelected = !obj.isAllSelected;
		for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
			obj.ParentChildchecklist[i].isSelected = obj.isAllSelected;
			for (var j = 0; j < obj.ParentChildchecklist[i].childList.length; j++) {
				obj.ParentChildchecklist[i].childList[j].isSelected = obj.isAllSelected;
			}
		}
	}
	//Expand/Collapse event on each parent
	expandCollapse(obj) {
		let selectdata = obj;
		let apidata;
		let childList = [];
		this.searchedKeyword = "";
		//this.item.isSelected
		//console.log(obj.isClosed + "--" + obj.isCollapsed);
		//debugger;
		obj.isClosed = !obj.isClosed;
		let api = {
			where_gid: obj.groupid
		}
		if (!obj.isClosed) {
			console.log(JSON.stringify(api));
			this.API.getalluser(api,this.api_end_point).subscribe((res: any) => {
				if (res.errors) {
					//console.log("---" + res.errors);
				} else {
					//console.log("--res-" + JSON.stringify(res.agents_list));
					apidata = res.agents_list;
					for (var c in this._data.ParentChildchecklist) {
						//console.log("--parent--" + this._data.ParentChildchecklist[c].id + "--child-id-----" + selectdata.id);
						if (this._data.ParentChildchecklist[c].id == selectdata.id) {
							for (var d in apidata) {
								//console.log("--child--" + apidata[d].name);
								//console.log(this._data.ParentChildchecklist[c].childList.length);
								if (this._data.ParentChildchecklist[c].childList.length == 0) {
									var child = { id: apidata[d].id, parent_id: this._data.ParentChildchecklist[c].id, name: apidata[d].name, email: apidata[d].email, phone: apidata[d].phone, groupid: apidata[d].groupid, isSelected: false, active_campaign: null, work_option: "WFO", selected: true, campaign_status: true }
									this._data.ParentChildchecklist[c].childList.push(child);
								} else if (this._data.ParentChildchecklist[c].childList.length != apidata.length) {
									//console.log(this._data.ParentChildchecklist[c].childList + "---" + apidata[d].id);
									//console.log("---" + apidata[d].active_campaign);
									var child = { id: apidata[d].id, parent_id: this._data.ParentChildchecklist[c].id, name: apidata[d].name, email: apidata[d].email, phone: apidata[d].phone, groupid: apidata[d].groupid, isSelected: false, active_campaign: apidata[d].active_campaign, work_option: "WFO", selected: true, campaign_status: true }
									this._data.ParentChildchecklist[c].childList.push(child);
								} else if (this._data.ParentChildchecklist[c].childList.length == apidata.length) {
									//console.log("already in");
								}
							}
						}
					}
				}
			})
		}
	}
	//Master expand/ collapse event
	expandCollapseAll(obj) {
		for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
			obj.ParentChildchecklist[i].isClosed = !obj.isAllCollapsed;
		}
		obj.isAllCollapsed = !obj.isAllCollapsed;

	}
	//Just to show updated JSON object on view
	stringify(obj) {
		return JSON.stringify(obj);
	}

	setDialRatio(val) {
		//console.log('dial ratio val', val);
		this.pd_dial_ratio = val;
	}
	omit_special_char(event) {
		let k;
		k = event.charCode;  // k = event.keyCode; (Both can be used)
		if (k == 95) {
			return true;
		}
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
	}
	getFormList() {
		this.showLoader=true;
		this.API.getCallForms(this.myUser.id).subscribe((d: any) => {
			this.showLoader=false;
			if (d.success) {
				this._formlist = d.success;
			}
		})
	}
	get _form() { return this.campaignForm.controls }
	hidePreviousDates = (d: Date | null): boolean => {
		const day = (d || new Date());
		return moment(day).isSameOrAfter(new Date(), 'day');
	}
	changeFile(event) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			this.currentFileobj = event.target.files[0];
			const [upload_file] = event.target.files;
			this.campaignForm.patchValue({
				fileSource: upload_file
			});
		}
	}
	submit_Camapign() {
		var agentList = []

		if (this.campaignForm.get('name').value == "") {

			this._snackBar.open("Please enter campaign name*", "close",{ 
				duration: 2000
			});
			return;
		}

		if (this.campaignForm.get('fileSource').value == "") {

			this._snackBar.open("Please attached Data file*", "close",{ 
				duration: 2000
			});
			return;
		}
		if (this.campaignForm.get('date').value == "") {

			this._snackBar.open("Please select Date*", "close",{ 
				duration: 2000
			});
			return;
		}
		// if (this.campaignForm.get('campaign_type').value == "") {

		// 	this._snackBar.open("Please select Master campaign*", "close");
		// 	return;
		// }
		if (this.campaignForm.get('screenname').value == "") {

			this._snackBar.open("Please select Form*", "close",{ 
				duration: 2000
			});
			return;
		}
		if (this.campaignForm.get('campaign_mode').value == "") {

			this._snackBar.open("Please select campaign_mode", "close",{ 
				duration: 2000
			});
			return;
		}
		// console.log('dial ration', this.pd_dial_ratio);
		if (this.selectedagent.length == 0) {
			//alert('Select at least one agent');
			//this.showHideAtleastOneAgent=true;
			this._snackBar.open('Select at least one agent', 'close',{ 
				duration: 2000
			});
			return;
		}
		// console.log('printing selected agents', selectedAgents);
		this.campaignUpload_msg = 'File Data checking started';
		// return;
		// this.validateExcelSheet().then((resolve) => {
		// 	console.log('Resolved');
		this.campaignUpload_msg = 'File Data checking completed';
		this.campaignUpload_msg = 'Start uploading ...';
		let campaign = this.campaignForm;
		let agent_list = {};
		//agent_list[this.myUser.groupid] = this.service.getAllAgentsByLevel('Level 1');
		let campaign_name = campaign.controls.name.value;
		let date = moment(campaign.controls.date.value).format('YYYY-MM-DD');
		let time = String(campaign.controls.time.value + ':00');
		let full_time = String(date).concat('T', time.toString()).toString();
		let bulk_file = campaign.get('fileSource').value;
		let master_campaign = campaign.get('campaign_type').value;
		//console.log(campaign.controls.priority.value);
		let dial_ratio;
		let priority;
		for (var i in this.dial_mode.dial_modes) {
			if ((campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) &&
				this.dial_mode.dial_modes[i].dial_ratio &&
				(this.dial_mode.dial_modes[i].dial_ratio.type == "static")) {
				//console.log(this.dial_mode.dial_modes[i].priority);
				dial_ratio = this.dial_mode.dial_modes[i].dial_ratio.value;
				priority = this.dial_mode.dial_modes[i].priority;
			} else if (campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) {
				//console.log(this.dial_mode.dial_modes[i].priority);
				dial_ratio = this.dial_mode.dial_modes[i].priority;
				priority = this.dial_mode.dial_modes[i].priority;
			}

		}


		//let _formID = campaign.controls.formidName.value
		let _fileDetails = this.campaignForm.get('fileSource').value;
		let _fileName = this.campaignForm.get('fileSource').value.name;
		let fileInput = document.getElementById('fileID') as HTMLInputElement;
		let filePath = this.campaignForm.get('fileSource').value.name;
		let incallscreen = this.campaignForm.get('screenname').value;
		var campain_mode = this.campaignForm.get('campaign_mode').value;
		//console.log("----", incallscreen);
		
		
		let allowedExtensions = /(\.xlsx)$/i;

		if (!allowedExtensions.exec(filePath)) {
			this._snackBar.open('Please upload .xlsx extensions file only.', 'Close');
			return;
		}

		let formData = new FormData();

		// formData.append('user_id', this.myUser.id);
		//formData.append('questionnaire_form', _formID);//vk
		let agents_list = _.groupBy(this.selectedagent, 'groupid');

		

		formData.append('leads', bulk_file, bulk_file.name);
		formData.append('user_id', this.myUser.id);
		formData.append('campaign_name', campaign_name);
		formData.append('campaign_mode', campain_mode);
		formData.append('dial_ratio', dial_ratio);
		formData.append('priority', priority);
		formData.append('pushed_date', full_time);
		formData.append('incallscreen', incallscreen);

		//console.log("---"+JSON.stringify(this.push_calltype))
		var push_type = this.push_calltype.push_calltype;
		var selected_pushtype;
		for(var d in push_type){
			if(push_type[d].name == incallscreen)
			{
				selected_pushtype = push_type[d].value;
				formData.append('push_calltype', push_type[d].value);
				//console.log(push_type[d].name);
			}
			
		}
		//formData.append('push_calltype', 'promotional');
		formData.append('agents_list', JSON.stringify(agents_list));
		formData.append('master_campaign', master_campaign);
		/*formData.forEach((value, key) => {
			console.log(key + value)
		});
		return;
*/
		this.API.bulkUploadAPI(formData,this.api_end_point).subscribe(
			(res: any) => {
				if (res.errors) {
					//alert(res.errors);
					this._snackBar.open(res.errors, 'close');
					return;
				}
				this.campaignUpload_msg = 'Camapign Upload Finished.'
				let uploaded_file = res.url.split('/');
				let file_name = uploaded_file.pop();

				let obj: any = {
					"blobfile": file_name,
					"start_date": full_time,
					"maxleadsperday": 500,
					"options": "Drop repeated data",
					"campaign_name": campaign_name,
					"incallscreen": incallscreen,
					//"questionnaire_form": _formID,//vk
					"priority": priority,
					"dial_ratio": dial_ratio,
					"master_campaign": master_campaign,
					"push_calltype": selected_pushtype,
					"campaign_mode": campain_mode,
					"groupedagentslist": agents_list
				}
				if (priority == 2) {
					obj.dial_ratio = this.pd_dial_ratio;
				}
				this.campaignUpload_msg = 'Start Assigning the data';
				this.API.bulkPushAPI(obj,this.api_end_point).subscribe(
					(res) => {
						this.campaignUpload_msg = 'Refreshing the campaign data';
						//this.closePopup();
						this.campaignForm.reset();
						this.router.navigate(['/central-campaign']);
					}, (err) => {
						this._snackBar.open(err, 'close');
						this.router.navigate(['/central-campaign']);
						//console.log('err', err)
					}
				)
			}
			, (err) => {
				//	console.log(err)
			}
		)
		// }, (reject) => {
		// 	this.campaignUpload_msg = reject;
		// 	console.log('Validation reject', reject)
		// })
	}
	//assign later
	assign_later() {
		if (this.campaignForm.get('name').value == "") {

			this._snackBar.open("Please enter campaign name*", "close",{ 
				duration: 2000
			});
			return;
		}

		if (this.campaignForm.get('fileSource').value == "") {

			this._snackBar.open("Please attached Data file*", "close",{ 
				duration: 2000
			});
			return;
		}
		if (this.campaignForm.get('date').value == "") {

			this._snackBar.open("Please select Date*", "close",{ 
				duration: 2000
			});
			return;
		}
		// if (this.campaignForm.get('campaign_type').value == "") {

		// 	this._snackBar.open("Please select master campaign*", "close");
		// 	return;
		// }
		if (this.campaignForm.get('screenname').value == "") {

			this._snackBar.open("Please select Form*", "close",{ 
				duration: 2000
			});
			return;
		}
		if (this.campaignForm.get('campaign_mode').value == "") {

			this._snackBar.open("Please select campaign_mode", "close",{ 
				duration: 2000
			});
			return;
		}
		if (this.selectedagent.length == 0) {
			//alert('Select at least one agent');
			//this.showHideAtleastOneAgent=true;
			this._snackBar.open('Select at least one agent', 'close',{ 
				duration: 2000
			});
			return;
		}
		// console.log('printing selected agents', selectedAgents);
		this.campaignUpload_msg = 'File Data checking started';
		// return;
		// this.validateExcelSheet().then((resolve) => {
		// 	console.log('Resolved');
		this.campaignUpload_msg = 'File Data checking completed';
		this.campaignUpload_msg = 'Start uploading ...';
		let campaign = this.campaignForm;
		//agent_list[this.myUser.groupid] = this.service.getAllAgentsByLevel('Level 1');
		let campaign_name = campaign.controls.name.value;
		let date = moment(campaign.controls.date.value).format('YYYY-MM-DD');
		let time = String(campaign.controls.time.value + ':00');
		let full_time = String(date).concat('T', time.toString()).toString();
		let bulk_file = this.campaignForm.get('fileSource').value;
		let master_campaign = this.campaignForm.get('campaign_type').value;
		let incallscreen = this.campaignForm.get('screenname').value;
		var campain_mode = this.campaignForm.get('campaign_mode').value;
		//console.log(bulk_file + "--------" + bulk_file.name);
		//let priority = campaign.controls.priority.value;
		let dial_ratio;
		let priority;
		for (var i in this.dial_mode.dial_modes) {
			if ((campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) &&
				this.dial_mode.dial_modes[i].dial_ratio &&
				(this.dial_mode.dial_modes[i].dial_ratio.type == "static")) {
				//console.log(this.dial_mode.dial_modes[i].priority);
				dial_ratio = this.dial_mode.dial_modes[i].dial_ratio.value;
				priority = this.dial_mode.dial_modes[i].priority;
			} else if (campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) {
				//console.log(this.dial_mode.dial_modes[i].priority);
				dial_ratio = this.dial_mode.dial_modes[i].priority;
				priority = this.dial_mode.dial_modes[i].priority;
			}

		}
		//agent_list[this.myUser.groupid] = selectedAgents;

		let _formID = campaign.controls.formidName.value;

		let _fileDetails = this.campaignForm.get('fileSource').value;
		let _fileName = this.campaignForm.get('fileSource').value.name;
		let fileInput = document.getElementById('fileID') as HTMLInputElement;
		let filePath = this.campaignForm.get('fileSource').value.name;
		//console.log(this.campaignForm.get('fileSource').value.name);
		let allowedExtensions = /(\.xlsx)$/i;

		if (!allowedExtensions.exec(filePath)) {
			this._snackBar.open('Please upload .xlsx extensions file only.', 'Close');
			return;
		}

		let formData = new FormData();
		// formData.append('user_id', this.myUser.id);
		formData.append('questionnaire_form', _formID);//vk
		//formData.append('agents_list', JSON.stringify(agent_list));
		let agents_list = _.groupBy(this.selectedagent, 'groupid');
		//console.log(agents_list)

		var push_type = this.push_calltype.push_calltype;
		var selected_pushtype;
		for(var d in push_type){
			if(push_type[d].name == incallscreen)
			{
				selected_pushtype = push_type[d].value;
				formData.append('push_calltype', push_type[d].value);
				//console.log(push_type[d].name);
			}
			
		}
		formData.append('leads', bulk_file, bulk_file.name);
		formData.append('user_id', this.myUser.id);
		formData.append('campaign_name', campaign_name);
		formData.append('campaign_mode', campain_mode);
		formData.append('dial_ratio', dial_ratio);
		formData.append('priority', priority);
		formData.append('pushed_date', full_time);
		formData.append('incallscreen', incallscreen);
		formData.append('push_calltype', selected_pushtype);
		formData.append('agents_list', JSON.stringify(agents_list));
		formData.append('master_campaign', master_campaign);
		// formData.forEach((value, key) => {
		// 	console.log(key + value)
		// });

		// return;
		this.API.bulkUploadAPI(formData,this.api_end_point).subscribe(
			(res: any) => {
				if (res.errors) {
					this._snackBar.open(res.errors, 'close');
					//alert(res.errors);

					return;
				}
				this._snackBar.open('Camapign Upload Finished.', 'close',{ 
					duration: 2000
				});
				this.campaignForm.reset();
				this.router.navigate(['/central-campaign']);
			})
		//}, (reject) => {
		// 	this.campaignUpload_msg = reject;
		// 	console.log('Validation reject', reject)
		// })
	}
	//this.API.getalluser().su   role=${obj.role}&role_id= 
	//this.API.getcenteralgroupid() where_gid 
	download_file() {
		this.API.bulkTemplateAPI(this.api_end_point).subscribe(
			(res: HttpResponse<any>) => {

				this.templateddownloadurl = res.url;
				window.open(this.templateddownloadurl, '_blank');  
				return;
				// let content_type = res.headers.get("content-type");
				// let file_data = res.headers.get("content-disposition");
				// console.log(res.url);

				// let p = file_data.split(';')[1];
				// let file_name = p.slice(p.indexOf('"') + 1, p.length - 1);

				// let blob: any = new Blob([res.url], { type: content_type });
				// let url = window.URL.createObjectURL(blob);
				// var anchor = document.createElement("a");
				// anchor.download = file_name;
				// anchor.href = url;
				// anchor.click();

				// if (file_data != null) {

				// 	let body = res.url;
				// }
			},
			(err) => {
				//console.log('printing errors', err)
				this._snackBar.open('Error in downloading the file', 'close',{ 
					duration: 2000
				});
				//alert('Error in downloading the file')
			}
		)
	}

	validateExcelSheet() {
		let promise = new Promise((resolve, reject) => {
			// console.log(this.currentFileobj);
			let file_path = this.currentFileobj;
			let mime_type = file_path.name.split('.').pop().toLowerCase();
			if (mime_type != 'xlsx') {
				reject('File format is not correct. Use xlsx file');
				return;
			}
			if (!file_path.name) {
				reject('File not found for validations');
				return;
			}
			// debugger;
			const reader: FileReader = new FileReader();
			reader.readAsBinaryString(file_path);
			reader.onload = (e: any) => {
				// debugger;
				/* create workbook */
				const binarystr: string = e.target.result;
				const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
				// debugger;
				/* selected the first sheet */
				const wsname: string = wb.SheetNames[0];
				const ws: XLSX.WorkSheet = wb.Sheets[wsname];
				// debugger;
				/* save data */
				const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
				// console.log(data); // Data will be logged in array format containing objects
				if (data && data.length) {
					// console.log('data to check', data.length);
					let mandatory_keys = ['ASPIRANT NAME (Compulsory)', 'ASPIRANT NUMBER(Compulsory)', 'EMAIL(Compulsory)'];
					let error = null;
					for (var i = 0; i < data.length && !error; i++) {
						let loc = data[i];
						let loc_line = i + 1;
						for (var k in mandatory_keys) {
							if (!loc.hasOwnProperty(mandatory_keys[k])) {
								error = mandatory_keys[k] + " not found at line number " + loc_line;
								break;
							}
							if (typeof loc[mandatory_keys[k]] != 'string') {
								loc[mandatory_keys[k]] = JSON.stringify(loc[mandatory_keys[k]]);
							}
							loc[mandatory_keys[k]] = loc[mandatory_keys[k]].trim();
							switch (mandatory_keys[k]) {
								case mandatory_keys[0]:
									// Looking for name
									if (!loc[mandatory_keys[k]].length) {
										error = "Name not found at line number " + loc_line;
										break;
									}
									if (this.name_pattern.test(loc[mandatory_keys[0]])) {
										error = "Name is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
								case mandatory_keys[1]:
									if (!this.num_pattern.exec(loc[mandatory_keys[1]])) {
										error = "Number is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
								case mandatory_keys[2]:
									if (!this.email_regex.exec(loc[mandatory_keys[2]])) {
										error = "Email is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
							}
						}
					}
					if (error) {
						reject(error);
						return;
					}
					resolve(null);
				}
			};
			// debugger;
		})
		return promise;
	}



}

