import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-contiinex-process-delete-dialog',
  templateUrl: './contiinex-process-delete-dialog.component.html',
  styleUrls: ['./contiinex-process-delete-dialog.component.scss']
})
export class ContiinexProcessDeleteDialogComponent implements OnInit {

  constructor(	public dialogRef: MatDialogRef<ContiinexProcessDeleteDialogComponent>,) { }

  ngOnInit(): void {
  }
  deleteItem(val){
    this.dialogRef.close(val);
  }
  closePopup(){
    this.dialogRef.close()
  }
}
