import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-trainer-create',
  templateUrl: './trainer-create.component.html',
  styleUrls: ['./trainer-create.component.scss']
})
export class TrainerCreateComponent implements OnInit {
  showHideAddBulkTrainers:boolean=true;
  showHideAddSingleTrainers:boolean=false;
  constructor(public router:Router) { }

  ngOnInit() {
  }
  changebuttonToggle(value){
if(value=="addBulkTrainers"){
  this.showHideAddBulkTrainers=true;
  this.showHideAddSingleTrainers=false;
}
else if(value=="addSingleTrainer"){
  this.showHideAddBulkTrainers=false;
  this.showHideAddSingleTrainers=true;
}

  }
  backtoTrainerList(){
    this.router.navigateByUrl('/training/trainers');
  }
}
