<div class="mainDv">
  <p class="text-center"><img class="imgFaqLogo" src="../assets/web_images/faqLogoImg.png" /></p>
  <h1 class="h1-hello">Hello, How Can We Help ?</h1>
  <div class="row">
    <div class="col-md-12" style="justify-content: center;display: flex;">
      <div class="searchDiv">
        <img src="../assets/web_images/faqSearchIcon.svg" class="imgSrchIcon">
        <input type="text" placeholder="Ask a question…" [(ngModel)]="searchInputText">
        <button type="submit" (click)="highlightTextFound(searchInputText)">Search</button>
      </div>
    </div>
  </div>
  <p class="findcs">Or choose a category to quickly find the help you need</p>
  <div class="containerFaq">
    <div *ngFor="let v of getTabList ;let i = index" [class.active]="selectedIndex === i" (click)="setIndex(i,v)">
      {{v.name}}</div>
  </div>

  <!-- <div id="ComplianceId" class="vk-accordion"> <div class="vk-card mb-0">
      <div class="vk-card-header collapsed" data-toggle="collapse" href="#collapseOne"><a class="card-title"> Compliance</a></div>
      <div id="collapseOne" class="vk-card-body collapse" data-parent="#ComplianceId"></div> </div></div> -->



  <div id="ComplianceId" class="vk-accordion" *ngIf="showHideComliance">
    <div class="vk-card mb-0">
      <div class="vk-card-header">
        <span class="card-title"> Compliance</span>
      </div>
      <div id="collapseOne" class="vk-card-body">
        <p *ngFor="let v of getCompliance"><span class="lbl1"> {{v.name}} </span>
          <span *ngFor="let d of v.instruction" class="lbDesc">
            <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
          </span>
        </p>
        <p><span class="lbl1"> Dependency Factor</span> </p>
        <p> <img src="../assets/web_images/dependencyFactor.png" class="depFac" /></p>

        <div class="rowfx">
          <div><img src="../assets/web_images/internetSpeedImg.png" /><br><b
              style="position: relative;top: 7px;">Internet Speed</b> </div>
          <div><img src="../assets/web_images/androidVersionImg.png" /><br><b>Android Version</b> </div>
          <div> <img src="../assets/web_images/phoneSignalImg.png" /><br><b>Sim Signal strength</b></div>
        </div>
      </div>
    </div>
  </div>



  <div id="RecruitmentDashboardId" class="vk-accordion" *ngIf="showHideRecruitmentDashboard">
    <div class="vk-card mb-0">
      <div class="vk-card-header">
        <span class="card-title"> Recruitment Dashboard</span>
      </div>
      <div id="collapseTwo" class="vk-card-body">

        <p class="lbDesc">Candidate can fill the registration form and fill the basic details of their experience and
          education.
          After registration they take assessment and other relevant test for further selection process.
          Recruiter will assess candidate’s performance and evaluation for further selection.</p>
        <div> <img class="rdImg" src="../assets/web_images/RDash.png" /></div>
        <br>
        <p *ngFor="let v of getRecruitmentDashboard"><span class="lbl1" style="color:#238609;font-size: 1.5em;">
            {{v.labelName}} <br></span>
          <span *ngFor="let d of v.record" class="lbDesc">
            <span class="lbl1" style="color:#000000;margin-bottom: 3px;">{{d.name}} <br>{{v.description}}</span>
            <span *ngFor="let r of d.instruction" class="pl-0">
              <mat-icon class="adjustBullet">adjust</mat-icon>{{r.desc}}<br>
            </span>
          </span>
        </p>
        <p style="color:#238609;font-size: 1.5em;"><u>FAQ </u></p>
        <p *ngFor="let v of getRecruitmentDashboard_FAQ; let i = index;">
          <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{i+1}}.&nbsp;&nbsp;{{v.labelName}} <br></span>
          <span class="pb-2"><b>Steps:</b><br></span>
          <span *ngFor="let d of v.instruction" class="pl-0 lbDesc">
            <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
          </span>
        </p>
        <p style="color:#238609;font-size: 1.5em;"><u>Recruiter</u></p>
        <p *ngFor="let v of getRecruitmentDashboard_Recruiter; let i = index;">
          <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{i+1}}.&nbsp;&nbsp;{{v.labelName}} <br></span>
          <span class="pb-2"><b>Steps:</b><br></span>
          <span *ngFor="let d of v.instruction" class="pl-0 lbDesc">
            <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
          </span>
        </p>
      </div>
    </div>
  </div>



  <div id="TrainingModuleId" class="vk-accordion" *ngIf="showHideTrainingModule">
    <div class="vk-card mb-0">
      <div class="vk-card-header">
        <span class="card-title"> Training Module </span>
      </div>
      <div id="collapseThree" class="vk-card-body">

        <p class="lbDesc">This module helps trainer to create training batch & their schedule, allot available trainer. We
          can monitor training batch, their assessment, mock call audit, OJT batch creation &
          certification. https://uearn.ai/ </p>
        <br>
       <p *ngFor="let v of getTrainingModule"><span class="lbl1" style="color:#238609;font-size: 1.5em;">
            {{v.labelName}} <br></span>
          <span *ngFor="let d of v.record" class="lbDesc">
            <span class="lbl1" style="color:#000000;margin-bottom: 3px;">{{d.name}}</span>
            <span *ngFor="let r of d.instruction" class="pl-0">
              <mat-icon class="adjustBullet">adjust</mat-icon>{{r.desc}}<br>
            </span>
          </span>
        </p>
       <p style="color:#238609;font-size: 1.5em;"><u>Training Activity List </u></p>

       <p *ngFor="let v of getTrainingActivityList"><span class="lbl1" style="color:#238609;font-size: 1.5em;">
        {{v.labelName}} <br></span>
      <span *ngFor="let d of v.record" class="lbDesc">
        <span class="lbl1" style="color:#000000;margin-bottom: 3px;">{{d.name}}</span>
        <span *ngFor="let r of d.instruction" class="pl-0">
          <mat-icon class="adjustBullet">adjust</mat-icon>{{r.desc}}<br>
        </span>
      </span>
    </p>
    <p style="color:#238609;font-size: 1.5em;"><u>FAQ </u></p>
         <p *ngFor="let v of getTrainingModule_FAQ; let i = index;">
          <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{i+1}}.&nbsp;&nbsp;{{v.labelName}} <br></span>
          <span class="pb-2"><b>We would recommend the following steps. </b><br></span>
          <span *ngFor="let d of v.instruction" class="pl-0 lbDesc">
            <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
          </span>
          <span class="lbl1" style="color:#238609;font-size: 1.2em;"><img class="depFac" [src]="v.imgPath"/> <br></span>
        </p>
        <p style="color:#238609;font-size: 1.5em;"><u>Troubleshooting Guide</u></p>
         <p *ngFor="let v of getTrainingTroubleshooting; let i = index;">
          <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{i+1}}.&nbsp;&nbsp;{{v.labelName}} <br></span>
          <span class="pb-2"><b>Steps:</b><br></span>
          <span *ngFor="let d of v.instruction" class="pl-0 lbDesc">
            <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
          </span>
        </p>
      </div>
    </div>
  </div>


  <div id="MockCallId" class="vk-accordion" *ngIf="showHideMockCall">
    <div class="vk-card mb-0">
      <div class="vk-card-header">
        <span class="card-title"> Mock Call Dashboard</span>
      </div>
      <div id="collapsFour" class="vk-card-body">
        <p class="lbDesc">Training admin can enrol Agents for Mock calling and for further training. Once Agents successfully
          clear the Assessment then they would be added to OJT group and then to Production.</p>
        <br>
    
        <p *ngFor="let v of getMockCall"><span class="lbl1" style="color:#238609;font-size: 1.5em;">
          {{v.labelName}} <br></span>
        <span *ngFor="let d of v.record" class="lbDesc">
          <span class="lbl1" style="color:#000000;margin-bottom: 3px;">{{d.name}}</span>
          <span *ngFor="let r of d.instruction" class="pl-0">
            <mat-icon class="adjustBullet">adjust</mat-icon>{{r.desc}}<br>
          </span>
        </span>
      </p>
      <p style="color:#238609;font-size: 1.5em;"><u>Mock call Activities</u></p>
      <p *ngFor="let v of getMockCallActivities;">
        <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{v.labelName}} <br></span>
        <span *ngFor="let d of v.instruction" class="pl-0 lbDesc">
          <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
        </span>
      </p>
      <p style="color:#238609;font-size: 1.5em;"><u>FAQ</u></p>
    
      <p *ngFor="let v of   getMockCall_FAQ; let i = index;">
        <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{i+1}}.&nbsp;&nbsp;{{v.labelName}} <br></span>
        <span class="pb-2"><b>We would recommend the following steps. </b><br></span>
        <span *ngFor="let d of v.instruction" class="pl-0 lbDesc">
          <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
        </span>
        <span class="lbl1" style="color:#238609;font-size: 1.2em;"><img class="depFac" [src]="v.imgPath"/> <br></span>
      </p>
      </div>
    </div>
  </div>

  <div id="OJTProductionId" class="vk-accordion" *ngIf="showHideOJTProduction">
  <div class="vk-card mb-0">
    <div class="vk-card-header">
      <span class="card-title"> Production & OJT Dashboard</span>
    </div>
    <div id="collapsFive" class="vk-card-body">
      <p class="lbDesc">We can upload active campaign for OJT & production candidate group & facilitate out-bound calling. Project manager
        Can add/remove Agent and also Team lead. Can access Agent activity report, Agent summary report, Call report and Audit Report.</p>
      <br>
  
     <p *ngFor="let v of getOjtProductionDashboard"><span class="lbl1" style="color:#238609;font-size: 1.5em;">
        {{v.labelName}} <br></span>
      <span *ngFor="let d of v.record" class="lbDesc">
        <span class="lbl1" style="color:#000000;margin-bottom: 3px;">{{d.name}}</span>
        <span *ngFor="let r of d.instruction" class="pl-0">
          <mat-icon class="adjustBullet">adjust</mat-icon>{{r.desc}}<br>
        </span>
      </span>
    </p>
    <p style="color:#238609;font-size: 1.5em;"><u>Production Activities</u></p>
     <p *ngFor="let v of getProductionActivities"><span class="lbl1" style="color:#238609;font-size: 1.5em;">
            {{v.labelName}} <br></span>
          <span *ngFor="let d of v.record" class="lbDesc">
            <span class="lbl1" style="color:#000000;margin-bottom: 3px;">{{d.name}} <br>{{v.description}}</span>
            <span *ngFor="let r of d.instruction" class="pl-0">
              <mat-icon class="adjustBullet">adjust</mat-icon>{{r.desc}}<br>
            </span>
          </span>
        </p>
    <p style="color:#238609;font-size: 1.5em;"><u>FAQ</u></p>
  
        <p *ngFor="let v of   getOjtProduction_FAQ; let i = index;">
      <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{i+1}}.&nbsp;&nbsp;{{v.labelName}} <br></span>
      <span class="pb-2"><b>Steps </b><br></span>
      <span *ngFor="let d of v.instruction" class="pl-0 lbDesc">
        <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
      </span>
      <span class="lbl1" style="color:#238609;font-size: 1.2em;"><img class="depFac" [src]="v.imgPath"/> <br></span>
    </p>
  </div>
</div>
</div>



<div id="PaymentModuleId" class="vk-accordion" *ngIf="showHidePaymentModule">
  <div class="vk-card mb-0">
    <div class="vk-card-header">
      <span class="card-title">Payment Module</span>
    </div>
    <div id="collapsSix" class="vk-card-body">
      <p class="lbDesc">Payment module can be configured for updating, calculating and approval of pay out for GIG workers.
        Managers can update/configure the payment logic as per the Process and the approval procedure.
        GIG worker can view the notification about the payment and further he can agree or negotiate for
        same. Passbook on the APP has the current and historic payment details.<br><br>
        Manager should design the payment logic for GIG worker as in hourly/minute/event/call based at the
        start the payment cycle. Or he can even upload the Data sheet and calculate the payments. Project
        Manager/Managers have access to Edit, Configure and Approve the pay-outs.</p>
      <br>
  
     <p *ngFor="let v of getPaymentModule"><span class="lbl1" style="color:#238609;font-size: 1.5em;">
        {{v.labelName}} <br></span>
        <span  class="lbDesc">{{v.description}}</span><br>
        <span style="display: block;"><img [src]="v.imgPath" [ngStyle]="{'width': v.labelName=='Approval'?'300px':'100%'}" /></span>
      <span *ngFor="let d of v.instruction" class="lbDesc pl-0">       
          <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
          <img [src]="d.imgPath" [ngStyle]="{'width': d.imgPath=='../assets/web_images/approvalOne.jpg'?'300px':'100%'}"  />
      </span>
    </p>

    <p style="color:#238609;font-size: 1.5em;"><u>FAQ</u></p>
  
       <p *ngFor="let v of   getPaymentModule_FAQ; let i = index;">
      <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{i+1}}.&nbsp;&nbsp;{{v.labelName}} <br></span>
      <span class="pb-2"><b>Steps </b><br></span>
      <span *ngFor="let d of v.instruction" class="pl-0 lbDesc">
        <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br>
      </span>
    </p>
  </div>
</div>
</div>

<div id="AgentSlotId" class="vk-accordion" *ngIf="showHideAgentSlot">
  <div class="vk-card mb-0">
    <div class="vk-card-header collapsed">
      <span class="card-title">Agent Slot Dashboard</span>
    </div>
    <div id="collapsSeven" class="vk-card-body" >
      <p class="lbDesc"> We can allocate time slot of GIG staff as per process requirement. Manager can configure the time slot from Dashboard and later he can
        allot Gig worker himself or Gig working can choose the slots. Gig workers who are allotted with slot can login and only those will get the
        calls assigned. Manager can also add and remove Gig worker from the slot (adhoc request).<br><br>
        Manager should design the staffing model for the process, number of slots per day, working hour, working day and number of gig worker to
        be staffed. This setting will be applicable for each day in general.</p>
      <br>
  
    <p *ngFor="let v of getAgentSlot"><span class="lbl1" style="color:#238609;font-size: 1.5em;">
        {{v.labelName}} <br></span>
        <span style="color:#000000;margin-bottom: 3px;"><b>{{v.name}} </b></span><br><br>
      <span *ngFor="let d of v.instruction" class="lbDesc pl-0 pt-4">       
          <mat-icon class="adjustBullet">adjust</mat-icon>{{d.desc}}<br><span style="display: block;"><img [src]="d.imgPath" [ngStyle]="{'width': d.widthCs}"  /></span><br>
      </span>
    </p>
  </div>
</div>
</div>

<div id="ActivityDashboardId" class="vk-accordion" *ngIf="showHideActivityDashboard">
  <div class="vk-card mb-0">
    <div class="vk-card-header" >
      <span class="card-title">How to do this Activity- Dashboard FAQ</span>
    </div>
    <div id="collapsEight" class="vk-card-body">
      <p *ngFor="let v of   getActivityDashboard;">
        <span class="lbl1" style="color:#238609;font-size: 1.2em;">{{v.labelName}} <br></span>
         <span *ngFor="let d of v.record let i = index;" class="lbDesc">
          <span class="lbl1" style="color:#000000;margin-bottom: 3px;">{{i+1}}.&nbsp;&nbsp;{{d.name}}</span>
          <span class="pb-2"><b>We would recommend the following steps. </b><br></span>
          <span *ngFor="let r of d.instruction" class="pl-0">
            <mat-icon class="adjustBullet">adjust</mat-icon>{{r.desc}}<br>
          </span>
        </span>
      </p> 
      <br>

  </div>
</div>
</div>

<p><mat-icon class="arrowUpCs" (click)="arrowUpClick()">arrow_upward</mat-icon> </p>
  <br><br><br><br>
</div>