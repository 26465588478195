<app-new-contiinex-menu-page></app-new-contiinex-menu-page>
<div class="v-mainDivCO">
    <div>
        <div class="row animation-One2x vk-sec">          
            <div class="col-md-12 col-sm-12 col-xs-12">
                <h1 class="v-textH text-center v-BannerTitle">
                     <!-- AI-Powered Customer Experience & Engagement<br> 
                     Platform Across Voice, Text & Video -->
                    Gain insights into your “Voice Of Customer” 
                    </h1>
                <h3 class="v-txt1 mb-2">
                    <!-- Hyperscale your business by automating customer <br> digital journeys at every stage -->
                    Helps businesses enhance Customer Experience <br> with Speech & Conversational AI
                </h3>
                <p><span class="v-view-demo text-center" title="Know More" (click)="openKnowMore()"> KNOW MORE </span>
                    <span class="v-book-demo" title="BOOK A DEMO" (click)="bookDemoBtn()">BOOK A DEMO</span>
                </p>
            </div>
        </div>

                <div class="row mb-0 vk-sec vk-ContentXy"> 
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <h1 class="v-textH text-center">
                            <!-- Automate your customer experience <br>
                            with Speech AI-powered by our NLP engine -->
                            Automate 100% of your Quality Assurance Process <br> for both voice & non-voice
                        </h1>               
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12">

                        <div class="mt-0 eec-sec-Baner" style="bottom: 15px;">
                            <div class="cee-Sec-div-One cSec3-one"> </div> 
                            <div class="cee-Sec-div" style="width: 455px;">
                                <img src="../assets/contiinex/image/comnexExpBlueBannerImg.png" style="width: 100%;" />
                            </div> 
                            <div class="cee-Sec-div-Two mb-4">
                                <table class="ceeTable text-left ceeTable3 ww1">
                                    <tr> <td>
                                        <!-- <img src="../assets/contiinex/image/comnexExpBlueImg.png" style="width: 245px;"/>  -->
                                        
                                        <img src="../assets/contiinex/image/ainexLogo.png" class="v-LblHd1-Img"/> 
                                        <span class="v-LblHd1">Quality Assurance Automation</span>
                                    </td> </tr>
                                    <tr>
                                         <td><span class="vk-cee-desc" style="margin:10px 5px;line-height: 1.67;letter-spacing: 0.45px;">
                                            <!-- With our speech AI you can now transcribe and contextualize each of your customer experiences whether over voice, email or chat to gain insights through VOC
                                            workflows like self-service, ticketing, agent-assist, and quality audits. -->
                                         
                                            AI-based Quality Audit as a service for Insurance, Healthcare, Consumer Banking, & Retail with 100% data security. Automate all QA parameters across customer interactions on phone, email, chat & social channels.
                                            </span></td></tr>    
                                         <tr>
                                             <td>
                                               <span class="v-view-demo" title="Know More" (click)="clickKnowMoreComnexExperience()" style="margin: 3px 5px;text-align: center;padding: 14px 18px;"> KNOW MORE </span>
                                             </td>
                                         </tr>    
                                </table>
                            </div> 
                            <div class="cee-Sec-div-One cSec3-one"> </div> 
                            </div>
                        </div>





                    <div class="col-md-12 col-sm-12 col-xs-12 mt-4">
                        <h1 class="v-textH text-center mt-4">
                            <!-- Proactive customer engagement through <br>
                            Conversational AI over WhatsApp Bot, IVR & Dialler -->
                        
                            <!-- Cloud-Based, Desktop & Mobile-Based Dialer <br>
                            With Omnichannel Capabilities -->
                            Cloud-based, Desktop & Mobile based dialer<br> with omnichannel capabilities

                        </h1>               
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12">

                        <div class="mt-0 eec-sec-Baner vv1" style="bottom: 0px;">
                            <div class="cee-Sec-div-One cSec3-one"> </div> 
                            <div class="cee-Sec-div" style="width: 455px;">
                                <img src="../assets/contiinex/image/ppImgx1.png" style="width: 100%;" />
                            </div> 
                            <div class="cee-Sec-div-Two vcex1 mb-4">
                                <table class="ceeTable text-left ceeTable3 ww1" style="width: 75%;">
                                    <tr> <td>
                                        <!-- <img src="../assets/contiinex/image/comnexEngageImg.png" style="width: 245px;"/> -->
                                        <img src="../assets/contiinex/image/ComnixLogo.png" class="v-LblHd1-Img"/> 
                                        <span class="v-LblHd1 pl-1">
                                            Unified Communication as a Service
                                           
                                        </span>
                                    </td> </tr>
                                    <tr>
                                         <td><span class="vk-cee-desc" style="margin:10px 5px;line-height: 1.67;letter-spacing: 0.45px;">
                                            <!-- Automate your customer conversations using Visual IVR, WhatsApp, Chat, cloud-based dialer & Video Bots to enhance customer engagement.<br>
                                            Comnex Engage allows you to sequence any of the conversation channels to automate your customer engagement process whether for any inbound service queue or an outbound sales process. -->
                                            Contiinex Cloud-based dialer with inbuilt WhatsApp/Voice/SMS Bot capabilities can now help your agents engage seamlessly with their clients for both inbound & outbound campaigns. Get a centralized dashboard with a 360° view of your business process.
                                            </span></td></tr>    
                                         <tr>
                                             <td>
                                               <span class="v-view-demo" title="Know More" (click)="clickKnowMoreComnexEngage1()" style="margin: 3px 5px;text-align: center;padding: 14px 18px;"> KNOW MORE </span>
                                             </td>
                                         </tr>    
                                </table>
                            </div> 
                            <div class="cee-Sec-div-One cSec3-one"> </div> 
                            </div>


                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12 vkXTop1" style="display: none;">
                            <h1 class="v-textH text-center">
                                <!-- Automate Customer experience workflows <br>
                                with Speech AI & Cloud Dialer -->
                                Next-Gen call center automation platform that <br>
                                you to manage employee life cycle in a single platform
                            </h1> 
                        </div>


                        <div class="col-md-12 col-sm-12 col-xs-12 mb-4 mt-3" style="display: none;">
                            <div class="mt-0 eec-sec-Baner btmx4 mmy">
                                <div class="cee-Sec-div-One cSec4-one"> </div> 
                                <div class="cee-Sec-div forMobileDiv" style="width: 455px;">
                                    <img src="../assets/contiinex/image/comnexExpX2.png" style="width: 100%;margin: 35px 0 0 0;" />
                                </div> 
                                <div class="cee-Sec-div-Two mb-4 xx4">
                                    <table class="ceeTable text-left ceeTable3" style="width: 100%;">
                                        <tr> <td class="tRytSec4"><img src="../assets/contiinex/image/comnexEnableGreenImg.png" style="width: 250px;"/> </td> </tr>
                                        <tr>
                                             <td><span class="vk-cee-desc tRytSec4" style="margin:10px 5px;line-height: 1.67;letter-spacing: 0.45px;">End to End Agent Life Cycle Automation for Hiring, Onboarding, Training, and Workforce management.<br> Ensures you save on OpEx & helps you focus on increasing operational agility, adhering to Compliance.<br>
                                                ​​Use it either as a modular product to support your call center operations or choose the complete comprehensive, easy-to-use suit to omit redundancy & promote resource efficiency.
                                                </span></td></tr>    
                                             <tr>
                                                 <td class="tRytSec4">
                                                   <span class="v-view-demo" title="Know More" (click)="clickKnowMoreComnexEnable()" style="margin: 3px 5px;text-align: center;padding: 14px 18px;"> KNOW MORE </span>
                                                 </td>
                                             </tr>    
                                    </table>
                                </div> 
                                <div class="cee-Sec-div forWebDiv" style="width: 455px;">
                                    <img src="../assets/contiinex/image/comnexExpX2.png" style="width: 100%;" />
                                </div> 
                                <div class="cee-Sec-div-One cSec4-one"> </div> 
                                </div>  
                            </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 vkXTop2">
                        <h1 class="v-textH text-center">
                            <!-- Engage customers at every stage of their journey <br>
                            through WhatsApp Business -->
                            Automate your Customer Engagement with two-way <br>conversation on WhatsApp Business Account
                        </h1>
                    </div>




                    <div class="col-md-12 col-sm-12 col-xs-12 mb-2 mt-3">
                        <div class="mt-0 eec-sec-Baner btmx4">
                            <div class="cee-Sec-div-One cSec4-one"> </div> 
                            <div class="cee-Sec-div forMobileDiv" style="width: 455px;">
                                <img src="../assets/contiinex/image/whatappGreenRecentImg.png" style="width: 100%;margin: 35px 0 0 0;" />
                            </div> 
                            <div class="cee-Sec-div-Two mb-4 xx4">
                                <table class="ceeTable text-left ceeTable3" style="width: 100%;">
                                    <tr> <td class="tRytSec41">
                                        <img src="../assets/contiinex/image/wapigreenImg.png" style="width: 270px;"/>
                                        <!-- <img src="../assets/contiinex/image/wdj-wp.png" class="v-LblHd1-Img-WA"/> 
                                        <span class="v-LblHd1 pl-0" style="right: 11px;">
                                           WABA
                                        </span> -->
                                    
                                    </td> </tr>
                                    <tr>
                                         <td><span class="vk-cee-desc tRytSec41" style="margin:10px 5px;line-height: 1.67;letter-spacing: 0.45px;">
                                            <!-- Connect with your customers through the most accepted messaging channel with Contiinex’s WhatsApp Business & Messaging API’s. Contiinex Automation Solution with powerful WhatsApp messaging is all that you need, to take care of your customers’ business conversational needs at every stage of their journey. -->

                                            <!-- With Contiinex WhatsApp Business API, you can automate your customer process for sales, service, or support for marketing, pre-purchase, or post-purchase engagement. It is supported by a unified dashboard giving an in-depth view of all your customer engagement matrics. -->
                                            With Contiinex WhatsApp Business API, you can automate your customer process for sales, service, or support for marketing, pre-purchase, or post-purchase engagement. It is supported by a unified dashboard giving an in-depth view of all your customer engagement metrics.
                                            </span></td></tr>    
                                         <tr>
                                             <td class="tRytSec41">
                                               <span class="v-view-demo" title="Know More" (click)="clickKnowMoreWhatsappApi()" style="margin: 3px 5px;text-align: center;padding: 14px 18px;"> KNOW MORE </span>
                                             </td>
                                         </tr>    
                                </table>
                            </div> 
                            <div class="cee-Sec-div forWebDiv" style="width: 455px;">
                                <img src="../assets/contiinex/image/whatappGreenRecentImg.png" style="width: 100%;" />
                            </div> 
                            <div class="cee-Sec-div-One cSec4-one"> </div> 
                            </div>  
                        </div>









      
                <div class="col-md-12 col-sm-12 col-xs-12 mt-4" style="display: none;">

                <div>

            <div class="row" style="padding: 0 20px;padding-bottom: 0;align-items: center;">
                <div class="col-md"></div>
                <div class="col-md-4 forMobileMenuOX">
                    <img class="" src="../assets/contiinex/image/wappax.png" style="width: 200px;" />
                </div>
                <div class="col-md-5 text-right1">
                    <p class="v-p1 v-hx2 mb-1">WhatsApp Business API</p>
                    <p class="v-hx4">                      
                        Connect with your customers through the most accepted messaging <br> channel with Contiinex’s
                        WhatsApp 
                        Business & Messaging API’s
                    </p>

                </div>
                <div class="col-md-4 forWebMenuOX text-left">
                    <img class="" src="../assets/contiinex/image/wappax.png" style="width: 200px" />
                </div>

                <div class="col-md"></div>
            </div>

            <div class="row mt-4" style="padding: 20px;padding-bottom: 0;align-items: center;">
                <div class="col-md-1"></div>
                <div class="col-md-4  text-right1">
                    <img class="wp-auto" src="../assets/contiinex/image/wappa-auto.png" />
                </div>
                <div class="col-md-5 text-left">
                    <p class="v-p1 v-hx2 mb-1">Contiinex Automation Solution</p>
                    <p class="v-hx4">
                        WhatsApp Business Account for a personalised customer experience. Contiinex Automation Solution
                        with powerful WhatsApp messaging is all that you need, to take care of your customers’ business
                        conversation needs at every stage of their journey.
                    </p>

                </div>

                <div class="col-md-2"></div>
            </div>



            <div class="row mt-2" style="padding: 20px;padding-bottom: 0;align-items: center;">
                <div class="col-md-1"></div>
                <div class="col-md-5 forMobileMenuOX">
                    <img class="" src="../assets/contiinex/image/wappa-feature.png" style="width: 100%;" />
                </div>
                <div class="col-md-5 text-right1">
                    <p class="v-p1 v-hx2">Features</p>
                    <p class="v-hx4">
                        The WhatsApp Business API supports all the features provided by WhatsApp business and allows you
                        to integrate WhatsApp Business API into your existing system to use various rich messaging and
                        conversation services right from day one.
                    </p>

                </div>
                <div class="col-md-5 forWebMenuOX">
                    <img class="" src="../assets/contiinex/image/wappa-feature.png" style="width: 300px" />
                </div>

                <div class="col-md-1"></div>
            </div>


            <p class="mt-4 text-center"><span class="v-view-demo text-center" title="Know More">
                    KNOW MORE </span>
            </p>
                </div>

                </div>

            </div>
<div style="display: none;">
            <div class="row mb-2 animation-One2x vk-sec vk-sec2 vkXTop3">          
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <h1 class="v-textH text-center"> Customer experience & engagement <br>
                        AI platform across voice, messaging and video</h1>               
                </div>
            </div>
            <div class="mt-0 eec-sec-Baner"  style="bottom: 0PX;">
                <div class="cee-Sec-div-One"> </div> 
                <div class="cee-Sec-div">
                    <img src="../assets/contiinex/image/cee-Img.png" class="ceeImg" />
                </div> 
                <div class="cee-Sec-div-Two">
                    <table class="ceeTable vv3">
                        <tr><td colspan="2"><br></td></tr>
                        <tr> <td style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceexInsaurance.png" class="v-cee-img"/> </td>  <td><span class="vk-cee-title">Insurance </span> <span class="vk-cee-desc">
                            <ul class="vkDotClsClr v-clr-Insaurance">
                                <li> Audit Automation</li>
                                <li>Agent Assist</li>                               
                                <li>Transcription </li>
                              </ul>
                                    

                        </span></td></tr>
                        <tr><td colspan="2"><br></td></tr>
                        <tr> <td  style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex4.png" class="v-cee-img"/> </td>  <td><span class="vk-cee-title">Fintech</span> <span  class="vk-cee-desc">                                                       
                            <ul class="vkDotClsClr v-clr-Fintech">
                                <li>New customer Onboarding</li>
                                <li>Renewals</li>
                                <li>Account Management </li>
                                <li>Audit Automation</li>
                                <li> Collections Automation</li>
                              </ul>                        
                        </span></td></tr>
                        <tr><td colspan="2"><br></td></tr>
                        <tr> <td style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex1.png" class="v-cee-img"/> </td>  <td><span class="vk-cee-title">Retail </span> <span class="vk-cee-desc">
                            <ul class="vkDotClsClr v-clr-Retail">
                                <li>Store Locator</li>
                                <li>Catalog & Stock information</li>
                                <li>Promotions & Offers </li>
                              </ul>
                        </span></td></tr>
    
                       <tr><td colspan="2"><br></td></tr>
    
                        <tr> <td  style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex2.png" class="v-cee-img"/> </td>  <td><span class="vk-cee-title">D2C ecommerce</span> <span  class="vk-cee-desc"> 
                            <ul class="vkDotClsClr v-clr-d2c">
                                <li>Abandoned cart</li>
                                <li>Shipment Automation</li>
                                <li>Returns Automation</li>
                              </ul>
                        </span></td></tr>
                            <tr><td colspan="2"><br></td></tr>
                            
                         
                    </table>
                </div> 
                <div class="cee-Sec-div-One"> </div> 
                </div>
    
    
                <div class="mt-0 eec-sec-Baner eecSecBanner2 vv2" style="bottom: 20px;" >
                    <div class="cee-Sec-div-One cRytDiv"> </div> 
                    <div class="cee-Sec-div cRytDiv2 forMobileDiv">
                        <img src="../assets/contiinex/image/cee-img-2x.png" class="ceeImg" />
                    </div> 
                    <div class="cee-Sec-div-Two cRytWidth vkSecondX2">
                        <table class="ceeTable ceeTable1 vv3">
                                    <tr>  <td>
                                        <span class="vk-cee-title cRyt">Marketplace </span>
                                         <span class="vk-cee-desc cRyt">
                                            <ul class="vkDotClsClr v-clr-Marketplace">
                                                <li>Seller onboarding & support</li>
                                                <li>App deployment </li>
                                                <li>Assisted sales</li>
                                              </ul>                                            
                                        </span></td>
                                        <td style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex3.png" class="v-cee-img"/> </td>
                                    </tr>
                
                                   <tr><td colspan="2"><br></td></tr>

                                   <tr>  <td>
                                    <span class="vk-cee-title cRyt">BPO & KPO</span>
                                     <span class="vk-cee-desc cRyt">                                       
                                         <ul class="vkDotClsClr v-clr-bpoKpo">
                                            <li> Hiring & Onboarding</li>
                                            <li>Training</li>
                                            <li>Retention</li>
                                            <li>Employee Support</li>
                                          </ul>    
                                        </span></td>
                                    <td style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex5.png" class="v-cee-img"/> </td>
                                </tr>
            
                               <tr><td colspan="2"><br></td></tr>



                                    <tr>  <td>
                                <span class="vk-cee-title cRyt">Captive Contact Center </span>
                                 <span class="vk-cee-desc cRyt">
                                <ul class="vkDotClsClr v-clr-capativeCC">
                                    <li> Inbound, Outbound </li>
                                    <li>Email & Chat</li>
                                    <li>Agent Assist</li>
                                    <li>Audit Automation</li>
                                  </ul>   
                            </span></td>
                                <td style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex6.png" class="v-cee-img"/> </td>
                            </tr>
        
                           <tr><td colspan="2"><br></td></tr>
        
                            <tr> <td>
                                <span class="vk-cee-title cRyt">HealthCare & Wellness</span> 
                                <span  class="vk-cee-desc cRyt">        
                                        
                                <ul class="vkDotClsClr v-clr-healthCW">
                                    <li> Appointment Schedule</li>
                                    <li> Invoice & Prescription</li>
                                    <li> Shipment & Delivery </li>
                                    <li> Audit Automation</li>
                                  </ul>   
                            </span></td>
                                <td style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex7.png" class="v-cee-img"/> </td>
                            </tr>
                                <tr><td colspan="2"><br></td></tr>

                        </table>
                    </div> 
                    <div class="cee-Sec-div cRytDiv2 forWebDiv">
                        <img src="../assets/contiinex/image/cee-img-2x.png" class="ceeImg" />
                    </div> 
                    <div class="cee-Sec-div-One"> </div> 
                    </div>


                    <div class="mt-0 eec-sec-Baner vv2" >
                        <div class="cee-Sec-div-One"> </div> 
                        <div class="cee-Sec-div">
                            <img src="../assets/contiinex/image/ceeVxImg3.png" class="ceeImg" />
                        </div> 
                   
                        <div class="cee-Sec-div-Two">
                            <table class="ceeTable vv3">
                                <tr><td colspan="2"><br></td></tr>
                                <tr> <td style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex8.png" class="v-cee-img"/> </td>  <td><span class="vk-cee-title">Hospitality </span> <span class="vk-cee-desc">
                                    <ul class="vkDotClsClr v-clr-Hospitality">
                                        <li> Booking & Events Details</li>
                                        <li> Touch-less check-in through WA(OTP)</li>                               
                                        <li>Card balance & Card value top-up </li>
                                        <li>Rewards </li>
                                      </ul>
                                            
        
                                </span></td></tr>
                                <tr><td colspan="2"><br></td></tr>
                                <tr> <td  style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex9.png" class="v-cee-img"/> </td>  <td><span class="vk-cee-title">Logistics & Delivery</span> <span  class="vk-cee-desc">                                                       
                                    <ul class="vkDotClsClr v-clr-Logistic">
                                        <li>Forward Shipment Management</li>
                                        <li>Returns Management</li>
                                        <li> Pick up notifications </li>
                                      </ul>                        
                                </span></td></tr>
                                <tr><td colspan="2"><br></td></tr>
                                <tr> <td style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceex10.png" class="v-cee-img"/> </td>  <td><span class="vk-cee-title">Media </span> <span class="vk-cee-desc">
                                    <ul class="vkDotClsClr v-clr-Media">
                                        <li>New customer Acquisition</li>
                                        <li>Personalized content & recommendation</li>
                                        <li>Subscription Reminders </li>
                                      </ul>
                                </span></td></tr>
            
                               <tr><td colspan="2"><br></td></tr>
            
                                <tr> <td  style="vertical-align: baseline;"><img src="../assets/contiinex/image/ceexMarketSale.png" class="v-cee-img"/> </td>  <td><span class="vk-cee-title">Marketing & Sales</span> <span  class="vk-cee-desc"> 
                                    <ul class="vkDotClsClr v-clr-marketSales">
                                        <li>Campaign Automation</li>
                                        <li>Themed advertising content</li>
                                        <li>Omnichannel Marketing</li>
                                      </ul>
                                </span></td></tr>
                                    <tr><td colspan="2"><br></td></tr>                                    
                                 
                            </table>
                        </div> 
                        <div class="cee-Sec-div-One"> </div> 
                        </div>
                    </div>

<div style="display: none;">

                        <p class="v-hd2 mt-4" style="font-weight: bold;">Benefits</p>


                        <div class="row text-center">
                            <div class="col-md-2 col-sm-12 col-xs-12"></div>
                            <div class="col-md-4 col-sm-12 col-xs-12 p-1">
                                <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #ffd5b5;">
                                    <p  class="text-center " style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon4.png" class="v-ben-fit-img"/></p>
                                    <p class="mb-1"><span class="v-benefit-titlex1">Strengthen Governance</span></p>
                                    <p class="v-benefit-descx11">
                                        <!-- Improves workflow and efficiency by minimizing risk and harm by identifying them sooner -->
                                        Flag & Reduce any Compliance & Risk Vulnerabilities real time
                                    </p>
                    
                                </mat-card>
                            </div>
                            <div class="col-md-4 col-sm-12 col-xs-12 p-1">
                                <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #ffd5b5;">
                                    <p  class="text-center " style="height: 40px;"><img src="../assets/contiinex/image/uVoc.png" class="v-ben-fit-img" style="width: 45px;"/></p>
                                    <p class="mb-1"><span class="v-benefit-titlex1">Understand VOC
                                    </span></p>
                                    <p class="v-benefit-descx11">Multilingual transcription capabilities  

                                    </p>
                    
                                </mat-card>
                            </div>
                        <div class="col-md-2 col-sm-12 col-xs-12"></div>
                        </div>




                        <div class="row text-center">
                            <div class="col-md-2 col-sm-12 col-xs-12"></div>
                            <div class="col-md-4 col-sm-12 col-xs-12 p-1">
                                <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #d0e2a2;">
                                    <p class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon1.png" class="v-ben-fit-img"/></p>
                                    <p class="mb-1"><span class="v-benefit-titlex1"> Scalability</span></p>
                                    <p class="v-benefit-descx11">
                                        Automate any process or any customer interactions, eliminates regulatory blind spots, reduce & prevent future risks
                                         </p>
                    
                                </mat-card>
                            </div>
                            <div class="col-md-4 col-sm-12 col-xs-12 p-1">
                                <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #b9e3e3;">
                                    <p  class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/bftDepth.png" class="v-ben-fit-img" style="width: 44px;"/></p>
                                    <p class=" mb-1"><span class="v-benefit-titlex1">
                                        In-depth VOC Analysis

                                    </span></p>
                                    <p class="v-benefit-descx11">
                                        Derive multi level business insights to Increase FTR, Optimize AHT, to identify buyer intent & market trends.

                                    </p>
                    
                                </mat-card>
                            </div>
                        <div class="col-md-2 col-sm-12 col-xs-12"></div>
                        </div>
                        <div class="row text-center">
                            <div class="col-md-2 col-sm-12 col-xs-12"></div>
                            <div class="col-md-4 col-sm-12 col-xs-12 p-1">
                                <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #c5c0de;">
                                    <p class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon3.png" class="v-ben-fit-img"/></p>
                                    <p class="mb-1"><span class="v-benefit-titlex1"> Accuracy</span></p>
                                    <p class="v-benefit-descx11">
                                        Ability to process and assess large & complex data sets with 97% accuracy
                                         </p>
                    
                                </mat-card>
                            </div>
                            <div class="col-md-4 col-sm-12 col-xs-12 p-1">
                                <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #b9e3e3;">
                                    <p  class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon2.png" class="v-ben-fit-img"/></p>
                                    <p class=" mb-1"><span class="v-benefit-titlex1">
                                        <!-- Reduce cost -->
                                        Save cost
                                    </span></p>
                                    <p class="v-benefit-descx11">
                                        <!-- Save 40% cost incurred on repeat or redundant process -->
                                        Phase wise cost reduction to the business by 50%

                                    </p>
                    
                                </mat-card>
                            </div>
                        <div class="col-md-2 col-sm-12 col-xs-12"></div>
                        </div>
                        <div class="row text-center">
                            <div class="col-md-2 col-sm-12 col-xs-12"></div>
                            <div class="col-md-4 col-sm-12 col-xs-12 p-1">
                                <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #efc5df;">
                                    <p class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon5.png" class="v-ben-fit-img"/></p>
                                    <p class="mb-1"><span class="v-benefit-titlex1"> Tailored Expertise</span></p>
                                    <p class="v-benefit-descx11">
                                        Improved agent performance through multi level support & Parameters on Etiquette, Process, Compliance & Regulations
                                         </p>
                    
                                </mat-card>
                            </div>
                            <div class="col-md-4 col-sm-12 col-xs-12 p-1">
                                <mat-card class="v-benefit-mcx11" style="border: 1.67px solid #bedeca;">
                                    <p  class="text-center" style="height: 40px;"><img src="../assets/contiinex/image/v-benefitIcon6.png" class="v-ben-fit-img"/></p>
                                    <p class="mb-1"><span class="v-benefit-titlex1">
                                        <!-- Eliminates Fraud & Risk -->
                                        Improved Agent Performance

                                    </span></p>
                                    <p class="v-benefit-descx11">
                                        <!-- Improves workflow and efficiency by minimizing risk and harm by identifying them
                                         sooner and highlighting it to the right stakeholders automatically and in realtime. -->
                                         Improved agent performance through focused training & real-time feedback

                                        </p>
                    
                                </mat-card>
                            </div>
                        <div class="col-md-2 col-sm-12 col-xs-12"></div>
                        </div>
                        <p class="text-center mt-3 vv4"><span class="v-view-demo text-center" title="Know More" (click)="openKnowMore_benefit()"> KNOW MORE </span>
                            <span class="v-book-demo" title="BOOK A DEMO" (click)="bookDemoBtn_Benefit()">BOOK A DEMO</span>
                        </p>


                    </div>


    

        <p class="v-hd2" style="font-weight: bold;margin-top: 40px;">
            <!-- How Contiinex Helps You Hyper-Scale Your<br> Business? -->
            How does Contiinex speech intelligence<br> help in Quality Assurance?
        </p>

        <!--start-->
        <br>
        <div class="v-pad-x">
            <div class="row mt-4 spaceMargin1">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <img class="" src="../assets/contiinex/image/v-image-new1.png" style="width: 100%;" />
                </div>
                <div class="col-md-4">
                    <p class="v-p1 v-hx2" style="height: auto;">
                        <!-- Customer interaction audit -->
                        Customer interaction audit
                    </p>
                    <p class="v-hx3">100%</p>
                    <p class="v-hx4">
                        <!-- Your Quality Audit is now AI enabled! With Contiinex you can now Monitor 100% of all customer interactions as against 1 to 2%. Eliminate fraud, access risk at right instance, Improve efficiency of the processes and deliver a great customer experience with Contiinex’s Speech Automation for Auto Auditing. --> 
                        With Contiinex you can now monitor 100% of all customer interactions for both voice & non-voice as against sample of 1-2% quality audits. Eliminate fraud by accessing risk at the right instance & improve process efficiency. 
                      
                    </p>
                    <p class="mt-4"><span class="v-view-demo text-center" title="Know More" (click)="clickKnowMoreComnexExperience()"> KNOW MORE
                        </span>
                    </p>

                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row v-r1">
                <div class="col-md"></div>
                <div class="col-md-5">
                    <img class="" src="../assets/contiinex/image/v-line1.png" style="width: 100%;" />
                </div>
                <div class="col-md"></div>
            </div>


            <div class="row spaceMargin2">
                <div class="col-md-2"></div>
                <div class="col-md-4 forMobileMenuOX">
                    <img class="" src="../assets/contiinex/image/v-image-new2.png" style="width: 100%;
                position: relative;right: 50px;
            " />
                </div>
                <div class="col-md-4 text-right1">
                    <p class="v-p1 v-hx2" style="height: auto;">
                        <!-- Improve sales results by -->
                        Quality parameter automation
                    </p>
                    <p class="v-hx3">
                        <!-- 44% -->
                        100%
                    </p>
                    <p class="v-hx4">
                        <!-- Open the door for better sales conversion by reducing pressure on your sales team. Let us tell you how; With outbound Sales automation nurture cold database, mature them to warm with a perfect balance of Bots + Smart Dialer + Workforce -->
                      
                        Contiinex twin engines of Speech to Text & in-house NLP can auto-update all the Quality Audit parameters for any inbound, outbound, or back office CX service process.
                    </p>
                    <p class="mt-4"><span class="v-view-demo text-center" title="Know More" (click)="clickKnowMoreComnexEngage()"> KNOW MORE
                        </span>
                    </p>
                </div>
                <div class="col-md-4 forWebMenuOX img2Eng">
                    <img class="" src="../assets/contiinex/image/v-image-new2.png" style="width: 100%;position: relative;
                right: 60px;" />
                </div>

                <div class="col-md-2"></div>
            </div>

            <div class="row v-r1 spaceMargin3">
                <div class="col-md"></div>
                <div class="col-md">
                    <img class="" src="../assets/contiinex/image/v-line2.png" style="width: 100%;" />
                </div>
                <div class="col-md"></div>
            </div>

            <div class="row spaceMargin4">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <img class="" src="../assets/contiinex/image/v-image-new3.png" style="width: 100%;" />
                </div>
                <div class="col-md-4">

                    <p class="v-p1 v-hx2" style="height: auto;">
                        <!-- TCO reduced by -->
                     
                        Proof against any breach of compliance 
                    </p>
                    <p class="v-hx3">
                        <!-- 60% -->
                        100%
                    </p>
                    <p class="v-hx4">
                        <!-- One solution does it all! Next Gen call center automation technology that enables you to manage a complete employee life cycle in a single platform. Ensures you save on OpEx & helps you focus on increasing on operational agility, adhering Compliance. -->
                      
                        <!-- Contiinex can be deployed in-house on customer's private cloud instance or behind their firewall ensuring complete proof against any compliance breach, data leakage, data privacy issues. -->
                        Identify & get flagged for any breach of process non-compliance, whether during the conversation or document updation.

                    </p>
                    <p class="mt-4"><span class="v-view-demo text-center" title="Know More" (click)="clickKnowMoreComnexEnable()"> KNOW MORE
                        </span>
                    </p>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row v-r1">
                <div class="col-md"></div>
                <div class="col-md-5">
                    <img class="" src="../assets/contiinex/image/v-line1.png" style="width: 100%;" />
                </div>
                <div class="col-md"></div>
            </div>

            <div class="row spaceMargin5">
                <div class="col-md-2"></div>
                <div class="col-md-4 forMobileMenuOX">
                    <img class="" src="../assets/contiinex/image/v-image-new4.png" style="width: 100%;margin-bottom: 40px;" />
                </div>
                <div class="col-md-4 text-right1">
                    <p class="v-p1 v-hx2">
                        <!-- Reduce RTO By -->
                        Save cost per audit up to
                    </p>
                    <p class="v-hx3">
                        <!-- 70% -->
                        50%
                    </p>
                    <p class="v-hx4">
                        <!-- Ecommerce businesses have built their delivery processes with Contiinex’s Conversational AI Bots and API’s and have drastically reduced the ratio of their failed deliveries & return orders. Connect with your customers through the most accepted messaging channel at every stage of the shipment with Contiinex’s WhatsApp Business & Messaging API’s -->
                        <!-- Reduce the cost per hour of Audit & the cost spent per process. Increase agent productivity by identifying clear training needs and by delivering in real-time. -->
                        Optimize your audit spend & reduce your cost per hour of the audit. Increase agent productivity by identifying clear training needs & channelize the feedback to them in real-time.
                    </p>
                    <p class="mt-4"><span class="v-view-demo text-center" title="Know More"  (click)="clickKnowMoreComnexEngage()" > KNOW MORE
                        </span>
                    </p>

                </div>
                <div class="col-md-4 forWebMenuOX">
                    <img class="" src="../assets/contiinex/image/v-image-new4.png" style="width: 100%;" />
                </div>

                <div class="col-md-2"></div>
            </div>
        </div>
        <!--end-->
        <div style="display:none;">
        <br>
        <p class="v-hd2 mt-4 vk-our-client" style="font-weight: bold;">Our Clients</p>
        <div class="v-box">
            <div><img src="../assets/contiinex/image/v-sky.png" style="width: 125px;" /></div>
            <div><img src="../assets/contiinex/image/v-conneqt.png" style="width: 100px;" /></div>
            <div><img src="../assets/contiinex/image/v-shopify.png" style="width: 125px;" /></div>
            <div><img src="../assets/contiinex/image/v-rabbit.png" style="width: 75px;" /></div>
            <div><img src="../assets/contiinex/image/v-cliq.png"
                    style="width: 112px; height: 38px;position: relative;top: 20px;" /></div>

            <div><img src="../assets/contiinex/image/eshipz.png" style="width: 125px;" /></div>
            <div><img src="../assets/contiinex/image/kimball.png"
                    style="width: 100%; height: 25px;position: relative;top: 30px;" /></div>
            <div><img src="../assets/contiinex/image/experian.png" style="width: 122px;height: 50px;position: relative;top: 15px;" /></div>
            <div><img src="../assets/contiinex/image/clickpost.png" style="width: 125px;" /></div>
            <div><img src="../assets/contiinex/image/shiprocket.png" style="width: 125px;" /></div>

            <div><img src="../assets/contiinex/image/unicom.png" style="width: 70px;height: 64px;position: relative;top: 5px;" /></div>
            <div><img src="../assets/contiinex/image/magento.png" style="width: 110px;height: 30px;position: relative;top: 22px;" /></div>
            <div><img src="../assets/contiinex/image/3iInfo.png" class="i3InfotekImg" /></div>
            <!--<div><img src="../assets/image/arise.png" style="width: 102px;position: relative;height: 37px;top: 15px;" /></div>-->
            <div><img src="../assets/contiinex/image/teamLease.png" style="width: 85px;" /></div>

            <!-- <div><img src="../assets/image/exl.png" style="width: 95px;height: 60px" /></div> -->
            <div><img src="../assets/contiinex/image/ethinos.png" style="width: 130px;height: 61px;position: relative;top: 3px;" /></div>
            <!-- <div><img src="../assets/image/aha.png" style="width: 75px;height: 40px;position: relative;top: 15px;" /></div> -->
            <div><img src="../assets/contiinex/image/homeocare.png" style="width: auto;height: 65px;position: relative;top: 10px;" /></div>
            <div><img src="../assets/contiinex/image/motilal.png" style="width: 75px;" /></div>
            <div><img src="../assets/contiinex/image/bnp.png" style="width: 110px;" /></div>

        </div>
        <br>
    </div>
    <div style="display: none;">
        <p class="v-hd2 mb-0" style="font-weight: bold;margin-top: 100px;">Integrations are easy with Contiinex </p>
        <h3 class="v-txt2x mb-0 pb-0">
            Empower your businesses to seamlessly integrate their native systems or support tools with leading digital
            platforms. </h3>
            <h3 class="v-txt2x">   Contiinex believes in synergy and helps businesses deliver unified and improved customer experience
            with integrations and automation.
        </h3>

        <div class="row v-mar">
            <div class="col-md-4 text-center" style="background: #fafafa;">
                <p class="v-p1 pt-4">CMS’S & LMS </p>
                <p class="pt-4"><img class="" src="../assets/contiinex/image/v-first.png" style="width: 100%;" /></p>
            </div>
            <div class="col-md-4 text-center" style="background: #f5f5f5;">
                <p class="v-p1  pt-4">E-Commerce </p>
                <p class="pt-4"><img class="" src="../assets/contiinex/image/v-second.png" style="width: 100%;" /></p>
            </div>
            <div class="col-md-4 text-center" style="background: #fafafa;">
                <p class="v-p1 pt-4">Logistics </p>
                <p class="pt-4"><img class="" src="../assets/contiinex/image/v-third.png" style="width: 100%;" /></p>
            </div>
        </div>
    </div>
        <br>
        <div style="height: auto;background-color: #f4f4f4;padding-bottom: 60px;">
            <p class="v-hd2 pt-4" style="position: relative;top: 45px;">Testimonials</p>
            <p> <img src="../assets/contiinex/image/v-quote1.png" class="vQut1" /></p>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10 v-dBorder">

                    <table style="width:100%;" class="forWebMenuOX">
                        <tr>
                            <td> <img src="../assets/contiinex/image/v-arwind.png" class="v-person1" /></td>
                            <td>
                                <p class="v-person-desc1">At last, a seasoned leadership team, a novel premise,
                                    immaculate
                                    conceptual skills, and meticulous execution. Contiinex is indeed a company to watch
                                    out
                                    for. </p>
                                <p class="v-personName">Mr.M J Aravind</p>
                                <p class="v-P-Title"> <i>Co-founder Daksh</i></p>
                            </td>
                        </tr>
                    </table>
                    <div class="forMobileMenuOX">
                        <p class="text-center"> <img src="../assets/contiinex/image/v-arwind.png" class="v-person1"
                                style="margin-right: 0;" /></p>
                        <p class="v-person-desc1">At last, a seasoned leadership team, a novel premise, immaculate
                            conceptual skills, and meticulous execution. Contiinex is indeed a company to watch out
                            for. </p>
                        <p class="v-personName">Mr.M J Aravind</p>
                        <p class="v-P-Title"> <i>Co-founder Daksh</i></p>

                    </div>



                </div>
                <div class="col-md-1"></div>
            </div>
            <p class="text-right"> <img src="../assets/contiinex/image/v-quote2.png" class="vQut2" /></p>
        </div>
      
        <div class="v-video-cs"
            style="background-image: url('../assets/contiinex/image/v-videoBg.png');    padding: 10px; display:none">
            <p class="v-hd2 pt-4">Videos</p>

            <div class="row">
                <div class="col-md-1 col-sm-12 col-xs-12"></div>
                <div class="col-md col-sm-12 col-xs-12">
                    <iframe width="100%" height="293" src="https://www.youtube.com/embed/InuzK-jgBYQ"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen style="border-radius: 8px;"></iframe>
                    <p class="videoTitle">Video One</p>
                    <p class="videodesc">https://www.youtube.com/embed/InuzK-jgBYQ</p>
                </div>
                <div class="col-md col-sm-12 col-xs-12">
                    <iframe width="100%" height="293" src="https://www.youtube.com/embed/2uOjS-N4YCU"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen style="border-radius: 8px;"></iframe>
                    <p class="videoTitle">Video Two</p>
                    <p class="videodesc">https://www.youtube.com/embed/2uOjS-N4YCU</p>
                </div>
                <div class="col-md col-sm-12 col-xs-12">
                    <iframe width="100%" height="293" src="https://www.youtube.com/embed/InuzK-jgBYQ"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen style="border-radius: 8px;"></iframe>
                    <p class="videoTitle">Video Three</p>
                    <p class="videodesc">https://www.youtube.com/embed/InuzK-jgBYQ</p>
                </div>
                <div class="col-md-1 col-sm-12 col-xs-12"></div>
            </div>


        </div>
        <p class="v-hd2 pt-4 v-conInsight" style="display:none">Contiinex Insights</p>


        <div class="row" style="display:none">
            <div class="col-md-1 col-sm-12 col-xs-12"></div>
            <div class="col-md col-sm-12 col-xs-12">
                <mat-card class="text-center insightCard1">
                    <p class="insightTitle">July 21, 2021</p>
                    <p class="insightdesc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor</p>
                    <p><img class="v-insight1" src="../assets/contiinex/image/v-insight1.png" /></p>
                </mat-card>
            </div>
            <div class="col-md col-sm-12 col-xs-12">
                <mat-card class="text-center insightCard2">
                    <p class="forWebMenuOX"><img class="v-insight2" src="../assets/contiinex/image/v-insight2.png" /></p>
                    <p class="insightTitle">Aug 21, 2021</p>
                    <p class="insightdesc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor</p>
                    <p class="forMobileMenuOX"><img class="v-insight2" src="../assets/contiinex/image/v-insight2.png" /></p>
                </mat-card>
            </div>
            <div class="col-md col-sm-12 col-xs-12">
                <mat-card class="text-center insightCard3">
                    <p class="insightTitle">Sep 18, 2021</p>
                    <p class="insightdesc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor</p>
                    <p><img class="v-insight3" src="../assets/contiinex/image/v-insight3.png" /></p>
                </mat-card>
            </div>
            <div class="col-md-1 col-sm-12 col-xs-12"></div>
        </div>
    </div>


    <div class="row pt-0">
        <div class="col-md-12 col-sm-12 col-xs-12 ftr1">
            <app-new-contiinex-footer-section-page></app-new-contiinex-footer-section-page>
        </div>
    </div>


</div>
