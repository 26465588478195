import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { MyserviceService } from 'src/app/myservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';



@Component({
	selector: 'app-contact-list-desktop-calling',
	templateUrl: './contact-list-desktop-calling.component.html',
	styleUrls: ['./contact-list-desktop-calling.component.scss']
})
export class ContactListDesktopCallingComponent implements OnInit {
	displayedColumns = ['Name'];
	dataSource: any = new MatTableDataSource();
	selectedRow;
	_nameUser: any = "";
	_psType: any = "";
	showHideWhatsapp: boolean = false;
	showHidePhone: boolean = false;
	showHideText: boolean = false;
	selectedButton: any = "";
	_ddlListExtraOption: any = [];
	user: any = this.myservice.get_loggedin_user();
	stateSettings: any = this.myservice.get_stage_settings();
	_paraMessage: any = "";
	_rowExtraOptionData: any = [];
	templateddlValue: any;
	dateSelect: any;
	phoneNoModel: any;
	trackingLinkModel: any;
	relationshipManagerModel: any;
	Product_Name: any;
	Product_Link:any;
	selectedWhatsappTemplate: any;

	_contactOptions: any;
	selectedContact: any;
	_wTemplate: any = { options: [] };
	_caller_id: string = "";
	c2cEndPoint: string = "";
	subscriptionDial: Subscription;
	subscription: Subscription;
	callStatus: any;
	minDate: any = new Date();
	templatepart1: any;
	templatepart2: any;
	@ViewChild('fromInput', {
		read: MatInput
	}) fromInput: MatInput;
	Publisher_Name:any;
	Reject_Key:any;
	Accept_Key:any;
	Service_Station:any;
	Vehicle_Number:any;
	Service_Name:any;
	Invoice_Number:any;
	Web_Link:any;
	media:any;
	ismedia:boolean=false;
  api_end_point:any;
	constructor(private dialog: MatDialog, public router: Router, private API: ApiService, private myservice: MyserviceService, private _snackBar: MatSnackBar) {
		this.myservice.settings.subscribe(e => {
			this.stateSettings = e;
		})
	 }

	ngOnInit(): void {
		let stateSettings: any = this.stateSettings;
		this.callStatus = this.myservice.getLastCallStatus();
    this.api_end_point = this.myservice.get_API_End_Point();
		let _inNumberOneDefinition = stateSettings.cloudtelephonyout.filter(v => { return v.name == "innumber1" });
		this._caller_id = _inNumberOneDefinition[0].definition;
		console.log(this._caller_id);

		if (stateSettings && stateSettings.extraoptions) {
			for (let opt of stateSettings.extraoptions) {
				if (opt.name == "c2cendpoint") {
					console.log(opt);
					this.c2cEndPoint = opt.definition;
				}
			}
		}
		this.subscriptionDial = this.myservice.getCallStatus().subscribe(obj => {
			console.log("home-call-history", obj);
			this.callStatus = obj;

		})
		this.getTabList();
		this.getDropdownList();
	}
	getTabList() {
		let obj = {
			user_id: this.user.id,
		} as any;
    //this.user.server_url
		this.API.getContactsWhtasapp(obj, this.api_end_point).subscribe((res: any) => {
			if (res.success) {
				let ELEMENT_DATA = res.success;
				//console.log(ELEMENT_DATA);
				this.dataSource = new MatTableDataSource(ELEMENT_DATA);
				this.dataSource.filterPredicate = function (data, filter: any): boolean {
					console.log(data.Name, data.Name.toString().toLowerCase().includes(filter))
					return data.Name.toString().toLowerCase().includes(filter)
				};
			}
		}, (err) => {
			console.log(err)
		}
		)
	}
	getDropdownList() {
		// let _contactOptions = [];

		if (this.stateSettings && this.stateSettings.extraoptions) {

			this._contactOptions = this.stateSettings.extraoptions.filter(a => { return a.name == "contacts_options" });
			this._wTemplate = JSON.parse(this._contactOptions[0].definition);

			this._ddlListExtraOption = this._wTemplate.whatsapp_template_data;

		}
	}
	changeWhatsappTemplate(v) {
		this._rowExtraOptionData = [];
		let _rowExtraOptionData1 = [];
		this._paraMessage = "";
		_rowExtraOptionData1 = this._ddlListExtraOption.filter(d => { return d.template_name == v });
		console.log(_rowExtraOptionData1[0]);
		this.templatepart1 = _rowExtraOptionData1[0].message;
		// //let message_firstpart = _rowExtraOptionData1[0].message;
		// let part1 = message_firstpart[0]   //_rowExtraOptionData1[0].substring( 0, message_firstpart );
		// let part2 = message_firstpart[1]  //_rowExtraOptionData1[0].substring( message_firstpart + 1 );

		// this.templatepart1 = part1;
		// this.templatepart2 = part2;
		if(_rowExtraOptionData1[0].media_url){
			this.ismedia=true;
			this.media = _rowExtraOptionData1[0].media_url;
		}
		this._paraMessage = _rowExtraOptionData1[0].message;

		this.selectedWhatsappTemplate = _rowExtraOptionData1[0];


		this._rowExtraOptionData = _rowExtraOptionData1[0].required_values;//_rowExtraOptionData.Next_Update_Time.type;

		this.dateSelect = '';
		//this.fromInput.value = '';
	}
	nameRowRecord(element) {
		this._nameUser = element.Name;
		this._psType = element.type;
		this.selectedContact = element;
		this.relationshipManagerModel = '';
		this.trackingLinkModel = '';
		this.phoneNoModel = '';
		this.Product_Name = '';
		this.Publisher_Name='';
		this.Reject_Key='';
		this.Accept_Key='';
		this.Product_Link='';
		this.Service_Station='';
		this.Vehicle_Number='';
		this.Service_Name='';
		this.Web_Link='';
		this.templateddlValue='';
		this.templatepart1='';
		this.templatepart2='';
		this.ismedia=false;
		this.media='';
		this._rowExtraOptionData = [];
		// this.dateSelect = new Date();
	}

	applyFilter(filterValue: any) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;

	}
	selectRow(row) {
		this.selectedRow = row;
	}
	CallChatPhoneClick(type: any) {
		if (this.selectedRow == undefined || this.selectedRow == null) {
			//  alert("please select agent user");
			this._snackBar.open("Please select agent user", 'close', {
				duration: 6000,
			});
			return;
		}
		this.selectedButton = "";
		this.showHideWhatsapp = false;
		this.showHidePhone = false;
		this.showHideText = false;
		this.selectedButton = type;



		if (type == "phone") {
			this.showHidePhone = true;
			this.callCustomer()
		}
		else if (type == "whatsapp") {
			this.showHideWhatsapp = true;
		}
		else if (type == "text") {
			this.showHideText = true;
		}


	}
	saveBtn() {
		debugger;
		let _date = moment(this.dateSelect).format('YYYY-MM-DD');
		let _templateName = this.templateddlValue;
		let _msg = this._paraMessage;
		let _uid = this.user.id;
		let _agentid = this.user.agentid;
		let _name = this.user.name;
		let _phone = this.user.phone;
		let _address = this.user.address;
		let _company = this.user.company;
		let _email = this.user.email;
		let _msgType = "TEXT";
		let _isHSM = true;
		let _type;
		let _modelObj: any = {};
		var checkEmpty = false;
		let a = this._rowExtraOptionData;
		console.log(a.length);
		if (a.Next_Update_Time) {

			_type = a.Next_Update_Time.type;
			if (_date) {
				checkEmpty = true;
			}
			_modelObj = {
				"Next_Update_Time": {
					"value": _date,
					"type": _type
				}
			}
		}
		if (a.Phone_Number) {
			_type = a.Phone_Number.type;
			if (this.phoneNoModel) {
				checkEmpty = true;
			}
			_modelObj = {
				"Phone_Number": {
					"value": this.phoneNoModel,
					"type": _type
				}
			}
		}
		if (a.Tracking_Link) {
			_type = a.Tracking_Link.type;
			if (this.trackingLinkModel) {
				checkEmpty = true;
			}
			_modelObj = {
				"Tracking_Link": {
					"value": this.trackingLinkModel,
					"type": _type
				}
			}
		}
		if (a.Web_Link) {
			let type1= a.Web_Link.type;
			if (this.Web_Link) {
				checkEmpty = true;
			}
			_modelObj = {
				"Tracking_Link": {
					"value": this.Web_Link,
					"type": type1
				}
			}
		}
		if (a.Accept_Key && a.Reject_Key && a.Publisher_Name) {
			let type1,type2,type3;
			//_type = a.Tracking_Link.type;
			type1= a.Accept_Key.type;
			type2= a.Reject_Key.type;
			type3= a.Publisher_Name.type;

			if (this.Accept_Key && this.Reject_Key && this.Publisher_Name) {
				checkEmpty = true;
			}
			_modelObj = {
				"Accept_Key": {
					"value": this.Accept_Key,
					"type": type1
				},
				"Reject_Key": {
					"value": this.Reject_Key,
					"type": type2
				},
				"Publisher_Name": {
					"value": this.Publisher_Name,
					"type": type3
				}
			}
		}
		if (a.Service_Station && a.Vehicle_Number && a.Service_Name) {
			let type1,type2,type3;
			//_type = a.Tracking_Link.type;
			type1= a.Service_Station.type;
			type2= a.Vehicle_Number.type;
			type3= a.Service_Name.type;

			if (this.Service_Station && this.Vehicle_Number && this.Service_Name) {
				checkEmpty = true;
			}

			_modelObj = {
				"Service_Station": {
					"value": this.Service_Station,
					"type": type1
				},
				"Vehicle_Number": {
					"value": this.Vehicle_Number,
					"type": type2
				},
				"Service_Name": {
					"value": this.Service_Name,
					"type": type3
				}
			}
		}else if(a.Vehicle_Number && a.Invoice_Number)
		{
			let type1 = a.Vehicle_Number.type;
			let type2 =a.Invoice_Number.type
			if (this.Vehicle_Number && this.Invoice_Number) {
				checkEmpty = true;
			}
			_modelObj = {
				"Vehicle_Number": {
					"value": this.Vehicle_Number,
					"type": type1
				},
				"Invoice_Number": {
					"value": this.Invoice_Number,
					"type": type2
				}
			}
		}
		if (a.Product_Name && a.Relationship_Manager) {
			let type1 = a.Product_Name.type;
			let type2 =a.Relationship_Manager.type
			if (this.Product_Name && this.relationshipManagerModel) {
				checkEmpty = true;
			}
			_modelObj = {
				"Product_Name": {
					"value": this.Product_Name,
					"type": type1
				},
				"Relationship_Manager": {
					"value": this.relationshipManagerModel,
					"type": type2
				}
			}
		}
		else if (a.Product_Name && a.Product_Link) {
			let type1 = a.Product_Name.type;
			let type2 = a.Product_Link.type;
			if (this.Product_Name && this.Product_Link) {
				checkEmpty = true;
			}
			_modelObj = {
				"Product_Name": {
					"value": this.Product_Name,
					"type": type1
				},
				"Product_Link": {
					"value": this.Product_Link,
					"type": type2
				}
			}
		}
		else if (a.Relationship_Manager) {
			let type1 = a.Relationship_Manager.type;
			if (this.relationshipManagerModel) {
				checkEmpty = true;
			}
			_modelObj = {
				"Relationship_Manager": {
					"value": this.relationshipManagerModel,
					"type": type1
				}
			}
		}

		let _obj = {
			"user_id": _uid,
			"customer_details": this.selectedContact,
			"template_data": this.selectedWhatsappTemplate,
		}
		console.log(this.selectedWhatsappTemplate)
		_obj['template_data']['required_values'] = _modelObj;

		if (checkEmpty == false && Object.keys(this.selectedWhatsappTemplate.required_values).length > 0) {
			this._snackBar.open("Fill required details", 'close', {
				duration: 5000,
			});
			return;
		}
			console.log(_obj);
		this.API.saveContactsWhatsapp(_obj, this.user.server_url).subscribe((res: any) => {
			this.relationshipManagerModel = '';
			this.trackingLinkModel = '';
			this.phoneNoModel = '';
			this.Product_Name = '';
			this.Publisher_Name='';
			this.Reject_Key='';
			this.Accept_Key='';
			this.Product_Link='';
			this.Service_Station='';
			this.Vehicle_Number='';
			this.Service_Name='';
			this.Web_Link='';
			if (res.status == "success") {
				this.showHideWhatsapp = false;
				this._snackBar.open(res.message, 'close', {
					duration: 5000,
				});
			}
		});

	}

	onlyNumberValidation(event) {
		return event.charCode >= 48 && event.charCode <= 57;
	}

	callCustomer() {
		const callSession = this.myservice.getLastCallSession();
		if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
			alert("Please Press Start for Calling");
			return;
		}
		if (callSession && callSession.internet_speed && callSession.internet_speed < 10) {
			alert("Internet speed slow try later");
			return;
		}
		if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
			alert("Sip connection not available try later");
			return;
		}
		console.log(this.selectedContact);
		//     var data = {
		//       "telco_partner": "TWILIO",
		//       "agent_number": this.user.phone,
		//       "customer_number": obj.number,
		//       "cli": "+15512617144"
		//   }
		//     this.API.gsmtogsmcall(data, this.user.server_url).subscribe(
		//       (res: any) => {
		//         console.log(res)
		//         if(res.status== "success"){
		//           alert("Dialing...")
		//         }

		//       }, (err) => {
		//         console.log(err)
		//       }
		//     )
		var updateObj = this.callStatus;
		if (updateObj.status == undefined) {

			updateObj.dialNum = this.selectedContact.Number;
			updateObj.dialing = 1;
			this.myservice.updateCallStatus(updateObj);
			updateObj = this.callStatus;
			updateObj.dialing = 2;
			this.myservice.updateCallStatus(updateObj);
		}




		// var data: any = {
		// 	"customer_number": this.selectedContact.Number,
		// 	"agent_number": this.user.phone,
		// 	"user_id": this.user.id,
		// 	"caller_id": this._caller_id,
		// 	"networktype": "WIFI",
		// 	"pingdelay": "45"
		// }
		// if (this.user.sip_id) {
		// 	data.sip_id = this.user.sip_id
		// }



		// this.API.sipOutboundCall(data, this.user.server_url, this.c2cEndPoint).subscribe((res: any) => {
		// 	debugger;
		// 	console.log(res)

		// }, (err) => {
		// 	console.log(err)
		// }
		// )

	}
}
