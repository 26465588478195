

      <!--start main dashboard-->
      <div *ngIf="businessType != 'WABA'">
        <!-- <mat-card class="CN-Card"> -->

            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12  mt-3">
                    <mat-card>
                        <p class="CN-Tab-head1">Project Activities
                            <!-- Overall  Project -->
                        </p>
                        <div [chart]="Columnchart"> </div>
                    </mat-card>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12  mt-3">
                    <mat-card>
                        <p class="CN-Tab-head1">Overall Cost<span style="color:#ababab">&nbsp;&nbsp;(All
                                Channels)</span> </p>
                        <div class="row" style="align-items: center;">
                            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div style="min-width: 200px; height: 200px; max-width: 200px;padding-top:8px;"
                                    [chart]="donutChartrecord"></div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-4">
                                <p class="CN-target">{{this.subscribed_channels["target_cost"]+this.subscribed_channels["currency"]}}<br> <span class="cn-sub-title"> Target</span> </p>
                                <hr>
                                <p class="CN-cost">{{this.current_cost+this.subscribed_channels["currency"]}}<br> <span class="cn-sub-title"> Cost </span> </p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12  mt-3">
                    <mat-card style="min-height: 267px;max-height:auto">
                        <p class="CN-Tab-head1">Total Cost By Channel</p>
                        <div>
                            <table style="width:100%;line-height: 35px;">
                                <tr style="border-bottom: 1.5px dashed #ccc;font-size: 0.84em;">
                                    <td><b>Channels </b></td>
                                    <td>&nbsp;</td>
                                    <td><b>Cost </b></td>
                                </tr>
                                <tr *ngFor="let v of _TotalCostByChannel" style="line-height: 21px;">
                                    <td style="font-family: Poppins;font-size: 0.84em;color: #606e36;padding: 21px 0 0 0px;"
                                        [ngStyle]="{'border-bottom':'5px solid'+ v.color}">{{v.Channel}} </td>
                                    <td style="padding: 21px 0 0 0px;">&nbsp;</td>
                                    <td style="padding: 21px 0 0 0px;position: relative;top: 8px;" [ngStyle]="{color:v.color}"> {{v.Cost}}</td>
                                </tr>
                            </table>

                        </div>
                    </mat-card>
                </div>
            </div>
<!--
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12  mt-3">
                    <mat-card>
                        <p class="CN-Tab-head1">Demo</p>
                        <div [chart]="columnStackChart"></div>
                    </mat-card>
                </div>
            </div>
-->
        <!-- </mat-card> -->
    </div>
    <!--end main dashboard-->
    <div id="cover-spin" *ngIf="currentLoader"></div>
