import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { ApiService } from 'src/app/api.service';
@Component({
  selector: 'app-onboarding-get-started',
  templateUrl: './onboarding-get-started.component.html',
  styleUrls: ['./onboarding-get-started.component.scss']
})
export class OnboardingGetStartedComponent implements OnInit {
  createdForm:FormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  constructor(private router:Router,public formbuilder:FormBuilder,public API :ApiService) { 
    window.scroll(0,0);
    this.createdForm = this.formbuilder.group({
      fname: ['', Validators.required],
      lname:[''],
      email: ['', [Validators.required, Validators.email]],
      companyName:[''],
      empWork:[''],
      countryCode:[''],
      phone:[''],
      location:[''],
      checkProceed:false
    })
  }

  ngOnInit(): void {
  }
  onSubmitBtn(createdForm: any){
   // debugger;
    this.messageDisplay="";
    this.showHideMessage = false;
    let _fname = createdForm.controls["fname"].value;
    let _lname = createdForm.controls["lname"].value;
    let _email = createdForm.controls["email"].value;
    let _companyName = createdForm.controls["companyName"].value;
    let _empWork = createdForm.controls["empWork"].value;
    let _phone = createdForm.controls["countryCode"].value+createdForm.controls["phone"].value;   
    let _location = createdForm.controls["location"].value;
    let _checkProceed=  createdForm.controls["checkProceed"].value;

    if (_fname == "") {
      this.messageDisplay = "Please enter first name*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (_email == "") {
      this.messageDisplay = "Please enter valid email*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    var regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (regEmail.test(_email) == false) {
      this.messageDisplay = "Please enter correct email*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return false;
    }
    if(_checkProceed==false){
      alert("Please check in checkbox");
      this.messageDisplay = "Please check the checkbox*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    let _obj = {
      "firstName": _fname,
      "lastName": _lname,
      "email": _email,
      "companyName": _companyName,
      "employeeWork": _empWork,
      "phone":_phone,
      "location": _location    
    }
    // this.API.saveOnboarding(_obj).subscribe((data: any) => {

    // });

    this.messageDisplay = "Congratulations, Saved Successfully.!!";
    this.msgColor = "green";
    this.showHideMessage = true;
    window.scroll({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(()=>{
      this.router.navigateByUrl('/onboarding_process_page1')
    },3000)


  }

  omit_special_char(event)
{   
   let k;  
   k = event.charCode;
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

onlyNumberValidation(event){
  return event.charCode >= 48 && event.charCode <= 57;
}
validateEmail(){
  //debugger;
  let emailField=this.createdForm.controls.email.value;
  let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  if (reg.test(emailField) == false) 
  {
      alert('Invalid Email Address');
      return false;
  }

  return true;

}
}
