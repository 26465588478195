<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span  style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
            <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()"/></span>
        </div>
</div>
<div class="mainDialogDiv">
    <div class="row">
        <div class="col-md-12">
            <h1 class="hOne">Detail View</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label class="Lbl">Agent ID</label>
            <span class="Lbl-val">{{_viewData.user_id}}</span>
           
        </div>
        <div class="col-md-4">
            <label class="Lbl">Agent Name</label>
            <span class="Lbl-val">{{_viewData.name}}</span>
            
           
        </div>
        <div class="col-md-4">
            <label class="Lbl">Agent Email</label>
            <span class="Lbl-val">{{_viewData.email}} </span>
           
        </div>
    </div>



    <div class="row linePad" style="display: none;">
        <div class="col-md-5 mt-2"><span class="recName1">{{selectedMonth}}</span>&nbsp;&nbsp; <span class="recName1">{{selectedCurYear}}</span></div>                                        
      
            <div class="col-md-7 mt-2 text-right">
                <mat-select [ngClass]="'arrowcsDDL'" class="dropdownDDL" placeholder="Select" [(value)]="selectedCurYear">
                    <mat-option value="2021">2021</mat-option>
                    <mat-option value="2020">2020</mat-option>
                    <mat-option value="2019">2019</mat-option>
                    <mat-option value="2018">2018</mat-option>
                    <mat-option value="2017">2017</mat-option>
                    <mat-option value="2016">2016</mat-option>
                    <mat-option value="2015">2015</mat-option>
                </mat-select>
         
                <mat-select [ngClass]="'arrowcsDDL'" class="dropdownDDL" placeholder="Select"  [(value)]="selectedMonth">
                    <mat-option value="Jan">Jan</mat-option>
                    <mat-option value="Feb">Feb</mat-option>
                    <mat-option value="Mar">Mar</mat-option>
                    <mat-option value="Apr">Apr</mat-option>
                    <mat-option value="May">May</mat-option>
                    <mat-option value="Jun">Jun</mat-option>
                    <mat-option value="Jul">Jul</mat-option>
                    <mat-option value="Aug">Aug</mat-option>
                    <mat-option value="Sep">Sep</mat-option>
                    <mat-option value="Oct">Oct</mat-option>
                    <mat-option value="Nov">Nov</mat-option>
                    <mat-option value="Dec">Dec</mat-option>
                </mat-select>
                <button class="lifetime-btn-ui"> Lifetime </button>
            </div> 
        </div>



    <div class="row" style="padding: 20px 0px;">
        <div class="col-md-4">
            <label class="Lbl">Actual Earnings</label>
            <span class="Lbl-val">{{currency_code| currencySymbol}}{{_viewData.actual_earnings}} </span>
           
        </div>
        <div class="col-md-4">
            <label class="Lbl">Total Deductions</label>
            <span class="Lbl-val">{{currency_code| currencySymbol}}{{_viewData.total_deductions}} </span>
           
        </div>
        <div class="col-md-4">
            <label class="Lbl">Opening Balance</label>
            <span class="Lbl-val">{{currency_code| currencySymbol}}{{_viewData.opening_balance}} </span>
           
        </div>
    </div>
    <div class="row"  style="padding:0 0 20px 0px;">
        <div class="col-md-4">
            <label class="Lbl">Total Earnings</label>
            <span class="Lbl-val">{{currency_code| currencySymbol}}{{_viewData.total_earnings}} </span>
           
        </div>
      
        <div class="col-md-4">
            <label class="Lbl">Closing Balance</label>
            <span class="Lbl-val">{{currency_code| currencySymbol}}{{_viewData.closing_balance}} </span>
           
        </div>
    </div>
	<div class="row" style="padding:0 0 20px 0px;border-bottom: 1.7px solid #ccc;">
		<div class="col-md-12">
			<button class="excel_export" (click)="exportExcel()">Export</button>
		</div>
	</div>
    <div>
        <mat-table class="matTableCs" [dataSource]="dataSource">
            <ng-container matColumnDef="date">
               <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
               <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
             </ng-container>
             <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef>  Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
             </ng-container>
                       
             <ng-container matColumnDef="actual_earnings">
                <mat-header-cell *matHeaderCellDef > Actual Earnings </mat-header-cell>
                <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}}{{element.actual_earnings}} </mat-cell>
             </ng-container>                               
             <ng-container matColumnDef="deductions">
                <mat-header-cell *matHeaderCellDef > Deductions </mat-header-cell>
                <mat-cell *matCellDef="let element" >{{currency_code| currencySymbol}}{{element.deductions}} </mat-cell>
             </ng-container>
             <ng-container matColumnDef="opening_balance">
                <mat-header-cell *matHeaderCellDef > Opening Balance </mat-header-cell>
                <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}}{{element.opening_balance}} </mat-cell>
             </ng-container>                               
             <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef > Amount </mat-header-cell>
                <mat-cell *matCellDef="let element" >{{currency_code| currencySymbol}}{{element.amount}} </mat-cell>
             </ng-container>
             <ng-container matColumnDef="closing_balance">
                <mat-header-cell *matHeaderCellDef > Closing Balance </mat-header-cell>
                <mat-cell *matCellDef="let element" >{{currency_code| currencySymbol}}{{element.closing_balance}} </mat-cell>
             </ng-container>
             <ng-container matColumnDef="pan_number">
                <mat-header-cell *matHeaderCellDef >PAN Number </mat-header-cell>
                <mat-cell *matCellDef="let element" > {{element.pan_number}} </mat-cell>
             </ng-container>
             <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef >Description </mat-header-cell>
				<mat-cell *matCellDef="let element"> 
					<span *ngIf="element.short_desc">
						{{ limit_string(element.description) }} 
						<span style="cursor: pointer;" (click)="element.short_desc = !element.short_desc">...</span>
					</span>
					<span *ngIf="!element.short_desc">
						{{element.description}}
					</span>
				</mat-cell>
             </ng-container>
            <mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>