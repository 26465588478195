<h3 class="sBiz-title">SmarterBiz</h3><br>
<div class="text-center">
    <p  class="hdtext"><span> On-Boarding Process</span></p>
    <p class="LText">Boost your operational efficiency with realtime<br>
        customer journey orchestration across channels.</p>
   
</div>

<div>
<mat-card class="m-card">
  <p *ngIf="showHideMessage" style="font-weight: bold;" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
  <form  [formGroup]="createdForm">
    <div class="row bgText">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            WORKFLOW
        </div>
    </div>
    <div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Workdays <span class="starMandatory">*</span> </span>
        <br>
        <mat-checkbox class="mcbx" formControlName="dayMonday">Monday</mat-checkbox>
        <mat-checkbox class="mcbx" formControlName="dayTuesday">Tuesday</mat-checkbox>
        <mat-checkbox class="mcbx" formControlName="dayWednesday">Wednesday</mat-checkbox>
        <mat-checkbox class="mcbx" formControlName="dayThursday">Thursday</mat-checkbox>
        <mat-checkbox class="mcbx" formControlName="dayFriday">Friday</mat-checkbox>
        <mat-checkbox class="mcbx" formControlName="daySaturday">Saturday</mat-checkbox>
        <mat-checkbox class="mcbx" formControlName="daySunday">Sunday</mat-checkbox>
    </div>
</div>

<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Work hours Start Time </span>
        <br> <br>
        <input type="time" id="appt" name="appt" class="timeInput" formControlName="startTime">
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Work hours End Time </span>
        <br> <br>
        <input type="time" id="appt" name="appt" class="timeInput" formControlName="endTime">
    </div>
</div>

<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">OTP based login feature in the web portal required </span>
        <br>
        <mat-radio-group class="rd-gp" formControlName="otpbased">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
            <mat-radio-button value="may be">May Be</mat-radio-button>
          </mat-radio-group>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Agent Slot Feature</span>
        <br>
        <span style="color:gray;font-size:11px">To schedule the working hours for agents</span><br>
        <mat-radio-group class="rd-gp" formControlName="agentSlot">
            <mat-radio-button value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
          </mat-radio-group>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Data upload Process (ONLY for outbound) </span>
        <br>
        <mat-checkbox  formControlName="apiBasedDataUploadProcess" class="mcbx">API Based</mat-checkbox>
        <mat-checkbox  formControlName="excelDataUploadProcess" class="mcbx">Excel Upload</mat-checkbox>
        <mat-checkbox  formControlName="otherDataUploadProcess" class="mcbx">Other</mat-checkbox>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Data upload fields</span>
        <br>
        <span style="color:gray;font-size:11px">Add required columns if required</span><br>
        <table class="tablecs" border="1">
            <thead>
              <tr>
                <th>Column Name</th>
                <th>Description</th>        
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CUSTOMER NAME (Optional)</td>
                <td>Name of the customer</td>
              </tr>
              <tr>
                <td style="background:red;">CUSTOMER NUMBER (Cumpulsory)</td>
                <td>Number of the customer</td>
              </tr>
              <tr>
                <td>CUSTOMER COMPANY NAME (Optional)</td>
                <td>Company Name of the customer</td>
              </tr>
                <tr>
                <td>NOTES (Optional)</td>
                <td>Notes</td>
              </tr>
                 <tr>
                <td>NEXT FOLLOW UP START DATE (Optional)</td>
                <td></td>
              </tr>
                <tr>
                <td>NEXT FOLLOW UP END DATE (Optional)</td>
                <td></td>
              </tr>
                  <tr>
                <td>NEXT FOLLOW UP START TIME (Optional)</td>
                <td>Follow Up Start Time of the next</td>
              </tr>
                <tr>
                <td>NEXT FOLLOW UP END TIME (Optional)</td>
                <td></td>
              </tr>
                <tr>
                <td>SUB STAGE 1 (Optional)</td>
                <td>Stage 1 of the sub</td>
              </tr>
                <tr>
                <td>SUB STAGE 2 (Optional)</td>
                <td>Stage 2 of the sub</td>
              </tr>
                 <tr>
                <td>Miscellaneous Data (Key Value Information) (Optional)</td>
                <td>
        The details to be shown to agents on call in a particular format</td>
              </tr>
         <tr>
                <td>Default Disposition (Optional)</td>
                <td></td>
              </tr>
               <tr>
                <td>Default Registered Agent Email Id to whom this customer should be assigned (Optional)</td>
                <td>Agents email id id needed</td>
              </tr>
              
            </tbody>
          </table>
          <br>
        <mat-form-field style="width: 100%;">
            <input matInput placeholder="Answer" formControlName="uoloadFileldAnswer"/>
            </mat-form-field>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Access to Data Upload</span>
        <br>
        <span style="color:gray;font-size:11px">Add required columns if required</span><br>
        <mat-radio-group class="rd-gp"  formControlName="accessDataUpload">
            <mat-radio-button value="allWhoHaveWebAccess">All who have web access</mat-radio-button>
            <mat-radio-button value="onlyTheHead">Only the Head</mat-radio-button>
            <mat-radio-button value="other">Other
                <mat-form-field class="otherWd">
                    <input matInput  formControlName="accessDataUploadOtherDesc"/>
                    </mat-form-field>
            </mat-radio-button>
          </mat-radio-group>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Data Drop duration (In Days)</span>
        <br>
        <span style="color:gray;font-size:11px">The duration of days to consider the data not to reuse if already exist in database</span><br>
        <mat-form-field style="width: 100%;">
            <input matInput placeholder="Your Answer" formControlName="dataDropAnswer"/>
            </mat-form-field>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">During call workflow (attach file)</span>       
        <br><br>
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" formControlName="addFileDuringCall" />
          </div>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Follow-up dialing</span>
        <br>
        <span style="color:gray;font-size:11px">To allow agent to do call to the the follow up</span><br><br>
        <mat-form-field appearance="outline" class="dropdn">
            <mat-select placeholder="Choose"  formControlName="followup">
              <mat-option>None</mat-option>
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Data Drop duration (In Days)</span>
        <br>
        <span style="color:gray;font-size:11px">To redial the last connected customer again</span><br><br>
        <mat-form-field appearance="outline" class="dropdn">
            <mat-select placeholder="Choose"  formControlName="dataDropDuration">
              <mat-option>None</mat-option>
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
</div>
<br><br>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
       <button class="onboard-submit"  (click)="onNextBtn(createdForm)">NEXT</button>
    </div>
</div>
<br><br>
</form>
</mat-card>
</div> 



