<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu>
	</mat-drawer>
	<br><br><br><br>
	<div class="v-mainDetailsDiv">
		<mat-card>
			<div class="row">
				<div class="col-md-6 mb-2 pt-1">
					<h4 class="titlehead vThdx1" style="position: relative;top: 0px;z-index: 9;">Campaign Details</h4>
				</div>
				<div class="col-md-6 v-lftRyt mb-2">
					<button mat-button (click)="backToCampaignDashboard()">BACK	</button>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-md-9 mb-2 pt-1">
					<div class="titlehead vThdx1" style="position: relative;top: 0px;z-index: 9;">
						<span>{{campaigndetails_data.campaign_name}}</span>
						<span class="toggle_btn" *ngIf="campaigndetails_status_assign">
							<mat-slide-toggle [(ngModel)]="campaign_status"
								(change)="campaignStatusChange($event,campaigndetails_data)"
								[disabled]="campaign_service.disable_campaign_full_access(campaigndetails_data)">
							</mat-slide-toggle>
						</span>
						<span class="prev_pred">
							{{campaigndetails_data.priority == 2 ? 'Predictive' : 'Preview'}}
						</span>
						<!-- <span *ngIf="campaigndetails_status_uploaded">
						<button class="btnLater" >Assign Now</button>
					</span> -->
						<!--; else elseBlock-->
					</div>
				</div>
				<div class="col-md-3 v-lftRyt mb-2">

					<!-- <button mat-button [matMenuTriggerFor]="menu" class="vk-dwn-report"> -->
					<button mat-button class="no-hover" [matMenuTriggerFor]="menu">
						<!-- <mat-icon class="mr-2">file_download</mat-icon> -->
						Download Report
						<mat-icon class="ml-2"
							style="font-size: 35px;position: relative;bottom: 5px;">arrow_drop_down</mat-icon></button>
					<mat-menu #menu="matMenu">
						<!-- <button mat-menu-item (click)="addmore(campaigndetails_data)">Add
							Data</button> -->
						<button mat-menu-item (click)="downloadCmail_report(campaigndetails_data)">Call Status
							Report</button>
						<!-- <button mat-menu-item (click)="downloadCmail_report(campaigndetails_data,true)">Customer Status
							Report</button> -->
						<button mat-menu-item (click)="downloadCampaignStatusReport(campaigndetails_data,true)"
							*ngIf="visibility_my_agents_report(campaigndetails_data)">Campaign Status
							Report (My Agents)</button>
						<button mat-menu-item
							[disabled]="campaign_service.disable_campaign_full_access(campaigndetails_data)"
							(click)="downloadCampaignStatusReport(campaigndetails_data)">Campaign
							Status
							Report</button>
						<!-- <button mat-menu-item [disabled]="campaign_service.disable_campaign_full_access(campaigndetails_data)">Data Sheet</button> -->
						<button mat-menu-item
							[disabled]="campaign_service.disable_campaign_full_access(campaigndetails_data)"
							(click)="downloadDataSheet(campaigndetails_data)">Data Sheet</button>
					</mat-menu>


				</div>
			</div>



			<div class="mt-2 divFlexCs1">
				<div>
					<span class="span1">Campaign Name</span>
					<span class="span2"
						matTooltip="{{campaigndetails_data.campaign_name }}">{{campaigndetails_data.campaign_name}}</span>

				</div>
				<div>
					<span class="span1">Owner</span>
					<span class="span2">{{campaigndetails_data.camapign_owner}}</span>

				</div>
				<div>
					<span class="span1">Status</span>
					<span class="span2">{{campaigndetails_data.status}}</span>

				</div>
				<div>
					<span class="span1">Last Refreshed</span>
					<span class="span2">{{campaigndetails_data.last_updated }}</span>

				</div>
				<div>
					<span class="span1">Campaign Created On</span>
					<span class="span2">
						{{campaigndetails_data.created_at | date :'short'}}
						<!-- {{convertDTime(campaigndetails_data.created_at)}} -->
					</span>

				</div>
				<div>
					<span class="span1">Uploaded</span>
					<span class="span2">{{bindWithDefaultValue(campaigndetails_data.data_uploaded, 0)}}</span>

				</div>
				<div>
					<span class="span1">Duplicate</span>
					<span class="span2">{{bindWithDefaultValue(campaigndetails_data.data_duplicate, 0)}}</span>

				</div>
				<div>
					<span class="span1">Errors</span>
					<span class="span2">{{bindWithDefaultValue(campaigndetails_data.errors, 0)}}</span>

				</div>
				<div>
					<span class="span1">Assigned</span>
					<span class="span2">{{bindWithDefaultValue(campaigndetails_data.data_added, 0)}}</span>

				</div>
				<div>
					<span class="span1">Dialled</span>
					<span class="span2">{{bindWithDefaultValue(campaigndetails_data.data_dialled, 0)}}</span>

				</div>
				<div>
					<span class="span1">Contacted</span>
					<span class="span2">{{bindWithDefaultValue(campaigndetails_data.contacted, 0)}}</span>

				</div>
				<div>
					<span class="span1">Rechurn</span>
					<span class="span2">{{bindWithDefaultValue(campaigndetails_data.rechurn, 0)}}</span>

				</div>
				<div>
					<span class="span1">Type</span>
					<span class="span2">{{campaigndetails_data.data_source}}</span>

				</div>
				<div>
					<span class="span1">WCP Dropped</span>
					<span class="span2">{{bindWithDefaultValue(campaign_data_details['Total WCP Dropped'], '0')}}</span>

				</div>
				<div>
					<span class="span1">Total DND</span>
					<span class="span2">{{bindWithDefaultValue(campaign_data_details['Total DND'], '0')}}</span>

				</div>
				<div>
					<span class="span1">Form Type</span>
					<span class="span2">{{bindWithDefaultValue(campaigndetails_data.incallscreen, '')}}</span>

				</div>
			</div>

			<div class="mt-4">
				<hr style="border:none;border-top: 3px solid #ebf2f7;">
			</div>

			<div class="row">
				<div class="col-md-3 mb-2 pt-1">
					<h4 class="titlehead vThdx1" style="position: relative;top: 5px;z-index: 9;">Campaign Agents List
					</h4>
				</div>
				<div class="col-md-9 v-lftRyt mb-2">
					<div style="display: inline-flex;flex-wrap: wrap;">
						<div>
							<mat-form-field appearance="outline">
								<input matInput placeholder="Search" autocomplete="off" [ngClass]="'vSerchField'"
									(keyup)="applySearchFilter($event.target.value)">
								<mat-icon matSuffix
									style="font-size: 24px;position: relative;top: 6px;color: #c0cbd6;">search</mat-icon>
							</mat-form-field>
						</div>
						<div style="width:185px">
							<button class="vbtnxk action_btn" (click)="dataTransferClick()"
								(change)="getdatatransfer(campaigndetails_data)" [disabled]="data_transfer_status()">
								<!-- <mat-icon class="mr-1" style="vertical-align: middle;">swap_horiz</mat-icon>  -->
								Data Transfer</button>
						</div>
						<div style="width:185px">
							<!--[disabled]="campaigndetails_data.master_campaign == null ? true: false"-->
							<button class="vbtnxk action_btn" (click)="addAgentClick()" [disabled]="add_agent_status()"> 
								<!-- <mat-icon class="mr-1" style="vertical-align: middle;">add</mat-icon>  -->
								Add Agent </button>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12 mb-2 mt-3">
					<mat-table [dataSource]="dataSource" class="vkTableAgent">
						<ng-container matColumnDef="agentID">
							<mat-header-cell *matHeaderCellDef> Agent ID </mat-header-cell>
							<mat-cell *matCellDef="let element" style="color: #7094ba !important;font-weight: 500;">
								{{element.id}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="agentName">
							<mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Agent Name </mat-header-cell>
							<mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.name}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="email">
							<mat-header-cell *matHeaderCellDef style="flex:0 0 165px;"> Email </mat-header-cell>
							<mat-cell *matCellDef="let element" style="flex:0 0 165px;"> {{element.email}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="currentCampaign">
							<mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Current Campaign
							</mat-header-cell>
							<mat-cell *matCellDef="let element" style="flex:0 0 150px;"
								matTooltip="{{element.active_campaign}}">
								{{curCampainViewData(element.active_campaign,30)}}
							</mat-cell>
						</ng-container>
						<!-- <ng-container matColumnDef="status">
							<mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
							<mat-cell *matCellDef="let element">
								<span style="font-weight: 500;"
									[ngStyle]="{color: element.active_status==true?'#3db10e':'#dd133c'}">
									{{element.active_status? "Active" : "Deactive"}} </span>
							</mat-cell>
						</ng-container> -->
						<ng-container matColumnDef="dataValue">
							<mat-header-cell *matHeaderCellDef> Data </mat-header-cell>
							<mat-cell *matCellDef="let element"> {{element.data}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="dialled">
							<mat-header-cell *matHeaderCellDef> Dialled </mat-header-cell>
							<mat-cell *matCellDef="let element"> {{element.dialled}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="contacted">
							<mat-header-cell *matHeaderCellDef> Contacted </mat-header-cell>
							<mat-cell *matCellDef="let element"> {{element.contacted}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="followups">
							<mat-header-cell *matHeaderCellDef> Followups </mat-header-cell>
							<mat-cell *matCellDef="let element"> {{element.followup}} </mat-cell>
						</ng-container>
						<ng-container matColumnDef="activeDeactive">
							<mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Active | Deactive
							</mat-header-cell>
							<mat-cell *matCellDef="let element" style="flex:0 0 150px;">
								<mat-slide-toggle [(ngModel)]="element.active_status"
									(change)="setTogeleValue(element , $event )"
									[disabled]="agent_switch_status()">
								</mat-slide-toggle>

								<!-- <mat-slide-toggle class="slideONOffClsForTableView"
									[checked]="element.active_status === false ? false: true"
									[disabled]='agent_switch_status()' (change)="setTogeleValue(element , $event )">
								</mat-slide-toggle><span class="ml-3">{{element.active_status? "ON" : "OFF"}} </span> -->
							</mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef=displayedColumns
							style="border-bottom-color: #fff;"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
					</mat-table>
					<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>


				</div>
			</div>

			<div class="mt-4">
				<hr style="border:none;border-top: 3px solid #ebf2f7;">
			</div>

			<div class="row" *ngIf="check_log_visibility()">
				<mat-accordion style="flex: auto;">
					<mat-expansion-panel (opened)="campaign_logs.logspanelOpenState = true"
						(closed)="campaign_logs.logspanelOpenState = false">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Campaign Logs &nbsp;
								<i class="fas fa-sync-alt fa-spin" *ngIf="campaign_logs.refresh_status"></i>
							</mat-panel-title>
							<!-- <mat-panel-description>								
								{{logspanelOpenState ? 'Close' : 'View'}} logs
							</mat-panel-description> -->
						</mat-expansion-panel-header>
						<button class="vbtnxk logs_refresh_btn" (click)="refresh_campaign_logs()"
							[disabled]="campaign_logs.refresh_status">Refresh</button>
						<table mat-table [dataSource]="campaign_logs.data" class="mat-elevation-z8">

							<!--- Note that these columns can be defined in any order.
								  The actual rendered columns are set as a property on the row definition" -->

							<!-- Name Column -->
							<ng-container matColumnDef="name">
								<th mat-header-cell *matHeaderCellDef> Name </th>
								<td mat-cell *matCellDef="let element"> {{element.name}} </td>
							</ng-container>
							<!-- Email Column -->
							<ng-container matColumnDef="email">
								<th mat-header-cell *matHeaderCellDef> Email </th>
								<td mat-cell *matCellDef="let element"> {{element.email}} </td>
							</ng-container>

							<!-- Activity Column -->
							<ng-container matColumnDef="activity_type">
								<th mat-header-cell *matHeaderCellDef> Activity </th>
								<td mat-cell *matCellDef="let element"> {{element.activity_type}} </td>
							</ng-container>

							<!-- Timestamp Column -->
							<ng-container matColumnDef="created_at">
								<th mat-header-cell *matHeaderCellDef> Date & Time </th>
								<td mat-cell *matCellDef="let element"> {{getLocalTime(element)}} </td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumnsLogs"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumnsLogs;"></tr>
						</table>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</mat-card>

	</div>
</mat-drawer-container>