import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MyserviceService} from '../../myservice.service';
import { ApiService } from '../../api.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ViewDetailsDialog1Component } from '../view-details-dialog1/view-details-dialog1.component';
import { DatePipe } from '@angular/common';
import * as _ from 'underscore';
import { FormGroup, FormControl } from '@angular/forms';
import { Routes, RouterModule, Router,ActivatedRoute, Params  } from '@angular/router';

@Component({
  selector: 'app-contiinex-details-view',
  templateUrl: './contiinex-details-view.component.html',
  styleUrls: ['./contiinex-details-view.component.scss']
})
export class ContiinexDetailsViewComponent implements OnInit {

  customer_name:string;
  project_name:string = "";
  projects:any;
  selected:any;
  selectedChannel:any;
  selectedDayWise = "Today";
  utils = new Utils();
  selectedCampaign = "All Campaign";
  selectedCampaignStatus = "Campaign Status";
  selectedProcess = "processFlow1";
  //displayedColumns = ['name', 'phone', 'prospectsReached', 'prospectsConnected', 'prospectsInterested', 'eApplyVisited', 'eApplyCompleted', 'eligible', 'CardApproved'];
  displayedColumns = ['Name', 'Phone'];
  selected_time_range:string;
  dataSource:any = new MatTableDataSource();
  customerTableData: any = [];
  funnelData: any = [];
  showHideDateRange: boolean = false;
  startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
  endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date())
  });
  _rangeDateStartEnd: any;
  //@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild('paginatorA', {static: false}) paginatorA: MatPaginator;
  currentLoader:boolean=false;

  api_end_point:any;
currentTitle = 'details-view';
currentUrl:any;
  constructor(private _service:MyserviceService, private API: ApiService,private dialog: MatDialog,private router: Router) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    this.selected="";
    this.selectedChannel="";
    this.selected_time_range = this._service.get_current_time_range();
    this.selectedDayWise = this._service.get_current_time_range();
    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.projects = this._service.get_loggedin_user().project;
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = String(this._service.get_current_project()).toLowerCase();
      this.project_name = this.project_name == 'undefined' ? '' : this.project_name;

    }
    this.getDetailedFunnelView();
  }

  calculatePercBy(num1, num2) {
    if (num1 == 0 && num2 == 0) {
      return 0;
    }
    var value: any = (num1 / num2) * 100;
    value = value.toFixed(1);
    if (value) {
      value = parseFloat(value)
    }
    return value
  }

  getDetailedFunnelView(){
    this.currentLoader=true;
    this.selected_time_range = this._service.get_current_time_range();
    let startDateFilter;
     let endDateFilter;
     if(this.selected_time_range=="Custom"){
        startDateFilter= this.startDateFilter;
      endDateFilter= this.endDateFilter;
}
    this.API.getFunnelDetailView(this.customer_name, this.project_name, this.selected_time_range,startDateFilter,endDateFilter,this.api_end_point).subscribe((data: any) => {
      //debugger;
      this.currentLoader=false;
      const funnelDispositionsData = data.disposition_summary;
      if (funnelDispositionsData && funnelDispositionsData.length) {
          this.funnelData = data.disposition_summary;
         // console.log('funnelDispositionsData',this.funnelData);
      }

      const funnelDispositions = data.disposition_header;
      if (funnelDispositions && funnelDispositions.length) {
        this.displayedColumns = ['Name', 'Phone'];
        for (var i in funnelDispositions) {
          this.displayedColumns.push(funnelDispositions[i].split('|')[2]);
        }
        //console.log('Details display columns',this.displayedColumns);
      }

      const funnelDataDetail = data.success;
      if(funnelDataDetail){
        var tableData = []
        this.customerTableData = []
        const groupedData = _.groupBy(funnelDataDetail, 'contact_phone');
        const displayedColumns:any = this.displayedColumns
        Object.keys(groupedData).map(function (key, index) {
          var customer:any = {};
          var customerData = groupedData[key]
          customer['Name'] = customerData[0].contact_name || '';
          customer['Phone'] = customerData[0].contact_phone;
          customerData.map((obj,index)=> {
            displayedColumns.map((mapObj)=>{
              if (mapObj != 'Name' && mapObj != 'Phone'){
                if (mapObj == obj.category.split('|')[2]) {
                  customer[obj.category.split('|')[2]] = obj.created_at;
                  return;
                } else if (!customer[mapObj]) {
                  customer[mapObj] = ''
                }
              }
            })
          });
          tableData.push(customer)
        });

        //console.log('Funnel Detailed table data',tableData);
        let elementData = tableData;
        this.customerTableData = tableData;      
		  setTimeout(()=>{
			this.dataSource = new MatTableDataSource(elementData);
			this.dataSource.paginator = this.paginatorA;
		},100)
      
      }
    })
    // let _response=[{name:'Ram',phone:'7523452352',prospectsReached:'11:00 AM',prospectsConnected:'11:30AM | 30 min',prospectsInterested:'11:40AM',eApplyVisited:'11:40AM',eApplyCompleted:'xxx',eligible:'xxx',CardApproved:'xxx'}]

  }
  getCurrentDate(){
    let myDate = new Date();
  let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
  let date = myDate.getDate();
  let month = monthsList[myDate.getMonth()];
  let year = myDate.getFullYear();
  let day = daysList[myDate.getDay()];
  let today = `${date} ${month} ${year}, ${day}`;
  return today;
  }

  OnChangeDayTime(val: any) {
    this.showHideDateRange = false;
    this.selectedDayWise = val;
    this._service.set_current_time_range(this.selectedDayWise);
    if (val == "Custom") {
      this.showHideDateRange = true;
    } else {
      this.getDetailedFunnelView();
    }

  }

  valueChanged() {
    //debugger;
    //console.log(this.range.value);
    this._rangeDateStartEnd = this.range.value;
    //console.log(this._rangeDateStartEnd);
    if (this.range.value.start) {
      this.startDateFilter = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, this.range.value.start);
    }
    if (this.range.value.end != null) {
      this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, this.range.value.end);
      this.getDetailedFunnelView();
    }

  }
  refreshBtn(){
    this.getDetailedFunnelView();
  }
}
