
<app-new-contiinex-menu-page></app-new-contiinex-menu-page>
<div class="v-mainDivCO" [ngClass]="_path == '/Contact' ? '' : 'my_class2Top'">

    <div class="row">

<div class="col-md-12 col-sm-12">
    <div class="contactBg" style="background-image: url('../assets/contiinex/image/contactBGimg.png');">
        <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
            <div class="col-md-5 col-sm-12 col-xs-12">
    <h3 class="con-title1">Contact Us</h3>
    <p class="con-title2">Let Us Know How We Can Help</p>
    
    <p class="msgerrorcs" [ngStyle]="{'color':msgColor}" *ngIf="showHideMsg">{{showHideErrorMsg}}</p>
        <form [formGroup]="createdForm">
    <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
            <mat-form-field class="mField myfield">
                <mat-label>First Name</mat-label>
                <input matInput autocomplete="off"  required  formControlName="firstName" maxlength="25" (keypress)="omit_special_char($event)">
              </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
            <mat-form-field  class="mField myfield">
                <mat-label>Last Name</mat-label>
                <input matInput  autocomplete="off"  formControlName="lastName" maxlength="25" (keypress)="omit_special_char($event)">
              </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
            <mat-form-field class="mField myfield">
                <mat-label>Your Email Address</mat-label>
                <input matInput  autocomplete="off"  required  formControlName="email" maxlength="60" (change)="validateEmail();">
              </mat-form-field>
    
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
            <mat-form-field class="mField myfield">
                <mat-label>Select the type of your enquiry</mat-label>
                <mat-select  formControlName="relatedProblem" required>
                    <mat-option value="Sales Inquiry">Sales Inquiry</mat-option>
                    <mat-option value="General Inquiry">General Inquiry</mat-option>
                      <mat-option value="Apply for Job">Apply for Job</mat-option>
                   
                </mat-select>
              </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <mat-form-field class="mField myfield">
                <mat-label>Type Your Message</mat-label>
                <textarea  autocomplete="off"  matInput placeholder="" style="resize: none;" maxlength="300"  formControlName="message" required></textarea>
              </mat-form-field>
    
        </div>
       </div>
       <div class="row">
        <div class="col-md-12 text-center">
            <p><button class="V-Btn11" (click)="submitBtn(createdForm)"  [ngClass]="!createdForm.valid ? 'cls1' : 'V-Btn'"  [disabled]="!createdForm.valid"  title="Submit Form">Submit</button></p>
        </div>
    </div>
        </form>
            </div>
    
            <div class="col-md-5 col-sm-12 col-xs-12">
                <p class="text-center"> <img src="../assets/contiinex/image/conPageImage.png" class="conPageImagecs"/>
                    <img src="../assets/contiinex/image/contactTextVertical.png" class="conPageImagecs2"/>
    
                </p>
    
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
            </div>
    </div>
    <p class="text-right mb-0" style="height: 12px; display: none;">   <span><img src="../assets/contiinex/image/msgBox1.png" class="msgIconText1" /></span></p>
    
        <div class="txtMedia">
            <!-- <a href="https://www.facebook.com/Contiinex/" class="fa fa-facebook fa-v v-fcbk"></a>
            <a href="https://www.linkedin.com/company/contiinex-com/" class="fa fa-linkedin fa-v v-linkedin"></a>
            <a href="https://twitter.com/contiinex" class="fa fa-twitter fa-v v-twtr"></a> -->

            <a  href="https://www.facebook.com/Contiinex/" target="_blank">
                <img src="../assets/contiinex/image/fcbkFooterIcon.png" style="width: 40px;margin: 15px;"/>
                </a>
                <a href="https://www.linkedin.com/company/contiinex-com/" target="_blank">
                    <img src="../assets/contiinex/image/linkedinFooterIcon.png" style="width: 40px;margin: 15px;"/>
                </a>
                <a href="https://twitter.com/contiinex"  target="_blank">
                    <img src="../assets/contiinex/image/twitterFooterIcon.png" style="width: 40px;margin: 15px;"/>
                </a>


          </div>
    <p class="txtMedia mt-4 mb-1 cText1">Address  </p>
    <p class="txtMedia cText2 mb-1">SmarterBiz Technologies Pvt Ltd, IndiQube Orion, 24th Main Rd, Agara Village, 1st Sector, HSR Layout, Bengaluru, Karnataka 560068</p>
    <!-- <p class="txtMedia cText3"> View In Map</p> -->
    
</div>






    <div class="col-md-12 col-sm-12 col-xs-12 mt-2">
        <app-new-contiinex-footer-section-page></app-new-contiinex-footer-section-page>
    </div>

</div>

</div>