import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ApiService } from 'src/app/api.service';
import * as highcharts from 'highcharts';
import * as Highcharts from 'highcharts';
import {MyserviceService} from "../../myservice.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-one',
  templateUrl: './project-one.component.html',
  styleUrls: ['./project-one.component.scss']
})
export class ProjectOneComponent implements OnInit {
  //sentDeliveredChart:any;
  //donutChartrecord:any;
  ChannelNameList:any=[];

  //wa_total_today:number;
  customer_name:string;
  project_name:string;
  business_type:string;
  //wa_total_yesterday:number;
  default_sent_axis:any=[];
  default_delivered_axis:any=[];
  category_xaxis:any[];
  selectedIndex: number = 0;
  //wa_today:number[] = [0,0,0,0];
  //wa_yesterday:number[] = [0,0,0,0];
  showHideAllChannels:boolean=false;
  showHideWhatsapp:boolean=false;
  showHideOutboundIVR:boolean=false;
  showHideOutbound:boolean=false;
  showHideInbound:boolean=false;

  showHideInboundIVR:boolean=false;
  _selectedTab:any="All Channels";
  showHideSMS:boolean=false;
  currentLoader:boolean=false;
  constructor(private API:  ApiService, private _service:MyserviceService,private _snackBar: MatSnackBar) {
    window.scrollTo(0,0);
   }

  ngOnInit() {

    this.default_sent_axis = [];
    this.default_delivered_axis = [];

    //this.ChannelNameList=[{name:'All Channels'},{name:'WhatsApp'},{name:'Outbound IVR'},{name:'Outbound'},{name:'Inbound'},{name:'Inbound IVR'},{name:'SMS'}];

    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = String(this._service.get_current_project()).toLowerCase();
      this.business_type =  this._service.get_loggedin_user().businessType;

      //console.log('ProjectOneComponent - ngOnInit called',this.customer_name,this.project_name);

      if (this.business_type && (this.business_type == 'WABA')) {
        this.showHideAllChannels=false;
        this.showHideWhatsapp=true;
        this.ChannelNameList=[{name:'WhatsApp'}];
      } else {

        this.showHideAllChannels=true;

        this.ChannelNameList=[{name:'All Channels'}];

        if (this.project_name && (this.project_name.indexOf('inivr')>=0)) {
          this.ChannelNameList.push({name:'Inbound IVR'});
        } else if (this.project_name && (this.project_name.indexOf('ivr')>=0)) {
          this.ChannelNameList.push({name:'Outbound IVR'});
        }

        if (this.project_name && (this.project_name.indexOf('wa')>=0)) {
          this.ChannelNameList.push({name:'WhatsApp'});
        }

        if (this.project_name && (this.project_name.indexOf('sms')>=0)) {
          this.ChannelNameList.push({name:'SMS'});
        }

        if (this.project_name && (this.project_name.indexOf('out')>=0)) {
          this.ChannelNameList.push({name:'Outbound'});
        }

        if (this.project_name && (this.project_name.indexOf('in_')>=0)) {
          this.ChannelNameList.push({name:'Inbound'});
        }

      }

    }

  }

  setIndex(index: number,v:any) {
   // console.log('setIndex', v.name);
     this.selectedIndex = index;
     this._selectedTab=v.name;
     this.showHideAllChannels=false;
     this.showHideWhatsapp=false;
     this.showHideOutboundIVR=false;
     this.showHideOutbound=false;
     this. showHideInbound=false;
     this.showHideInboundIVR=false;
     this.showHideSMS=false;
     if(v.name=='All Channels'){
      this.showHideAllChannels=true;
    }
    else if(v.name=='WhatsApp'){
       this.showHideWhatsapp=true;
     }
     else if(v.name=="Outbound IVR"){
      this.showHideOutboundIVR=true;
     }
     else if(v.name=="Outbound"){
      this.showHideOutbound=true;
     }
     else if(v.name=="Inbound"){
      this. showHideInbound=true;
     }
     else if(v.name=="Inbound IVR"){
      this.showHideInboundIVR=true;
     }
     else if(v.name=="SMS"){
      this.showHideSMS=true;
     }
     else{
      this.showHideAllChannels=false;
      this.showHideWhatsapp=false;
      this.showHideOutboundIVR=false;
      this.showHideOutbound=false;
      this. showHideInbound=false;
      this.showHideInboundIVR=false;
      this.showHideSMS=false;
     }
  }
  refreshBtn(){
   // console.log('refreshBtn', this._selectedTab);
    // if(this._service.current_time_range =="NotClickVerify"){
    //   this._snackBar.open("Sorry,Please first click Set button", 'close', {
    //     duration: 4000,
    //  });
    //  setTimeout(()=>{
    //   this.currentLoader=false;
    // },10)
    //   return false;
    // }
      if(this._service.current_time_range =="CustomDate"){
      this._snackBar.open("Please Select Custom Date Range", 'close', {
        duration: 4000,
     });
     setTimeout(()=>{
      this.currentLoader=false;
    },10)
      return false;
    }
    this.showHideAllChannels=false;
    this.showHideWhatsapp=false;
    this.showHideOutboundIVR=false;

    this.showHideOutbound=false;
    this. showHideInbound=false;
    this.showHideInboundIVR=false;
    this.showHideSMS=false;

    this.currentLoader=true;
    //debugger;
    //start
 
    if((this._service.current_time_range=="This Month") && this.business_type && (this.business_type == 'WABA')){
      this._selectedTab="WhatsApp";
        setTimeout(()=>{
        this.showHideWhatsapp=true;
      },100)
    }
    if((this._service.current_time_range=="Today") && this.business_type && (this.business_type == 'WABA')){
      this._selectedTab="WhatsApp";
        setTimeout(()=>{
        this.showHideWhatsapp=true;
      },100)
    }
    if((this._service.current_time_range=="This Year") && this.business_type && (this.business_type == 'WABA')){
      this._selectedTab="WhatsApp";
        setTimeout(()=>{
        this.showHideWhatsapp=true;
      },100)
    }
  
    let _splitCustom=this._service.current_time_range;
    let splitCustom=_splitCustom.split(" "); 
    if((splitCustom[0]== "CustomDate") && this.business_type && (this.business_type == 'WABA')){
      this._selectedTab="WhatsApp";
        setTimeout(()=>{
        this.showHideWhatsapp=true;
      },100)
    }
    //end

    if(this._selectedTab=="All Channels")
    {
      setTimeout(()=>{
        this.showHideAllChannels=true;
      },100)

    }
    if(this._selectedTab=="WhatsApp")
    {
      setTimeout(()=>{
        this.showHideWhatsapp=true;
      },100)

    }
    if(this._selectedTab=="Outbound IVR")
    {
      setTimeout(()=>{
        this.showHideOutboundIVR=true;
      },100)

    }
    if(this._selectedTab=="Outbound")
    {
      setTimeout(()=>{
        this.showHideOutbound=true;
      },100)

    }
    if(this._selectedTab=="Inbound")
    {
      setTimeout(()=>{
        this.showHideInbound=true;
      },100)

    }
    if(this._selectedTab=="Inbound IVR")
    {
      setTimeout(()=>{
        this.showHideInboundIVR=true;
      },100)

    }
    if(this._selectedTab=="SMS")
    {
      setTimeout(()=>{
        this.showHideSMS=true;
      },100)

    }

    // if((this._service.current_time_range=="This Month" ||"Today" ||"This Year") && this.business_type && (this.business_type == 'WABA')){
    //   this._selectedTab=="WhatsApp";
    //     setTimeout(()=>{
    //     this.showHideWhatsapp=true;
    //   },100)
    // }

    setTimeout(()=>{
      this.currentLoader=false;
    },2000)

  }
// getSentVsDelivered(){

//     this.sentDeliveredChart = new Chart({
//       chart: {
//         type: 'line',
//         height: 300
//       },
//       credits: {
//         enabled: false
//       },
//       title: {
//         text: ''
//       },
//       xAxis: {
//         categories: this.category_xaxis,
//         gridLineWidth: 1,
//        minorGridLineWidth: 1
//       },
//       yAxis: {
//         title:null,
//         gridLineWidth: 1,
//         minorGridLineWidth: 1,

//       },
//       legend: {
//         reversed: false,
//         verticalAlign: "top",
//         align: "right",
//         borderWidth: 1,
//       },
//       plotOptions: {
//         line: {
//           allowPointSelect: true,
//           cursor: 'pointer',
//           dataLabels: {
//             enabled: false
//           },
//           showInLegend: true
//         },

//       },
//       series: [

//         {
//           type:undefined,
//           name: "Sent",
//           data: this.default_sent_axis,
//           color: "#f1a627"
//         },
//         {
//           type:undefined,
//           name: "Delivered",
//           data: this.default_delivered_axis,
//           color: "#ed3a31"
//         }
//       ]
//     });


// }
// donutChart(){
//   let chartdata = new Chart({

//       title: {
//         text: '<strong>'+this.wa_today[0]+'</strong><br>Sent',
//         align: 'center',
//         verticalAlign: 'middle',
//         y: 18,

//       },

//     credits: {
//       enabled: false
//     },
//     plotOptions: {
//       pie: {

//         cursor: 'pointer',
//         dataLabels: {
//           distance:-30,
//           enabled: false
//         },
//         showInLegend: false,

//       }
//     },

//     series: [
//       {


//         states: {
//         inactive: {
//           opacity: 1
//         },
//         hover: {
//         enabled: false
//         }
//         },
//         name: 'Active',
//         data: [
//           {
//             borderColor:'gray',
//             name: 'Yesterday',
//             y: this.wa_total_yesterday,
//             color:'white'//'#e6e6e6'
//           },
//           {
//             borderColor:'black',
//             name: 'Today',
//             y: this.wa_total_today,
//             color:'#eeeeee'//'black'
//           },],
//         type: 'pie',
//         innerSize: '80%',
//       }]
//   });
//   this.donutChartrecord=chartdata;


// }
}
