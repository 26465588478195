import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { Observable, BehaviorSubject, Subject, Subscription } from "rxjs";
import {  IStreamState } from '../../interface/stream-state.interface';
import { IAudioDialogData } from '../../interface/audio-dialog-data.interface';
import { AudioserviceService } from '../../audioservice.service';

export interface StreamState {
	playing: boolean;
	readableCurrentTime: string;
	readableDuration: string;
	duration: number | undefined;
	currentTime: number | undefined;
	canplay: boolean;
	error: boolean;
	volume: number;
	isMute: boolean;
  }

@Component({
  selector: 'app-audio-dialog',
  templateUrl: './audio-dialog.component.html',
  styleUrls: ['./audio-dialog.component.scss']
})
export class AudioDialogComponent implements OnInit {
	private _subscription = new Subscription();
	state: IStreamState;

	constructor(
	  @Inject(MAT_DIALOG_DATA) public data: IAudioDialogData,
	  private _dialogRef: MatDialogRef<AudioDialogComponent>,
	  private _audioService: AudioserviceService
	) {}
  
	ngOnInit(): void {
		this._subscribeAudioState();
		//console.log(this.data.url);
		this._subscribePlayStream(this.data.url);
		this._subscribeAudioState();
	}
  
	ngOnDestroy(): void {
	  this._subscription.unsubscribe();
	}
  
	onSliderChangeEnd(change: MatSliderChange): void {
	  this._audioService.seekTo(change.value);
	}
  
	play(): void {
		// this._subscribePlayStream(this.data.url);
		// this._subscribeAudioState();

	  this._audioService.play();
	}
  
	pause(): void {
	  this._audioService.pause();
	}
  
	close(): void {
	  this._audioService.stop();
	  this._dialogRef.close({ foo: '123' });
	}
  
	private _subscribePlayStream(url: string): void {
	  const subscription = this._audioService.playStream(url).subscribe();
  
	  this._subscription.add(subscription);
	}
  
	private _subscribeAudioState(): void {
	  const subscription = this._audioService.getState().subscribe((state) => {
		this.state = state;
	  });
  
	  this._subscription.add(subscription);
	}

  }