<form [formGroup]="docUploadForm">
	<table class="tbl">
		<tr style="border-bottom: 1px solid #ccc;">
			<td>
				<h5 class="hd5">Upload {{data.selected_doc_type}} Document</h5>
				
			</td>
			<td style="text-align: right;"> 
				<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
			</td>
	   </tr>
	   <tr *ngIf="showHideUploadMsg" [style.color]="_color" ><td colspan="2">
         <!-- <p style="font-size:1.3em;color:red;font-family:poppins;"> Please upload file having extensions .pdf/.doc/.docx/.xls/.xlsx only.  </p> -->
		 <p [style.color]="_color" style="font-weight: bold;font-size: 20px;">{{passingMessage}}</p>
		</td></tr>
		<tr>
			<td class="pt-1"><span>Upload File</span></td>
			<td class="pt-1">
				<input type="file" #fileUpload class="filecs" (change)="changeFile($event)"  accept="image/*,application/pdf,application/msword,
				application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
			</td>
		</tr>
		<tr>
			<td><span>Document ID*</span></td>
			<td>
				<mat-form-field>
					<input matInput placeholder="doc_value" maxlength="20" formControlName="doc_value">
				 </mat-form-field>
			</td>
		</tr>
		<tr *ngIf="passbookShowHide"><!--*ngIf="displayPassBook(passbookShowHide)"-->
			<td class="pt-1"><span>Bank Name</span></td>
			<td class="pt-1">
				<mat-form-field>
					<input matInput placeholder="bank_name" maxlength="20" formControlName="bank_name">
				 </mat-form-field>
			</td>
		</tr>
		<tr *ngIf="passbookShowHide">
			<td class="pt-1"><span>Bank Branch Details</span></td>
			<td class="pt-1">
				<mat-form-field>
					<input matInput placeholder="bank_branch" maxlength="20" formControlName="bank_branch">
				 </mat-form-field>
			</td>
		</tr>
		<tr *ngIf="passbookShowHide">
			<td class="pt-1"><span>IFSC Code</span></td>
			<td class="pt-1">
				<mat-form-field>
					<input matInput placeholder="ifsc_code" maxlength="20" formControlName="ifsc_code">
				 </mat-form-field>
		</tr>
		<tr *ngIf="passbookShowHide">
			<td class="pt-1"><span>Candidate Address</span></td>
			<td class="pt-1">
				<mat-form-field>
					<input matInput placeholder="candidate_address" maxlength="20" formControlName="candidate_address">
				 </mat-form-field>
			</td>
		</tr>

		<!-- <tr>
			<td><span>Document Type</span></td>
			<td>
			 <mat-form-field>
				 <mat-select formControlName="doc_type">
					 <mat-option value="AADHAR">AADHAR</mat-option>
					 <mat-option value="PAN">PAN</mat-option>
					 <mat-option value="PASS BOOK">BANK PASS BOOK</mat-option>
					 <mat-option value="SERVICE AGREEMENT">SERVICE AGREEMENT</mat-option>
					 <mat-option value="OTHERS">OTHERS</mat-option>
				 </mat-select>
			 </mat-form-field>
			</td>
		</tr> -->
		<tr>
			<td>
				&nbsp;
			</td>
			<td class="actionBTN">
				<button mat-button  (click)="resetForm()">Reset</button>
				<button mat-raised-button (click)="uploadDoc()">
				<span *ngIf="showHideWaitUpload"><b><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> &nbsp;Please wait...</b></span>	
				<span *ngIf="!showHideWaitUpload">Upload</span>
				
				</button>
			</td>
		</tr>
	</table>
</form>