
<div>
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12 text-center">
			<h3>Dialling</h3>
			<p>Please answer the call to continue</p>
		</div>
	</div>
	
			
			<br>
			<div class="row mt-3">

				<div class="col-md-12 col-sm-12 col-xs-12 text-center">
					<button mat-button class="cancelbtn"
						style="color: #3fb90d !important;border: solid 1px #3fb90d !important;height: 40px;width:120px;"
						(click)="closeDialog()">Cancel</button>
					<button mat-button class="submitbtn ml-2" 
						style="border: solid 1px #3fb90d !important;background-color: #3fb90d !important;height: 40px;width:120px;"
						(click)="submitbtn()">Answer</button>
				</div>

			</div>
	
</div>