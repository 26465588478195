import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-voice-biometric',
  templateUrl: './voice-biometric.component.html',
  styleUrls: ['./voice-biometric.component.scss']
})
export class VoiceBiometricComponent implements OnInit {
  currentTitle = 'voiceBiometric';
  showHideSuccessMsg : boolean=false;
  validationResult: any;
  sample1: any;
  sample2: any;
  sample3: any;
  currentFileobj: any;
  files: any[] = ["","",""];
  validateUrl: any;
  myUser: any;
  showWait1: boolean = false;
  showWait2: boolean = false;
  showWait3: boolean = false;
  showWait4: boolean = false;
  validating: boolean = false;
  uploading: boolean = false;
  api_end_point:any;
  constructor(private API: ApiService,private service: MyserviceService,) { }

  ngOnInit(): void {
	this.api_end_point = this.service.get_API_End_Point();
    this.myUser = this.service.get_loggedin_user();

  }

  changeFile(event, type) {
    var file, response;
    if (event.target.files && event.target.files.length) {
      file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = function(e) {
          document.getElementById('sample'+type).innerHTML = '<audio controls><source src="'+e.target.result+'" type="audio/mpeg"></audio>';       
      };
      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('attachment', file, file.name);
      if(type < 5){
        this['showWait'+type] = true;
      }
      
     
      this.API.uplaodFileServer(formData,this.api_end_point).subscribe((data: any) => {
        if(type < 5){
          this['showWait'+type] = false;
        }
        if (data.errors) {
          alert(data.errors);
        } else {
          // this.files.push(data.Location)
          if(type == 1){
            this.sample1 = {};
            this.sample1 = file;
            this.files[0] = data.Location;
          }
          if(type == 2){
            this.sample2 = {};
            this.sample2 = file;
            this.files[1] = data.Location;
          }
          if(type == 3){
            this.sample3 = {};
            this.sample3 = file;
            this.files[2] = data.Location;
          }
            if(type == 4){
              this.currentFileobj = {};
              this.currentFileobj = file;
              this.validateUrl = data.Location;
              this.showHideSuccessMsg = true;
            }
        }
     });
     
    }
    
    
		
  }

  uploadtBtn(){
    this.uploading = true;
this.API.bulkUploadVoice({fileUrls: JSON.stringify(this.files), userId: this.myUser.id},this.api_end_point).subscribe((data: any) => {
  this.uploading = false;
  if (data.errors) {
    alert(data.errors);
  } else {
    this.showHideSuccessMsg=true;
  }
});
  }

  validateVoice(){
    this.validating = true;
    this.API.validateVoice({validateUrl: this.validateUrl,userId: this.myUser.id},this.api_end_point).subscribe((data: any) => {
      this.validating = false;
      if (data.errors) {
        alert(data.errors);
        
      } else {
        if(data.status && data.status.status == 'success'){
          this.validationResult = data.status;
          this.validationResult.confidence = Math.round(this.validationResult.confidence)
        }
        if(data.status && data.status.status == 'failed'){
          this.validationResult = data.status;
          // setTimeout(()=>{
          //   this.validationResult = '';
          // },3000)
        }
      }
    });
  }
}
