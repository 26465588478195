<div class="background">
	<!--<mat-drawer-container autosize>
		<mat-drawer #drawer mode="side" opened="true">
			<app-desktop-menu></app-desktop-menu>
		</mat-drawer>-->
		<div class="mDiv" style="margin: 40px 30px;min-height: 100vh;">
			<div class="row" style="margin: 30px;">
				<div class="col-lg-6 col-md-6 col-sm-12 divTab">
					<div class="headingcontact">
						<p class="font">Contact list</p>
						<mat-form-field class="cForm" style="font-size: 13px;width:100%;position: sticky;top: -7px;"
						appearance="outline" [ngClass]="'bgWhite'">
						<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search"
							autocomplete="off">
						<i class="fa fa-search search-icon" matSuffix style="position: relative;
							top: -2px;"></i>
					</mat-form-field>
					</div>
					<div class="mScroll">


						<table mat-table [dataSource]="dataSource" class="mtable" matSort>
							<ng-container matColumnDef="Name">
								<th mat-header-cell *matHeaderCellDef style="display: none;">&nbsp;</th>
								<td mat-cell *matCellDef="let element" class="CR" (click)="nameRowRecord(element)">
									{{element.Name}}
									<span class="psType" style="font-size: 14px !important;
								position: relative;float: right;top: 2px;color: #f44336;">{{element.type.substring(0,1).toUpperCase()}}</span>
								</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns" style="display: none;"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"
								(click)="selectedRow = selectedRow === row ? null : row"
								[ngClass]="{ 'selected': row === selectedRow }"></tr>
						</table>
					</div>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12 selectedcontact" *ngIf="selectedContact">
					<div class="col-md-12">
						<img src="../../../assets/desktop_calling/img_avatar.png" alt="Avatar"  class="circleimg">
						<br>
						<p class="username">{{_nameUser}}</p>
					</div>
					<div class="col-md-12">
						<table class="mg">
							<tr>
								<td class="td-width">Phone Number</td>
								<td class="colorchange">({{selectedContact.Number}})</td>
								<td class="pdimg">
									<span class="iconWd" *ngIf="_wTemplate.options.includes('Call')" matTooltip="Call"
								[ngClass]="{ 'aActive': 'phone' === selectedButton }">
									<img src="../../../assets/desktop_calling/call support.svg" (click)="CallChatPhoneClick('phone')">
								</span>
								<!--<img src="../../../assets/desktop_calling/call support.svg" >-->
								</td>
							</tr>
							<tr class="mr">
								<td class="td-width">Email</td>
								<td class="colorchange">Test@gmail.com</td>
								<td class="pdimg">
									<span class="iconWd" *ngIf="_wTemplate.options.includes('Whatsapp')" matTooltip="Whatsapp"
								[ngClass]="{ 'aActive': 'whatsapp' === selectedButton }"> 
									<img src="../../../assets/desktop_calling/call-support-mail.svg" (click)="CallChatPhoneClick('whatsapp')">
								</span>
									<!--<img src="../../../assets/desktop_calling/call-support-mail.svg">-->
								</td>
							</tr>
							<tr >
								<td class="td-width">Work Number</td>
								<td class="colorchange">({{selectedContact.Number}})</td>
								<td class="pdimg">
									<span class="iconWd" *ngIf="_wTemplate.options.includes('Call')" matTooltip="Call"
									[ngClass]="{ 'aActive': 'phone' === selectedButton }">
									<img src="../../../assets/desktop_calling/call support.svg" (click)="CallChatPhoneClick('phone')">
								</span>
								<!--<img src="../../../assets/desktop_calling/call support.svg" >-->
								</td>
							</tr>
							<tr class="mr">
								<td class="td-width">Facebook ID</td>
								<td class="colorchange">Fb/allen.wells</td>

							</tr>
						</table>
					</div>
					<!--<div class="col-md-12">
						<p class="plft"><img src="../assets/web_images/iconPersonImg.svg" /> <span
								class="tabUserName">{{_nameUser}} ({{selectedContact.Number}})</span><span
								class="psType">{{_psType}}</span> </p>
						<p class="pt-2 plft">
							<span class="iconWd" *ngIf="_wTemplate.options.includes('Call')" matTooltip="Call"
								[ngClass]="{ 'aActive': 'phone' === selectedButton }"><img
									(click)="CallChatPhoneClick('phone')" class="cr"
									src="../assets/web_images/phoneGrayIcon.svg"> </span>
							<span class="iconWd" *ngIf="_wTemplate.options.includes('Whatsapp')" matTooltip="Whatsapp"
								[ngClass]="{ 'aActive': 'whatsapp' === selectedButton }"> <img
									(click)="CallChatPhoneClick('whatsapp')" class="cr"
									src="../assets/web_images/whatsappGrayIcon.svg"> </span>
							<span class="iconWde" *ngIf="_wTemplate.options.includes('Email')" matTooltip="Email"
								[ngClass]="{ 'aActive': 'text' === selectedButton }"> <img
									(click)="CallChatPhoneClick('text')" class="cr"
									src="../assets/web_images/envelopeGrayIcon.svg"> </span>
						</p>
						<hr class="hrLine">
						<div *ngIf="showHideWhatsapp">
							<div class="row plft pt-4">
								<div class="col-lg-4 col-md-4 col-sm-12">
									<span class="lvl">Select WhatsApp Template</span><br>
									<mat-select [ngClass]="'arrowcs'" class="dropdownCs mt-3" [(ngModel)]="templateddlValue"
										(selectionChange)="changeWhatsappTemplate($event.value)"
										placeholder="Select a Template">
										<mat-option [value]="v.template_name" *ngFor="let v of _ddlListExtraOption">
											{{v.template_name}}</mat-option>
									</mat-select>
								</div>
							</div>

							<div class="row plft pt-4">
								<div class="col-lg-11 col-md-11 col-sm-12">
									<br>
									{{_paraMessage}}

								</div>
							</div>


							<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Relationship_Manager">
								<div class="col-lg-4 col-md-4 col-sm-12">
									<span class="lvl">Relationship_Manager</span> <br>
									<mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
										<input matInput class="mt-3 dtcs" autocomplete="off"
											[(ngModel)]="relationshipManagerModel">
									</mat-form-field>
								</div>
							</div>
							<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Tracking_Link">
								<div class="col-lg-4 col-md-4 col-sm-12">
									<span class="lvl">Tracking Link</span> <br>
									<mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
										<input matInput class="mt-3 dtcs" autocomplete="off"
											[(ngModel)]="trackingLinkModel">
									</mat-form-field>
								</div>
							</div>
							<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Phone_Number">
								<div class="col-lg-4 col-md-4 col-sm-12">
									<span class="lvl">Phone number</span> <br>
									<mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
										<input matInput (keypress)="onlyNumberValidation($event)" class="mt-3 dtcs"
											autocomplete="off" maxlength="15" [(ngModel)]="phoneNoModel">
									</mat-form-field>
								</div>
							</div>

							<div class="row plft pt-4">
								
								<div class="col-lg-4 col-md-4 col-sm-12" *ngIf="_rowExtraOptionData.Next_Update_Time">
									<span class="lvl">Next_Update_Time</span> <br>
									<mat-form-field appearance="outline" class="mt-3 cForm" style="width:100%">
										<input #fromInput matInput [min]="minDate" [matDatepicker]="picker"
											class="mt-3 dtcs" placeholder="Date" [(ngModel)]="dateSelect" readonly
											(click)="picker.open()">
										<mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 18px !important;">
										</mat-datepicker-toggle>
										<mat-datepicker #picker></mat-datepicker>
									</mat-form-field>
								</div>
							</div>

							<div class="row plt">
								<div class="col-lg-4 col-md-4 col-sm-12">
									<button class="submit-btn-ui" (click)="saveBtn()"> Submit</button>
								</div>
							</div>

						</div>
						<div *ngIf="showHidePhone">
							<b>Call</b>
						</div>
						<div *ngIf="showHideText">
							<b>Text</b>

						</div>
					</div>-->
				</div>
			</div>
		</div>
	<!--</mat-drawer-container>-->
</div>