import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import * as _ from 'underscore';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { EmployeeviewComponent } from './employeeview/employeeview.component';

import { Utils } from '../utils';

import { ServicesService } from './services.service';

@Component({
	selector: 'app-fk-emp',
	templateUrl: './fk-emp.component.html',
	styleUrls: ['./fk-emp.component.scss'],
	providers: [Title]
})
export class FkEmpComponent implements OnInit {
	currentTitle = 'fk_service_dash';
	total_connected_chart = 'total';
	tabs_data = this.service.tabs_data;
	START_DATE = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	END_DATE = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);

	total_data_chart = new Chart();
	data_dialled_chart = new Chart();

	detailed_data_object = {
		'isActive': false,
		'tab_details': null,
		'data_obj': null
	}

	public divisonTableData: any = new MatTableDataSource([]);
	public divisionTableColoumns: any = ['sl_no', 'customer_name', 'customer_number', 'agent_name', 'agent_number', 'start_time', 'end_time'];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	date_range_filter = (d: Date | null): boolean => {
		// Start date should be less than the end date
		if (moment().diff(moment(d)) < 0) {
			return false;
		}
		return true;
	}

	summary_object = {
		"total_dialled": 0,
		"total_connected": 0,
		"total_rnr": 0,
		"unique_dialled": 0,
		"unique_contacted": 0,
		"not_reachable": 0,
		"wrong_number": 0,
		"callback": 0,
		"divisons_contacted_unique": {
			"TRAINING": 0,
			"OJT": 0,
			"EMAIL": 0,
			"PRODUCTION": 0,
			"RETENTION": 0,
			"CERTIFICATION": 0
		}
	}

	constructor(
		private title: Title, private service: ServicesService, private utils: Utils, private dialog: MatDialog,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {
		this.title.setTitle('Contiinex | Customer Dashboard');
		this.refreshData();
	}

	ngAfterViewInit() {
		this.service.getSummaryObject().subscribe((obj) => {
			// console.log('Summary object received from the service', obj);
			this.summary_object = obj;
			this.data_dialled_chart = new Chart({
				chart: {
					plotBorderWidth: null,
					plotShadow: false,
					plotBackgroundColor: null,
					type: 'pie',
					width: 300,
					height: 300
				},
				credits: {
					enabled: false
				},
				tooltip: {
					useHTML: true,
					formatter: function () {
						// console.log('tootip', this); // just to see , what data you can access
						var p: any = this.point;
						return this.key +
							'</b> ' + this.y + '%<b> (' + p.text + ')';
					}
				},
				title: {
					text: '',
					style: {
						display: 'none'
					}
				},
				plotOptions: {
					pie: {
						colors: [
							'#50B432',
							'#ED561B',
							'#DDDF00',
							'#24CBE5',
							'#64E572',
							'#FF9655',
							'#FFF263',
							'#6AF9C4'
						],
						shadow: false,
						center: ['50%', '50%'],
						size: '90%',
						innerSize: '40%',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true
					},
				},
				series: [{
					type: 'pie',
					data: [
						{
							"y": parseInt(this.utils.getPercentage(this.summary_object.total_connected, this.summary_object.total_dialled, 0)),
							"name": "CONNECTED",
							"text": this.summary_object.total_connected.toString()
						},
						{
							"y": parseInt(this.utils.getPercentage(this.summary_object.total_rnr, this.summary_object.total_dialled, 0)),
							"name": "RNR",
							"text": this.summary_object.total_rnr.toString()
						}
					]
				}]
			})
			// console.log('printing data_dialled_chart', this.data_dialled_chart);
		})
		this.service.table_data_map['total']['chart_data'].subscribe(
			(d) => {
				// console.log('printing chart data',d);
				this.total_data_chart = new Chart({
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: 'pie',
						width: 300,
						height: 300
					},
					title: {
						text: '',
						style: {
							display: 'none'
						}
					},
					credits: {
						enabled: false
					},
					accessibility: {
						point: {
							valueSuffix: '%'
						}
					},
					plotOptions: {
						pie: {
							size: '90%',
							dataLabels: {
								enabled: false,
							},
							allowPointSelect: true,
							cursor: 'pointer',
							showInLegend: true
						}
					},
					series: [
						{
							name: d.name,
							data: d.data,
							type: undefined
						}
					]
				})
			}
		);
	}

	change_date(key, event): any {
		// console.log('dates', this.START_DATE, this.END_DATE);
		// console.log('date difference', moment(this.END_DATE).diff(moment(this.START_DATE)))
		if (moment(this.END_DATE).diff(this.START_DATE) < 0) {
			this.END_DATE = this.START_DATE
		}
	}

	refreshData() {
		// console.log('calling refresh the data', this.START_DATE, this.END_DATE);
		let obj = {
			'start_date': this.utils.localToUTC(this.START_DATE, '', 'YYYY-MM-DDTHH:mm:ss'),
			'end_date': this.utils.localToUTC(moment(this.END_DATE).endOf('day'), '', 'YYYY-MM-DDTHH:mm:ss')
		}
		// console.log('output obj', obj);
		this.reset_detailed_div
		this.service.getDashboardData(obj).then((success) => {
			// console.log('Data received');
		}, (error) => {
			// console.log('Error occured', error);
		})
	}

	reset_detailed_div() {
		// console.log('Resetting the detailed div');
		this.detailed_data_object = {
			'isActive': false,
			'tab_details': null,
			'data_obj': null
		}
	}

	openCustomerDialog(obj) {
		// console.log('open customer dialog', obj);
		var dialogRef = this.dialog.open(EmployeeviewComponent, {
			width: '40%',
			hasBackdrop: true,
			data: obj
		});
	}


	detailed_section(tab_name, index) {
		// console.log('printing tab name view click', tab_name);
		if (this.service.api_data_response && this.service.api_data_response[this.tabs_data[index]['data_key']]) {
			this.detailed_data_object.tab_details = this.tabs_data[index];
			this.detailed_data_object.data_obj = this.service.api_data_response[this.tabs_data[index]['data_key']];
			let temp = this.detailed_data_object.data_obj;
			let t = temp.data;
			if (t && t.length) {
				this.detailed_data_object.isActive = true;
				let arr = [];
				for (var i in t) {
					let obj: any = {};
					let fb = t[i]['feedback'];
					obj.sl_no = parseInt(i) + 1;
					obj.customer_name = this.utils.getValue(t[i]['customer_name'], '');
					obj.start_time = this.utils.utcToLocal(t[i]['start_time'], 'YYYY-MM-DDTHH:mm:ss', 'MMM DD LTS');
					obj.end_time = this.utils.utcToLocal(t[i]['end_time'], 'YYYY-MM-DDTHH:mm:ss', 'MMM DD LTS');
					obj.call_url = this.utils.getValue(t[i]['url'], null);
					obj.customer_number = this.utils.getValue(t[i]['customer_number'], '');
					obj.agent_name = this.utils.getValue(t[i]['agent_name'], '');
					obj.agent_number = this.utils.getValue(t[i]['agent_number'], '');
					obj.feedback = fb;
					// console.log('printing data obj', obj);
					arr.push(obj)
				}
				// console.log('printing arr',columns, arr, this.detailed_data_object);
				this.divisonTableData = new MatTableDataSource(arr);
				this.divisonTableData.paginator = this.paginator;
			} else {
				this.snackBar.open('No Employee Feedback', 'Close', {
					duration: 2000,
					horizontalPosition: 'start',
					verticalPosition: 'bottom'
				})
			}
		} else {
			this.snackBar.open('Data not available', 'Close', {
				duration: 2000,
				horizontalPosition: 'start',
				verticalPosition: 'bottom'
			})
		}
		// console.log('printing selected tab data', this.tabs_data[index]);
		// console.log('api response', this.service.api_data_response);
		// console.log('api selected', this.service.api_data_response[this.tabs_data[index]['data_key']]);
	}

}
