import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { ApiService } from '../../api.service';
import {MyserviceService} from '../../myservice.service'
@Component({
  selector: 'app-demo-portal-crc-call',
  templateUrl: './demo-portal-crc-call.component.html',
  styleUrls: ['./demo-portal-crc-call.component.scss']
})
export class DemoPortalCrcCallComponent implements OnInit {
  showHideVisible:boolean=true;
  showHideVisibleSuccess:boolean=false;
  createdForm: FormGroup;
  _call_id:any="";
  _message:any="";
  api_end_point:any;


  constructor(public router :Router,private API: ApiService,
		private formbuilder: FormBuilder,private _service:MyserviceService) {
      this.createdForm = this.formbuilder.group({
				"telcoPartner": ['AIRTEL', Validators.required],
				"agentVoip": ['', Validators.required],
				"customerNo": ['', Validators.required],
				"cli": ['+918045475055', Validators.required]
			  })
     }

  ngOnInit(): void {
  }
  backDashboard(){
   // window.location.reload();
   this.router.navigateByUrl('/demo-portal');
   setTimeout(()=>{
     this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
   },100);
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
  cancelBtn(){
    this.showHideVisible=true;
    this.showHideVisibleSuccess=false;
    this.createdForm.reset();
  }
  submitBtn(createdForm:any){   
    let _telcoPartner=createdForm.controls.telcoPartner.value;
    let _agentVoip=createdForm.controls.agentVoip.value;
    let  _cusNo=createdForm.controls.customerNo.value;
    let _cli=createdForm.controls.cli.value;
    if(_telcoPartner=="" || _agentVoip=="" || _cusNo =="" || _cli==""){
        alert("Please enter all filelds");
        return false;
    }
    let _obj={
        "telco_partner":_telcoPartner,
        "agent_voip":_agentVoip,
        "customer_no":_cusNo,
        "cli":_cli
    }
    this.API.c2cCallApiSave(_obj,this.api_end_point).subscribe((response:any) => {
if(response.status=="success"){
 this._call_id= response.call_id;
 this._message= response.message;
  this.showHideVisible=false;
this.showHideVisibleSuccess=true;
}
else{
  alert(response.status);
}



      });
  }
  okBtn(){
    window.location.reload();
    this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
}
