
<br>
<div *ngIf="showHideVisible"> 
    <form  [formGroup]="createdForm"> 
<div class="row mt-2">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left item">
        <img src="../assets/crcImg.png">&nbsp;&nbsp;&nbsp;
        <span>Email</span>
</div>
</div>

<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Template</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off"  formControlName="template"/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Mail Subject</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off"  formControlName="mailSubject" required maxlength="150"/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Mail Body</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="mailBody" required/>
            </mat-form-field>
</div>
</div>


<div formArrayName="customers_list">
    <span class="row mt-2 rowx party">Customer List</span><br>
<div class="row mt-2 rowx" *ngFor="let v of customersList.controls; let i=index;let last=last" [formGroupName]="i">            
    <div class="col-md-3 col-sm-3 col-xs-12 text-left">
        <mat-form-field style="width: 100%;">
            <input matInput autocomplete="off" placeholder="Name" formControlName="name" maxlength="100"/>
            </mat-form-field>
</div>
<div class="col-md-3 col-sm-3 col-xs-12 text-left">
    <mat-form-field style="width: 100%;">
        <input matInput autocomplete="off" placeholder="Email" formControlName="email" maxlength="100"/>
        </mat-form-field>
</div>
<div class="col-md-3 col-sm-3 col-xs-12 text-left">
    <mat-form-field style="width: 100%;">
        <input matInput autocomplete="off" placeholder="Link" formControlName="link" />
        </mat-form-field>
</div>
<div class="col-md-3 col-sm-3 col-xs-12 text-left"><br>
    <mat-icon class="icon-cs" *ngIf="i !=0" (click)="deleterow(i)">remove_circle_outline</mat-icon>
    <mat-icon class="icon-cs" *ngIf="last" (click)="addrow()">add_circle_outline</mat-icon>
</div>
</div>

</div>
<br>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <button class="demo-back" (click)="backDashboard()">BACK</button>
        <button class="demo-cancel" (click)="cancelBtn()">CANCEL</button>
        <button class="demo-save" (click)="submitBtn(createdForm)">SUBMIT</button>
</div>
</div>
</form>
</div>


<div  *ngIf="showHideVisibleSuccess">
    <div class="row mt-2">            
        <div class="col-md-4 col-sm-4 col-xs-12 item" style="text-align: center;
        margin: 10% auto;">
            <span>ID : {{_email_id}}</span><br>
            <span>{{_message}} </span><br>
            <button class="demo-save" (click)="okBtn()">OK</button>
    </div>
    </div>
</div>
<br>
<br>
<br>