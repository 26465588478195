import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import{HttpClient }from '@angular/common/http';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {MyserviceService}from '../myservice.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
createdForm:FormGroup;
_urldata:any;
resResult:any;

  constructor(private router: Router,private http:HttpClient,private fb:FormBuilder,private _service:MyserviceService) { 

    this.createdForm=fb.group({
      email:['',[Validators.required,Validators.email]],
      password:['',[Validators.required,Validators.maxLength(50)]]
    })
  }

  ngOnInit() {
  }
  signBtn(createdForm:any){
let _email=createdForm.controls.email.value;
let _password=createdForm.controls.password.value;
let formData={
  "email": _email,
  "password": _password,
  "login_method": "smb"
 }
 let _url='';
 let str1 = new String( _url); 
 let str2 = new String( "email="+formData.email+"&password="+formData.password+"&login_method="+formData.login_method+"" ); 
 let urlapi = str1.concat(str2.toString());

    this.http.get(urlapi).subscribe(
      (res) => {
     let _error=   res["errors"];
        if(_error=="Invalid Credentials" || _error=="User does not exist"){
          alert(_error);
         // alert("Invalid Credentials,User does not exist")
          return false;
        }
        else{           
         this.resResult=res;        
       // console.log("res",this.resResult); 
       

          this.router.navigate(['/dashboard']); 
          sessionStorage.setItem('seturlvalue', 'successful'); 
        }
      },
      (err) =>{
       alert("User does not exist")
      }
    );

   
  }
 

}
