import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-questionnaire-response-dialog',
  templateUrl: './questionnaire-response-dialog.component.html',
  styleUrls: ['./questionnaire-response-dialog.component.scss']
})
export class QuestionnaireResponseDialogComponent implements OnInit {
  QuestionAnswer:any;
  constructor(	private dialogRef: MatDialogRef<QuestionnaireResponseDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public QuestionAnswerData,) { }

  ngOnInit() {
   // debugger;
   // console.log(this.QuestionAnswerData)
   this.QuestionAnswer= this.QuestionAnswerData
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
