import { Component, OnInit,ElementRef, HostListener, } from '@angular/core';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { Title, Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-new-contiinex-home-page',
  templateUrl: './new-contiinex-home-page.component.html',
  styleUrls: ['./new-contiinex-home-page.component.scss']
})
export class NewContiinexHomePageComponent implements OnInit {
  myTitle = 'Contiinex | Customer Engagement Automation';
  activeItem: string;
  _menuPage:string;
  constructor(private metaTagService: Meta,private titleService:Title,private router:Router,private location: Location,private API : ApiService,protected $gaService: GoogleAnalyticsService) {
    window.scroll(0,0);
   }


  ngOnInit() {
    this.titleService.setTitle(this.myTitle);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Hyperscale your business by automating customer digital journeys at every stage'},"name='description'"
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: 'Hyperscale your business by automating customer digital journeys at every stage'},"property='og:description'"
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: 'Hyperscale your business by automating customer digital journeys at every stage'},"name='twitter:description'"
    );
  }


  openKnowMore(){
    this.$gaService.event('Clicked Know More From Product Banner Section', 'Home Page');
    this.router.navigateByUrl('/Quality-Assurance-Automation');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

   }
   bookDemoBtn(){
    this.$gaService.event('Clicked Book A Demo From Product Banner Section', 'Home Page');   

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Contact');
   }

   openKnowMore_benefit(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Quality-Assurance-Automation');
   }


   bookDemoBtn_Benefit(){     
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Contact');
   }


 clickKnowMoreComnexExperience(){
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
});
this.router.navigateByUrl('/Quality-Assurance-Automation');
 }
 clickKnowMoreComnexEngage(){
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
});
this.router.navigateByUrl('/Quality-Assurance-Automation');
 }
 clickKnowMoreComnexEngage1(){
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
});
this.router.navigateByUrl('/Unified-Communication-as-a-Service');
 }
 clickKnowMoreComnexEnable(){
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
});
this.router.navigateByUrl('/Quality-Assurance-Automation');
 }
 clickKnowMoreWhatsappApi(){
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
});
this.router.navigateByUrl('/WhatsApp-Business-Api');
 }
}
