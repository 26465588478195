<div  class="m-3">

    <mat-card  class="mt-cd">
        <div class="flexcontainer">
            <div> <span class="span-label"> Call Id:</span> 
                        <span class="span-label-data">111</span></div>
            <div>  <span class="span-label"> Agent:</span> 
                        <span class="span-label-data">xxxxx</span></div>      
            <div>  <span class="span-label">Project:</span> 
                        <span class="span-label-data">xxx</span></div>
            <div>     <span class="span-label">Chats to Audit:</span> 
                        <span class="span-label-data">xxxx</span></div>
            <div> <span class="span-label"> Audited:</span> 
                        <span class="span-label-data">xxx</span></div>  
            <div> <span class="span-label"> Total Earned:</span> 
                        <span class="span-label-data">  &nbsp;xxxx    </span></div>
                         <div>&nbsp;</div>
         <!--   <div>  <span class="span-label"> <input type="text" placeholder="search" class="input-field"></span></div> -->
          </div> 
<div class="span-voice">
<span class="span-label pdl"> Chat Audit </span> 
</div>  
    </mat-card>

    <div class="row mt-2">        
        <div class="col-md-6 mt-1">
<mat-card class="matcard" >
    <p class="title-heading">Ticket no 42324</p>



    <div id="chatBox" class="message_area chats" style="border-bottom: 1px solid #ccc;">
      <div class="incoming-msg" style="display: inline-flex;">
        <div>
          <img src="https://icons.iconarchive.com/icons/google/noto-emoji-people-face/1024/10147-adult-light-skin-tone-icon.png" alt="Avatar" style="width:40px;border-radius: 50%;margin-left:7px;">
        </div>
        <div class="incoming"> 
          <span class="date-cs">FRI 11:30AM</span>    
      <!--  <p style="margin-bottom: 0px;">
         <span  class="user-name">veenit</span> 
          <span class="timespan"> 12:30 PM</span>     
      </p> --> 
    
         <p class="incoming-p"> <span>hiii krunal</span></p> 
      </div>
      </div>
    
<div class="outgoing-message" style="display: inline-flex;">
  <div class="outgoing">   
    <span class="date-cs">&nbsp;</span>  
     <!-- <p style="margin-bottom: 0px;">
       <span  class="user-name">Krunal</span>
        <span class="timespan"> 12:31PM</span>       
    </p> --> 
      <p class="outgoing-p"> <span>hello veenit</span></p>  
    </div>
</div>
      
         

    </div>

    <input type="text" class="sendInput" id="textarea"  placeholder="Type a message…" />

    <!-- <button class="sendBtn">Send</button> -->
     <div class="d-flex flex-row justify-content-start ">
                <div>
                <mat-icon class="maticonOption" >image</mat-icon>
                <mat-icon class="maticonOption"  >gif</mat-icon>
                <mat-icon class="maticonOption"  >tag_faces</mat-icon>
                <mat-icon class="maticonOption" >date_range</mat-icon>
                <mat-icon class="maticonOption"  >camera_alt</mat-icon>
              </div>
              </div>


</mat-card>
        </div>          
            <div class="col-md-6 mt-1">
              <mat-card class="matcard" >
<p class="title-heading">Questionnaire Response</p>
<div class="tablediv">
<table class="table-cls">
<tr><td><span style="color: #e40a67;font-size: 14px;"> Questions </span> </td><td> <span style="color: #e40a67;    font-size: 14px;"> Answer </span></td> </tr>
<tr *ngFor="let v of QuestionAnswer"><td>{{v.question}} </td><td>{{v.answer}} </td> </tr>
</table> 
<br><br>
  </div>
</mat-card>
<mat-card class="matcard mt-2">
  <p class="title-heading">Call Auditing score</p>
    <div class="tablediv" *ngFor="let a of CallAuditingscore;let i=index;">
  <p class="mt-1" style="color: #e40a67;margin-bottom: 3px;">{{i+1}}. {{a.label}} </p>
      <table  class="tableaudit">
<tr *ngFor="let b of a.data;let j=index;"> 
<td  style="color: gray;"> {{i+1}}.{{j+1}} {{b.title}} </td>
<td style="position:absolute;right:20px"> 
 <select class="selectddl" [value]="b.select">
   <option value="yes">Yes</option>
    <option value="no">No</option>
 </select>
  </td> 
</tr>
        </table>
  </div>
  <div  class="tablediv">
            <table style="width:100%;">
<tr>
 <td colspan="2"> <p class="mt-2" style="color: #e40a67;"><b>Comments</b> </p> </td>
 </tr>
  <tr>
 <td colspan="2"> 
   <input type ="text" class="input-field wd"/>
    </td>
 </tr>
    <tr>
 <td> 
  <p class="mt-2" style="color: #184b89;"><b>Total Score: 5</b> </p> 
    </td>
      <td style="position:absolute;right:10px;margin-top:7px"> 
  <button class="submit-Btn">Submit</button>
    </td>
 </tr>
        </table>
  </div>
</mat-card>
        </div>
    </div>
  
</div>

