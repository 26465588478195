<h3 class="sBiz-title">SmarterBiz</h3><br>
<div class="text-center">
    <p  class="hdtext"><span> On-Boarding Process</span></p>
    <p class="LText">Boost your operational efficiency with realtime<br>
        customer journey orchestration across channels.</p>
   
</div>

<div>
<mat-card class="m-card">
    <div class="row bgText">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            QUALITY AUDIT METRICS
        </div>
    </div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Quality audit questionnaire</span> <br> <br>       
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" />
          </div>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Quality audit score calculation</span> <br> <br>       
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" />
          </div>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Quality audit dashboard</span>  <br> <br>    
        <p style="font-size: 10px;word-spacing: normal;word-break: break-all;">Sample dashboard :<br>
            https://drive.google.com/file/d/1cTRsCnyBpaRZsddz6hYF6LE57LzAp6Yr/view?usp=sharing</p>
       
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" />
          </div>
    </div>
</div>
<div class="row rowDiv" style="background: white;">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <mat-checkbox class="mcbx">
            Send me a copy of my responses.
       </mat-checkbox>
    </div>
</div>
<br><br>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
       <button class="onboard-submit"  (click)="saveBtn()">SUBMIT</button>
    </div>
</div>
<br><br>
</mat-card>
</div> 
