import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';
import { Utils } from '../../utils';

@Component({
  selector: 'app-auditor-add-client-dialog',
  templateUrl: './auditor-add-client-dialog.component.html',
  styleUrls: ['./auditor-add-client-dialog.component.scss']
})
export class AuditorAddClientDialogComponent implements OnInit {
  createdForm: FormGroup;
  myUser: any;
  passingMessage:string = "";
  showHidePassingMessage:boolean=false;
  _color:any="green";

  @ViewChild('f') formVar;


  constructor(private service: MyserviceService, private API: ApiService, public dialogRef: MatDialogRef<AuditorAddClientDialogComponent>,private formbuilder:FormBuilder,  private utils: Utils) { 
    this.createdForm = this.formbuilder.group({
      projectName: ['',[Validators.required, this.utils.ValidateEmptyInput]],
      companyName: ['', [Validators.required, this.utils.ValidateEmptyInput]],
      cxTouchPoint: ['', Validators.required],
      formName: ['', [Validators.required, this.utils.ValidateEmptyInput]],
      formJson: ['', [Validators.required]],
      normalizedScore: [null, [Validators.required, Validators.pattern("[0-9 ]{1,3}")]],
    })
  }

  ngOnInit(): void {
    this.onFormChanges();
    this.myUser = this.service.get_loggedin_user();

  }

  onFormChanges(): void {
    this.createdForm.valueChanges.subscribe(val => {

    });
  }

  addClientWithForm(createdForm: any){
   // console.log(createdForm);
    const _modelObject = {
      project_name: createdForm.controls.projectName.value,
      company_name: createdForm.controls.companyName.value,
      cx_touch_point: createdForm.controls.cxTouchPoint.value,
      production_group: this.myUser.id,
      superadmin_id: this.myUser.id,
    } as any;

    var _modelFormObject = {
      audit_form_object: createdForm.controls.formJson.value,
      audit_form_name: createdForm.controls.formName.value,
      audit_client_id: 0,
      cx_touch_point: createdForm.controls.cxTouchPoint.value,
      normalized_score: createdForm.controls.normalizedScore.value,
    }
    this.API.saveNewAuditClient(_modelObject).subscribe((data: any) => {
      if (data.success) {
          _modelFormObject.audit_client_id = data.success.data[0].id;
        this.API.saveNewAuditForm(_modelFormObject).subscribe((data: any) => {
          if(data.success){
            this.passingMessage = "Audit client and form created succcessfully";
            this.showHidePassingMessage = true;
            this.formVar.resetForm();
            setTimeout(() => {
              
              this.closeDialog();
              }, 2000);
          }
          
        });
          
        
      }
    });
  }

  closeDialog(){
    this.dialogRef.close({refresh:true});
  }

  omit_special_char(event)
  {   
    let k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }
}
