<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
      <app-menu  [navigateLink]="currentTitle" ></app-menu>
    </mat-drawer>
    <app-logout></app-logout>
<div class="CN-Main">
    <p class="CN-Tab-head">Dashboard</p>
    <div class="pt-2">
        <mat-card class="CN-Card cardFirst card-first">
            <div style="display: flex;flex-wrap: wrap;">
                <div class="mr-2" style="width:170px">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(value)]="selected"
                            (selectionChange)="OnChangeProject($event.value)">
                            <mat-option value="allProjects">Select Project</mat-option>
                            <mat-option *ngFor="let prj of projects.split('|')" [value]="prj">{{prj}}</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="mr-2" style="width:170px" *ngIf="project_name == 'waba'">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(value)]="selected_business_phone"
                                    (selectionChange)="OnChangeBusinessPhone($event.value)">
                            <mat-option *ngFor="let bp of ['ALL'].concat(business_phones.split(','))" [value]="bp">{{bp}}</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="mr-2" style="width:160px">
                    <span *ngIf="showHideBasedOnProject">
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(value)]="selectedDayWise"
                            (selectionChange)="OnChangeDayTime($event.value)">
                            <mat-option value="Today">Today</mat-option>
                            <mat-option value="This Month">This Month</mat-option>
                            <mat-option value="This Year">This Year</mat-option>
                            <mat-option value="CustomDate">Custom Date</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="mr-1" style="min-width:210px" *ngIf="showHideCustomDate">
                    <mat-form-field appearance="outline" class="customDate">
                        <mat-date-range-input [rangePicker]="rangePicker">
                          <input matStartDate placeholder="Start Date" readonly (click)="rangePicker.open()"  #dateRangeStart>
                          <input matEndDate placeholder="End Date" readonly (click)="rangePicker.open()" #dateRangeEnd  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #rangePicker></mat-date-range-picker>
                      </mat-form-field>
                
                </div>
             
                <div class="mr-0" style="min-width:162px" *ngIf="showHideCustomDate">  
                    <!-- cn-dat -->
                    <!-- {{getCurrentDate()}} -->
                    <!-- *ngIf="showHideCustomTime" -->
                    <table class="timeInputtbl" >
                        <tr>
                            <td> 
                                Start Time  <input type="time" id="myStartTime" value="00:00" class="pl-2">
                             </td>
                      </tr>
                    </table>
                </div>


                <div class="mr-0" style="width:200px" *ngIf="showHideCustomDate"> 
                    <table class="timeInputtbl" >
                        <tr>                           
                        <td class="pl-2">
                            End Time <input type="time" id="myEndTime" value="23:59" class="pl-2">
                        </td>
                        <td class="pl-2" style="vertical-align: baseline;"><button class="verifyBtn" (click)='verifyButton()'>Change</button></td>
                      </tr>
                    </table>
                </div>



            </div>
        </mat-card>
        <!--start main dashboard-->
        <div *ngIf="showHideMainDashboard">
            <mat-card class="CN-Card">
                <p class="CN-Tab-head1">All Projects</p>
                <!-- <div class="row" style="display: none;">
                    <div class="col-md-4 col-sm-12 col-xs-12  mt-3">
                        <mat-card>
                            <p class="CN-Tab-head1">Traffic</p>
                            <div [chart]="AreachartOptions"></div>
                        </mat-card>
                    </div>
                    <div class="col-md-8 col-sm-12 col-xs-12  mt-3">
                        <mat-card>
                            <p class="CN-Tab-head1">Quality Of Delivery</p>
                            <div [chart]="lineChart"> </div>
                        </mat-card>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12  mt-3">
                        <mat-card>
                            <p class="CN-Tab-head1">Overall Cost </p>

                               <div style="min-width: 200px; height: 200px; max-width: 200px;"
                                        [chart]="donutChartrecord"></div>
                                        <p class="p-donut"><span> <i class="fa fa-square"
                                                        style="color: #e6e6e6;padding-right: 10px;"
                                                        aria-hidden="true"></i>
                                                </span><span>Target</span></p>
                                            <p class="p-donut"><span> <i class="fa fa-square"
                                                        style="color: #ed372d;padding-right: 10px;"
                                                        aria-hidden="true"></i></span><span>Cost</span></p>
                        </mat-card>
                    </div>

                </div> -->

            </mat-card>
        </div>
        <!--end main dashboard-->
        <!--start project wise dashboard-->
        <div *ngIf="showHideprojectOne">
            <app-project-one></app-project-one>
        </div>

    </div>
</div>
</mat-drawer-container>