<div class="row">
	<div class="col-md-12">
		<mat-card style="margin: 0 10px;box-shadow: 0px 0px 4px 0px #dfdfdf;">
			<div class="row">
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon8.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1">{{getAllTotalAgents()}}</span>
								<span class="nameLabel1">Agents</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon9.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#50bafd;">{{getAllTotalLoggedIn()}}</span>
								<span class="nameLabel1">Logged In</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon10.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#f3385d;">{{getAllTotalLive()}}</span>
								<span class="nameLabel1">Live</span>
							</td>
						</tr>
					</table>
				</div>
			</div>

		</mat-card>
	</div>
</div>
<div class="row ">
	<div class="col-md-12">
		<mat-card style="margin: 0 12px;" class="mt-3">
			<h4 class="titlehead thd1 mb-4">ASM</h4>


			<table matSort (matSortChange)="sortData($event)" class="vTableV">
				<tr>
					<th mat-sort-header="name">NAME</th>
					<th mat-sort-header="totalAgents"> TOTAL AGENTS</th>
					<th mat-sort-header="loggedIn">LOGGED IN</th>
					<th mat-sort-header="totalLoggedIn">TOTAL LOGGED IN</th>
					<th mat-sort-header="liveIn">LIVE IN %</th>
					<th mat-sort-header="totalLive">TOTAL LIVE</th>
					<th mat-sort-header="dailpercall">DIAL PER AGENT</th>
				</tr>
				<tbody *ngFor="let d of sortedData | async;let i = index;let first=first;"><!---let first=first;-->
					<tr>
						<td colspan="6" style="color: #000;font-weight: bold;">{{d.title}}</td>
					</tr>
					<tr *ngFor="let v of d.rData;let j = index;let first=first;"><!--let first=first;-->
						<td>{{v.name}}</td>
						<td>{{v.totalAgents}}</td>
						<td>{{v.loggedIn}}</td>
						<td>{{v.totalLoggedIn}}</td>
						<td>{{v.liveIn}}</td>
						<td>{{v.totalLive}}</td>
						<td>{{v.dailpercall}}</td>
					</tr>
					<tr style="background: #f9f9f9;">
						<td><b>Total</b></td>
						<td>{{getTotalAgents(d)}}</td>
						<td>{{getLoggedIn(d)}}</td>
						<td>{{getTotalLoggedIn(d)}}</td>
						<td>{{getLiveIn(d)}}</td>
						<td>{{getTotalLive(d)}}</td>
						<td>{{getTotaldialper_agent(d)| number : '1.1-1'}}</td>

					</tr>
				</tbody>
				<tfoot>
					<tr style="background: #f3f3f3;">
						<td><b>All Total</b></td>
						<td>{{getAllTotalAgents()}}</td>
						<td>{{getAllLoggedIn()}}</td>
						<td>{{getAllTotalLoggedIn()}}</td>
						<td>{{getAllLiveIn()}}</td>
						<td>{{getAllTotalLive()}}</td>
						<td>{{getAlldailpercall()| number : '1.1-1'}}</td>
					</tr>
				</tfoot>
			</table>
		</mat-card>
	</div>
</div>