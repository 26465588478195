import { Component, OnInit, HostListener } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { MyserviceService } from '../myservice.service';

@Component({
	selector: 'app-central-dasboard-dd-section-part',
	templateUrl: './central-dasboard-dd-section-part.component.html',
	styleUrls: ['./central-dasboard-dd-section-part.component.scss']
})

export class CentralDasboardDdSectionPartComponent implements OnInit {

	private subscription: Subscription = new Subscription();
	selectedRole: string;
	showHideDDlData: boolean = false;
	showHideArrow1: boolean = true;
	showHideArrow2: boolean = false;
	allComplete: boolean = true;
	dropDownList = [];
	rawDDLData;
	task = {
		name: 'All',
		completed: true
	};

	constructor(private service: MyserviceService) {

	}

	ngOnInit() {
		this.subscription.add(
			this.service.SELECTED_BI_ROLE.subscribe(role => {
				this.selectedRole = role;
				// console.log('selected role', this.selectedRole);
				this.changedSelectionList()
			})
		)
		this.subscription.add(
			this.service.ddlSelection.subscribe(ddlData => {
				this.rawDDLData = ddlData;
				this.changedSelectionList()
			})
		)
	}

	changedSelectionList(){
		if(this.selectedRole && this.rawDDLData){
			this.task.completed = true;
			let selectedList;
			switch(this.selectedRole){
				case 'ZSM':
					selectedList = this.rawDDLData.zsm;
					break;
				case 'RSM':
					selectedList = this.rawDDLData.rsm;
					break;
				case 'ASM':
					selectedList = this.rawDDLData.asm;
					break;
			}
			// console.log('selected list', selectedList);
			let listItems = []
			for(var key in selectedList){
				if(!selectedList[key]['visible']){
					this.task.completed = false;
				}
				listItems.push({
					name: selectedList[key]['name'],
					completed: selectedList[key]['visible'],
					id: key
				})
			}
			this.dropDownList = listItems;
			// console.log('after modify', listItems, this.task);
			this.updateAllCompleteStatus(true);
		}
	}


	updateAllComplete(ddItem) {
    debugger;
		console.log('input', ddItem, this.rawDDLData);
		if(this.rawDDLData){
			let role = this.selectedRole.toLowerCase();
			this.rawDDLData[role][ddItem.id]['visible'] = ddItem.completed;
			this.service.ddlSelection.next(this.rawDDLData);
		this.updateAllCompleteStatus(true);
    }
	}

	updateAllCompleteStatus(status){
		this.allComplete = this.dropDownList != null && this.dropDownList.every(t => t.status);
	}

	someComplete(): boolean {
		if (this.dropDownList == null) {
			return false;
		}
		return this.dropDownList.filter(t => t.completed).length > 0 && !this.allComplete;
	}

	setAll(completed: boolean) {
    //debugger
    let role = this.selectedRole.toLowerCase();
    console.log(completed);
		 this.allComplete = completed;
		if (this.dropDownList == null) {
			return;
		}
    if(completed)
    {
    this.dropDownList.forEach((t)=>{
      t.completed = true;
     this.updateAllComplete(t);
   });
    }
    else{
        console.log(JSON.stringify(this.dropDownList));
      this.dropDownList.forEach((t)=>{
         t.completed = false;
        // this.rawDDLData[role][t.id]['visible'] = false;
        this.updateAllComplete(t);
      });
      console.log(JSON.stringify(this.rawDDLData[role]));
      console.log(JSON.stringify(this.dropDownList));
      //this.updateAllCompleteStatus(completed);
    }
	}
	openDDL() {
		this.showHideArrow1 = false;
		this.showHideArrow2 = true;
		this.showHideDDlData = true;
	}
	closeDDL() {
		this.showHideArrow1 = true;
		this.showHideArrow2 = false;
		this.showHideDDlData = false;
	}
	leaveDDl() {
		this.closeDDL();
	}
	ngOnDestroy(){
		this.subscription.unsubscribe();
	}
}
