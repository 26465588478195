import { Component, OnDestroy, Inject } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { DOCUMENT ,Location,PlatformLocation,LocationStrategy} from '@angular/common'; 

import { MyserviceService } from './myservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
	title = 'contiinex | Remote Contact Center';
	constructor(private _service:MyserviceService, private router: Router, @Inject(DOCUMENT) document,location: PlatformLocation,) {
		// console.log('********* Activate App ***********');
		location.onPopState(() => {





		});
		this._service.APPACTIVATE.next(true);
		this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator

				let _obj = {
					"url": document.location.protocol + '//' + window.location.host + event.url
				}
				setTimeout(() => {
					try{
						window.contiinexUserUpdate(_obj);
					}catch(e){
						//console.error(e);
					}
				}, 2000)

				if (event.url != '/' && event.url != 'signin'){
					document.getElementById('Contiinex-widget').style.display = 'none';
				}else{
					document.getElementById('Contiinex-widget').style.display = 'block';
				}
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });
	}
    ngOnInit() {

      window.addEventListener('storage', (event) => {
          if (event.storageArea == localStorage) {
               let tokenLocal = localStorage.getItem('access_token');
               let tokenSession = sessionStorage.getItem('access_token');
               if(tokenLocal != tokenSession) { 
                  console.log("auto logout")
                 // Perform logout
                 //Navigate to login/home
                  this._service.endSession();
                  
               }
          }
      });

   }
	ngOnDestroy() {
		console.log('Destroying the pipe');		
	}	
	
}
declare const window: any;