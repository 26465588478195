
<mat-card class="CN-Card">
    <span (click)="refreshBtn()" class="refreshcs"><i class="fa fa-refresh" aria-hidden="true"></i></span>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <p class="chanel-text">Select A Channel</p>


            <carousel [ngClass]="'arrowAd1'" [height]="55" [arrowsOutside]="false" [autoplay]="false" [dots]="false"
                [loop]="false" style="text-align: center;padding: 0 45px;" id="myDIV">

                <div class="carousel-cell  btns " style=" padding: 7px 20px;"
                    *ngFor="let v of ChannelNameList; let i = index" (click)="setIndex(i,v)">
                    <span class="chanel-name" [class.activeChannel]="selectedIndex === i"> {{v.name}} </span>
                </div>
                <div class="carousel-cell btns"  style=" padding: 7px 20px;">
                    <span class="chanel-name " style="border: none;"> </span>
             </div>
            </carousel>

            <div *ngIf="showHideWhatsapp">
                <app-whatsapp-dashboard1></app-whatsapp-dashboard1>
              
            </div>
            <div *ngIf="showHideOutboundIVR">
                <app-channels-outbound-ivr></app-channels-outbound-ivr>
                </div>
                <div *ngIf="showHideAllChannels">
                    <app-all-channels-dashboard></app-all-channels-dashboard>
                </div>
                <div *ngIf="showHideOutbound">
                    <app-contiinex-outbound></app-contiinex-outbound>
                </div>
                
<div *ngIf="showHideInbound">
    <app-contiinex-inbound></app-contiinex-inbound>
</div>
<div *ngIf="showHideInboundIVR">
    <app-inbound-ivr></app-inbound-ivr>
    </div>

    <div *ngIf="showHideSMS">
        <app-contiinex-sms-dashboard></app-contiinex-sms-dashboard>
        </div>
        </div>
    </div>
</mat-card>
<div id="cover-spin" *ngIf="currentLoader"></div>