import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { ManagementTeamDialogComponent } from '../management-team-dialog/management-team-dialog.component';
import { AuditFormDialogComponent } from '../audit-form-dialog/audit-form-dialog.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { keys } from 'underscore';

@Component({
  selector: 'app-audit-forms',
  templateUrl: './audit-forms.component.html',
  styleUrls: ['./audit-forms.component.scss']
})
export class AuditFormsComponent implements OnInit {
  currentTitle = 'AuditForms';
	displayedColumns = ['sno','audit_form_name','cx_touch_point', 'normalized_score','download','options'];
	dataSource: any = new MatTableDataSource();
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	showHideOptionDDL: boolean = false;
	myUser: any;
	currentUrl: String;
  auditdata: string[]= [];
  _auditformdata: any;
	api_end_point:any;
	constructor(private dialog: MatDialog, public router: Router, private API: ApiService, private service: MyserviceService) { }

	ngOnInit() {
		this.api_end_point = this.service.get_API_End_Point();
		this.getAuditFormTable();
		this.myUser = this.service.get_loggedin_user();
		// console.log(this.myUser)
		this.currentUrl = this.router.url;
	}

	getAuditFormTable() {

      this.API.getAuditForm(this.api_end_point).subscribe((response: any) => {
       
          if (response.success) 
          {
			let filterdata = response.success;
			let _data = filterdata.filter(ab=>ab.isvisible === 1);
			//console.log(_data);
			this._auditformdata = _data;
           // this.dataSource = _data;
			this.dataSource =new MatTableDataSource(_data);
			this.dataSource.paginator = this.paginator;
			
          }
          else
          {
           //console.log('--2--'+response.error);
          }
      })
	}
  
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

  addForm() {
	 // console.log(this.API._selectClient)
	var obj={
		forNew:"add",
		audit_client_id:this.API._selectClient.id
	}
	let _element = {
		"_element": obj,
	}
	//'this.dataSource[0]'
	const dialogRef = this.dialog.open(AuditFormDialogComponent, {
		width: '30%',
		disableClose: true,
		data: _element
	});

	dialogRef.afterClosed().subscribe(result => {
		if (result && result.refresh) {
			this.getAuditFormTable();
		}
	});
}

	editForm(auditdata: any) {


		let _element = {
			"_element": auditdata,
		}
		const dialogRef = this.dialog.open(AuditFormDialogComponent, {
			width: '30%',
			disableClose: true,
			data: _element
		});

		dialogRef.afterClosed().subscribe(result => {
			//console.log(result)
			if (result && result.refresh) {
				this.getAuditFormTable();
			}

		});
	}

	downloadQuestionaire(element, index){
		// console.log(this.dataSource.data[index]);
		// console.log(JSON.parse(this.dataSource.data[index].audit_form_object))
		var obj = JSON.parse(this.dataSource.data[index].audit_form_object);
		let workbook = new Workbook(); 
		for (var sIndex=0; sIndex < obj.length; sIndex++) {
		  let worksheet = workbook.addWorksheet(obj[sIndex].category);
		  var headers = ['Questions', 'Answer Formats', 'Answers', 'Score', 'Legend'];
		  worksheet.addRow(headers);
		  for (var rw=0; rw < obj[sIndex].questions.length; rw++) {
			var rowValues = ['','','',''];
			//console.log(obj[sIndex].questions[rw])
			Object.keys(obj[sIndex].questions[rw]).map(function(key, index) {
			 // console.log(key,index,obj[sIndex].questions[rw][key])
			  if(key == 'a'){
				  
			  }else if(key == 'q'){
				rowValues[0] = (obj[sIndex].questions[rw][key].toString())
			  }else if(key == 'params_type'){
				rowValues[1] = 'Single Answer';
			  }else if(key == 'audit_params'){
				var answer_type = [];  
				for(let val of obj[sIndex].questions[rw][key]){
					answer_type.push(val.name)
				}  
				rowValues[2] = answer_type.toString();
			  }else if(key == 'weighted_score'){
				rowValues[3] = (obj[sIndex].questions[rw][key].toString())
			  }else if(key == 'legend'){
				rowValues[4] = (obj[sIndex].questions[rw][key].toString())
			  }
			  
			  
			});
			worksheet.addRow(rowValues);
		  }
		}
				workbook.xlsx.writeBuffer().then((data) => {
						let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
						fs.saveAs(blob, `audit-form-${element.audit_form_name}.xlsx`);
					})
		// let workbook = new Workbook();
			// 		let worksheet = workbook.addWorksheet('Candidate Data');
			// 		worksheet.addRow(headers);
			// 		data.forEach(d => {
			// 			let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
			// 			//	console.log('printing row', row);
			// 		})
			// 		//Generate & Save Excel File
			// 		workbook.xlsx.writeBuffer().then((data) => {
			// 			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			// 			fs.saveAs(blob, 'Recruitment.xlsx');
			// 		})
	  }
}
