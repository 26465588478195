import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'
import {MyserviceService} from '../../myservice.service';
import {ApiService} from "../../api.service";

@Component({
  selector: 'app-contiinex-shopify-billing',
  templateUrl: './contiinex-shopify-billing.component.html',
  styleUrls: ['./contiinex-shopify-billing.component.scss']
})
export class ContiinexShopifyBillingComponent implements OnInit {
  currentLoader:boolean=false;
  api_end_point:any;
  currentTitle = 'shopify-billing';
  currentUrl:any;
  constructor( private API: ApiService, private _service:MyserviceService,private router: Router) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
  }

}
