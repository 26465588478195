import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Utils } from '../utils';
import { MyserviceService } from 'src/app/myservice.service';
import { MatDialog } from '@angular/material/dialog';
import { PaymentDashboardApprovalEditDialogComponent } from '../payment-dashboard-approval-edit-dialog/payment-dashboard-approval-edit-dialog.component';
import { PaymentDashboardApprovalChatDialogComponent } from '../payment-dashboard-approval-chat-dialog/payment-dashboard-approval-chat-dialog.component';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import * as io from 'socket.io-client';
import { DomSanitizer } from '@angular/platform-browser';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { FormControl, Validators } from '@angular/forms';
import { createFalse } from 'typescript';
import { PaymentDashboardApprovalUploadfileDialogComponent } from '../payment-dashboard-approval-uploadfile-dialog/payment-dashboard-approval-uploadfile-dialog.component';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getCurrencySymbol } from '@angular/common';

@Component({
	selector: 'app-payment-dashboard-approval',
	templateUrl: './payment-dashboard-approval.component.html',
	styleUrls: ['./payment-dashboard-approval.component.scss']
})
export class PaymentDashboardApprovalComponent implements OnInit {

	displayedColumns: string[] = ['gig_id', 'user_id', 'name', 'email', 'pan_number', 'actual_earnings', 'total_deductions', 'total_earnings', 'total_payments', 'current_balance', 'last_payment_date', 'approval_amount', 'approval', 'editAmt', 'chat'];

	dataSource: any = new MatTableDataSource();
	showHide: boolean = true

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatTable) table: MatTable<any>;
	table_data: any = [];
	table_data_export: any = [];
	selectedCurYear: any;
	selectedMonth: any;
	isNoInternetPopup: boolean = false;
	mandatoryFiled: boolean = false;
	socketConnection: boolean = false;
	messages: any = [];
	selectedActive: any = -1;
	username: any = '';
	role: any = '';
	IsVisible: boolean = true;
	_dataTabName: any;
	_dataToId: any;
	filterChannel: any;
	txtmsg: any;
	myUser: any;
	groupList: any[] = [];
	channels: any = "";
	selectedChannel: any = "";
	allData: any = "";
	socket: any = "";
	selectedFile: any;
	msgType: string = "TEXT";
	socketId: any = "";
	socketStatusBeforeCheck: any = "";
	uniqueAgents: any[] = [];
	widgetUser: any;
	isTextComponent: boolean = false;
	searchmsgValue: any;
	currentNewUser: any = null;

	ApprovalAmountValue: any;

	showHideErrorMsg: boolean = false;
	currentLoader: boolean = false;
	selectedFilter: any = "All";
	rupesssymbol:any = true;
	searchFilter = '';

	_allData: any;
	_inProcessData: any;
	_approveddata: any;
	_approveData: any;
	currency_code:any;
	api_end_point:any;

	@ViewChild('searchInput', {
		read: MatInput
	}) searchInput: MatInput;
	_gplist:any=[];
	constructor(private _service: MyserviceService, public dialog: MatDialog, private API: ApiService, private utils: Utils, private _snackBar: MatSnackBar) {
		this.myUser = this._service.get_loggedin_user();
		//this.socket = io('https://devchat.youearn.in', { "transports": ["polling"] });
		//this.socket = io('https://devchat.contiinex.com', { "transports": ["polling"] });
	}

	ngOnInit() {
		this.api_end_point = this._service.get_API_End_Point();
		this.getCurrentMonthYear();
		this.getTableData();
		var interval_id = window.setInterval(() => { }, 99999);
		for (var i = 0; i < interval_id; i++) {
			window.clearInterval(i);
		}
		this.groupList = [];
		this.subscribe();
		//this.initSocket()
		if (window.location.pathname.includes('text')) {
			this.isTextComponent = true;
		}
	}

	getCurrentMonthYear() {
		let _aboutToday = new Date();
		// let _fullDate = _aboutToday.getUTCDate();
		let _getNowMonth = _aboutToday.toLocaleString('default', { month: 'long' });
		let _getCurrentMonth = _getNowMonth.substring(0, 3)
		let _getCurrentYear = _aboutToday.getFullYear();
		this.selectedMonth = _getCurrentMonth;
		this.selectedCurYear = _getCurrentYear.toString();
	}
	getTableData() {
		this.currentLoader = true;
		let obj = "";
		this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
				// console.log(data.errors);
				return;
			}
			if (data.success) {
				// console.log(data.success);
				var setting = data.success;

				for (const currencysetting of setting.payment) {
					if (currencysetting.name == "currency_code") {
						// console.log("currency Symbol is ",currencysetting.definition);
					this.currency_code= currencysetting.definition;
					} 
				}
			}
		})
		this.API.getPaymentdashboardDetails(obj,this.api_end_point).subscribe((data: any) => {
			
			let _response = data.success;
			// console.log(_response);
			this.currentLoader = false;
			let arr = [];
			if (_response) {
				for (var key in _response) {
					if (_response.hasOwnProperty(key)) {
						_response[key]['changingBalance'] = _response[key]['current_balance'];
						if(_response[key]['last_payment_date']){
							_response[key]['last_payment_date']=moment(_response[key]['last_payment_date']).local().format('MMM Do YYYY, h:mm:ss A');
						}
						arr.push(_response[key]);
					}
				}
			}
			this.table_data = arr;
			this.refreshTable();
			// this.dataSource = new MatTableDataSource(this.table_data);
			
		});

	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	editAmountDialog(element) {
		const dialogRef = this.dialog.open(PaymentDashboardApprovalEditDialogComponent, {
			width: '55%',
			height: '70%',
			disableClose: true,
			data: element
		});
		dialogRef.afterClosed().subscribe(result => {
			// console.log("Dialog is closed", result);
			if (result) {
				this.changeFilter('All');
			}
		});
	}
	approvalChatBtn() {
		this.showHide = true;
	}
	closeChat() {
		this.showHide = false;
	}
	subscribe() {
		var _obj = {
			"username": this.myUser.name,
			"userid": this.myUser.id,
			"client": "WEB"
		};
		this.API.subscribe(_obj)
			.subscribe(
				(response: any) => {
					if (response && response.message === "Subscription Done") {
						this.channels = response.channels;
						this.allData = response;
						this.extractGroupList(response.channels)

					}
				});
	}

	extractGroupList(data: any) {

		// var uniqueAgents:any [] = [];
		for (let key in data) {
			// console.log(key)
			if (key == 'PM') {
				this.selectedChannel = this.channels[key];
			}
		}
		let value = data['PM'];
		if (value.messages && value.messages.length) {
			for (let msgKey in value.messages) {
				var msg = value.messages[msgKey];
				const agentIndex = this.uniqueAgents.indexOf(msg.from_id);
				const agentIndex2 = this.uniqueAgents.indexOf(msg.to_id);

				if ((msg["channel_type"] && msg["channel_type"] == "WHATSAPP") || msg.channel == "CUSTOMER_CHANNEL") {
					// console.log(msg.message, msg.decrypt)
					if (msg.channel == "CUSTOMER_CHANNEL" && !msg.decrypt) {
						msg.message = decodeURIComponent(escape(window.atob(msg.message)))
					} else {
						msg.message = msg.message;
					}


				} else {
					msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
				}
				// console.log("Entry -- ", msg, agentIndex, agentIndex2)
				// msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
				msg.decrypt = true;
				if (this.isTextComponent) {
					if (msg.channel_type == 'SMS') {
						if (this.myUser.id != msg.from_id) {
							this.appendMessageToGroup('PM', msg, agentIndex)
						} else {
							this.appendMessageToGroup('PM', msg, agentIndex2)
						}
					}

				} else {
					// if(this.myUser.id != msg.from_id){
					//   this.appendMessageToGroup('PM',msg,agentIndex, msg[msg.to_id+"_seen"] ? false : true)
					// }else if(this.myUser.id != msg.from_id){
					//   this.appendMessageToGroup('PM',msg,agentIndex2, false)
					// }

					if (this.myUser.id == msg.from_id) {
						this.appendMessageToGroup('PM', msg, agentIndex2, msg[msg.to_id + "_seen"] ? false : true)
					} else {
						this.appendMessageToGroup('PM', msg, agentIndex, msg[msg.to_id + "_seen"] ? false : true)
					}
				}


			}
			this.sortByTime();
		}

		// console.log(this.uniqueAgents)
		// console.log(this.groupList)
		// console.log(this.selectedChannel.channel)
		this.setupSocketConnection(this.selectedChannel.channel);

	}

	initSocket() {

		this.socket.on('connect', () => {
			// console.log(this.socket);
			this.socketId = this.socket.id;

			this.socket.emit("SOCKET_CONNECTION", {
				userid: this.myUser.id,
				socketid: this.socketId
			});

		});
		// console.log(this.socket.id)
		//   this.socket.emit("SOCKET_CONNECTION", {
		//     userid: this.myUser.id,
		//     socketid: this.socket.id
		// });
		this.socket.on("TEST_CONNECTION", (msg: any) => {
			// console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
			this.socketStatusBeforeCheck = msg.status;
			// console.log(this.socketStatusBeforeCheck);
		});
		this.username = this.myUser.name;
		this.role = this.myUser.role;

	}

	fetchUser(phone) {
		// this.widgetUser = ""
		this.API.getWidgetUserByPhone(phone,this.api_end_point).subscribe((data: any) => {
			let _response = data;
			if (_response.success) {
				if (!this.widgetUser) {
					this.widgetUser = _response.success;
				} else if (_response.success.external_url != this.widgetUser.external_url || _response.success.state != this.widgetUser.state) {
					this.widgetUser.external_url = _response.success.external_url;
					this.widgetUser.state = _response.success.state;
					this.counter = 0;
				}

			}
			else {

			}
		});
	}

	counter: any = 0;

	openAndFindAgentInChat(user) {
		if (this.showHide == false) {
			this.showHide = true;
		}
		// console.log(this.groupList, user.user_id)
		for (var i = 0; i < this.groupList.length; i++) {
			if (this.groupList[i].id == user.user_id) {
				this.tabkeyMenu('', 0);
				// console.log("found")
				return;
			}
		}
		// console.log("not found")
		this.sendFirstMessage(user)
		this.currentNewUser = 0;

	}

	sendFirstMessage(user) {
		var obj = {
			"type": "CHAT_MESSAGE",
			message_type: "TEXT",
			channel: this.selectedChannel.channel,
			groupid: this.myUser.groupid,
			from_id: this.myUser.id,
			to_id: user.user_id,
			user: this.myUser.name,
			phone: user.name,
			message: this.encryptMessage(this.myUser.id, this.allData.groupid, "Hi"),//encodeURIComponent("Hi Merchant")
			session_id: "",

		}
		this.API.message(obj)
			.subscribe(
				(response: any) => {
					// console.log(response);
				})
	}

	sendMessage_With_Enter(messageToSend: any) {
		this.mandatoryFiled = false;
		if (this.txtmsg == "" || messageToSend == undefined) {
			this.mandatoryFiled = true;
			return;
		}
		// console.log("You entered: ", messageToSend);
		let msg = {
			type: "CHAT_MESSAGE",
			message_type: this.msgType,
			channel: this.selectedChannel.channel,
			groupid: this.myUser.groupid,
			from_id: this.myUser.id,
			to_id: this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length && this.groupList[this.selectedActive].messages[0].from_id != this.myUser.id ? this.groupList[this.selectedActive].messages[0].from_id : this.groupList[this.selectedActive].messages[0].to_id,
			user: this.myUser.name,
			phone: this.myUser.phone,
			session_id: "",
			// message: mesg //.trim()
			// message: $scope.encryptMessage($scope.userid, $scope.groupid, mesg)
		} as any;
		//debugger;
		if (this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length && this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length - 1].session_id) {
			msg["session_id"] = this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length - 1].session_id;
		}
		if (this.groupList[this.selectedActive].messages[0].channel_type == "WHATSAPP" && msg.to_id.length == 10) {
			msg["channel_type"] = "WHATSAPP";
		}
		if ((this.groupList[this.selectedActive].messages[0].channel_type && this.groupList[this.selectedActive].messages[0].channel_type == "WHATSAPP") || msg.channel == "CUSTOMER_CHANNEL") {
			msg["message"] = btoa(unescape(encodeURIComponent(messageToSend)));
		} else {
			msg["message"] = this.encryptMessage(this.myUser.id, this.allData.groupid, messageToSend);
		}
		if (this.groupList[this.selectedActive].messages[0].channel_type == 'SMS') {
			msg['channel_type'] = 'SMS';
		}
		// msg["message"] = this.encryptMessage(this.myUser.id, this.allData.groupid, messageToSend);

		this.API.message(msg)
			.subscribe(
				(response: any) => {
					// console.log(response);
					this.txtmsg = "";
					//     var obj = {} as any;
					//     obj.message = this.txtmsg;
					//     obj.date = new Date();
					//     obj.from_id = this.myUser.id;
					//     obj.user = this.myUser.name;
					//     obj.message_type = this.msgType;
					//     obj.session_id = msg.session_id;
					//     obj.message_time = (new Date()).toLocaleTimeString([], {
					//       hour: '2-digit',
					//       minute: '2-digit'
					//     });
					//     this.groupList[this.selectedActive].last_message = obj.message_type == 'TEXT' ? obj.message : obj.message_type == 'AUDIO'?"AUDIO":"IMAGE";
					// this.groupList[this.selectedActive].last_message_time = (new Date(obj.date)).toLocaleTimeString([], {
					//   hour: '2-digit',
					//   minute: '2-digit'
					// });
					// this.groupList[this.selectedActive].lmt = this.groupList[this.selectedActive].last_message_time.replace(':','');
					//     this.groupList[this.selectedActive].messages.push(obj)
					//     this.txtmsg = "";
					//     this.scrollToBottom();
					//     this.mandatoryFiled=false;
					//     if (!msg[this.myUser.id + "_delivered"]) {
					//       console.log("delivering")
					//       this.sendMessageAcknowledgment(msg);
					//     }
				});
	}

	setupSocketConnection(channel: any) {

		// this.socket.emit('my message', 'Hello there from Angular.');
		// console.log(channel);

		this.socket.on(channel, (msg: any) => {
			// console.log(msg);

			if ((msg["channel_type"] && msg["channel_type"] == "WHATSAPP") || msg.channel == "CUSTOMER_CHANNEL") {
				if (msg.channel == "CUSTOMER_CHANNEL") {
					msg.message = decodeURIComponent(escape(window.atob(msg.message)));
				} else {
					msg.message = msg.message;
				}

			} else {
				msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
			}

			// msg.message = this.decryptMessage(msg.from_id, msg.groupid, msg.message);
			msg.decrypt = true;
			var agentIndex = -1;

			if (msg.from_id != this.myUser.id) {
				agentIndex = this.groupList.findIndex(group => group.id == msg.from_id);
				this.appendMessageToGroup("TL", msg, agentIndex, true, true)
				//append to table
				if (this.showHide == false) {
					this.showHide = true;
				}
			} else {

				agentIndex = this.groupList.findIndex(group => group.id == msg.to_id);
				this.appendMessageToGroup("TL", msg, agentIndex, true, true);

			}

			this.sortByTime();
			if (this.currentNewUser == 0 && this.currentNewUser != null) {
				this.tabkeyMenu('', this.currentNewUser)
			}
			// if(this.selectedActive == 0){
			//   this.groupList[this.selectedActive].messages.push(msg);
			// }

			setTimeout(() => {
				this.scrollToBottom();
			}, 500)
		});
	}

	appendMessageToGroup(key: any, msg: any, agentIndex: any, checkUnread: any = "", fromSocket: any = false) {
		if (agentIndex == -1) {
			this.uniqueAgents.push(msg.from_id == this.myUser.id ? msg.to_id : msg.from_id);
			// console.log("inserting")

			var group = {
				key,
				id: msg.from_id == this.myUser.id ? msg.to_id : msg.from_id,
				tab_name: msg.user == this.myUser.name ? msg.phone : msg.user,
				last_message: '',
				last_message_time: '11:11',
				lmt: '1111',
				unread_messages: 0,
				messages: []
			} as any;

			group.last_message = msg.message_type == 'TEXT' ? msg.message : msg.message_type == 'AUDIO' ? "AUDIO" : "IMAGE";
			group.last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
				hour: '2-digit',
				minute: '2-digit'
			});
			group.lmt = new Date(msg.date).getTime();
			if (checkUnread) {
				if (this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id) {
				} else {
					if (msg.from_id != this.myUser.id) {
						group.unread_messages = group.unread_messages + 1;
					}

				}
			}

			group.messages.push(msg)
			this.groupList.push(group);
			this._gplist=this.groupList;//added in one variable..need
		} else {
			this.groupList[agentIndex].last_message = msg.message_type == 'TEXT' ? msg.message : msg.message_type == 'AUDIO' ? "AUDIO" : "IMAGE";
			this.groupList[agentIndex].last_message_time = (new Date(msg.date)).toLocaleTimeString([], {
				hour: '2-digit',
				minute: '2-digit'
			});
			this.groupList[agentIndex].lmt = new Date(msg.date).getTime();
			if (checkUnread) {
				if (this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id) {
				} else {
					if (msg.from_id != this.myUser.id) {
						this.groupList[agentIndex].unread_messages = this.groupList[agentIndex].unread_messages + 1;
					}

				}
			}

			this.groupList[agentIndex].messages.push(msg);
		}
		if (!msg[this.myUser.id + "_seen"] && this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id) {
			// console.log("confirming")
			this.sendMessageSeenConfirmation(msg);
		}
		if (!msg[this.myUser.id + "_delivered"]) {
			// console.log("delivering")
			this.sendMessageAcknowledgment(msg);
		}




	}

	sortByTime = () => {
		//  this.groupList = this.groupList.sort(function(a,b){return b.lmt - a.lmt});
		var prevSelected: any;
		if (this.selectedActive != -1) {
			prevSelected = this.groupList[this.selectedActive];
		}

		// console.log(this.groupList)
		this.groupList = _.sortBy(this.groupList, function (o) { return -o.lmt; })
			;
		if (this.selectedActive != -1) {
			this.selectedActive = _.findIndex(this.groupList, function (obj) { return obj.id == prevSelected.id })
		}

		// console.log(this.groupList.sort(function(a,b){console.log(a); return b.lmt - a.lmt}));
	}

	startSession() {
		let msg = {
			type: "SESSION_STARTS",
			message_type: this.msgType,
			channel: this.selectedChannel.channel,
			groupid: this.myUser.groupid,
			from_id: this.myUser.id,
			to_id: this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length ? this.groupList[this.selectedActive].messages[0].from_id : this.myUser.groupid,
			user: this.myUser.name,
			username: this.myUser.name,
			phone: this.myUser.phone,
			session_id: "",
			message: "",
		} as any;
		this.API.message(msg)
			.subscribe(
				(response: any) => {


				});
	}

	endSession() {
		let msg = {
			type: "SESSION_ENDS",
			message_type: this.msgType,
			channel: "CUSTOMER_CHANNEL",
			groupid: this.myUser.groupid,
			from_id: this.myUser.id,
			to_id: this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length ? this.groupList[this.selectedActive].messages[0].from_id : this.myUser.groupid,
			user: this.myUser.name,
			username: this.myUser.name,
			phone: this.myUser.phone,
			session_id: "",
			message: "",
		} as any;
		if (this.groupList[this.selectedActive].messages && this.groupList[this.selectedActive].messages.length && this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length - 1].session_id) {
			msg["session_id"] = this.groupList[this.selectedActive].messages[this.groupList[this.selectedActive].messages.length - 1].session_id;
		}
		this.API.message(msg)
			.subscribe(
				(response: any) => {
				//	alert("Session ended")
					this._snackBar.open('Session ended', 'close', {
						duration: 5000,
					});

				});
	}

	sendMessageSeenConfirmation(message: any) {
		this.socket.emit("MESSAGE_SEEN", {
			id: message.id,
			session_id: message.session_id,
			channel: message.channel,
			userid: this.myUser.id
		});
	}

	sendMessageAcknowledgment(message: any) {
		this.socket.emit("MESSAGE_DELIVERY", {
			id: message.id,
			session_id: message.session_id,
			channel: message.channel,
			userid: this.myUser.id
		});
	}

	generateAESKey(value: string = "", type: string = "") {
		// return CryptoJS.enc.Base64.parse("SMB_CHAT_SERVICE_"+inputValue);
		if (type == "KEY") {
			return CryptoJS.enc.Base64.parse('u/Gu5posvwDsXUnV5Zaq4g==');
		} else {
			return CryptoJS.enc.Base64.parse('5D9r9ZVzEYYgha93/aUK2w==');
		}
	}

	encryptMessage(senderId: any, groupId: any, plainText: any) {
		var key = this.generateAESKey(senderId, "KEY");
		var iv = this.generateAESKey(groupId, "IV");
		let encryptedMessage = CryptoJS.AES.encrypt(
			plainText,
			key, {
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
			iv: iv
		}
		);
		let cipherText = encryptedMessage.toString();
		return cipherText;
	}
	decryptMessage(senderId: any, groupId: any, cipherText: string) {
		var key = this.generateAESKey(senderId, "KEY");
		var iv = this.generateAESKey(groupId, "IV");
		var decodeCipherText = CryptoJS.enc.Base64.parse(cipherText);
		// console.log("decodeCipherText : ", decodeCipherText);
		let decryptedMessage = CryptoJS.AES.decrypt({
			ciphertext: decodeCipherText
		} as any,
			key, {
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
			iv: iv
		}
		);
		// console.log("decryptedMessage : ", decryptedMessage);
		let plainText = CryptoJS.enc.Utf8.stringify(decryptedMessage);
		// console.log("plainText : ", plainText);
		return plainText;
	}

	tabkeyMenu(key: any, index: any) {
		if (this.currentNewUser == 0) {
			this.currentNewUser = null;
		}
		this.counter = 0;
		this.selectedActive = index;
		this._dataTabName = this.groupList[index].tab_name;
		this.groupList[index].unread_messages = 0;
		// console.log(this.groupList[index])
		for (let msg of this.groupList[this.selectedActive].messages) {
			if (!msg[this.myUser.id + "_seen"] && this.selectedActive != -1 && this.groupList[this.selectedActive].id == msg.from_id) {
				// console.log("confirming")
				this.sendMessageSeenConfirmation(msg);
			}
		}
		setTimeout(() => {
			this.scrollToBottom();
		}, 500)
		// this.startSession();
	}


	scrollToBottom() {
		let messageBody: any = document.querySelector('#messageBody');
		messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
	}
	currentImageClick(index: any) {
		var img: any = document.getElementById("imgId" + index);
		var modal: any = document.getElementById("myModal");
		var modalImg: any = document.getElementById("img01");
		modal.style.display = "block";
		modalImg.src = img.src;
	}

	closeLarge() {
		var modal: any = document.getElementById("myModal");
		modal.style.display = "none";
	}
	uploadFile(event: any) {

	}
	onTextMessage() {
		if (this.txtmsg.length > 0) {
			this.mandatoryFiled = false;
		}
		else {
			// this.mandatoryFiled=true;
		}
	}
	closeDialog() {
		// this.dialogRef.close();
	}
	refreshTable() {
		// console.log('table refreshed');
		const tempData = JSON.parse(JSON.stringify(this.table_data));
		// console.log('selected Filter', this.searchFilter, this.selectedFilter);
		// console.log('tempData', tempData);
		if (this.selectedFilter == "All") {
			this._allData = tempData;
			this.dataSource = new MatTableDataSource(this._allData);
		}
		else if (this.selectedFilter == "Approve") {
			this._approveData = tempData.filter(v => { return (v.approval_request_status == null) || (v.approval_request_status == '') });
			// this.table_data_export=this._approveData;
			this.dataSource = new MatTableDataSource(this._approveData);
			
		}
		else if (this.selectedFilter == "In process") {
			this._inProcessData = tempData.filter(v => { return v.approval_request_status == "IN PROCESS" });
			// this.table_data_export = this._inProcessData;
			// console.log('inprocess data', this._inProcessData);
			this.dataSource = new MatTableDataSource(this._inProcessData);
			
		}
		else if (this.selectedFilter == "Approved") {
			this._approveddata = tempData.filter(v => { return v.approval_request_status == "APPROVED" });
			// this.table_data_export=this._approveddata;
			this.dataSource = new MatTableDataSource(this._approveddata);
			
		} else {
			this.dataSource = new MatTableDataSource(tempData);
		}
		this.dataSource.paginator = this.paginator;
	}
	approveBtn(element: any, index) {
		this.showHideErrorMsg = false;
		element.approval_progress = true;
		// console.log('index', index);
		if (element.changingBalance > element.current_balance) {
			this.showHideErrorMsg = true;
			setTimeout(() => {
				this.showHideErrorMsg = false;
				element.approval_progress = false;
			}, 6000)
			return;
		}

		let _approval_amount = element.changingBalance;
		let _obj = {
			"for_user": element.user_id,
			"approval_amount": _approval_amount
		}
		
		// return;
		this.API.paymentRequestToUser(_obj,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				element['approval_request_status'] = data.success['approval_request'];
				element['approval_amount'] = data.success['approved_amount'];
				this._snackBar.open('Success.!!', 'close', {
					duration: 5000,
				});
			}
			// this.refreshTable();
		});
	}

	refreshBtn() {
		this.currentLoader = true;
		this.getTableData();
		this.searchInput.value = '';
		this.searchFilter = '';
	}
	exportTable() {
		let data = this.dataSource.filteredData;
		if (!data || !data.length) {
			this._snackBar.open('No data found for file download', 'close', {
				duration: 4000,
			});
			return;
		}
		 /* if(this.dataSource.data != null || this.dataSource.data.length !== 0 ){
			data = this.dataSource.data;
			console.log('printing data--1--', data);
		}
		else if(this.dataSource.data= null || this.dataSource.data.length== 0)
		{
			data = this.dataSource.data;
			console.log('printing data--2-', data);		
		}
		else{
			//alert('No data found for file download');
			this._snackBar.open('No data found for file download', 'close', {
				duration: 4000,
			});
			return;
		} */
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Approval Report');
		worksheet.addRow(['Gig ID', 'Agent ID', 'Agent Name', 'Agent Email', 'Pan Number','Cost Code', 'Gross Earnings', 'Tax Deductions', 'Net Earnings', 'Total Payments', 'Current Balance', 'Approval Amount', 'Approval Request Date', 'Approval Status', 'Recent Payment', 'Payment Date', 'Paid Amount']);

		let table_keys = ['gig_id', 'user_id', 'name', 'email','pan_number','companycode', 'actual_earnings', 'total_deductions', 'total_earnings', 'total_payments', 'current_balance', 'approval_amount', 'approval_request_date', 'approval_request_status', 'last_payment', 'last_payment_date', 'xxxxx'];

		for (var k in data) {
			let arr = [];
			const loc_obj = data[k];
			for (var i in table_keys) {
				const key = table_keys[i];
				if (loc_obj.hasOwnProperty(key)) {
					if (key == 'approval_request_date') {
						if (loc_obj.hasOwnProperty(key)) {
							arr.push(this.utils.utcToLocal(loc_obj[key], 'YYYY-MM-DDTHH:mm:ss', 'll'))
						}
					} else {
						arr.push(loc_obj[key]);
					}
				} else {
					arr.push('');
				}
			}
			worksheet.addRow(arr);
		}
		workbook.xlsx.writeBuffer().then((buff_data) => {
			let blob = new Blob([buff_data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, 'ApprovalData.xlsx');
		})
	}

	extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {

				if (ref[i] == 'Customer ID' && obj['Customer ID']) {
					// console.log(ref[i], obj)
					arr.push(obj['Customer ID'])
				} else {
					arr.push(obj[ref[i]])
				}
			} else {
				arr.push('')
			}
		}
		return arr;
	}

	valueInputChange(approvalValue: any, curBalValue: any) {
		this.showHideErrorMsg = false;
		if (approvalValue > curBalValue) {
			this.showHideErrorMsg = true;
			setTimeout(() => {
				this.showHideErrorMsg = false;
			}, 6000)
			return;
		}
		else {
			this.showHideErrorMsg = false;
			return;
		}
	}
	uploadExcelBtn() {
		const dialogRef = this.dialog.open(PaymentDashboardApprovalUploadfileDialogComponent, {
			width: '35%',
			height: '35%',
			disableClose: true,
			data: ''
		});
		dialogRef.afterClosed().subscribe(result => {
			// console.log("Dialog is closed", result);
			this.refreshBtn();
		});
	}
	changeFilter(val: any) {
		this.searchInput.value = '';
		this.searchFilter = null;
		// this.currentLoader = true;
		this.selectedFilter = val;
		// this.refreshTable();
		this.getTableData();
	}
	approvalAllBtn() {
		// debugger;
		let _arrayObj: any = [];
		let obj: any = this.dataSource.data.filter(v => { return ((v.approval_request_status == '') || (v.approval_request_status == null)) && v.changingBalance > 0 });

		for (let i = 0; i < obj.length; i++) {
			_arrayObj.push({
				"id": obj[i].user_id,
				"amount": obj[i].changingBalance
			})
		}
		if (_arrayObj.length) {
			let _obj = {
				"user_set": _arrayObj
			}
			this.API.approveAllApi(_obj,this.api_end_point).subscribe((data: any) => {
				if (data.success) {
					this._snackBar.open(data.success, 'close', {
						duration: 5000,
					});
					this.changeFilter('All');
				}
	
			});
		} else {
			this._snackBar.open("No agent found for approval request.", 'close', {
				duration: 5000,
			});
		}

	}
	filterSearch(val:any){
		this.groupList=this._gplist;
		let matches = [];
		  for (let i = 0; i < this.groupList.length; i++) {
			if (this.groupList[i].tab_name.match(val)) {
			  matches.push(this.groupList[i]);
			}
		  }
		  if(matches.length>0){
			this.groupList=matches;
		  }
		  else{
			this.groupList=[];
			this.groupList=this._gplist;
		  }
	}
}

