import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import { APIRESPONSE } from 'src/app/interface';
import { ApiService } from 'src/app/api.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-new-contiinex-footer-section-page',
  templateUrl: './new-contiinex-footer-section-page.component.html',
  styleUrls: ['./new-contiinex-footer-section-page.component.scss']
})
export class NewContiinexFooterSectionPageComponent implements OnInit {

  constructor(private router:Router, private location:Location,private API:ApiService,protected $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
  }
  backToTopBtn(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  }
  linkingBtn(type:any){
    //let _objLinkContactus="otherLink";
if(type=="EAuto"){
  this.$gaService.event('Clicked Ecommerce Automation', 'Footer Page');
}
if(type=="OSA"){
  this.$gaService.event('Clicked Outbound Sales Automation', 'Footer Page');
}
if(type=="VRS"){
  this.$gaService.event('Clicked Virtual Relationship Solution', 'Footer Page');
}
if(type=="CBWBVB"){
  this.$gaService.event('Clicked Conversational Bot, WA & Voice Bots', 'Footer Page');
}
if(type=="PFB"){
  this.$gaService.event('Clicked Process Flow Builder', 'Footer Page');
}
if(type=="MTECH"){
  this.$gaService.event('Clicked MarTech', 'Footer Page');
}
if(type=="EEnable"){
  this.$gaService.event('Clicked Ecommerce Enablers', 'Footer Page');
}
if(type=="MPlace"){
  this.$gaService.event('Clicked Marketplaces', 'Footer Page');
}
if(type=="CCenter"){
  this.$gaService.event('Clicked Contact Centers', 'Footer Page');
}
if(type=="CRMLMS"){
  this.$gaService.event('Clicked CRM & LMS companies', 'Footer Page');
}
if(type=="OurTeam"){
  this.$gaService.event('Clicked Our Team ', 'Footer Page');
}
if(type=="OurSuccess"){
  this.$gaService.event('Clicked Our Success', 'Footer Page');
}
if(type=="CBots"){
  this.$gaService.event('Clicked Conversational Bots', 'Footer Page');
}
if(type=="CallCenter"){
  this.$gaService.event('Clicked Call center Automation', 'Footer Page');
}
if(type=="Speech"){
  this.$gaService.event('Clicked Speech', 'Footer Page');
}
   
  this.router.navigateByUrl('/Contact');
window.scrollTo({
  top: 0,
  left: 0,
  behavior: 'smooth'
});
  }
  linkingBtnContactus(){
    this.router.navigateByUrl('/Contact');


    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.$gaService.event('Clicked Contact us', 'Footer Page');
  }

  linkingBtnWaba(){
    this.router.navigateByUrl('/WhatsApp-Business-Api');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.$gaService.event('Clicked WhatsApp Business Api', 'Footer Page');
  }

  clickPrivacyPolicy(){
    this.router.navigateByUrl('/Privacy-Policy');
  }

  linkingBtnProduct(type:any){
    if(type=="comnexExperience"){     
       this.$gaService.event('Clicked Quality Assurance Automation', 'Footer Page');
       this.router.navigateByUrl('/Quality-Assurance-Automation');
    }
    if(type=="comnexEngage"){     
      this.$gaService.event('Clicked Unified Communication as a Service ', 'Footer Page');
      this.router.navigateByUrl('/Unified-Communication-as-a-Service');
   }
   if(type=="comnexEnable"){   
    this.$gaService.event('Clicked Comnex Enable', 'Footer Page');
    this.router.navigateByUrl('/Comnex-Enable');
 }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
}
linkingBtnHome(){
   this.router.navigateByUrl('/');
window.scrollTo({
top: 0,
left: 0,
behavior: 'smooth'
});
this.$gaService.event('Clicked Contiinex', 'Footer Page');


}
linkingCompanyBtn(type:any){
  this.router.navigateByUrl('/Company');
  window.scrollTo({
  top: 0,
  left: 0,
  behavior: 'smooth'
  });
  this.$gaService.event('Clicked AboutUs', 'Footer Page');
  
}
}
