<div class="text-center">
    <p class="msgerrorcs" [ngStyle]="{'color':msgColor}" *ngIf="showHideMsg">{{showHideErrorMsg}}</p>
    <p class="mb-0"> <mat-icon style="color:gray">delete</mat-icon></p>
    <p class="mb-2"> DELETE</p>
    <p style="font-family:poppins;font-size: 15px;color: #a5a5a5;"> Are you sure want to delete this agent ?</p>
    <div class="row mt-4">
        <div class="col-md-12 text-center">
            <button class="prevAdd mt-0 ml-3" style="color:gray !important ;border:1px solid gray !important;background: #fff !important;" 
            mat-button (click)="closePopup()">
                CANCEL
            </button>
    
            <button class="prevAdd mt-0 ml-3"  (click)="deleteBtn()" mat-button>
               DELETE
            </button>
        </div>
    </div>
</div>
