import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';

export interface recruitDashboardSummaryChild {
	'total': number,
	'selected': number,
	'rejected': number
}
export interface recruitDashboardSummary {
	'l1_data': recruitDashboardSummaryChild,
	'l2_data': recruitDashboardSummaryChild,
	'client_data': recruitDashboardSummaryChild,
	'training': recruitDashboardSummary
}

@Injectable({
	providedIn: 'root'
})
export class ServicesService {

	constructor(
		private API: ApiService,
		private service: MyserviceService
	) { }
	private campaignData = new BehaviorSubject<any>(null);
	private recruitData = new BehaviorSubject<any>(null);
	public myCampaigns = this.campaignData.asObservable();
	public applicantData = this.recruitData.asObservable();
	private api_end_point = this.service.get_API_End_Point();
	private dashSummary = new BehaviorSubject<any>({
		'l1_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'l2_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'client_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'training': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		allData: []
	})
	public SELECTED_CAMPAIGN = new BehaviorSubject<any>(null)
	public SEARCH_CHANGE = new BehaviorSubject<any>({});
	public dash_search = this.SEARCH_CHANGE.asObservable();

	public campaignSummary_default() {
		return {
			'summary': {
				'total': 0,
				'data_tocall': 0,
				'data_called': 0,
				'data_contacted': 0,
				'data_selected': 0,
				'data_rejected': 0,
				'data_rejected_perc': 0,
				'data_selected_perc': 0,
				'total_perc': 100.0,
				'data_called_perc': 0,
				'data_contacted_perc': 0,
				'data_selected_l1': 0,
				'data_rejected_l1': 0,
				'data_rejected_l1_perc': 0,
				'data_selected_l1_perc': 0,
				'data_selected_l2': 0,
				'data_rejected_l2': 0,
				'data_rejected_l2_perc': 0,
				'data_selected_l2_perc': 0,
				'data_selected_client': 0,
				'data_rejected_client': 0,
				'data_rejected_client_perc': 0,
				'data_selected_client_perc': 0,
			},
			'campaigns': {
				'total': 0,
				'active': 0,
				'inactive': 0,
			},
			'campaigns_active': {
				'total': 0,
				'data_tocall': 0,
				'data_called': 0,
				'data_contacted': 0,
				'data_selected': 0,
				'data_rejected': 0,
				'data_rejected_perc': 0,
				'data_selected_perc': 0,
				'total_perc': 100.0,
				'data_called_perc': 0,
				'data_contacted_perc': 0,
			},
			'campaigns_inactive': {
				'total': 0,
				'data_tocall': 0,
				'data_called': 0,
				'data_contacted': 0,
				'data_selected': 0,
				'data_rejected': 0,
				'data_rejected_perc': 0,
				'data_selected_perc': 0,
				'total_perc': 100.0,
				'data_called_perc': 0,
				'data_contacted_perc': 0,
			},
			'today_campaigns': {
				'total': 0,
				'active': 0,
				'inactive': 0
			},
			'agencies': {
				'organic': 0,
				'online': 0,
				'offline': 0
			},
			'agency_name': {
				'Vahan': 0,
				'Workex': 0,
				'Capricco': 0,
				'Others': 0,
				'Vahan_Perc': 0,
				'Workex_Perc': 0,
				'Capricco_Perc': 0,
				'Cii':0,
				'CII_Perc':0,
				'AspiringMinds':0,
				'AspiringMinds_Perc':0,
				'Others_Perc': 0
			},
			allData: []
		} as any
	}

	private campaignSummary = new BehaviorSubject<any>(this.campaignSummary_default());
	public dashSummaryData = this.dashSummary.asObservable();
	public campaignSummaryData = this.campaignSummary.asObservable();

	/* Project Manager Dashboard Service Variables starts */
	private ASSESSMENT_DASH_DATA = new BehaviorSubject<any>(null);
	public assessment_dash_data = this.ASSESSMENT_DASH_DATA.asObservable();
	private PM_DASH_DATA = new BehaviorSubject<any>(null);
	public pm_dash_data = this.PM_DASH_DATA.asObservable();
	private PM_DASH_INBOUND_DATA = new BehaviorSubject([]);
	public pm_dash_inbound_data = this.PM_DASH_INBOUND_DATA.asObservable();
	private PM_DASH_SLOT_DATA = new BehaviorSubject<any>(null);
	public pm_dash_slot_data = this.PM_DASH_SLOT_DATA.asObservable();
	/*  Project Manager Dashbord Service variabales ends */

	/* AGENTS Summary table data */
	private AGENTS_TABLE_DATA = new BehaviorSubject(null);
	public agents_table_data = this.AGENTS_TABLE_DATA.asObservable();
	/* AGENTS Summary table data ends */

	/* OPS Dashboard Data */
	private OPS_TABLE_DATA = new BehaviorSubject([]);
	public ops_table_data = this.OPS_TABLE_DATA.asObservable();
	private OPS_TS_TABLE_DATA = new BehaviorSubject([]);
	public ops_ts_table_data = this.OPS_TS_TABLE_DATA.asObservable();
/* OPS Dashboard Data */
	
	getSelectedCamapign() {
		return this.SELECTED_CAMPAIGN.asObservable()
	}

	setMyCampaigns(start_date?, end_date?) {
		let promise = new Promise((resolve, reject) => {
			var obj = {
				"index": 0,
				"agents_list": this.service.getAllIds('user', 'email'),
				start_date,
				end_date
			}

			this.API.getCamapignsAPI(obj,this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					if (res.campaign_list) {
						this.campaignData.next(res.campaign_list);
					}
					resolve(null);
				}, (err: any) => {
					reject(err)
				}
			)
		})
		return promise;
	}

	setApplicantsData(startDate, endDate) {
		let promise = new Promise((resolve, reject) => {
			this.API.getApplicantData(startDate, endDate,this.api_end_point).subscribe(
				(res: any) => {
					if (res.errors) {
						alert('Error in the data fetch ' + res.errors);
						reject(res.errors);
					} else {
						this.recruitData.next(res.success);
						this.prepareRecruitmentData()
						resolve(null);
					}
				}, (err) => {
					alert('Error in the new API');
					reject(err);
				}
			)
		})
		return promise;
	}
	prepareRecruitmentData() {
		// Prepare summary for the data;
		// debugger;
		this.applicantData.subscribe((d: any) => {
			// debugger;
			let obj = {
				'l1_data': {
					'total': 0,
					'selected': 0,
					'rejected': 0
				},
				'l2_data': {
					'total': 0,
					'selected': 0,
					'rejected': 0
				},
				'client_data': {
					'total': 0,
					'selected': 0,
					'rejected': 0
				},
				'training': {
					'total': 0,
					'selected': 0,
					'rejected': 0
				},
				allData: d
			}
			for (var i in d) {
				if (d[i]['current_status'] && (d[i]['current_status'] == 'Level 1 Selected')) {
					obj['l1_data']['total'] += 1;
					obj['l1_data']['selected'] += 1;
				}
				if (d[i]['current_status'] && (d[i]['current_status'] == 'Level 1 Rejected')) {
					obj['l1_data']['total'] += 1;
					obj['l1_data']['rejected'] += 1;
				}
				if (d[i]['current_status'] && (d[i]['current_status'] == 'Level 2 Selected')) {
					obj['l2_data']['total'] += 1;
					obj['l2_data']['selected'] += 1;
				}
				if (d[i]['current_status'] && (d[i]['current_status'] == 'Level 2 Rejected')) {
					obj['l2_data']['total'] += 1;
					obj['l2_data']['rejected'] += 1;
				}
				if (d[i]['current_status'] && (d[i]['current_status'] == 'Client Selected')) {
					obj['client_data']['total'] += 1;
					obj['client_data']['selected'] += 1;
				}
				if (d[i]['current_status'] && (d[i]['current_status'] == 'Client Rejected')) {
					obj['client_data']['total'] += 1;
					obj['client_data']['rejected'] += 1;
				}
				if (d[i]['current_status'] && (d[i]['current_status'] == 'Training Completed')) {
					obj['training']['total'] += 1;
					obj['training']['selected'] += 1;
				}
				if (d[i]['current_status'] && (d[i]['current_status'] == 'Training Rejected')) {
					obj['training']['total'] += 1;
					obj['training']['rejected'] += 1;
				}
			}
			this.dashSummary.next(obj);
		})
	}

	setDashSummary(obj: any) {
		this.dashSummary.next(obj);
	}

	setCampaignSummary(obj: any) {
		this.campaignSummary.next(obj);
	}

	updateCampaignSummary(obj: any) {
		this.campaignSummary.next(obj);
	}

	getCampaignSummary() {
		return this.campaignSummary;
	}

	getAssessmentData(all:boolean = false, user_id: string = "", production_group: string = "") {
		var api_end_point = this.service.get_API_End_Point();
		let promise = new Promise((resolve, reject) => {
			this.API.getAssessmentForCandidate(true, user_id, production_group,null,api_end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							reject(res.errors)
						} else {
							this.ASSESSMENT_DASH_DATA.next(res);
							// debugger;
							resolve(null);
						}
					}, (err) => {
						reject('API Error')
					}
				)
		})
		return promise;
	}

	/* Project Manager dashboard data calls */
	getPMDashData(obj: any,api_end_point:any) {
		var end_point = api_end_point;
		let promise = new Promise((resolve, reject) => {
			this.API.recruiterDashboard(obj,end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							reject(res.errors)
						} else {
							this.PM_DASH_DATA.next(res);
							// debugger;
							resolve(null);
						}
					}, (err) => {
						reject('API Error')
					}
				)
		})
		return promise;
	}
	getPMDashDataOutbound(obj: any,api_end:any) {
		//debugger;
		var end_point = api_end;
		//console.log(end_point);
		let promise = new Promise((resolve, reject) => {
			this.API.pmDashboard(obj,end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							reject(res.errors)
						} else {
							this.PM_DASH_DATA.next(res);
							// debugger;
							resolve(null);
						}
					}, (err) => {
						reject('API Error')
					}
				)
		})
		return promise;
	}
	getPMDashDataInbound(obj: any,api_end:any) {
		//debugger;
		var end_point = api_end;
		//console.log(end_point);
		let promise = new Promise((resolve, reject) => {
			this.API.pmDashboardInbound(obj,end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							reject(res.errors)
						} else {
							this.PM_DASH_INBOUND_DATA.next(res.success);
							// debugger;
							resolve(null);
						}
					}, (err) => {
						reject('API Error')
					}
				)
		})
		return promise;
	}
	/* Project Manager dashboard data ends */

	/* AGENTS TABLE DATA starts */
	setAgentsTableData(obj: any) {
		this.AGENTS_TABLE_DATA.next(obj);
	}
	/* AGENTS TABLE DATA ends */
	/* OPS Table data starts */
	setOPSTableData(startDate, endDate,api_end_point) {
		var end_point = api_end_point;
		let promise = new Promise((resolve, reject) => {
			this.API.getOPSDATA(startDate, endDate,end_point).subscribe((res: any) => {
				if (res.errors) {
					alert(res.errors)
					this.OPS_TABLE_DATA.next([]);
				}
				if (res.success) {
					//console.log('OPS data received', res.success);
					this.OPS_TABLE_DATA.next(res.success);
				}
				resolve(null)
			}, (err: any) => {
				alert('API call error');
			//	console.log('Error API call', err)
				this.OPS_TABLE_DATA.next([]);
				reject('API Error');
			})
		})
		return promise;
	}
	setOPSTSTableData(api_end_point:any) {
		var end_point = api_end_point;
		let promise = new Promise((resolve, reject) => {
			this.API.getOPSTSDATA(end_point).subscribe((res: any) => {
				if (res.errors) {
					alert(res.errors)
					this.OPS_TS_TABLE_DATA.next([]);
				}
				if (res.success) {
				//	console.log('OPS data received', res.success);
					this.OPS_TS_TABLE_DATA.next(res.success);
				}
				resolve(null)
			}, (err: any) => {
				alert('API call error');
			//	console.log('Error API call', err)
				this.OPS_TS_TABLE_DATA.next([]);
				reject('API Error');
			})
		})
		return promise;
	}
	/* OPS Table data ends */

	getDatewiseSlots(obj: any,api_end_point:any) {
		var end_point = api_end_point;
		let promise = new Promise((resolve, reject) => {
			this.API.pmDatewiseSlots(obj,end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							reject(res.errors)
						} else {
							this.PM_DASH_SLOT_DATA.next(res);
							resolve(null);
						}
					}, (err) => {
						reject('API Error')
					}
				)
		})
		return promise;
	}
	getPMDashDataOutbound1(obj: any,api_end_point:any) {
		var end_point = api_end_point;
		let promise = new Promise((resolve, reject) => {
			this.API.pmDashboard1(obj,end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							reject(res.errors)
						} else {
							this.PM_DASH_DATA.next(res);
							// debugger;
							resolve(null);
						}
					}, (err) => {
						reject('API Error')
					}
				)
		})
		return promise;
	}
	getDatewiseSlots1(obj: any,api_end_point:any) {
		var end_point = api_end_point;
		let promise = new Promise((resolve, reject) => {
			this.API.pmDatewiseSlots1(obj,end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							reject(res.errors)
						} else {
							this.PM_DASH_SLOT_DATA.next(res);
							resolve(null);
						}
					}, (err) => {
						reject('API Error')
					}
				)
		})
		return promise;
	}
	getPMDashDataInbound1(obj: any,api_end_point:any) {
		var end_point = api_end_point;
		let promise = new Promise((resolve, reject) => {
			this.API.pmDashboardInbound1(obj,end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							reject(res.errors)
						} else {
							this.PM_DASH_INBOUND_DATA.next(res.success);
							// debugger;
							resolve(null);
						}
					}, (err) => {
						reject('API Error')
					}
				)
		})
		return promise;
	}
}
