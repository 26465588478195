import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import * as _ from 'underscore';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MyserviceService } from '../myservice.service';
import { RtddataService } from '../rtdData.service';
import { Utils } from '../utils';
import { object } from 'underscore';
@Component({
	selector: 'app-badashboard-dialed-page',
	templateUrl: './badashboard-dialed-page.component.html',
	styleUrls: ['./badashboard-dialed-page.component.scss']
})
export class BadashboardDialedPageComponent implements OnInit, OnDestroy {

	private subscription: Subscription = new Subscription();
	@ViewChild('selectASM') selectASM: MatSelect;
	allSelectedAsm = false;

	_fetchData: any = [];
	asmData: any = [];
	ddList: BehaviorSubject<any> = new BehaviorSubject([]);
	ddListSelected = [];
	groupdata=[];
	selecteBtnSM: any;
	sortedData: BehaviorSubject<[]> = new BehaviorSubject([]);
	totaldialled:any=null;
	totalnewdialled:any=null;
	agentFeed: Subscription=null;
	groupFeed: Subscription=null;
	util = new Utils();
	group_level_data:any;
	groupid:any=null;
	user: any;
	constructor(private rtd: RtddataService, private myService: MyserviceService) {
		this.user = this.myService.get_loggedin_user();
		this.totaldialled = 0
		this.totalnewdialled =0; 
	}
	ngOnInit() {
		this.getRtdValues();
	}

	getRtdValues() {
		//debugger;
		 this.group_level_data = this.myService.get_bidashboard();
		 this.groupid = this.myService.get_asm_groupid();
			console.log(JSON.stringify(this.group_level_data));
			this.groupdata = Object.values(this.group_level_data);
		//console.log("-1--",JSON.stringify(this.groupdata))
		//console.log("-2-",JSON.stringify(this.groupdata[0].rtd_dialled_count));
		// this.totaldialled = this.groupdata[0].rtd_dialled_count||0;
		// this.totalnewdialled = this.groupdata[0].rtd_new_data_dialled_count||0;

		this.subscription.add(
			this.rtd.asmData.subscribe(asmData => {
				// console.log('asm data', asmData);
				this.asmData = asmData;
				this.updateFetchData(this.selecteBtnSM);
			})
		)
		this.agentFeed = this.rtd.agentFeed.subscribe(data => {
			//console.log('agent feed', data);
			let finaldata =[];
			// if(this.groupid)
			// {
			// 	console.log('agent feed', data);
			// 	for(let i in data)
			// 	{
			// 		//console.log(data[i]);
			// 		if(data[i].groupid == this.groupid)
			// 		{
			// 			finaldata.push(data[i]);
			// 		}
			// 	}
			// 	console.log(finaldata);
			// }
			// else{
			// 	console.log('agent feed', data);
				
			// 	for(let i in data)
			// 	{
			// 		//console.log(data[i])
			// 			finaldata.push(data[i]);
					
			// 	}
			// 	console.log(finaldata);
			// }
			let tlbase = _.groupBy(data, 'tl_name');
			// console.log('tlbase', tlbase);
			let arr = [];
			for(let tl in tlbase){
				let obj:any = {};
				obj.title = tl;
				obj.rData = [];
				this.groupid = tlbase[tl][0]['groupid'];
				tlbase[tl].forEach((agent:any) => {
					//console.log('-----', JSON.stringify(agent));
					obj.rData.push({
						name: agent.head,
						dataUtilization: this.util.getPercentage(agent.rtd_new_data_dialled_count,agent.rtd_data_pushed_count), 
						Utilization_data: agent.rtd_new_data_dialled_count,
						data_push: agent.rtd_data_pushed_count,
						followUpsMissed: agent.rtd_followup_data_count,   //agent.rtd_followup_data_count,agent.rtd_data_consumed_count+
						totalDialed: (agent.rtd_dialled_count),//+agent.rtd_rnr_data_count+agent.rtd_rnr_data_count_WFH 
						newDataDialed: agent.rtd_new_data_dialled_count, 
						followUpDialed: agent.rtd_flp_data_dialled_count
					})
				})
				arr.push(obj);
			}
			// console.log('after add data', arr);
			this.formTable(arr);
		})

		this.groupFeed = this.rtd.groupFeed.subscribe(data => {
			//console.log('group feed', data);
			if (this.groupid) {
				if(data[this.groupid])
				{
					//console.log(data[this.groupid]['rtd_dialled_count']);
					this.totaldialled= data[this.groupid]['rtd_dialled_count'];
					this.totalnewdialled= data[this.groupid]['rtd_new_data_dialled_count'];
				}
			} else if (data.hasOwnProperty(this.user.groupid)) {
				this.totaldialled = data[this.user.groupid]['rtd_dialled_count'];
				this.totalnewdialled = data[this.user.groupid]['rtd_new_data_dialled_count'];
			}
		})
	}

	formTable(arr?){
		this._fetchData = []
		if(arr && arr.length){
			this._fetchData = arr;
		}
		this.sortedData.next(this._fetchData.slice());
	}

	sortData(sort: Sort) {
		const data = this._fetchData.slice();
		if (!sort.active || sort.direction === '') {
			this.sortedData.next(data)
			return;
		}

		let p = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'dataUtilization':
					return this.compare(a.dataUtilization, b.dataUtilization, isAsc);
				case 'followUpsMissed':
					return this.compare(a.followUpsMissed, b.followUpsMissed, isAsc);
				case 'totalDialed':
					return this.compare(a.totalDialed, b.totalDialed, isAsc);
				case 'newDataDialed':
					return this.compare(a.newDataDialed, b.newDataDialed, isAsc);
				case 'followUpDialed':
					return this.compare(a.followUpDialed, b.followUpDialed, isAsc);
				default:
					return 0;
			}
		});
		this.sortedData.next(p)
	}
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	getDataUtilization(d: any) {
		let total = 0;
		let agent=0;
		for (let k = 0; k < d.rData.length; k++)
		{
			total+=Number(d.rData[k].Utilization_data);
			agent+=Number(d.rData[k].data_push)
		}
			//total += Number(d.rData[k].dataUtilization);
		return this.util.getPercentage(total,agent);
	}
	getSummationData(variable){
		let total = 0;
		let dataValue:any = this.sortedData.getValue();
		if(dataValue && dataValue.length){
			for (let j = 0; j < dataValue.length; j++) {
				if(dataValue[j].rData && dataValue[j].rData.length){
					for (let k = 0; k < dataValue[j].rData.length; k++)
						total += Number(dataValue[j].rData[k][variable]);
				}
			}
		}
		return total;
	}
	getAllDataUtilization() {
		var data = this.getSummationData('Utilization_data');
		var data_push = this.getSummationData('data_push');
		return this.util.getPercentage(data,data_push);
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].dataUtilization);
		}
		return total; */
	}


	getFollowUpsMissed(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].followUpsMissed);
		return total;
	}
	getAllFollowUpsMissed() {
		return this.getSummationData('followUpsMissed');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].followUpsMissed);
		}
		return total; */
	}

	getTotalDialed(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].totalDialed);
		return total;
	}
	getAllTotalDialed() {
		return this.getSummationData('totalDialed');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].totalDialed);
		}
		return total; */
	}

	getNewDataDialed(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].newDataDialed);
		return total;
	}
	getAllNewDataDialed() {
		return this.getSummationData('newDataDialed');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].newDataDialed);
		}
		return total; */
	}

	getFollowUpDialed(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
		//console.log(d.rData[k].followUpDialed);
			total += Number(d.rData[k].followUpDialed);
		return total;
	}
	getAllFollowUpDialed() {
		return this.getSummationData('followUpDialed');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].followUpDialed);
		}
		return total; */
	}
	async updateFetchData(val) {
		if (val == "ASM") {
			this._fetchData = await this.modifiedValues(this.asmData, 'asm');
		}
		this.sortedData.next(this._fetchData);
	}
	modifiedValues(data, role) {
		// console.log('modified values data', data);
		let arr = [];
		const ddlList = this.ddList.getValue()[role];
		// console.log('printing ddList', ddlList);
		for (var i in data) {
			let obj: any = {};
			if (ddlList && ddlList.hasOwnProperty(i)) {
				obj.name = ddlList[i].name;
				obj.visible = ddlList[i].visible;
				obj.dataUtilization = this.util.getPercentage(data[i]['rtd_new_data_dialled_count'],data[i]['rtd_data_pushed_count']);
				obj.Utilization = data[i]['rtd_new_data_dialled_count'];
				obj.pushed_Count=data[i]['rtd_data_pushed_count']
				obj.followUpsMissed = data[i]['rtd_followup_data_count'];
				obj.totalDialed = data[i]['rtd_dialled_count'];
				obj.newDataDialed = data[i]['rtd_new_data_dialled_count'];
				obj.followUpDialed = data[i]['rtd_flp_data_dialled_count'];
				// console.log('obj', obj);
				arr.push(obj);
			}
		}
		return arr;
	}

	ngOnDestroy() {
		this.agentFeed.unsubscribe();
		this.groupFeed.unsubscribe();
	}
}


