<app-new-contiinex-menu-page></app-new-contiinex-menu-page>
<div class="v-mainDivCO">

    <div class="row animation-One2x vk-sec">
        <div class="col-md-12 col-sm-12 col-xs-12 mb-4">
         

            <div class="row mt-4" style="align-items: center;">
                <div class="col-md-1"></div>
                <div class="col-md-5">
<img src="../assets/contiinex/image/pdrImg2.png" style="width:100%;"/>
                </div>
                <div class="col-md-5">
                    <h1 class="v-textH v-BannerTitle text-left">
                        We are Contiinex</h1>
                    <p> <span class="vkX-desc mb-2 p-0 ml-1">
                        Contiinex is an AI software company that has evolved Speech Intelligence capabilities & develop intelligent platforms.

 <br><br>

                        Our AI-based CX automation platform which includes Unified Communication as a Service & Quality Assurance as a service, is built on a homegrown Speech-To-Text & NLP engine. It is developed to evolve speech intelligence capabilities to deliver AI-fueled analysis & derive meaningful insights.
                        
                        <br><br>
                        
                        Contiinex is a next-gen AI platform supported by an in-house built NLP engine to deliver Unified Communication as a Service & Speech AI as a Service across voice, text, and Video. Contiinex NLP engine enables Conversational intelligence through an inbuilt Unified communication stack comprising of bots/dialers & widgets that auto stitches an end-to-end digital journey for a customer at one end and on the other end, it enables Autonomous Intelligence through speech translation to automate a voice or non-voice auto audit.
                        </span>
                    </p>

                </div>
                <div class="col-md-1"></div>
          </div>















        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 mt-4" style="display: none;">
            <h1 class="v-textH text-center v-BannerTitle">Our Leadership</h1>
            <div class="row mt-4">
                <div class="col-md-1"></div>
                <div class="col-md-5">
                    <mat-card class="text-center mcd">
                        <img src="../assets/contiinex/image/Prateek1.png" alt="Prateek Mehta" title="Prateek Mehta" style="margin-top: 20px;"/>
                        <p>
                            <br><br>
                            <span class="t4 fsiz">
                                <a class="adec" href="https://www.linkedin.com/in/prateek-mehta-1023616/"
                                    target="_blank"> <span class="ceoName">Prateek Mehta </span>
                                    <br>
                                    Co-founder & CEO
                                    <br>
                                    <img src="../assets/contiinex/image/LinkedIcon1.png" title="Linkedin"  style="width: 38px !important; margin: 0 7px;"/>
                                
                                </a>
                            
                            </span>
                        </p>
                        <p class="ceo-desc">
                            Prateek is ex-Microsoft he has 23+ years of experience in driving partnerships, customer acquisition and scale for SaaS.
                        </p>
                    </mat-card>
                </div>
                <div class="col-md-5">
                    <mat-card class="text-center mcd">
                        <img src="../assets/contiinex/image/Vijay.png" alt="Vijay Krishna" title="Vijay Krishna" style="margin-top: 20px;" />

                        <p>
                            <br><br>
                            <span class="t4 fsiz">
                                <a class="adec" href="https://www.linkedin.com/in/bsvijaykrishna/"
                                    target="_blank"> <span class="ceoName"> Vijay Krishna </span><br>
                                    Co-founder & CTO<br>
                                    <img src="../assets/contiinex/image/LinkedIcon1.png" title="Linkedin" style="width: 38px !important; margin: 0 7px;"/>
                                </a>
                              
                              
                            </span>
                        </p>
                        <p class="ceo-desc">

                            Vijay is ex-Accenture has multiple patents under his name in space of neural network in ML and embedded platforms with total experience of 22+ years.

                        </p>

                    </mat-card>
                </div>
          
                <div class="col-md-1"></div>
            </div>

        </div>

    </div>


<div style="display: none;">
    <app-new-contiinex-new-contact-page></app-new-contiinex-new-contact-page>
</div>




    <!-- <div class="mt-4">
        <app-new-contiinex-footer-section-page></app-new-contiinex-footer-section-page>
    </div> -->
</div>