import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-voice-call-upload-audio',
  templateUrl: './voice-call-upload-audio.component.html',
  styleUrls: ['./voice-call-upload-audio.component.scss']
})
export class VoiceCallUploadAudioComponent implements OnInit {
  currentTitle = 'uploadAudio';
  _fileNameView:any;
 _successMsg:any="";
 myUser:any;
 errorCount:any=0;
 _fileDetails:any;
 fileUploaded: boolean = false;
 showHidePleaseWaitMsg: boolean = false;
 assignedProject: any;
 api_end_point:any;
  constructor(private API:ApiService,private service:MyserviceService) { 
    this.myUser = this.service.get_loggedin_user();
  }

  ngOnInit(): void {
	this.api_end_point = this.service.get_API_End_Point();
    this.API.getAuditorAssignedProject(this.myUser.id).subscribe((response: any) => {
      if(response.success){
        this.assignedProject = response.success[0];
      }
      if(response.errors){
        alert("No project is assigned")
      }
    })
  }
  fileEvent($event){
   // debugger;
     this._fileDetails = $event.target.files[0];
    let _fileName=$event.target.files[0].name;
    let fileInput = document.getElementById('fileID') as HTMLInputElement;
    let filePath = fileInput.value;
    let allowedExtensions = /(\.mpg|\.mpeg|\.mov|\.ogg|\.wav|\.mp3|\.aac)$/i;
    if(!allowedExtensions.exec(filePath)){
        alert('Please upload file having extensions .mpg/.mpeg/.mov/.ogg/.mp3/.aac only.');
        fileInput.value = '';
        document.getElementById('imagePreview').innerHTML ="";
        this._fileNameView="";
        this._successMsg="";
        return false;
    }
else{
 this._fileNameView=$event.target.files[0].name;
  let filePath = fileInput.value;
  if (fileInput.files && fileInput.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
        document.getElementById('imagePreview').innerHTML = '<audio controls><source src="'+e.target.result+'" type="audio/mpeg"></audio>';       
    };
    reader.readAsDataURL(fileInput.files[0]);

}

}
    
    
}
submitBtn(){
//  debugger;
  this.showHidePleaseWaitMsg=true;
  let _groupid=this.myUser.groupid;
  let formData = new FormData();
   formData.append('call', this._fileDetails, this._fileNameView);
  this.API.uploadAudioRecord(formData ,parseInt(this.assignedProject.production_group),this.api_end_point )
  .subscribe(
    (res: any) => {
    //  debugger;
      if (res.errors) {

      } else {
        this._successMsg="Audio file upload successfully..!!";
        this.fileUploaded = true;
        this.showHidePleaseWaitMsg=false;
        setTimeout(()=>{
          this.cancelBtn()
        },3000)
      }
  });
}
cancelBtn(){
  let fileInput = document.getElementById('fileID') as HTMLInputElement;
  fileInput.value = '';
  document.getElementById('imagePreview').innerHTML ="";
  this._fileNameView="";
  this._successMsg="";
  this._successMsg="";
  this._fileDetails = false;
  this.showHidePleaseWaitMsg=false;
}
}

