<mat-card style="margin-top:1.4%">
<div class="row pdt">
        <div class="col-md-12 col-sm-12 col-xs-12">
         <h2 class="d-head">AGENTS IN CERTIFICATION<img src="../assets/underline.svg"
            class="linebelow"> </h2>
         </div>
</div>
<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
<h2 class="batch-head">BATCH</h2>
</div>
</div>
<div  class="row">
   
    <div class="col-md-3 col-sm-12 col-xs-12">
        <mat-form-field>     
            <mat-select placeholder="Select A Batch" (selectionChange)="changeBatch($event.value)">
              <mat-option style="font-family:poppins" [value]="v" *ngFor="let v of batchOptionList">Batch {{v.id}}({{v.training_type}})</mat-option>
            </mat-select>
          </mat-form-field>
          
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12">

        <mat-form-field style="font-size: 12px;" appearance="outline">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
            <i class="fa fa-search search-icon" matSuffix style="position: relative;
            top: -2px;"></i>
          </mat-form-field>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12">

      <button mat-button class="T-createbtn"  (click)="exportBatch()">Export </button>
      <button mat-button class="T-createbtn" *ngIf="selectedBatchObj && selectedBatchObj.training_type == 'Regular' " (click)="exportBatchEligible()">Export Eligible</button>
    </div>
 
</div>
<div>
  <p class="mt-2 mb-2 errmsg" [ngStyle]="{'color':statusColor}">{{errorMsg}}</p>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

<ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef>NAME </th>
  <td mat-cell *matCellDef="let element">{{element.name}} </td>
</ng-container>

<ng-container matColumnDef="email">
  <th mat-header-cell *matHeaderCellDef> EMAIL</th>
  <td mat-cell *matCellDef="let element"> {{element.email}} </td>
</ng-container>
<ng-container matColumnDef="phone">
  <th mat-header-cell *matHeaderCellDef> PHONE</th>
  <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
</ng-container>

<ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>USER ID</th>
    <td mat-cell *matCellDef="let element">{{element.agent_reference_id || element.id}} </td>
  </ng-container>
  
  <ng-container matColumnDef="score">
    <th mat-header-cell *matHeaderCellDef>Score</th>
    <td mat-cell *matCellDef="let element" [disable]="element.score==null?false:true">
      <!-- {{element.score}}  -->
    <span class="vscore" [ngClass]="element.score==null?'vsclass':''"  (click)="viewScoreDialog(element)">View Score</span>
    </td>
  </ng-container>
  <!-- <ng-container matColumnDef="test_eligible">
    <th mat-header-cell *matHeaderCellDef>Test Eligible</th>
    <td mat-cell *matCellDef="let element">{{element.eligible == 1 ? 'Yes' : element.eligible == 0 ? 'No' : ''}} </td>
  </ng-container> -->
  <ng-container matColumnDef="audit_score">
    <th mat-header-cell *matHeaderCellDef>Audit Score</th>
    <td mat-cell *matCellDef="let element">{{element.batch_type == 'Regular' ? element.mock_audit_score : element.ojt_audit_score}} </td>
  </ng-container>
  <ng-container matColumnDef="uncertify_count">
    <th mat-header-cell *matHeaderCellDef>Attempt</th>
    <td mat-cell *matCellDef="let element">{{element.uncertify_count}} </td>
  </ng-container>
  <ng-container matColumnDef="batch_status">
    <th mat-header-cell *matHeaderCellDef> STATUS</th>
    <td mat-cell *matCellDef="let element"> {{element.batch_status}} </td>
  </ng-container>
  <ng-container matColumnDef="batch_type">
    <th mat-header-cell *matHeaderCellDef> Type</th>
    <td mat-cell *matCellDef="let element"> {{element.batch_type}} </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Mock Calling</th>
    <td mat-cell *matCellDef="let element">
      <!-- {{element.score}}  -->
    <span class="vscore" *ngIf="element.agent_status != 'On Board'" (click)="markForMockCalling(element)">Enable</span>
    <span class="vscore" *ngIf="element.agent_status == 'On Board'" >Enabled</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="certified_date">
    <th mat-header-cell *matHeaderCellDef> CERTIFIED/UNCERTIFIED</th>
    <td mat-cell *matCellDef="let element; let i = index;"> 
        <mat-form-field *ngIf="element.batch_type == 'OJT'">
            <mat-select  [ngClass]="element.certify_status==null?'':element.certify_status==0?'custom-color2':'custom-color1'" 
              [value] ="element.certify_status==null?'':element.certify_status==0?'Uncertified':'Certified'" (selectionChange)="onSelectChange($event,i,'OJT')">
              <mat-option value="Certified" >Ceritified</mat-option>
              <mat-option value="Uncertified" >Uncertified</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="element.batch_type == 'Regular'">
            <mat-select  [ngClass]="element.certify_status==null?'':element.certify_status==0?'custom-color2':'custom-color1'" 
              [value] ="element.certify_status==null?'':element.certify_status==0?'Uncertified':'Certified'" (selectionChange)="onSelectChange($event,i,'Regular')">
              <mat-option value="Certified" >Ceritified</mat-option>
              <mat-option value="Uncertified" >Uncertified</mat-option>
            </mat-select>
          </mat-form-field>
    </td>
  </ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator  [ngClass]="'paginator-training'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
<div class="row pdt">     
  <div class="col-md-12 col-sm-12 col-xs-12 rightside">
      <button mat-button class="cancelbtn" (click)="changeBatch(selectedBatchObj)">Cancel</button>
      <button mat-button class="submitbtn" (click)="updateCertification()" >Update</button>
  </div>

  </div>
</mat-card>