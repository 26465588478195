import { Component, OnInit,ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-create-ojt-batch',
  templateUrl: './create-ojt-batch.component.html',
  styleUrls: ['./create-ojt-batch.component.scss']
})
export class CreateOjtBatchComponent implements OnInit {
  showHideDeleteForEdit:boolean=false;
  showHidePopupDelete:boolean=false;
  createdForm:FormGroup;
  agentsDDL:any=[];
  trainerDDL:any=[];
  todayDate=new Date();
  updateEdit:any;
  passingMessage:any;
  showHidePassingMessage:boolean=false;
  _color:any="";
  minDate:any=new Date();
  api_end_point:any;

  constructor(public router:Router,private formbuilder:FormBuilder,private API: ApiService,private myService: MyserviceService) { 
    this.createdForm=this.formbuilder.group({
      batchCode:[''],
      startOJTBatch:['',Validators.required],
      endOJTBatch:['',Validators.required],
      batchTime:['',Validators.required],
      duration:['',Validators.required],
      capacity:['',Validators.required],
      agents:['',Validators.required],
      trainer:['',Validators.required],
      location:['',Validators.required]
    })
  }

  ngOnInit() {
	this.api_end_point = this.myService.get_API_End_Point();
    this.getTrainer();
   let _elementEdit= this.API.editBatch();
  if(_elementEdit){ 
  this.agentsForDrodown(true,_elementEdit.element.id);
  this.getEditBindingBatch(_elementEdit);
  this.showHideDeleteForEdit=true;
  }
  else{
     this.agentsForDrodown()
     this.showHideDeleteForEdit=false;
   }
   this.onFormChanges();
  }

  agentsForDrodown(edit: boolean = false, batchCode: number = 0){
    this.API.getAgentList(edit ? JSON.stringify(["On Board","OJT Reject"]): JSON.stringify(["On Board","OJT Reject"]), batchCode, "OJT","&newBatch=true",this.api_end_point).subscribe((response:any)=>{
      if(response.success){
         this.agentsDDL=  response.success;
      }  
    })
  }
  getTrainer(){
    this.API.trainersAllTrainersList("","","Available",this.api_end_point).subscribe((response:any)=>{
      if(response.success){
         this.trainerDDL=  response.success;
      }  
    })
  }


  backtoOJTbatchList(){
    this.createdForm.reset();
this.router.navigateByUrl('/training/onJobTraining');

  }

  openWarningMessage(){
this.showHidePopupDelete=true;
}
closedeletePopup(){
this.showHidePopupDelete=false;
}

onFormChanges() {
  this.createdForm.valueChanges.subscribe(val => { 
    this.minDate=val.startOJTBatch;
       if(val.startOJTBatch && val.endOJTBatch){
        const _startBatch=moment(val.startOJTBatch);
        const _endBatch=moment(val.endOJTBatch);
        if(_startBatch.diff(_endBatch) >= 1 ){
         // alert("Start date should be less than end date") 
        this.passingMessage="* Start date should be less than end date";
        this.showHidePassingMessage=true;
        this._color='red';
        this.createdForm.controls['endOJTBatch'].reset()
      }
      else{
        this.showHidePassingMessage=false;
      }

       }
  });
}

formatDate(_date:any){
  return moment(_date).format();
  }
  cancelClear(){
    this.createdForm.reset();
  }
  cancelDeleteOjtBatch(){
    this.showHidePopupDelete=false;
  }
  deleteOjtBatch(){
    let _delObj={
      "batch_code":this.createdForm.value.batchCode,
      "deleteType":"soft",
	  "batch_type": "OJT"
    }
    this.API.deleteBatch(_delObj,this.api_end_point).subscribe((data:any)=>{
      if(data.success){
        // if(data.success.message== "Training Batch Created Successfully"){
         //  alert(data.success.message);
           this.showHidePopupDelete=false;
           this.passingMessage=data.success.message;
           this.showHidePassingMessage=true;
           this._color='green';
          setTimeout(() => {
            this.backtoOJTbatchList();
            }, 4000);
         
        // }
      }
    });
  }
  formatTime(){
    let timePattern = new RegExp('([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]');
    let _batchTime=this.createdForm.controls.batchTime.value;
  if(timePattern.test(_batchTime)==false){
   // alert("Please enter time 'hh:mm:ss' format.")
    this.passingMessage="Please enter time 'hh:mm:ss' format.";
    this.showHidePassingMessage=true;
    this._color='red';
  return false;
  }
  }
  
checkStartDateToday(startDate: any){
  
  const isToday = moment(moment(startDate)).isSame(moment(), 'day')
  if(isToday){
    return moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
  }
  return moment(startDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
}
  getEditBindingBatch(_elementEdit:any){
      this.updateEdit= _elementEdit;  
      if(_elementEdit){  
let _agentArrayData=JSON.parse(_elementEdit.element.agents_list);
let _agentsArray= _agentArrayData.map(String);
  this.createdForm=new FormGroup({
  'batchCode' :new FormControl(_elementEdit.element.id),
  'startOJTBatch' :new FormControl(_elementEdit.element.start_date),
  'endOJTBatch' :new FormControl(_elementEdit.element.end_date),
  'batchTime':new FormControl((_elementEdit.element.start_time).split('T')[1].substring(0, 8)),
  'duration':new FormControl(_elementEdit.element.duration.toString()),
  'capacity' :new FormControl(_elementEdit.element.capacity.toString()),
  'agents' :new FormControl(_agentsArray),
  'trainer' :new FormControl(_elementEdit.element.trainer_id.toString()),
  'location' :new FormControl(_elementEdit.element.training_location),
})
      }
  
  }
saveBatch(createdForm:any){
  let timePattern = new RegExp('([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]');
  if(this.updateEdit && this.updateEdit!=undefined){
   let _batchcode=createdForm.controls.batchCode.value;
    let _startBatch = this.checkStartDateToday(createdForm.controls.startOJTBatch.value);
  let _endBatch=moment(createdForm.controls.endOJTBatch.value).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
  let _batchTime=createdForm.controls.batchTime.value;
  if(timePattern.test(_batchTime)==false){
   // alert("Please enter time 'hh:mm:ss' format.")
    this.passingMessage="Please enter time 'hh:mm:ss' format.";
    this.showHidePassingMessage=true;
    this._color='red';
  return false;
  }
  if(moment(moment(createdForm.controls.startOJTBatch.value)).isSame(moment(), 'day')){
    var currDate = new Date();
    const h1 = currDate.getHours(), m1 = currDate.getMinutes(),h2 =  parseInt(_batchTime.split(':')[0]), m2 =parseInt(_batchTime.split(':')[1]);
   // console.log(h1,h2,m1,m2)
    if(h2 < h1){
      this.passingMessage="* Select time greater than current time";
      this.showHidePassingMessage=true;
      this._color='red';
      return false;
    }
    if(h2 == h1){
      if(m2 < m1){
        this.passingMessage="* Select time greater than current time";
        this.showHidePassingMessage=true;
        this._color='red';
        return false;
      }
    }
  }
let _duration=createdForm.controls.duration.value;
  let _batchCapacity=createdForm.controls.capacity.value;
  let _agentsArray=(createdForm.controls.agents.value);//.toString().replace(/[\[\]']+/g,'');
  if(_agentsArray.length==0 ||_agentsArray==""){
    //alert("Please Select Atleast One Agents");
    this.passingMessage="Please Select Atleast One Agents";
    this.showHidePassingMessage=true;
    this._color='red';
    return false;
      }
  let _agents= _agentsArray.map(i=>Number(i));
  let _trainer=createdForm.controls.trainer.value;
  let _location=createdForm.controls.location.value;
  let todayDateTime = new Date();
  let _time = todayDateTime.getHours() + ":" + todayDateTime.getMinutes() + ":" + todayDateTime.getSeconds();
  let _modelObject={
  "batch_code":Number(_batchcode),
  "for_group":2053,
  "trainer_id":Number(_trainer),
	"start_date":_startBatch,
	"end_date":_endBatch,
	"start_time":_batchTime,
	"duration":Number(_duration),
  "capacity":Number(_batchCapacity),
  "agents_list":_agents,
  "training_location":_location,
  "training_type": "OJT"
  }

    this.API.modifyBatch(_modelObject,this.api_end_point).subscribe((data:any)=>{
    if(data.success){
      if(data.success.message== "Batch details updated successfully"){
       // alert("OJT Batch details updated successfully");
        this.passingMessage="OJT Batch details updated successfully";
        this.showHidePassingMessage=true;
       this._color='green';
       setTimeout(() => {
        this.createdForm.reset();
        this.backtoOJTbatchList();
         }, 3000);
      
      }
    }
    if(data.errors){
     // alert(data.errors);
      this.passingMessage=data.errors;
      this.showHidePassingMessage=true;
     this._color='red';
    }
  });
  }
  else{
    let _startBatch =this.checkStartDateToday(createdForm.controls.startOJTBatch.value);
    let _endBatch=moment(createdForm.controls.endOJTBatch.value).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');

let _batchTime=createdForm.controls.batchTime.value;
let _duration=createdForm.controls.duration.value;


if(createdForm.controls.startOJTBatch.value==""){
  this.passingMessage="Please select ojt starts";
  this.showHidePassingMessage=true;
  this._color='red';
return false;
}
if(createdForm.controls.endOJTBatch.value==""){
  this.passingMessage="Please select ojt ends";
  this.showHidePassingMessage=true;
  this._color='red';
return false;
}





if(timePattern.test(_batchTime)==false){
  //alert("Please enter time 'hh:mm:ss' format.")
  this.passingMessage="Please enter time 'hh:mm:ss' format.";
  this.showHidePassingMessage=true;
  this._color='red';
return false;
}
if(moment(moment(createdForm.controls.startOJTBatch.value)).isSame(moment(), 'day')){
  var currDate = new Date();
  const h1 = currDate.getHours(), m1 = currDate.getMinutes(),h2 =  parseInt(_batchTime.split(':')[0]), m2 =parseInt(_batchTime.split(':')[1]);
 // console.log(h1,h2,m1,m2)
  if(h2 < h1){
    this.passingMessage="* Select time greater than current time";
    this.showHidePassingMessage=true;
    this._color='red';
    return false;
  }
  if(h2 == h1){
    if(m2 < m1){
      this.passingMessage="* Select time greater than current time";
      this.showHidePassingMessage=true;
      this._color='red';
      return false;
    }
  }
}
  let _batchCapacity=createdForm.controls.capacity.value;
  let _agentsArray=(createdForm.controls.agents.value);//.toString().replace(/[\[\]']+/g,'');
  if(_agentsArray.length==0 ||_agentsArray==""){
    //alert("Please Select Atleast One Agents");
    this.passingMessage="Please Select Atleast One Agents";
    this.showHidePassingMessage=true;
    this._color='red';
    return false;
      }
  let _agents;
  if(_agentsArray !=""){
   _agents= _agentsArray.map(i=>Number(i));
  }
  else{
    _agents= "";
  }
  let _trainer=createdForm.controls.trainer.value;
  if(createdForm.controls.trainer.value==""){
    this.passingMessage="* Please Select Trainer";
    this.showHidePassingMessage=true;
    this._color='red';
    return false;
      }
  let _location=createdForm.controls.location.value;
  let todayDateTime = new Date();
  let _time = todayDateTime.getHours() + ":" + todayDateTime.getMinutes() + ":" + todayDateTime.getSeconds();
  let _modelObject={
  "for_group":2053,
  "trainer_id":Number(_trainer),
	"start_date":_startBatch,
	"end_date":_endBatch,
	"start_time":_batchTime,
	"duration":Number(_duration),
  "capacity":Number(_batchCapacity),
  "agents_list":_agents,
  "training_location":_location,
  "training_type": "OJT"
  }

  this.API.saveBatch(_modelObject,this.api_end_point).subscribe((data:any)=>{
    if(data.success){
      if(data.success.message== "Training Batch Created Successfully"){
       // alert("Training OJT Batch Created Successfully");
        this.passingMessage="Training OJT Batch Created Successfully";
        this.showHidePassingMessage=true;
       this._color='green';
       setTimeout(() => {
        this.createdForm.reset();
        this.backtoOJTbatchList();
         }, 3000);
      
      }
    }else if(data.errors){
      // alert(data.errors);
       this.passingMessage=data.errors;
       this.showHidePassingMessage=true;
       this._color='red';
    }
  });
}
}

onlyNumberDecimalValidation(evt){
  var charCode = (evt.which) ? evt.which : evt.keyCode;
          if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57))
             return false;
			 
          return true;
}
}
