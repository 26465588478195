
<mat-drawer-container autosize>
  <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
    <app-menu  [navigateLink]="currentTitle" ></app-menu>
  </mat-drawer>
  <app-logout></app-logout>
<div class="CN-Main">
    <p class="CN-Tab-head">Message Template</p>
    <div>

        <div class="row msgTempTabList">
            <div class="col-md-12" style="padding: 0px 3.5px;">
                <mat-button-toggle-group class="dashMsgTempcs" #group="matButtonToggleGroup"
                    [value]="selectedButtonToggleVal" (change)="onToggleButtonValChange(group.value)">
                    <mat-button-toggle value="list">List</mat-button-toggle>
                    <mat-button-toggle value="create" >Create</mat-button-toggle>
                    <!-- <mat-button-toggle value="clone">Clone</mat-button-toggle> *ngIf="customer_name=='smbwaba'"-->
                </mat-button-toggle-group>
            </div>
        </div>

<div class="mt-4" *ngIf="showHideForList">
    <mat-card class="p-0" style="border-radius: 4px;padding: 10px 8px!important;">
<!-- <div class="pt-4">
    <div class="row mt-3 mb-4">
        <div class="col-md-12 text-center">
<span class="xCopy"> Copy </span>
<span class="xCloTemp ml-3">Clone This Template</span>

<span class="xTestTemp ml-3">Test Your Template</span>
<span class="xPrevTemp ml-3" (click)="previewTemplateBtn()">Preview Template</span>
        </div>
    </div>
</div> -->
<!-- <span class="xPrevTemp ml-3" (click)="previewTemplateBtn()">Preview Template</span> -->
<div style="width:250px;float: right;margin-right: 16px;">
  <mat-form-field style="width:100%">
      <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" />
      <i class="fa fa-search" matSuffix aria-hidden="true"></i>
  </mat-form-field>
</div>
    <mat-table  style="border-radius: 4px;" class="msgtblcs" [dataSource]="dataSource" matSort>
<!--
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef [ngClass]="'wdTemp'"> Id </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'wdTemp'"> <span  [ngClass]="element.currentStatus=='APPROVED'? 'enabledCls':'rejectedCls' "> {{element.id}} </span>
        </mat-cell>
        </ng-container>
-->
<ng-container matColumnDef="id" >
  <mat-header-cell *matHeaderCellDef [ngClass]="'wdTemp'" style="display: none;"> Id </mat-header-cell>
  <mat-cell *matCellDef="let element" [ngClass]="'wdTemp'" style="display: none;"> {{element.id}}</mat-cell>
</ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="pl-2"> Template Name </mat-header-cell>
          <mat-cell *matCellDef="let element" class="pl-2" (click)="previewTemplateBtn(element)"> {{element.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="template">
          <mat-header-cell *matHeaderCellDef [ngClass]="'wdTemp'" mat-sort-header> Body </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="'wdTemp'" (click)="previewTemplateBtn(element)"> {{  ((element.template.text).length > 50)? (element.template.text | slice:0:50)+'....':(element.template.text) }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="language">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 110px;"> Language </mat-header-cell>
          <mat-cell *matCellDef="let element"  style="flex: 0 0 110px;" (click)="previewTemplateBtn(element)"> {{element.language}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="currentStatus">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 170px;"> Status </mat-header-cell>
          <mat-cell *matCellDef="let element" style="flex: 0 0 170px;" (click)="previewTemplateBtn(element)" ><span [ngClass]="element.currentStatus=='APPROVED'? 'enabledCls':'rejectedCls' ">
            <mat-icon style="vertical-align: bottom;" *ngIf="element.currentStatus=='Enabled'">done</mat-icon>
            <mat-icon style="vertical-align: bottom;" *ngIf="element.currentStatus=='Rejected'">close</mat-icon>
             {{element.currentStatus}}
             </span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 155px;"> Category </mat-header-cell>
          <mat-cell *matCellDef="let element" style="flex: 0 0 155px;" (click)="previewTemplateBtn(element)"> {{element.category}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="updatedOn">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 155px;"> Updated On </mat-header-cell>
          <mat-cell *matCellDef="let element" style="flex: 0 0 155px;" (click)="previewTemplateBtn(element)"> {{element.updatedOn | date:'short':'IST'}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="qualityRating">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 150px;"> Quality Rating </mat-header-cell>
          <mat-cell *matCellDef="let element" style="flex: 0 0 150px;" (click)="previewTemplateBtn(element)"> {{element.qualityRating}}
          </mat-cell>
        </ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 150px;"> Action </mat-header-cell>
			<mat-cell *matCellDef="let element" style="flex: 0 0 150px;" > 
				<mat-icon matTooltip="Delete" (click)="deleteDialog(element)">delete</mat-icon>
			</mat-cell>
		  </ng-container>

        <mat-header-row *matHeaderRowDef=displayed_columns></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayed_columns"  [ngClass]="{ 'selectedrowcs': row === selectedRow }"></mat-row>
		<!-- (click)="previewTemplateBtn(row)" -->
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-card>
    <br><br>
</div>



<div class="mt-4" *ngIf="showHideForCreate">
<app-contiinex-message-template-create [itemEdit]="selectedRow" (onPassToList)="doShowList($event)"></app-contiinex-message-template-create>
</div>
    </div>
</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>

<div   *ngIf="showHidePopupDelete" class="popupoverlayv1">
	<div class="delete-popupv1">
		<h3>Are you sure you want to delete this message template?</h3>
		<p>All Templates with this template name will be deleted permanently.</p>
		<a class="close"  (click)="closedeletePopup()">&times;</a>
		<div class="content" *ngIf="!isShowDeleteMsg">
			<button class="cancelDelete" (click)="cancelDeleteTemplate()">Cancel</button>
			<button class="delete-button" (click)="deleteTemplate()">Delete</button>
		</div>
	       	<p *ngIf="isShowDeleteMsg" class="mt-3" style="color:green;font-weight:500;font-size:18px;">{{deleteMessgae}}</p>
	</div>
</div>
