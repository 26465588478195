<mat-expansion-panel [disabled]="false">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Lead Status
        </mat-panel-title>
        <mat-panel-description>
            {{current_status}}
        </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-list role="list" class="list_item-body">
        <mat-list-item role="listitem" *ngFor="let status of display_arr">
            <span [ngStyle]="{'color': status.color}">{{ status.status }}</span>
        </mat-list-item>
    </mat-list>
</mat-expansion-panel>