import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import{HttpClient, HttpHeaders,HttpResponse }from '@angular/common/http';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import {MyserviceService}from '../myservice.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  createdForm:FormGroup;
  _urldata:any;
	resResult: any;
	showLoader = false;
	breakoption:any="breakoption";
	api_end_point:any;
  constructor(
    private router: Router,
    private http:HttpClient,
    // private http_headers: HttpHeaders,
    private fb:FormBuilder,
    private _service:MyserviceService,
    private API: ApiService) {
    this.endSession()
    this.createdForm=fb.group({
      email:['',[Validators.required,Validators.email]],
      password:['',[Validators.required,Validators.maxLength(50)]]
    })
  }

  endSession(){
    var myUser = this._service.get_loggedin_user();
    if(myUser){
      this.API.remoteDialStop(myUser.id,myUser.server_url,this.breakoption).subscribe(
        (res: any) => {
          this._service.clearAllItems();
          window.sessionStorage.clear();
          // window.localStorage.clear();
        }, (err) => {
        }
      )
    }else{
      this._service.clearAllItems();
      window.sessionStorage.clear();
      // window.localStorage.clear();
    }

  }

  ngOnInit(): void {
	this.api_end_point = this._service.get_API_End_Point();
  }

	signBtn(createdForm: any) {
		this.showLoader = true;
    let _email=createdForm.controls.email.value;
    let _password=createdForm.controls.password.value;
    let formData={
      "email": _email,
      "password": _password,
      "login_method": "smb"
	  }
  //   console.log('login api')
    var userDetails, stageSettings;
    this.API.loginAPI(formData)
    .subscribe(
      (res) => {
        // console.log('printing res',res)
        let _error=   res["errors"];
        if(_error=="Invalid Credentials" || _error=="User does not exist"){
          this.showLoader = false;
          alert(_error);
         // alert("Invalid Credentials,User does not exist");
          return false;
        }
        else{
          userDetails = res;

			let access_token = res['access_token'];
      localStorage.setItem('access_token',access_token);
			let landing_page = '';
          if(access_token){
            this._service.set_access_token(access_token);
            this._service.set_loggedin_user(res);
			//   debugger;
			// Calling team api
			// console.log('login team api')
			  this.API.loginAinexAPI(formData)
				  .subscribe(
					  (res: any) => {
						  localStorage.setItem('ainex_token', res["token"])
						  this.API.teamAPI(this.api_end_point)
							  .subscribe(
								  (res) => {
									  // debugger;
									  //   console.log('printing team response',res)
									  this._service.set_my_team(res);

									  // Calling settings api
									  //   console.log('signin settigs')
									  this.API.settingsAPI(this.api_end_point).subscribe(
										  (res: any) => {
											  //   console.log('printing settings response',res);
											  //   debugger;
											  if (res.success) {
												  stageSettings = res.success;
												  this._service.set_stage_settings(res.success);
												  // console.log('signin full team api');
												  if (res.success.web) {
													  for (var i in res.success.web) {
														  if (res.success.web[i]['name'] == 'landing_page') {
															  landing_page = res.success.web[i]['definition'];
														  }
													  }
												  }

											  } else {
												  alert('Error in fetching settings');
												  this._service.clearAllItems();
												  this.router.navigate(['/managementTeam']);
												  return;
											  }
											  this.API.getFullTeamData(this.api_end_point).subscribe(
												  (res: any) => {
													  if (res.success) {
														  this._service.set_Full_team(res.success);
														  this.showLoader = false;
														  // Routing to Dashboard
														  if (userDetails.role == 'user') {
															  var obj = { "activity_type": "signin", "gps_status": "{\"latitude\":22.0860604,\"longitude\":82.188001}", "call_recording_status": "false", "battery_status": "0", "user_id": userDetails.id, "version": "26" } as any;
															  this.API.agentStartEndSession(obj, this.api_end_point).subscribe(
																  (res: any) => {
																	  console.log(res)
																  }, (err) => {
																	  console.log(err)
																  }
															  )
															  if (landing_page.toLowerCase().includes('audit')) {
																  this.router.navigate(['/' + landing_page ? landing_page : 'dashboard']);
															  } else {
																  // if(!userDetails.isVisonEnabled){
																  //   this.router.navigate(['/agentPlugin/face']);
																  //   return;
																  // }
																  // this.API.checkAssessmentGiven(userDetails.groupid,userDetails.id).subscribe(
																  //   (res: any) => {
																  //     if(res.success == 1){
																  //       this.router.navigate(['/agentPlugin/assesment']);
																  //       // return;
																  //     }else{
																  //       this.router.navigate(['/agentPlugin/home']);
																  //     }
																  //   }, (err) => {
																  //     console.log(err);
																  //     this.router.navigate(['/agentPlugin/home']);
																  //   }
																  // )
																  // return;
																  if (stageSettings && stageSettings.extraoptions) {
																	  for (let opt of stageSettings.extraoptions) {
																		  if (opt.name == "biometric" && opt.definition == 'true') {
																			  if (!userDetails.isVisonEnabled) {
																				  this.router.navigate(['/agentPluginPC/face']);
																				  return;
																			  }
																		  }
																		  if (opt.name == "assessment" && opt.definition == 'true') {
																			  this.API.checkAssessmentGiven(userDetails.applicant_group, userDetails.id,this.api_end_point).subscribe(
																				  (res: any) => {
																					  if (res.success == 1) {
																						  this.router.navigate(['/agentPluginPC/assessment']);
																						  return;
																					  } else {
																						  this.router.navigate(['/agentPluginPC/home']);
																					  }
																				  }, (err) => {
																					  console.log(err)
																				  }
																			  )
																		  } else {
																			  this.router.navigate(['/agentPlugin/home']);
																		  }
																	  }
																  } else {
																	  this.router.navigate(['/agentPlugin/home']);
																  }
															  }

														  } else {
															  let total_pages = this._service.setMyPages();
															  // console.log('total_pages', total_pages);
															  let page_found: any = false;
															  for (var i in total_pages) {
																  if ((total_pages[i]['data_id'] == landing_page) && total_pages[i]['isAccessed']) {
																	  page_found = total_pages[i];
																	  break;
																  }
															  }
															  if (page_found) {
																  //	this.router.navigate([page_found['path']]);
																  window.location.href = window.location.protocol + "//" + window.location.host + page_found['path'];
															  } else {
																  this.router.navigate(['/managementTeam']);
															  }
														  }
													  } else {
														  alert(res.error)
													  }
												  }, (err) => {
													  alert('Error in the full team fetch')
												  }
											  )
										  },
										  (err) => {
											  alert('Error in the settings api')
										  }
									  )
								  },
								  (err) => {
									  // debugger;
									  alert('Error in the api')
								  }
							  )
					  })

          }else{
            alert('Unathorized access. Contact tech support')
          }
        }
      },
      (err) => {
        alert("User does not exist")
      });
    return;
    }
    forgotPassword(){
      this.router.navigateByUrl('/forgot-password');
    }
}
