
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span  style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
            <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()"/></span>
        </div>
</div>
<div style="padding:0 15px;">
    <div class="row">
        <div class="col-md-12">
            <h1 class="hOne">Edit</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label class="Lbl">Agent ID</label>
            <span class="Lbl-val">{{_viewData.user_id}} </span>
           
        </div>
        <div class="col-md-4">
            <label class="Lbl">Agent Name</label>
            <span class="Lbl-val">{{_viewData.name}}</span>
            
           
        </div>
        <div class="col-md-4">
            <label class="Lbl">Agent Email</label>
            <span class="Lbl-val">{{_viewData.email}} </span>
           
        </div>
    </div>
    <div class="row" style="padding: 20px 0px;">
        <div class="col-md-4">
            <label class="Lbl">Current Balaance</label>
            <span class="Lbl-val">{{_viewData.current_balance}} </span>
           
        </div>
     
    </div>
    <div class="row" style="padding: 20px 0px;">
        <div class="col-md-12">
    <mat-radio-group [(ngModel)]="creditDebitVal" (change)="radioChangeDebit($event.value)">
        <mat-radio-button value="CREDIT" style="padding-right:15px;">Credit</mat-radio-button>
        <mat-radio-button value="DEBIT">Debit</mat-radio-button>
      </mat-radio-group>
    </div>
     
</div>
<div class="row">
    <div class="col-md-4">
        <mat-form-field style="width:100%">
            <input matInput placeholder="Amount"  maxlength="10" autocomplete="off" (keypress)="decimalFilter($event)" [(ngModel)]="amountVal">           
          </mat-form-field>
    </div>
    <div class="col-md-4">
        <mat-form-field style="width:100%">
            <input matInput placeholder="Message (Optional)" autocomplete="off" [(ngModel)]="messageValue">           
          </mat-form-field>
    </div>
 
</div>
<div class="row">
    <div  class="col-md-12" >
        <h4 *ngIf="showHidePassingMessage"  [style.color]="_color" style="font-weight: bold;font-size: 18px;">{{passingMessage}}</h4>      
        </div>
        </div>
    <div class="row">
        <div class="col-md-12 textLR">
           <button class="reset-btn-ui" (click)="cancelBtn()"> Reset </button>
           <button class="submit-btn-ui" (click)="updateBtn()"> Update</button>
        </div>
    </div>
</div>
<div id="cover-spin" *ngIf="currentLoader">
	<mat-spinner strokeWidth="3" [diameter]="100"></mat-spinner>
</div>