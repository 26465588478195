import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { kMaxLength } from 'buffer';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { BatchCreateUploadDialogComponent } from '../auditor/batch-create-upload-dialog/batch-create-upload-dialog.component';
import { MyserviceService } from '../myservice.service';

@Component({
	selector: 'app-batch-create',
	templateUrl: './batch-create.component.html',
	styleUrls: ['./batch-create.component.scss']
})
export class BatchCreateComponent implements OnInit {
	showHideDeleteForEdit: boolean = false;
	showHidePopupDelete: boolean = false;
	createdForm: FormGroup;
	agentsDDL: any = [];
	trainerDDL: any = [];
	todayDate = new Date();
	updateEdit: any;
	passingMessage: any;
	showHidePassingMessage: boolean = false;
	_color: any = "";
	fileUploadStatus: any = "";
	selectedItems = [];
	dropdownSettings: any = {};
	currentFileobj: any = {};
	minDate: any = new Date();
	files: any = [];

	api_end_point:any;

	constructor(public router: Router, private formbuilder: FormBuilder, private API: ApiService, private dialog: MatDialog,private myService:MyserviceService) {
		this.createdForm = this.formbuilder.group({
			batchCode: [''],
			startBatch: ['', Validators.required],
			endBatch: ['', Validators.required],
			batchTime: ['', Validators.required],
			duration: ['', Validators.required],
			capacity: ['', Validators.required],
			agents: ['', Validators.required],
			trainer: ['', Validators.required],
			location: ['', Validators.required]
		})
	}

	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		this.dropdownSettings = {
			singleSelection: false,
			text: "Select Agents",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			enableCheckAll: true,
			searchBy: ["itemName"],
			noDataLabel: '',
			itemsShowLimit: 3,
			maxHeight: 200,
			badgeShowLimit: 3
		}
		this.getTrainer();
		this.fetchAgents();
		this.onFormChanges();
	}

	onItemSelect(item: any) {
		// console.log(item);
		// console.log(this.selectedItems);
	}
	OnItemDeSelect(item: any) {
		// console.log(item);
		// console.log(this.selectedItems);
	}
	onSelectAll(items: any) {
		//console.log(items);
	}
	onDeSelectAll(items: any) {
		//console.log(items);
	}

	fetchAgents() {
		let _elementEdit = this.API.editBatch();
		if (_elementEdit) {
			this.agentsForDrodown(true, _elementEdit.element.id, _elementEdit);

			this.showHideDeleteForEdit = true;
		}
		else {
			this.agentsForDrodown()
			this.showHideDeleteForEdit = false;
		}

	}

	changeFile(event) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			console.log(event.target.files);
			this.currentFileobj = event.target.files[0];
			let formData = new FormData();
			formData.append('image', event.target.files[0], event.target.files[0].name);
			this.API.uploadAnyFile(formData,this.api_end_point).subscribe(
				(res: any) => {
					this.files.push(res.url)

				}, (err) => {
					console.log(err)
				}
			)
		}
	}

	onFormChanges(): void {
		this.createdForm.valueChanges.subscribe(val => {
			this.minDate = val.startBatch;
			if (val.startBatch && val.endBatch) {
				const _startBatch = moment(val.startBatch);
				const _endBatch = moment(val.endBatch);
				//console.log(moment(val.startBatch).diff(moment(val.endBatch)))
				if (_startBatch.diff(_endBatch) >= 1) {
					// alert("Start date should be less than end date");
					this.passingMessage = "* Start date should be less than end date";
					this.showHidePassingMessage = true;
					this._color = 'red';
					this.createdForm.controls['endBatch'].reset()
				}
				else {
					this.showHidePassingMessage = false;
				}
			}


		});
	}

	agentsForDrodown(edit: boolean = false, batchCode: number = 0, _elementEdit: any = '') {
		this.API.getAgentList(edit ? JSON.stringify(["Training", "Training Reject"]) : JSON.stringify(["Training", "Training Reject"]), batchCode, "", "&newBatch=true",this.api_end_point).subscribe((response: any) => {
			if (response.success) {
				//  this.agentsDDL=  response.success;
				for (let o of response.success) {
					this.agentsDDL.push({ id: o.id, itemName: o.email })
				}
			}
			if (_elementEdit) {
				this.getEditBindingBatch(_elementEdit);
			}

		})
	}
	getTrainer() {
		this.API.trainersAllTrainersList("", "", "Available",this.api_end_point).subscribe((response: any) => {
			//console.log("--1-",response.success);
			//debugger;
			if (response.success) {
				
				this.trainerDDL = response.success;
				//console.log("--2-",this.trainerDDL);
			}
		})
	}

	getEditBindingBatch(_elementEdit: any) {
		this.updateEdit = _elementEdit;
		if (_elementEdit) {
			// let _removeQuotes=_elementEdit.element.agents_list.replace(/(^"|"$)/g, ''); 
			//   let _splitArr=_removeQuotes.split(',');
			//   let _agentsArray=[..._splitArr];
			let _agentArrayData = JSON.parse(_elementEdit.element.agents_list);
			let _agentsArray = this.agentsDDL.filter(x => _agentArrayData.indexOf(x.id) > -1);
			this.createdForm = new FormGroup({
				'batchCode': new FormControl(_elementEdit.element.id),
				'startBatch': new FormControl(_elementEdit.element.start_date),
				'endBatch': new FormControl(_elementEdit.element.end_date),
				'batchTime': new FormControl((_elementEdit.element.start_time).split('T')[1].substring(0, 8)),
				'duration': new FormControl(_elementEdit.element.duration.toString()),
				'capacity': new FormControl(_elementEdit.element.capacity.toString()),
				'agents': new FormControl(_agentsArray),
				'trainer': new FormControl(_elementEdit.element.trainer_id.toString()),
				'location': new FormControl(_elementEdit.element.training_location),
			});
			this.onFormChanges();
		}

	}

	backtobatchList() {
		this.router.navigateByUrl('/training/batch');
		this.createdForm.reset();
	}

	openWarningMessage() {
		this.showHidePopupDelete = true;
	}
	closedeletePopup() {
		this.showHidePopupDelete = false;
	}
	checkStartDateToday(startDate: any) {

		const isToday = moment(moment(startDate)).isSame(moment(), 'day')
		if (isToday) {
			return moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
		}
		return moment(startDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
	}
	saveBatch(createdForm: any) {
		//debugger;
		this.passingMessage = "";
		this._color = "";
		this.showHidePassingMessage = false;
		let timePattern = new RegExp('([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]');
		if (this.updateEdit && this.updateEdit != undefined) {
			let _batchcode = createdForm.controls.batchCode.value;
			let _startBatch = this.checkStartDateToday(createdForm.controls.startBatch.value);

			let _endBatch = moment(createdForm.controls.endBatch.value).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
			let _batchTime = createdForm.controls.batchTime.value;
			if (timePattern.test(_batchTime) == false) {
				// alert("Please enter time 'hh:mm:ss' format.");
				this.passingMessage = "Please enter batch time 'hh:mm:ss' format.";
				this.showHidePassingMessage = true;
				this._color = 'red';
				return false;
			}

			if (moment(moment(createdForm.controls.startBatch.value)).isSame(moment(), 'day')) {
				var currDate = new Date();
				const h1 = currDate.getHours(), m1 = currDate.getMinutes(), h2 = parseInt(_batchTime.split(':')[0]), m2 = parseInt(_batchTime.split(':')[1]);
				//console.log(h1, h2, m1, m2)
				if (h2 < h1) {
					this.passingMessage = "* Select time greater than current time";
					this.showHidePassingMessage = true;
					this._color = 'red';
					return false;
				}
				if (h2 == h1) {
					if (m2 < m1) {
						this.passingMessage = "* Select time greater than current time";
						this.showHidePassingMessage = true;
						this._color = 'red';
						return false;
					}
				}
			}

			if (createdForm.controls.duration.value > 24) {
				this.passingMessage = "*should be less than 24";
				this.showHidePassingMessage = true;
				this._color = 'red';
				return false;
			}
			let _duration = createdForm.controls.duration.value;
			let _batchCapacity = createdForm.controls.capacity.value;
			let _agentsArray = (createdForm.controls.agents.value.map(x => x.id));//.toString().replace(/[\[\]']+/g,'');
			if (_agentsArray.length == 0 || _agentsArray == "") {
				//alert("Please Select Atleast One Agents");
				this.passingMessage = "* Please Select Atleast One Agents.";
				this.showHidePassingMessage = true;
				this._color = 'red';

				return false;
			}
			let _agents = _agentsArray.map(i => Number(i));
			let _trainer = createdForm.controls.trainer.value;
			let _location = createdForm.controls.location.value;
			let todayDateTime = new Date();
			let _time = todayDateTime.getHours() + ":" + todayDateTime.getMinutes() + ":" + todayDateTime.getSeconds();
			let _modelObject:any = {
				"batch_code": Number(_batchcode),
				"for_group": 2053,
				"trainer_id": Number(_trainer),
				"start_date": _startBatch,
				"end_date": _endBatch,
				"start_time": _batchTime,
				"duration": Number(_duration),
				"capacity": Number(_batchCapacity),
				"agents_list": _agents,
				"training_location": _location
			}
			if(this.files.length > 0){
				_modelObject.files = JSON.stringify(this.files);
			}
			//console.log(_modelObject);
			//debugger;
			this.API.modifyBatch(_modelObject,this.api_end_point).subscribe((data: any) => {
				if (data.success) {
					if (data.success.message == "Batch details updated successfully") {
						// alert("Batch details updated successfully");
						this.passingMessage = "Batch details updated successfully";
						this.showHidePassingMessage = true;
						this._color = 'green';
						setTimeout(() => {
							this.createdForm.reset();
							this.backtobatchList();
						}, 3000);

					}
				} else if (data.errors) {
					// alert(data.errors);
					this.passingMessage = data.errors;
					this.showHidePassingMessage = true;
					this._color = 'red';
				}
			});
		}
		else {

			let _startBatch = this.checkStartDateToday(createdForm.controls.startBatch.value);
			let _endBatch = moment(createdForm.controls.endBatch.value).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');



			let _batchTime = createdForm.controls.batchTime.value;
			let _duration = createdForm.controls.duration.value;
			if (createdForm.controls.startBatch.value == "") {
				this.passingMessage = "Please select batch starts on";
				this.showHidePassingMessage = true;
				this._color = 'red';
				return false;
			}
			if (createdForm.controls.endBatch.value == "") {
				this.passingMessage = "Please select batch ends on";
				this.showHidePassingMessage = true;
				this._color = 'red';
				return false;
			}

			if (timePattern.test(_batchTime) == false) {
				// alert("Please enter time 'hh:mm:ss' format.");
				this.passingMessage = "Please enter batch time 'hh:mm:ss' format.";
				this.showHidePassingMessage = true;
				this._color = 'red';
				return false;
			}
			if (moment(moment(createdForm.controls.startBatch.value)).isSame(moment(), 'day')) {
				var currDate = new Date();
				const h1 = currDate.getHours(), m1 = currDate.getMinutes(), h2 = parseInt(_batchTime.split(':')[0]), m2 = parseInt(_batchTime.split(':')[1]);
				//console.log(h1, h2, m1, m2)
				if (h2 < h1) {
					this.passingMessage = "* Select time greater than current time";
					this.showHidePassingMessage = true;
					this._color = 'red';
					return false;
				}
				if (h2 == h1) {
					if (m2 < m1) {
						this.passingMessage = "* Select time greater than current time";
						this.showHidePassingMessage = true;
						this._color = 'red';
						return false;
					}
				}
			}

			if (createdForm.controls.duration.value > 24) {
				this.passingMessage = "duration should be less than 24*";
				this.showHidePassingMessage = true;
				this._color = 'red';
				return false;
			}
			//console.log(createdForm.controls.capacity.value);
			let _batchCapacity = createdForm.controls.capacity.value;

			//debugger;
			let _agentsArray = (createdForm.controls.agents.value.map(x => x.id));//.toString().replace(/[\[\]']+/g,'');
			if (_agentsArray.length == 0 || _agentsArray == "") {
				// alert("Please Select Atleast One Agents");
				this.passingMessage = "* Please Select Atleast One Agents.";
				this.showHidePassingMessage = true;
				this._color = 'red';
				return false;
			}

			if (createdForm.controls.trainer.value == "") {
				this.passingMessage = "* Please Select Trainer";
				this.showHidePassingMessage = true;
				this._color = 'red';
				return false;
			}
			let _agents = _agentsArray.map(i => Number(i));
			let _trainer = createdForm.controls.trainer.value;
			let _location = createdForm.controls.location.value;
			let todayDateTime = new Date();
			let _time = todayDateTime.getHours() + ":" + todayDateTime.getMinutes() + ":" + todayDateTime.getSeconds();
			let _modelObject:any = {
				"for_group": 2053,
				"trainer_id": Number(_trainer),
				"start_date": _startBatch,
				"end_date": _endBatch,
				"start_time": _batchTime,
				"duration": Number(_duration),
				"capacity": Number(_batchCapacity),
				"agents_list": _agents,
				"training_location": _location
			}

			this.API.saveBatch(_modelObject,this.api_end_point).subscribe((data: any) => {
				if (data.success) {
					if (data.success.message == "Training Batch Created Successfully") {
						//  alert("Training Batch Created Successfully");


						this.passingMessage = "Training Batch Created Successfully";
						this.showHidePassingMessage = true;
						this._color = 'green';
						setTimeout(() => {
							this.createdForm.reset();
							this.backtobatchList();
						}, 3000);


					}
				} else if (data.errors) {
					// alert(data.errors);
					this.passingMessage = data.errors;
					this.showHidePassingMessage = true;
					this._color = 'red';
				}
			});
		}
	}
	formatDate(_date: any) {
		// var date = new Date(_date),
		// mnth = ("0" + (date.getMonth() + 1)).slice(-2),
		// day = ("0" + date.getDate()).slice(-2);
		return moment(_date).format();
	}
	cancelClear() {
		this.createdForm.reset();
	}
	cancelDeleteBatch() {
		this.showHidePopupDelete = false;
	}
	deleteBatch() {
		let _delObj = {
			"batch_code": this.createdForm.value.batchCode,
			"deleteType": "soft"
		}
		this.API.deleteBatch(_delObj,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				// if(data.success.message== "Training Batch Created Successfully"){
				//alert(data.success.message);
				this.showHidePopupDelete = false;
				this.passingMessage = data.success.message;
				this.showHidePassingMessage = true;
				this._color = 'green';
				setTimeout(() => {
					this.backtobatchList();
				}, 4000);
				// }
			}
		});
	}
	formatTime() {
		let timePattern = new RegExp('([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]');
		let _batchTime = this.createdForm.controls.batchTime.value;
		if (timePattern.test(_batchTime) == false) {
			// alert("Please enter time 'hh:mm:ss' format.");
			this.passingMessage = "* Please enter batch time 'hh:mm:ss' format.";
			this.showHidePassingMessage = true;
			this._color = 'red';
			return false;
		}
	}


	uploadNew() {
		const dialogRef = this.dialog.open(BatchCreateUploadDialogComponent, {
			width: '40%',
			disableClose: true,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == true) {
				this.fetchAgents();
			}
		});
	}


	onlyNumberDecimalValidation(evt) {
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode != 46 && charCode > 31
			&& (charCode < 48 || charCode > 57))
			return false;

		if (evt > 24)
			return false;

		return true;
	}

	// fileUploadChange($event:any){
	//   debugger;
	//   let _fileDetails = $event.target.files[0];
	//   let _fileName=$event.target.files[0].name;
	//   let fileInput = document.getElementById('fileID') as HTMLInputElement;
	//   let filePath = fileInput.value;
	//   let allowedExtensions = /(\.pdf|\.exec|\.exe|\.doc|\.docs|\.xlsx)$/i;
	//   if(!allowedExtensions.exec(filePath)){
	//       alert('Please upload file having extensions .pdf/.exec/.exe/.doc/.docs/.xlsx only.');
	//       fileInput.value = '';      
	//       return false;
	//   }
	//   let formData = new FormData();
	//   var query = ``;
	//   formData.append('leads', _fileDetails, _fileDetails.name);
	//   this.fileUploadStatus = 'Uploading...'
	//   this.API.trainingNewCandidateUpload(formData, query, "",this.api_end_point).subscribe(
	//     (res:any) => {
	//       if(res.success){
	//         this.fileUploadStatus = 'File upload done';
	//         this.fetchAgents();
	//       }
	//       setTimeout(()=>{
	//         this.fileUploadStatus = '';
	//       },3000)

	//     }, (err) => {
	//       // debugger;
	//       console.log(err)
	//     })
	//   }


}
