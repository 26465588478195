import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contiinex-ainex-page',
  templateUrl: './contiinex-ainex-page.component.html',
  styleUrls: ['./contiinex-ainex-page.component.scss']
})
export class ContiinexAinexPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
