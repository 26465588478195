import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import{HttpClient, HttpHeaders,HttpResponse }from '@angular/common/http';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Title, Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-new-contiinex-new-contact-page',
  templateUrl: './new-contiinex-new-contact-page.component.html',
  styleUrls: ['./new-contiinex-new-contact-page.component.scss']
})
export class NewContiinexNewContactPageComponent implements OnInit {

  myTitle = 'Contiinex | Contact Us Now';
  createdForm:FormGroup;
  showHideErrorMsg:any;
  msgColor: any = "";
  showHideMsg:boolean=false;
  _path:any=window.location.pathname;
  constructor(private metaTagService: Meta,private titleService:Title,private router: Router,
    private http:HttpClient,
    private fb:FormBuilder,private API: ApiService,private _snackBar: MatSnackBar,protected $gaService: GoogleAnalyticsService) {
      this.createdForm=fb.group({
        firstName:['',[Validators.required,Validators.maxLength(50)]],
        lastName:['',[Validators.maxLength(50)]],
        email:['',[Validators.required,Validators.email]],
        relatedProblem:['',Validators.required],
        message:['',Validators.required],
      })

     }

  ngOnInit() {
    this.titleService.setTitle(this.myTitle);
    this.metaTagService.updateTag(
      { name: 'description', content: 'For more information get in touch with our Contiinex sales team now'}
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: 'For more information get in touch with our Contiinex sales team now'}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: 'For more information get in touch with our Contiinex sales team now'}
    );
    
    this.$gaService.pageView('/Contiinex-contactus', 'Click ContactUs');
  }
  submitBtn(createdForm:any){
    this.showHideMsg=false;
    let _firstName=createdForm.controls.firstName.value;
    let _lastName=createdForm.controls.lastName.value;
    let _email=createdForm.controls.email.value;
    let _relatedProblem=createdForm.controls.relatedProblem.value;
    let _message=createdForm.controls.message.value;

    if(_firstName==""||_email==""||_relatedProblem==""||_relatedProblem==""||_message==""){
      this.showHideErrorMsg="Please fill all fields *.";
      this.showHideMsg=true;
      this.msgColor="red";
      return false;
    }
    else{
      let emailField=createdForm.controls.email.value;
      let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (reg.test(emailField) == false)
      {
        this.showHideErrorMsg="Invalid Email Address";
        this.msgColor="red";
        this.showHideMsg=true;
        //  alert('Invalid Email Address');
          return false;
      }
    let formData={
      "first_name":_firstName,
      "last_name":_lastName,
      "email": _email,
      "query": _relatedProblem,
      "message":_message,
      "source":'Contiinex'
    }
   // debugger;
    this.API.saveContactFormApi(formData).subscribe((data: any) => {
  if(data.success){
    this.$gaService.event('Clicked Submit Form', 'ContactUs Page');
    this.showHideErrorMsg=data.success;
    this.msgColor="green";
    this.showHideMsg=true;
    this.createdForm.reset();

    this._snackBar.open(data.success,"", {
      //  panelClass: 'alert-snackbar-class',
      //  verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 3000,
        });

    setTimeout(()=>{
      this.showHideMsg=false;
      this.showHideErrorMsg="";
      this.msgColor="";
      },5000);
  }
    });
  }
  }

  omit_special_char(event)
  {
   let k;
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  validateEmail(){
    let emailField=this.createdForm.controls.email.value;
    let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (reg.test(emailField) == false)
    {
      this.showHideErrorMsg="Invalid Email Address";
      this.msgColor="red";
      this.showHideMsg=true;
      //  alert('Invalid Email Address');
        return false;
    }
    this.showHideMsg=false;
    return true;

  }
}
