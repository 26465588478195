import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-joinus-business',
  templateUrl: './joinus-business.component.html',
  styleUrls: ['./joinus-business.component.scss']
})
export class JoinusBusinessComponent implements OnInit {
  showHideJoinBusiness :boolean=true;
  showHideJoin :boolean=false;
  createdForm:FormGroup;
  successMsg:string ="";

  constructor(private formbuilder:FormBuilder,private API:ApiService,private location: Location) {
    this.createdForm=this.formbuilder.group({
      salutation:['',Validators.required],
      firstName:['',Validators.required],
      lastName:['',Validators.required],
      companyName:['',Validators.required],
      designation:['',Validators.required],
      mobileNo:['',[Validators.required,Validators.maxLength(10)]],
      email:['', [Validators.required, Validators.email]],
      description:['',Validators.required],
      preferedTime:['',Validators.required]
    })
   }

  ngOnInit() {
  }
  submitBtn(createdForm:any){
   // console.log(createdForm.valid)
    if(!createdForm.valid){
      return;
    }
    this.successMsg = "";
    let _salutation=createdForm.controls["salutation"].value;
    let _firstName=createdForm.controls["firstName"].value;
    let _lastName=createdForm.controls["lastName"].value;
    let _companyName=createdForm.controls["companyName"].value;
    let _designation=createdForm.controls["designation"].value;
    let _mobileNo=createdForm.controls["mobileNo"].value;
    let _email=createdForm.controls["email"].value;
    let _description=createdForm.controls["description"].value;
    let _preferedTime=createdForm.controls["preferedTime"].value;
    let _obj={
    "title":"demo",
    "name":_salutation + ' '+ _firstName + ' ' + _lastName,
    "lastName":_lastName,
    "company":_companyName,
    "designation":_designation,
    "phone":_mobileNo,
    "email":_email,
    "message":_description,
    "category": 'sales',
    "preferedTime":_preferedTime,
    }
//console.log(_obj);
this.API.saveJoinBusiness(_obj).subscribe((data:any)=>{
  if(data.success){
    this.createdForm.reset();
    this.successMsg = "Thank you! We wil get back to you";
    setTimeout(()=>{
      window.location.href = '/'
    },2000)
  }
});
  }
  continueEmail(){
    this.showHideJoinBusiness=false;
    this.showHideJoin=true;
    this.location.replaceState('/joinus');
  }
  omit_special_char(event)
  {   
     let k;  
     k = event.charCode;  //         k = event.keyCode;  (Both can be used)
     return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

onlyNumberValidation(event){
  return event.charCode >= 48 && event.charCode <= 57;
}
}
