import { Component, OnInit,Inject,ViewChild } from '@angular/core';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyserviceService } from 'src/app/myservice.service';
import { MatInput } from '@angular/material/input';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-desktop-contact-list-whatsapp-ui-dialog',
  templateUrl: './desktop-contact-list-whatsapp-ui-dialog.component.html',
  styleUrls: ['./desktop-contact-list-whatsapp-ui-dialog.component.scss']
})
export class DesktopContactListWhatsappUiDialogComponent implements OnInit {
	viewRecord:any=[];
	templateddlValue: any;
	_ddlListExtraOption: any = [];
	stateSettings: any = this.myservice.get_stage_settings();

	_contactOptions: any;
	_wTemplate: any = { options: [] };
	templatepart1: any;

	_rowExtraOptionData: any = [];
	ismedia: boolean = false;
	media: any;


	relationshipManagerModel: any;
	Product_Name: any;
	trackingLinkModel: any;
	phoneNoModel: any;

	Accept_Key: any;
	Reject_Key: any;
	Publisher_Name: any;
	Service_Station: any;
	Vehicle_Number: any;
	Invoice_Number: any;
	Service_Name: any;
	Product_Link: any;
	Web_Link: any;
	dateSelect: any;
	@ViewChild('fromInput', {
		read: MatInput
	}) fromInput: MatInput;
	minDate: any = new Date();
	user: any = this.myservice.get_loggedin_user();
	_paraMessage: any = "";
	selectedWhatsappTemplate: any;
	selectedContact: any;
	api_end_point:any;
	constructor(public dialogRef: MatDialogRef<DesktopContactListWhatsappUiDialogComponent>,@Inject(MAT_DIALOG_DATA) public viewData,private myservice: MyserviceService, private _snackBar: MatSnackBar,private API: ApiService) { }


  ngOnInit() {
	//debugger;
	//console.log(this.user);
	this.api_end_point = this.myservice.get_API_End_Point();
	this.viewRecord=this.viewData;
	let eleemnt= this.viewRecord;
	this.selectedContact =eleemnt;
	this.getDropdownList();
  }
  changeWhatsappTemplate(v) {
	this._rowExtraOptionData = [];
	let _rowExtraOptionData1 = [];
	this.media = '';
	this.ismedia = false;
	this._paraMessage = "";
	_rowExtraOptionData1 = this._ddlListExtraOption.filter(d => { return d.template_name == v });
	this.templatepart1 = _rowExtraOptionData1[0].message;
	if (_rowExtraOptionData1[0].media_url) {
		this.ismedia = true;
		this.media = _rowExtraOptionData1[0].media_url;
	}
	this._paraMessage = _rowExtraOptionData1[0].message;
	this.selectedWhatsappTemplate = _rowExtraOptionData1[0];
	this._rowExtraOptionData = _rowExtraOptionData1[0].required_values;

}
  closeDialog() {
    this.dialogRef.close();
  }
  getDropdownList() {

this._ddlListExtraOption=this.viewRecord._ddlListExtraOption;
}

onlyNumberValidation(event) {
	return event.charCode >= 48 && event.charCode <= 57;
}

saveBtn() {
	//debugger;
	let _date = moment(this.dateSelect).format('YYYY-MM-DD');
	let _templateName = this.templateddlValue;
	let _msg = this._paraMessage;
	let _uid = this.user.id;
	let _agentid = this.user.agentid;
	let _name = this.user.name;
	let _phone = this.user.phone;
	let _address = this.user.address;
	let _company = this.user.company;
	let _email = this.user.email;
	let _msgType = "TEXT";
	let _isHSM = true;
	let _type;
	let _modelObj: any = {};
	var checkEmpty = false;
	let a = this._rowExtraOptionData;
	if (a.Next_Update_Time) {

		_type = a.Next_Update_Time.type;
		if (_date) {
			checkEmpty = true;
		}
		_modelObj = {
			"Next_Update_Time": {
				"value": _date,
				"type": _type
			}
		}
	}
	if (a.Phone_Number) {
		_type = a.Phone_Number.type;
		if (this.phoneNoModel) {
			checkEmpty = true;
		}
		_modelObj = {
			"Phone_Number": {
				"value": this.phoneNoModel,
				"type": _type
			}
		}
	}
	if (a.Tracking_Link) {
		_type = a.Tracking_Link.type;
		if (this.trackingLinkModel) {
			checkEmpty = true;
		}
		_modelObj = {
			"Tracking_Link": {
				"value": this.trackingLinkModel,
				"type": _type
			}
		}
	}
	if (a.Web_Link) {
		let type1 = a.Web_Link.type;
		if (this.Web_Link) {
			checkEmpty = true;
		}
		_modelObj = {
			"Tracking_Link": {
				"value": this.Web_Link,
				"type": type1
			}
		}
	}
	if (a.Accept_Key && a.Reject_Key && a.Publisher_Name) {
		let type1, type2, type3;
		//_type = a.Tracking_Link.type;
		type1 = a.Accept_Key.type;
		type2 = a.Reject_Key.type;
		type3 = a.Publisher_Name.type;

		if (this.Accept_Key && this.Reject_Key && this.Publisher_Name) {
			checkEmpty = true;
		}
		_modelObj = {
			"Accept_Key": {
				"value": this.Accept_Key,
				"type": type1
			},
			"Reject_Key": {
				"value": this.Reject_Key,
				"type": type2
			},
			"Publisher_Name": {
				"value": this.Publisher_Name,
				"type": type3
			}
		}
	}
	if (a.Service_Station && a.Vehicle_Number && a.Service_Name) {
		let type1, type2, type3;
		//_type = a.Tracking_Link.type;
		type1 = a.Service_Station.type;
		type2 = a.Vehicle_Number.type;
		type3 = a.Service_Name.type;

		if (this.Service_Station && this.Vehicle_Number && this.Service_Name) {
			checkEmpty = true;
		}

		_modelObj = {
			"Service_Station": {
				"value": this.Service_Station,
				"type": type1
			},
			"Vehicle_Number": {
				"value": this.Vehicle_Number,
				"type": type2
			},
			"Service_Name": {
				"value": this.Service_Name,
				"type": type3
			}
		}
	} else if (a.Vehicle_Number && a.Invoice_Number) {
		let type1 = a.Vehicle_Number.type;
		let type2 = a.Invoice_Number.type
		if (this.Vehicle_Number && this.Invoice_Number) {
			checkEmpty = true;
		}
		_modelObj = {
			"Vehicle_Number": {
				"value": this.Vehicle_Number,
				"type": type1
			},
			"Invoice_Number": {
				"value": this.Invoice_Number,
				"type": type2
			}
		}
	}
	if (a.Product_Name && a.Relationship_Manager) {
		let type1 = a.Product_Name.type;
		let type2 = a.Relationship_Manager.type
		if (this.Product_Name && this.relationshipManagerModel) {
			checkEmpty = true;
		}
		_modelObj = {
			"Product_Name": {
				"value": this.Product_Name,
				"type": type1
			},
			"Relationship_Manager": {
				"value": this.relationshipManagerModel,
				"type": type2
			}
		}
	}
	else if (a.Product_Name && a.Product_Link) {
		let type1 = a.Product_Name.type;
		let type2 = a.Product_Link.type;
		if (this.Product_Name && this.Product_Link) {
			checkEmpty = true;
		}
		_modelObj = {
			"Product_Name": {
				"value": this.Product_Name,
				"type": type1
			},
			"Product_Link": {
				"value": this.Product_Link,
				"type": type2
			}
		}
	}
	else if (a.Relationship_Manager) {
		let type1 = a.Relationship_Manager.type;
		if (this.relationshipManagerModel) {
			checkEmpty = true;
		}
		_modelObj = {
			"Relationship_Manager": {
				"value": this.relationshipManagerModel,
				"type": type1
			}
		}
	}

	let _obj = {
		"user_id": _uid,
		"customer_details": this.selectedContact,
		"template_data": this.selectedWhatsappTemplate,
	}

	_obj['template_data']['required_values'] = _modelObj;

	if (checkEmpty == false && Object.keys(this.selectedWhatsappTemplate.required_values).length > 0) {
		this._snackBar.open("Fill required details", 'close', {
			duration: 5000,
		});
		return;
	}
	//debugger;
	_obj['customer_details']['Agent ID'] = this.user.id;
	_obj['customer_details']['Name']=_obj['customer_details']['element'].name;
	_obj['customer_details']['Number']=_obj['customer_details']['element'].number;
	_obj['customer_details']['Email']=_obj['customer_details']['element'].email;
	_obj['customer_details']['Address']=_obj['customer_details']['element'].address;
	_obj['customer_details']['Company']=this.user.company;
	_obj['customer_details']['type']='Primary';

	//console.log(_obj);

	this.API.saveContactsWhatsapp1(_obj, this.user.server_url,this.api_end_point).subscribe((res: any) => {
		if (res.status == "success") {
			this.relationshipManagerModel = '';
			this.trackingLinkModel = '';
			this.phoneNoModel = '';
			this.Product_Name = '';
			this.Publisher_Name = '';
			this.Reject_Key = '';
			this.Accept_Key = '';
			this.Product_Link = '';
			this.Service_Station = '';
			this.Vehicle_Number = '';
			this.Service_Name = '';
			this.Web_Link = '';
			this._snackBar.open(res.message, 'close', {
				duration: 3000,
			});
			setTimeout(()=>{
             this.dialogRef.close();
			},4000)
		}
		else if(res.status== "failure"){
			this._snackBar.open(res.message, 'close', {
				duration: 4000,
			});
		}
	});

}


}






