import { Component, OnInit } from '@angular/core';
import{FormGroup,FormControl,FormArray, Validators} from '@angular/forms';
import{MyserviceService} from '../myservice.service';
import { Location } from '@angular/common';
import { ApiService } from '../api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  showHideJoinus:boolean=false;
  showHidePopupPhoneCall:boolean=false;
  currentLoader:boolean=false;
  aParty:any;
  bParty:any;
  session:any;
  api_end_point:string;
  constructor(private _service:MyserviceService,private location: Location,private API:ApiService,private router:Router) {
    // this._service.clearAllItems();
   }
  ngOnInit() {
    // this.session = this.route.snapshot.paramMap.get('session');
    this.session = this.getParameterByName('session');
	this.api_end_point = this._service.get_API_End_Point();
    if(this.session){
      this.currentLoader = true;
      this._service.clearAllItems();
      window.sessionStorage.clear();
      sessionStorage.setItem('from_external', 'true');
      this.processRedirectedUser(JSON.parse(atob(this.session)))

    }
  }

  processRedirectedUser(res){
    var userDetails = res, stageSettings;
    let access_token = res['access_token'];
      let landing_page = '';
      if (access_token) {
        this._service.set_access_token(access_token);
        localStorage.setItem('access_token',access_token);
        this._service.set_loggedin_user(res);
        //   debugger;
        // Calling team api
        // console.log('login team api')
        this.API.teamAPI(this.api_end_point)
          .subscribe(
            (res) => {
                  // debugger;
                //   console.log('printing team response',res)
                this._service.set_my_team(res);

                // Calling settings api
              //   console.log('signin settigs')
                        this.API.settingsAPI(this.api_end_point).subscribe(
                          (res:any) => {
                          //   console.log('printing settings response',res);
                //   debugger;
                    if (res.success) {
                      stageSettings = res.success;
                    this._service.set_stage_settings(res.success);
                          // console.log('signin full team api');
                      if (res.success.web) {
                        for (var i in res.success.web) {
                          if (res.success.web[i]['name'] == 'landing_page') {
                            landing_page = res.success.web[i]['definition'];
                          }
                        }
                      }

                    } else {
                      alert('Error in fetching settings');
                      this.currentLoader = false;
                      this._service.clearAllItems();
                      this.router.navigate(['/managementTeam']);
                      return;
                    }
                    this.API.getFullTeamData(this.api_end_point).subscribe(
                      (res:any) => {
                        if (res.success) {
                          this._service.set_Full_team(res.success);

                          // Routing to Dashboard
                          if(userDetails.role == 'user'){
                            var obj = {"activity_type":"signin","gps_status":"{\"latitude\":22.0860604,\"longitude\":82.188001}","call_recording_status":"false","battery_status":"0","user_id":userDetails.id,"version":"26"} as any;
                            this.API.agentStartEndSession(obj, this.api_end_point).subscribe(
                              (res: any) => {
                                console.log(res)
                              }, (err) => {
                                console.log(err)
                              }
                            )
                            if(landing_page.toLowerCase().includes('audit')){
                              this.router.navigate(['/'+landing_page ? landing_page : 'dashboard']);
                            }else{
                              // if(!userDetails.isVisonEnabled){
                              //   this.router.navigate(['/agentPlugin/face']);
                              //   return;
                              // }
                              // this.API.checkAssessmentGiven(userDetails.groupid,userDetails.id).subscribe(
                              //   (res: any) => {
                              //     if(res.success == 1){
                              //       this.router.navigate(['/agentPlugin/assesment']);
                              //       // return;
                              //     }else{
                              //       this.router.navigate(['/agentPlugin/home']);
                              //     }
                              //   }, (err) => {
                              //     console.log(err);
                              //     this.router.navigate(['/agentPlugin/home']);
                              //   }
                              // )
                              // return;
                              if (stageSettings && stageSettings.extraoptions){
                                for(let opt of stageSettings.extraoptions){
                                  if(opt.name == "biometric" &&  opt.definition == 'true'){
                                    if(!userDetails.isVisonEnabled){
                                      this.router.navigate(['/agentPluginPC/face']);
                                      return;
                                    }
                                  }
                                  if(opt.name == "assessment" &&  opt.definition == 'true'){
                                    this.API.checkAssessmentGiven(userDetails.applicant_group,userDetails.id,this.api_end_point).subscribe(
                                      (res: any) => {
                                        if(res.success == 1){
                                          this.router.navigate(['/agentPluginPC/assessment']);
                                          return;
                                        }else{
                                          this.router.navigate(['/agentPluginPC/home']);
                                        }
                                      }, (err) => {
                                        console.log(err)
                                      }
                                    )
                                  }else{
                                    this.router.navigate(['/agentPlugin/home']);
                                  }
                                }
                              }else{
                                this.router.navigate(['/agentPlugin/home']);
                              }
                            }

                } else {
                  let total_pages = this._service.setMyPages();
                  // console.log('total_pages', total_pages);
                  let page_found:any = false;
                  for (var i in total_pages) {
                    if ((total_pages[i]['data_id'] == landing_page) && total_pages[i]['isAccessed']) {
                      page_found = total_pages[i];
                      break;
                    }
                  }
                  if (page_found) {
                  //	this.router.navigate([page_found['path']]);
                    window.location.href= window.location.protocol + "//" + window.location.host + page_found['path'];
                  } else {
                    this.router.navigate(['/managementTeam']);
                  }
                          }
                        } else {
                          alert(res.error)
                          this.currentLoader = false;
                        }
                      }, (err) => {
                        alert('Error in the full team fetch')
                        this.currentLoader = false;
                    }
                  )
                          },
                          (err) => {
                            alert('Error in the settings api')
                            this.currentLoader = false;
                          }
                        )
            },
            (err) => {
              // debugger;
              alert('Error in the api')
              this.currentLoader = false;
            }
          )
      } else {
        alert('Unathorized access. Contact tech support')
        this.currentLoader = false;
      }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
  joinusBusiness(){
this.showHideJoinus=true;
this.location.replaceState('/joinus-business');
  }
  phoneCall(){
    this.showHidePopupPhoneCall=true;
  }
  closePhoneCallPopup(){
this.showHidePopupPhoneCall=false;

  }
  cancelPhoneCall(){
    this.showHidePopupPhoneCall=false;
  }
  savePhoneCall(){
    let obj={
      "agent_id":this.aParty,
      "customer_id":this.bParty
  }

    this.API.placeCallPhoneSave(obj,this.api_end_point).subscribe((data:any)=>{
      if(data.status=="success"){
        alert(data.message);
        this.showHidePopupPhoneCall=false;
        this.aParty="";
        this.bParty="";
      }
    });

  }
}
