<div *ngIf="showHideJoinBusiness">
    <div class="row" style="margin: 8% 8% 2% auto;">
        <div class="col-md-1"></div>
        <div class="col-md-4 col-sm-12">
            <h2 class="b-title">Request for Business Demo</h2>
            <div class="under_line"></div><br>
            <form [formGroup]="createdForm">
             
    <table class="wd-100">
        <tr><td>
            <div class="input-label-group">
                <label>Salutation*</label>
                <select placeholder="Salutation" formControlName="salutation">
                    <option value="Mr">Mr</option>
                    <option value="Miss">Miss</option>
                    <option value="Dr">Dr</option>
                    <option value="Advocate">Advocate</option>
                  </select>
            </div>

           
              
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>First Name*</label>
                <input matInput autocomplete="off" placeholder="First Name*" (keypress)="omit_special_char($event)" formControlName="firstName">
            </div>

           
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Last Name*</label>
                <input matInput  autocomplete="off" placeholder="Last Name*" (keypress)="omit_special_char($event)" formControlName="lastName">
            </div>
          
        </td></tr>
         <tr><td>
            <div class="input-label-group">
                <label>Company Name*</label>
                <input matInput  autocomplete="off" placeholder="Company Name" formControlName="companyName">
            </div>          
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Designation*</label>
                <input matInput  autocomplete="off" placeholder="Designation" formControlName="designation">
            </div> 
            
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Mobile Number*</label>
                <input matInput  autocomplete="off" placeholder="Mobile Number*" maxlength="10"   (keypress)="onlyNumberValidation($event)" formControlName="mobileNo">
            </div>            
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Email*</label>
                <input matInput  autocomplete="off" placeholder="Email*" formControlName="email">
            </div> 
          
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Description*</label>
                <input matInput  autocomplete="off" placeholder="Description" formControlName="description">
            </div> 
           
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Prefered time to talk*</label>
                <input matInput  autocomplete="off" placeholder="Prefered time to talk" formControlName="preferedTime">
            </div> 
        </td></tr>
    
        <tr><td>
            <button class="JoinSubmit"  (click)="submitBtn(createdForm)">Submit</button>
        </td></tr>
        <tr><td>
            <small style="color: green" *ngIf="successMsg != ''">{{ successMsg }}</small>
        </td></tr>
    </table>
       
</form>
             
        </div>
        <div class="col-md-1">
            <div class="vl"></div>
        </div>
        <div class="col-md-6 col-sm-12">
            <h2 class="b-title">Join a Uearn Agent</h2>
            <div class="under_line"></div><br>
            <p class="pText">Join a community of thousands of people earning already.</p>
    
            <button class="g-btn"><span><a href="#" class="fa fa-google">G</a></span><span>Continue with Google </span> </button><br><br>
            <button class="f-btn"><span><a href="#" class="fa fa-facebook">f</a></span><span>Continue with Facebook </span> </button><br><br>
            <button class="in-btn"><span><a href="#" class="fa fa-linkedin">in</a></span><span>Continue with Linkedin </span> </button>
     <p class="orText">OR</p>
     <button class="E-btn" (click)="continueEmail()"><span><i class="fa fa-envelope" ></i></span><span>Continue with Email </span> </button><br><br>
          
        
        </div>
    </div>
    
</div>


<div *ngIf="showHideJoin">
    <app-joinus></app-joinus>
</div>