import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-call-during-form-summary-dialog',
  templateUrl: './call-during-form-summary-dialog.component.html',
  styleUrls: ['./call-during-form-summary-dialog.component.scss']
})
export class CallDuringFormSummaryDialogComponent implements OnInit {
  // _dataRecord:any=[];
  treeView: any[] = [];
  isToggle: boolean[] = [false,false,false,false,false,false,false];
    @Input()
  public removeSubSection: Function;
  @Input()
  public untitleToggle: Function;
  @Input() selectedIndex: string;
@Input() selectedChildIndex: string;
  @Input() selectedLevel: any;
  @Input() radioIndex: any;
@Input() checked: any;

  constructor(public dialogRef: MatDialogRef<CallDuringFormSummaryDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData,) {
    //console.log(viewData)
    this.treeView = viewData;
  }

  ngOnInit(): void {
    // this._dataRecord=[{title:'Personal Details'},{title:'Education Details'},{title:'Experience Details'}]
  }
  closeDialog() {
    this.dialogRef.close();
  }
  togglepanel(index) {
   // console.log(index)
    this.isToggle[index] = !this.isToggle[index];
  }
  getAlphabets(num) {
    if (num < 1 || num > 26 || typeof num !== 'number') {
      return -1;
    }
    const leveller = 64;
    return String.fromCharCode(num + leveller);
  }
  trigerRemoveSubSection(){
   // console.log(this.selectedIndex)
    this.removeSubSection(this.selectedIndex,this.selectedChildIndex, this.selectedLevel,this.radioIndex)
  }
  triggerToggle(event){
   // console.log(this.selectedIndex)
    this.untitleToggle(this.selectedIndex,event, this.selectedLevel,this.selectedChildIndex, this.radioIndex)
  }
}
