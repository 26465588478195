<form [formGroup]="createdForm">
    <div class="containercss"> 
        <h3>Welcome for signin</h3>   
           <span class="spancs">
            <input class="inpt" required type="email" placeholder="Email" formControlName="email">
           </span> 
        <span class="spancs">
            <input class="inpt" required type="password" placeholder="Password" formControlName="password">
        </span>
        <span class="spancs"> 
            <button class="signbtn" (click)="signBtn(createdForm)">Signin</button>
        </span>
        <span class="spancs"> 
            <b class="bkres"><a [routerLink]="['/home']" routerLinkActive class="signcs">Back</a></b>
            <b class="bkres">Reset</b>
        </span>       
        </div>

</form>
<!-- <app-dashboard [childMessage]="parentMessage"></app-dashboard> -->
   
