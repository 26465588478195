<div class="p-2 pb-0">
	<table class="tbl1DD text-center">
		<tr style="background: #f2f2f2;">
			<td><b>Name of the customer </b> </td>
			<td><b>Mobile Number </b></td>
			<td><b>Email id </b> </td>
		</tr>
		<tr>
			<td>{{viewData.Name|| '--'}} </td>
			<td> {{viewData.Number|| '--'}}</td>
			<td> {{viewData.Email || '--'}}</td>
		</tr>

	</table>
	<!-- <div class="row" *ngIf="arrayCustomVs.length==0">
		<div class="col-md-6 pr-0">
			<table class="tbl1DD ctbl1">
				<tr>
					<td><b>Report Date </b> </td>
					<td> 17-06-2022</td>
				</tr>
				<tr>
					<td><b>Lead Reference Number</b> </td>
					<td>12345678</td>
				</tr>
				<tr>
					<td><b>Application Number </b> </td>
					<td>12345678</td>
				</tr>
				<tr>
					<td><b>Source Code </b> </td>
					<td> S1234</td>
				</tr>
				<tr>
					<td><b>Promo Code </b> </td>
					<td>PROMOCD1</td>
				</tr>
				<tr>
					<td><b>Soft Decision Status</b> </td>
					<td>Active</td>
				</tr>
				<tr>
					<td><b>No Doc Flag</b> </td>
					<td> PANCARD</td>
				</tr>
				<tr>
					<td><b>Appointment Date </b> </td>
					<td> 15-06-2022</td>
				</tr>

				<tr>
					<td><b>Occupation Type </b> </td>
					<td> General</td>
				</tr>
			</table>
		</div>
		<div class="col-md-6">
			<table class="tbl1DD ctbl1">
				<tr>
					<td><b>Residence Address </b> </td>
					<td> HSR Layout</td>
				</tr>
				<tr>
					<td><b>Residence City</b> </td>
					<td>Bangalore</td>
				</tr>
				<tr>
					<td><b>Residence Pin Code</b> </td>
					<td> 560102</td>
				</tr>
				<tr>
					<td><b>Office Address </b> </td>
					<td>HSR Layout</td>
				</tr>
				<tr>
					<td><b>Office City</b> </td>
					<td> Bangalore</td>
				</tr>
				<tr>
					<td><b>Office Pin Code </b> </td>
					<td>560102</td>
				</tr>
				<tr>
					<td><b>Designation</b> </td>
					<td>Software Engineer</td>
				</tr>
				<tr>
					<td><b>Card Type</b> </td>
					<td>General Card</td>
				</tr>
				<tr>
					<td><b>Originating Source (platform of lead)</b> </td>
					<td> Org Source</td>
				</tr>
			</table>
		</div>
	</div> -->
	<div *ngIf="cust && arrayCustomVs.length > 0">

		<div class="row mt-0" >	
			<!-- <div class="col-md-12">
				<div style="background: #e9e7e7;font-weight: 600;padding: 10px;">CustomKVS</div>
			</div>	 -->
			<div class="col-md-12" style="column-count: 2">
				<table class="tbl1DD ctbl1">
					<tr *ngFor="let b of arrayCustomVs">
						<td><b> {{b.labelName}} </b></td>
						<td>{{b.valueOfLabel}}</td>
					</tr>
					<tr *ngIf="oddOrEven(arrayCustomVs.length)"><td>--</td><td>--</td></tr>
				</table>
			
			</div>
		</div>
	</div>


	<div class="vOkFx mt-4">

		<p class="text-right"><button (click)="closeDialog()" class="okBtn1">OK</button></p>

	</div>
</div>