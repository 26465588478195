<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <app-menu  [navigateLink]="currentTitle" ></app-menu>
    </mat-drawer>

<div class="CN-Main sbPad" style="padding-bottom: 16px;">
    <p class="CN-Tab-head">Shopify Settings</p>
    <div>
        <div class="row sopifySetTabList">
            <div>
                <mat-button-toggle-group class="dashSopSetboxcs" #group="matButtonToggleGroup"
                [value]="selectedButtonToggleVal" (change)="onToggleButtonValChange(group.value)">
                <mat-button-toggle value="channelSetting">Channel settings</mat-button-toggle>
                <mat-button-toggle value="templateSetting">Template Settings</mat-button-toggle>
            </mat-button-toggle-group>
            </div>
        </div>

<div class="mt-4">
    <mat-card style="border-radius: 4px;min-height: calc(100vh - 240px);padding: 20px 40px;">  
        <div *ngIf="isShowChannel">
            <p class="pHd"><b>Widget Channel Configuration</b></p>
            <p class="pHd2">You can select/deselect the configured channels</p>
            <p class="mt-4 vcx" >
                <mat-checkbox class="mr-3 wcc1" [(ngModel)]="waModel">WhatsApp</mat-checkbox>
                <mat-checkbox class="mr-3 wcc1" [(ngModel)]="chatModel">Chat</mat-checkbox>
                <mat-checkbox class="mr-3 wcc1" [(ngModel)]="callModel">Call</mat-checkbox>
                <mat-checkbox class="mr-3 wcc1" [(ngModel)]="textModel">Text Me Back</mat-checkbox>
            </p>
            <p class="mb-0"> <button class="go-Submit" (click)="updateBtn()">UPDATE</button></p>
        </div>

      <div *ngIf="isShowTemplate">
          <div class="row">
              <div class="col-md-9">
                <p class="pHd"><b>Template Configuration</b></p>
                <p class="pHd2">Select / Deselect Template</p>
              </div>
              <div class="col-md-3">
                <div class="inputlabelgroup mt-3">          
                 <p>  <mat-select placeholder="Select">
                        <mat-option value="WhatsApp">WhatsApp</mat-option>
                        <mat-option value="Chat">Chat</mat-option>
                        <mat-option value="Call">Call</mat-option>
                        <mat-option value="TextMeBack">Text Me Back </mat-option>
                      </mat-select></p> 
                </div> 
            </div>
          </div>

<!--Start table of template channel-->

<div class="row">
    <div class="col-md-12">

<mat-table [dataSource]="dataSourceTemplate" class="mTabTemplate">     
    <ng-container matColumnDef="notificationEvent">
      <mat-header-cell *matHeaderCellDef style="flex:0 0 200px;"> Notification Event </mat-header-cell>
      <mat-cell *matCellDef="let element" style="flex:0 0 200px;"> {{element.notificationEvent}} </mat-cell>
    </ng-container>      
    <ng-container matColumnDef="content">
      <mat-header-cell *matHeaderCellDef style="flex:0 0 245px;"> Content </mat-header-cell>
      <mat-cell *matCellDef="let element" style="flex:0 0 245px;"> {{element.content}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="partner">
      <mat-header-cell *matHeaderCellDef > Partner </mat-header-cell>
      <mat-cell *matCellDef="let element" >
           <!-- {{element.partner}}  -->
           <div class="inputlabelgroup mt-3">          
            <p>  <mat-select placeholder="Select" [(value)]="element.partner" class="partCs1">
                   <mat-option value="Clickpost">Click Post</mat-option>
                 </mat-select></p> 
           </div> 
        
        
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef style="flex: 0 0 150px;"> Status </mat-header-cell>
      <mat-cell *matCellDef="let element"style="flex: 0 0 150px;" > <span [ngClass]="element.status == 'Active' ? 'my_class1' : 'my_class2'">{{element.status}} </span>

        <mat-slide-toggle class="slideONOffClass ml-2" [checked]="element.status === 'Active' ? true : false"></mat-slide-toggle>
      </mat-cell>
    </ng-container>      
    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell *matCellDef="let element">
              <button class="actEditBtn1" (click)="editTemplateProfileDialog(element)">Edit</button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="preview">
        <mat-header-cell *matHeaderCellDef> Preview </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <img src="../assets/contiinex/image/previewEyeTemplate1.PNG" style="width:49px;cursor: pointer;"/>
        </mat-cell>
      </ng-container>
    <mat-header-row *matHeaderRowDef=displayed_columns></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayed_columns"></mat-row>
  </mat-table>
  <mat-paginator #paginatorA="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</div>
</div>


<!--End table of template channel-->


      
      </div>
    </mat-card>
</div>
    </div>





  <!--start popup success error dialog-->
    <div class="vk-popupmodal">
        <div class="vk-popupmodal-content">
            <p class="text-center mb-0"><mat-icon [ngStyle]="{'color':icon_color}" style="font-size: 35px;" >{{iconTextSW}}</mat-icon></p>
            <h1 style="font-size: 17px;font-weight: 400;text-align: center;" [ngStyle]="{'color': iconTextSW=='warning' ? 'red' : 'black' }"><b>{{msg_Display}}</b></h1>
            <p class="text-center mt-4"> <button class="vk-popclose-button vk-popclose-ok-button vk-go-OK ">OK</button></p>
        </div>       
    </div>
<!--end popup success error dialog-->

</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>

