<mat-drawer-container autosize>
  <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
      <app-menu  [navigateLink]="currentTitle" ></app-menu>
  </mat-drawer>
<div class="CN-Main sbPad">
    <p class="CN-Tab-head">Shopify Team</p>
    <mat-card>
        <div>
            <div class="row sopifySetTabList">
                <div class="col-md-4">
                    <p class="pHd"><b>Agent List</b></p>
                </div>
                <div class="col-md-8" style="text-align: right;">
                   <button class="addTeamBtn" (click)="addTeamMember()"><mat-icon class="mr-2">add</mat-icon> Add Team Member</button>
                </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-table [dataSource]="dataSource" class="mTabTeam">     
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                    </ng-container>      
                    <ng-container matColumnDef="email">
                      <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                    </ng-container>                 
                    <ng-container matColumnDef="phone">
                      <mat-header-cell *matHeaderCellDef> Phone Number </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.phone}}
                      </mat-cell>
                    </ng-container>      
                    <ng-container matColumnDef="job_profile">
                        <mat-header-cell *matHeaderCellDef style="flex:0 0 140px;"> Category </mat-header-cell>
                        <mat-cell *matCellDef="let element" style="flex:0 0 140px;"> {{element.job_profile}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="edit">
                        <mat-header-cell *matHeaderCellDef style="flex:0 0 50px;"> </mat-header-cell>
                        <mat-cell *matCellDef="let element" style="flex:0 0 50px;">
                            <i class="fa fa-pencil-square-o" (click)="editBtn(element)" matTooltip="Edit" style="font-size: 28px;color: #dd133c;position: relative;top: 2px;cursor: pointer;" aria-hidden="true"></i>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef style="flex:0 0 50px;"> </mat-header-cell>
                        <mat-cell *matCellDef="let element" style="flex:0 0 50px;">
                            <mat-icon matTooltip="Delete" style="color: #dd133c;font-size: 29px;cursor: pointer;" (click)="deleteDialog(element)">cancel</mat-icon>
                        
                        </mat-cell>
                      </ng-container>
                    <mat-header-row *matHeaderRowDef=displayed_columns></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayed_columns"></mat-row>
                  </mat-table>
                  <mat-paginator class="mpage" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>

        </div>

    </div>
    </mat-card>
</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>