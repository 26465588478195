<app-menu [navigateLink]="currentTitle"></app-menu>
<h4 class="titlehead">OPS Tech Support Dashboard</h4>
<table class="maintable">
	<tr>
		<td>
			<div class="containecs">
				<div class="colcomn">
					<div class="card">
						<div class="card-header">UEARN ID</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Total</td><td>{{ ops_ts_summary.uearn.total }}</td></tr>
								<tr><td>ID Created</td><td>{{ ops_ts_summary.uearn.created }}</td></tr>
								<!-- <tr><td>Data Called</td><td></td></tr> -->
							</table>               						
						</div> 					   
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">NITTO ID</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Total</td><td>{{ ops_ts_summary.nitto.total }}</td></tr>
								<tr><td>ID Created</td><td>{{ ops_ts_summary.nitto.created }}</td></tr>
							</table>
						</div>					   
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">PROJECT</div>
						<div class="card-body">
							<table  class="tablecs">
								<tr><td>Total</td><td>{{ ops_ts_summary.project.total }}</td></tr>
								<tr><td>Assigned</td><td>{{ ops_ts_summary.project.assigned }}</td></tr>
							</table>
						</div>					   
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">APPS</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Total</td><td>{{ ops_ts_summary.app.total }}</td></tr>
								<tr><td>Assigned</td><td>{{ ops_ts_summary.app.installed }}</td></tr>						   
							</table>
						</div>					   
					</div>
				</div>
			</div>
		</td>
	</tr>
</table>
<div class="pDiv">
	<div class="divprintRt">
	   <table style="width: 100%;">
		  <tr>
			 <td>
				 <span class="recName">
					 Ops Onboarding Dashboard
				 </span>
				 <span class="datecs">{{ today }}</span>
			  </td>
			 <td style="text-align: right;position: relative;right: 20px;">
				<span class="searchSpan">
				   <mat-form-field >
					  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
					  <i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
				   </mat-form-field>
				</span>
			 </td>
		  </tr>
		  <tr>
			 <td>
				<p> 
					<span class="das-pe">Print</span>
				   <span class="das-pe">Export</span>
				   <span class="das-pe" (click)="refreshData()">Refresh</span>
				</p>
			 </td>
		  </tr>
	   </table>
	</div>
	<!--start table-->
	<div>
	   <mat-table [dataSource]="dataSource">
		  <ng-container matColumnDef="applicant_id">
			 <mat-header-cell *matHeaderCellDef> Applicant Id </mat-header-cell>
			 <mat-cell *matCellDef="let element"> {{element.applicant_id}} </mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="applicant_name">
			 <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
			 <mat-cell *matCellDef="let element"> {{element.applicant_name}} </mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="applicant_email">
			 <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
			 <mat-cell *matCellDef="let element"> {{element.applicant_email}} </mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="reg_date">
			 <mat-header-cell *matHeaderCellDef> Reg Date </mat-header-cell>
			 <mat-cell *matCellDef="let element"> {{element.reg_date}} </mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="uearn_id">
			 <mat-header-cell *matHeaderCellDef> UEARN ID </mat-header-cell>
			 <mat-cell *matCellDef="let element"> {{element.user_id}} </mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="nitto_id">
			 <mat-header-cell *matHeaderCellDef> NITTO ID </mat-header-cell>
			 <mat-cell *matCellDef="let element" class="grid-display">
				 <span>{{element.custom_id}}</span>  
				 <span>{{element.custom_id_created}}</span>  
				</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="app_status">
			 <mat-header-cell *matHeaderCellDef> App Status </mat-header-cell>
			 <mat-cell *matCellDef="let element" class="grid-display">
				 <span [ngStyle]="{color: statusColor(element.app_status)}">{{element.app_status }}</span>
				  <span>{{element.source_app_version }}</span>
				</mat-cell>
		  </ng-container>
		  <ng-container matColumnDef="edit">
			<mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
			<mat-cell *matCellDef="let element"> 
				<i class="fas fa-pencil-alt editpencil"  (click)="editBtnClick($event, element)"></i>
			</mat-cell>
		 </ng-container>
		  <mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
		  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
	   </mat-table>
	   <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
	</div>
	<!--end table-->
 </div>
 <div id="cover-spin" *ngIf="currentLoader"></div>