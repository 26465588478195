import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Routes, RouterModule, Router,ActivatedRoute, Params  } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import {MyserviceService}from '../../myservice.service';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-contiinex-register-newui',
  templateUrl: './contiinex-register-newui.component.html',
  styleUrls: ['./contiinex-register-newui.component.scss']
})

export class ContiinexRegisterNewuiComponent implements OnInit {
  createdForm: FormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  _heading:any="Register Here...";
  _selectProductValue:any;
  disabledBtnRegister:boolean=false;
  ddlProductValue:any="WABA";
  registration_data:any;
  onboard_preparatory_data:any;

  showHideRegisterPage:boolean=true;
  showHideWPOnBoardingPage:boolean=false;
  showHideCreateWPAccountPage:boolean=false;
  showHideTransferExistingPage:boolean=false;
  constructor(private formbuilder: FormBuilder,private router:Router,private API: ApiService,private _service:MyserviceService,private _snackBar: MatSnackBar,protected $gaService: GoogleAnalyticsService) {
    window.scroll(0,0);
    this.createdForm = this.formbuilder.group({
      fullName: ['', [Validators.required]],
      companyName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.maxLength(250),Validators.minLength(5), Validators.pattern(/.+@.+\..+/)]],
      mobileNo: ['', [Validators.required,Validators.minLength(10), Validators.maxLength(15),Validators.pattern("^[0-9]*$")]],
      password: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(15)]],
      confirmPassword: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(15)]],
    })
  }

  ngOnInit() {
    this.registration_data = {
      name: 'User'
    };
    let _setDefaultProduct= this.API.getDefaultProductValue();
    if(_setDefaultProduct=="WABA"){
    this.ddlProductValue=_setDefaultProduct;
    this._selectProductValue= _setDefaultProduct;
    }
  }
  onChangeProduct(val:any){
this._selectProductValue=val;
  }
  backToLogin(){
    this.router.navigateByUrl('/Login');
    this.$gaService.event('Clicked Back To Login', 'Register Page');
  }
  registerBtn(createdForm: any){
    const charpattern = new RegExp("^[a-zA-Z ]*$");
    const phonepattern= new RegExp("^[0-9]");
    this.showHideMessage = false;

    let _fullName = createdForm.controls["fullName"].value;
    let _companyName = createdForm.controls["companyName"].value;
    let _email = createdForm.controls["email"].value;
    let _mobileNo = createdForm.controls["mobileNo"].value;
    let _password = createdForm.controls["password"].value;
    let _confirmPwd=createdForm.controls["confirmPassword"].value;

   if(_fullName=="" || _fullName==null){
    this._snackBar.open("Please Enter Full Name", 'close', {
      duration: 5000,
    });
  return;
   }
   if(_companyName=="" || _companyName==null){
    this._snackBar.open("Please Enter Company Name", 'close', {
      duration: 5000,
    });
  return;
   }
   if(_email=="" || _email==null){
    this._snackBar.open("Please Enter Valid Email", 'close', {
      duration: 5000,
    });
  return;
   }
   if(_mobileNo=="" || _mobileNo==null){
    this._snackBar.open("Please Enter Mobile Number", 'close', {
      duration: 5000,
    });
  return;
   }
   if(_password=="" || _password==null){
    this._snackBar.open("Please Enter Password", 'close', {
      duration: 5000,
    });
  return;
   }
    if(_password != _confirmPwd)
    {
      this._snackBar.open("Oh, Password and Confirm Password didn't match", 'close', {
        duration: 5000,
      });
    return;
    }

    this.disabledBtnRegister=true;
    this._selectProductValue="WABA";
    let _obj = {
      "name": _fullName,
      'company':_companyName,
      "email": _email,
      "phone": _mobileNo,
      "password": _password,
      "role": "superadmin",
      "businessType" : this._selectProductValue
    };

    if(this._selectProductValue=="WABA"){
      _obj["project"] = 'WABA';
    }

    this.API.saveJoin(_obj).subscribe((res: any) => {
     // console.log(res);
      if(res.errors){
      //  alert(res.errors);
        this._snackBar.open(res.errors, 'close', {
          duration: 5000,
        });
        this.disabledBtnRegister=false;
        return;
      } else {
        this.$gaService.event('Clicked Register', 'Register Page');

        localStorage.setItem('ext-u',_email);
        localStorage.setItem('ext-p', _password);

        this.registration_data = _obj;

        if(this._selectProductValue=="WABA"){
          let access_token = res['token'];
          if (access_token) {
            this._service.set_access_token(access_token);
            this._service.set_loggedin_user(res);
          }
         // debugger;
          if(this._selectProductValue=="WABA"){
            localStorage.removeItem('setWabaVal_prp');
            localStorage.setItem('setWabaVal_prp','WABA');
            }

         // this.router.navigateByUrl("/");
          setTimeout(()=>{
         //   window.location.href = "/Profile-Settings";
         // this.router.navigateByUrl('/Profile-Settings');
          this.showHideRegisterPage=false;
    this.showHideWPOnBoardingPage=true;
    this.showHideCreateWPAccountPage=false;
    this.showHideTransferExistingPage=false;
          },1000)
        }
        else if(this._selectProductValue=="Comnex"){
    this.router.navigateByUrl("/");
   setTimeout(()=>{
      window.location.href = "/Profile-Settings";
    this.router.navigateByUrl('/Profile-Settings');
     this.showHideRegisterPage=false;
this.showHideWPOnBoardingPage=false;
this.showHideCreateWPAccountPage=false;
this.showHideTransferExistingPage=false;
     },1000)
        }
else{

}
      }
    });

  }

  omit_special_char_num(event) {
    let k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);



  }
  onlyNumberValidation(event) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

  resetForm(){
    this.createdForm.reset();
    this.disabledBtnRegister=false;
  }
  conweb(){
   // this.router.navigateByUrl('/Contiinex-website');
   // window.location.href="/";
    window.location.href="https://www.contiinex.com/";
    this.$gaService.event('Clicked Contiinex Logo', 'Register Page');
  }
  createNewWBA(){
    this.onboard_preparatory_data = {new_waba: true};
    this.showHideRegisterPage=false;
    this.showHideWPOnBoardingPage=false;
    this.showHideCreateWPAccountPage=true;
    this.showHideTransferExistingPage=false;
  }
  transferExistingWBA(){
    this.onboard_preparatory_data = {transfer_waba: true};
    this.showHideRegisterPage=false;
    this.showHideWPOnBoardingPage=false;
    this.showHideCreateWPAccountPage=false;
    this.showHideTransferExistingPage=true;
  }
  backToOnboard(){
    this.showHideRegisterPage=false;
    this.showHideWPOnBoardingPage=true;
    this.showHideCreateWPAccountPage=false;
    this.showHideTransferExistingPage=false;
  }
  backToRegisterPage(){
    this.showHideRegisterPage=true;
    this.showHideWPOnBoardingPage=false;
    this.showHideCreateWPAccountPage=false;
    this.showHideTransferExistingPage=false;
    this.createdForm.reset();
  }
  updateWabaAndNavigateToDashboard() {
    let obj = {
      waba_details: {
        preparatory_data: this.onboard_preparatory_data,
        account: [
          {"title":"Verified Business Name", "desc":"","borderColor":"#c0eee1","bColor":"#f8fffd"},
          {"title":"Account Connection Status", "desc":"","borderColor":"#afe2e9","bColor":"#f0fdff"},
          {"title":"Business Number", "desc":"","borderColor":"#ded7f4","bColor":"#f5f4f8"},
          {"title":"Business Verification Status", "desc":"","borderColor":"#f4dae3","bColor":"#fff4f7"}
        ],
        "profile": {
          "businessNameRO": false,
          "businessName": "",
          "displayNameRO": false,
          "displayName": "",
          "businessDescription": "",
          "websiteURL": "",
          "businessManagerIDRO": false,
          "businessManagerID": "",
          "waNumberRO": false,
          "waNumber": "",
          "uploadFileImg": "",
          "alertEmail": "",
          "businessType": "",
          "companyDescription": "",
          "companyAddress": "",
          "statusCB": "",
          "receivedCB": "",
          "bearerToken": ""
        }
      }
    };

    this.API.updateWabaProfile('new',obj).subscribe((res: any) => {
      this.router.navigateByUrl("/");
      setTimeout(()=>{
        window.location.href = "/Profile-Settings";
        this.router.navigateByUrl('/Profile-Settings');
      },1000)
    });
  }
  alreadyBusinessAccount(){
    this.onboard_preparatory_data["existing_fb_business"] = true;
    this.updateWabaAndNavigateToDashboard();
  }
  createNewBusinessAccount(){
    this.onboard_preparatory_data["new_fb_business"] = true;
    this.updateWabaAndNavigateToDashboard();
  }
  sendServiceRequest() {
    this.onboard_preparatory_data["support_requested"] = true;
    this.updateWabaAndNavigateToDashboard();
  }
}
