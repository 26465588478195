<div *ngIf="callStatus && callStatus.dialing && !showHideStartCallButton" class="text-center">
	<table style="width:100%">
	  <tr>
		<td style="width: 8%;">
		  <mat-icon style="color: gray;">add_circle_outline</mat-icon>
		</td>
		<td style="width: 77%;">
		  <div class="output">
			<input id="code" class="numInput" readonly>
		  </div>
		</td>
		<td><i class="fa fa-close CR" (click)="removeNum()" style="color: gray;" aria-hidden="true"></i></td>
	  </tr>
	</table>
  
	<div class="row">
	  <div class="digit" id="one" (click)="clickNumBtn(1)">1</div>
	  <div class="digit" id="two" (click)="clickNumBtn(2)">2
		<div class="sub">ABC</div>
	  </div>
	  <div class="digit" id="three" (click)="clickNumBtn(3)">3
		<div class="sub">DEF</div>
	  </div>
	</div>
	<div class="row">
	  <div class="digit" id="four" (click)="clickNumBtn(4)">4
		<div class="sub">GHI</div>
	  </div>
	  <div class="digit" id="five" (click)="clickNumBtn(5)">5
		<div class="sub">JKL</div>
	  </div>
	  <div class="digit" (click)="clickNumBtn(6)">6
		<div class="sub">MNO</div>
	  </div>
	</div>
	<div class="row">
	  <div class="digit" (click)="clickNumBtn(7)">7
		<div class="sub">PQRS</div>
	  </div>
	  <div class="digit" (click)="clickNumBtn(8)">8
		<div class="sub">TUV</div>
	  </div>
	  <div class="digit" (click)="clickNumBtn(9)">9
		<div class="sub">WXYZ</div>
	  </div>
	</div>
	<div class="row">
	  <div class="digit">*
	  </div>
	  <div class="digit" (click)="clickNumBtn(0)">0
		<div class="sub">+</div>
	  </div>
	  <div class="digit">#
	  </div>
	</div>
  </div>
  
  <div class="row" style="justify-content: center;" *ngIf="(showHideConnecting == false && showHide_Connected == false)">
	<!-- <div class="call col-md-8" (click)="startSession()" *ngIf="!sessionStarted">START SESSION</div> -->
	<!-- <div class="call col-md-8" style="background:#e10f59 !important;" (click)="endSession()" *ngIf="sessionStarted">END SESSION</div> -->
	 <div class="call col-md-5" (click)="startCallLoading()"  *ngIf="callStatus && callStatus.dialing && showHideCallPad == true">START CALL</div>
	<div class="call col-md-5" (click)="startSession()" *ngIf="!sessionStarted">START</div>
	<div class="call callStop col-md-5" (click)="endSession()" *ngIf="sessionStarted">STOP</div>
	<div class="col-md-2" *ngIf="!showHideCallPad && sessionStarted == true" style="margin: 14px;">
	  <mat-icon class="dpad"  (click)="startCallLoading()">dialpad</mat-icon>  
	</div>
  
  
  </div>
  <div *ngIf="showHideStartCall">
	<!-- <img class="imgLoader" width="100" src="../assets/loaderIcon.svg"/> -->
	<div>
  
	</div>
	<div>
	  <p class="loader text-center" style="margin-bottom: 0;">
	  </p>
	  <img class="imgLoader" width="100" src="../assets/loaderIcon.svg" />
  
	  <p style="color:#e40659;font-weight:bold;padding-left: 15%;">Connecting Please wait…</p>
	</div>
  </div>
  
  <!--start connecting.. UI-->
  <mat-card *ngIf="showHideConnecting">
  <div *ngIf="showHideConnecting" style="margin-top: 2%;">
  
	<div class="startHead" style="margin-left: -16px;margin-right: -16px;">Connecting…</div>
	<p class="text-center pt-4 ml-2 mr-2" style="font-size: 20px;">Wait we are reaching out </p>
	<!-- <p class="text-center pt-1 ml-2 mr-2" style="color:gray;font-size:14px;">If you are in a conversation and haven’t
	  received any details click on
	</p>
	<p class="text-center pt-1 ml-2 mr-2" style="color:gray;font-size:12px;" *ngIf="callStatus && callStatus.widgetUser">
	  <span class="getInfo">Get Info</span>
	</p> -->
	<!-- <p class="text-center pt-1 ml-2 mr-2"><img class="micIcon" src="../assets/micOffIcon.svg" /></p> -->

	<p class="text-center pt-1 ml-2 mr-2" style="color:gray;font-size:12px;">
	  <span class="endCall" (click)="endCall()">END CALL</span>
	</p>
  </div>
  
  <!-- <div *ngIf="showHideConnecting">
  
	<table class="wd-100 text-center" style="border: 1px solid #ccc;">
	  <tr>
		<td><img src="../assets/phoneDisconnect.svg" width="75px" /> </td>
	   
		<td> <img src="../assets/phoneCall.svg" width="59px" /> </td>
	  </tr>
	</table>
  </div> -->
  </mat-card>
  
  <div *ngIf="showHide_Connected" style="margin-top: 2%;">
	<mat-card>
	<div class="startHead" style="background-color: #3fb90d;height: 50px;
	padding: 6px 0 0 20px; margin-left: -16px;margin-right: -16px;">
	  <table class="wd-100">
		<tr>
		  <td>Connected</td>
		  <td class="text-right CR">
			<mat-icon>more_vert</mat-icon>
		  </td>
		</tr>
	  </table>
	</div>
	<p *ngIf="callStatus && callStatus.widgetUser" class="text-center pt-0 ml-2 mr-2">Customer -
	  {{callStatus.widgetUser.name}}</p>
	<p *ngIf="callStatus && callStatus.customerInfo && !callStatus.widgetUser" class="text-center pt-0 ml-2 mr-2">Customer
	  - {{callStatus.customerInfo.customer_name}}</p>
	<div class="queryDiv" style="text-align: -webkit-center;">
	  <table class="text-center" style="width: 100%;">
		<tr>
		  <td *ngFor="let section of callFlowJsonSections;let $index=index" style="padding-left: 15px;padding-top: 15px;">
			<div *ngFor="let child of section.child;let $childIndex=index">
			  <mat-form-field class="wd-100" *ngIf="child.at == 'FT'">
				<mat-label>{{child.q}}</mat-label>
				<input matInput placeholder="" [(ngModel)]="child.answer">
			  </mat-form-field>
  
			  <div *ngIf="child.at == 'RT'">
				{{child.q}}
				<!-- <star-rating-comp style="float: left;" [starType]="'icon'" [size]="'large'" [rating]="4"></star-rating-comp> -->
  
  
  
				<ng-template #t let-fill="fill">
				  <span class="star" [class.full]="fill === 100">
					<span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
				  </span>
				</ng-template>
				<ngb-rating  [max]="5" [(rate)]="child.answer" [starTemplate]="t"></ngb-rating>
  
  
  
  
  
  
  
			  </div>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'FN'" >
				<mat-label>{{child.q}}</mat-label>
				<input matInput placeholder="" type="number" [(ngModel)]="child.answer" >
			  </mat-form-field>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'NP'">
				<mat-label>{{child.q}}</mat-label>
				<input matInput type="number" placeholder="Min Value" [(ngModel)]="child.answer" >
			  </mat-form-field>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'NP'">
				<mat-label>{{child.q}}</mat-label>
				<input matInput placeholder="Max Value" type="number" [(ngModel)]="child.answer" >
			  </mat-form-field>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'DATE'">
				<mat-label>{{child.q}}</mat-label>
				<input matInput [matDatepicker]="picker" placeholder="Date" readonly (click)="picker.open()">
				<mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 25px;"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			  </mat-form-field>
			  
			  <div *ngIf="child.at == 'YN'">
				{{child.q}}<br>
			   <button mat-button class="savbtn">Yes</button>
			  </div>
			  
			  <div *ngIf="child.at == 'YN'">
				{{child.q}}<br>
			   <button mat-button class="savbtn">No</button>
			  </div>
  
			  <div *ngIf="child.at == 'ATTACHMENT'">
				{{child.q}}
				<div class="upload-btn-wrapper">
				  <button class="btnbrowse">Browse File &nbsp;&nbsp; <img src="../assets/BrowsefileIcon.svg"/></button>
			   <input type="file" #fileUpload class="filecs" (change)="changeFile($event,$childIndex)"/>
			   <span style="color: #bababa;margin-left: 15px">{{currentFileobj.name}}</span>
				</div>
			  </div>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'BLANK'">
				<mat-label>{{child.q}}</mat-label>
				<input matInput placeholder="Your booking is successful">
			  </mat-form-field>
  
		   
			  <div  *ngIf="child.at == 'CB'"  style="font-size: 12px;color: gray;">
				<span>{{child.q}}</span><br>
				<div *ngFor="let c of getParsed(child.cb_data); let $mdd_index=index" class="mt-1" style="color:gray;display: inline-block;flex-wrap: wrap;">
						  <mat-checkbox (change)="mddValueChange($event, $index, $childIndex, $mdd_index)" class="pl-2">{{c}}</mat-checkbox>
					  </div>
			  </div>
  
			  <div *ngIf="child.at == 'RB'" style="font-size: 12px;color: gray;">
				<span>{{child.q}}</span><br>
				<mat-radio-group aria-labelledby="radio{{$childIndex}}" [(ngModel)]="child.answer" style="    position: relative;top: 10px;">
				  <mat-radio-button value="{{d}}"[ngClass]="'radioPresent'" *ngFor="let d of getParsed(child.rb_data)" style="padding-right: 12px;">{{d}}</mat-radio-button>
				  
				</mat-radio-group> 
				<br/>
				<div *ngIf="child.answer == 'Enquiry'">
				  <div *ngFor="let rbchild of child.Enquiry;let $rbchildIndex=index" style="margin-top: 10px; margin-left: 10px">
					<span>{{rbchild.q}}</span><br>
					<mat-radio-group  aria-labelledby="radio{{$childIndex}}" [(ngModel)]="rbchild.answer" class="ml-3">
					
					  <mat-radio-button value="{{d1}}" [ngClass]="'radioPresent'" *ngFor="let d1 of getParsed(rbchild.dd_data)" style="padding-right: 12px;">{{d1}}</mat-radio-button>
					
				  </mat-radio-group>
				  <br/>
				  <!-- Testing -->
				<div *ngIf="rbchild.answer == 'Communications'">
					<div *ngFor="let rbchild2 of rbchild.Communications;let $rbchild2Index=index" style="margin-top: 10px; margin-left: 20px">
						<!-- <span>{{rbchild2.q}}</span><br> -->
						<mat-form-field class="wd-100" *ngIf="rbchild2.at == 'SDD'">
							<mat-label>{{rbchild2.q}}</mat-label>
							<!-- <input matInput placeholder="Search" >   -->
							<mat-select placeholder="{{rbchild2.q}}" [(ngModel)]="rbchild2.answer">
								<mat-option [value]="d" *ngFor="let d of getParsed(rbchild2.sdd_data)">{{d}}</mat-option>
							</mat-select>
						</mat-form-field>
						
						<mat-form-field class="wd-100" *ngIf="rbchild2.at == 'MSDD'">
							<mat-label>{{rbchild2.q}}</mat-label>
							<mat-select multiple [(ngModel)]="rbchild2.answer" (selectionChange)="checkNextTrigger($event, rbchild2.next_action)" >
								<mat-option [value]="d" *ngFor="let d of getParsed(rbchild2.msdd_data)">{{d}}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field class="wd-100" *ngIf="rbchild2.at == 'FN'">
							<mat-label>{{rbchild2.q}}</mat-label>
							<input matInput placeholder="" type="number" [(ngModel)]="rbchild2.answer">
						</mat-form-field>
						<div *ngIf="rbchild2.at == 'ATTACHMENT'">
							{{rbchild2.q}}
							<div class="upload-btn-wrapper">
								<button class="btnbrowse">Browse File &nbsp;&nbsp; <img src="../assets/BrowsefileIcon.svg" /></button>
								<input type="file" #fileUpload class="filecs" (change)="changeFileLevel2($event,$childIndex,$rbchild2Index,$rbchildIndex,child.answer,rbchild.answer)" />
								<span style="color: #bababa;margin-left: 15px">{{currentFileobj.name}}</span>
							</div>
						</div>
						<mat-form-field class="wd-100" *ngIf="rbchild2.at == 'DATETIME'">
							<mat-label>{{rbchild2.q}}</mat-label>
							<input matInput [matDatepicker]="dpicker" [(ngModel)]="rbchild2.answer"  placeholder="Date"
								readonly (click)="dpicker.open()">
							<mat-datepicker-toggle matSuffix [for]="dpicker" style="font-size: 25px;"></mat-datepicker-toggle>
							<mat-datepicker #dpicker></mat-datepicker>
						</mat-form-field>
						<div *ngIf="rbchild2.at == 'CB'" style="font-size: 12px;color: gray;">
							<span>{{rbchild2.q}}</span><br>
							<div *ngFor="let c of getParsed(rbchild2.cb_data); let $mdd_index=index" class="mt-1"
								style="color:gray;display: inline-block;flex-wrap: wrap;">
								<mat-checkbox (change)="mddValueChange($event, $index, $childIndex, $mdd_index)" class="pl-2">{{c}}
								</mat-checkbox>
							</div>
						</div>
						<div *ngIf="rbchild2.at == 'YN'">
							<span>{{rbchild2.q}}</span><br>
							<mat-radio-group aria-labelledby="radio{{$rbchild2Index}}" [(ngModel)]="rbchild2.answer"
								class="ml-3">
							
								<mat-radio-button value="Yes" [ngClass]="'radioPresent'" 
									style="padding-right: 12px;">Yes</mat-radio-button>
								<mat-radio-button value="No" [ngClass]="'radioPresent'" 
									style="padding-right: 12px;">No</mat-radio-button>
							
							</mat-radio-group>
						</div>
						
						<!-- <div *ngIf="rbchild2.at == 'YN'">
							{{rbchild2.q}}<br>
							<button mat-button class="savbtn">No</button>
						</div> -->
						<div *ngIf="rbchild2.at == 'RT'">
							{{rbchild2.q}}
							<br/>
							<ng-template #t let-fill="fill">
								<span class="star" [class.full]="fill === 100">
									<span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
								</span>
							</ng-template>
							<ngb-rating  [max]="5" [(rate)]="rbchild2.answer" [starTemplate]="t" ></ngb-rating>
							</div>
					</div>
				</div>
				  <div *ngIf="rbchild.answer == 'YES'" >
					<div *ngFor="let rbchild2 of rbchild.YES" style="margin-top: 10px; margin-left: 20px">
					  <span>{{rbchild2.q}}</span><br>
					  <mat-radio-group  aria-labelledby="radio{{$childIndex}}" [(ngModel)]="rbchild2.answer" class="ml-3">
					  
						<mat-radio-button value="{{d2}}" [ngClass]="'radioPresent'" *ngFor="let d2 of getParsed(rbchild2.rb_data)" style="padding-right: 12px;">{{d2}}</mat-radio-button>
					  
					</mat-radio-group>
					</div>
				  </div>
				  <div *ngIf="rbchild.answer == 'NO'" >
					<div *ngFor="let rbchild2 of rbchild.NO" style="margin-top: 10px; margin-left: 20px">
					  <span>{{rbchild2.q}}</span><br>
					  <mat-radio-group  aria-labelledby="radio{{$childIndex}}" *ngIf="rbchild2.at == 'RB'" [(ngModel)]="rbchild2.answer" class="ml-3">
					  
						<mat-radio-button value="{{d2}}" [ngClass]="'radioPresent'" *ngFor="let d2 of getParsed(rbchild2.rb_data)" style="padding-right: 12px;">{{d2}}</mat-radio-button>
					  
					  </mat-radio-group>
					  <mat-form-field class="wd-100" *ngIf="rbchild2.at == 'FT'">
						<mat-label>{{rbchild2.q}}</mat-label>
						<input matInput placeholder="" [(ngModel)]="rbchild2.answer">
					  </mat-form-field>
					</div>
				  </div>
				  </div>
				</div>
  
				<div *ngIf="child.answer == 'NO'">
				  <div *ngFor="let rbchild of child.NO" style="margin-top: 10px; margin-left: 10px">
					<span>{{rbchild.q}}</span><br>
					<mat-radio-group  aria-labelledby="radio{{$childIndex}}" [(ngModel)]="rbchild.answer" class="ml-3">
					
					  <mat-radio-button value="{{d1}}" [ngClass]="'radioPresent'" *ngFor="let d1 of getParsed(rbchild.rb_data)" style="padding-right: 12px;">{{d1}}</mat-radio-button>
					
				  </mat-radio-group>
				  <br/>
				  <div *ngIf="rbchild.answer == 'NO'" >
					<div *ngFor="let rbchild2 of rbchild.NO" style="margin-top: 10px; margin-left: 20px">
					  <span>{{rbchild2.q}}</span><br>
					  <mat-radio-group  aria-labelledby="radio{{$childIndex}}" [(ngModel)]="rbchild2.answer" class="ml-3">
					  
						<mat-radio-button value="{{d2}}" [ngClass]="'radioPresent'" *ngFor="let d2 of getParsed(rbchild2.rb_data)" style="padding-right: 12px;">{{d2}}</mat-radio-button>
					  
					</mat-radio-group>
					</div>
				  </div>
				  </div>
				</div>
				
				
				  
				  
			  </div>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'SDD'">
				<mat-label>{{child.q}}</mat-label>
				<!-- <input matInput placeholder="Search" >   -->
				<mat-select placeholder="{{child.q}}" [(ngModel)]="child.answer">
				  <mat-option [value]="d" *ngFor="let d of getParsed(child.sdd_data)">{{d}}</mat-option>
				  </mat-select>       
			  </mat-form-field>
	  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'MSDD'">
				<mat-label>{{child.q}}</mat-label>
				<mat-select multiple [(ngModel)]="child.answer">
				  <mat-option [value]="d" *ngFor="let d of getParsed(child.msdd_data)">{{d}}</mat-option>
				</mat-select>         
			  </mat-form-field>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'TIME'">
				<mat-label>{{child.q}}</mat-label>
				<input matInput placeholder="Time" type="time" min="00:00" max="24:00" required style="font-size: 15px !important;">  
			  </mat-form-field>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'DATETIME'">
				<mat-label>{{child.q}}</mat-label>
				<input matInput [matDatepicker]="dpicker" (dateChange)="change_date($event, $index, $childIndex)" placeholder="Date" readonly (click)="dpicker.open()">
				<mat-datepicker-toggle matSuffix [for]="dpicker" style="font-size: 25px;"></mat-datepicker-toggle>
				<mat-datepicker #dpicker></mat-datepicker> 
			  </mat-form-field>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'DATETIME'">
				<mat-label>{{child.q}}</mat-label>
				<input matInput placeholder="Time" type="time" (change)="change_time($event, $index, $childIndex)" min="00:00" max="24:00" required style="font-size: 15px !important;">  
			  </mat-form-field>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'DD'">
				<mat-label>{{child.q}}</mat-label>
			   <mat-select [(ngModel)]="child.answer">
				<mat-option value="{{d}}" *ngFor="let d of getParsed(child.dd_data)">{{d}}</mat-option>
			 </mat-select>
			  </mat-form-field>
  
			  <mat-form-field class="wd-100" *ngIf="child.at == 'FLP'">
				<mat-label>{{child.q}}</mat-label>
			   <input matInput placeholder="" >          
			  </mat-form-field>
  
			
				<div *ngIf="child.at == 'BT'">
				  {{child.q}}<br>
				 <button mat-button class="savbtn">Button</button>
				</div>
			</div>
  
		  </td>
		</tr>
		<!-- <tr><td><br></td></tr>
		<tr><td><p class="call">NEXT</p></td></tr> -->
	  </table>
	</div>

	<!-- <p class="text-center pt-1 ml-2 mr-2"><img class="micIcon" src="../assets/micOffIcon.svg" /></p> -->

	<p class="text-center pt-3 ml-2 mr-2" style="color:gray;font-size:12px;">
	  <span class="endCall" (click)="endCall()">END CALL</span>
	</p>
  </mat-card>
  </div>
  <audio #stream id="remoteAudio" controls style="display: contents;">
	  <p>Your browser doesn't support HTML5 audio.</p>
  </audio>
  <!--end connecting.. UI-->