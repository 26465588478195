import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as highcharts from 'highcharts';
import * as Highcharts from 'highcharts';
import {Router} from '@angular/router'
import {MyserviceService} from '../../myservice.service';
import {ApiService} from "../../api.service";

@Component({
  selector: 'app-inbound-ivr',
  templateUrl: './inbound-ivr.component.html',
  styleUrls: ['./inbound-ivr.component.scss']
})
export class InboundIvrComponent implements OnInit {

  donutChartOutboundIVR:any;
  customer_name:string;
  project_name:string;
  totalAnsMissedChart:any;
  total_time_axis:any;
  answered_time_axis:any[];
  options_selected:any;
  missed_time_axis:any[];
  subscribed_channels:any;
  callDetailsItems:any[];
  category_xaxis:any[];
  call_counts:number[] = [0,0,0,0];
  duration_counts:string[] = ['0h 0m 0s','0h 0m 0s'];
  api_end_point:any;
  constructor(private API:  ApiService, private _service:MyserviceService) {

  }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.answered_time_axis = [];
    this.missed_time_axis = [];

    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = String(this._service.get_current_project()).toLowerCase();
    }

    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().business_process) {
      this.subscribed_channels = JSON.parse(this._service.get_loggedin_user().business_process);
    }

    let time_range = this._service.get_current_time_range();

    this.API.getInboundIvrDashboard(this.customer_name,this.project_name, time_range,this.api_end_point).subscribe((data: any) => {
      if(data.errors){
        console.log('getIvrDashboard failed',data.errors);
      } else {
       // console.log('getIvrDashboard success',data);

        this.category_xaxis = data.axis;

        if (data.options_list) {
          this.options_selected = data.options_list;
        }
        if (data.call_counts && data.call_counts['TOTAL']) {
          this.call_counts[0] = data.call_counts['TOTAL'];
        }
        if (data.call_counts && data.call_counts['ANSWERED-SELECTED']) {
          this.call_counts[1] = data.call_counts['ANSWERED-SELECTED'];
        }
        if (data.call_counts && data.call_counts['ANSWERED-UNSELECTED']) {
          this.call_counts[2] = data.call_counts['ANSWERED-UNSELECTED'];
        }
        if (data.call_counts && data.call_counts['MISSED']) {
          this.call_counts[3] = data.call_counts['MISSED'];
        }

        if (data.duration_counts && data.duration_counts['ACD']) {
          this.duration_counts[0] = data.duration_counts['ACD'];
        }

        if (data.duration_counts && data.duration_counts['ART']) {
          this.duration_counts[1] = data.duration_counts['ART'];
        }

        /*Create empty values array*/
        for (var i in this.category_xaxis) {
          this.answered_time_axis[i] = 0;
          this.missed_time_axis[i] = 0;
        }

        for (var i in data.answered_time_axis) {
          this.answered_time_axis[this.category_xaxis.indexOf(i)] = data.answered_time_axis[i];
        }

        for (var i in data.missed_time_axis) {
          this.missed_time_axis[this.category_xaxis.indexOf(i)] = data.missed_time_axis[i];
        }

       // console.log(this.answered_time_axis,this.missed_time_axis);

      }

      this.getDonutChartOutboundIVR();
      this.gettotalAnsMissedChart();
      this.getCallDetails();

    });

  }

  getCallDetails(){
   this.callDetailsItems = [
      { header: 'Selected', description: this.call_counts[1],content: []},
      { header: 'Not selected', description: this.call_counts[2], content: [] },
      { header: 'Missed', description: this.call_counts[3], content: [] }
    ];

    for (let i in this.options_selected) {
      var mapped_option = 'DTMF';
      if (this.subscribed_channels && this.subscribed_channels.ivr_map && this.subscribed_channels.ivr_map[String(i).split(',').reverse()[0]]) {
        mapped_option = this.subscribed_channels.ivr_map[String(i).split(',').reverse()[0]];
      }
      if (i != 'none') {
        this.callDetailsItems[0].content.push({
          option: mapped_option,
          digit: i,
          total: this.options_selected[i]
        });
      }
    }
  }

  getDonutChartOutboundIVR(){
    this.donutChartOutboundIVR = new Chart({
      title: {
        text: '<small style="color:gray">'+this.call_counts[0]+'</small><br><small style="font-size:0.5em;color:gray">Total Calls</small>',
        align: 'center',
        verticalAlign: 'middle',
        y: -20,
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          dataLabels: {
            distance:-30,
            enabled: false
          },
          showInLegend: true,
          size:'100%',

        }

      },
      legend: {
        itemStyle: {
            fontSize: '0.92em'
        }
      },
      series: [
        {  states: {
          inactive: {
            opacity: 1
          },
          hover: {
          enabled: false
          }
          },
          name: '',
          data: [
            {
              name: 'Answered (Selected)',
              y: this.call_counts[1],
              color:'#606e36'
            },
            {
              name: 'Answered (Not Selected)',
              y: this.call_counts[2],
              color:'#fd6820'
            },
            {
              name: 'Missed',
              y: this.call_counts[3],
              color:'#febd2c'
            }

          ],
          type: 'pie',
          innerSize: '55%'
        }]
    });

  }
  gettotalAnsMissedChart(){
    this.totalAnsMissedChart = new Chart({
      chart: {
        type: 'line',
        height: 260
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories:  this.category_xaxis,
        gridLineWidth: 1,
       minorGridLineWidth: 1
      },
      yAxis: {
        title:null,
        gridLineWidth: 1,
        minorGridLineWidth: 1,

      },
      legend: {
        reversed: false,
        verticalAlign: "top",
        align: "right",
        borderWidth: 0,
      },
      plotOptions: {
        line: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },

      },
      series: [
        {
          type:undefined,
          name: "Answered",
          data: this.answered_time_axis,
          color:'#fd6820'
        },
        {
          type:undefined,
          name: "Missed",
          data: this.missed_time_axis,
          color:'#febd2c'
        }
      ]
    });


}
}
