import { Component, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { CampaignDialogComponent } from './campaign/campaign-dialog/campaign-dialog.component';
import { CreateDialogComponent } from './assessment/create-dialog/create-dialog.component';
import { RecruiterTableComponent } from './recruiter-table/recruiter-table.component';
import { CampaignTableComponent } from './campaign-table/campaign-table.component';
import { AgentsTableComponent } from './agents/agents-table/agents-table.component';
import { AgentsComponent } from './agents/agents.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { AssessmentTableComponent } from './assessment/assessment-table/assessment-table.component';
import { ClientStatusComponent } from './client-status/client-status.component';
import { BatchCreateUploadDialogComponent } from '../auditor/batch-create-upload-dialog/batch-create-upload-dialog.component';import { Utils } from '../utils';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ApiService } from '../api.service';
import { ServicesService } from './services.service';
import { MatInput } from '@angular/material/input';
import { MyserviceService } from '../myservice.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	selectedButtonToggleVal: string = 'campaignValue';
	currentTitle: string = 'dashboard-recruiter';
	selectAgencies: string = '';
	selectSources: string = '';
	selectedLevel: string = '';
	today = moment().format('DD-MMM-YYYY');
	showCampaign: boolean = true;
	showRecruiter: boolean = false;
	showHiring: boolean = false;
	showAssessment: boolean = false;
	showLoader: boolean = false;
	isCampbtnDisable: boolean = true;
	refreshTriggered: boolean = false;
	utils = new Utils();
	startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
	endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	campaign_start = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
	campaign_end = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true);
	campaign_start_ist = this.utils.utcToLocal(this.campaign_start, 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM-YYYY');
	campaign_end_ist = this.utils.utcToLocal(this.campaign_end, 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM-YYYY');
	myUser: any;
	@ViewChild(RecruiterTableComponent) recTable: any;
	@ViewChild(CampaignTableComponent) campTable: any;
	@ViewChild(AgentsTableComponent) agentTable: any;
	@ViewChild(AgentsComponent) agentsSummary: any;
	@ViewChild(AssessmentComponent) assessmentSummary: any;
	@ViewChild(AssessmentTableComponent) assessmentTable: any;
	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;
	@ViewChild('searchInput', {
		read: MatInput
	}) searchInput: MatInput;

	public tableSearch = {
		'value': '',
		'isCampaign': this.showCampaign,
		'isRecruiter': this.showRecruiter,
		'data_filter': ''
	}
	showHideDateCondition:boolean=false;
	showHideSentLinkMsg:boolean=false;
	api_end_point:any;
	constructor(
		private dialog: MatDialog,
		private dashService: ServicesService,
		private API: ApiService,
		private service: MyserviceService
	) { }

	ngOnInit() {
		this.api_end_point = this.service.get_API_End_Point();
		this.onloadDataFn();
		this.myUser = this.service.get_loggedin_user();
	}

	onloadDataFn() {
		// alert('Onload calling');
		this.showLoader = true;
		this.dashService.setMyCampaigns(this.campaign_start, this.campaign_end).then(
			() => {
				// alert('Campaign Data refreshed');
				this.isCampbtnDisable = false;
				this.prepareCampaignSummary();
				this.dashService.setApplicantsData(this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter)).then(
					() => {
						setTimeout(() => {
							this.showLoader = false;
						})
					}, (err) => {
						alert('dash-component, Error in the api service');
						this.showLoader = false;
					}
				)
			}, (err) => {
				alert('dash-component, Error in the api campaign')
				this.showLoader = false;
			}
		)
	}

	resetTrigger() {
		setTimeout(() => {
			this.refreshTriggered = false;
		})
	}
	prepareCampaignSummary() {
		// alert(1);
		this.dashService.myCampaigns.subscribe(
			(d: any) => {
				// debugger;
				let today = moment().format('YYYY-MM-DD');
				let c = this.dashService.campaignSummary_default();
				if (d.length) {
					c.allData = d;
					var agency_data = {};
					// debugger;
					for (var i in d) {
						if (d[i] && (d[i]['status'] == 'assigned')) {
							let loc = d[i];
							let details = JSON.parse(loc.details);
							let created_at = moment(loc.created_at, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
							c.campaigns.total += 1;
							if (loc.active_status == 0) {
								c.campaigns.inactive += 1;
							} else {
								c.campaigns.active += 1;
							}
							if (today === created_at) {
								c.today_campaigns.total += 1;
								if (loc.active_status == 0) {
									c.today_campaigns.inactive += 1;
								} else {
									c.today_campaigns.active += 1;
								}
							}
							if (details) {
								if (!isNaN(details['Total Pushed'])) {
									let total_pushed = parseInt(details['Total Pushed'])
									c.summary.total += total_pushed;

									if (loc.active_status == 0) {
										c.campaigns_inactive.total += total_pushed;
									} else {
										c.campaigns_active.total += total_pushed;

									}
									if (loc.master_campaign && loc.master_campaign.length) {
										switch (loc.master_campaign) {
											case 'online_agency':
												c.agencies.online += total_pushed;
												break;
											case 'offline_agency':
												c.agencies.offline += total_pushed;
												break;
											case 'organic':
												c.agencies.organic += total_pushed;
												break;
										}
									}
									if (loc.others && loc.others.length) {
										switch (loc.others) {
											case 'Vahan':
												c.agency_name.Vahan += total_pushed;
												break;
											case 'Workex':
												c.agency_name.Workex += total_pushed;
												break;
											case 'Capricco':
												c.agency_name.Capricco += total_pushed;
												break;
											case 'CII':
												c.agency_name.Cii += total_pushed;
												break;
											case 'AspiringMinds':
												c.agency_name.AspiringMinds += total_pushed;
												break;
											default:
												c.agency_name.Others += total_pushed;
												break;
										}
									}
									if (loc.master_campaign && loc.master_campaign.length) {
										if (!agency_data.hasOwnProperty(loc.master_campaign)) {
											agency_data[loc.master_campaign] = 0;
										}
										agency_data[loc.master_campaign] += parseInt(details['Total Pushed']);
									}
								}
								if (!isNaN(details['data_not_called'])) {
									c.summary.data_tocall += parseInt(details['data_not_called']);
								}
								if (!isNaN(details['contacted'])) {
									c.summary.data_contacted += parseInt(details['contacted']);
								}
								if (loc.active_status == 0) {
									if (!isNaN(details['data_not_called'])) {
										c.campaigns_inactive.data_tocall += parseInt(details['data_not_called']);
									}
									if (!isNaN(details['contacted'])) {
										c.campaigns_inactive.data_contacted += parseInt(details['contacted']);
									}
								} else {
									if (!isNaN(details['data_not_called'])) {
										c.campaigns_active.data_tocall += parseInt(details['data_not_called']);
									}
									if (!isNaN(details['contacted'])) {
										c.campaigns_active.data_contacted += parseInt(details['contacted']);
									}
								}
								c.summary.data_called = (c.summary.total - c.summary.data_tocall);
								c.summary.data_called_perc = this.calculatePercBy(c.summary.data_called, c.summary.total);

								c.campaigns_inactive.data_called = (c.campaigns_inactive.total - c.campaigns_inactive.data_tocall);
								c.campaigns_inactive.data_called_perc = this.calculatePercBy(c.campaigns_inactive.data_called, c.summary.total);

								c.campaigns_active.data_called = (c.campaigns_active.total - c.campaigns_active.data_tocall);
								c.campaigns_active.data_called_perc = this.calculatePercBy(c.campaigns_active.data_called, c.summary.total);

								c.summary.data_contacted_perc = this.calculatePercBy(c.summary.data_contacted, c.summary.total);

								c.campaigns_inactive.data_contacted_perc = this.calculatePercBy(c.campaigns_inactive.data_contacted, c.campaigns_inactive.total);

								c.campaigns_active.data_contacted_perc = this.calculatePercBy(c.campaigns_active.data_contacted, c.campaigns_active.total);

							}
						} else {
							setTimeout(() => {
								this.isCampbtnDisable = true;
							})
						}
					}
					/* Percentage calculation after all summation */
					c.agency_name.Vahan_Perc = this.calculatePercBy(c.agency_name.Vahan, c.summary.total);
					c.agency_name.Workex_Perc = this.calculatePercBy(c.agency_name.Workex, c.summary.total);
					c.agency_name.Capricco_Perc = this.calculatePercBy(c.agency_name.Capricco, c.summary.total);
					c.agency_name.CII_Perc = this.calculatePercBy(c.agency_name.Cii, c.summary.total);
					c.agency_name.AspiringMinds_Perc = this.calculatePercBy(c.agency_name.AspiringMinds, c.summary.total);
					c.agency_name.Others_Perc = this.calculatePercBy(c.agency_name.Others, c.summary.total);
					// debugger
					c.summary.total_perc = c.summary.total ? 100.0 : "0.0";
					c.campaigns_active.total_perc = c.campaigns_active.total ? 100.0 : "0.0";
					c.campaigns_inactive.total_perc = c.campaigns_inactive.total ? 100.0 : "0.0";
				}

				this.refreshTriggered = true;

				this.dashService.setCampaignSummary(c);
				// this.refreshTriggered = false;	
			}
		)
	}

	calculatePercBy(num1, num2) {
		if (num1 == 0 && num2 == 0) {
			return "0.0";
		}
		var value: any = (num1 / num2) * 100;
		value = value.toFixed(1);
		return value
	}

	refreshAgentsTableData() {
		setTimeout(() => { this.showLoader = true; })
		this.agentsSummary.refreshData();
		setTimeout(() => { this.showLoader = false; }, 2000)
	}

	refreshAssessmentTableData() {
		setTimeout(() => { this.showLoader = true; })
		this.assessmentSummary.refreshData();
		setTimeout(() => { this.showLoader = false;this.searchInput.value=""; }, 2000)
	}

	refreshRecruitData(startDate, endDate) {
		setTimeout(() => {
			this.showLoader = true;
		})
		this.dashService.setApplicantsData(startDate, endDate).then(
			() => {
				this.filterByLevel("")
				setTimeout(() => {
					this.showLoader = false;
				})
			}, (err) => {
				alert('dash-component, Error in the api service')
			}
		)
	}

	refreshCampaignData() {
		// alert('calling from child');
		setTimeout(() => {
			this.showLoader = true;
		})
		this.dashService.setMyCampaigns(this.campaign_start, this.campaign_end).then(
			() => {
				// alert('Data refreshed');
				setTimeout(() => {
					this.showLoader = false;
					this.isCampbtnDisable = false;
					this.prepareCampaignSummary();
				})
			}, (err) => {
				alert('dash-component, Error in the api campaign')
				this.showLoader = false;
			}
		)
	}

	onToggleButtonValChange(val: string) {
		this.searchInput.value = '';
		this.selectedButtonToggleVal = val;
		if (val == 'recruiterValue') {
			this.showCampaign = false;
			this.showRecruiter = true;
			this.showHiring = false;
			this.showAssessment = false;
		} else if (val == 'hiring_agents') {
			this.showHiring = true;
			this.showCampaign = false;
			this.showRecruiter = false;
			this.showAssessment = false;
		}else if (val == 'assessment') {
			this.showHiring = false;
			this.showCampaign = false;
			this.showRecruiter = false;
			this.showAssessment = true;
		} else {
			this.showRecruiter = false;
			this.showCampaign = true;
			this.showHiring = false;
			this.showAssessment = false;
		}
	}

	openCreateCampaign() {
		//console.log('Creating a campaign');
		this.isCampbtnDisable = true;
		var dialogRef = this.dialog.open(CampaignDialogComponent, {
			width: '40%',
			hasBackdrop: false,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				this.dashService.campaignSummary_default();
			}
			// console.log('The dialog was closed');
			this.isCampbtnDisable = false;
		});
	}
	openCreateAssessment(data:any) {
		//console.log('Creating a campaign');
		var dialogRef = this.dialog.open(CreateDialogComponent, {
			width: '40%',
			//hasBackdrop: false,
			disableClose:true,
			data: data
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				this.refreshAssessmentTableData();
			}
		});
	}
	openCientStatusdailog() {
		var dialogRef = this.dialog.open(ClientStatusComponent, {
			width: '35%',
			disableClose: true,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			//	console.log('The dialog was closed');
		});
	}
	uploadNew(){
		const dialogRef = this.dialog.open(BatchCreateUploadDialogComponent, {
			  width: '40%',
			  disableClose: true,
			  data: ""
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
			  if (result==true) {
			
			  }
		  });
	  }
	applyFilter(val: string) {
		// console.log('Search in filter', val);
		// console.log('rec table data', this.recTable.dataSource.data);
		if (this.showRecruiter) {
			this.recTable.dataSource.filter = val.trim().toLowerCase();
		}
		if (this.showCampaign) {
			this.campTable.dataSourceCampaign.filter = val.trim().toLowerCase();
		}
		if (this.showHiring) {
			this.agentTable.dataSource.filter = val.trim().toLowerCase();
		}
		if (this.showAssessment) {
			this.assessmentTable.dataSource.filter = val.trim().toLowerCase();
		}
	}

	refreshDataSource() {
		//alert(0)
		this.searchInput.value = '';
		if (this.showCampaign) {
			// debugger;
			// Refresh Campaign data
			// this.refreshCampaignData();
			this.onToggleButtonValChange('campaignValue');
			this.refreshCampaignData();
		}
		if (this.showRecruiter) {
			// Refresh Recruiter data
			// this.refreshRecruitData(); 
			this.onToggleButtonValChange('recruiterValue');
			this.refreshRecruitData("","");
			this.selectAgencies = '';
		this.selectSources = '';
		this.selectedLevel = '';
		}
		if (this.showHiring) {
			this.refreshAgentsTableData();
		}
		if (this.showAssessment) {
			this.refreshAssessmentTableData();
		}
	}

	extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {
				
				if( ref[i] == 'user_id' && obj['ecode']){
					//console.log(ref[i],obj)
					arr.push(obj['ecode'])
				  }else{
					arr.push(obj[ref[i]])
				  }
			} else {
				arr.push('')
			}
		}
		return arr;
	}

	exportExcel() {
		//debugger;
		//	console.log('exporting excel');
		var data_mapper = {
			"applicant_id": "Applicant ID",
			"name": "Name",
			"user_id": "User Id",
			"phone": "Phone",
			"email": "Email",
			"education": "Education Qualifications",
			"dob": "Date of Birth",
			"setup": "Laptop/Desktop",
			"connectivity": "Dongle/BroadBand/Wifi/Hotspot",
			"city": "City",
			"state": "State",
			"language": "English/ Hindi",
			"created_at": "Reg. Date",
			"current_status": "Status",
			"event_1_interview": "L1 Date",
			"event_1_organizer": "L1 Agent",
			"event_1_status": "L1 Status",
			"event_2_interview": "L2 Date",
			"event_2_organizer": "L2 Agent",
			"event_2_status": "L2 Status",
			"event_3_interview": "Client Interview Date",
			"event_3_status": "Client Interview Status",
			"training_start_date": "Training Start",
			"training_end_date": "Training End",
			"trainer_id": "Trainer",
			"master_campaign": "Agency Type",
			"campaign_agency": "Agency Name",
			"campaign_name": "Campaign Name",
		}
		if (this.showRecruiter) {
			//console.log('Exporting the recruitment data');
			var data = this.recTable.dataSource.data;
			var headers = Object.values(data_mapper);
			// console.log('data and headers', data, headers);
			// return;
			if (data && data.length) {
				let workbook = new Workbook();
				let worksheet = workbook.addWorksheet('Candidate Data');
				worksheet.addRow(headers);
				data.forEach(d => {
					let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
					//	console.log('printing row', row);
				})
				//Generate & Save Excel File
				workbook.xlsx.writeBuffer().then((data) => {
					let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					fs.saveAs(blob, 'Recruitment.xlsx');
				})
			}
		}


		if (this.showCampaign) {
			let _data_mapper = {
				"sl_no": "sl_no",
				"campaign_name": "camapign Name",
				"campaign_status": "campaign Status",
				"campaign_created": "campaign Created",
				"data_uploaded": "Uploaded",
				"data_duplicate": "Duplicate",
				"errors": "Errors",
				"data_added": "Data to Call",
				"contacted": "Contacted",
				"agency_type": "Agency Type",
				"agency_name": "Agency Name",
				"created_at": "created_at"
			}
			let data = this.campTable.dataSourceCampaign.data;
			let headers = Object.values(_data_mapper);
			if (data && data.length) {
				let workbook = new Workbook();
				let worksheet = workbook.addWorksheet('Candidate Data');
				worksheet.addRow(headers);
				data.forEach(d => {
					let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
					//console.log('printing row', row);
				})
				workbook.xlsx.writeBuffer().then((data) => {
					let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					fs.saveAs(blob, 'Campaign.xlsx');
				})
			}

		}

		if (this.showHiring) {
			let _data_mapper = {
				"user_id": "Agent Id",
				"name": "Agent Name",
				"level": "level",
				"dialled": "Dialled",
				"unique_dialled": "Unique Dialled",
				"contacted": "Contacted",
				"audit_score": "Audit Score",
				"ctc_score": "CTC Score",
			}
			let data = this.agentTable.dataSource.data;
			let headers = Object.values(_data_mapper);
			if (data && data.length) {
				let workbook = new Workbook();
				let worksheet = workbook.addWorksheet('Candidate Data');
				worksheet.addRow(headers);
				data.forEach(d => {
					let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
					//console.log('printing row', row);
				})
				workbook.xlsx.writeBuffer().then((data) => {
					let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					fs.saveAs(blob, 'Hiring.xlsx');
				})
			}
		}


	}

	downloadReportBySource(){
		var data_mapper = {
			"campaign_agency": "Source",
			"user_id": "User Id",
			"name": "Name",
			"phone": "Phone",
			"email": "Email",
			"education": "Education Qualifications",
			"dob": "Date of Birth",
			"setup": "Laptop/Desktop",
			"connectivity": "Dongle/BroadBand/Wifi/Hotspot",
			"city": "City",
			"state": "State",
			"language": "English/ Hindi",
			"created_at": "Reg. Date",
			"current_status": "Recruitment Status",
			"event_1_interview": "L1 Date",
			"event_1_organizer": "L1 Agent",
			"event_1_status": "L1 Status",
			"event_2_interview": "L2 Date",
			"event_2_organizer": "L2 Agent",
			"event_2_status": "L2 Status",
			"event_3_interview": "Client Interview Date",
			"event_3_status": "Client Interview Status",
			"training_start_date": "Training Start",
			"training_end_date": "Training End",
			"trainer_id": "Trainer",
			"master_campaign": "Agency Type",
			"campaign_name": "Campaign Name",
			"status": "Status",
		}

		// var data = this.recTable.dataSource.data;
	
		var data = _.filter(this.recTable.dataSource.data, (rec) => {return rec.campaign_agency})
		//console.log(data)
		data = _.sortBy(data, 'campaign_agency');
		var headers = Object.values(data_mapper);
		// console.log('data and headers', data, headers);
		// return;
		if (data && data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Candidate Data');
			worksheet.addRow(headers);
			data.forEach(d => {
				let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
				//	console.log('printing row', row);
			})
			//Generate & Save Excel File
			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'cadidate-source-report.xlsx');
			})
		}
	}

	downloadReportByjoined(){
		var data_mapper = {
			"campaign_agency": "Source",
			"name": "Name",
			"user_id": "User Id",
			"phone": "Phone",
			"email": "Email",
			"education": "Education Qualifications",
			"dob": "Date of Birth",
			"setup": "Laptop/Desktop",
			"connectivity": "Dongle/BroadBand/Wifi/Hotspot",
			"city": "City",
			"state": "State",
			"language": "English/ Hindi",
			"created_at": "Reg. Date",
			"current_status": "Status",
			"event_1_interview": "L1 Date",
			"event_1_organizer": "L1 Agent",
			"event_1_status": "L1 Status",
			"event_2_interview": "L2 Date",
			"event_2_organizer": "L2 Agent",
			"event_2_status": "L2 Status",
			"event_3_interview": "Client Interview Date",
			"event_3_status": "Client Interview Status",
			"training_start_date": "Training Start",
			"training_end_date": "Training End",
			"trainer_id": "Trainer",
			"master_campaign": "Agency Type",
			"campaign_name": "Campaign Name",
			"status": "Status",
		}

		// var data = this.recTable.dataSource.data;
	
		var data = _.filter(this.recTable.dataSource.data, (rec) => {return rec.campaign_agency})
		 data = _.filter(data, (rec) => {return rec.onboarding_status && rec.onboarding_status == 'Verified'})
		//console.log(data)
		data = _.sortBy(data, 'campaign_agency');
		var headers = Object.values(data_mapper);
		// console.log('data and headers', data, headers);
		// return;
		if (data && data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Candidate Data');
			worksheet.addRow(headers);
			data.forEach(d => {
				let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
				//	console.log('printing row', row);
			})
			//Generate & Save Excel File
			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'cadidate-joined-report.xlsx');
			})
		}
	}

	changeAgencies(e) {
		this.searchInput.value = '';
		if (this.selectAgencies.length) {
			let d = _.groupBy(this.recTable.table_data, 'master_campaign');
			if (d.hasOwnProperty(this.selectAgencies)) {
				this.recTable.dataTableRestart(d[this.selectAgencies])
			} else {
				this.recTable.dataTableRestart([]);
			}
		} else {
			/* Considering choosen select agency is All */
			this.recTable.dataTableRestart(this.recTable.table_data);
		}
	}
	changeSources(e) {
		this.searchInput.value = '';
		let d = this.recTable.table_data;
			if (this.selectedLevel.length) {
				d = _.groupBy(this.recTable.table_data, 'current_status');
				if (d.hasOwnProperty(this.selectedLevel)) {
					d = d[this.selectedLevel];
				}
			}
		if (this.selectSources.length) {
			
			//console.log(d)
			d = _.groupBy(d, 'campaign_agency');
			if (d.hasOwnProperty(this.selectSources)) {
				this.recTable.dataTableRestart(d[this.selectSources])
			} else {
				this.recTable.dataTableRestart([]);
			}
		} else {
			/* Considering choosen select agency is All */
			this.recTable.dataTableRestart(d);
		}
	}
	filterByLevel(e) {
		this.searchInput.value = '';
		let d = this.recTable.table_data;
			if (this.selectSources.length) {
				d = _.groupBy(this.recTable.table_data, 'campaign_agency');
				if (d.hasOwnProperty(this.selectSources)) {
					d = d[this.selectSources];
				}
			}
		if (this.selectedLevel.length) {
			
			d = _.groupBy(d, 'current_status');
			if (d.hasOwnProperty(this.selectedLevel)) {
				this.recTable.dataTableRestart(d[this.selectedLevel])
			} else {
				this.recTable.dataTableRestart([]);
			}
		} else {
			/* Considering choosen select agency is All */
			this.recTable.dataTableRestart(d);
		}
	}

	change_date(key: string, event: MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
			this.startDateFilter = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDateFilter).diff(moment(this.endDateFilter)) >= 0) {
				this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				//this.endDateFilter = "";
				//alert("Start date should be less than end date");
				//return;
			}
		} else {
			this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDateFilter).diff(moment(this.endDateFilter)) >= 0) {
				//	this.endDateFilter = "";
				this.endDateFilter = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.showHideDateCondition=true;
				return;
			} else {
				this.showHideDateCondition=false;
			}

		}
	}

	refreshDataByDate(type) {
		this.searchInput.value = '';
		if (type == 'hiring') {
			this.agentsSummary.refreshData();
		}else if(type == 'recruiter'){
			//debugger
			this.refreshRecruitData(this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter));
		this.selectAgencies = '';
		// this.selectSources = '';
		// this.selectedLevel = '';
		this.searchInput.value = '';
		}
	}
	printwindow() {
		window.print();
	}

	sendAppLink(){
		this.showHideSentLinkMsg=false;
		var tableData = this.recTable.dataSource.filteredData ? this.recTable.dataSource.filteredData : this.recTable.dataSource.data;
		var emails = _.pluck(tableData, 'email');
		var obj = {
			emails: JSON.stringify(emails),
			production_group:  this.myUser.groupid
		}
		this.API.sendAppLink(obj,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				// alert("User updated successfully");
				//alert("Emails sent successfully")
				this.showHideSentLinkMsg=true;
				setTimeout(()=>{
					this.showHideSentLinkMsg=false;
				},4000)
			}
		});
		//console.log(emails)
	}
}
