<table class="tbl">
	<tr style="border-bottom: 1px solid #ccc;">
		<td>
			<h5 class="hd5">{{ documentData.key }} - Document</h5>
		</td>
		<td style="text-align: right;"> 
			<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
		</td>
   </tr>
   <tr>
	   <td>&nbsp;</td>
	   <td>
		   <img *ngIf="showHidelink" class="img_view" src="{{ documentData.url }}" >
	   </td>
   </tr>
   
   <tr>
	   <td>File Name</td>
	   <td >
			   {{ documentData.file_name }} <a style="padding-left: 10px;" href="{{documentData.url}}" ><i class="fa fa-download" aria-hidden="true" ></i></a>
		</td>
   </tr>
   <tr *ngIf="passbookShowHide">
	<td>Bank</td>
	<td >
			{{ passbook_details.doc_Bank }}
	 </td>
	</tr>
	<tr *ngIf="passbookShowHide">
	 <td>Bank Branch</td>
	 <td >
			 {{ passbook_details.doc_Bank_Branch }}
	  </td>
	</tr>
	<tr *ngIf="passbookShowHide">
	  <td>Bank IFSC CODE</td>
	  <td >
			  {{ passbook_details.doc_IFSC_Code }}
	   </td>
	</tr>
	<tr *ngIf="passbookShowHide">
	   <td>Candidate Address</td>
	   <td >
			   {{ passbook_details.doc_Cadidate_Address }}
		</td>
	</tr>
   <!-- <tr>
	   <td>Document Value</td>
	   <td>{{ documentData.value }}</td>
   </tr> -->
   <tr>
	   <td>Uploaded on</td>
	   <td>{{ documentData.date }}</td>
   </tr>
</table>