<app-menu [navigateLink]="currentTitle"></app-menu>
<h4 class="titlehead">Customer</h4>
<table class="maintable">
   <tr>
      <td>
         <div class="containecs">
            <div class="colcomn">
				<div class="card">
					<div class="card-header">Call Summary</div>
					<div class="card-body">
						<table class="tablecs">
							<tr><td>Dialled</td><td>{{ customer_summary.call_summary.dialled }}</td></tr>
							<tr><td>Contacted</td><td>{{ customer_summary.call_summary.contacted }}</td></tr>
							<tr><td>RNR</td><td>{{ customer_summary.call_summary.rnr }}</td></tr>
						</table> 
					</div>
				</div>
			 </div>
			 <div class="colcomn">
				<div class="card">
					<div class="card-header">L1 Interview Status</div>
					<div class="card-body">
						<table class="tablecs">
							<tr><td>Total</td><td>{{ customer_summary.l1_data.total }}</td></tr>
							<tr><td>Selected</td><td>{{ customer_summary.l1_data.selected }}</td></tr>
							<tr><td>Rejected</td><td>{{ customer_summary.l1_data.rejected }}</td></tr>
						</table> 
					</div>
				</div>
			 </div>
			 <div class="colcomn">
				<div class="card">
					<div class="card-header">L2 Interview Status</div>
					<div class="card-body">
						<table class="tablecs">
							<tr><td>Total</td><td>{{ customer_summary.l2_data.total }}</td></tr>
							<tr><td>Selected</td><td>{{ customer_summary.l2_data.selected }}</td></tr>
							<tr><td>Rejected</td><td>{{ customer_summary.l2_data.rejected }}</td></tr>
						</table> 
					</div>
				</div>
			 </div>
			 <div class="colcomn">
				<div class="card">
					<div class="card-header">Client Interview Status</div>
					<div class="card-body">
						<table class="tablecs">
							<tr><td>Total</td><td>{{ customer_summary.client_data.total }}</td></tr>
							<tr><td>Selected</td><td>{{ customer_summary.client_data.selected }}</td></tr>
							<tr><td>Rejected</td><td>{{ customer_summary.client_data.rejected }}</td></tr>
						</table> 
					</div>
				</div>
			 </div>
         </div>
      </td>
   </tr>
   <tr>
      <td>
         <div>
            <div class="pDiv">
               <!--start agency table-->
               <div>
               </div>
               <!--start agency table-->
            </div>
         </div>
      </td>
   </tr>
</table>
<div class="pDiv">
   <div class="divprintRt">
      <table style="width: 100%;">
         <tr>
            <td>
               <!-- <span class="recName">{{agency_Name}}  </span> -->
               <span class="datecs">Date - {{ today }}</span>
            </td>
            <td style="text-align: right;position: relative;right: 20px;">
               <span class="searchSpan">
                  <mat-form-field >
                     <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                     <i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
                  </mat-form-field>
               </span>
            </td>
         </tr>
         <tr>
            <td>
               <p> 
				   <span class="das-pe">Print</span>
                  <span class="das-pe">Export</span>
                  <span class="das-pe" (click)="refreshData()">Refresh</span>
               </p>
            </td>
         </tr>
      </table>
   </div>
   <!--start table-->
   <div>
      <mat-table [dataSource]="dataSource">
         <ng-container matColumnDef="sl_no">
            <mat-header-cell *matHeaderCellDef> SNo. </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.sl_no}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="agency">
            <mat-header-cell *matHeaderCellDef> Agency </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.agency}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="aadhar">
            <mat-header-cell *matHeaderCellDef> Aadhar No </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.aadhar}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.phone}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="event_1_status">
            <mat-header-cell *matHeaderCellDef> L1 Interview </mat-header-cell>
            <mat-cell *matCellDef="let element"  [ngStyle]="{'text-transform':'Capitalize','color':getL1InterviewColor(element.event_1_status)}"> {{element.event_1_status}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="event_2_status">
            <mat-header-cell *matHeaderCellDef> L2 Interview </mat-header-cell>
            <mat-cell *matCellDef="let element"  [ngStyle]="{'text-transform':'Capitalize','color':getL2InterviewColor(element.event_2_status)}"> {{element.event_2_status}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="event_3_status">
            <mat-header-cell *matHeaderCellDef> Client Interview </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngStyle]="{'text-transform':'Capitalize','color': element.event_3_status === 'Selected' ? '#0dca0d' : '#ff8499'}"> {{element.event_3_status}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="training_status">
            <mat-header-cell *matHeaderCellDef> Training </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.training_status}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="certified">
            <mat-header-cell *matHeaderCellDef> Certified </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.certified}} </mat-cell>
         </ng-container>
         <ng-container matColumnDef="onBoarding">
            <mat-header-cell *matHeaderCellDef> On Boarding </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.onBoarding}} </mat-cell>
         </ng-container>
         <mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
         <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
   </div>
   <!--end table-->
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>