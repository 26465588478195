
<br>
<div *ngIf="showHideVisible"> 
    <form  [formGroup]="createdForm"> 
<div class="row mt-2">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left item">
        <img src="../assets/crcImg.png">&nbsp;&nbsp;&nbsp;
        <span>Whatsapp</span>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Template</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off"  formControlName="template"/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Telco Partner</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="telcoPartner" required/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Phone Number</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off"  formControlName="phoneNo" required maxlength="13"/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Message</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off"  formControlName="message" required maxlength="250"/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">Type</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="type" required readonly/>
            </mat-form-field>
</div>
</div>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <span class="party">CLI Number</span><br>
        <mat-form-field class="m-wd">
            <input matInput autocomplete="off" formControlName="cli" required maxlength="13"/>
            </mat-form-field>
</div>
</div><br>
<div class="row mt-2 rowx">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">
        <button class="demo-back" (click)="backDashboard()">BACK</button>
        <button class="demo-cancel" (click)="cancelBtn()">CANCEL</button>
        <button class="demo-save" (click)="submitBtn(createdForm)">SUBMIT</button>
</div>
</div>
</form>
</div>


<div  *ngIf="showHideVisibleSuccess">
    <div class="row mt-2">            
        <div class="col-md-4 col-sm-4 col-xs-12 item" style="text-align: center;
        margin: 10% auto;">
            <span>ID : {{_sms_id}}</span><br>
            <span>{{_message}} </span><br>
            <button class="demo-save" (click)="okBtn()">OK</button>
    </div>
    </div>
</div>