<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	  
	  
	</mat-drawer>

<div class="CN-Main" style="margin: 90px 15px;">
    <p class="CN-Tab-head"><span style="position: relative;left: 2px;margin: 14px;vertical-align: text-top;color: green;padding: 0px 0px;cursor: pointer;" (click)="backTowhatsapp()"><mat-icon matTooltip="Back">arrow_back_ios</mat-icon></span><b>Reports</b></p>
    <div>
        <mat-card class="CN-Card cardFirst">
            <div class="row">
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <span>
                        <!-- <mat-select [ngClass]="'arrowcs'" class="dropdownCs"
                            [(value)]="project_name" (selectionChange)="OnChangeProject($event.value)">
                            <mat-option value="">Select Project</mat-option>
                            <mat-option *ngFor="let prj of projects.split('|')" [value]="prj">{{prj}}</mat-option>                          
                        </mat-select> -->
                        <mat-select [ngClass]="'arrowDDLcs'" class="dropdownCs" [(ngModel)]="project_name">
                            <mat-option [value]="project_name">{{project_name}}</mat-option>                          
                        </mat-select>
                    </span>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <!-- <mat-select [ngClass]="'arrowDDLcs'" [disabled]="!project_name"  class="dropdownCs" [(value)]="selectedChannel" (selectionChange)="OnChangeChannel($event.value)">
                        <mat-option value="">Select Channel</mat-option>
                        <mat-option *ngFor="let v of ChannelsData" [value]="v">{{v}}</mat-option>
                    </mat-select> -->


                    <mat-select [ngClass]="'arrowDDLcs'"  class="dropdownCs" [(value)]="selectedChannel" (selectionChange)="OnChangeChannel($event.value)">
                        <mat-option value="">Select Channel</mat-option>
                        <mat-option value="WhatsApp">Whatsapp</mat-option>
                    </mat-select>



                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <!-- <mat-select [ngClass]="'arrowDDLcs'" [disabled]="!project_name || !selectedChannel" placeholder="Type"  class="dropdownCs" (selectionChange)="channelRefresh()" [(value)]="selectedType">
                        <mat-option>None</mat-option>
                        <mat-option value="detailed">Detailed</mat-option>
                    </mat-select> -->

                    <mat-select [ngClass]="'arrowDDLcs'" [disabled]=" !selectedChannel"  class="dropdownCs" placeholder="Type"  [(value)]="selectedType" (selectionChange)="channelRefresh()">
                       <mat-option>None</mat-option>
                        <mat-option value="detailed">Detailed</mat-option>
                    </mat-select>

                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 cn-dat">{{getCurrentDate()}}</div>
            </div>
        </mat-card>
        <!-- <mat-card>
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12 cn-title"> <span> Reports</span></div>
                <div class="col-md-9 col-sm-9 col-xs-12 cn-dat">{{getCurrentDate()}}</div>
            </div>
        </mat-card> -->
    </div>
    <mat-card class="mt-3">
        <div  class="row mt-4">
            <div class="col-md-12 col-sm-12 col-xs-12"> 
            <mat-radio-group class="rbgp" (change)="changeRadioByDay($event.value)" [(ngModel)]="byDateValue">
                <mat-radio-button class="rbcs" value="ByDate" checked>By Date</mat-radio-button>
                <mat-radio-button class="rbcs" value="ByMonth">By Month</mat-radio-button>
              </mat-radio-group>
              </div>
        </div>
<br>
<p class="dateCondition" *ngIf="showHideDateCondition" style="color:red;">Start date should be less than end date*</p>
<div style="display:flex;flex-wrap:wrap;">    
    <div> 
        <span>
            <mat-label class="date-lbl">Start Date</mat-label>
            <input class="dat-inpt" matInput [matDatepicker]="startpicker" autocomplete="off" placeholder="Select Date" readonly (click)="startpicker.open()" (dateChange)="change_date('start_date', $event)" [max]="today" [(ngModel)]="startDateFilter">
            <mat-datepicker-toggle class="dat-tog" matSuffix [for]="startpicker"></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
        </span>
    </div>
    <div> 
        <span>
            <mat-label class="date-lbl">End Date</mat-label>
            <input class="dat-inpt"  #fromInput matInput [matDatepicker]="endpicker" autocomplete="off" placeholder="Select Date" readonly (click)="endpicker.open()" (dateChange)="change_date('end_date', $event)" [max]="today" [(ngModel)]="endDateFilter">
            <mat-datepicker-toggle class="dat-tog" matSuffix [for]="endpicker"></mat-datepicker-toggle>
            <mat-datepicker #endpicker></mat-datepicker>
        </span>
    </div>
    <div> 
        <button class="g-btn mt-0" mat-button (click)="channelRefresh()">Go</button>
    </div>
</div>

        <br>        
        <div class="tableDiv" >
            <table  style="width: 100%;" >
                <tr>
                    <td class="date-lbl" *ngIf="selectedType" style="width:auto"><b>{{ selectedChannel }}</b>
                        </td>
                    <td *ngIf="selectedType" class="text-right"> 
                  <span class="dwnld" (click)="downloadReport()"><mat-icon style="position: relative;top: 8px;" >file_download</mat-icon> DOWNLOAD</span>
                    </td>
                </tr>
            </table>
            <br>
            <!-- <div *ngIf="selectedChannel == 'Outbound IVR' && selectedType">
                <table class="tableReports" mat-table [dataSource]="dataSource" style="width: 100%;">
                    <ng-container matColumnDef="event_type">
                      <th mat-header-cell *matHeaderCellDef style="width:220px;"> Event </th>
                      <td mat-cell *matCellDef="let element" style="width:220px;"> {{getEventName(element.event_type)}} </td>
                    </ng-container>
                    <ng-container matColumnDef="customer_number">
                      <th mat-header-cell *matHeaderCellDef> Customer Phone </th>
                      <td mat-cell *matCellDef="let element"> {{element.customer_number}} </td>
                    </ng-container>
                    <ng-container matColumnDef="dtmf_digit">
                      <th mat-header-cell *matHeaderCellDef> Digit </th>
                      <td mat-cell *matCellDef="let element"> {{element.dtmf_digit}} </td>
                    </ng-container>
                    <ng-container matColumnDef="start_time">
                      <th mat-header-cell *matHeaderCellDef> Start time </th>
                      <td mat-cell *matCellDef="let element"> {{element.start_time | date:'dd-MMM-yyyy h:mm:ss a'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="end_time">
                      <th mat-header-cell *matHeaderCellDef> End time </th>
                      <td mat-cell *matCellDef="let element"> {{element.end_time | date:'dd-MMM-yyyy h:mm:ss a'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="call_duration">
                      <th mat-header-cell *matHeaderCellDef> Duration </th>
                      <td mat-cell *matCellDef="let element"> {{element.call_duration}} </td>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef> Details </th>
                        <td mat-cell *matCellDef="let element"> 
                            <span  class="view-details" (click)="viewDetailsDialog(element,'outboundIvr')"> VIEW DETAILS </span>
                        </td>
                      </ng-container>
                    <tr style="background-color: #b9b9b947;" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table> 
                  <mat-paginator #OutboundPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div> -->
            <div *ngIf="selectedChannel == 'WhatsApp' && selectedType">
                <table class="tableReports" mat-table [dataSource]="dataSource" matSort style="width: 100%;">

                <ng-container matColumnDef="sender_name">
                    <th mat-header-cell *matHeaderCellDef style="width:220px;" mat-sort-header> Sender Name </th>
                    <td mat-cell *matCellDef="let element" style="width:220px;"> {{element.sender_name}} </td>
                </ng-container>

                <ng-container matColumnDef="sender_phone">
                    <th mat-header-cell *matHeaderCellDef style="width:220px;" mat-sort-header> Sender </th>
                    <td mat-cell *matCellDef="let element" style="width:220px;"> {{element.sender_phone}} </td>
                </ng-container>
                <ng-container matColumnDef="receiver_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.receiver_name}} </td>
                </ng-container>

                <ng-container matColumnDef="receiver_phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient </th>
                    <td mat-cell *matCellDef="let element"> {{element.receiver_phone}} </td>
                </ng-container>
                <ng-container matColumnDef="content">
                    <th mat-header-cell [hidden]="true"  *matHeaderCellDef mat-sort-header> Content </th>
                    <td mat-cell [hidden]="true" *matCellDef="let element"> {{element.content}} </td>
                </ng-container>
                <ng-container matColumnDef="whatsapp_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Whatsapp Number </th>
                    <td mat-cell *matCellDef="let element"> {{element.whatsapp_number}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>
                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Message Date</th>
                    <td mat-cell *matCellDef="let element"> {{element.created_at | date:'dd-MMM-yyyy h:mm:ss a'}} </td>
                </ng-container>
                <ng-container matColumnDef="message_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                    <td mat-cell *matCellDef="let element"> {{element.message_type}} </td>
                </ng-container>
                <ng-container matColumnDef="direction">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Direction </th>
                    <td mat-cell *matCellDef="let element"> {{element.direction}} </td>
                </ng-container>
                <ng-container matColumnDef="details">
                    <th mat-header-cell *matHeaderCellDef> Details </th>
                    <td mat-cell *matCellDef="let element"> 
                        <span  class="view-details" (click)="viewDetailsDialog(element,'whatsapp')"> VIEW DETAILS </span>
                    </td>
                  </ng-container>
                <tr style="background-color: #b9b9b947;" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator  #whatsappPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
            <!-- <div *ngIf="!selectedChannel">
                <table class="tableReports" mat-table [dataSource]="dataSource" style="width: 100%;">
                    <ng-container matColumnDef="channel">
                        <th mat-header-cell *matHeaderCellDef style="width:220px;"> Channel </th>
                        <td mat-cell *matCellDef="let element" style="width:220px;"> {{element.channel}} </td>
                    </ng-container>
                <ng-container matColumnDef="receiver_phone">
                    <th mat-header-cell *matHeaderCellDef> Customer </th>
                    <td mat-cell *matCellDef="let element"> {{element.receiver_phone}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>
                <ng-container matColumnDef="start_time">
                    <th mat-header-cell *matHeaderCellDef> Start time </th>
                    <td mat-cell *matCellDef="let element"> {{element.start_time  | date:'dd-MMM-yyyy h:mm:ss a'}} </td>
                </ng-container>
                <ng-container matColumnDef="end_time">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.end_time  | date:'dd-MMM-yyyy h:mm:ss a'}} </td>
                </ng-container>
                <ng-container matColumnDef="details">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element"> 
                       <span class="view-details" (click)="viewDetailsDialog(element,element.channel)"> VIEW DETAILS </span> 
                    </td>
                  </ng-container>
                <tr style="background-color: #b9b9b947;" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator  #UPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
             -->
            
        </div>
      
        <br><br>
    </mat-card>

</div>
</mat-drawer-container>