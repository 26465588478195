import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { timer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import moment from 'moment';

@Component({
	selector: 'app-contiinex-login-newui',
	templateUrl: './contiinex-login-newui.component.html',
	styleUrls: ['./contiinex-login-newui.component.scss']
})
export class ContiinexLoginNewuiComponent implements OnInit {
	_heading: any = "Login";
	showHideProduct: boolean = true;
	showHideProductGignexLogo: boolean = false;
	showHideProductComnexLogo: boolean = false;
	showHideProductAinexLogo: boolean = false;

	createdForm: FormGroup;
	otpForm: FormGroup;
	loginname: any;
	showLoader: boolean = false;
	selectedGig: any = "";
	selectedMerchant: any = "";
	merchants_list: any;
	showHideErrorMsg: boolean = false;
	showHideMultiUser: boolean = false;
	passMsgText: any = "";
	otp: any;
	otpvalidation: any = false; //true ;
	idpassword: any = true;//false;
	email: any;
	password: any;
	totalTime: number;
	min: number;
	sec: number;
	clockTimer: string = '00 : 00';
	subscribe: any;
	otpstatus: any = "firstlogin";
	API_End_Point: any;
	API_Platform: any;
	currentURL = '';

	showHideLoginPageUI: boolean = true;
	showHideForgotPageUI: boolean = false;
	forgotEmail: any;
	forgotMsgError: any = "";
	showHideForgotMsg: boolean = false;
	showHideResetPageUI: boolean = false;
	newPassword: any = "";
	confirmPassword: any = "";
	api_end_point: any;
	resend_otp_disabled:boolean = true;
	disable_timer_calculation:boolean = false;

	windowNewOpenCounter: any = 1;
	extentionID: any = "dbhcchccbhpnohpmcallfdfcfnogdfpo";
	ipAddress: string;
	constructor(private activeRoute: ActivatedRoute, private Location: Location, private router: Router, private http: HttpClient, private fb: FormBuilder, private API: ApiService, private _service: MyserviceService, protected $gaService: GoogleAnalyticsService, private _snackBar: MatSnackBar) {
		window.scroll(0, 0);
		this.currentURL = window.location.href;
		//console.log(window.location.hostname);
		this.createdForm = fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.maxLength(50)]]
		}), this.otpForm = fb.group({
			otp: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6), Validators.maxLength(7)]]
		})
	}

	ngOnInit() {
		//console.log(this.currentURL);
		//console.log(this.Location.path());
		//console.log(window.location.hostname =="localhost");
		//	console.log(window.location.hostname =="dev.continnex.com");
		const id = this.activeRoute.snapshot.paramMap.get('id');
		if (id) {
			this.selectedGig = id;
		}
		this.loginname = '';
		if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
			this.loginname = this._service.get_loggedin_user().email;
		}

		this.merchants_list = ['Store1', 'Store2'];
	}
	onChangeMerchant(val: any) {
		this.selectedMerchant = val;
	}
	onChangeProduct(val: any) {
		this.selectedGig = val;
		this.showHideProduct = true;
		this.showHideProductGignexLogo = false;
		this.showHideProductComnexLogo = false;
		this.showHideProductAinexLogo = false;
		this._heading = "";
		if (val == "Gignex") {
			this.showHideProduct = false;
			this.showHideProductGignexLogo = true;
			this._heading = "Login With Your Credential";
		}
		else if (val == "Comnex") {
			this.showHideProduct = false;
			this.showHideProductComnexLogo = true;
			this._heading = "Login With Your Credential";
		}
		else if (val == "Ainex") {
			this.showHideProduct = false;
			this.showHideProductAinexLogo = true;
			this._heading = "Login With Your Credential";
		}
		else if (val == "WABA") {
			this._heading = "Login";
		}
		else {
			this.showHideProduct = true;
			this.showHideProductGignexLogo = false;
			this.showHideProductComnexLogo = false;
			this.showHideProductAinexLogo = false;
			this._heading = "Login";
		}


	}
	backToSelectProduct() {
		this._heading = "";
		this.showHideProduct = true;
		this.showHideProductGignexLogo = false;
		this.showHideProductComnexLogo = false;
		this.showHideProductAinexLogo = false;
		this._heading = "Login";
		this.selectedGig = "";
	}
	registerNowBtn() {
		this.router.navigateByUrl('/Register');
		this.$gaService.event('Clicked Register', 'Login Page');
	}

	getIPAddress() {
		this.API.getIpAddress().subscribe((res:any)=>{
			this.ipAddress = res.ip
		})
	}

	LoginBtn(createdForm: any) {
		//debugger;
		console.log('keyp enters')
		this.showHideErrorMsg = false;
		this.passMsgText = "";

		let _email = createdForm.controls.email.value;
		let _password = createdForm.controls.password.value;
		if (_email == "") {
			this.showHideErrorMsg = true;
			//this._snackBar.open("Please enter user name.", "close",config);
			this._snackBar.open("Please enter user name.", "close", {
				duration: 2000
			});
			//this.passMsgText = ;
			setTimeout(() => {
				this.showHideErrorMsg = false;
				this.passMsgText = "";
			}, 5000);
			return;
		}

		let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if (reg.test(_email) == false) {
			this.showHideErrorMsg = true;
			this._snackBar.open("Please enter valid email.", "close", {
				duration: 5000
			});
		}
		if (_password == "") {
			this.showHideErrorMsg = true;
			this._snackBar.open("Please enter password.", "close", {
				duration: 5000
			});
		}

		this.getIPAddress();
		this.email = _email;
		this.password = _password;
		let formData = this.get_form_data_login();
		this.check_redirection_login(formData);
	}

	check_redirection_login(formData){
		if (window.location.pathname == '/Login/Ainex' || window.location.pathname == '/Contiinex/Login/Ainex') {
			this.ainexLogin(formData)
		} else {
			this.uearnLogin(formData);
		}
	}

	get_form_data_login(otp?:number|string){
		let formData = {
			"email": this.email,
			"password": this.password,
			"login_method": "smb",
			"company": "",
			"source_ip_add": this.ipAddress
		};
		if (this.selectedMerchant) {
			formData.company = this.selectedMerchant;
		}
		return formData;
	}

	login_error_handler(errors){
		this.showLoader = false;
		this._snackBar.open(errors, "close", {
			duration: 5000
		});
		if(errors == "Invalid OTP. Try again"){
			this.otpvalidation = true;
			this.idpassword = false;
			return;
		}
		if(errors == "OTP is expired. Click Resend OTP"){
			this.otpvalidation = true;
			this.idpassword = false;
			this.resend_otp_disabled = false;
			this.disable_timer_calculation = true;
			this.reset_otp_timer();
			this.f.otp.reset();
			return;
		}
	}

	uearnLogin(formData) {
		this.showLoader = true;
		//   console.log('login api')
		var userDetails, stageSettings;
		//console.log(formData);
		//console.log('uearnLogin',window.location.hostname);
		// console.log(window.location.hostname =="dev.continnex.com");
		if (true || !(window.location.hostname == "localhost" || window.location.hostname == "dev.contiinex.com")) {
			this.API.SBIloginAPI(formData)
				.subscribe(
					(res:any) => {
						//console.log('prod printing res', res)
						if(res.errors){
							return this.login_error_handler(res.errors);
						}
						let _error = res["status"];
						if (false && (_error == "failure")) {
							const demo_user = this._service.is_ainex_demo_user(formData.email, formData.password)
							if (demo_user) {
								let objJsonStr = JSON.stringify(demo_user);
								let objJsonB64 = btoa(objJsonStr);
								window.location.href = 'http://ainexdemo.contiinex.com/' + demo_user.page;
								return;
							} else {
								this.idpassword = true;
								this.showLoader = false;
								this._snackBar.open("Invalid Credentials", "close", {
									duration: 5000
								});
							}
						}
						else {
							userDetails = res['user_details'];
							if (userDetails['mail_otp_authentication'] == true) {
								this.showLoader = false;
								this.disable_timer_calculation = false;
								const seconds = this.calculate_seconds_for_reset_password(userDetails['otp_generated_time']);
								if(seconds < 600){
									this.otpvalidation = true;
									this.idpassword = false;
									this.timerClock(600-seconds);
								}
								return;
							}
							//console.log("--" + res['user_details']);
							//console.log(res['user_details']['id']);
							if (res['user_details']) {
								userDetails = res['user_details'];
								let access_token = res['user_details']['access_token'];
								//console.log(access_token)
								localStorage.setItem('access_token', access_token);
								//this.API_End_Point = res['platform_details']['API_End_Point'];

								this.API_Platform = res['platform_details']['Platform_Name'];

								//this._service.set_API_End_Point(res['platform_details']['API_End_Point']);

								if (res['user_details']["domain_url"]) {
									this._service.set_API_End_Point(res['user_details']["domain_url"]);
									this.API_End_Point = res['user_details']["domain_url"];
								}
								else {
									this._service.set_API_End_Point(res['platform_details']['API_End_Point']);
									this.API_End_Point = res['platform_details']['API_End_Point'];
								}

								this._service.set_API_Platform1(res['platform_details']['Platform_Name'])

								let landing_page = '';
								this._service.set_access_token(access_token);
								this._service.set_loggedin_user(res['user_details']);

								if (this.API_Platform == "CONTIINEX") {
									this.$gaService.event('Clicked Login', 'Login Page');
									userDetails = res;
									if (res['user_details']["multi_user_login"]) {
										this.merchants_list = res['user_details']["multi_user_login"];
										this.showHideMultiUser = true;
										return;
									} else if (res['user_details']['businessType'] && (res['user_details']['businessType'] == 'COMNEX') && res['user_details']['partner'] != "SHOPIFY") {

										if (access_token) {
											this._service.set_access_token(access_token);
											this._service.set_loggedin_user(res['user_details']);
										}
										if (res['user_details']['company'] == "COLLECTIONS") {
											var settingpages = {
												"web": [
													{
														"stage": 1,
														"type": "web",
														"name": "landing_page",
														"definition": "funnel-dashboard",
														"project": "comnex",
														"created_at": "2020-10-15T09:00:25.000Z",
														"updated_at": "2020-10-15T09:00:25.000Z"
													},
													{
														"stage": 1,
														"type": "web",
														"name": "pages",
														"definition": "Dashboard,funnel-dashboard,details-view,processFlowBuilder,Reports",
														"created_at": "2020-04-14T17:55:48.000Z",
														"project": "comnex",
														"updated_at": "2020-04-14T17:55:48.000Z"
													}
												]
											}
											this._service.setdevcomnexPages(settingpages);
											window.location.href = "/Funnel-Dashboard";
											return;
										}

										else {
											var settingpages = {
												"web": [
													{
														"stage": 1,
														"type": "web",
														"name": "landing_page",
														"definition": "funnel-dashboard",
														"project": "comnex",
														"created_at": "2020-10-15T09:00:25.000Z",
														"updated_at": "2020-10-15T09:00:25.000Z"
													},
													{
														"stage": 1,
														"type": "web",
														"name": "pages",
														"definition": "Dashboard,funnel-dashboard,details-view,Message-Template,Reports,contiinex-campaign",
														"created_at": "2020-04-14T17:55:48.000Z",
														"project": "comnex",
														"updated_at": "2020-04-14T17:55:48.000Z"
													}
												]
											}
											this._service.setdevcomnexPages(settingpages);
											window.location.href = "/Funnel-Dashboard";
											return;
										}


									}
									else if (res['user_details']['businessType'] && (res['user_details']['businessType'] == 'WABA')) {
										if (access_token) {
											this._service.set_access_token(access_token);
											this._service.set_loggedin_user(res['user_details']);
										}
										if ((res['user_details']['company'] == "SMBWABA")) {
											var settingpages = {
												"web": [
													{
														"stage": 1,
														"type": "web",
														"name": "landing_page",
														"definition": "Profile-Settings",
														"project": "comnex",
														"created_at": "2020-10-15T09:00:25.000Z",
														"updated_at": "2020-10-15T09:00:25.000Z"
													},
													{
														"stage": 1,
														"type": "web",
														"name": "pages",
														"definition": "Dashboard,Reports,Profile-Settings,Message-Template,Contiinex-sandbox,contiinex-campaign",
														"created_at": "2020-04-14T17:55:48.000Z",
														"project": "comnex",
														"updated_at": "2020-04-14T17:55:48.000Z"
													}
												]
											}
											this._service.setdevcomnexPages(settingpages);
											window.location.href = "/Profile-Settings";
											return;

										}
										else {
											var settingpages = {
												"web": [
													{
														"stage": 1,
														"type": "web",
														"name": "landing_page",
														"definition": "Profile-Settings",
														"project": "comnex",
														"created_at": "2020-10-15T09:00:25.000Z",
														"updated_at": "2020-10-15T09:00:25.000Z"
													},
													{
														"stage": 1,
														"type": "web",
														"name": "pages",
														"definition": "Dashboard,Reports,Profile-Settings,Message-Template,contiinex-campaign",
														"created_at": "2020-04-14T17:55:48.000Z",
														"project": "comnex",
														"updated_at": "2020-04-14T17:55:48.000Z"
													}
												]
											}
											this._service.setdevcomnexPages(settingpages);
											window.location.href = "/Profile-Settings";
											//this.router.navigateByUrl('/Profile-Settings');
											return;

										}

									}


									else if (res['user_details']['businessType'] && (res['user_details']['businessType'] == 'COMNEX') && res['user_details']['partner'] == "SHOPIFY") {
										if (access_token) {
											this._service.set_access_token(access_token);
											this._service.set_loggedin_user(res['user_details']);
										}
										localStorage.removeItem('shopifyForRegister');
										localStorage.setItem('shopifyForRegister', 'ShopifyReg');

										var settingpages = {
											"web": [
												{
													"stage": 1,
													"type": "web",
													"name": "landing_page",
													"definition": "Shopify-Dashboard",
													"project": "shopify",
													"created_at": "2020-10-15T09:00:25.000Z",
													"updated_at": "2020-10-15T09:00:25.000Z"
												},
												{
													"stage": 1,
													"type": "web",
													"name": "pages",
													"definition": "shopify-dashboard,shopify-team,shopify-billing,shopify-setting",
													"created_at": "2020-04-14T17:55:48.000Z",
													"project": "shopify",
													"updated_at": "2020-04-14T17:55:48.000Z"
												}
											]
										}
										this._service.setdevcomnexPages(settingpages);

										window.location.href = "/Shopify-Dashboard";
										this.router.navigateByUrl('/Shopify-Dashboard');
										return;
									}
								}
								else {
									if (access_token) {
										this._service.set_access_token(access_token);
										this._service.set_loggedin_user(res['user_details']);
										// var user_id_list = this._service.get_speical_access_users();
										// var user_id = res['user_details'].id
										// console.log(res['user_details']);
										// const result_after_filter = user_id_list.filter(s =>s.includes(user_id));
										// if(result_after_filter)
										// {
										// 	console.log("----id-match---");
										// }
										//   debugger;
										// Calling team api
										//console.log('prod login team api',this.API_End_Point);

										this.API.teamAPI(this.API_End_Point)
											.subscribe(
												(res) => {

													//debugger;
													  console.log('printing team response',res)
													this._service.set_my_team(res);

													// Calling settings api
													//   console.log('signin settigs')

													this.API.settingsAPI(this.API_End_Point).subscribe(
														(res: any) => {
															//console.log('printing settings response', res);
															//   debugger;
															if (res.success) {
																stageSettings = res.success;
																this._service.set_stage_settings(res.success);
																// console.log('signin full team api');
																if (res.success.web) {
																	for (var i in res.success.web) {
																		if (res.success.web[i]['name'] == 'landing_page') {
																			landing_page = res.success.web[i]['definition'];
																		}
																	}
																}

															} else {
																alert('Error in fetching settings');
																this._service.clearAllItems();
																this.router.navigate(['/managementTeam']);
																return;
															}
															this.API.getFullTeamData(this.API_End_Point).subscribe(
																(res: any) => {
																	if (res.success) {
																		this._service.set_Full_team(res.success);
																		this.showLoader = false;
																		// Routing to Dashboard
																		if (userDetails.role == 'user') {
																			var obj = { "activity_type": "signin", "gps_status": "{\"latitude\":22.0860604,\"longitude\":82.188001}", "call_recording_status": "false", "battery_status": "0", "user_id": userDetails.id, "version": "26" } as any;
																			this.API.agentStartEndSession(obj, this.API_End_Point).subscribe(
																				(res: any) => {
																					//console.log(res)
																				}, (err) => {
																					//console.log(err)
																				}
																			)
																			if (landing_page.toLowerCase().includes('audit')) {
																				this.router.navigate(['/' + landing_page ? landing_page : 'dashboard']);
																			} else {
																				// if(!userDetails.isVisonEnabled){
																				//   this.router.navigate(['/agentPlugin/face']);
																				//   return;
																				// }
																				// this.API.checkAssessmentGiven(userDetails.groupid,userDetails.id).subscribe(
																				//   (res: any) => {
																				//     if(res.success == 1){
																				//       this.router.navigate(['/agentPlugin/assesment']);
																				//       // return;
																				//     }else{
																				//       this.router.navigate(['/agentPlugin/home']);
																				//     }
																				//   }, (err) => {
																				//     console.log(err);
																				//     this.router.navigate(['/agentPlugin/home']);
																				//   }
																				// )
																				// return;
																				if (stageSettings && stageSettings.extraoptions) {
																					for (let opt of stageSettings.extraoptions) {
																						if (opt.name == "biometric" && opt.definition == 'true') {
																							if (!userDetails.isVisonEnabled) {
																								this.router.navigate(['/agentPluginPC/face']);
																								return;
																							}
																						}
																						if (opt.name == "assessment" && opt.definition == 'true') {
																							this.API.checkAssessmentGiven(userDetails.applicant_group, userDetails.id, this.API_End_Point).subscribe(
																								(res: any) => {
																									if (res.success == 1) {
																										//this.router.navigate(['/agentPluginPC/assessment']);
																										window.location.href = ('/agentPluginPC/assessment');
																										return;
																									} else {
																										//this.router.navigate(['/agentPluginPC/home']);
																										window.location.href = ('/agentPluginPC/home');
																									}
																								}, (err) => {
																									//console.log(err)
																								}
																							)
																						} else {
																							this.router.navigate(['/agentPlugin/home']);
																						}
																					}
																				} else {
																					this.router.navigate(['/agentPlugin/home']);
																				}
																			}

																		} else {
																			let total_pages = this._service.setMyPages();
																			// console.log('total_pages', total_pages);
																			let page_found: any = false;
																			for (var i in total_pages) {
																				if ((total_pages[i]['data_id'] == landing_page) && total_pages[i]['isAccessed']) {
																					page_found = total_pages[i];
																					break;
																				}
																			}
																			if (page_found) {
																				//	this.router.navigate([page_found['path']]);
																				window.location.href = window.location.protocol + "//" + window.location.host + page_found['path'];
																			} else {
																				this.router.navigate(['/managementTeam']);
																			}
																		}
																	} else {
																		alert(res.error)
																	}
																}, (err) => {
																	alert('Error in the full team fetch')
																}
															)
														},
														(err) => {
															alert('Error in the settings api')
														}
													)
												},
												(err) => {
													// debugger;
													alert('Error in the api')
												}
											)

									} 
								}
							}
						}
					},
					(err) => {
						this._snackBar.open("User does not exist", "close", {
							duration: 2000
						});
						//alert("User does not exist")
					});
		}else{
			//this.API.SBIDEVloginAPI
			console.log('calling Uearn login')
			this.API.SBIDEVloginAPI(formData)
				.subscribe(
					(res:any) => {
						console.log('printing res', res)
						if(res.errors){
							return this.login_error_handler(res.errors);
						}
						console.log('after errors');
						let _error = res["status"];
						if (_error == "failure") {
							const demo_user = this._service.is_ainex_demo_user(formData.email, formData.password)
							if (demo_user) {
								// let objJsonStr = JSON.stringify(demo_user);
								// let objJsonB64 = btoa(objJsonStr);
								window.location.href = 'http://ainexdemo.contiinex.com/' + demo_user.page;
								return;
							} else {
								this.showLoader = false;
								this._snackBar.open("Invalid Credentials", "close", {
									duration: 5000
								});
							}
							// alert("Invalid Credentials,User does not exist");
							//return false;
						}
						else {
							userDetails = res['user_details']
							if (userDetails['mail_otp_authentication'] == true) {
								this.showLoader = false;
								const seconds = this.calculate_seconds_for_reset_password(userDetails['otp_generated_time']);
								if(seconds < 600){
									this.otpvalidation = true;
									this.idpassword = false;
									this.timerClock(600-seconds);
								}
								return;
							}
							console.log("--user details--" + res['user_details']);
							//console.log(res['user_details']['id']);
							if (res['user_details']) {
								userDetails = res['user_details'];
								let access_token = res['user_details']['access_token'];
								//console.log(access_token)
								localStorage.setItem('access_token', access_token);


								this.API_Platform = res['platform_details']['Platform_Name'];

								if (res['user_details']["domain_url"]) {
									this._service.set_API_End_Point(res['user_details']["domain_url"]);
									this.API_End_Point = res['user_details']["domain_url"];
								}
								else {
									this._service.set_API_End_Point(res['platform_details']['API_End_Point']);
									this.API_End_Point = res['platform_details']['API_End_Point'];
								}
								this._service.set_API_Platform1(res['platform_details']['Platform_Name'])

								let landing_page = '';
								this._service.set_access_token(access_token);
								this._service.set_loggedin_user(res['user_details']);

								if (this.API_Platform == "CONTIINEX") {
									this.$gaService.event('Clicked Login', 'Login Page');
									userDetails = res;
									if (res['user_details']["multi_user_login"]) {
										this.merchants_list = res['user_details']["multi_user_login"];
										this.showHideMultiUser = true;
										return;
									} else if (res['user_details']['businessType'] && (res['user_details']['businessType'] == 'COMNEX') && res['user_details']['partner'] != "SHOPIFY") {

										if (access_token) {
											this._service.set_access_token(access_token);
											this._service.set_loggedin_user(res['user_details']);
										}
										if (res['user_details']['company'] == "COLLECTIONS") {
											var settingpages = {
												"web": [
													{
														"stage": 1,
														"type": "web",
														"name": "landing_page",
														"definition": "funnel-dashboard",
														"project": "comnex",
														"created_at": "2020-10-15T09:00:25.000Z",
														"updated_at": "2020-10-15T09:00:25.000Z"
													},
													{
														"stage": 1,
														"type": "web",
														"name": "pages",
														"definition": "Dashboard,funnel-dashboard,details-view,processFlowBuilder,Reports",
														"created_at": "2020-04-14T17:55:48.000Z",
														"project": "comnex",
														"updated_at": "2020-04-14T17:55:48.000Z"
													}
												]
											}
											this._service.setdevcomnexPages(settingpages);
											window.location.href = "/Funnel-Dashboard";
											return;
										}

										else {
											var settingpages = {
												"web": [
													{
														"stage": 1,
														"type": "web",
														"name": "landing_page",
														"definition": "funnel-dashboard",
														"project": "comnex",
														"created_at": "2020-10-15T09:00:25.000Z",
														"updated_at": "2020-10-15T09:00:25.000Z"
													},
													{
														"stage": 1,
														"type": "web",
														"name": "pages",
														"definition": "Dashboard,funnel-dashboard,details-view,Message-Template,Reports,contiinex-campaign",
														"created_at": "2020-04-14T17:55:48.000Z",
														"project": "comnex",
														"updated_at": "2020-04-14T17:55:48.000Z"
													}
												]
											}
											this._service.setdevcomnexPages(settingpages);
											window.location.href = "/Funnel-Dashboard";
											return;
										}


									}
									else if (res['user_details']['businessType'] && (res['user_details']['businessType'] == 'WABA')) {
										if (access_token) {
											this._service.set_access_token(access_token);
											this._service.set_loggedin_user(res['user_details']);
										}
										if ((res['user_details']['company'] == "SMBWABA")) {
											var settingpages = {
												"web": [
													{
														"stage": 1,
														"type": "web",
														"name": "landing_page",
														"definition": "Profile-Settings",
														"project": "comnex",
														"created_at": "2020-10-15T09:00:25.000Z",
														"updated_at": "2020-10-15T09:00:25.000Z"
													},
													{
														"stage": 1,
														"type": "web",
														"name": "pages",
														"definition": "Dashboard,Reports,Profile-Settings,Message-Template,Contiinex-sandbox,contiinex-campaign",
														"created_at": "2020-04-14T17:55:48.000Z",
														"project": "comnex",
														"updated_at": "2020-04-14T17:55:48.000Z"
													}
												]
											}
											this._service.setdevcomnexPages(settingpages);
											window.location.href = "/Profile-Settings";
											return;

										}
										else {
											var settingpages = {
												"web": [
													{
														"stage": 1,
														"type": "web",
														"name": "landing_page",
														"definition": "Profile-Settings",
														"project": "comnex",
														"created_at": "2020-10-15T09:00:25.000Z",
														"updated_at": "2020-10-15T09:00:25.000Z"
													},
													{
														"stage": 1,
														"type": "web",
														"name": "pages",
														"definition": "Dashboard,Reports,Profile-Settings,Message-Template,contiinex-campaign",
														"created_at": "2020-04-14T17:55:48.000Z",
														"project": "comnex",
														"updated_at": "2020-04-14T17:55:48.000Z"
													}
												]
											}
											this._service.setdevcomnexPages(settingpages);
											window.location.href = "/Profile-Settings";
											//this.router.navigateByUrl('/Profile-Settings');
											return;

										}

									}


									else if (res['user_details']['businessType'] && (res['user_details']['businessType'] == 'COMNEX') && res['user_details']['partner'] == "SHOPIFY") {
										if (access_token) {
											this._service.set_access_token(access_token);
											this._service.set_loggedin_user(res['user_details']);
										}
										localStorage.removeItem('shopifyForRegister');
										localStorage.setItem('shopifyForRegister', 'ShopifyReg');

										var settingpages = {
											"web": [
												{
													"stage": 1,
													"type": "web",
													"name": "landing_page",
													"definition": "Shopify-Dashboard",
													"project": "shopify",
													"created_at": "2020-10-15T09:00:25.000Z",
													"updated_at": "2020-10-15T09:00:25.000Z"
												},
												{
													"stage": 1,
													"type": "web",
													"name": "pages",
													"definition": "shopify-dashboard,shopify-team,shopify-billing,shopify-setting",
													"created_at": "2020-04-14T17:55:48.000Z",
													"project": "shopify",
													"updated_at": "2020-04-14T17:55:48.000Z"
												}
											]
										}
										this._service.setdevcomnexPages(settingpages);

										window.location.href = "/Shopify-Dashboard";
										this.router.navigateByUrl('/Shopify-Dashboard');
										return;
									}
								}
								else {
									if (access_token) {
										this._service.set_access_token(access_token);
										this._service.set_loggedin_user(res['user_details']);
										// var user_id_list = this._service.get_speical_access_users();
										// var user_id = res['user_details'].id
										// console.log(res['user_details']);
										// const result_after_filter = user_id_list.filter(s =>s.includes(user_id));
										// if(result_after_filter)
										// {
										// 	console.log("----id-match---");
										// }
										//   debugger;
										// Calling team api
										//console.log('login team api',this.API_End_Point);

										this.API.teamAPI(this.API_End_Point)
											.subscribe(
												(res) => {

													//debugger;
													//   console.log('printing team response',res)
													this._service.set_my_team(res);

													// Calling settings api
													//   console.log('signin settigs')

													this.API.settingsAPI(this.API_End_Point).subscribe(
														(res: any) => {
															//console.log('printing settings response', res);
															//   debugger;
															if (res.success) {
																stageSettings = res.success;
																this._service.set_stage_settings(res.success);
																// console.log('signin full team api');
																if (res.success.web) {
																	for (var i in res.success.web) {
																		if (res.success.web[i]['name'] == 'landing_page') {
																			landing_page = res.success.web[i]['definition'];
																		}
																	}
																}

															} else {
																alert('Error in fetching settings');
																this._service.clearAllItems();
																this.router.navigate(['/managementTeam']);
																return;
															}
															this.API.getFullTeamData(this.API_End_Point).subscribe(
																(res: any) => {
																	if (res.success) {
																		this._service.set_Full_team(res.success);
																		this.showLoader = false;
																		// Routing to Dashboard
																		if (userDetails.role == 'user') {
																			var obj = { "activity_type": "signin", "gps_status": "{\"latitude\":22.0860604,\"longitude\":82.188001}", "call_recording_status": "false", "battery_status": "0", "user_id": userDetails.id, "version": "26" } as any;
																			this.API.agentStartEndSession(obj, this.API_End_Point).subscribe(
																				(res: any) => {
																					//console.log(res)
																				}, (err) => {
																					//console.log(err)
																				}
																			)
																			if (landing_page.toLowerCase().includes('audit')) {
																				this.router.navigate(['/' + landing_page ? landing_page : 'dashboard']);
																			} else {
																				// if(!userDetails.isVisonEnabled){
																				//   this.router.navigate(['/agentPlugin/face']);
																				//   return;
																				// }
																				// this.API.checkAssessmentGiven(userDetails.groupid,userDetails.id).subscribe(
																				//   (res: any) => {
																				//     if(res.success == 1){
																				//       this.router.navigate(['/agentPlugin/assesment']);
																				//       // return;
																				//     }else{
																				//       this.router.navigate(['/agentPlugin/home']);
																				//     }
																				//   }, (err) => {
																				//     console.log(err);
																				//     this.router.navigate(['/agentPlugin/home']);
																				//   }
																				// )
																				// return;
																				if (stageSettings && stageSettings.extraoptions) {
																					for (let opt of stageSettings.extraoptions) {
																						if (opt.name == "biometric" && opt.definition == 'true') {
																							if (!userDetails.isVisonEnabled) {
																								this.router.navigate(['/agentPluginPC/face']);
																								return;
																							}
																						}
																						if (opt.name == "assessment" && opt.definition == 'true') {
																							this.API.checkAssessmentGiven(userDetails.applicant_group, userDetails.id, this.API_End_Point).subscribe(
																								(res: any) => {
																									if (res.success == 1) {
																										//this.router.navigate(['/agentPluginPC/assessment']);
																										window.location.href = ('/agentPluginPC/assessment');
																										return;
																									} else {
																										//this.router.navigate(['/agentPluginPC/home']);
																										window.location.href = ('/agentPluginPC/home');

																									}
																								}, (err) => {
																									//console.log(err)
																								}
																							)
																						} else {

																							// if(this.windowNewOpenCounter==1){
																							// 	var mywin;
																							// 	setTimeout(() => {
																							// 		console.log('Launching Exten..');
																							// 		mywin = window.open("chrome-extension://"+this.extentionID+"/index.html", "myexten", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=2000,width=300,height=700");
																							// 	 }, 1000);
																							// 	this.windowNewOpenCounter++;
																							// }
																							this.router.navigate(['/agentPlugin/home']);
																						}
																					}
																				} else {
																					this.router.navigate(['/agentPlugin/home']);
																				}
																			}

																		} else {
																			let total_pages = this._service.setMyPages();
																			// console.log('total_pages', total_pages);
																			let page_found: any = false;
																			for (var i in total_pages) {
																				if ((total_pages[i]['data_id'] == landing_page) && total_pages[i]['isAccessed']) {
																					page_found = total_pages[i];
																					break;
																				}
																			}
																			if (page_found) {
																				//	this.router.navigate([page_found['path']]);
																				window.location.href = window.location.protocol + "//" + window.location.host + page_found['path'];
																			} else {
																				this.router.navigate(['/managementTeam']);
																			}
																		}
																	} else {
																		alert(res.error)
																	}
																}, (err) => {
																	alert('Error in the full team fetch')
																}
															)
														},
														(err) => {
															alert('Error in the settings api')
														}
													)
												},
												(err) => {
													// debugger;
													alert('Error in the api')
												}
											)
										/* this.API.loginAinexAPI(formData)
											.subscribe(
												(res2: any) => {
													localStorage.setItem('ainex_token', res2["token"])
												}) */

									}
								}
							}
						}
					},
					(err) => {
						this._snackBar.open("User does not exist", "close", {
							duration: 2000
						});
						//alert("User does not exist")
					});
		}

		return;
	}

	ainexLogin(formData) {
		//	debugger;
		var userDetails, stageSettings;

		this.API.loginAinexAPI(formData)
			.subscribe(
				(res: any) => {
					// debugger;
					// console.log('printing res',res)
					let _error = res["errors"];
					if (_error == "Invalid Credentials" || _error == "User does not exist") {
						this.showLoader = false;
						// alert("Invalid Credentials,User does not exist");
						// alert(_error);
						this.showHideErrorMsg = true;
						this.passMsgText = _error;
						setTimeout(() => {
							this.showHideErrorMsg = false;
							this.passMsgText = "";
						}, 5000);
						return false;
					}
					else {
						userDetails = res;
						let objJsonStr = JSON.stringify(userDetails);
						let objJsonB64 = btoa(objJsonStr);
						if (window.location.href.includes('dev.contiinex')) {
							window.location.href = 'https://ainexdev.contiinex.com/exl?session=' + objJsonB64;
						} else {
							if (res['email'] == 'demo@sainapse.ai') {
								window.location.href = 'https://ainex.contiinex.com/agent-assist?session=' + objJsonB64;
							}
							else {
								window.location.href = 'https://ainex.contiinex.com/voiceCallAudit?session=' + objJsonB64;
							}

						}
						return;
					}
				},
				(err) => {
					// alert("User does not exist");
					this.showHideErrorMsg = true;
					this.passMsgText = "User does not exist";
					setTimeout(() => {
						this.showHideErrorMsg = false;
						this.passMsgText = "";
					}, 5000);
				});
	}
	conweb() {
		//this.router.navigateByUrl('/');
		window.location.href = "https://www.contiinex.com/";
		this.$gaService.event('Clicked Contiinex Logo', 'Login Page');
	}
	otpsbumit(otpForm: any) {
		let otp = otpForm.controls.otp.value;
		//console.log(otp);
		if (otp == "") {
			this.showHideErrorMsg = true;
			this.passMsgText = "* Please enter otp ";
			setTimeout(() => {
				this.showHideErrorMsg = false;
				this.passMsgText = "";
			}, 5000);
			return;
		}
		this.showLoader = true;
		let formData = this.get_form_data_login();
		formData["otp_number"] = otp;
		this.otpvalidation = false;
		this.check_redirection_login(formData);
	}
	resend_otp() {
		this.otpvalidation = true;
		this.reset_otp_timer();
		this.resend_otp_disabled = true;
		let formData = this.get_form_data_login();
		this.check_redirection_login(formData);
	}

	calculate_seconds_for_reset_password(server_time):number{
		try{
			const now = moment();
			const server_local_time = moment.utc(server_time).local();
			return server_local_time.diff(now, 'seconds');
		}catch(e){
			return 0;
		}
	}

	timerClock(seconds_required:number) {
		console.log('seconds received', seconds_required);
		const source = timer(0, 1000).subscribe(val => {
			// console.log('timer',this.resend_otp_disabled,seconds_required, val);
			this.calculate_otp_timer((seconds_required - val));
			if(this.disable_timer_calculation){
				source.unsubscribe()
			}else{
				if(val == seconds_required){
					this.resend_otp_disabled = false;
					source.unsubscribe();
				}
			}
		})
	}
	get f() {
		return this.otpForm.controls;
	}

	reset_otp_timer(){
		this.clockTimer = "00:00";
	}

	calculate_otp_timer(seconds_show){
		let minutes, seconds, formatedMinutes, formatedSeconds;
		minutes = Math.floor(seconds_show / 60) || 0;
		seconds = seconds_show % 60;

		formatedMinutes = minutes > 9 ? minutes : '0' + minutes;
		formatedSeconds = seconds > 9 ? seconds : '0' + seconds;

		this.clockTimer = `${formatedMinutes} : ${formatedSeconds}`;
	}

	forgotPasswordBtn() {
		this.forgotEmail = "";
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;

		this.showHideLoginPageUI = false;
		this.showHideForgotPageUI = true;
		this.showHideResetPageUI = false;
	}
	backToLoginBtn() {
		this.forgotEmail = "";
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;
		this.showHideLoginPageUI = true;
		this.showHideForgotPageUI = false;
		this.showHideResetPageUI = false;
		this.router.navigateByUrl('/signin');
	}


	validateForgotEmail() {
		let emailField = this.forgotEmail;
		let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if (reg.test(emailField) == false) {
			this.forgotMsgError = "Please Enter valid Email Address";
			this.showHideForgotMsg = true;
			return false;
		}
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;

		return true;

	}
	sendForgotEmail() {
		/* console.log('window location', window.location)
		return; */
		this.forgotMsgError = "";
		this.showHideForgotMsg = false;
		let emailField = this.forgotEmail;
		let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if (emailField == "" || emailField == null || emailField == undefined) {
			this.forgotMsgError = "Please Enter valid Email Address";
			this.showHideForgotMsg = true;
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 3000)
			return false;
		}
		if (reg.test(emailField) == false) {
			this.forgotMsgError = "Please Enter valid Email Address";
			this.showHideForgotMsg = true;
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 3000)
			return false;
		}
		let obj = {
			"email": emailField,
			"domain": window.location.origin
		}

		this.showLoader = true;
		this.API.passwordReset(obj).subscribe((res: any) => {
			this.showLoader = false;
			if (res.errors) {
				this.forgotMsgError = res.errors;
				this.showHideForgotMsg = true;
				setTimeout(() => {
					this.forgotMsgError = "";
					this.showHideForgotMsg = false;
				}, 3000)
				return false;
			} else {

				setTimeout(() => {
					this.showHideResetPageUI = true;
					this.showHideForgotPageUI = false;
					this.showHideLoginPageUI = false;
					this.forgotMsgError = "";
					this.showHideForgotMsg = false;
				}, 2000)
				return false;
			}
		}, (err) => {
			this.showLoader = false;
			this.forgotMsgError = "Something went wrong we couldnt not fetch your details";
			this.showHideForgotMsg = true;
			setTimeout(() => {
				this.forgotMsgError = "";
				this.showHideForgotMsg = false;
			}, 3000)
			return false;
		});

	}

}
