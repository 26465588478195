import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyserviceService } from 'src/app/myservice.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-contiinex-message-template-create',
	templateUrl: './contiinex-message-template-create.component.html',
	styleUrls: ['./contiinex-message-template-create.component.scss']
})
export class ContiinexMessageTemplateCreateComponent implements OnInit {

	@Output() onPassToList = new EventEmitter<any>();

	titleCreateEdit: any = "Create Message Template";
	@Input() itemEdit = '';
	createdForm: FormGroup;
	showHideErrorMsg: any;
	msgColor: any = "";
	showHideMsg: boolean = false;

	nameTemplateCount: any = 0;
	headerCount: any = 0;
	footerCount: any = 0;
	bodyCount: any = 0;
	_headerPreview: any = "Header";
	_bodyMsgPreview: any = "Body Message";
	_footerPreview: any = "";
	phoneCount: any = 0;
	btnTextCount: any = 0;
	btnTextCount2: any = 0;

	btnTextCountQuick1: any = 0;
	btnTextCountQuick2: any = 0;
	btnTextCountQuick3: any = 0;

	showHideButtonDiv: boolean = false;
	showHideButtonDiv2: boolean = false;
	ShowHide_For_CallPhone: boolean = false;
	ShowHide_For_Visit_Website: boolean = false;
	_headerAddVariable: any = "";
	_bodyAddVariable: any = "";
	_footerAddVariable: any = "";

	headerOptionValue: any = "";
	showHideHeaderText: boolean = false;
	showHideHeaderImage: boolean = false;
	showHideHeaderVideo: boolean = false;
	showHideHeaderDocument: boolean = false;
	hdrImageUrl: any;
	_headerPreviewImageUrl: any;
	hdrVideoUrl: any;
	_headerPreviewVideoUrl: any;

	rowRecordQuick = [
		{
			first1: '',
		}
	];

	listQuickButtonModel: any = [];
	setIndexNo: any = 0;
	actionTypeDdl: any;
	headerRadioChangeValue: any = "";
	btnTextCountCall: any = 0;
	btnTextCountVisit: any = 0;

	phoneButtonValueForPreview: any = "CALL";
	visitWebValueForPreview: any = "LINK";

	wa_number: any;
	bearer_token: any;
	_languagesList: any = [];
	_categoryList: any = [];

	isShowHeaderVariableText: boolean = false;
	_headerAddVarValue: any = "";
	_headerJsonObj: any = {};
	_bodyMessageJsonObj: any = {};
	_quickActionReplyObject: any = {};

	displayedFields: string[] = ['name'];
	bodyformArray = new FormArray([]);
	_webAddVariable: any = "";


	_webAddVarValue: any = "";
	isShowWebVariableText: boolean = false;
	customer_name: any;
	yourBearerToken: any = "";

	filenameContainer: any = "";
	filenameContainerVideo: any = "";
	filenameDocumentContainer: any = "";
	isShowForDynamic: boolean = false;

	_documentUrl: any = "";
	_loadVideoFileDetails1: any = "";


	isShowEyeOn: boolean = true;
	isShowEyeOff: boolean = false;
	_headerPreview1: any = "";
	_headerPreview2: any = "";

	isDisabledForEdit: boolean = false;

	quickCallModelDDl: any = "";

	forModifyVideoUrl: boolean = false;
	radioEditHdrVal: any = "";
	currentLoader: boolean = false;
	listQuickBtn: any = [];
	_listQuick:any=[];

	api_end_point:any;
	constructor(private router: Router,
		private http: HttpClient,
		private fb: FormBuilder, private API: ApiService, private _snackBar: MatSnackBar, private _service: MyserviceService, private _sanitizer: DomSanitizer) {
		this.createdForm = fb.group({
			templatename: ['', [Validators.required]],
			language: ['', Validators.required],
			category: ['', [Validators.required]],
			headerTitle: ['', Validators.required],
			headerAddVarValue: [''],
			bodyMessage: ['', Validators.required],
			footerTitle: ['', Validators.required],
			buttonTitle: ['', Validators.required],

			CallPhoneModel: ['PHONE_NUMBER'],
			btnActionCallModel: [''],
			visitWebsiteModel: ['URL'],
			btnActionVisitModel: [''],
			stdCodeModel: ['+91'],
			btnActionphoneModel: [''],
			btnActionUrlModel: ['static'],
			btnActionWebsiteModel: [''],
			webAddVarValue: ['']
		})

	}

	ngOnInit() {
		this.api_end_point = this._service.get_API_End_Point();
		if (this._service.get_loggedin_user()) {
			this.bearer_token = String(this._service.get_loggedin_user().bearer_token);
			this.wa_number = String(this._service.get_loggedin_user().phone); //You should trim the '+' sign before using
			this.yourBearerToken= this.bearer_token;
		}
		if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
			this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
		}
		// this.API.getWabaProfile(this.customer_name,this.api_end_point).subscribe((res: any) => {
		//   if (res && res.account && res.profile) {
		//     if (res.profile) {
		//       this.yourBearerToken = res.profile.bearerToken;
		//     }
		//   }
		// });

		this.getLanguages();
		this.getCategories();

		this.titleCreateEdit = "";
		this.isDisabledForEdit = false;

		if (this.itemEdit) {
			this.titleCreateEdit = "Edit Message Template";
			this.isDisabledForEdit = true;
			setTimeout(() => {
				this.createdForm.get("templatename").patchValue(this.itemEdit['name']);
				this.createdForm.get("language").patchValue(this.itemEdit['language']);
				this.createdForm.get("category").patchValue(this.itemEdit['category']);

				if (this.itemEdit['footer']) {
					this.createdForm.get("footerTitle").patchValue(this.itemEdit['footer'].text);
					this._footerPreview = this.itemEdit['footer'].text ? this.itemEdit['footer'].text : '';
				}

				if (this.itemEdit['buttons']) {
					this.quickCallModelDDl = "";
					if ((this.itemEdit['buttons'][0].type == "QUICK_REPLY")) {
						this.quickCallModelDDl = "quickReply";
						this.showHideButtonDiv = false;
						this.showHideButtonDiv2 = true;
						this.rowRecordQuick = [];
						for (let i = 0; i < this.itemEdit['buttons'].length; i++) {
							const obj = {
								first1: this.itemEdit['buttons'][i].text
							}
							this.rowRecordQuick.push(obj);

						}
						let _quickBtnList = [];
						for (let j = 0; j < this.rowRecordQuick.length; j++) {
							_quickBtnList.push({ 'text': this.rowRecordQuick[j].first1 })
						}
						this.listQuickButtonModel = _quickBtnList;
					}
					else {
						this.ShowHide_For_CallPhone = false;
						this.ShowHide_For_Visit_Website = false;

						if ((this.itemEdit['buttons'][0].type == "PHONE_NUMBER") && (this.itemEdit['buttons'].length == 1)) {
							this.showHideButtonDiv = true;
							this.showHideButtonDiv2 = false;
							this.ShowHide_For_CallPhone = true;
							this.ShowHide_For_Visit_Website = false;
							this.quickCallModelDDl = "callToAction";

							let _phoneNum = this.itemEdit['buttons'][0].phone_number;

							if (_phoneNum) {
								if (_phoneNum.slice(0, 3) == "+91") {
									_phoneNum = _phoneNum.slice(3);

									this.createdForm.get("stdCodeModel").patchValue((this.itemEdit['buttons'][0].phone_number).slice(0, 3));
								}
								if (_phoneNum.slice(0, 2) == "+1") {
									_phoneNum = _phoneNum.slice(2);
									this.createdForm.get("stdCodeModel").patchValue((this.itemEdit['buttons'][0].phone_number).slice(0, 2));
								}
							}
							this.createdForm.get("btnActionCallModel").patchValue((this.itemEdit['buttons'][0].text));
							this.createdForm.get("btnActionphoneModel").patchValue(_phoneNum);
						}
						else if ((this.itemEdit['buttons'][0].type == "URL") && (this.itemEdit['buttons'].length == 1)) {
							this.showHideButtonDiv = true;
							this.showHideButtonDiv2 = false;
							this.ShowHide_For_CallPhone = false;
							this.ShowHide_For_Visit_Website = true;
							this.quickCallModelDDl = "callToAction";
							this.createdForm.get("btnActionVisitModel").patchValue((this.itemEdit['buttons'][0].text));
							this._webAddVariable = this.itemEdit['buttons'][0].url;


							let _checkBracketCurly = /\s*\{{.*?\}}\s*/g.test(this._webAddVariable);
							if (_checkBracketCurly == true) {
								this.isShowForDynamic = true;
								this.isShowWebVariableText = true;
								this.createdForm.get("btnActionUrlModel").patchValue('dynamic');
								this._webAddVarValue = this.itemEdit['buttons'][0].example[0];
							}
							else {
								this.isShowForDynamic = false;
								this.isShowWebVariableText = false;
								this.createdForm.get("btnActionUrlModel").patchValue('static');
							}

						}
						else if ((this.itemEdit['buttons'][0].type == "PHONE_NUMBER") && (this.itemEdit['buttons'][1].type == "URL") && this.itemEdit['buttons'].length == 2) {
							this.showHideButtonDiv = true;
							this.showHideButtonDiv2 = false;
							this.ShowHide_For_CallPhone = true;
							this.ShowHide_For_Visit_Website = true;
							this.quickCallModelDDl = "callToAction";
							let _phoneNum = this.itemEdit['buttons'][0].phone_number;

							if (_phoneNum) {
								if (_phoneNum.slice(0, 3) == "+91") {
									_phoneNum = _phoneNum.slice(3);

									this.createdForm.get("stdCodeModel").patchValue((this.itemEdit['buttons'][0].phone_number).slice(0, 3));
								}
								if (_phoneNum.slice(0, 2) == "+1") {
									_phoneNum = _phoneNum.slice(2);
									this.createdForm.get("stdCodeModel").patchValue((this.itemEdit['buttons'][0].phone_number).slice(0, 2));
								}
							}

							this.createdForm.get("btnActionCallModel").patchValue((this.itemEdit['buttons'][0].text));
							this.createdForm.get("btnActionphoneModel").patchValue(_phoneNum);

							this.createdForm.get("btnActionVisitModel").patchValue((this.itemEdit['buttons'][1].text));
							this._webAddVariable = this.itemEdit['buttons'][1].url;

							let _checkBracketCurly = /\s*\{{.*?\}}\s*/g.test(this._webAddVariable);
							if (_checkBracketCurly == true) {
								this.isShowForDynamic = true;
								this.isShowWebVariableText = true;
								this.createdForm.get("btnActionUrlModel").patchValue('dynamic');

								this._webAddVarValue = this.itemEdit['buttons'][1].example[0];
							}
							else {
								this.isShowForDynamic = false;
								this.isShowWebVariableText = false;
								this.createdForm.get("btnActionUrlModel").patchValue('static');

							}


						}
					}

				}








				if (this.itemEdit['header']) {
					this.headerOptionValue = "";
					if ((this.itemEdit['header'].type == "HEADER") && (this.itemEdit['header'].format == "TEXT") && !this.itemEdit['header'].example) {
						this.headerOptionValue = "TEXT";
						this.showHideHeaderText = true;
						this.isShowHeaderVariableText = false;
						this._headerAddVariable = this.itemEdit['header'].text;
						this._headerPreview1 = this._headerAddVariable;
					}

					if ((this.itemEdit['header'].type == "HEADER") && (this.itemEdit['header'].format == "TEXT") && this.itemEdit['header'].example) {
						this.headerOptionValue = "TEXT";
						this.showHideHeaderText = true;
						this._headerAddVariable = this.itemEdit['header'].text;
						this._headerPreview1 = this._headerAddVariable;

						this.isShowHeaderVariableText = true;
						this._headerAddVarValue = this.itemEdit['header'].example.header_text[0];


					}

					if ((this.itemEdit['header'].type == "HEADER") && (this.itemEdit['header'].format == "IMAGE")) {
						this.headerOptionValue = "IMAGE";
						this.showHideHeaderImage = true;
						if (this.itemEdit['header'].example) {
							this.hdrImageUrl = this.itemEdit['header'].example.header_handle[0];
							this._headerPreviewImageUrl = this.itemEdit['header'].example.header_handle[0];
							this.filenameContainer = "Image selected";
						}



					}
					if ((this.itemEdit['header'].type == "HEADER") && (this.itemEdit['header'].format == "VIDEO")) {
						this.hdrVideoUrl = "";
						this.headerOptionValue = "VIDEO";
						this.showHideHeaderVideo = true;
						if (this.itemEdit['header'].example) {
							this.hdrVideoUrl = this.itemEdit['header'].example.header_handle[0];

							this.hdrVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.hdrVideoUrl);
							this._headerPreviewVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.hdrVideoUrl);
							this.forModifyVideoUrl = true;

						}
					}

					if ((this.itemEdit['header'].type == "HEADER") && (this.itemEdit['header'].format == "DOCUMENT")) {
						this.headerOptionValue = "DOCUMENT";
						this.showHideHeaderDocument = true;
						if (this.itemEdit['header'].example) {
							this._documentUrl = this.itemEdit['header'].example.header_handle[0];
							this.filenameDocumentContainer = "Document selected";
						}
					}
				}


				if (this.itemEdit['template']) {
					let _tempArray = [];
					if ((this.itemEdit['template'].type == "BODY")) {
						this.createdForm.get("bodyMessage").patchValue(this.itemEdit['template'].text);
						document.getElementById('resultBodyTemplate1').innerHTML = (this.itemEdit['template'].text).replace(new RegExp("\n", "g"), "<br>");
						this._bodyMsgPreview = (document.getElementById('resultBodyTemplate1').innerHTML).replace(new RegExp("\n", "g"), "<br>");;
						document.getElementById('resultBodyTemplate1').style.display = "block";
						document.getElementById('resultBodyTemplate2').style.display = "block";
						this.isShowEyeOn = true;
						this.isShowEyeOff = false;

						let _wordCount1 = (this.itemEdit['template'].text).match(/\s*\{{\d+}}\s*/g).length;
						if (_wordCount1 > 0) {
							if ((this.itemEdit['template'].type == "BODY" && this.itemEdit['template'].example.body_text.length > 0)) {
								this.bodyFirstTimeAdd = false;
								_tempArray = this.itemEdit['template'].example.body_text[0];
							}

							var newGroup = new FormGroup({});
							for (let i = 0; i < _wordCount1; i++) {
								let aa1;
								aa1 = _tempArray[i];
								this.displayedFields.forEach(x => {
									newGroup.addControl(x, new FormControl(aa1))
								})
								this.bodyformArray.push(newGroup);
								newGroup = new FormGroup({});
							}
						}
						else {
							this.bodyFirstTimeAdd = true;
						}
					}
				}
			}, 100);
		}

		else {
			this.titleCreateEdit = "Create Message Template";
			this.isDisabledForEdit = false;
			this.hdrImageUrl = "https://youearn.in/files/attachments/228b873a-552d-4ffc-9b38-d43116d05a44.jpeg";
			this.hdrVideoUrl ="";// "https://youtu.be/lt7Hgb95w0A?list=PLdslA-CIica7ODu-IgQMdorq77uIKvdqx";

			document.getElementById('resultBodyTemplate1').style.display = "block";
			document.getElementById('resultBodyTemplate2').style.display = "none";
		}
	}

	submitBtn(createdForm: any, tempType: any) {

		if (tempType == "forModify") {
			this.headerRadioChangeValue = this.headerOptionValue;
			if (this.radioEditHdrVal == "TEXT") {
				this.headerOptionValue = "TEXT";
				this.headerRadioChangeValue = this.headerOptionValue;
			}
			else if (this.radioEditHdrVal == "IMAGE") {
				this.headerOptionValue = "IMAGE";
				this.headerRadioChangeValue = this.headerOptionValue;
			}
			else if (this.radioEditHdrVal == "VIDEO") {
				this.headerOptionValue = "VIDEO";
				this.headerRadioChangeValue = this.headerOptionValue;
			}
			else if (this.radioEditHdrVal == "DOCUMENT") {
				this.headerOptionValue = "DOCUMENT";
				this.headerRadioChangeValue = this.headerOptionValue;
			}
			else if (this.radioEditHdrVal == "") {
				this.headerOptionValue = "";
				this.headerRadioChangeValue = this.headerOptionValue;
			}
		}
//debugger;
		this.showHideErrorMsg = "";
		this.msgColor = "";
		this.showHideMsg = false;
		let _name = createdForm.controls.templatename.value.toLowerCase();
		let _category = createdForm.controls.category.value;
		let _language = createdForm.controls.language.value;
		let _headerTitle;
		let _bodyMessage = createdForm.controls.bodyMessage.value;
		let _footerTitle = createdForm.controls.footerTitle.value;
		let _buttonTitle = createdForm.controls.buttonTitle.value;

		let _checkHeaderRadioValue = this.headerRadioChangeValue;
		if (_checkHeaderRadioValue == "") {
			_headerTitle = "";
			this._headerJsonObj = {};
		}
		if (_checkHeaderRadioValue == "TEXT") {
			this._headerJsonObj = {};
			_headerTitle = createdForm.controls.headerTitle.value;
			let _varValueHdr = "";
			_varValueHdr = this.createdForm.controls.headerAddVarValue.value;

			let _resultExample = _headerTitle.includes("{{1}}");
			if (_resultExample == false) {
				this._headerJsonObj = {
					"type": "HEADER",
					"format": "TEXT",
					"text": _headerTitle
				}
			}
			else if (_resultExample == true) {
				if(_varValueHdr==""){
					this._snackBar.open("Enter Add variable value in Header Text", 'close', {
						duration: 5000,
					});
					return;
				}
				this._headerJsonObj = {
					"type": "HEADER",
					"format": "TEXT",
					"text": _headerTitle,
					"example": { "header_text": [_varValueHdr] }
				}
			}
		}
		if (_checkHeaderRadioValue == "IMAGE") {
			_headerTitle = this.hdrImageUrl;
			if (_headerTitle == "" || _headerTitle == undefined) {
				this.showHideErrorMsg = "Please Select Image on Header *.";
				this.showHideMsg = true;
				this.msgColor = "red";
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				})
				setTimeout(() => {
					this.showHideErrorMsg = "";
					this.msgColor = "";
					this.showHideMsg = false;
				}, 5000)
				return false;
			}
			this._headerJsonObj = {
				"type": "HEADER",
				"format": "IMAGE",
				"example": { "header_handle": [_headerTitle] }
			}
		}
		if (_checkHeaderRadioValue == "VIDEO") {
			_headerTitle = this.hdrVideoUrl;
			if (_headerTitle == "") {
				this.showHideErrorMsg = "Please Select Video on Header *.";
				this.showHideMsg = true;
				this.msgColor = "red";
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				})
				setTimeout(() => {
					this.showHideErrorMsg = "";
					this.msgColor = "";
					this.showHideMsg = false;
				}, 6000)
				return false;
			}
			this._headerJsonObj = {
				"type": "HEADER",
				"format": "VIDEO",
				"example": { "header_handle": [_headerTitle] }
			}

		}
		if (_checkHeaderRadioValue == "DOCUMENT") {
			_headerTitle = this._documentUrl;
			if (_headerTitle == "") {
				this.showHideErrorMsg = "Please Select PDF File on Document Header *.";
				this.showHideMsg = true;
				this.msgColor = "red";
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				})
				setTimeout(() => {
					this.showHideErrorMsg = "";
					this.msgColor = "";
					this.showHideMsg = false;
				}, 5000)
				return false;
			}
			this._headerJsonObj = {
				"type": "HEADER",
				"format": "DOCUMENT",
				"example": { "header_handle": [_headerTitle] }
			}
		}



		if (_name == "" || _name == null) {
			this.showHideErrorMsg = "Please enter template name *.";
			this.showHideMsg = true;
			this.msgColor = "red";
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.showHideErrorMsg = "";
				this.msgColor = "";
				this.showHideMsg = false;
			}, 5000)
			return false;
		}
		if (_language == "" || _language == null || _language == undefined) {
			this.showHideErrorMsg = "Please select language *.";
			this.showHideMsg = true;
			this.msgColor = "red";
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.showHideErrorMsg = "";
				this.msgColor = "";
				this.showHideMsg = false;
			}, 5000)
			return false;
		}
		if (_category == "" || _category == null) {
			this.showHideErrorMsg = "Please select category *.";
			this.showHideMsg = true;
			this.msgColor = "red";
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.showHideErrorMsg = "";
				this.msgColor = "";
				this.showHideMsg = false;
			}, 5000)
			return false;
		}

		if (_bodyMessage == "" || _bodyMessage == null) {
			this.showHideErrorMsg = "Please enter something message in body *.";
			this.showHideMsg = true;
			this.msgColor = "red";
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.showHideErrorMsg = "";
				this.msgColor = "";
				this.showHideMsg = false;
			}, 5000)
			return false;
		}

		if (_buttonTitle == "quickReply" && this.listQuickButtonModel.length == 0) {
			this.showHideErrorMsg = "Please Enter Button Text For Quick Reply *.";
			this.showHideMsg = true;
			this.msgColor = "red";
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
			setTimeout(() => {
				this.showHideErrorMsg = "";
				this.msgColor = "";
				this.showHideMsg = false;
			}, 5000)
			return false;
		}
		this._bodyMessageJsonObj = {};
		let _resultBodyExample = _bodyMessage.includes("{{1}}");
		if (_resultBodyExample == false) {
			this._bodyMessageJsonObj = {
				"type": "BODY",
				"text": _bodyMessage
			}
		}
		else if (_resultBodyExample == true) {
			let _bodyBind = this.bodyformArray.value;
			let resBody = [];

			for (var i = 0; i < _bodyBind.length; i++) {
				if(_bodyBind[i].name==null || _bodyBind[i].name ==""){
					this._snackBar.open("Enter Add Body Variable Value in Body", 'close', {
						duration: 5000,
					});
					return;
				}
				resBody.push(_bodyBind[i].name);
			}
			this._bodyMessageJsonObj = {
				"type": "BODY",
				"text": _bodyMessage,
				"example": { "body_text": [resBody] }
			}
		}


		let _buttonQuickList;
		this._quickActionReplyObject = {};

		if (tempType == "forCreate") {
			_buttonQuickList = this.listQuickButtonModel;
			if (_buttonTitle == "quickReply" && this.listQuickButtonModel.length > 0) {
				this._quickActionReplyObject = {
					"type": "BUTTONS",
					"buttons": _buttonQuickList
				}
			}
		}

		else if (tempType == "forModify") {
			// debugger;  
			this._listQuick=[];
			if (_buttonTitle == "quickReply" && this.listQuickButtonModel.length > 0 ) {

				if (_buttonTitle == "quickReply" && this.listQuickButtonModel.length > 0 && this.listQuickButtonModel1.length==0 && this.listQuickButtonModel2.length==0 && this.listQuickButtonModel3.length==0) {
					for (let i = 0; i < this.itemEdit['buttons'].length; i++) {
						this._listQuick.push(...this.itemEdit['buttons']);
						_buttonQuickList=  this._listQuick ;
					}
					this._quickActionReplyObject = {
						"type": "BUTTONS",
						"buttons": _buttonQuickList
					}
				}
				else{

					_buttonQuickList = this.listQuickButtonModel;
					if (_buttonTitle == "quickReply" && this.listQuickButtonModel.length > 0) {
						this._quickActionReplyObject = {
							"type": "BUTTONS",
							"buttons": _buttonQuickList
						}
					}
				}

			}
		}

		if (_buttonTitle == "callToAction") {
			//  debugger;
			let _CallPhoneModel = createdForm.controls.CallPhoneModel.value;
			let _btnActionCallModel = createdForm.controls.btnActionCallModel.value;
			let _visitWebsiteModel = createdForm.controls.visitWebsiteModel.value;
			let _btnActionVisitModel = createdForm.controls.btnActionVisitModel.value;
			let _stdCodeModel = createdForm.controls.stdCodeModel.value;
			let _btnActionphoneModel = createdForm.controls.btnActionphoneModel.value;
			let _btnActionUrlModel = createdForm.controls.btnActionUrlModel.value;

			let _btnActionWebsiteModel = createdForm.controls.btnActionWebsiteModel.value;

			let _rowPhone = this.ShowHide_For_CallPhone;
			let _rowVisit = this.ShowHide_For_Visit_Website;
			let _webTitle;
			_webTitle = createdForm.controls.btnActionWebsiteModel.value;
			let _resultWebExample = _webTitle.includes("{{1}}");
			let _varValueWev = "";
			_varValueWev = this.createdForm.controls.webAddVarValue.value;

			if(_rowPhone == false && _rowVisit == false)
			{
				this._snackBar.open("Please Click Add for (call To action) in Buttons and enter field.", 'close', {
					duration: 4000,
				});
				return;
			}

			if (_rowPhone == true && _rowVisit == true) {
				// start
				if(_btnActionCallModel=="" || _btnActionCallModel==undefined){
					this._snackBar.open("Please enter button text in phone_number inside Call To Action Button", 'close', {
						duration: 5000,
					});
					return;
				}
				if(_btnActionphoneModel.length < 10 && _stdCodeModel=="+91"){
					this._snackBar.open("Please enter 10 digit phone number", 'close', {
						duration: 4000,
					});
					return;
				}
				if(_btnActionVisitModel=="" || _btnActionVisitModel==undefined){
					this._snackBar.open("Please enter button text in URL inside Call To Action Button", 'close', {
						duration: 5000,
					});
					return;
				}
				if(_btnActionWebsiteModel=="" || _btnActionWebsiteModel==undefined){
					this._snackBar.open("Please enter website in URL inside Call To Action Button", 'close', {
						duration: 5000,
					});
					return;
				}


				if (_resultWebExample == false) {
					this._quickActionReplyObject = {
						"type": "BUTTONS",
						"buttons": [
							{
								"type": _CallPhoneModel,
								"text": _btnActionCallModel,
								"phone_number": _stdCodeModel + _btnActionphoneModel
							},
							{
								"type": _visitWebsiteModel,
								"text": _btnActionVisitModel,
								"url": _btnActionWebsiteModel
							}
						]
					}
				}
				else if (_resultWebExample == true) {
					this._quickActionReplyObject = {
						"type": "BUTTONS",
						"buttons": [
							{
								"type": _CallPhoneModel,
								"text": _btnActionCallModel,
								"phone_number": _stdCodeModel + _btnActionphoneModel
							},
							{
								"type": _visitWebsiteModel,
								"text": _btnActionVisitModel,
								"url": _btnActionWebsiteModel,
								"example": [_varValueWev]
							}
						]
					}
				}
				//end
			}
			if (_rowPhone == true && _rowVisit == false) {
				if(_btnActionCallModel=="" || _btnActionCallModel==undefined){
					this._snackBar.open("Please enter button text in phone number inside Call To Action Button", 'close', {
						duration: 5000,
					});
					return;
				}
				if(_btnActionphoneModel.length < 10 && _stdCodeModel=="+91"){
					this._snackBar.open("Please enter 10 digit phone number", 'close', {
						duration: 4000,
					});
					return;
				}
				this._quickActionReplyObject = {
					"type": "BUTTONS",
					"buttons": [
						{
							"type": _CallPhoneModel,
							"text": _btnActionCallModel,
							"phone_number": _stdCodeModel + _btnActionphoneModel
						}
					]
				}

			}
			if (_rowPhone == false && _rowVisit == true) {
				if(_btnActionVisitModel=="" || _btnActionVisitModel==undefined){
					this._snackBar.open("Please enter button text in URL inside Call To Action Button", 'close', {
						duration: 5000,
					});
					return;
				}
				if(_btnActionWebsiteModel=="" || _btnActionWebsiteModel==undefined){
					this._snackBar.open("Please enter website in URL inside Call To Action Button", 'close', {
						duration: 5000,
					});
					return;
				}
				if (_resultWebExample == false) {
					this._quickActionReplyObject = {
						"type": "BUTTONS",
						"buttons": [
							{
								"type": _visitWebsiteModel,
								"text": _btnActionVisitModel,
								"url": _btnActionWebsiteModel
							}
						]
					}
				}
				else if (_resultWebExample == true) {
					this._quickActionReplyObject = {
						"type": "BUTTONS",
						"buttons": [
							{
								"type": _visitWebsiteModel,
								"text": _btnActionVisitModel,
								"url": _btnActionWebsiteModel,
								"example": [_varValueWev]
							}
						]
					}
				}
			}

		}

		let _mergeJson = [];
		let _footerJson = {};

		if ((Object.keys(this._headerJsonObj).length == 0) && (Object.keys(this._quickActionReplyObject).length == 0)) {
			if (_footerTitle == "") {
				_mergeJson = [
					this._bodyMessageJsonObj
				];
			}
			else {
				_mergeJson = [
					this._bodyMessageJsonObj,
					{
						"type": "FOOTER",
						"text": _footerTitle
					},
				];
			}


		}
		if ((Object.keys(this._headerJsonObj).length != 0) && (Object.keys(this._quickActionReplyObject).length == 0)) {

			if (_footerTitle == "") {
				_mergeJson = [
					this._headerJsonObj,
					this._bodyMessageJsonObj
				];
			}
			else {

				_mergeJson = [
					this._headerJsonObj,
					this._bodyMessageJsonObj,
					{
						"type": "FOOTER",
						"text": _footerTitle
					},

				];
			}
		}
		if ((Object.keys(this._headerJsonObj).length == 0) && (Object.keys(this._quickActionReplyObject).length != 0)) {
			if (_footerTitle == "") {
				_mergeJson = [
					this._bodyMessageJsonObj,
					this._quickActionReplyObject
				];
			}
			else {
				_mergeJson = [
					this._bodyMessageJsonObj,
					{
						"type": "FOOTER",
						"text": _footerTitle
					},
					this._quickActionReplyObject

				];
			}
		}
		if ((Object.keys(this._headerJsonObj).length != 0) && (Object.keys(this._quickActionReplyObject).length != 0)) {
			if (_footerTitle == "") {
				_mergeJson = [
					this._headerJsonObj,
					this._bodyMessageJsonObj,
					this._quickActionReplyObject
				];
			}
			else {
				_mergeJson = [
					this._headerJsonObj,
					this._bodyMessageJsonObj,
					{
						"type": "FOOTER",
						"text": _footerTitle
					},
					this._quickActionReplyObject

				];
			}
		}


		let _modelObject = {};
		let _modelObject2 = {};
		if (tempType == "forCreate") {
			if (_checkHeaderRadioValue == "DOCUMENT") {
				_modelObject =
				{
					// "bearerToken": this.bearer_token,
					"wa_number": this.wa_number.replace(/\+/g, ""),
					"template_name": _name,
					"language_code": _language,
					"category": _category,
					"components": _mergeJson,
					"document_type": 'PDF'
				}
			}
			else {
				_modelObject =
				{
					// "bearerToken": this.bearer_token,
					"wa_number": this.wa_number.replace(/\+/g, ""),
					"template_name": _name,
					"language_code": _language,
					"category": _category,
					"components": _mergeJson
				}
			}
			this.currentLoader = true;						
			this.API.postCreateMeaasgeTemplate(_modelObject, this.yourBearerToken,this.api_end_point).subscribe((response: any) => {
				if (response.status == "failure") {
					this._snackBar.open(response.message, 'close', {
						duration: 6000,
					});
					this.currentLoader = false;
					return;
				}
				else if (response.status == "success") {

					this._snackBar.open(response.message, 'close', {
						duration: 3000,
					});
					setTimeout(() => {
						this.currentLoader = false;
						this.onPassToList.emit("GoToTemplateList");
					}, 3000)
				}

			});
		}
		else {
			if (_checkHeaderRadioValue == "DOCUMENT") {
				_modelObject2 =
				{
					"wa_number": this.wa_number.replace(/\+/g, ""),
					"template_name": _name,
					"template_id": this.itemEdit['id'],
					"status": "REJECTED",
					"language_code": _language,
					"category": _category,
					"components": _mergeJson,
					"document_type": 'PDF'
				}
			}
			else {
				_modelObject2 =
				{
					"wa_number": this.wa_number.replace(/\+/g, ""),
					"template_name": _name,
					"template_id": this.itemEdit['id'],
					"status": "REJECTED",
					"language_code": _language,
					"category": _category,
					"components": _mergeJson
				}
			}

			//API start for edit
			this.currentLoader = true;
			this.API.postModifyMeaasgeTemplate(_modelObject2, this.yourBearerToken,this.api_end_point).subscribe((response: any) => {
				if (response.status == "failure") {
					this._snackBar.open(response.message, 'close', {
						duration: 6000,
					});
					this.currentLoader = false;
					return;
				}
				else if (response.status == "success") {

					this._snackBar.open(response.message, 'close', {
						duration: 3000,
					});

					setTimeout(() => {
						this.currentLoader = false;
						this.onPassToList.emit("GoToTemplateList");
					}, 3000)

				}

			});
			//API end for edit
		}

	}

	countKeyUPTemplateName(boxInput: HTMLInputElement) {
		this.nameTemplateCount = boxInput.value.length;

	}
	countKeyUPHeader(boxInput: HTMLInputElement, event) {
		this.headerCount = boxInput.value.length;
		var valueStr: any = boxInput.value;
		this._headerPreview = this.createdForm.controls.headerTitle.value;
		if (boxInput.value.length == 0) {
			this.isShowHeaderVariableText = false;
		}
		const indexStart = boxInput.selectionStart;

		if (event.keyCode == 37 || event.keyCode == 39) {
			return;
		}

		if ((boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart] == '{') || (boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart - 2] == '{')) {
			let finalString = valueStr.substring(0, indexStart - 1) + valueStr.substring(indexStart, valueStr.length);
			this._headerAddVariable = finalString;
		}
		let text = valueStr;
		let result = text.includes("{{1}}");
		if (result == false) {
			this.isShowHeaderVariableText = false;
			this._headerAddVarValue = "";

		}
		else {
			this.isShowHeaderVariableText = true;
			this._headerAddVarValue = "";
		}
		this._headerPreview1 = this._headerPreview;//vk

	}
	pasteHdrText(boxInput: HTMLInputElement, event: ClipboardEvent) {
		var valueStr: any = boxInput.value;
		const indexStart = boxInput.selectionStart;
		if ((boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart] == '{') || (boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart - 2] == '{')) {
			let finalString = valueStr.substring(0, indexStart - 1) + valueStr.substring(indexStart, valueStr.length);
			this._headerAddVariable = finalString;
		}
		let text = valueStr;
		let result = text.includes("{{1}}");
		let _pastedText = event.clipboardData.getData('text');
		if (result == true && _pastedText == "{{1}}") {
			event.preventDefault();
		}

		let pattern = /\s*\{{.*?\}}\s*/g;
		let _checkBracket1 = pattern.test(_pastedText);
		if (_checkBracket1 == true) {
			event.preventDefault();
		}
	}

	countKeyUPFooter(boxInput: HTMLInputElement) {
		this.footerCount = boxInput.value.length;
		this._footerPreview = this.createdForm.controls.footerTitle.value;
	}

	countKeyUPBody(boxInput: HTMLInputElement, event) {
		this.bodyCount = boxInput.value.length;
		var valueStr: any = boxInput.value;
		this._bodyMsgPreview = this.createdForm.controls.bodyMessage.value;
		document.getElementById('resultBodyTemplate1').innerHTML = (this._bodyMsgPreview).replace(new RegExp("\n", "g"), "<br>");;

		document.getElementById('resultBodyTemplate1').style.display = "block";
		document.getElementById('resultBodyTemplate2').style.display = "none";
		this.isShowEyeOn = true;
		this.isShowEyeOff = false;

		if (this._bodyMsgPreview.length == 0) {
			this.counterForBdy == 1;
			this.bodyformArray = new FormArray([]);
			this.bodyFirstTimeAdd = true;
		}
		const indexStart = boxInput.selectionStart;
		if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
			return;
		}
		// if ((boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart] == '{') || (boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart - 2] == '{')){
		//   event.preventDefault();
		//  let finalString = valueStr.substring(0, indexStart - 1) + valueStr.substring(indexStart, valueStr.length);
		//  this._bodyAddVariable = finalString
		//     }


	}
	_pasteTxtData:any;
	pasteBody(event: ClipboardEvent) {

		let _pastedText = event.clipboardData.getData('text');
		// let pattern = /\s*\{{.*?\}}\s*/g;
		// let _checkBracket1 = pattern.test(_pastedText);
		//   if(_checkBracket1==true){
		//    // event.preventDefault();
		//   }
		// if (/\s*\{{\d+}}\s*/g.test(_pastedText) == false) {
		//   this._snackBar.open("Only number 1 to 25 allow inside double curly bracess. eg. {{ }}", 'close', {
		//     duration: 5000,
		//   });
		//   event.preventDefault();
		//   return;
		// }
		this._pasteTxtData=_pastedText;

		this._bodyMsgPreview = _pastedText;
		document.getElementById('resultBodyTemplate1').innerHTML =_pastedText.replace(new RegExp("\n", "g"), "<br>");;
		document.getElementById('resultBodyTemplate1').style.display = "block";
		document.getElementById('resultBodyTemplate2').style.display = "none";
		this.isShowEyeOn = true;
		this.isShowEyeOff = false;



		var _wordCount;
		if(_pastedText.match(/\s*\{{\d+}}\s*/g)){
			_wordCount = _pastedText.match(/\s*\{{\d+}}\s*/g).length;
		}
		var _notMatch = _pastedText.match(/\s*\{{([a-zA-Z]*)}}\s*/g); //[a-zA-Z]+[a-zA-Z\d];
		var _notMatchAlphabetWithNum = _pastedText.match(/\s*\{{([a-zA-Z]+[a-zA-Z\d]*)}}\s*/g);
		var _notMatchNumWithAlpbhabet = _pastedText.match(/\s*\{{([0-9]+[a-zA-Z]+)}}\s*/g);

		if (_notMatch != null) {
			if (_notMatch.length > 0) {
				this._snackBar.open("You can't use alphabet inside double curly braces.Please try again..!!!", 'close', {
					duration: 7000,
				});
				event.preventDefault();
				return;
			}
		}
		if (_notMatchAlphabetWithNum != null) {
			if (_notMatchAlphabetWithNum.length > 0) {
				this._snackBar.open("You can't use alphabet with number inside double curly braces.Please try again..!!!", 'close', {
					duration: 7000,
				});
				event.preventDefault();
				return;
			}
		}
		if (_notMatchNumWithAlpbhabet != null) {
			if (_notMatchNumWithAlpbhabet.length > 0) {
				this._snackBar.open("You can't use number with alphabet inside double curly braces.Please try again..!!!", 'close', {
					duration: 7000,
				});
				event.preventDefault();
				return;
			}
		}
		let _pasteValue = [];
		_pasteValue = _pastedText.match(/\s*\{{\d+}}\s*/g);
		if(_pasteValue){
			for (let i = 0; i < _pasteValue.length; i++) {
				if (Number(_pasteValue[i].replace(/[{}]/g, "")) < 26) {

				}
				else {
					this._snackBar.open("You can't use number more than 25 inside double curly braces.", 'close', {
						duration: 7000,
					});
					event.preventDefault();
					return;
				}
			}
		}

		if(_wordCount ==0 || _wordCount==undefined ){
			this.bodyFirstTimeAdd = true;
		}
		else{
			this.bodyFirstTimeAdd = false;
		}
		if (_wordCount < 26) {
			

			// const newGroup = new FormGroup({});
			for (let i = 1; i <= _wordCount; i++) {
				// this.displayedFields.forEach(x => {
				//   newGroup.addControl(x, new FormControl())
				// })
				// this.bodyformArray.push(newGroup);

				this.bodyAddVariable(i, "pasteTime");

			}

		}
		this.counterForBdy = this.bodyformArray.value.length;


	}
	countKeyUPPhone(boxInput: HTMLInputElement) {
		this.phoneCount = boxInput.value.length;

	}
	countKeyUPBtnTextCall(boxInput: HTMLInputElement) {
		this.btnTextCountCall = boxInput.value.length;
		this.phoneButtonValueForPreview = boxInput.value;
	}
	countKeyUPBtnTextVisit(boxInput: HTMLInputElement) {
		this.btnTextCountVisit = boxInput.value.length;
		this.visitWebValueForPreview = boxInput.value;
	}
	// countKeyUPBtnText(boxInput : HTMLInputElement){
	//   this.btnTextCount= boxInput.value.length ;
	// }
	countKeyUPBtnText2(boxInput: HTMLInputElement) {
		this.btnTextCount2 = boxInput.value.length;
	}
	countKeyUPBtnTextQuick(boxInput: HTMLInputElement, i) {
		if (i == 1) {
			this.btnTextCountQuick1 = boxInput.value.length;
		}
		if (i == 2) {
			this.btnTextCountQuick2 = boxInput.value.length;
		}
		if (i == 3) {
			this.btnTextCountQuick3 = boxInput.value.length;
		}

	}
	countKeyUPWebsite(boxInput: HTMLInputElement, event) {
		var valueStr: any = boxInput.value;
		const indexStart = boxInput.selectionStart;
		if (event.keyCode == 37 || event.keyCode == 39) {
			return;
		}
		if ((boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart] == '{') || (boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart - 2] == '{')) {
			let finalString = valueStr.substring(0, indexStart - 1) + valueStr.substring(indexStart, valueStr.length);
			this._webAddVariable = finalString;
		}
		let text = valueStr;
		let result = text.includes("/{{1}}");
		if (result == false) {
			this.isShowWebVariableText = false;
		}
		else {
			this.isShowWebVariableText = true;
		}
	}
	onChangeButton(val: any) {
		this.ShowHide_For_CallPhone = false;
		this.ShowHide_For_Visit_Website = false;
		this.showHideButtonDiv = false;
		this.showHideButtonDiv2 = false;
		this.isShowWebVariableText = false;

		this.listQuickButtonModel = [];
		this.rowRecordQuick = [
			{
				first1: '',
			}
		];
		if (val == "callToAction") {
			this.showHideButtonDiv = true;
			//  if(this.createdForm.controls.actionTypeControl.value=="callPhone"){
			//   this.ShowHide_For_CallPhone=true;
			// }
		}
		else if (val == "quickReply") {
			this.createdForm.controls.btnActionCallModel.reset();
			this.createdForm.controls.btnActionVisitModel.reset();
			this.createdForm.controls.btnActionphoneModel.reset();
			this.createdForm.controls.btnActionUrlModel.reset();
			this.createdForm.controls.btnActionWebsiteModel.reset();
			this.showHideButtonDiv2 = true;
		}
	}


	// changeActionBtn(value:any,index){
	//   this.ShowHide_For_CallPhone=false;
	//   this.ShowHide_For_Visit_Website=false;
	//   this.setIndexNo=index;
	//   this.actionTypeDdl=value;
	//   if(value=="callPhone"){
	//     this.ShowHide_For_CallPhone=true;
	//   }
	//   else if(value=="visiteWebsite"){
	//     this.ShowHide_For_Visit_Website=true;
	//   }
	// }

	headerAddVariable() {
		let _val = this.createdForm.controls.headerTitle.value;
		if (_val.indexOf('{{ }}') > -1 || _val.indexOf('{{}}') > -1 || _val.indexOf('{{') > -1) {
			this._snackBar.open("You Can't Add More Than One Variable", 'close', {
				duration: 5000,
			});
			return;
		}
		else {
			this._headerAddVariable = this.createdForm.controls.headerTitle.value + "{{1}}";
			this._headerPreview = this._headerAddVariable;
			this._headerPreview1 = this._headerPreview;//vk
			this.isShowHeaderVariableText = true;
		}
	}

	delHeaderTB() {
		this.isShowHeaderVariableText = false;
		this._headerAddVarValue = "";
		this._headerAddVariable = this._headerAddVariable.replace("{{1}}", "");
		// this._headerAddVarValue= this._headerAddVariable;
		this._headerPreview = this._headerAddVariable;

		this._headerPreview1 = this._headerPreview;//vk
	}
	delWebTB() {
		this.isShowWebVariableText = false;
		this._webAddVarValue = "";
		this._webAddVariable = this._webAddVariable.replace("/{{1}}", "");
	}

	counterForBdy: any = 1;
	bodyFirstTimeAdd: boolean = true;
	indexNumberOfBody: any;
	bodyAddVariable(indexNum, nopasteType: any) {
		this.indexNumberOfBody = indexNum;
		if (indexNum == 1) {
			this.bodyFirstTimeAdd = false;
		}

		let _val = this.createdForm.controls.bodyMessage.value;
		if (_val == "") {
			this.counterForBdy = 1;
		}
		var _leng = (_val.match(/{{/g) || _val.match(/}}/g) || _val.match(/{{}}/g) || []).length;
		// if (_leng == 25) {
		if (indexNum == 26 || _leng == 25) {
			this._snackBar.open("You Can't Add More Than Twenty Five Variable", 'close', {
				duration: 5000,
			});
			return;
		}
		//this._bodyAddVariable = this.createdForm.controls.bodyMessage.value + "{{" + this.counterForBdy + "}}";
		if (nopasteType == "pasteTime") {
			this._bodyAddVariable = this.createdForm.controls.bodyMessage.value;
		}
		else {
			this._bodyAddVariable = this.createdForm.controls.bodyMessage.value + "{{" + indexNum + "}}";
		}
		this._bodyMsgPreview = this._bodyAddVariable;
		document.getElementById('resultBodyTemplate1').innerHTML = this._bodyAddVariable.replace(new RegExp("\n", "g"), "<br>");;
		document.getElementById('resultBodyTemplate1').style.display = "block";
		document.getElementById('resultBodyTemplate2').style.display = "none";
		this.isShowEyeOn = true;
		this.isShowEyeOff = false;

		const newGroup = new FormGroup({});
		this.displayedFields.forEach(x => {
			newGroup.addControl(x, new FormControl())
		})
		this.bodyformArray.push(newGroup)

		// console.log(this.bodyformArray.value);
		this.counterForBdy = this.bodyformArray.value.length;
		this.counterForBdy++;
		return this.counterForBdy;
	}

	_bodyAddVariableNew1: any = "";

	deleteBodyRow(index: number) {
		this.counterForBdy = this.bodyformArray.value.length;
		for (let i = 0; i < this.bodyformArray.value.length; i++) {
			if (i >= index) {
				this._bodyAddVariable = (this._bodyAddVariable).replace(`{{${i + 1}}}`, `{{${i}}}`);
			}
		}
		this.bodyformArray.removeAt(index);


		//       var regex1 =/\s*\{{.*?\}}\s*/g;// /\d+/g;
		//       var _string = this._bodyAddVariable;
		//       var matches1 = _string.match(regex1);     
		//        if(matches1){
		//       for(let i=0;i<matches1.length;i++){
		//         let _str1= this._bodyAddVariable.replace(matches1[i], `{{${i+1}}}`);
		//         this._bodyAddVariableNew1=_str1;      
		//       }
		//       this._bodyAddVariable=  this._bodyAddVariableNew1;
		//     }


		if (this.bodyformArray.value.length == 0) {
			this.counterForBdy = 1;
			// this._bodyAddVariable= _string.replace(/\s*\{{[0-9]?\}}\s*/g,'');
			this.bodyFirstTimeAdd = true;
		}
		this._bodyAddVariable = (this._bodyAddVariable).replace(`{{${index}}}`, ' ');
		this._bodyMsgPreview = this._bodyAddVariable;

		document.getElementById('resultBodyTemplate1').innerHTML = this._bodyAddVariable.replace(new RegExp("\n", "g"), "<br>");;
		document.getElementById('resultBodyTemplate1').style.display = "block";
		document.getElementById('resultBodyTemplate2').style.display = "none";
		this.isShowEyeOn = true;
		this.isShowEyeOff = false;

	}


	footerAddVariable() {
		let _val = this.createdForm.controls.footerTitle.value;
		if (_val.indexOf('{{ }}') > -1 || _val.indexOf('{{}}') > -1 || _val.indexOf('{{') > -1) {
			this._snackBar.open("You Can't Add More Than One Variable", 'close', {
				duration: 5000,
			});
			return;
		}
		else {
			this._footerAddVariable = this.createdForm.controls.footerTitle.value + "{{1}}";
			this._footerPreview = this._footerAddVariable;
		}
	}

	addClick() {
		this.ShowHide_For_CallPhone = true;
		this.ShowHide_For_Visit_Website = true;
		this.isShowWebVariableText = false;

		this._webAddVarValue = "";
		this._webAddVariable = "";

	}
	deleteRow(x) {
		//  this.rowRecord.splice(x, 1 );

		if (x == 0) {
			this.ShowHide_For_CallPhone = false;
			this.ShowHide_For_Visit_Website = true;

		}
		else if (x == 1) {
			this.ShowHide_For_CallPhone = true;
			this.ShowHide_For_Visit_Website = false;

			this._webAddVarValue = "";
			this._webAddVariable = "";
			this.isShowWebVariableText = false;
		}
	}
	addClickQuick() {
		let _lenth = this.rowRecordQuick.length;
		// console.log(this.listQuickButtonModel);
		// console.log(this.listQuickButtonModel1);
		// console.log(this.listQuickButtonModel2);
		// console.log(this.listQuickButtonModel3);
		if (this.listQuickButtonModel.length == 1) {
			this.listQuickButtonModel2 = [];
			this.listQuickButtonModel3 = [];
		}
		if (this.listQuickButtonModel.length == 2) {
			this.listQuickButtonModel3 = [];
		}
		if (_lenth >= 3) {
			this._snackBar.open("You Can't Add  More Than Three Record", 'close', {
				duration: 5000,
			});
			return;
		}
		else {
			const obj = {
				first1: ''
			}
			this.rowRecordQuick.push(obj);
		}
	}
	deleteQuickRow(x) {
		this.rowRecordQuick.splice(x, 1);
		this.listQuickButtonModel.splice(x, 1);

		if (x == 0) {
			this.listQuickButtonModel1.splice(x, 1);
		}
		if (x == 1) {
			this.listQuickButtonModel2 = [];
			// this.listQuickButtonModel2.splice(x, 1);

		}

		if (x == 2) {
			this.listQuickButtonModel3 = [];
			//  this.listQuickButtonModel3.splice(x, 1);

		}


	}
	radioChangeHeader(val: any) {
		this.radioEditHdrVal = val;
		this.showHideHeaderText = false;
		this.showHideHeaderImage = false;
		this.showHideHeaderVideo = false;
		this.showHideHeaderDocument = false;
		this.headerRadioChangeValue = val;
		this.isShowHeaderVariableText = false;
		this._headerAddVarValue = "";
		this.hdrImageUrl = "";
		this.filenameContainer = "";
		this.filenameContainerVideo = "";
		this.filenameDocumentContainer = "";
		this._documentUrl = '';
		this.forModifyVideoUrl = false;

		this._headerPreview1 = "";
		this._headerPreview2 = "";

		if (this.hdrImageUrl == "") {
			this.hdrImageUrl = "";// "https://youearn.in/files/attachments/228b873a-552d-4ffc-9b38-d43116d05a44.jpeg";
		}
		if (this.hdrVideoUrl == "") {
			this.hdrVideoUrl = "";//"https://youtu.be/lt7Hgb95w0A?list=PLdslA-CIica7ODu-IgQMdorq77uIKvdqx";
		}
		if (val == "") {
			this._headerPreview = "";

		}
		if (val == "TEXT") {
			this.showHideHeaderText = true;
			this._headerAddVariable = "";
			this._headerPreview = "";

		}
		else if (val == "IMAGE") {
			this.showHideHeaderImage = true;
			this._headerPreview = "";
			this._headerPreviewImageUrl = this.hdrImageUrl;
			this.createdForm.controls.headerTitle.reset();
		}
		else if (val == "VIDEO") {
			this.showHideHeaderVideo = true;
			this._headerPreview = "";
			this.hdrImageUrl = "";
			this._headerPreviewVideoUrl = this.hdrVideoUrl;
		}

		else if (val == "DOCUMENT") {
			this.showHideHeaderDocument = true;
			this._headerPreview = "";
		}

	}

	listQuickButtonModel1: any = [];
	listQuickButtonModel2: any = [];
	listQuickButtonModel3: any = [];
	chaneButtonEvent(boxBtnTextQuick, index, v) {
		//debugger;
		this.listQuickButtonModel = [];
		if (index == 0) {
			this.listQuickButtonModel1 = [];
			let _btnQuickVal1 = boxBtnTextQuick.value;
			this.listQuickButtonModel1.push({
				"type": "QUICK_REPLY",
				"text": _btnQuickVal1
			});
		}
		if (index == 1) {
			this.listQuickButtonModel2 = [];
			let _btnQuickVal2 = boxBtnTextQuick.value;
			this.listQuickButtonModel2.push({
				"type": "QUICK_REPLY",
				"text": _btnQuickVal2
			});
		}
		if (index == 2) {
			this.listQuickButtonModel3 = [];
			let _btnQuickVal3 = boxBtnTextQuick.value;
			this.listQuickButtonModel3.push({
				"type": "QUICK_REPLY",
				"text": _btnQuickVal3
			});
		}

		// let _btnQuickVal = boxBtnTextQuick.value;
		// this.listQuickButtonModel.push({
		//   "type": "QUICK_REPLY",
		//   "text": _btnQuickVal
		// });
		this.listQuickButtonModel.push(...this.listQuickButtonModel1, ...this.listQuickButtonModel2, ...this.listQuickButtonModel3);
		// console.log(this.listQuickButtonModel);
		this.listQuickBtn = this.listQuickButtonModel;

	}
	removeSpecialChar(event) {
		var kcode = event.keyCode;
		if (kcode == 8 || kcode == 9 || kcode == 95 || (kcode > 47 && kcode < 58) || (kcode > 64 && kcode < 91) || (kcode > 96 && kcode < 123)) {
			return true;
		}
		else {
			return false;
		}
	}
	getLanguages() {
		this.API.getLanguagesForCreateTemplate().subscribe((res: any) => {
			if (res.status == "success") {
				this._languagesList = res.data;
			}
		})
	}
	getCategories() {
		this.API.getCategoriesForCreateTemplate().subscribe((res: any) => {
			if (res.status == "success") {
				this._categoryList = res.data;
			}
		})
	}


	websiteAddVariable() {
		let _val = this.createdForm.controls.btnActionWebsiteModel.value;
		if (_val.indexOf('{{ }}') > -1 || _val.indexOf('{{}}') > -1 || _val.indexOf('{{') > -1) {
			this._snackBar.open("You Can't Add More Than One Variable", 'close', {
				duration: 5000,
			});
			return;
		}
		else {
			this._webAddVariable = this.createdForm.controls.btnActionWebsiteModel.value + "/{{1}}";
			this._webAddVariable = this._webAddVariable.replace("//{{1}}", "/{{1}}")
			this.isShowWebVariableText = true;
		}
	}

	getImageFile(event: any, _files: FileList) {
		let fileInput = (<HTMLInputElement>document.getElementById("testfile1")).value;
		var idxDot = fileInput.lastIndexOf(".") + 1;
		var extFile = fileInput.substring(idxDot, fileInput.length).toLowerCase();
		if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {

			this.filenameContainer = fileInput.split('\\').pop();
			let reader;
			let input = event.target;
			if (input.files && input.files[0]) {
				reader = new FileReader();
				reader.onload = function (e) {
					(<HTMLInputElement>document.getElementById("previewIMg")).setAttribute('src', e.target.result);
				}
				reader.readAsDataURL(input.files[0]);
				this.hdrImageUrl = "";
			}

			let formData = new FormData();
			formData.append('image', event.target.files[0], event.target.files[0].name);
			this.API.createMsgTemplateUploadImage(formData,this.api_end_point).subscribe(
				(res: any) => {
					if (res.errors) {
						this._snackBar.open(res.errors, 'close', {
							duration: 5000,
						});
					}
					else {
						this.hdrImageUrl = res.url;
						this._headerPreviewImageUrl = this.hdrImageUrl;
					}
				}, (err) => {
					console.log(err)
				}
			)
		}
		else {
			this._snackBar.open("Only jpg/jpeg and png files are allowed!", 'close', {
				duration: 5000,
			});
			return;
		}

	}





	getVideoFile(event: any, _files: FileList) {

		this.forModifyVideoUrl = false;

		this._loadVideoFileDetails1 = event.target.files[0];
		let fileInput = (<HTMLInputElement>document.getElementById("testfile2")).value;
		var idxDot = fileInput.lastIndexOf(".") + 1;
		var extFile = fileInput.substring(idxDot, fileInput.length).toLowerCase();
		if (extFile == "mp4") {
			this.filenameContainerVideo = fileInput.split('\\').pop();
			let reader;
			let input = event.target;
			if (input.files && input.files[0]) {
				reader = new FileReader();
				reader.onload = function (e) {
					//  (<HTMLInputElement>document.getElementById("video1")).setAttribute('src', e.target.result);       
					(document.getElementById("vidSelectedId") as HTMLIFrameElement).src = URL.createObjectURL(event.target.files[0]);
				}
				reader.readAsDataURL(input.files[0]);
				this.hdrVideoUrl = "";
			}
			let formData = new FormData();
			formData.append('image', event.target.files[0], event.target.files[0].name);

			this.API.createMsgTemplateUploadVideo(formData,this.api_end_point).subscribe(
				(res: any) => {
					if (res.errors) {
						this._snackBar.open(res.errors, 'close', {
							duration: 5000,
						});
					}
					else {
						this.hdrVideoUrl = res.url;
						this._headerPreviewVideoUrl = this.hdrVideoUrl;
						setTimeout(() => {
							(document.getElementById("preVidId") as HTMLIFrameElement).src = URL.createObjectURL(this._loadVideoFileDetails1);
						}, 100)

					}

				}, (err) => {
					console.log(err)
				}
			)
		}
		else {
			this._snackBar.open("Only mp4 files are allowed!", 'close', {
				duration: 5000,
			});
			return;
		}
	}
	// getPreviewVideo(){  
	//   (document.getElementById("preVidId") as HTMLIFrameElement).src =  URL.createObjectURL(this._loadVideoFileDetails1);
	// }
	getDocumentFile(event: any) {
		let fileInput = (<HTMLInputElement>document.getElementById("testfile3")).value;
		var idxDot = fileInput.lastIndexOf(".") + 1;
		var extFile = fileInput.substring(idxDot, fileInput.length).toLowerCase();
		if (extFile == "pdf") {//|| extFile=="docx" || extFile=="doc"
			this.filenameDocumentContainer = fileInput.split('\\').pop();
			let reader;
			let input = event.target;
			if (input.files && input.files[0]) {
				reader = new FileReader();
				reader.onload = function (e) {
					// (<HTMLInputElement>document.getElementById("video1")).setAttribute('src', e.target.result);
				}
				reader.readAsDataURL(input.files[0]);
			}
			let formData = new FormData();
			formData.append('image', event.target.files[0], event.target.files[0].name);
			this.API.createMsgTemplateUploadDocument(formData,this.api_end_point).subscribe(
				(res: any) => {
					this._documentUrl = res.url;
				}, (err) => {
					console.log(err)
				}
			)
		}
		else {
			this._snackBar.open("Only pdf files are allowed!", 'close', {
				duration: 5000,
			});
			return;
		}
	}
	urlTypeChange(val: any) {
		if (val == "dynamic") {
			this.isShowForDynamic = true;
			this.isShowWebVariableText = false;
			this._webAddVarValue = "";
		}
		else {
			this.isShowForDynamic = false;
			this.isShowWebVariableText = false;
			this._webAddVarValue = "";
			this._webAddVariable = this._webAddVariable.replace("/{{1}}", "");
		}

	}
	pasteWebText(boxInput: HTMLInputElement, event: ClipboardEvent) {
		var valueStr: any = boxInput.value;
		const indexStart = boxInput.selectionStart;
		if ((boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart] == '{') || (boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart - 2] == '{')) {
			let finalString = valueStr.substring(0, indexStart - 1) + valueStr.substring(indexStart, valueStr.length);
			this._webAddVariable = finalString;
		}
		let text = valueStr;
		let result = text.includes("/{{1}}");
		let _pastedText = event.clipboardData.getData('text');
		if (result == true && _pastedText == "/{{1}}") {
			event.preventDefault();
		}

		let pattern = /\s*\{{.*?\}}\s*/g;
		let _checkBracket1 = pattern.test(_pastedText);
		if (_checkBracket1 == true) {
			event.preventDefault();
		}
	}


	eyeVisibilty(type: any) {

		if (type == 'on') {
			this.isShowEyeOn = false;
			this.isShowEyeOff = true;
			let _val = this._headerPreview1;
			this._headerPreview2 = _val.replace(/\s*\{{.*?\}}\s*/g, ' ' + this._headerAddVarValue + ' ');

			let _bodyVariableArrayData = [];
			_bodyVariableArrayData = this.bodyformArray.value;


			if (_bodyVariableArrayData.length > 0) {
				for (let i = 0; i < _bodyVariableArrayData.length; i++) {
					this._bodyMsgPreview = (this._bodyMsgPreview).replace(`{{${i + 1}}}`, ' ' + _bodyVariableArrayData[i].name + ' ');

					document.getElementById('resultBodyTemplate2').innerHTML = this._bodyMsgPreview.replace(new RegExp("\n", "g"), "<br>");
				}
			}
			else {
				document.getElementById('resultBodyTemplate2').innerHTML = this._bodyMsgPreview.replace(new RegExp("\n", "g"), "<br>");
			}
			document.getElementById('resultBodyTemplate1').style.display = "none";
			document.getElementById('resultBodyTemplate2').style.display = "block";

		}
		else if (type == 'off') {
			this.isShowEyeOn = true;
			this.isShowEyeOff = false;
			let _val2 = this._headerAddVariable;
			this._headerPreview1 = _val2;

			document.getElementById('resultBodyTemplate1').innerHTML = this._bodyAddVariable.replace(new RegExp("\n", "g"), "<br>");
			document.getElementById('resultBodyTemplate1').style.display = "block";
			document.getElementById('resultBodyTemplate2').style.display = "none";
		}
	}

	bodyKeyDown(boxInput: HTMLInputElement, event) {
		var start = boxInput.selectionStart,
			end = boxInput.selectionEnd,
			value = boxInput.value,
			key = event.keyCode;

		let a1 = boxInput.value[start - 1];
		let a2 = boxInput.value[start - 2];
		let a3 = boxInput.value[start - 3];
		let a4 = boxInput.value[start - 4];
		let a5 = boxInput.value[start - 5];

		let b1 = boxInput.value[start - 1];
		let b2 = boxInput.value[start - 2];
		let b3 = boxInput.value[start - 3];
		let b4 = boxInput.value[start - 4];

		let c1 = boxInput.value[start - 1];
		let c2 = boxInput.value[start - 2];
		let c3 = boxInput.value[start - 3];

		let f = boxInput.value[start];
		let f1 = boxInput.value[start + 1];
		let f2 = boxInput.value[start + 2];
		let f3 = boxInput.value[start + 3];
		let f4 = boxInput.value[start + 4];
		let f5 = boxInput.value[start + 5];


		if (key == 8 && a5 == '{' && a4 == '{' && Number(a3) && a2 == '}' && a1 == '}') {
			event.preventDefault();
			return;
		}
		else if ((key == 8 || (key == 46) || (key == 32)) && (a1 == '}' && a2 == '}')) {

			if(key==32){
				return true;
			}

			event.preventDefault();
			return;
		}
		else if ((key == 8 || (key == 46) || (key == 32)) && (b1 == '}' && Number(b2) && b3 == '{' && b4 == '{')) {
			event.preventDefault();
			return;
		}

		else if ((key == 8 || (key == 46) || (key == 32)) && (Number(c1) && c2 == '{' && c3 == '{')) {
			event.preventDefault();
			return;
		}
		// if ((key == 8 ||(key == 46)) &&  c1 == '{'  && c2 == '{') 
		// {
		//   event.preventDefault();
		// }

		else if ((key == 8 || (key == 46) || (key == 32)) && a1 == '{' && a2 == '{' && (Number(f)) && f1 == '}' && f2 == '}') {
			event.preventDefault();
			return;
		}

		else if ((key == 8 || (key == 46) || (key == 32)) && f == '{' && f1 == '{' && Number(f2) && f3 == '}' && f4 == '}') {
			if(key==8 ||key == 32 ){
				return true;
			}

			event.preventDefault();
			return;
		}
		else if ((key == 8 || (key == 46) || (key == 32)) && (f == '{') && Number(f1) && (f2 == '}' && f3 == '}')) {
			event.preventDefault();
			return;
		}

		else if ((key == 8 || (key == 46) || (key == 32)) && a3 == '{' && a4 == '{' && Number(a2) && a1 == '}') {
			event.preventDefault();
			return;
		}

		else if ((key == 8 || (key == 46) || (key == 32)) && a1 == '{' && a2 == '{' && Number(f) && f1 == '}' && f2 == '}') {
			event.preventDefault();
			return;
		}

		else if ((key == 8 || (key == 46) || (key == 32)) && a1 == '{' && f == '{' && Number(f1) && f2 == '}' && f3 == '}') {
			event.preventDefault();
			return;
		}
		else {
			const indexStart = boxInput.selectionStart;
			if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
				return;
			}
			var valueStr: any = boxInput.value;
			if ((boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart] == '{') || (boxInput.value[indexStart - 1] == '{' && boxInput.value[indexStart - 2] == '{')) {
				event.preventDefault();
				let finalString = valueStr.substring(0, indexStart - 1) + valueStr.substring(indexStart, valueStr.length);
				this._bodyAddVariable = finalString
			}
		}


	}
	onCategoryChange(value:any) {
		// console.log(value);
	}
	//  _arr:any=[];
	// vkChangeTB(index:any,c){
	// 	debugger;
	// 	let _val;
	// 		 _val=c.value;
	// 	this._arr.push({
	// 		"name":_val
	// 	})
	// 	var newGroup = new FormGroup({});

	// 	this.displayedFields.forEach(x => {
	// 		newGroup.addControl(x, new FormControl(_val))
	// 	  })
	// 	  this.bodyformArray.push(newGroup);
	// 	console.log(this.bodyformArray);
	// }

	changeBodyValTxtBox(index,ele,data){
		//debugger
		let _val=data.value;
		let a=this.bodyformArray.controls[index].value;
		console.log(a);

		// //  for(let i=0;i<this.bodyformArray.value.length;i++){
		// 	this.bodyformArray.value[index]=this.bodyformArray.controls[index].value;
		//  //}

		//     // let _wordCount;
		//     //  if(this._pasteTxtData.match(/\s*\{{\d+}}\s*/g)){
		//     //  _wordCount = this._pasteTxtData.match(/\s*\{{\d+}}\s*/g).length;}

		// 	// 	const newGroup = new FormGroup({});
		// 	// 	this.bodyformArray.push(new FormControl(_val));

		// 	console.log( this.bodyformArray);

	}
	// formatText(tag) {
	// 	let text = this.createdForm.controls.bodyMessage.value;
	// 	var selection1: any = window.getSelection();
	// 	let _txt;
	// 	if (tag == 'Not') {
	// 		//let boldText =selection1;//.replace(/<b[^>]*>/g,'<span>').replace(/<\/b>/g,'</span>');
	// 	// _txt = text.replace(selection1,boldText);
	// 	}
	// 	else {
	// 		let boldText = "<" + tag + ">" + selection1 + "</" + tag + ">";
	// 		 _txt = text.replace(selection1, boldText);	

	// 		//document.getElementById("tAreaID").innerHTML=this._bodyAddVariable;
	// 		//document.getElementById("tAreaID").innerHTML = _txt;
	// 	}
	// 	this._bodyAddVariable = _txt;
	// 	this._bodyMsgPreview = _txt;
	// 	document.getElementById('resultBodyTemplate1').innerHTML = this._bodyAddVariable.replace(new RegExp("\n", "g"), "<br>");
	// 	document.getElementById('resultBodyTemplate2').innerHTML = this._bodyMsgPreview.replace(new RegExp("\n", "g"), "<br>");

	// }
	  formatText(tag) {		
		var textarea1 = (<HTMLInputElement>document.getElementById("tAreaID"));
		var len = textarea1.value.length;
		var start = textarea1.selectionStart;
		var end = textarea1.selectionEnd;
		var sel = textarea1.value.substring(start, end);
		var replace1;
		var defaultVal = sel;          
		var bonly = defaultVal.match(/<b>(.*?)<\/b>/);
		var ionly = defaultVal.match(/<i>(.*?)<\/i>/);
		var uonly = defaultVal.match(/<u>(.*?)<\/u>/);
		if (bonly && (bonly.length > 1)) {
		//alert(bonly[1]); 
		var html1=defaultVal;
		html1 = html1.replace(/<b>/g, "");
		html1 = html1.replace(/<\/b>/g, "");
		replace1= html1;
		}
		else if (ionly && (ionly.length > 1)) {
			var html1=defaultVal;
			html1 = html1.replace(/<i>/g, "");
			html1 = html1.replace(/<\/i>/g, "");
			replace1= html1;
			}
			else if (uonly && (uonly.length > 1)) {
				var html1=defaultVal;
				html1 = html1.replace(/<u>/g, "");
				html1 = html1.replace(/<\/u>/g, "");
				replace1= html1;
				}
	  else{
			 replace1 = "<" + tag + ">"  + sel +  "</" + tag + ">";
	      }
	  textarea1.value = textarea1.value.substring(0, start) + replace1 + textarea1.value.substring(end, len);
        var formatted = textarea1.value;
			this._bodyAddVariable = formatted;
		this._bodyMsgPreview = formatted;
		document.getElementById('resultBodyTemplate1').innerHTML = this._bodyAddVariable.replace(new RegExp("\n", "g"), "<br>");
		document.getElementById('resultBodyTemplate2').innerHTML = this._bodyMsgPreview.replace(new RegExp("\n", "g"), "<br>");
	 }
}



