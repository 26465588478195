
<div>
    <mat-card class="mt-2">
        <div class="row">
<div class="col-md-12">

    <mat-radio-group class="rgRadio" (change)="profileRadioChange($event.value)">
        <mat-radio-button class="mrd" value="ATTENDANCE">ATTENDANCE</mat-radio-button>
        <mat-radio-button class="mrd" value="LEAVE">LEAVE</mat-radio-button>
        <mat-radio-button class="mrd" value="RESIGNATION">RESIGNATION</mat-radio-button>
      </mat-radio-group>


<!-- 
<button class="profilebtn" (click)="attendaceClick()">Mark Attendance</button>
</div>
<div class="col-md-2">
<button  class="profilebtn" (click)="leaveClick()">Apply for leave</button>
</div>
<div class="col-md-2">
<button  class="profilebtn" (click)="resignationClick()">Resignation </button>-->
<div *ngIf="showHideAttendance">
    <app-profile-attendance-dialog></app-profile-attendance-dialog>
</div>
<div *ngIf="showHideLeave">
    <app-profile-leave-dialog></app-profile-leave-dialog>
</div>
<div *ngIf="showHideResignation">
    <app-profile-resignation-dialog></app-profile-resignation-dialog>
</div>


</div> 
        </div>
    </mat-card>
</div>
