import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { MyserviceService } from 'src/app/myservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-call-plugin',
  templateUrl: './contact-call-plugin.component.html',
  styleUrls: ['./contact-call-plugin.component.scss']
})
export class ContactCallPluginComponent implements OnInit {
  displayedColumns = ['Name'];
  dataSource: any = new MatTableDataSource();
  selectedRow;
  _nameUser: any = "";
  _psType: any = "";
  showHideWhatsapp: boolean = false;
  showHidePhone: boolean = false;
  showHideText: boolean = false;
  selectedButton: any = "";
  _ddlListExtraOption: any = [];
  user: any = this.myservice.get_loggedin_user();
  stateSettings: any = this.myservice.get_stage_settings();
  _paraMessage: any="";
  _rowExtraOptionData: any = [];
  templateddlValue:any;
  dateSelect:any;
  phoneNoModel:any;
  trackingLinkModel:any;
  relationshipManagerModel:any;
  selectedWhatsappTemplate:any;
  _contactOptions:any;
  selectedContact:any;
  _wTemplate: any ={options:[]};
	_caller_id: string = "";
	c2cEndPoint: string = "";
	subscriptionDial: Subscription;
	subscription: Subscription;
	callStatus: any;
	minDate: any = new Date();
  api_end_point:any;

	@ViewChild('fromInput', {
		read: MatInput
	}) fromInput: MatInput;

  constructor(private dialog: MatDialog, public router: Router, private API: ApiService, private myservice: MyserviceService,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
	  let stateSettings: any = this.myservice.get_stage_settings();
	  this.callStatus = this.myservice.getLastCallStatus();
    this.api_end_point = this.myservice.get_API_End_Point();
	  let _inNumberOneDefinition = stateSettings.cloudtelephonyout.filter(v => { return v.name == "innumber1" });
	  this._caller_id = _inNumberOneDefinition[0].definition;
	  console.log(this._caller_id);
    this.myservice.settings.subscribe(e => stateSettings = e);

	  if (stateSettings && stateSettings.extraoptions) {
		  for (let opt of stateSettings.extraoptions) {
			  if (opt.name == "c2cendpoint") {
				  console.log(opt);
				  this.c2cEndPoint = opt.definition;
			  }
		  }
	  }
	  this.subscriptionDial = this.myservice.getCallStatus().subscribe(obj => {
		  console.log("home-call-history", obj);
		  this.callStatus = obj;

	  })
    this.getTabList();
    this.getDropdownList();
  }
  getTabList() {
    let obj = {
      user_id: this.user.id,
    } as any;
    //this.user.server_url
    this.API.getContactsWhtasapp(obj, this.api_end_point).subscribe((res: any) => {
      if (res.success) {
        let ELEMENT_DATA = res.success;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      }
    }, (err) => {
      console.log(err)
    }
    )
  }
  getDropdownList() {
   // let _contactOptions = [];

    if (this.stateSettings && this.stateSettings.extraoptions) {

      this._contactOptions = this.stateSettings.extraoptions.filter(a => { return a.name == "contacts_options" });
      this._wTemplate = JSON.parse(this._contactOptions[0].definition);

      this._ddlListExtraOption = this._wTemplate.whatsapp_template_data;

    }
  }
  changeWhatsappTemplate(v) {
    this._rowExtraOptionData=[];
    let _rowExtraOptionData1=[];
    this._paraMessage="";
     _rowExtraOptionData1 = this._ddlListExtraOption.filter(d => { return d.template_name == v });
    this._paraMessage = _rowExtraOptionData1[0].message;

    this.selectedWhatsappTemplate=_rowExtraOptionData1[0];


    this._rowExtraOptionData = _rowExtraOptionData1[0].required_values;//_rowExtraOptionData.Next_Update_Time.type;

	this.dateSelect = '';
	this.fromInput.value = '';
  }
  nameRowRecord(element) {
    this._nameUser = element.Name;
    this._psType = element.type;
	this.selectedContact = element;
	this.relationshipManagerModel = '';
	this.trackingLinkModel = '';
	this.phoneNoModel = '';
	// this.dateSelect = new Date();
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  selectRow(row) {
    this.selectedRow = row;
  }
  CallChatPhoneClick(type: any) {
    if (this.selectedRow == undefined || this.selectedRow == null) {
    //  alert("please select agent user");
      this._snackBar.open("Please select agent user", 'close', {
				duration: 6000,
			});
      return;
    }
    this.selectedButton = "";
    this.showHideWhatsapp = false;
    this.showHidePhone = false;
    this.showHideText = false;
    this.selectedButton = type;



    if (type == "phone") {
      this.showHidePhone = true;
		this.callCustomer()
    }
    else if (type == "whatsapp") {
      this.showHideWhatsapp = true;
    }
    else if (type == "text") {
      this.showHideText = true;
    }


  }
  saveBtn() {

    let _date= moment(this.dateSelect).format('YYYY-MM-DD');
    let _templateName=this.templateddlValue;
    let _msg =this._paraMessage;
    let _uid=this.user.id;
    let _agentid=this.user.agentid;
    let _name=this.user.name;
    let _phone=this.user.phone;
    let _address=this.user.address;
    let _company=this.user.company;
    let _email=this.user.email;
    let _msgType="TEXT";
    let _isHSM=true;
    let _type;
    let _modelObj:any={};
	var checkEmpty = false;
let a=this._rowExtraOptionData;
if(a.Next_Update_Time){

_type=a.Next_Update_Time.type;
	if (_date){
		checkEmpty = true;
	}
_modelObj={
  "Next_Update_Time": {
    "value": _date,
    "type": _type
}
}
}
if(a.Phone_Number){
  _type=a.Phone_Number.type;
	if (this.phoneNoModel) {
		checkEmpty = true;
	}
  _modelObj={
    "Phone_Number":{
      "value":this.phoneNoModel,
      "type":_type
      }
  }
}
if(a.Tracking_Link){
  _type=a.Tracking_Link.type;
	if (this.trackingLinkModel) {
		checkEmpty = true;
	}
  _modelObj={
    "Tracking_Link":{
      "value":this.trackingLinkModel,
      "type":_type
      }
  }
}
if(a.Relationship_Manager){
  _type.a.Relationship_Manager.type;
	if (this.relationshipManagerModel) {
		checkEmpty = true;
	}
  _modelObj={
    "Relationship_Manager":{
    "value":this.relationshipManagerModel,
    "type":_type
    }
  }
}

let _obj = {
  "user_id": _uid,
  "customer_details": this.selectedContact,
  "template_data": this.selectedWhatsappTemplate,
}
	  console.log(this.selectedWhatsappTemplate)
_obj['template_data']['required_values']=_modelObj;

	  if (checkEmpty == false && Object.keys(this.selectedWhatsappTemplate.required_values).length > 0){
		  this._snackBar.open("Fill required details", 'close', {
			  duration: 5000,
		  });
		  return;
	  }

  this.API.saveContactsWhatsapp(_obj, this.user.server_url).subscribe((res: any) => {
	  this.relationshipManagerModel = '';
	  this.trackingLinkModel = '';
	  this.phoneNoModel = '';

if(res.status== "success"){
  this._snackBar.open(res.message, 'close', {
    duration: 5000,
  });
}
  });

  }

onlyNumberValidation(event){
  return event.charCode >= 48 && event.charCode <= 57;
}

	callCustomer() {
		console.log(this.selectedContact);
		//     var data = {
		//       "telco_partner": "TWILIO",
		//       "agent_number": this.user.phone,
		//       "customer_number": obj.number,
		//       "cli": "+15512617144"
		//   }
		//     this.API.gsmtogsmcall(data, this.user.server_url).subscribe(
		//       (res: any) => {
		//         console.log(res)
		//         if(res.status== "success"){
		//           alert("Dialing...")
		//         }

		//       }, (err) => {
		//         console.log(err)
		//       }
		//     )
		var updateObj = this.callStatus;
		if (updateObj.status == undefined) {

			updateObj.dialNum = this.selectedContact.Number;
			updateObj.dialing = 1;
			this.myservice.updateCallStatus(updateObj);
			updateObj = this.callStatus;
			updateObj.dialing = 2;
			this.myservice.updateCallStatus(updateObj);
		}




		// var data: any = {
		// 	"customer_number": this.selectedContact.Number,
		// 	"agent_number": this.user.phone,
		// 	"user_id": this.user.id,
		// 	"caller_id": this._caller_id,
		// 	"networktype": "WIFI",
		// 	"pingdelay": "45"
		// }
		// if (this.user.sip_id) {
		// 	data.sip_id = this.user.sip_id
		// }



		// this.API.sipOutboundCall(data, this.user.server_url, this.c2cEndPoint).subscribe((res: any) => {
		// 	debugger;
		// 	console.log(res)

		// }, (err) => {
		// 	console.log(err)
		// }
		// )

	}
}
