<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <app-menu  [navigateLink]="currentTitle" ></app-menu>
    </mat-drawer>
<div class="CN-Main sbPad">

    <div class="row">
        <div class="col-md-8">
            <p class="CN-Tab-head">Shopify Dashboard</p>
        </div>
        <div class="col-md-4">
            <div class="input-label-group mr-0" style="margin-top: -4px;">
                <p>
                    <mat-select placeholder="Change Store" [(value)]="customer_name" style="width: 100%;">
                    <mat-option value="Contiinex-online.shopify.com">{{customer_name}}</mat-option>
                    </mat-select>
                </p>
            </div>
            </div>
    </div>
   
    <mat-card>
    <div>
        <div class="row sopifySetTabList">
            <div class="col-md-3">
                <p class="pHd"><b>Overview</b></p>
            </div>
            <div class="col-md-12">
                <div style="display:flex;float: right;flex-wrap: wrap;">
                    <div class="input-label-group mr-3">
                        <label>Select Project</label>
                        <p>                         
                            <mat-select  placeholder="Select Project" [(value)]="project_name" (selectionChange)="OnChangeProject($event.value)">
                            <mat-option *ngFor="let prj of projects.split('|')" [value]="prj">{{prj}}</mat-option>
                        </mat-select>
                        </p>
                    </div>

                    <div class="input-label-group mr-3">
                        <label>Select Team</label>
                        <p>
                            <mat-select placeholder="Select Sales" [(value)]="selectedSalesType">
                                <mat-option value="ALL">All</mat-option>
                                <mat-option value="SALES">Sales Team</mat-option>
                                <mat-option value="SUPPORT">Support Team</mat-option>
                            </mat-select>
                        </p>
                    </div>

                    <div class="input-label-group mr-3">
                        <label>Select Activity</label>
                        <p>
                            <mat-select placeholder="Activity Type" [(value)]="selectedActivityType" (selectionChange)="OnActivityChange($event.value)">
                                <mat-option value="COMPLETED">Completed</mat-option>
                                <mat-option value="PENDING">Pending</mat-option>
                                <mat-option value="RECEIVED">Received</mat-option>
                            </mat-select>
                        </p>
                    </div>

                    <div class="input-label-group mr-3">
                        <label>Select Time</label>
                        <p>
                            <mat-select placeholder="Select Date Range"  [(value)]="selectedDayWise"
                            (selectionChange)="OnChangeDayTime($event.value)">
                            <mat-option value="Today">Today</mat-option>
                            <mat-option value="This Month">This Month</mat-option>
                            <mat-option value="This Year">This Year</mat-option>
                            <mat-option value="CustomDate">Custom Date</mat-option>
                            </mat-select>
                        </p>
                    </div>

<div class="input-label-group " *ngIf="showHideCustomDate">
    <mat-form-field appearance="outline" class="customDate">
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate placeholder="Start date" readonly (click)="rangePicker.open()"  #dateRangeStart>
          <input matEndDate placeholder="End date" readonly (click)="rangePicker.open()" #dateRangeEnd  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker></mat-date-range-picker>
      </mat-form-field>
</div>









                </div>
            </div>
        </div>


        <div class="row sopifySetTabList mt-2">
            <div class="col-md-8 pr-0">
                <div class="dd-main1">
                    <span class="CN-Tab-head1" style="z-index:9;position:relative;top:8px;">Channel Overview</span>
                    <div [chart]="Columnchart"> </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="dd-main2">
                    <p><span class="CN-Tab-head1">Widget</span> </p>
                    <div class="d-wd-div">
                        <table style="width:100%">
                            <tr>
                                <td style="width: 33%;"> <img src="../assets/contiinex/image/iconWidgetEye.png"
                                        style="width:35px;" /></td>
                                <td style="width: 20%;">
                                    <div class="d-linex1"> </div>
                                </td>
                                <td><span class="d-l1">{{_totalVisited}}</span> <span class="d-l2">Total Visited </span> </td>
                            </tr>
                        </table>
                    </div>
                    <div class="d-wd-div">
                        <table style="width:100%">
                            <tr>
                                <td style="width: 33%;"> <img src="../assets/contiinex/image/iconWidgetTimerOnline.png"
                                        style="width:35px;" /></td>
                                <td style="width: 20%;">
                                    <div class="d-linex1"> </div>
                                </td>
                                <td><span class="d-l1">{{_totalActiveNow}}</span> <span class="d-l2">Total Active Now </span> </td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>




        <div class="row sopifySetTabList mt-3">
            <div class="col-md-8 pr-0">
                <div class="dd-main1">
                    <div>
                       
                            <div class="row"> 
                                <div class="col-md-4"> 
                                  <p class="mt-2">  <span class="CN-Tab-head1">Team Funnel Detail View</span></p>
                                </div>
                                <div class="col-md-8">
<!--
                                    <div class="input-label-group ml-2">
                                        <label style="height: 8px;">&nbsp;</label>
                                        <p>
                                            <mat-select placeholder="Select Sales">
                                                <mat-option>None</mat-option>
                                                <mat-option value="salesTeam">Sales Team</mat-option>
                                            </mat-select>
                                        </p>
                                    </div>
-->
                                </div>
                            </div>

                            <div class="row"> 
                                <div class="col-md"> 
                                    <span class="d-l1" style="color:#39b54a">{{_whatsApp_Count}}</span> <span class="d-l2">WhatsApp </span>
                                </div>
                                <div class="col-md-1 colmd1"> </div>
                                <div class="col-md"> 
                                    <span class="d-l1" style="color: #8857b5">{{_calls_count}}</span> <span class="d-l2">Calls </span>
                                </div>
                                <div class="col-md-1 colmd1"> </div>
                                <div class="col-md">
                                    <span class="d-l1"  style="color: #d07d1e">{{_chats_count}}</span> <span class="d-l2">Chat </span>
                                </div>
                                <div class="col-md-1 colmd1"> </div>
                                <div class="col-md"> 
                                    <span class="d-l1"  style="color: #20427f">{{_text_count}}</span> <span class="d-l2">Text </span>
                                </div>
                            </div>

                            <div class="row mt-3"> 
                                <div class="col-md-12"> 
                                    
    <mat-table [dataSource]="dataSource" class="mTabWP" style="min-height: 298px;">     
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>      
        <ng-container matColumnDef="whatsapp">
          <mat-header-cell *matHeaderCellDef> WhatsApp </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.whatsapp}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="calls">
          <mat-header-cell *matHeaderCellDef> Calls </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.calls}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="chat">
          <mat-header-cell *matHeaderCellDef> Chat </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.chat}}
          </mat-cell>
        </ng-container>      
        <ng-container matColumnDef="text">
            <mat-header-cell *matHeaderCellDef> Text </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.text}}
            </mat-cell>
          </ng-container>


        <mat-header-row *matHeaderRowDef=displayed_columns></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayed_columns"></mat-row>
      </mat-table>
      <mat-paginator class="mpage" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                                </div>
                            </div>

                    </div>
                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="dd-main1 dmx">
                    <p  style="min-height: 50px;"><span class="CN-Tab-head1">Graphical Overview</span> </p>
                    <div [chart]="donutChartData">
                  </div>
                </div>

            </div>
        </div>

    </div>
</mat-card>
</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>