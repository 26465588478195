<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <app-menu  [navigateLink]="currentTitle" ></app-menu>
    </mat-drawer>
<div class="CN-Main">
    <p class="CN-Tab-head">Detail Overview</p>
    <div>
        <mat-card class="CN-Card cardFirst">
            <div class="row">
                <div class="col-md-9 col-sm-12 col-xs-12">
                    <!-- <span style="text-transform: capitalize;">
                    <b style="position: relative;top: 1px;">{{customer_name}}</b>
                    </span> -->
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 cn-dat text-right">{{getCurrentDate()}}</div>
                </div>
                <br>
            <div class="row">
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs"  [(ngModel)]="selectedProcess">
                            <mat-option value="">Select</mat-option>
                            <mat-option value="processFlow1">{{project_name}}</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(ngModel)]="selectedCampaign">
                            <mat-option value="">Select</mat-option>
                            <mat-option value="All Campaign">{{project_name}}</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(ngModel)]="selectedCampaignStatus">
                            <mat-option value="">Select</mat-option>
                            <mat-option value="Campaign Status">Campaign Status</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(ngModel)]="selectedDayWise"
                            (selectionChange)="OnChangeDayTime($event.value)">
                            <mat-option value="Today">Today</mat-option>
                            <mat-option value="This Month">This Month</mat-option>
                            <mat-option value="This Year">This Year</mat-option>
                            <mat-option value="Custom">Custom Date</mat-option>
                        </mat-select>
                    </span>
                </div>


            </div>
        </mat-card>
    </div>
    <mat-card class="mt-3">
        <span (click)="refreshBtn()" class="refreshcs" style="top: 10px;right: 2px;  cursor: pointer;"><i class="fa fa-refresh" aria-hidden="true"></i></span>
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 mt-1">
           <h6 style="font-weight: 600;position: relative;top: 16px;">Campaign Funnel Detail View</h6>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 mt-2 text-right">
            <mat-form-field appearance="outline" style="width: 208px;position: relative;top: -8px;">
                <mat-date-range-input [rangePicker]="rangepicker" [formGroup]="range" 
                    style="position: relative;top: -3px;font-size: 0.85em !important;">
                    <input matStartDate placeholder="Start date" formControlName="start" readonly
                        (click)="rangepicker.open()">
                    <input matEndDate placeholder="End date" formControlName="end" (dateChange)="valueChanged()" readonly
                        (click)="rangepicker.open()">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="rangepicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangepicker></mat-date-range-picker>
            </mat-form-field>
            </div>
        </div>

        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-2 marg" *ngFor="let obj of funnelData;let $index=index">
                <span class="valWt val{{$index}}">{{obj['value']}} </span><span class="percLbl">({{obj['value_perc']}}%)</span>
                <span class="lName">{{obj.stage}}</span>

            </div>
        </div>

            <!-- <div class="col-md marg">
                <span class=" valWt val2">20,000 </span><span class="percLbl">(10%)</span>
                <span class="lName" >Prospects Reached</span>
            </div>
            <div class="col-md marg">
                <span class="valWt val3">10,000 </span><span class="percLbl">(10%)</span>
                <span class="lName" >Prospects connected </span>
            </div>
            <div class="col-md marg">
                <span class="valWt val4">15,000 </span><span class="percLbl">(17%)</span>
                <span class="lName" >Prospects Interested </span>
            </div>
            <div class="col-md marg" style="border-right:none">
                <span class="valWt val5">6,000 </span><span class="percLbl">(9%)</span>
                <span class="lName" >eApply Visited</span>
            </div>
        </div> -->

        <!-- <div class="row">
            <div class="col-md marg">
                <span class="valWt val6">60,000 </span><span class="percLbl">(100%)</span>
                <span class="lName" >eApply completed </span>
            </div>
            <div class="col-md marg">
                <span class="valWt val7">60,000 </span><span class="percLbl">(100%)</span>
                <span class="lName" >Eligible</span>
            </div>
            <div class="col-md marg">
                <span class="valWt val8">12,000 </span><span class="percLbl">(12%)</span>
                <span class="lName" >Card Approved </span>
            </div>
            <div class="col-md marg">
                <span class="valWt val9">12,000 </span><span class="percLbl">(12%)</span>
                <span class="lName" >Overall Total Counts</span>
            </div>
            <div class="col-md marg" style="border-right:none">
                <span class="valWt val10">200 </span><span class="percLbl">(30%) hrs</span>
                <span class="lName" >Average Time</span>
            </div>
        </div>
        <br><br> -->
<div *ngIf="customerTableData && customerTableData.length">
    <table mat-table [dataSource]="dataSource" >
    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns" >
    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    <td mat-cell *matCellDef="let element" ><mat-icon class="cross-or-tick" [ngStyle]="{'color': element[column] ? 'lightgreen' : 'red'}">{{element[column] ? 'done' : 'clear'}}</mat-icon>{{(column == 'Phone' || column == 'Name') ? element[column] : (element[column] | date:'dd-MM-yyyy hh:mm a':'IST')}} </td>
    </ng-container>
        <!-- <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name </th>
          <td mat-cell *matCellDef="let element">{{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let element">{{element.phone}} </td>
        </ng-container>

        <ng-container matColumnDef="Notified">
          <th mat-header-cell *matHeaderCellDef>Notified</th>
          <td mat-cell *matCellDef="let element"> {{element.Notified | date:'dd-MMM-yyyy h:mm:ss a'}} </td>
        </ng-container>

        <ng-container matColumnDef="Reminded">
          <th mat-header-cell *matHeaderCellDef>Reminded</th>
          <td mat-cell *matCellDef="let element"> {{element.Reminded | date:'dd-MMM-yyyy h:mm:ss a'}} </td>
        </ng-container>

        <ng-container matColumnDef="Paid">
            <th mat-header-cell *matHeaderCellDef>Paid</th>
            <td mat-cell *matCellDef="let element"> {{element.Paid | date:'dd-MMM-yyyy h:mm:ss a'}}
            </td>
          </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginatorA="matPaginator" [pageSize]="10" [ngClass]="'paginator-training'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>





    </mat-card>

</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>