<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <app-menu  [navigateLink]="currentTitle"></app-menu>
    </mat-drawer>
<div class="CN-Main">
    <p class="CN-Tab-head">Overview</p>
    <div>
        <mat-card class="CN-Card cardFirst">
            <div class="row">
                <div class="col-md-9 col-sm-12 col-xs-12">
                    <!-- <span style="text-transform: capitalize;">
                    <b style="position: relative;top: 1px;">{{customer_name}}</b>
                    </span> -->
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 cn-dat text-right">{{getCurrentDate()}}</div>
                </div>
                <br>
            <div class="row">
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs"  [(ngModel)]="selectedProcess">
                            <mat-option value="">Select</mat-option>
                            <mat-option value="processFlow1">{{project_name}}</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(ngModel)]="selectedCampaign">
                            <mat-option value="">Select</mat-option>
                            <mat-option value="All Campaign">{{project_name}}</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <span>
                        <mat-select [ngClass]="'arrowcs'" class="dropdownCs" [(ngModel)]="selectedDayWise"   (selectionChange)="OnChangeDayTime($event.value)">
                            <mat-option value="Today">Today</mat-option>
                            <mat-option value="This Month">This Month</mat-option>
                            <mat-option value="This Year">This Year</mat-option>
                            <mat-option value="Custom">Custom Date</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12" *ngIf="showHideDateRange">
                    <mat-form-field appearance="outline" style="width: 208px;position: relative;top: -8px;">
                        <mat-date-range-input [rangePicker]="rangepicker" [formGroup]="range" style="position: relative;top: -3px;font-size: 0.85em !important;">
                        <input matStartDate placeholder="Start date" formControlName="start"  readonly (click)="rangepicker.open()">
                        <input matEndDate placeholder="End date" formControlName="end" (dateChange)="valueChanged()" readonly (click)="rangepicker.open()">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="rangepicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #rangepicker></mat-date-range-picker>
                    </mat-form-field>
                </div>

            </div>
        </mat-card>
    </div>
    <mat-card class="mt-3">
        <span (click)="refreshBtn()" class="refreshcs" style="top: 10px;right: 2px;"><i class="fa fa-refresh" aria-hidden="true"></i></span>
        <div class="row mt-4">
            <div class="col-md-7 col-sm-12 col-xs-12">
                <mat-card>
                    <div class="row mb-4">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <mat-card-title>Campaign Funnel</mat-card-title>
                        </div>
                        <div class="col-md-6 col-sm-12 col-xs-12 text-right">
                            <mat-radio-group [ngClass]="'radioBtn-Cs'" style="position: relative;top: 5px;" [(value)]="ON_OFF" (change)="funnelOnOffChange($event)">
                                <mat-radio-button value="ON">ON</mat-radio-button>
                                <mat-radio-button value="OFF" class="ml-4">OFF</mat-radio-button>
                              </mat-radio-group>


                            <!-- <p class="text-right"><mat-slide-toggle [ngClass]="'slideCs'" (change)="funnelOnOffChange($event)"></mat-slide-toggle><span style="position: relative;
                                top: -2px;left: 5px;">{{ON_OFF}}</span> </p> -->
                        </div>
</div>

                   
                    <mat-card-content>
                  
                        <table class="Cam-table animationFunnel" *ngIf="shaowHideChartinNormal">
                                <tr *ngFor="let obj of funnelData;let $index=index">
                                    <td><span class="transform-text"> {{obj.stage}}</span>
                                        <mat-progress-bar mode="determinate" class="colorShow{{$index+1}}" value="{{obj['value_perc']}}">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData{{$index+1}} text-right"><span><b>{{obj['value']}}</b></span><span> ({{obj['value_perc']}}%) </span></td>
                                </tr>


                        </table>
                        <div  class="animationFunnel" *ngIf="shaowHideChartinFunnel"  [chart]="_funnelcontainer"> </div>
                        
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-5 col-sm-12 col-xs-12">
                <mat-card>
                    <mat-card-title>Campaign Status</mat-card-title>
                    <mat-card-content class="camStatusScroll1">
                        <!-- <table class="Cam-table" style="line-height: 10px;" *ngFor="let v of _campainStatusList">
                            <tr> <td><span style="color:#fd6c21"> <b> {{v.campaignStatusTitle}}</b></span></td> <td class="text-right"><span><b>{{v.campaignStstusValue}}</b></span></td> </tr>

                            <tr *ngFor="let k of v.campaignSub"> <td><span style="color:#3f4a3b">{{k.title}}</span></td> <td class="text-right"><span><b>{{k.value}}</b></span></td> </tr>
                         </table> -->
                         <mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl" class="example-tree">
                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                                {{node.name}} <span style="position: absolute;
                                right: 35px;">{{node.val}} </span>
                            </mat-tree-node>
                            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                                <div class="mat-tree-node">
                                  <button mat-icon-button matTreeNodeToggle style="color:'#ff5722'"
                                          [attr.aria-label]="'Toggle ' + node.name">
                                    <i *ngIf="!treeControl.isExpanded(node)" class="fa fa-plus" style="font-size: 24px;" aria-hidden="true"></i>
                                    <i *ngIf="treeControl.isExpanded(node)" class="fa fa-minus" style="font-size: 24px;" aria-hidden="true"></i>
                                  </button>
                                 <span> {{node.name}} </span> <span style="position: absolute;
                                  right: 35px;">{{node.val}} </span>
                                </div>
                                <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                                    role="group">
                                  <ng-container matTreeNodeOutlet></ng-container>
                              </div>
                            </mat-nested-tree-node>
                          </mat-tree>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-card>

</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>