import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MyserviceService } from '../../myservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../../api.service';
import * as FileSaver from 'file-saver';


@Component({
	selector: 'app-doc-status',
	templateUrl: './doc-status.component.html',
	styleUrls: ['./doc-status.component.scss']
})
export class DocStatusComponent implements OnInit {
	errorMsg: any = "";
	statusColor: any = "";
	myUser: any = "";
	api_end_point:any;
	docStatusForm: FormGroup = new FormGroup({
		doc_status: new FormControl(this.data.doc_staus, Validators.required),
		remarks: new FormControl('', Validators.required)
	})

	constructor(
		public dailogRef: MatDialogRef<DocStatusComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private API: ApiService,
		private service: MyserviceService,
		private _snackBar: MatSnackBar
	) {
		//console.log('printing doc status construct', this.data);
	}

	ngOnInit(): void {
		this.myUser = this.service.get_loggedin_user();
		this.api_end_point = this.service.get_API_End_Point();
	}

	closePopup() {
		this.resetForm();
		this.dailogRef.close();
	}

	resetForm() {
		this.docStatusForm.reset();
	}
	uploadFinalStauts() {
		//console.log('Form details', this.docStatusForm);
		if (this.docStatusForm.get('doc_status').value == "Not Proper" && this.docStatusForm.get('remarks').value == "") {
			this._snackBar.open("mendatory to fill remarks", "close");
			return;
		}
		if (this.docStatusForm.get('doc_status').value == "") {
			this._snackBar.open("select Document Status", "close");
			return;
		}
		let status = this.docStatusForm.get('doc_status').value;
		let remarks = this.docStatusForm.get('remarks').value;

		let obj = {
			'applicant_id': this.data.applicant_id,
			'applicant_email': this.data.applicant_email,
			'data_tochange': {
				'onboard_status': status,
				'doc_remarks': remarks
			},
			'verified_by': this.myUser.email
		}
		this.API.updateApplicantData(obj,this.api_end_point).subscribe((res: any) => {
			if (res.errors) {
				this.errorMsg = res.errors;
				this.statusColor = 'red';
				//console.log('Errors', res.errors);
				//alert(res.errors)
			} else {
				this.errorMsg = res.success;
				this.statusColor = 'green';
				//alert(res.success);
			}
			//this.closePopup();
			this.resetForm();
			this.dailogRef.close('true');
		}, (err) => {
			this.errorMsg = "Error in the Upload";
			this.statusColor = 'red';
			//alert('Error in the Upload')
		})
	}

	downloadFileAttachment() {
		if (this.data.aadhar.url) {
			FileSaver.saveAs(this.data.aadhar.url + `?access_token=${this.myUser.access_token}`, "aadhar.jpg");
		}
		if (this.data.pan.url) {
			FileSaver.saveAs(this.data.pan.url + `?access_token=${this.myUser.access_token}`, "pan.jpg");
		}
		if (this.data.pass_book.url) {
			FileSaver.saveAs(this.data.pass_book.url + `?access_token=${this.myUser.access_token}`, "pass_book.jpg");
		}
		if (this.data.service.url) {
			FileSaver.saveAs(this.data.service.url + `?access_token=${this.myUser.access_token}`, "service.jpg");
		}
		if (this.data.education_certificate.url) {
			FileSaver.saveAs(this.data.education_certificate.url + `?access_token=${this.myUser.access_token}`, "education_certificate.jpg");
		}
		if (this.data.HSC.url){
			FileSaver.saveAs(this.data.HSC.url + `?access_token=${this.myUser.access_token}`, "HSC.jpg");
		}
		if (this.data.SSC.url) {
			FileSaver.saveAs(this.data.SSC.url + `?access_token=${this.myUser.access_token}`, "SSC.jpg");
		}
		if (this.data.typing_certificate.url) {
			FileSaver.saveAs(this.data.typing_certificate.url + `?access_token=${this.myUser.access_token}`, "typing_certificate.jpg");
		}
		if (this.data.GST.url) {
			FileSaver.saveAs(this.data.GST.url + `?access_token=${this.myUser.access_token}`, "GST.jpg");
		}
		if (this.data.ITR.url) {
			FileSaver.saveAs(this.data.ITR.url + `?access_token=${this.myUser.access_token}`, "ITR.jpg");
		}
		if (this.data.resume.url) {
			let url = "/api/v1/attachments/" + this.data.resume.file_name;
			this.API.downloadReportFromUrl(url, true,this.api_end_point).then(() => {
				console.log('file download completed');
			});
		}


	}

}
