<!--start Comnex-->
<br><br>
<div class="animation-One">
    <p class="text-center paraAdjust"> <span class="t2">
            <img src="../assets/image/comnexProductImg.png" alt="Image" class="comnexProductImg" />
        </span>
        <br> <span class="t4 txtMiddle">
            <h1 class="lbl-ttl" style="font-size: 1.6em;top: 22px;font-weight: 600;">Accelerate your customer’s
                self-service experience </h1> <br><br>
            Web Widget and Automated workflow at their best. This combination has unleashed its power to renovate the
            rapidly changing customer experience industry.<br>
            Comnex Widget can be placed on any of your digital assets i.e. Website, an App, LinkedIn page or any of your
            social pages and can be further automated to form an intuitive communication bridge through multiple
            channels and call to action
        </span>
    </p><br><br>

    <div class="bg-gray alignCenter">
        <div class="row rowMLRZero paraAdjust">
            <div class="col-md-2 col-sm-2 col-xs-2"></div>

            <div class="col-md-8 col-sm-8 col-xs-8">
                <div class="text-center">
                    <img src="../assets/image/comnexBannerImg1.png" alt="Image" class="comImg1 boxImg" />
                </div>
                <span class="t4">
                    <b class="lbl-ttl">Sales in a box</b><br><br>
                    It takes about 20 connects with a prospect for a sales executive to close a deal and untold hours to
                    prepare the right approach for call email or calls.<br><br>

                    That’s a lot of time spent getting to know your customer and their needs, writing notes, logging
                    data, and following up. And as your prospect and customer list grow, the management becomes more
                    challenging.<br><br>

                    At Contiinex we have developed a solution that can help you save a lot of time and manage effectual
                    sales operations across the industries. <br><br>

                    Introducing Comnex, customer journey automation for sales. Digital sales automation drives
                    engagement for all the cold leads through Comnex's efficient process flows paired with intelligent
                    Bots, until the cold leads advance to warm.<br><br>

                    You can now achieve your sales outcomes by focusing less on operational challenges and inheriting
                    Comnex sales automation with a perfect blend of technology with human workforce. <br><br>

                    Sales in a box bundle comes with <br>
                    <ul>
                        <li> Cold leads engagement through Comnex omnichannel (WhatsApp, email, AI bots, SMS)</li>
                        <li> Mobile friendly interface for sales agent</li>
                        <li> Smart routing for all the leads that are advanced to warm gets directly connected to sales
                            agent </li>
                        <li>Predictive dialler for agents with a ratio up to 1:8, resulting in exponential increase of
                            the connect rate and conversion percentage </li>

                    </ul>
                </span>
                <br><br>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-2"></div>
        </div>
    </div>
    <br><br>




    <div class="bg-gray1" style="padding: 45px;">
        <div class="row rowMLRZero paraAdjust alignCenter">
            <div class="col-md-2 col-sm-2 col-xs-2"></div>

            <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="text-center">
                    <img src="../assets/image/measure.png" class="boxImg" alt="Image" />
                </div>
                <span class="t4">
                    <b class="lbl-ttl">Service in a box</b><br><br>

                    AI has been transforming the new age customer service, making it more convenient and personalised by
                    automating quality resolutions to consumers’ and empowering human workforce to work smarter.<br><br>

                    At Contiinex, We believe that customer success is as important and strategic as sales in the process
                    of building a business. <br><br>

                    Comnex Service in a box provides responses instantly to customers, quicker than any human reducing
                    the first response time to bare minimum. These instant response are available all the time, making
                    time zones irrelevant in the first few interactions with customer service. Human workforce
                    engagement isn’t required for every scenario, and a chatbot operating 24 hours a day will make an
                    enormous impact on those tenuous initial steps.<br><br>

                    Our conversational AI is not just answers to the customer's questions but can facilitate routine
                    actions and raise tickets for customer complaints. Making the experience seamless for the customer
                    and delivering first contact resolution without the need for customer to agent connection.<br><br>

                    Service-in-a-box bundle comes with:<br>
                    <ul>
                        <li> Omnichannel customer experience - WhatsApp, SMS, email, IVR BOT, calls</li>
                        <li>First contact resolution by intelligent virtual assistant </li>
                        <li>Proactive outreach to the customers through customer satisfaction survey and NPS </li>
                        <li>Built in Automatic Call distributor to ensure the when the customer calls it connects with
                            the available agent in a split second </li>
                        <li>AI Powered engine empowers Bot to take the right action and provide solution </li>
                    </ul>

                </span>
                <br><br>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-2"></div>
        </div>
    </div>
    <br><br>

    <p class="text-center paraAdjust"> <span class="t2">
            <img src="../assets/image/ComnixLogo.png" alt="Image" style="width: 2em;" />
        </span>
        <br> <span class="t4 txtMiddle">
            <b class="lbl-ttl">Intelligent Customizable Widget </b> <br><br>
            Comnex comes with an intelligent customizable widget that can be plugged into your websites or any of your
            Social Media pages and can enable an instant chat/voice Bridge between the customer and your sales/service
            teams through instant mobile-to-mobile connect.
        </span>
    </p><br><br>
    <br><br>
    <div class="row rowMLRZero paraAdjust revodiv alignCenter">
        <div class="col-md-2 col-sm-1 col-xs-12"></div>
        <div class="col-md-4 col-sm-5 col-xs-12">
            <br>
            <p class="revo"> <img src="../assets/image/comImg.png" alt="Image" style="width:100%;" /></p>
        </div>
        <div class="col-md-4 col-sm-5 col-xs-12" style="padding:0 45px;">
            <span class="t4" style="color: #ececec;"><br>
                <b class="lbl-ttl" style="color: #fff;"> A Revolutionary Button
                    On Your Digital Real-Estate </b><br><br>
                Comnex strategize omni channel communication with AI and Automation. Defining metrics,
                building end-to-end workflows and effortlessly managing them, cannot get easier than this.
            </span><br><br>
        </div>
        <div class="col-md-2 col-sm-1 col-xs-12"></div>
    </div> <br><br><br>
    <div class="row paraAdjust alignCenter">
        <div class="col-md-2 col-sm-12 col-xs-12"></div>
        <div class="col-md-4 col-sm-12 col-xs-12">
            <img src="../assets/image/comnexBanner1.png" alt="Image" class="comImg2" />
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
            <span class="t4">
                <b class="lbl-ttl"> Process Flow Builder </b><br><br>
                <!-- Comnex as a platform can also automate the communication to your customer whether over SMS, Email, WhatsApp, Voice Bot making it a seamless experience for your customer from the time he/she visits your website to the final delivery of service. -->
                Comnex allows you the flexibility to decide the messaging tool you would want to use whether Voice IVR,
                WhatsApp, SMS, or Email to auto-communicate with your customers at each stage of your delivery
                process.<br> Comnex gets integrated into your panel or your CRM and can help you create a seamless
                experience for your customers through the usage of intelligent bots
            </span>
        </div>
        <div class="col-md-2 col-sm-12 col-xs-12"></div>
    </div> <br><br>






    <p class="text-center">
        <span class="t4">
            <b class="lbl-ttl" style="font-size: 2.3em;"> Comnex</b>&nbsp;&nbsp; <b class="lbl-ttl"
                style="color:#fd6c21">Comes with</b>
        </span>
    </p>
    <br>
    <div class="vk-container">
        <div>Cloud Communication</div>
        <div>Process Flow Builder </div>
    </div>
    <div class="vk-container">
        <div>Chat Bot & Voice Bot</div>
        <div>Realtime Dashboard & Analytics</div>
    </div>

    <p class="text-center paraAdjust">
        <br> <span class="t4 txtMiddle">

            It is purely built on a pay-as-you-go model with no upfront cost. You don’t have to pay any Capex for
            in-house server setup whatsoever!

        </span>
    </p>
</div>
<!--end Comnex-->