import { Component, OnInit } from '@angular/core';
import { MyserviceService } from '../myservice.service';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  api_end_point = this.service.get_API_End_Point();
	api_platform = this.service.get_API_Platform1();
  user = this.service.get_loggedin_user();

  constructor(
    private service: MyserviceService,
    private router: Router,
    private API: ApiService
  ) { }

  ngOnInit(): void {
  }

  logout() {
		//	if (this.api_end_point == "https://dev.contiinex.com" || this.api_end_point == "http://dev.contiinex.com") {
		if (this.api_platform == "CONTIINEX") {
			this.service.clearAllItems();
			this.router.navigateByUrl('/Login');
		} else {
			// sessionStorage.setItem('access_token',null);
			var validateUrl = "";
			this.API.logoutAPI(this.api_end_point).subscribe((res) => {
				this.service.clearAllItems();
				window.location.href = "https://contiinex.com";
			})
			return;
			if (sessionStorage.getItem('from_external') == 'true') {
				if (window.location.href.includes('dev.youearn.in')) {
					validateUrl = 'https://dev.contiinex.com/Login';
				} else if (window.location.href.includes('uearn.ai')) {
					validateUrl = 'https://uearn.ai/signin';
				} else {
					validateUrl = 'https://contiinex.com/Login';
				}
			}
			console.log(sessionStorage.getItem('from_external'))
			if (validateUrl) {
				if (window.location.href.includes('dev.youearn.in')) {
					window.location.href = 'https://dev.contiinex.com/Login';
				} else if (window.location.href.includes('uearn.ai')) {
					window.location.href = 'https://uearn.ai/signin';
				} else {
					window.location.href = 'https://contiinex.com/Login';
				}
			} else {
				// this.router.navigate(['/home']);
				// window.location.href = '/home'
				if (window.location.href.includes('dev.youearn.in')) {
					window.location.href = 'https://dev.contiinex.com/Login';
				} else if (window.location.href.includes('uearn.ai')) {
					window.location.href = 'https://uearn.ai/signin';
				} else {
					window.location.href = 'https://contiinex.com/Login';
				}
			}
		}
	}

}
