import { Component, OnInit, Inject, ViewChild , ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { HttpResponse } from '@angular/common/http';
import * as Excel from 'exceljs';
import { DateAdapter } from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface PeriodicElement {
  campaignname: string;
  pusheddate: string;
  totalleads: number;
  totalpushed: number;
  totaldnd: number;
  totalerrors:number; 
  totalreportsdrops:number;
  totalwCPdropped:number;
  incallscreen:string;
  dialmode:string;
  addmoredata:String;
}


@Component({
  selector: 'app-dashboard-addmore-dialog',
  templateUrl: './dashboard-addmore-dialog.component.html',
  styleUrls: ['./dashboard-addmore-dialog.component.scss']
})
export class DashboardAddmoreDialogComponent implements OnInit {
  displayedColumns = ['campaignname','pusheddate','totalleads', 'totalpushed','totaldnd','totalerrors','totalreportsdrops','totalwCPdropped','incallscreen','dialmode','addmoredata'];

  currentLoader:boolean=false;
  createdForm: FormGroup;


  passingMessage: any;
  showHideMessage: boolean = false;
  showHidePassingMessage: boolean = false;
	_color: any = "";
  data:any;

  myUser = this.service.get_loggedin_user();
  @ViewChild('fileUpload')
  fileUploadInput: ElementRef;
  obj1={};
   obj={
    campaign:'',
    pushed_date:'',
    totalleads:'',
    totalpushed:'',
    totaldnd:'',
    totalerror:'',
    totalrepeatdrops:'',
    totalwcpdropped: '' ,
    incallscreen:'',
    dialmode:'',
    url:'', 
  }
  currentFileobj: any = {};
  api_end_point:any;
  
  constructor(public dialogRef: MatDialogRef<DashboardComponent>, @Inject(MAT_DIALOG_DATA) public viewCampaiganDetails, public formbuilder: FormBuilder, private API: ApiService, private service: MyserviceService,private _snackBar: MatSnackBar) {
		//console.log(viewAuditFormDetails)
		this.createdForm = this.formbuilder.group({
			"fileSource": [''],
			"file": [''],
		})
	}
  ngOnInit() {
   // console.log(this.viewCampaiganDetails._element);
    //console.log(this.viewCampaiganDetails._element.details['Total Leads']); 
	this.api_end_point = this.service.get_API_End_Point();
     var test = this.viewCampaiganDetails._element;
     this.obj.campaign = test.campaign;
      this.obj.pushed_date = test.pushed_date;
      this.obj.incallscreen =test.incallscreen;
      this.obj.dialmode = test.priority;
      this.obj.url= test.url;
      
     this.obj1 = JSON.parse(this.viewCampaiganDetails._element.details)
     //console.log(this.obj1);
    

    //console.log(obj.campaign);
			/*this.dataSource.paginator = this.paginator;*/
  }

  closeDialog(refresh) {
    this.createdForm.reset();
    this.dialogRef.close(refresh);
  }
    saveButton(createdForm: any)
    {
      let formData = new FormData();
				var isvisible= 1;
				let campagin_id= this.viewCampaiganDetails._element.id;
				let bulk_file = createdForm.get('fileSource').value;
				formData.append('campaign_id', campagin_id);
				//formData.append('audit_form_name', audit_form_name);
				//formData.append('cx_touch_point', 'Call');
				//formData.append('normalized_score', createdForm.controls.normalized_score.value);

          if (createdForm.get('fileSource').value == "") {

            this._snackBar.open("Please attached Data file*", "close");
            /*this.passingMessage = "Please attached Data file*";
                this.showHidePassingMessage = true;
                this._color = 'red';
                this.currentLoader=false;
                */
                return;
          }

        let _fileDetails = createdForm.get('fileSource').value;
        let _fileName=createdForm.get('fileSource').value.name;
        let fileInput = document.getElementById('fileID') as HTMLInputElement;
        let filePath = createdForm.get('fileSource').value.name;
        //console.log(createdForm.get('fileSource').value.name);
        let allowedExtensions = /(\.xlsx)$/i;
        
        if(!allowedExtensions.exec(filePath)){
          this._snackBar.open('Please upload .xlsx extensions file only.', 'Close');
         /* this.passingMessage = "Please upload .xlsx extensions file only.";
          this.showHidePassingMessage = true;
          this._color = "red";
          fileInput.value = '';
          this.currentLoader=false;*/      
          return;
        }

				if(bulk_file){
					formData.append('leads', bulk_file, bulk_file.name);
				}
			
	
				if(this.viewCampaiganDetails._element.id)
				{
					
					var query = `?campaign_id=${campagin_id}`
				//	console.log(query);

					if(this.viewCampaiganDetails._element.id){
						query = query + `&id=${this.viewCampaiganDetails._element.id}`
           // console.log("this is groupid"+this.myUser.groupid+" "+formData+" "+query);
//
					this.API.addmoredata(formData,query, this.myUser.groupid,this.api_end_point).subscribe(
						(res:any) => {
              if(res.errors){
                this.closeDialog({refresh:true});
                this._snackBar.open(res.errors, 'Close', {
                  duration: 3000
                  });
                  
                /*this.passingMessage = res.errors;
                this.showHidePassingMessage = true;
                this._color = 'red';
                this.currentLoader=false;
                  setTimeout(()=>{
                    this.showHideMessage=false;
                    this.closeDialog({refresh:true});
                    },3000)*/   
                }
							if(res.success){
                this.closeDialog({refresh:true});
                this._snackBar.open('Data Successfully added', 'Close', {
                  duration: 3000
                  });
                  
                /*
							this.passingMessage = "Data Successfully added*";
							this.showHidePassingMessage = true;
							this._color = 'green';
							this.currentLoader=false;
								setTimeout(()=>{
									this.showHideMessage=false;
									this.closeDialog({refresh:true});
								  },3000)*/
							}
						}, (err) => {
              this._snackBar.open(err, 'Close', {
                duration: 3000
                });
              /*this.passingMessage = err;
                this.showHidePassingMessage = true;
                this._color = 'red';
                this.currentLoader=false;
                  setTimeout(()=>{
                    this.showHideMessage=false;
                    this.closeDialog({refresh:true});
                    },3000)*/
							// debugger;
							console.log(err)
						})
          }
        }

    }
      changeFile(event) {
        this.currentFileobj = {};
        this.passingMessage="";
        this.showHidePassingMessage=false;
        let filePath = event.target.files[0].name;
        let allowedExtensions = /(\.xlsx)$/i;
        if(!allowedExtensions.exec(filePath)){
          //alert('Please upload file having extensions .pdf/.doc/.docx/.xls/.xlsx only.');
          this._snackBar.open('Please upload file having extensions .xlsx only.', 'Close');
          /*
          this.passingMessage="Please upload file having extensions .xlsx only.";
          this.showHidePassingMessage=true;
          this._color = "red";*/
        setTimeout(()=>{
          this.passingMessage = "";
          this.showHidePassingMessage=false;
          this._color = "green"
        },3000)
            return false;
        }
        const file = event.target.files[0]
        const reader = new FileReader()
        const workbook = new Excel.Workbook();
        var rowCount = 0;
        reader.readAsArrayBuffer(file)
        reader.onload = () => {
          const buffer: any = reader.result;
          workbook.xlsx.load(buffer).then(workbook => {
          //  console.log(workbook, 'workbook instance')
    
            workbook.eachSheet((sheet, id) => {
              
              sheet.eachRow((row, rowIndex) => {
                rowCount = rowCount + 1;
               // console.log(row.values, rowIndex)
              })
            })
            if(rowCount < 2){
              this.currentFileobj = {};
              this.passingMessage="Data empty for uploaded file";
          this.showHidePassingMessage=true;
          this._color = "red";
              this.fileUploadInput.nativeElement.value = "";
          this.createdForm.controls.fileSource.setValue(null);
          this.createdForm.controls.file.setValue(null);
              setTimeout(()=>{
                this.passingMessage = "";
          this._color = "green";
          this.showHidePassingMessage=false;
              },3000);
              
            }else{
        if (event.target.files && event.target.files.length) {
         // console.log(event.target.files);
          this.currentFileobj = event.target.files[0];
          const [upload_file] = event.target.files;
          this.createdForm.patchValue({
            fileSource: upload_file
          });
          }
            }
            
          })
        }
        
      }
	download_file() {
		this.API.bulkTemplateAPI(this.api_end_point).subscribe(
			(res: HttpResponse<any>) => {
				let content_type = res.headers.get("content-type");
				let file_data = res.headers.get("content-disposition");
				let p = file_data.split(';')[1];
				let file_name = p.slice(p.indexOf('"') + 1, p.length - 1);
				let body = res.body;
				let blob: any = new Blob([body], { type: content_type });
				let url = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.download = file_name;
				anchor.href = url;
				anchor.click();
			},
			(err) => {
				//console.log('printing errors', err)
				//alert('Error in downloading the file')
        this._snackBar.open('Error in downloading the file', 'Close');
			}
		)
	}
      
  }