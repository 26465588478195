import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import{HttpClient, HttpHeaders,HttpResponse }from '@angular/common/http';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MyserviceService } from 'src/app/myservice.service';
@Component({
  selector: 'app-contiinex-add-team-member-dialog',
  templateUrl: './contiinex-add-team-member-dialog.component.html',
  styleUrls: ['./contiinex-add-team-member-dialog.component.scss']
})
export class ContiinexAddTeamMemberDialogComponent implements OnInit {
  createdForm:FormGroup;
  showHideErrorMsg:any;
  msgColor: any = "";
  showHideMsg:boolean=false;

  project:any;
  customer_name:any;
  business_process:any;
  businessType:any;
  groupid:any;
  showHideStdForEdit:boolean=true;
  addEditText:any="ADD";
  api_end_point:any;
  constructor(public dialogRef: MatDialogRef<ContiinexAddTeamMemberDialogComponent>, private router: Router, private _service:MyserviceService,
    private http:HttpClient,private fb:FormBuilder,private API: ApiService,private _snackBar: MatSnackBar,	@Inject(MAT_DIALOG_DATA) public viewEditData)
     {

      this.createdForm=fb.group({
        agentName:['',[Validators.required]],
        agentEmail:['',[Validators.required,Validators.email]],
      //  agentPassword:['',Validators.required],
      //  agentConfirmPassword:['',Validators.required],
        agentStdCode:['+91',Validators.required],
        agentPhoneNumber:['',Validators.required],
        agentCategory:['',Validators.required]
      })
    }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
      this.customer_name = String(this._service.get_loggedin_user().company);
      this.project = String(this._service.get_loggedin_user().project);
      this.business_process = String(this._service.get_loggedin_user().business_process);
      this.businessType = String(this._service.get_loggedin_user().businessType);
      this.groupid = this._service.get_loggedin_user().groupid;
    }
    if(this.viewEditData){
      this.showHideStdForEdit=false;
      this.addEditText="UPDATE";
      this.createdForm.controls['agentName'].patchValue(this.viewEditData.name);
      this.createdForm.controls['agentEmail'].patchValue(this.viewEditData.email);
     // this.createdForm.controls['agentPassword'].patchValue(this.viewEditData.agentPassword);
     // this.createdForm.controls['agentConfirmPassword'].patchValue(this.viewEditData.agentConfirmPassword);
      this.createdForm.controls['agentPhoneNumber'].patchValue(this.viewEditData.phone);
      this.createdForm.controls['agentCategory'].patchValue(this.viewEditData.job_profile);
    }
    else{
      this.showHideStdForEdit=true;
      this.addEditText="ADD";
    }
  }
  submitBtn(createdForm:any){
    this.showHideMsg=false;
    let _agentName=createdForm.controls.agentName.value;
    let _agentEmail=createdForm.controls.agentEmail.value;
   // let _agentPassword=createdForm.controls.agentPassword.value;
   // let _agentConfirmPassword=createdForm.controls.agentConfirmPassword.value;
    let _agentStdCode=createdForm.controls.agentStdCode.value;
    let _agentPhoneNumber=createdForm.controls.agentPhoneNumber.value;
    let _agentCategory=createdForm.controls.agentCategory.value;
//||_agentPassword==""||_agentConfirmPassword==""
    if(_agentName==""){
      this.showHideErrorMsg="Please Enter Agent Name *.";
      this.showHideMsg=true;
      this.msgColor="red";
      setTimeout(()=>{
        this.showHideErrorMsg="";
         this.showHideMsg=false;
        this.msgColor="";
      },3000)
      return false;
    }
    if(_agentEmail==""){
      this.showHideErrorMsg="Please Enter Agent Email *.";
      this.showHideMsg=true;
      this.msgColor="red";
      setTimeout(()=>{
        this.showHideErrorMsg="";
         this.showHideMsg=false;
        this.msgColor="";
      },3000)
      return false;
    }
    let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (reg.test(_agentEmail) == false)
    {
      this.showHideErrorMsg="Invalid Email Address";
      this.msgColor="red";
      this.showHideMsg=true;
      setTimeout(()=>{
        this.showHideErrorMsg="";
         this.showHideMsg=false;
        this.msgColor="";
      },3000)
        return false;
    }
    if( _agentPhoneNumber==""){//_agentStdCode=="" ||
      this.showHideErrorMsg="Please Enter Phone Number *.";
      this.showHideMsg=true;
      this.msgColor="red";
      setTimeout(()=>{
        this.showHideErrorMsg="";
         this.showHideMsg=false;
        this.msgColor="";
      },3000)
      return false;
    }
    if( _agentCategory=="" || _agentCategory==null ){
      this.showHideErrorMsg="Please Select Category *.";
      this.showHideMsg=true;
      this.msgColor="red";
      setTimeout(()=>{
        this.showHideErrorMsg="";
         this.showHideMsg=false;
        this.msgColor="";
      },3000)
      return false;
    }
   
    if(this.addEditText=="UPDATE"){  

      let isphone =/^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (isphone.test(_agentPhoneNumber) == false)
    {
      this.showHideErrorMsg="Please enter phone with country code";
      this.msgColor="red";
      this.showHideMsg=true;
      setTimeout(()=>{
        this.showHideErrorMsg="";
         this.showHideMsg=false;
        this.msgColor="";
      },3000)
        return false;
    }
  }
  let _obj ;
if(this.addEditText=="ADD"){
     _obj = {
      "name": _agentName,
      'company':this.customer_name || "",
      'project':this.project || "",
      "business_process": this.business_process || "",
      "email": _agentEmail,
      "phone":  _agentStdCode +""+_agentPhoneNumber,
      "job_profile":_agentCategory,
      "role": "user",
      "groupid": this.groupid,
      "status": "On Board",
      "businessType" : "COMNEX"
    };
  }
  else{
     _obj = {
      "name": _agentName,
      'company':this.customer_name || "",
      'project':this.project || "",
      "business_process": this.business_process || "",
      "email": _agentEmail,
      "phone":  _agentPhoneNumber,
      "job_profile":_agentCategory,
      "role": "user",
      "groupid": this.groupid,
      "status": "On Board",
      "businessType" : "COMNEX",
      "id":this.viewEditData.id
    };
  }
  if(this.addEditText=="ADD"){
    this.API.saveJoin1(_obj, this.api_end_point).subscribe((res: any) => {
      if(res.errors){
        this._snackBar.open(res.errors, 'close', {
          duration: 5000,
        });

        return;
      }
       else {
        this.showHideErrorMsg="Good, Saved Successfully Done..!!";
        this.showHideMsg=true;
        this.msgColor="green";
        this.createdForm.reset();
        setTimeout(()=>{
          this.showHideErrorMsg="";
           this.showHideMsg=false;
          this.msgColor="";
          this.dialogRef.close('true');
        },3000)
      }
    });
  }
  else{
    this.API.editAddShopifyTeam(_obj, this.api_end_point).subscribe((res: any) => {
      if(res.errors){
        this._snackBar.open(res.errors, 'close', {
          duration: 5000,
        });

        return;
      }
      else {
        this.showHideErrorMsg="Good, Updated Successfully Done..!!";
        this.showHideMsg=true;
        this.msgColor="green";
        this.createdForm.reset();
        setTimeout(()=>{
          this.showHideErrorMsg="";
           this.showHideMsg=false;
          this.msgColor="";
          this.dialogRef.close('true');
        },3000)
      }
    });
  }


  }
  closePopup(){
    this.dialogRef.close('false')
  }
  omit_special_char(event)
  {
     let k;
     k = event.charCode; 
     return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  onlyNumberValidation(event){
    return event.charCode >= 48 && event.charCode <= 57;
  }
  omitSpaceChar(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)){
        return false;
    }
    return true;
          
          }
}

