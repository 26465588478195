import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { ServicesService } from '../../services.service';
import { MyserviceService } from '../../../myservice.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { keys } from 'underscore';

@Component({
  selector: 'app-assessment-table',
  templateUrl: './assessment-table.component.html',
  styleUrls: ['./assessment-table.component.scss']
})
export class AssessmentTableComponent implements OnInit {
  table_data: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) tableSort: MatSort;
	
	public dataSource = new MatTableDataSource(this.table_data);

  @Input('receiveFromDashboard') fromDash: any;
  @Output() openCreateAssessment: EventEmitter<any> = new EventEmitter();

  displayedColumns = [
    'name',
    // 'type',
    'default',
    'passing_score',
    'timing',
    'total_questions',
    'total_score',
    'total_sections',
    'created_at',
    'download',
    'action'
  ];
  
  constructor(private dashService: ServicesService,
		private myService: MyserviceService) { }

  ngOnInit(): void {
    this.dashService.assessment_dash_data.subscribe((d) => {
			if (d) {
       // console.log(d)
		let finaldata = d.success.filter(obj => {
			console.log("---",obj.status);
			return (obj.status == 1)})
			console.log("--final--",finaldata);
				this.table_data = finaldata
				// console.log('init subscribe data', d);
				this.refreshTableData();
			}
		});
  }

  refreshTableData() {
		// console.log('table data refresh', this.table_data);
		this.dataSource = new MatTableDataSource(this.table_data);
		this.dataSource.paginator = this.paginator;
		// this.dataSource.sort = this.tableSort;
  }
  downloadQuestionaire(element, index){
  //  console.log(this.table_data[index]);
   // console.log(JSON.parse(this.table_data[index].questionaire))
    var obj = JSON.parse(this.table_data[index].questionaire);
    let workbook = new Workbook(); 
    for (var sIndex=0; sIndex < obj.length; sIndex++) {
      let worksheet = workbook.addWorksheet(obj[sIndex].title);
      var headers = ['Questions', 'Answer Formats', 'Answers', 'Score'];
      worksheet.addRow(headers);
      for (var rw=0; rw < obj[sIndex].child.length; rw++) {
        var rowValues = [];
      //  console.log(obj[sIndex].child[rw])
        Object.keys(obj[sIndex].child[rw]).map(function(key, index) {
         // console.log(key,index,obj[sIndex].child[rw][key])
          if(index == 2 && key != 'Answers'){
            rowValues.push("")
            rowValues.push(obj[sIndex].child[rw][key].toString())
          }else{
            rowValues.push(obj[sIndex].child[rw][key].toString())
          }
          
        });
        worksheet.addRow(rowValues);
      }
    }
    		workbook.xlsx.writeBuffer().then((data) => {
					let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					fs.saveAs(blob, `assessment-${element.name}-${element.type}.xlsx`);
				})
    // let workbook = new Workbook();
		// 		let worksheet = workbook.addWorksheet('Candidate Data');
		// 		worksheet.addRow(headers);
		// 		data.forEach(d => {
		// 			let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
		// 			//	console.log('printing row', row);
		// 		})
		// 		//Generate & Save Excel File
		// 		workbook.xlsx.writeBuffer().then((data) => {
		// 			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		// 			fs.saveAs(blob, 'Recruitment.xlsx');
		// 		})
  }
  editAssessment(element, index){
    element.questionaire = this.table_data[index].questionaire;

    this.openCreateAssessment.emit({data: element, index})
  }
}
