import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { TimeslotSettingComponent } from '../timeslot-setting/timeslot-setting.component';
import { MatSort } from '@angular/material/sort';

@Component({
	selector: 'app-more-slot-dialog',
	templateUrl: './more-slot-dialog.component.html',
	styleUrls: ['./more-slot-dialog.component.scss']
})
export class MoreSlotDialogComponent implements OnInit {

	displayedColumns: string[] = ['agent_name', 'agent_email', 'agent_phone', 'slot_status', 'audited_calls', 'audit_score', 'actions'];//'further_slot'
	dataSource: any = new MatTableDataSource();
	slotsettings;
	agentlist;
	updatecapcityslotForm: FormGroup;
	createdForm: FormGroup;

	passingMessage: any;
	showHideMessage: boolean = false;
	showHidePassingMessage: boolean = false;
	column_addagent: boolean = false;
	_color: any = "";
	currentLoader: boolean = false;
	agentDetails = [];
	btnText = 'Book';
	checked: boolean;
	agentAuditScores: any = {};


	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;


	bookedagent = [];
	api_end_point:any;
	
	constructor(public dialogRef: MatDialogRef<TimeslotSettingComponent>, @Inject(MAT_DIALOG_DATA) public viewCampaiganDetails, public formbuilder: FormBuilder, private API: ApiService, private service: MyserviceService, private _snackBar: MatSnackBar) {
		this.createdForm = this.formbuilder.group({
			capacity: ['', Validators.required],
			wanttoactivate: this.formbuilder.array([]),
		}),
			this.updatecapcityslotForm = this.formbuilder.group({
				activestatus: ['', Validators.required],
				agentactivate: this.formbuilder.array([])
			});
	}

	ngOnInit() {
		this.api_end_point = this.service.get_API_End_Point();
		//console.log(this.viewCampaiganDetails._element);
		this.API.getagentBookedSlot(this.viewCampaiganDetails._element.id,this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
				// this._snackBar.open(data.errors,"close");
				this.Bookedagentstatus();
				//console.log(data.errors);
			} else {
				// console.log(data.success);
				this.bookedagent = data.success;
				this.Bookedagentstatus();
			}
		});
		this.API.getAuditScores(this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
				this._snackBar.open("Audit Score data Error. " + data.errors, "close", {
					duration: 3000
				});
			}
			if (data.success) {
				this.agentAuditScores = data.success;
			}
		})
		this.slotsettings = this.viewCampaiganDetails._element;
		this.agentlist = this.service.getAllIds('user');
		// console.log(this.agentlist);
	}



	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
	/*paginatorfunction() {
		this.dataSource.paginator = this.paginator;
	}*/

	close() {
		this.dialogRef.close();
	}

	getAgentAuditData(obj: any, key: string) {
		// console.log('printing obj', obj);
		if (this.agentAuditScores.hasOwnProperty(obj.id.toString())) {
			return this.agentAuditScores[obj.id][key];
		}
		return 0;
	}

	Bookedagentstatus() {

		//
		let testa = [];
		// console.log(this.viewCampaiganDetails._element);
		// console.log(this.bookedagent);

		this.agentlist.forEach((agent, index) => {
			this.agentlist[index]['slot_status'] = '';
		});


		if (this.bookedagent.length > 0) {
			for (var i = 0; i < this.bookedagent.length; i++) {
				//console.log("Booked list ", this.bookedagent[i].email);
				//testa.push(this.bookedagent[i].email)
				for (var j = 0; j < this.agentlist.length; j++) {
					this.agentlist[j].slotstatusaction = "";
				//	console.log(this.agentlist[j].id == this.bookedagent[i].user_id);
					if (this.agentlist[j].id == this.bookedagent[i].user_id) {
						this.agentlist[j]['slot_status'] = this.bookedagent[i]['status'];
						if (this.bookedagent[i].status == "Cancelled") {
							this.agentlist[j].slotstatusaction = "book";
						}
						else if (this.bookedagent[i].status == "Booked") {
							this.agentlist[j].slotstatusaction = "Cancel";
						//	console.log(this.agentlist[j]);
						}
						else {
							//this.btnText= 'Book';
							//this.btnText == "Cancel"
							this.agentlist[j].slotstatusaction = "Cancel";
						//	console.log(this.agentlist[j]);
						}
					}
					else {
						this.agentlist[j].slotstatusaction = "Book";
					}
				}
			}
			this.dataSource = new MatTableDataSource(this.agentlist);
			//console.log("--1--", this.dataSource.data);
			this.dataSource.sort = this.sort;
			this.ngAfterViewInit();
			//this.paginatorfunction();

		}
		else {
			for (var j = 0; j < this.agentlist.length; j++) {
				this.agentlist[j].slotstatusaction = "Book";
			}
			this.dataSource = new MatTableDataSource(this.agentlist);
			//console.log("--2--", this.dataSource.data);
			this.dataSource.sort = this.sort;
			this.ngAfterViewInit();
			//this.paginatorfunction();
		}

	}
	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	updateslot(createForm: any) {
		let obj = [];
		if (this.createdForm.controls["capacity"].value == "" || this.createdForm.controls["capacity"].value == null) {
			this.passingMessage = "Please enter capacity*";
			this.showHidePassingMessage = true;
			this._color = 'red';
			this.currentLoader = false;
			return;
		}
		else if (this.createdForm.controls["capacity"].value < 0) {
			this.passingMessage = "Please enter capacity greater than Zero*";
			this.showHidePassingMessage = true;
			this._color = 'red';
			this.currentLoader = false;
			return;
		}
		else {
			//console.log(this.createdForm.controls["capacity"].value);


			let obj1: any = {
				new_max_limit: this.createdForm.controls["capacity"].value,
				slot_id: this.viewCampaiganDetails._element.id
			}
			//console.log("here data" + obj1);
			this.API.editSlot(obj1,this.api_end_point).subscribe((data: any) => {
				if (data.errors) {
					this._snackBar.open(data.errors, "close");
				} else {
					this._snackBar.open("Slot updated successfully... ", "close", {
						duration: 3000
					});
					//alert("Slot updated successfully... ");
					this.dialogRef.close({ refresh: true });
				}
			});
		}
	}

	Bookedslot(val) {

		let agent = val;
	//	console.log("--" + JSON.stringify(agent));

		if (agent.slotstatusaction == "Book") {

			let obj: any = {
				slot_id: this.viewCampaiganDetails._element.id,
				for_user: agent.id
			}
			//console.log(JSON.stringify(obj));
			this.API.agentRequestForBookingSlot(obj,this.api_end_point).subscribe((data: any) => {
				if (data.errors) {
				//	alert(data.errors);
					this._snackBar.open(data.errors, "close", {
						duration: 3000
					});
				//	console.log(data.errors);
				} else {
					//console.log(data.success);
					this._snackBar.open("agent selected... ", "close", {
						duration: 3000
					});
					//alert("Slot updated successfully... ");
					this.dialogRef.close({ refresh: true });
					/*setTimeout(() => {
						window.location.reload();
					}, 2000)*/

				}
			});
		}
		else if (agent.slotstatusaction == "Cancel") {
			this.cancelledslot(agent);
		}
	}
	cancelledslot(val) {
		var agentdetails = val
		let cancelagent: any = {
			slot_id: this.viewCampaiganDetails._element.id,
			for_user: agentdetails.id
		}
		//console.log(JSON.stringify(cancelagent));

		this.API.agentPluginGetRequestForCancelSlot(cancelagent,this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
			//	alert(data.errors);
				this._snackBar.open(data.errors, "close", {
					duration: 3000
				});
				//console.log(data.errors);
			} else {
				//console.log(data.success);
				this._snackBar.open(data.success, "close", {
					duration: 3000
				});
				//alert("Slot updated successfully... ");
				this.dialogRef.close({ refresh: true });
			}
		})
	}


}



