import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { ApiService } from '../../api.service';
import { MyserviceService } from 'src/app/myservice.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: 'app-end-session-dialog',
	templateUrl: './end-session-dialog.component.html',
	styleUrls: ['./end-session-dialog.component.scss']
})
export class EndSessionDialogComponent implements OnInit {
	viewSessionData: any;
	endWorkDay: any;
	stagesetting: any;
	api_end_point:any;
	constructor(private API: ApiService,
		public dialogRef: MatDialogRef<EndSessionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public viewData, private myservice: MyserviceService,private _snackBar: MatSnackBar) {
	}

	ngOnInit(): void {
		// this.api_end_point = this.myservice.get_API_End_Point();
		// this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
		// 	// console.log(data);
		// 	// console.log(data.success.extraoptions);
		// 	let setting = data.success.extraoptions
		// 	let stopsetting = setting.filter(v => {
		// 		// console.log(v);
		// 		return (v.name == "usernegativepreferences")
		// 	})
		// 	//console.log(stopsetting[0].definition);
		// 	let text = stopsetting[0].definition;
		// 	this.stagesetting = text.split(",");
		// 	//console.log(this.stagesetting);
		// })
		//console.log(this.viewData);
		//this.endWorkDay = this.viewSessionData;
		this.stagesetting = ["Bio Break","Lunch Break","Coffee Break","Team briefing & Quality Feedback","End of Day Work"];
	}
	closeDialog() {
		this.dialogRef.close('false');
	}
	onOptionsSelected(value) {
		//console.log(this.stagesetting, value);
		if(value)
    {
      this.endWorkDay = value;
    }

	}
	submitbtn() {
		//debugger;
		if (this.endWorkDay == null || this.endWorkDay == "") {
			this._snackBar.open("please select option","close", {
        duration: 4000,
      });
		} else {
			this.dialogRef.close('true');
			this.myservice.clearCallStatus();
			//console.log(this.endWorkDay)
			// this.API.remoteDialStop(this.viewData.id, this.api_end_point, this.endWorkDay).subscribe(
			// 	(res: any) => {
			// 		//console.log(res)
			// 		// this.removeNum();
			// 		this.dialogRef.close('true');
			// 		//this.myservice.clearCallStatus();
			// 	}, (err) => {
			// 		console.log(err)
			// 	}
			// )
		}
	}

}
