import { Component, OnInit, Inject, ViewChild , ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { HttpResponse } from '@angular/common/http';
import * as Excel from 'exceljs';
import { values } from 'underscore';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard-addagent',
  templateUrl: './dashboard-addagent.component.html',
  styleUrls: ['./dashboard-addagent.component.scss']
})
export class DashboardAddagentComponent implements OnInit {
  currentLoader:boolean=false;
  createdForm: FormGroup;  //'email',
  displayedColumns = ['Status','email','data', 'followup','dialled','contacted','inactive'];
  dataSource: any = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("toggleElement") ref: ElementRef;
  passingMessage: any;
  showHideMessage: boolean = false;
  showHidePassingMessage: boolean = false;
  column_addagent: boolean = false;
	_color: any = "";

  addagent: any;
  agents_list:string[]=[];
  wanttoactivate=[]; 
  allComplete: boolean = false;

  checked: boolean;
  agentcreatedForm: FormGroup;
  api_end_point:any
  constructor(public dialogRef: MatDialogRef<DashboardComponent>, @Inject(MAT_DIALOG_DATA) public viewAddagentDetails,  public formbuilder: FormBuilder, private API: ApiService, private service: MyserviceService,private _snackBar: MatSnackBar){ 
    this.createdForm = this.formbuilder.group({
      agentdatalist:['',Validators.required],
      wanttoactivate: this.formbuilder.array([]),
      }),
      this.agentcreatedForm = this.formbuilder.group({
        activestatus: ['',Validators.required],
        agentactivate: this.formbuilder.array([])
      });
    }

  ngOnInit(): void {
	this.api_end_point = this.service.get_API_End_Point();
    //console.log(this.viewAddagentDetails._element.agentdata);
    this.showagent(this.viewAddagentDetails._element.agentdata);
    
  }

  //table details shown.
  showagent(obj){
    var test = Object.values(obj);
    //console.log(JSON.parse(JSON.stringify(test)));
    this.addagent=JSON.parse(JSON.stringify(test));
    // console.log(this.addagent);
    //console.log(this.addagent[this.agents_list[0]].inactive);
    this.addingat_table();
    var allagentlist =[];
    allagentlist = this.service.getAllIds('user');

    let valuesArray1 = allagentlist.reduce(function(a, c) {
      a[c.email] = c.email;
      return a;
  }, {});
  let valuesArray2 = this.addagent.reduce(function(a, c) {
    a[c.email] = c.email;
      return a;
  }, {});
  var result = allagentlist
      .filter(function(c) {
          return !valuesArray2[c.email];
      })
      .concat(
        this.addagent.filter(function(c) {
              return !valuesArray1[c.email];
          })
      );
//   console.log("5", result);
// console.log(result.length);

        this.agents_list= result;
        //console.log(this.agents_list);
        //this.checked=true;
    //debugger;
  }
  addingat_table()
  {
    this.dataSource =new MatTableDataSource(this.addagent);
    //console.log("table"+this.dataSource.data);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  changed(value){
    var status= value;
   // console.log(status.activestatus);
  }

  add_more_agent()
  {
    if(this.agents_list.length == 0)
      {
        this.column_addagent = false;
          this._snackBar.open('All agent already added*','close');
      }
      else{
        //console.log(this.agents_list);
        this.column_addagent=true;
      }
    
  }

  //toggle button with angular material
  addLocalCampaignAgents(createdForm: any){
    let obj = [];
    if (createdForm.controls["agentdatalist"].value == "" || createdForm.controls["agentdatalist"].value == null) {
			this.passingMessage = "Please select atleast one Agent*";
					this.showHidePassingMessage = true;
					this._color = 'red';
					this.currentLoader=false;
					return;
		}
    else{
      for(let i=0; i< this.addagent.length; i++)
      {
        //debugger;
        if(createdForm.controls["wanttoactivate"].value[i].email === this.addagent[i].email)
        {
         // console.log(createdForm.controls["wanttoactivate"].value[i].email);
          this.passingMessage = "Agent already added*";
					this.showHidePassingMessage = true;
					this._color = 'red';
					this.currentLoader=false;
          this.createdForm.get('agentdatalist').reset();
          setInterval(() => {
						//this.dialogRef.close({ refresh: true });
						this.currentLoader=false;
					}, 2000);
        }
        else{
         // console.log("else part"+JSON.stringify(createdForm.controls["wanttoactivate"].value[i]));
          obj.push(createdForm.controls["wanttoactivate"].value[i].email);

          let obj_topost = {
            "agents_list": obj,
            "campaign_name": this.viewAddagentDetails._element.campaign,
            "priority": this.viewAddagentDetails._element.priority
          }
          //console.log(JSON.stringify(obj_topost));
          this.API.addAgentstoCampaign(obj_topost,this.api_end_point).subscribe((res: any) => {
            if (res.errors) {
              this.passingMessage = res.errors;
              this.showHidePassingMessage = true;
              this._color = 'red';
              this.currentLoader=false;
            }else if(res.success){
             // console.log(res.success);
              //this.dialogRef.close({refresh: true});
              this.passingMessage = "agent added successfully*";
							this.showHidePassingMessage = true;
							this._color = 'green';
							this.currentLoader=false;
								setTimeout(()=>{
									this.showHideMessage=false;
									this.closeDialog({refresh:true});
								  },2000)
            }
          });
        

        }
        
      }
      //console.log("adding agents"+createdForm.controls["agentdatalist"].wanttoactivate);
      
    }
    
  }




  addlistcheck(val:any)
  {
    ////debugger;
    let selectagent =val;
   // console.log(JSON.stringify(selectagent));
    this.createdForm.controls["wanttoactivate"].value.push(selectagent);
   // console.log(this.createdForm.controls["wanttoactivate"].value);
    //this.wanttoactivate.push({data1});
  }

  closeDialog(refresh) {
    //this.createdForm.reset();
    this.dialogRef.close(refresh);
  }

  setValue(i , e){
    if(e.checked){
        this.dataSource.data[i].active_status = 'true'
       // console.log("checked"+JSON.stringify(this.dataSource.data[i]));
        this.agentcreatedForm.controls["agentactivate"].value.push(this.dataSource.data[i]);

   }else{
    this.dataSource.data[i].active_status = 'false'
   // console.log("unchecked"+this.dataSource.data[i].active_status);
   }
}
  saveButton(agentcreatedForm:any)
  {

   // console.log(this.agentcreatedForm.controls["agentactivate"].value);
    //this.send_agent_list(this.agentcreatedForm.controls["agentactivate"].value);
    let obj = [];

    
    for(let i=0; i< this.agentcreatedForm.controls["agentactivate"].value.length; i++)
    {

       // console.log(this.agentcreatedForm.controls["agentactivate"].value[i].email);
        obj.push(this.agentcreatedForm.controls["agentactivate"].value[i].email);
    }
    //console.log(obj);
      let obj_topost = {
          "active_agents_list":obj,
          "active_list": [this.viewAddagentDetails._element.campaign]
        }
       // console.log(JSON.stringify(obj_topost));
        this.API.updateAgentStatus(obj_topost,this.api_end_point).subscribe((res: any) => {
          if (res.errors) {
            this.passingMessage = res.errors;
            this.showHidePassingMessage = true;
            this._color = 'red';
            this.currentLoader=false;
          }else if(res.success){
           // console.log(res.success);
            this.passingMessage = "switch campaigns agents updated successfully*";
							this.showHidePassingMessage = true;
							this._color = 'green';
							this.currentLoader=false;
								setTimeout(()=>{
									this.showHideMessage=false;
									this.closeDialog({refresh:true});
								  },2000)
          }
        });
  }
  
  /*send_agent_list(val)
  {

  }*/

}
