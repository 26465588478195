<div class="row">
	<div class="col-md-3 ml-3">
		<div class="parentdiv" *ngIf="chatStatus && (chatStatus.slot1 != undefined) " ><!--*ngIf="IsVisible"-->
			<!--<mat-icon class="close-icon" color="warn">close</mat-icon>-->
			<div class="headerPart">
				<table width="100%">
					<tr>
						<td>
							<p style="font-size: 15px;font-weight: bold;padding: 4px 0px;margin: 0px 0px 0px 8px;">
								{{chatStatus.slot1._dataTabName}} <span style=" vertical-align: text-top;float: right;"><mat-icon style="color: red;cursor: pointer;" (click)="removeSlot(1)" >cancel</mat-icon></span></p>
							<!--<span> <img src="../assets/userImage.png"
						style="border: 1px solid #ccc;width: 30px;height: 30px;border-radius: 50%;margin-bottom: 4px;">
				</span>-->
						</td>
						<td>
							<!--<table class="tableuser">
								<tr>
									<td> <b></b> </td>
								</tr>
							</table>-->
						</td>
					</tr>
				</table>
			</div>
			<div class="bodyPart rightScroll" id="messageBody1" (scroll)="onScroll($event)" ><!--style="height: calc(100vh - 350px) !important;"-->
				<div *ngIf="chatStatus.groupList[chatStatus.slot1.index]">
					<!-- *ngIf="chatStatus.groupList[chatStatus.slot1.index]" -->
					<div class="mesgs" *ngFor="let msg of chatStatus.groupList[chatStatus.slot1.index].messages;let $msgIndex=index">
						<!-- *ngFor="let msg of chatStatus.groupList[chatStatus.slot1.index].messages;let $msgIndex=index" -->
						<div class="incoming_msg" *ngIf="myUser.id != msg.from_id">
							<div class="received_msg">
								<!-- <h4>29-11-2020</h4> -->
								<div class="received_withd_msg">
									
									<p *ngIf="msg.message_type == 'TEXT'"><span style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><span *ngIf="checkIfYoutubeLink(msg.message) == false">{{msg.message}}</span> <span *ngIf="checkIfYoutubeLink(msg.message) == true"><iframe id="player" width="100%" height="200" [src]="youtube_parser(msg.message) | safe" frameborder="0"
											allowfullscreen></iframe>
</span> <span class="time_date"> {{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
									<p *ngIf="msg.message_type == 'IMAGE'"><span style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><img src={{msg.message}} style="width: 100%;" /> <span class="time_date">
											{{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
									<p *ngIf="msg.message_type == 'DOCUMENT'"><span
											style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><a href="{{msg.message}}" download>
											<mat-icon >file_download
											</mat-icon>
										</a> <span class="time_date">
											{{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
									<p *ngIf="msg.message_type == 'AUDIO'"><span style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><audio style="width:100%" controls>
											<source src={{msg.message}} type="audio/ogg">
											<source src={{msg.message}} type="audio/mpeg">
										</audio> <span class="time_date"> {{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
								</div>
							</div>
						</div>
			
						<div class="outgoing_msg" *ngIf="myUser.id == msg.from_id"
							style="margin-top: -8px;">
							<!-- <h4>30-11-2020</h4> -->
							<div class="sent_msg">
								<p *ngIf="msg.message_type == 'TEXT'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><span *ngIf="checkIfYoutubeLink(msg.message) == false">{{msg.message}}</span> <span
										*ngIf="checkIfYoutubeLink(msg.message) == true"><iframe id="player" width="100%" height="200"
											[src]="youtube_parser(msg.message) | safe" frameborder="0" allowfullscreen></iframe>
									</span>	 <span class="time_date"> <span *ngIf="msg.to_id">
										<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
										</mat-icon>
									</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
								<p *ngIf="msg.message_type == 'IMAGE'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><img src={{msg.message}} style="width: 100%;" /> <span class="time_date">
										<span *ngIf="msg.to_id">
											<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
											</mat-icon>
										</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
									<p *ngIf="msg.message_type == 'DOCUMENT'"><span style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><a href="{{msg.message}}" download>
											<mat-icon>file_download
											</mat-icon>
										</a> <span class="time_date">
											<span *ngIf="msg.to_id">
												<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
												</mat-icon>
											</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
								<p *ngIf="msg.message_type == 'AUDIO'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><audio style="width:100%" controls>
										<source src={{msg.message}} type="audio/ogg">
										<source src={{msg.message}} type="audio/mpeg">
									</audio> <span class="time_date"> <span *ngIf="msg.to_id">
										<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
										</mat-icon>
									</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
							</div>
						</div>
			
					</div>
				</div>
			
			</div>
			
			<div class="footerPart1" >
				<!-- <span class="reqField" >Required field</span> -->
		
				<div class="row">
					<div class="col-md col-sm-6">
						<input type="text" id="textarea" (keyup)="onTextMessage1()"
							[(ngModel)]="txtmsg" class="inputtext"
							placeholder="Type a message here…" required="true"
							[disabled]="socketConnection"
							(keydown.enter)="sendMessage_With_Enter(txtmsg,chatStatus.slot1.index,1)" autocomplete="off" />
		
						<div class="image-upload" style="padding-right:185px;margin-top:-25px;cursor: pointer;" >
							<span><label for="file-input" class="fa fa-paperclip input-icon1" style="z-index: 9999;"></label></span>
							<input id="file-input" type="file" (change)="changeFile($event,1)" file-model="myFile" />
						</div>
					</div>
					<div class="col-md-1  col-sm-2 image-upload" style="right: 57px;bottom: -11px;">
						<!-- original code make like this -->
						<!-- <label for="file-input" class="fa fa-microphone input-icon1"></label> -->

						<!-- play pause code -->
						<p class="text-center" *ngIf="showHideStartMic1" style="margin-left: -2px;cursor: pointer;"><span style="cursor: pointer;">
								<mat-icon class="micCs"  (click)="startRecordingBtn(1)">mic</mat-icon>
							</span> </p>
						<p class="text-center" *ngIf="showHideStopMic1" style="margin-left: -2px;"><span style="cursor: pointer;">
								<mat-icon class="micCs"  style="color: #d7392d;border: 2px solid #d7392d;"
									(click)="stopRecordingBtn(1)">mic</mat-icon>
							</span> </p>
						<!--<button class="btncs" id="send" style="right:30px;background-color: #3fb90d;" (click)="endSession()"><i
					class="fa fa-times" aria-hidden="true"></i></button>-->
					</div>
					<div class="col-md-1  col-sm-2">
						<button class="btncs" style="right:23px;background-color: #3fb90d;"
							id="send" (click)="sendMessage_With_Enter(txtmsg,chatStatus.slot1.index,1)"><i class="fa fa-send"
								aria-hidden="true"></i></button>
					</div>
				</div>
		
		
			</div>
		</div>
	</div>
	<div class="col-md-3 ml-3">
		<div class="parentdiv"
			*ngIf="chatStatus && (chatStatus.slot2 != undefined) ">
			<!--*ngIf="IsVisible"-->
			<!--<mat-icon class="close-icon" color="warn">close</mat-icon>-->
			<div class="headerPart">
				<table width="100%">
					<tr>
						<td>
							<p style="font-size: 15px;font-weight: bold;padding: 4px 0px;margin: 0px 0px 0px 8px;">
								{{chatStatus.slot2._dataTabName}}<span style=" vertical-align: text-top;float: right;">
									<mat-icon style="color: red;cursor: pointer;" (click)="removeSlot(2)">cancel</mat-icon>
								</span></p>
							<!--<span> <img src="../assets/userImage.png"
							style="border: 1px solid #ccc;width: 30px;height: 30px;border-radius: 50%;margin-bottom: 4px;">
					</span>-->
						</td>
						<td>
							<!-- <table class="tableuser">
								<tr>
									<td> <b></b> </td>
								</tr>
							</table> -->
						</td>
					</tr>
				</table>
			</div>
			<div class="bodyPart rightScroll" id="messageBody2" (scroll)="onScroll2($event)"><!--style="height: calc(100vh - 350px) !important;"-->
				<div *ngIf="chatStatus.groupList[chatStatus.slot2.index]">
					<!-- *ngIf="chatStatus.groupList[chatStatus.slot2.index]" -->
					<div class="mesgs"
						*ngFor="let msg of chatStatus.groupList[chatStatus.slot2.index].messages;let $msgIndex=index">
						<!-- *ngFor="let msg of chatStatus.groupList[chatStatus.slot2.index].messages;let $msgIndex=index" -->
						<div class="incoming_msg" *ngIf="myUser.id != msg.from_id">
							<div class="received_msg">
								<!-- <h4>29-11-2020</h4> -->
								<div class="received_withd_msg">
									<p *ngIf="msg.message_type == 'TEXT'"><span
											style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><span *ngIf="checkIfYoutubeLink(msg.message) == false">{{msg.message}}</span> <span
											*ngIf="checkIfYoutubeLink(msg.message) == true"><iframe id="player" width="100%" height="200"
												[src]="youtube_parser(msg.message) | safe" frameborder="0" allowfullscreen></iframe>
										</span><span class="time_date"> {{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
									<p *ngIf="msg.message_type == 'IMAGE'"><span
											style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><img src={{msg.message}} style="width: 100%;" /> <span class="time_date">
											{{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
										<p *ngIf="msg.message_type == 'DOCUMENT'"><span style="font-size:11px;display:block;color:black">
												{{msg.user == 'Server' ? msg.to_id : msg.user}}
											</span><a href="{{msg.message}}" download>
												<mat-icon>file_download
												</mat-icon>
											</a> <span class="time_date">
												{{msg.date| date:'dd-MM-yyyy h:mm a'}}
											</span></p>
									<p *ngIf="msg.message_type == 'AUDIO'"><span
											style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><audio style="width:100%" controls>
											<source src={{msg.message}} type="audio/ogg">
											<source src={{msg.message}} type="audio/mpeg">
										</audio> <span class="time_date"> {{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
								</div>
							</div>
						</div>
	
						<div class="outgoing_msg" *ngIf="myUser.id == msg.from_id"
							style="margin-top: -8px;">
							<!-- <h4>30-11-2020</h4> -->
							<div class="sent_msg">
								<p *ngIf="msg.message_type == 'TEXT'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><span *ngIf="checkIfYoutubeLink(msg.message) == false">{{msg.message}}</span> <span
										*ngIf="checkIfYoutubeLink(msg.message) == true"><iframe id="player" width="100%" height="200"
											[src]="youtube_parser(msg.message) | safe" frameborder="0" allowfullscreen></iframe>
									</span> <span class="time_date"> <span *ngIf="msg.to_id">
										<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
										</mat-icon>
									</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
								<p *ngIf="msg.message_type == 'IMAGE'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><img src={{msg.message}} style="width: 100%;" /> <span class="time_date">
										<span *ngIf="msg.to_id">
											<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
											</mat-icon>
										</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
									<p *ngIf="msg.message_type == 'DOCUMENT'"><span style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><a href="{{msg.message}}" download>
											<mat-icon>file_download
											</mat-icon>
										</a> <span class="time_date">
											<span *ngIf="msg.to_id">
												<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
												</mat-icon>
											</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
								<p *ngIf="msg.message_type == 'AUDIO'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><audio style="width:100%" controls>
										<source src={{msg.message}} type="audio/ogg">
										<source src={{msg.message}} type="audio/mpeg">
									</audio> <span class="time_date"> <span *ngIf="msg.to_id">
										<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
										</mat-icon>
									</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
							</div>
						</div>
	
					</div>
				</div>
	
			</div>
	
			<div class="footerPart1">
				<!-- <span class="reqField" >Required field</span> -->
			
				<div class="row">
					<div class="col-md col-sm-6">
						<input type="text" id="textarea" (keyup)="onTextMessage2()" [(ngModel)]="txtmsg2" class="inputtext"
							placeholder="Type a message here…" required="true" [disabled]="socketConnection"
							(keydown.enter)="sendMessage_With_Enter(txtmsg2,chatStatus.slot2.index,2)" autocomplete="off" />
			
						<div class="image-upload" style="padding-right:185px;margin-top:-25px;cursor: pointer;">
							<span><label for="file-input" class="fa fa-paperclip input-icon1" style="z-index: 9999;"></label></span>
							<input id="file-input" type="file" (change)="changeFile($event,2)" file-model="myFile" />
						</div>
					</div>
					<div class="col-md-1  col-sm-2 image-upload" style="right: 57px;bottom: -11px;">
						<!-- original code make like this -->
						<!-- <label for="file-input" class="fa fa-microphone input-icon1"></label> -->
			
						<!-- play pause code -->
						<p class="text-center" *ngIf="showHideStartMic2" style="margin-left: -2px;cursor: pointer;"><span
								style="cursor: pointer;">
								<mat-icon class="micCs" (click)="startRecordingBtn(2)">mic</mat-icon>
							</span> </p>
						<p class="text-center" *ngIf="showHideStopMic2" style="margin-left: -2px;"><span style="cursor: pointer;">
								<mat-icon class="micCs" style="color: #d7392d;border: 2px solid #d7392d;"
									(click)="stopRecordingBtn(2)">mic</mat-icon>
							</span> </p>
						<!--<button class="btncs" id="send" style="right:30px;background-color: #3fb90d;" (click)="endSession()"><i
								class="fa fa-times" aria-hidden="true"></i></button>-->
					</div>
					<div class="col-md-1  col-sm-2">
						<button class="btncs" style="right:23px;background-color: #3fb90d;" id="send"
							(click)="sendMessage_With_Enter(txtmsg2,chatStatus.slot2.index,2)"><i class="fa fa-send"
								aria-hidden="true"></i></button>
					</div>
				</div>
			
			
			</div>
		</div>
	</div>
	<div class="col-md-3 ml-3">
		<div class="parentdiv" *ngIf="chatStatus && (chatStatus.slot3 != undefined) ">
			<!--*ngIf="IsVisible"-->
			<!--<mat-icon class="close-icon" color="warn">close</mat-icon>-->
			<div class="headerPart">
				<table width="100%">
					<tr>
						<td>
							<p style="font-size: 15px;font-weight: bold;padding: 4px 0px;margin: 0px 0px 0px 8px;">
								{{chatStatus.slot3._dataTabName}} <span style=" vertical-align: text-top;float: right;">
									<mat-icon style="color: red;cursor: pointer;" (click)="removeSlot(3)">cancel</mat-icon>
								</span></p>
							<!--<span> <img src="../assets/userImage.png"
								style="border: 1px solid #ccc;width: 30px;height: 30px;border-radius: 50%;margin-bottom: 4px;">
						</span>-->
						</td>
						<td>
							<!-- <table class="tableuser">
								<tr>
									<td> <b>{{chatStatus.slot3._dataTabName}}</b> </td>
								</tr>
							</table> -->
						</td>
					</tr>
				</table>
			</div>
			<div class="bodyPart rightScroll" id="messageBody3" (scroll)="onScroll3($event)"><!--style="height: calc(100vh - 350px) !important;"-->
				<div *ngIf="chatStatus.groupList[chatStatus.slot3.index]">
					<!-- *ngIf="chatStatus.groupList[chatStatus.slot3.index]" -->
					<div class="mesgs"
						*ngFor="let msg of chatStatus.groupList[chatStatus.slot3.index].messages;let $msgIndex=index">
						<!-- *ngFor="let msg of chatStatus.groupList[chatStatus.slot3.index].messages;let $msgIndex=index" -->
						<div class="incoming_msg" *ngIf="myUser.id != msg.from_id">
							<div class="received_msg">
								<!-- <h4>29-11-2020</h4> -->
								<div class="received_withd_msg">
									<p *ngIf="msg.message_type == 'TEXT'"><span
											style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><span *ngIf="checkIfYoutubeLink(msg.message) == false">{{msg.message}}</span> <span
											*ngIf="checkIfYoutubeLink(msg.message) == true"><iframe id="player" width="100%" height="200"
												[src]="youtube_parser(msg.message) | safe" frameborder="0" allowfullscreen></iframe>
										</span> <span class="time_date"> {{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
									<p *ngIf="msg.message_type == 'IMAGE'"><span
											style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><img src={{msg.message}} style="width: 100%;" /> <span class="time_date">
											{{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
										<p *ngIf="msg.message_type == 'DOCUMENT'"><span style="font-size:11px;display:block;color:black">
												{{msg.user == 'Server' ? msg.to_id : msg.user}}
											</span><a href="{{msg.message}}" download>
												<mat-icon>file_download
												</mat-icon>
											</a> <span class="time_date">
												{{msg.date| date:'dd-MM-yyyy h:mm a'}}
											</span></p>
									<p *ngIf="msg.message_type == 'AUDIO'"><span
											style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><audio style="width:100%" controls>
											<source src={{msg.message}} type="audio/ogg">
											<source src={{msg.message}} type="audio/mpeg">
										</audio> <span class="time_date"> {{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
								</div>
							</div>
						</div>
	
						<div class="outgoing_msg" *ngIf="myUser.id == msg.from_id"
							style="margin-top: -8px;">
							<!-- <h4>30-11-2020</h4> -->
							<div class="sent_msg">
								<p *ngIf="msg.message_type == 'TEXT'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><span *ngIf="checkIfYoutubeLink(msg.message) == false">{{msg.message}}</span> <span
										*ngIf="checkIfYoutubeLink(msg.message) == true"><iframe id="player" width="100%" height="200"
											[src]="youtube_parser(msg.message) | safe" frameborder="0" allowfullscreen></iframe>
									</span> <span class="time_date"> <span *ngIf="msg.to_id">
										<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
										</mat-icon>
									</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
								<p *ngIf="msg.message_type == 'IMAGE'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><img src={{msg.message}} style="width: 100%;" /> <span class="time_date">
										<span *ngIf="msg.to_id">
											<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
											</mat-icon>
										</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
									<p *ngIf="msg.message_type == 'DOCUMENT'"><span style="font-size:11px;display:block;color:black">
											{{msg.user == 'Server' ? msg.to_id : msg.user}}
										</span><a href="{{msg.message}}" download>
											<mat-icon>file_download
											</mat-icon>
										</a> <span class="time_date">
											<span *ngIf="msg.to_id">
												<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
												</mat-icon>
											</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
										</span></p>
								<p *ngIf="msg.message_type == 'AUDIO'"><span style="font-size:11px;display:block;color:black">
										{{msg.user == 'Server' ? msg.to_id : msg.user}}
									</span><audio style="width:100%" controls>
										<source src={{msg.message}} type="audio/ogg">
										<source src={{msg.message}} type="audio/mpeg">
									</audio> <span class="time_date"> <span *ngIf="msg.to_id">
										<mat-icon [ngStyle]="{'color': checkSeenOutgoing(msg) }" style="vertical-align: middle; margin-right: 5px;">done_all
										</mat-icon>
									</span>{{msg.date| date:'dd-MM-yyyy h:mm a'}}
									</span></p>
							</div>
						</div>
	
					</div>
				</div>
	
			</div>
	
			<div class="footerPart1">
				<!-- <span class="reqField" >Required field</span> -->
			
				<div class="row">
					<div class="col-md col-sm-6">
						<input type="text" id="textarea" (keyup)="onTextMessage3()" [(ngModel)]="txtmsg3" class="inputtext"
							placeholder="Type a message here…" required="true" [disabled]="socketConnection"
							(keydown.enter)="sendMessage_With_Enter(txtmsg3,chatStatus.slot3.index,3)" autocomplete="off" />
			
						<div class="image-upload" style="padding-right:185px;margin-top:-25px;cursor: pointer;">
							<span><label for="file-input" class="fa fa-paperclip input-icon1" style="z-index: 9999;"></label></span>
							<input id="file-input" type="file" (change)="changeFile($event,3)" file-model="myFile" />
						</div>
					</div>
					<div class="col-md-1  col-sm-2 image-upload" style="right: 57px;bottom: -11px;">
						<!-- original code make like this -->
						<!-- <label for="file-input" class="fa fa-microphone input-icon1"></label> -->
			
						<!-- play pause code -->
						<p class="text-center" *ngIf="showHideStartMic3" style="margin-left: -2px;cursor: pointer;"><span
								style="cursor: pointer;">
								<mat-icon class="micCs" (click)="startRecordingBtn(3)">mic</mat-icon>
							</span> </p>
						<p class="text-center" *ngIf="showHideStopMic3" style="margin-left: -2px;"><span style="cursor: pointer;">
								<mat-icon class="micCs" style="color: #d7392d;border: 2px solid #d7392d;"
									(click)="stopRecordingBtn(3)">mic</mat-icon>
							</span> </p>
						<!--<button class="btncs" id="send" style="right:30px;background-color: #3fb90d;" (click)="endSession()"><i
								class="fa fa-times" aria-hidden="true"></i></button>-->
					</div>
					<div class="col-md-1  col-sm-2">
						<button class="btncs" style="right:23px;background-color: #3fb90d;" id="send"
							(click)="sendMessage_With_Enter(txtmsg3,chatStatus.slot3.index,3)"><i class="fa fa-send"
								aria-hidden="true"></i></button>
					</div>
				</div>
			
			
			</div>
		</div>
	</div>
</div>

<div *ngIf="socketConnection" class="interNetMesg" id="closeInternetMsg">
	<p><span class="warn-cs"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
		No internet connection.<br>
		Kindly check your internet connectivity</p>
</div>

<div id="myModal" class="modalLarge">
	<span class="closeLarge" (click)="closeLarge()">&times;</span>
	<img class="modal-large-content" id="img01">
</div>