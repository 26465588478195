<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu>


	</mat-drawer>
<router-outlet *ngIf="show_details"></router-outlet>
<div class="row" style="background: #f6f6f6;">

    <div class="col-md-12">

<div id="main_data" *ngIf="!show_details" style="padding: 80px 22px 50px 26px;">

	<div class="row">
		<div class="col-md-12">
		  <h4 class="titlehead" style="margin-top: 0%;padding-left: 10px;position: relative;top: -15px;z-index: 9;">Campaign Dashboard</h4>
		  </div>
	 </div>

	 <div class="row">
		<div class="col-md-3" style="padding-right: 0;">
			<div class="card">
				<div class="card-header">Campaigns</div>
				<div class="card-body">
					<table class="Cam-table">
						<tr><td><span>Data assigned</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="summary.total.data_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData1"><span>{{summary.total.data_percent}}% ({{ summary.total.data }})</span></td>
						</tr>
						<tr><td><span>Data Dialled</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="summary.total.to_call_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData2"><span>{{summary.total.to_call_percent}}% ({{ summary.total.to_call }})</span>
						  </td>
						</tr>
						<tr>
						  <td><span>Data Contacted</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="summary.total.called_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData3"><span>{{summary.total.called_percent}}% ({{summary.total.called}})</span>
						  </td>
						</tr>
					  </table>


					<!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div><hr class="level1"
										[style.width.%]="summary.total.data_percent" /></div></td>
							<td><div class="show_count">{{summary.total.data_percent}}% <span class="value_count">({{ summary.total.data }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Dialled</div><hr class="level2"
										[style.width.%]="summary.total.to_call_percent" /></div></td>
							<td><div class="show_count">{{summary.total.to_call_percent}}% <span class="value_count">({{ summary.total.to_call }})</span></div>	</td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Contacted</div><hr class="level3" [style.width.%]="summary.total.called_percent" /></div></td>
							<td><div class="show_count">{{summary.total.called_percent}}% <span class="value_count">({{ summary.total.called }})</span>	</div>
							</td>
						</tr>
					</table> -->
				</div>
			</div>
		</div>
		<div class="col-md-3"  style="padding-right: 0;">
			<div class="card">

				<div class="card-header">Total Active Campaigns
				</div>
				<div class="card-body">
					<table class="Cam-table">
						<tr><td><span>Data assigned</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="summary.active.data_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData1"><span>{{summary.active.data_percent}}% ({{ summary.active.data }})</span></td>
						</tr>
						<tr><td><span>Data Dialled</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="summary.active.to_call_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData2"><span>{{summary.active.to_call_percent}}% ({{ summary.active.to_call }})</span>
						  </td>
						</tr>
						<tr>
						  <td><span>Data Contacted</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="summary.active.called_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData3"><span>{{summary.active.called_percent}}% ({{summary.active.called}})</span>
						  </td>
						</tr>
					  </table>



					<!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div><hr class="level1" [style.width.%]="summary.active.data_percent" /></div></td>
							<td><div class="show_count">{{summary.active.data_percent}}% <span	class="value_count">({{ summary.active.data }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Dialled</div><hr class="level2"	[style.width.%]="summary.active.to_call_percent" />	</div>	</td>
							<td><div class="show_count">{{summary.active.to_call_percent}}% <span class="value_count">({{ summary.active.to_call }})</span>	</div>	</td>
						</tr>
						<tr><td><div class="show_bar">	<div>Data Contacted</div><hr class="level3"
										[style.width.%]="summary.active.called_percent" /></div></td>
							<td><div class="show_count">{{summary.active.called_percent}}% <span
										class="value_count">({{ summary.active.called }})</span>
								</div>
							</td>
						</tr>
					</table> -->
				</div>
			</div>
		</div>
		<div class="col-md-3"  style="padding-right: 0;">
			<div class="card">
				<div class="card-header">Total InActive Campaigns
				</div>
				<div class="card-body">

					<table class="Cam-table">
						<tr><td><span>Data assigned</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="summary.inactive.data_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData1"><span>{{summary.inactive.data_percent}}% ({{summary.inactive.data }})</span></td>
						</tr>
						<tr><td><span>Data Dialled</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="summary.inactive.to_call_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData2"><span>{{summary.inactive.to_call_percent}}% ({{ summary.inactive.to_call }})</span>
						  </td>
						</tr>
						<tr>
						  <td><span>Data Contacted</span>
							<mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="summary.inactive.called_percent"></mat-progress-bar>
						  </td>
						  <td class="tdData3"><span>{{summary.inactive.called_percent}}% ({{summary.inactive.called}})</span>
						  </td>
						</tr>
					  </table>






					<!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div>
									<hr class="level1"	[style.width.%]="summary.inactive.data_percent" />	</div>	</td>
							<td><div class="show_count">{{summary.inactive.data_percent}}% <span
										class="value_count">({{ summary.inactive.data }})</span></div>	</td>
						</tr>
						<tr><td><div class="show_bar">
									<div>Data Dialled</div><hr class="level2" [style.width.%]="summary.inactive.to_call_percent" /></div></td>
							<td><div class="show_count">	{{summary.inactive.to_call_percent}}% <span
										class="value_count">({{ summary.inactive.to_call }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Contacted</div>	<hr class="level3"	[style.width.%]="summary.inactive.called_percent" /></div>	</td>
							<td><div class="show_count">
									{{summary.inactive.called_percent}}% <span
										class="value_count">({{ summary.inactive.called }})</span>
								</div>
							</td>
						</tr>
					</table> -->
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<div class="card">
				<div class="card-header" *ngIf="this.user.groupid == 81419">Channels</div>
				<div class="card-header" *ngIf="this.user.groupid != 81419">Data Sources</div>
				<div class="card-body scroll">
					<table class="tablecs">
						<thead *ngIf="this.user.groupid == 81419">
							<tr>
								<th class="data_source_headers">Channel</th>
								<th class="data_source_headers">Clicks</th>
								<th class="data_source_headers">Throughput</th>
							</tr>
						</thead>
						<thead *ngIf="this.user.groupid != 81419">
							<tr>
								<th class="data_source_headers">Source</th>
								<th class="data_source_headers">Data</th>
								<th class="data_source_headers">Utilized</th>
							</tr>
						</thead>
						<tbody *ngIf="this.user.groupid == 81419">

							<tr *ngFor="let key of getJsonKeys(summary.channels)">
								<td class="data_source_campaign">{{ key }}</td>
								<td class="data_source_data">
									{{  summary.channels[key].clicks }}</td>
								<td class="numeric_threshold"
									[class]="numericThresholdBycolor(summary.channels[key].throughput)">
									{{  summary.channels[key].throughput }}</td>
							</tr>
						</tbody>
						<tbody *ngIf="this.user.groupid != 81419">
							<tr *ngFor="let campaign of getJsonKeys(summary.data_source)">
								<td class="data_source_campaign">{{ campaign }}</td>
								<td class="data_source_data">
									{{ summary.data_source[campaign].data }}</td>
								<td class="numeric_threshold"
									[class]="numericThresholdBycolor(summary.data_source[campaign].called_percent)">
									{{ summary.data_source[campaign].called_percent }}%</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>


<br>
<mat-card>
	<p class="textAlertMessage" style="font-size: 18px;" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
	<div class="row">
		<div class="col-md-12">
			<div class="pDiv1">
				<div class="divprintRt">
					<span class="recName" *ngIf="showCampaign" style="top: 0px;">
						Campaign Summary
					</span>
					<span class="datecs">{{ today }}</span>
					<span class="year_dropdown">
						<mat-form-field appearance="outline" [ngClass]="'selectOutlineCss'">
							<mat-label>Select Month</mat-label>
							<mat-select style="padding-top: 3px;" [value]="selected_month.value"
								(selectionChange)="change_selectedMonth($event)">
								<mat-option *ngFor="let month of months_filter" [value]="month.value">
									{{month.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</span>

					<span style="padding-left: 12px;" (click)="refreshCampaignData()"><button
							mat-button>Go</button></span>

					<span class="createbtn" *ngIf="showCampaign">
						<!-- <button mat-button (click)="openCreateCampaign()" [disabled]="isCampbtnDisable">Create
							campaign
							+</button> -->
							<button mat-button (click)="createCampaignPage()" [disabled]="isCampbtnDisable">Create
								campaign
								+</button>
					</span>
					<span class="createbtn" *ngIf="showCampaign">
						<button mat-button (click)="downloadAttachment()">
							<mat-icon class="mr-2 v-back-ico">arrow_downward</mat-icon><!--[disabled]="isCampbtnDisable"-->
						</button>
				   </span>
					<span class="searchSpan">
						<mat-form-field>
							<input matInput #_searchValue (keyup)="applyFilter($event.target.value)" placeholder="Search">
							<i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
						</mat-form-field>
					</span>

				</div>
			</div>
			<br>
			<div class="pDiv1">
				<mat-table [dataSource]="dataSourceCampaign" class="cTable" style="width: 100%;">
					<ng-container matColumnDef="sl_no">
						<mat-header-cell *matHeaderCellDef> SNo </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.sl_no}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="campaign_name">
						<mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
						<mat-cell *matCellDef="let element" class="campaign_name" (click)="actionViewMore(element)"	> {{element.campaign_name}} </mat-cell>
							<!-- (click)="openCampaignDetails(element)" -->
					</ng-container>
					<ng-container matColumnDef="camapign_owner">
						<mat-header-cell *matHeaderCellDef> Owner </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.camapign_owner}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="incallscreen">
						<mat-header-cell *matHeaderCellDef> Campaign Type </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.incallscreen}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="work_type">
						<mat-header-cell *matHeaderCellDef> Work Type </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.work_type}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="campaign_status">
						<mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
						<mat-cell *matCellDef="let element">
							<mat-slide-toggle *ngIf="check_val_type(element.campaign_status) == 'boolean'"
								[(ngModel)]="element.campaign_status"
								(change)="campaignStatusChange($event,element)" [disabled]="conditonfordisable(element)"> <!-- [disabled]="element.user_id != user.id ? true: false"-->
							</mat-slide-toggle>
							<button mat-button color="accent" style="height: 35px;" *ngIf="(check_val_type(element.campaign_status) == 'string') && (element.campaign_status == 'uploaded')" (click)="AssignNow(element)" color="accent"><p style="font-size:smaller">Assign</p></button>
							<span *ngIf="(check_val_type(element.campaign_status) == 'string') && (element.campaign_status == 'assigning')"
								style="color:  #02360f;text-transform: capitalize;">{{ element.campaign_status }}</span>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="campaign_created">
						<mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.campaign_created}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="last_updated">
						<mat-header-cell *matHeaderCellDef> Last Refreshed </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.last_updated}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="data_uploaded">
						<mat-header-cell *matHeaderCellDef> Uploaded </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.data_uploaded}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="data_duplicate">
						<mat-header-cell *matHeaderCellDef> Duplicate </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.data_duplicate}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="errors">
						<mat-header-cell *matHeaderCellDef> Errors </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.errors}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="agents_count">
						<mat-header-cell *matHeaderCellDef> Agents </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.agents_count}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="data_added">
						<mat-header-cell *matHeaderCellDef> Assigned </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.data_added}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="data_dialled">
						<mat-header-cell *matHeaderCellDef> Dialled </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.data_dialled}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="contacted">
						<mat-header-cell *matHeaderCellDef> Contacted </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.contacted}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="rechurn">
						<mat-header-cell *matHeaderCellDef> Rechurn </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.rechurn || element.user_id}} </mat-cell>
					</ng-container>
					<!-- <ng-container matColumnDef="data_source">
						<mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.data_source}}&ensp; </mat-cell>
					</ng-container> -->
					<ng-container matColumnDef="actionViewMore">
						<mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
						<mat-cell *matCellDef="let element">
							<span class="v-actionViewMore" (click)="actionViewMore(element)">View More</span>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="downloadReports">
						<mat-header-cell *matHeaderCellDef>Reports </mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button style="white-space: nowrap;
							background: white;
							color: #fd6c21;
							font-size: 13px;
							border: 1px solid #ccc;
							border-radius: 4px;
							padding: 5px;position:relative" class="download_btn"
								[matMenuTriggerFor]="menu" [disabled]="element.showLoader">
								Download&nbsp;
								<i class="fa fa-angle-down" *ngIf="!element.showLoader"></i>
								<i class="fas fa-sync-alt fa-spin" *ngIf="element.showLoader"></i>
							</button>
							<mat-menu #menu="matMenu">
								<!-- <button mat-menu-item (click)="addmore(element)">Add
									Data</button> -->

								<button mat-menu-item (click)="downloadCmail_report(element)">Call Status
									Report</button>
								<!-- <button mat-menu-item (click)="downloadCmail_report(element,true)">Customer Status
									Report</button> -->
								<button mat-menu-item (click)="downloadCampaignStatusReport(element)">Campaign Status
									Report</button>
								<button mat-menu-item  *ngIf="check_rsmzsm()" (click)="downloadMasterCampaignStatusreport(element)">{{element.data_source}} - Master Campaign Status Report</button>
								<button mat-menu-item  *ngIf="check_rsmzsm()" (click)="downloadMasterCampaigncallStatusreport(element)">{{element.data_source}} - Master Campaign Call Status Report</button>
								<button mat-menu-item *ngIf="checkOwnership(element)" (click)="downloadDataSheet(element)">Data Sheet</button>
							</mat-menu>
						</mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef=displayedColumnsCampaigan></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumnsCampaigan" [ngClass]="row.user_id != user.id ? 'highlightRowCls':'highlightRowCls2' "></mat-row>
					<!--  -->
				</mat-table>
				<mat-paginator #campaignpaginator [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50]" (page)="pageEvents($event)" showFirstLastButtons>
				</mat-paginator>
			</div>
		  </div>
	 </div>
	</mat-card>



</div>

</div>
</div>
<div id="cover-spin" *ngIf="showLoader"></div>
</mat-drawer-container>
