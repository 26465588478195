import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";

@Injectable({providedIn: "root"})

export class FileDownloaderService {
  url: string = "https://www.contiinex.com/assets/ringtone/Phone_Ringtone.mp3";

  constructor(private http: HttpClient) {}

  download() {
    console.log('Speed test download service ccalling')
    const req = new HttpRequest("GET", this.url, {
      responseType: "blob",
      reportProgress: true
    });
    return this.http.request(req);
  }
}