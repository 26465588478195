
<br>
<div class="row">

<div class="col-lg-12 col-md-12">

<div>
  <ngb-alert [type]="alert.type" class="ng-alert-dialog" *ngIf="!alertClosed" (close)="alertClosed = true">
    {{ alert.message }}</ngb-alert>

  <div class="row">



	  <!-- This is not required for manual dialling -->


    <div class="col-md-4 col-sm-12 col-xs-12 pr-3" style="padding-right: 0 !important;" >
      <mat-card>
        <mat-card-header>
          <mat-card-title>Follow ups</mat-card-title>
        </mat-card-header>
        <mat-card-content>


          <table class="Cam-table">
            <tr><td><span>Total</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="this.dashSummary.followup_perc"></mat-progress-bar>
              </td>
              <td class="tdData1"><span>{{this.dashSummary.followup_perc}}% ({{this.dashSummary.followup}})</span></td>
            </tr>
            <tr><td><span>Completed</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="this.dashSummary.followupdone_perc"></mat-progress-bar>
              </td>
              <td class="tdData2"><span>{{this.dashSummary.followupdone_perc}}% ({{this.dashSummary.followupdone}})</span>
              </td>
            </tr>
            <tr>
              <td><span>ToDo</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="this.dashSummary.followuptodo_perc"></mat-progress-bar>
              </td>
              <td class="tdData3"><span>{{this.dashSummary.followuptodo_perc}}% ({{this.dashSummary.followuptodo}})</span>
              </td>
            </tr>
          </table>

        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12 pr-3" style="padding-right: 0 !important;" >
      <mat-card>
        <mat-card-header>
          <mat-card-title>Agents Status</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Total Agents</span>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.dashData.length}}</span>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Today Logged IN</span>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-2">
              <span
                class="tile-percent-primary pr-2">{{this.agentCallingStatus.login}}</span>
            </div>
		  </div>
		  <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agent Active Today</span>
            </div>
            <div class="col-md-2">
              <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#33EB1A', border: 'none' }"></span> -->
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.active}}</span>
            </div>
		  </div>
          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agent On Call</span>
            </div>
            <div class="col-md-2">
              <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#33EB1A', border: 'none' }"></span> -->
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.on_call}}</span>
            </div>
		  </div>

          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agent OnBreak</span>
            </div>
            <div class="col-md-2">
              <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#FF0000', border: 'none' }"></span> -->
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.break }}</span>
            </div>
          </div>

          <div class="row py-2">
            <div class="col-md-8">
              <span class="tile-percent">Agents Logged Out</span>
            </div>
            <div class="col-md-2">
              <!-- <span class="statusCircle" [ngStyle]="{'background-color':'#D3BA80', border: 'none' }"></span> -->
            </div>
            <div class="col-md-2">
              <span class="tile-percent-primary pr-2">{{this.agentCallingStatus.logout }}</span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12"   *ngIf="showHideNewDataChannelSummary">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <!-- Total Calls -  -->
            Customer Calls</mat-card-title>
          <button mat-icon-button aria-label="Open in new tab" class="feedback_ext"
            matTooltip="Detailed Form Dispositions" matTooltipPosition="before" (click)="openFeedback()">
            <mat-icon style="color:#000000">open_in_new</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>

          <table class="Cam-table">
            <tr><td><span>Calls Placed</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="this.dashSummary.calls_dialled_perc"></mat-progress-bar>
              </td>
              <td class="tdData1"><span>{{this.dashSummary.calls_dialled_perc}}% ({{this.dashSummary.calls_dialled}})</span></td>
            </tr>
            <tr><td><span>RNR</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="this.dashSummary.calls_rnr_perc"></mat-progress-bar>
              </td>
              <td class="tdData2"><span>{{this.dashSummary.calls_rnr_perc}}% ({{this.dashSummary.calls_rnr}})</span>
              </td>
            </tr>
            <tr>
              <td><span>Contacted</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="this.dashSummary.calls_contacted_perc"></mat-progress-bar>
              </td>
              <td class="tdData3"><span>{{this.dashSummary.calls_contacted_perc}}% ({{this.dashSummary.calls_contacted}})</span>
              </td>
            </tr>
          </table>

        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12"   *ngIf="!showHideNewDataChannelSummary">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Total Calls</mat-card-title>
          <button mat-icon-button aria-label="Open in new tab" class="feedback_ext"
            matTooltip="Detailed Form Dispositions" matTooltipPosition="before" (click)="openFeedback()">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>

          <table class="Cam-table">
            <tr><td><span>Dialled</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="this.dashSummary.calls_dialled_perc"></mat-progress-bar>
              </td>
              <td class="tdData1"><span>{{this.dashSummary.calls_dialled_perc}} ({{this.dashSummary.calls_dialled}})</span></td>
            </tr>
            <tr><td><span>RNR</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="this.dashSummary.calls_rnr_perc"></mat-progress-bar>
              </td>
              <td class="tdData2"><span>{{this.dashSummary.calls_rnr_perc}}% ({{this.dashSummary.calls_rnr}})</span>
              </td>
            </tr>
            <tr>
              <td><span>Contacted</span>
                <mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="this.dashSummary.calls_contacted_perc"></mat-progress-bar>
              </td>
              <td class="tdData3"><span>{{this.dashSummary.calls_contacted_perc}}% ({{this.dashSummary.calls_contacted}})</span>
              </td>
            </tr>
          </table>


        </mat-card-content>
      </mat-card>
    </div>

  </div>

  <div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12 pr-3" style="padding-right: 0 !important;" >
      <mat-card style="margin-top: 24px; min-height: 332px;">
        <mat-card-header>
          <mat-card-title>Calls Overview</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">

            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div style="min-width: 240px; height: 240px; max-width: 240px;"
                   [chart]="donutChartOutbound">
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-4 text-right">
              <p class="CN-Select">{{this.dashSummary.calls_contacted}}<br> <span class="cn-sub-title">Connected</span> </p>
              <p class="CN-Missed">{{this.dashSummary.calls_rnr}}<br> <span class="cn-sub-title">Missed</span> </p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
      <mat-card class="mat-card-table-responsive call-summary" style="padding: 0px !important;">
        <mat-card-header>
          <mat-card-title>Connected Vs Missed</mat-card-title>
        </mat-card-header>
        <mat-card-content>
        <div [chart]="totalAnsMissedChart"> </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="md-12 sm-12 xs-12">
    <mat-card class="mat-card-table-responsive call-summary" style="padding: 0px !important;">
      <table mat-table [dataSource]="dataSourceCallSummary" class="mat-elevation-z8">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type </th>
          <td mat-cell *matCellDef="let element" class="CR">{{element.type}} </td>
        </ng-container>


        <ng-container matColumnDef="login_time">
          <th mat-header-cell *matHeaderCellDef> Login Hours </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.login_time}} </td>
        </ng-container>

        <ng-container matColumnDef="spoken_time">
          <th mat-header-cell *matHeaderCellDef> Talk Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.spoken_time}} </td>
        </ng-container>

        <ng-container matColumnDef="wrap_time">
          <th mat-header-cell *matHeaderCellDef> Wrap Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.wrap_time}} </td>
        </ng-container>

        <ng-container matColumnDef="wait_time">
          <th mat-header-cell *matHeaderCellDef> Dial Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.wait_time}} </td>
        </ng-container>

        <ng-container matColumnDef="idle_time">
          <th mat-header-cell *matHeaderCellDef> Idle Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.idle_time}} </td>
        </ng-container>

        <ng-container matColumnDef="break_time">
          <th mat-header-cell *matHeaderCellDef> Break Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.break_time}} </td>
        </ng-container>

        <ng-container matColumnDef="unavailable_time">
          <th mat-header-cell *matHeaderCellDef> Unavailable Time </th>
          <td mat-cell *matCellDef="let element" class="CR"> {{element.unavailable_time}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsCallSummary;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCallSummary;"></tr>
      </table>
    </mat-card>
  </div>

  <div class="md-12 sm-12 xs-12">
    <mat-card class="mat-card-table-responsive">
      <p class="textAlertMessage" style="font-size: 18px;" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
      <div class="row pdt filter-div  align-items-center">
        <div class="col-md-2">
          <!-- <span class="date-label">10-Jun-2020</span> -->
          <button mat-button [matMenuTriggerFor]="export_menu" aria-label="Example icon-button with a menu" style="background: #dd133c !important;">
            Reports
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #export_menu="matMenu">
            <!-- <button mat-menu-item (click)="downloadReportGeneral()">Call Report</button> -->
            <button mat-menu-item (click)="downloadReportGeneral()">Call Report</button>
            <button mat-menu-item (click)="downloadAuditReport()">Audit Report</button>
            <button mat-menu-item (click)="agentSummaryReport()">Agent Summary Report</button>
            <button mat-menu-item (click)="exportAgentActivity()">Agent Activity Report</button>
          </mat-menu>
		</div>
		<!-- Sort by activity starts -->
		<div class="col-md-2">
			<button mat-button [matMenuTriggerFor]="sortBy_menu" aria-label="Filter By" style="background: #dd133c !important;">
				Filter
				<mat-icon>filter_list</mat-icon>
			</button>
			<mat-menu #sortBy_menu="matMenu">
				<button mat-menu-item (click)="resetTableDataByActivityFilter()">None</button>
				<button mat-menu-item (click)="filterTableDataByActivity('Active')">Active</button>
				<button mat-menu-item (click)="filterTableDataByActivity('Break')">Break</button>
				<button mat-menu-item (click)="filterTableDataByActivity('Login')">Login</button>
				<button mat-menu-item (click)="filterTableDataByActivity('Logout')">Logout</button>
				<button mat-menu-item (click)="filterTableDataByActivity('null')">No Activity</button>
			</mat-menu>
		</div>

		<div class="col-md-2">
          <div ngbDropdown class="d-inline-block">
			<!-- <span class="table-tag px-2">Select Agent</span> -->
			<button class="btn btn-outline-secondary" id="dropdownForm1"
              ngbDropdownToggle>{{selectAll ? 'All Selected' : selectedAgentsLen+' Agents'}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1">
              <div class="px-4 py-3" style="max-height: 300px;overflow-y: auto;">
                <mat-checkbox (change)="updateCheck()" class="example-margin" [(ngModel)]="selectAll">
                  Select All
                </mat-checkbox>
                <section *ngFor="let ing of selectedAgents; let i = index" class="example-section">
                  <mat-checkbox (change)="selectChildren()" [(ngModel)]="ing.checked">
                    {{ing.name}}
                  </mat-checkbox>
                </section>
              </div>
            </div>
          </div>
		</div>
        <div class="col-md-3 col-sm-12 col-xs-12" style="position: relative;top: 5px;">
            <mat-form-field style="width:83px">
              <input matInput [matDatepicker]="frompicker" [max]="maxDate" placeholder="From" readonly
                (click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)"
                [(ngModel)]="callStartDate">
              <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
              <mat-datepicker #frompicker></mat-datepicker>
              </mat-form-field>
            <mat-form-field class="ml-3"  style="width:83px">
              <input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate" placeholder="To" readonly
                (click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)"
                [(ngModel)]="callEndDate">
              <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
              <mat-datepicker #topicker></mat-datepicker>
              </mat-form-field>

                  <!-- <button mat-button class="btnAdd ml-3" (click)="refreshDatafortoday()">Today</button> -->
        </div>
    <div  class="col-md-1">
      <button mat-button class="ml-0" (click)="refreshData(true)" style="background: #dd133c !important;">Go</button>  <!--*ngIf="!isToday"-->
      <!-- <button *ngIf="isToday" mat-button class="ml-2" (click)="refreshDatafortoday()">Today</button> -->
    </div>
        <div class="col-md-2" style="margin-left: auto">
          <mat-form-field class="wdh">
            <input matInput placeholder="Search" #_searchValue (keyup)="applyFilter($event.target.value)" />
            <i class="fa fa-search" matSuffix aria-hidden="true"></i>
          </mat-form-field>
        </div>
      </div>

      <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 das-table"
          *ngIf="selectedTabSts == 'call_status'" style="box-shadow: none !important;">
          <ng-container matColumnDef="user_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Id </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.user_id }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Name </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
		  </ng-container>


      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Action  </mat-header-cell>
        <mat-cell *matCellDef="let element">
        <span class="actionBtn" *ngIf="getBargeinBtn(element)" (click)="actionBergewishper(element,'BI')">Barge IN</span>
        <span class="actionBtn" *ngIf="getBargeToWishperBtn(element)" (click)="actionBergewishper(element,'BW')">Barge to whisper</span>
        <span class="actionBtn" *ngIf="getWishperToBargeBtn(element)" (click)="actionBergewishper(element,'WB')">Whisper to barge</span>
        </mat-cell>
  </ng-container>

		  <ng-container matColumnDef="live_status">
			<mat-header-cell *matHeaderCellDef> Latest Status </mat-header-cell>
			<mat-cell *matCellDef="let element">
				<!-- <span class="statusCircle"
					[ngStyle]="{'background-color': getStatusColor(element), border: 'none' }">
				</span> -->
				<!-- <div *ngIf="isToday && getCallStatusObj(element) as call_status" style="display: grid;">
					<span *ngIf="call_status.text" style="font-weight: bold;" [ngStyle]="{'color':'call_status.color'}">{{ call_status.text }}</span>
					<span *ngIf="call_status.duration" style="font-weight: bold;">{{ call_status.duration }} min</span>
				</div> -->
				<div *ngIf="isToday" style="display: grid;">
					<span style="font-weight: bold;" [ngStyle]="{'color': element.activity_color}">{{ element.last_activity }}</span>
					<span>{{element.sub_disposition}}</span>
				</div>
			</mat-cell>
		  </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Latest Activity At </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <!-- <span class="statusCircle"
				[ngStyle]="{'background-color': getStatusColor(element), border: 'none' }"></span> -->
				<!-- <div *ngIf="isToday && getCurrentStatus(element) as loc_status" style="display: grid;">
					<span *ngIf="loc_status.msg" style="font-weight:bold;" [ngStyle]="{'color': loc_status.colour}">{{loc_status.msg}}</span>
					<span
					*ngIf="loc_status.sync_time"
					style="font-size: 10px;"
					matTooltip="Last Sync At - {{loc_status.sync_time}}"
                	matTooltipPosition="above"
					>{{loc_status.sync_time}}</span>
				</div> -->
				<div *ngIf="isToday" style="display: grid;">
					<!-- <span style="font-weight: bold;" [ngStyle]="{'color': element.activity_color}">{{ element.last_activity }}</span> -->
					<span>{{element.duration_now}}</span>
					<span
					style="font-size: 10px;"
					matTooltip="Last Sync At - {{element.last_activity_at}}"
                	matTooltipPosition="above"
					>{{element.last_activity_at}}</span>
				</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="dialled">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Calls Placed
              <!-- Dialled  -->
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.dialled }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="unique_dialled">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <!-- Unique Dialled  -->
              Unique Calls Placed
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.unique_dialled }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contacted">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Contacted </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.contacted }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="unique_contacted">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Unique Contacted </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.unique_contacted }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="rnr">
            <mat-header-cell *matHeaderCellDef mat-sort-header> RNR </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.rnr }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="forms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Forms </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.forms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="login_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Login Hours </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.login_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="unavailable_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Unavailable Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.unavailable_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="idle_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Idle Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.idle_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="wait_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Dial Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.wait_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="spoken_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Talk Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.spoken_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="wrap_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Wrap Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.wrap_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="break_time_hms">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Break Time </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.break_time_hms }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="aht">
			<mat-header-cell *matHeaderCellDef mat-sort-header> AHT </mat-header-cell>
			<mat-cell *matCellDef="let element">{{ element.aht }}</mat-cell>
		  </ng-container>
          <ng-container matColumnDef="audit_score">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Audit score </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.audit_score }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="ctc_score">
            <mat-header-cell *matHeaderCellDef mat-sort-header> CTC score </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.ctc_score }}</mat-cell>
          </ng-container>
		  <ng-container matColumnDef="connectivity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Connectivity </mat-header-cell>
            <mat-cell *matCellDef="let element">
				<div *ngIf="isToday" style="display: grid;">
					<span>{{element.connectivity_src}}</span>
					<span style="font-weight: bold;" [ngStyle]="{'color': element.connect_color}">{{ element.connect_status }}</span>
				</div>
			</mat-cell>
          </ng-container>
          <ng-container matColumnDef="more">
            <mat-header-cell *matHeaderCellDef mat-sort-header> More </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="Open Detail Timings" class="" matTooltip="Details"
                matTooltipPosition="below" (click)="openDetailActivity(element)">
                <mat-icon>launch</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef=displayedColumnsCallStatus></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsCallStatus"></mat-row>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[5,10, 20, 30, 50]" showFirstLastButtons></mat-paginator>
    </mat-card>
  </div>

</div>

</div>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>
