import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { ApiService } from 'src/app/api.service';
@Component({
  selector: 'app-onboarding-process-page3',
  templateUrl: './onboarding-process-page3.component.html',
  styleUrls: ['./onboarding-process-page3.component.scss']
})
export class OnboardingProcessPage3Component implements OnInit {
  createdForm:FormGroup;
  msgColor: any = "";
  messageDisplay: any = "";
  showHideMessage: boolean = false;
  constructor(private router:Router,public formbuilder:FormBuilder,public API :ApiService) { 
    window.scroll(0,0);
    this.createdForm = this.formbuilder.group({
      dayMonday:false,
      dayTuesday: false,
      dayWednesday:false,
      dayThursday:false,
      dayFriday:false,
      daySaturday:false,
      daySunday:false,
      startTime:[''],
      endTime:[''],
      otpbased:[''],
      agentSlot:[''],
      apiBasedDataUploadProcess:false,
      excelDataUploadProcess: false,
      otherDataUploadProcess:false,
      uoloadFileldAnswer:[''],
      accessDataUpload:[''],
      accessDataUploadOtherDesc:[''],
      dataDropAnswer:[''],
      addFileDuringCall:[''],
      followup:[''],
      dataDropDuration:['']
    })
  }

  ngOnInit(): void {
  }
  onNextBtn(createdForm: any){
    //debugger;
    this.messageDisplay="";
    this.showHideMessage = false;

    let _dayMonday = createdForm.controls["dayMonday"].value;
    let _dayTuesday = createdForm.controls["dayTuesday"].value;
    let _dayWednesday = createdForm.controls["dayWednesday"].value;
    let _dayThursday = createdForm.controls["dayThursday"].value;
    let _dayFriday=  createdForm.controls["dayFriday"].value;
    let _daySaturday=  createdForm.controls["daySaturday"].value;
    let _daySunday=  createdForm.controls["daySunday"].value;

    let _startTime=  createdForm.controls["startTime"].value;
    let _endTime=  createdForm.controls["endTime"].value;
    
    let _otpbased=  createdForm.controls["otpbased"].value;
    let _agentSlot=  createdForm.controls["agentSlot"].value;
    let _apiBasedDataUploadProcess=  createdForm.controls["apiBasedDataUploadProcess"].value;
    let _excelDataUploadProcess=  createdForm.controls["excelDataUploadProcess"].value;
    let _otherDataUploadProcess=  createdForm.controls["otherDataUploadProcess"].value;
    let _uoloadFileldAnswer=  createdForm.controls["uoloadFileldAnswer"].value;

    let accessDataUpload =  createdForm.controls["accessDataUpload"].value;
    let accessDataUploadOtherDesc =  createdForm.controls["accessDataUploadOtherDesc"].value;
    let _dataDropAnswer =  createdForm.controls["dataDropAnswer"].value;
    let _addFileDuringCall =  createdForm.controls["addFileDuringCall"].value;
    let _followup =  createdForm.controls["followup"].value;
    let _dataDropDuration =  createdForm.controls["dataDropDuration"].value;
    if (_dayMonday == false && _dayTuesday ==false && _dayWednesday ==false  && _dayThursday ==false && _dayFriday ==false   && _daySaturday ==false && _daySunday ==false) {
      this.messageDisplay = "Please select atleast one Workdays*";
      this.msgColor = "red";
      this.showHideMessage = true;
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }

   this.messageDisplay = "Good.., Now you are going to next page..!!";
   this.msgColor = "green";
   this.showHideMessage = true;
   window.scroll({
     top: 20,
     left: 0,
     behavior: 'smooth'
   });
   setTimeout(()=>{
     this.router.navigateByUrl('/onboarding_process_page4')
   },3000)
      }
}
