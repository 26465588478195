
<span style="position: relative;
top: -15px;
right: 20px;
float: right;
z-index: 9;
cursor: pointer;">
  <img src="../assets/contiinex/image/closeicon.svg" width="35px" style="position: fixed;" (click)="closeDialog()" /></span>
<div class="containerOne">
  <div class="ms-centeredText ms-scrolly">
    <div>
    <mat-card class="msCardy">
        <p class="text-center mb-2"><img [src]="previewData.uploadFileImg" style="width:40px;"/></p>
      <p class="vk-header-title text-center mb-0" >{{previewData.displayName}}</p>
      <p class="text-center mb-1" style="font-family: Roboto;font-size:12.5px;">{{previewData.waNumber}}</p>
      <p class="text-center mb-1" style="font-family: Roboto;font-size:12.5px;">{{previewData.businessType}}</p>
      <div class="text-center" style="display: flex;justify-content: center;">
         <table class="text-center" style="width:100%"><tr>
            <td><span class="iic"><mat-icon style="color: #3d9989;transform: scaleX(-1);">chat</mat-icon></span><br><span> Message</span> </td>
            <td><span class="iic2"><mat-icon style="color: #3d9989;transform: scaleX(-1);">reply</mat-icon></span> <br><span> Forward</span></td>
        </tr></table>
    </div>
      <p class="vk-footer-title mt-2 mb-1" >This is a business account.</p>
    <!-- </mat-card>
    <mat-card class="msCardy mt-3"> -->
<hr style="margin: 10px 0 5px 0;border-top: 1px solid #04040440;">
      <p class="mt-2 linkFoot">
          <span class="span-fl-text" style="color: #000;">{{previewData.businessDescription}}</span>
          <span class="span-fl-text mt-2">{{previewData.companyAddress}}</span>
          <span class="span-fl-text">
            <!-- support@smarter-biz.com -->
            {{previewData.alertEmail}}
          </span>
          <span class="span-fl-text">{{previewData.websiteURL}}</span>
        </p>
    </mat-card>
    </div>
  </div>
  <img class="img-responsive pImgWidth" src="../assets/contiinex/image/ms-preview-img.png"  alt="Preview"/>
</div>

