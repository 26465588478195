import { Component, ElementRef, OnDestroy, OnInit, Inject, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utils } from '../../utils';
import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { duration } from 'moment';

@Component({
	selector: 'app-campaign-details-transfer-agent-data-dialog',
	templateUrl: './campaign-details-transfer-agent-data-dialog.component.html',
	styleUrls: ['./campaign-details-transfer-agent-data-dialog.component.scss']
})
export class CampaignDetailsTransferAgentDataDialogComponent implements OnInit {

	displayedColumnsA: string[] = ['select', 'agentName', 'email', 'dataValue'];
	displayedColumnsB: string[] = ['select', 'agentName', 'email', 'dataValue'];
	dataSourceA = new MatTableDataSource();
	dataSourceB = new MatTableDataSource();
	// @ViewChild(MatPaginator,{static:true}) paginatorA: MatPaginator;
	// @ViewChild(MatPaginator,{static:true}) paginatorB: MatPaginator;
	checkeddata: any = [];
	currentLoader = false;
	@ViewChild('paginatorA', { static: true }) paginatorA: MatPaginator;
	@ViewChild('paginatorB', { static: true }) paginatorB: MatPaginator;


	selected_agent: any = [];
	from_agents: any = [];
	campaign_details: any;
	api_end_point: any;
	constructor(private dialog: MatDialog,
		private API: ApiService,
		private utils: Utils,
		private myservice: MyserviceService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<CampaignDetailsTransferAgentDataDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewAddagentDetails) { }

	ngOnInit() {
		this.api_end_point = this.myservice.get_API_End_Point();
		this.campaign_details = this.viewAddagentDetails.campaign_details;
		//console.log(this.campaign_details);
		this.showagent(this.viewAddagentDetails);
	}

	applySearchFilter1(val: string) {
		this.dataSourceA.filter = val.toLowerCase().trim()
	}
	applySearchFilter2(val: string) {
		this.dataSourceB.filter = val.toLowerCase().trim()
	}
	closeDialog() {
		this.dialogRef.close();
	}
	cancelBtn() {
		this.dialogRef.close();
	}
	transferDoneBtn() {
		var obj = {
			"from_list": [],
			"to_list": [],
			"campaign": this.campaign_details.campaign_name
		}
		if (this.selected_agent.length > 0 && this.from_agents.length > 0) {


			this.selected_agent.forEach(t => {
				if (t.email) {
					obj.to_list.push(t.email)
				}
			})
			this.from_agents.forEach(t => {
				if (t.email) {
					obj.from_list.push(t.email)
				}
			});
			//console.log(JSON.stringify(obj));
			this.currentLoader = true
			this.API.commonevenTransfer(obj, this.api_end_point).subscribe((d: any) => {
				this.currentLoader = false;
				if (d.success) {
					//alert('Data Transferred successfully');
					this.dialogRef.close();
					this._snackBar.open('Data Transferred successfully', 'close', {
						duration: 2000
					});

				} else if (d.errors) {
					this.dialogRef.close();
					this._snackBar.open(d.errors, 'close', {duration: 2000});
					//alert(d.errors)
				} else {
					//alert('Unknown error');
					this.dialogRef.close();
					if (d.errors || d.error || d.err) {
						this._snackBar.open(d.errors, "close", {duration: 2000});
					}
				}
				setTimeout(() => {
					if (window.location.href.includes('central-campaign')) {
						this.router.navigate(['central-campaign'])
					}
					if (window.location.href.includes('campaignV2')) {
						this.router.navigate(['campaignV2'])
					}
				})
			})
		}
		else {
			this._snackBar.open("Please select agent", "close", {
				duration: 2000
			});

		}

	}
	//table details shown.
	showagent(obj) {
		//debugger;
		var allagentlist = [];
		//console.log(obj);
		//console.log(obj.length);
		obj.agent.map(agent => {agent.from_selected = false, agent.to_selected = false});
		allagentlist = obj.agent;
		console.log('agent list', allagentlist)
		this.dataSourceA = new MatTableDataSource(allagentlist);
		setTimeout(() => {
			this.dataSourceA.paginator = this.paginatorA;
		}, 100)

		this.dataSourceB = new MatTableDataSource(allagentlist);
		setTimeout(() => {
			this.dataSourceB.paginator = this.paginatorB;
		}, 200)

	}

	// addingat_table() {
	// 	this.dataSourceB = new MatTableDataSource(this.agents_list);
	// 	setTimeout(() => {
	// 		this.dataSourceB.paginator = this.paginatorB;
	// 	}, 200)
	// 	//console.log("table"+this.dataSource.data);
	// }
	to_agents(element, toselect) {
		//	console.log(element);
		console.log(toselect.checked);
		element.to_selected = false
		if (toselect.checked) {
			element.to_selected = true
			this.selected_agent.push(element);
		} else {
			for (var i = 0; i < this.selected_agent.length; i++) {

				if (this.selected_agent[i].email === element.email) {

					this.selected_agent.splice(i, 1);
				}

			}
		}
	}
	from_tableagents(element, fromselect) {
		console.log(fromselect.checked);
		element.from_selected = false;
		if (fromselect.checked) {
			const userindex = this.checkeddata.findIndex(x => x == element.id)
			if (userindex > -1) {
				this.checkeddata.splice(userindex, 1)
				return
			}
			//console.log(element.id);
			// let data = (this.dataSourceB.data).filter(v =>{
			// 	return v === element.email });
			let tableA = element;
			let tableB = this.dataSourceB.data;
			this.checkeddata.push(element.id);
			var _datachecked = tableB.filter(v => {
				return v;
			})
			element.from_selected = true
			this.from_agents.push(element);
		} else {
			for (var i = 0; i < this.from_agents.length; i++) {

				if (this.from_agents[i].email === element.email) {

					this.from_agents.splice(i, 1);
					this.checkeddata.splice(i,1);
				}

			}
			//console.log(element);
			//this.from_agents.pop();
		}
	}
	getcurrentstate(id) {
		return this.checkeddata.includes(id) ? true : false;
	}
}


