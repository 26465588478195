import { Component, OnInit, Input, Inject, ViewChild, Renderer2, ElementRef, AfterViewInit, OnChanges, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';

import { MyserviceService } from '../myservice.service';

import { ApiService } from '../api.service';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-menu',
	providers: [ApiService],
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
	public my_pages = []
	is_minimized:boolean = this.service.get_collapse_status();
	@ViewChild("myDIVMenu") menu: ElementRef;
	// @ViewChildren('menu_button') buttonRef?: ElementRef<HTMLButtonElement>;
	@ViewChildren('menu_button', { read: ElementRef })  buttonRef: QueryList<ElementRef<HTMLButtonElement>>;
	@ViewChildren('parent_button', { read: ElementRef })  parent_buttonRef: QueryList<ElementRef<HTMLButtonElement>>;
	@Input("navigateLink") navLink: string;
	public total_pages = [];
	public user = this.service.get_loggedin_user();
	showHidemainToggle1: boolean = true;
	showHidemainToggle2: boolean = false;
	showHideSideNav: boolean = true;
	widthSide: any = "260px";
	noneBlock: any = "inline-block";
	api_end_point: any;
	isScanBar: boolean = false;
	customer_name: any = "";

	currentLoader: boolean = false;
	api_platform: any;

	constructor(
		private router: Router,
		private renderer: Renderer2,
		private service: MyserviceService,
		private API: ApiService
	) {
		this.api_end_point = this.service.get_API_End_Point();
		this.api_platform = this.service.get_API_Platform1();
		// const pages = this.service.setMyPages();
		const pages = this.service.getAccessPages();
		console.log('pages received', pages);
		pages.forEach(page => {
			if (page.data_id.indexOf('.') > -1) {
				// console.log('having sub menu')
				if (this.total_pages.hasOwnProperty(page.parent_id)) {
					if (this.total_pages[page.parent_id]['child']) {
						this.total_pages[page.parent_id]['child'].push(page)
					} else {
						this.total_pages[page.parent_id]['child'] = [page]
					}
				} else {
					this.total_pages[page.parent_id] = { ...this.service.total_pages.filter(p => p.data_id == page.parent_id)[0], ...{ "child": [page] } }
				}
			} else {
				console.log('no sub menu')
				this.total_pages[page.data_id] = page;
			}
		})
		/* let _totpages = this.service.setMyPages();
		var found = false, allIndex = [];
		for (let i = 0; i < _totpages.length; i++) {
			if (_totpages[i] && _totpages[i].name == "Dashboard" && _totpages[i].isAccessed) {
				if (found) {
					allIndex.push(i)
				}
				found = true;
			}
		}
		for (var i = allIndex.length - 1; i >= 0; i--) {
			//console.log(this.total_pages[i]);
			this.total_pages.splice(allIndex[i], 1);
		} */
		console.log('total pages', this.total_pages);
	}

	ngOnInit() {
		this.isScanBar = false;
		if (this.service && this.service.get_loggedin_user()) {
			let _userDetailsLogin = this.service.get_loggedin_user();
			if (_userDetailsLogin.businessType == "COMNEX" && _userDetailsLogin.partner == "SHOPIFY") {
				this.isScanBar = true;
			}
			if (this.service.get_loggedin_user().company) {
				this.customer_name = String(this.service.get_loggedin_user().company);
			}
		}

		// console.log('printing view child',this.menu);
		if (this.service.get_loggedin_user() && this.service.get_loggedin_user().company) {
			this.customer_name = String(this.service.get_loggedin_user().company);
		}
	}
	ngAfterViewInit() {
		// debugger;
		// this.menu.nativeElement.firstElementChild.click();
		// console.log('After view init', this.menu);
		// console.log('After view init', this.navLink);
		this.highlightMenu();
	}

	update_side_menu_collapse_status(val:boolean){
		this.service.update_collapse_status(val);
		this.is_minimized = val;
	}

	async checkingMandatoryValues() {
		let a = { 'token': false, 'team': false, 'full_team': false, 'settings': false, 'user': false };

		a.token = await this.service.check_access_token();
		a.team = await this.service.check_my_team();

		a.full_team = await this.service.check_full_team();
		// debugger;
		a.settings = await this.service.check_settings();
		a.user = await this.service.check_user();
		// console.log('printing a', a);
		/* if (this.api_platform != "CONTIINEX") {
			for (var i in a) {
				if (!a[i]) {
					alert(`Session ends. Re-login again. ${i} not found`);
					this.logout();
					break;
				}
			}
		} */
	}



	highlightMenu() {
		this.showHideSideNav = true;
		let target = this.navLink;
		if (target == "training" || target == "auditor") {
			this.showHideSideNav = false;
		}
		// debugger;
		if (this.menu) {
			this.checkingMandatoryValues();
			// let b = this.menu.nativeElement.children;
			// console.log('childs', b);
			this.parent_buttonRef.forEach(p => {
				p.nativeElement.classList.remove('active');
			})
			this.buttonRef.forEach(button => {
				const b = button.nativeElement
				const id = b.getAttribute('id');
				const parent_id = b.getAttribute('data-parent-id');
				b.classList.remove('active');
				console.log('target', target, id)
				if(target == id){
					b.classList.add('active');
					if(parent_id){
						const parent = this.parent_buttonRef.filter(element => {
							return element.nativeElement.id === parent_id;
						});
						if(parent && parent.length){
							if(!parent[0].nativeElement.classList.contains('active')){
								parent[0].nativeElement.classList.add('active');
							}
						}
					}
				}
			})
		}
	}

	resetMenuHighlighter(e, page) {
		// console.log('page details', JSON.stringify(page));
		if (page && page.isAccessed) {
			this.router.navigate([page.path]);
		} else {
			alert('Unauthorized Access. Access denied.');
			return;
		}
	}

	logout() {
		this.currentLoader = false;
		//	if (this.api_end_point == "https://dev.contiinex.com" || this.api_end_point == "http://dev.contiinex.com") {
		if (this.api_platform == "CONTIINEX") {
			this.service.clearAllItems();
			this.router.navigateByUrl('/');
		} else {
			// sessionStorage.setItem('access_token',null);
			var validateUrl = "";
			this.currentLoader = true;
			this.API.logoutAPI(this.api_end_point).subscribe((res) => {
				this.service.clearAllItems();
				window.location.href = "/";
				this.currentLoader = false;
			})
			return;
			if (sessionStorage.getItem('from_external') == 'true') {
				if (window.location.href.includes('dev.youearn.in')) {
					validateUrl = 'https://dev.contiinex.com/Login';
				} else if (window.location.href.includes('uearn.ai')) {
					validateUrl = 'https://uearn.ai/signin';
				} else {
					validateUrl = 'https://contiinex.com/Login';
				}
			}
			console.log(sessionStorage.getItem('from_external'))
			if (validateUrl) {
				if (window.location.href.includes('dev.youearn.in')) {
					window.location.href = 'https://dev.contiinex.com/Login';
				} else if (window.location.href.includes('uearn.ai')) {
					window.location.href = 'https://uearn.ai/signin';
				} else {
					window.location.href = 'https://contiinex.com/Login';
				}
			} else {
				// this.router.navigate(['/home']);
				// window.location.href = '/home'
				if (window.location.href.includes('dev.youearn.in')) {
					window.location.href = 'https://dev.contiinex.com/Login';
				} else if (window.location.href.includes('uearn.ai')) {
					window.location.href = 'https://uearn.ai/signin';
				} else {
					window.location.href = 'https://contiinex.com/Login';
				}
			}
		}
	}

	sidenavToggle(e: any) {
		//debugger;
		if (this.showHidemainToggle1 == true) {
			this.showHidemainToggle2 = true;
			this.showHidemainToggle1 = false;
			this.widthSide = "90px";

			this.noneBlock = "none";
			//  e.toggle();
			return;
		}
		if (this.showHidemainToggle2 == true) {
			this.showHidemainToggle2 = false;
			this.showHidemainToggle1 = true;
			// e.toggle();
			this.widthSide = "260px";

			this.noneBlock = "inline-block";
			return;
		}
	}

	check_sub_menu(title: string) {
		try {
			if (title.indexOf('.') > -1) {
				return true;
			}
			return false;
		} catch (e) {
			return false;
		}
	}

}
