                <section class="sectionPart1" (mouseleave)="leaveDDl()">
                <div>
                  <table class="tablex1">
                    <tr> <td> <mat-checkbox [checked]="allComplete" [indeterminate]="someComplete()"
                      (change)="setAll($event.checked)" class="ml-2 vcbxx">{{task.name}} </mat-checkbox>  </td>
                      <td class="text-right">
                          <span *ngIf="showHideArrow1" (click)="openDDL()"><i class="fa fa-chevron-down mr-2" style="cursor: pointer;" aria-hidden="true"></i></span>
                          <span *ngIf="showHideArrow2" (click)="closeDDL()"><i class="fa fa-chevron-up mr-2" style="cursor: pointer;" aria-hidden="true"></i></span>
                        </td></tr>
                      </table>
                    </div>
               <div class="divUlList"  *ngIf="showHideDDlData"> <span>
                  <ul>
                    <!-- <li *ngFor="let v of ddSelectionObj.subtasks | async"> -->
					<li *ngFor="let v of dropDownList">
                      <mat-checkbox [(ngModel)]="v.completed" (ngModelChange)="updateAllComplete(v)">
                        {{v.name}}
                      </mat-checkbox>
                    </li>
                  </ul>
                </span>
              </div>
              </section>
