import { Component, OnInit } from '@angular/core';
import { ContactListCampaignContactDialogPageComponent } from '../contact-list-campaign-contact-dialog-page/contact-list-campaign-contact-dialog-page.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-contact-list-campaign-details-page',
  templateUrl: './contact-list-campaign-details-page.component.html',
  styleUrls: ['./contact-list-campaign-details-page.component.scss']
})
export class ContactListCampaignDetailsPageComponent implements OnInit {

	jsonData:any=[];
	tab = 0;
	vTable:any=[];
  constructor(	private dialog: MatDialog,) { }

  ngOnInit() {
	this.jsonData=[];
	this.vTable=[];
	this.jsonData=[
		{
			CampaignName:'Campaign-1',
			priority:'P2',
			Available:4,
			Completed:5,
			campaignType:'Preview',
			createdDate:'2nd July 2022 12:30 PM',
			totalData:'20',
			assignedData:'8',
			calledData:'16',
			availableData:'4',
			tableData:[
				{customerName:'Praveen',phone:'+91 9898989876',Email:'pravven@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Prashant',phone:'+91 987788876',Email:'prashant@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 4:30 PM'},
				{customerName:'Veenit',phone:'+91 6776637634',Email:'veen@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 5:30 PM'}
			]
		},
		{
			CampaignName:'Campaign-2',
			priority:'P1',
			Available:3,
			Completed:4,
			campaignType:'Preview',
			createdDate:'3rd July 2022 2:30 PM',
			totalData:'22',
			assignedData:'5',
			calledData:'14',
			availableData:'5',
			tableData:[
				{customerName:'Vivek',phone:'+91 9118989876',Email:'pravven@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Patel',phone:'+91 934788876',Email:'prashant@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Krunal',phone:'+91 676232232',Email:'veen@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'}
			]
		},
		{
			CampaignName:'Campaign-3',
			priority:'P3',
			Available:6,
			Completed:5,
			campaignType:'Preview',
			createdDate:'4th July 2022 10:30 AM',
			totalData:'30',
			assignedData:'6',
			calledData:'12',
			availableData:'6',
			tableData:[
				{customerName:'Praveen',phone:'+91 9898989876',Email:'pravven@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Prakash',phone:'+91 987788876',Email:'prakash@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Omkar',phone:'+91 6776637634',Email:'omkar@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Pradeep',phone:'+91 878686776',Email:'pradeep@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'}
			]
		},
		{
			CampaignName:'Campaign-4',
			priority:'P5',
			Available:3,
			Completed:3,
			campaignType:'Preview',
			createdDate:'4th July 2022 05:30 PM',
			totalData:'15',
			assignedData:'7',
			calledData:'11',
			availableData:'8',
			tableData:[
				{customerName:'Prashant',phone:'+91 9898989876',Email:'prashant@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Lal',phone:'+91 987788876',Email:'lal@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Dkay',phone:'+91 6776637634',Email:'dkay@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Krunal',phone:'+91 637266334',Email:'kr@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'}
			]
		},
		{
			CampaignName:'Campaign-5',
			priority:'P4',
			Available:4,
			Completed:3,
			campaignType:'Predictive',
			createdDate:'5th July 2022 10:00 AM',
			totalData:'12',
			assignedData:'4',
			calledData:'6',
			availableData:'4',
			tableData:[
				{customerName:'Praveen',phone:'+91 9898989876',Email:'pravven@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Prakash',phone:'+91 987788876',Email:'prakash@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Omkar',phone:'+91 6776637634',Email:'omkar@smarterbiz.com',callStatus:'Available',updatedAt:'4th July 2022 2:30 PM'},
				{customerName:'Vinay',phone:'+91 6776637634',Email:'vinay@smarterbiz.com',callStatus:'Dialled',updatedAt:'4th July 2022 2:30 PM'}
			]
		}
	];
	this.vTable=	this.jsonData[0].tableData;
  }

  isActive(tabId): boolean {
    return this.tab === tabId;
  }

  openCardTab(tabId,v) {
	debugger;
    this.tab = tabId;
	this.vTable=[];

	if(v.campaignType== "Predictive"){
		this.vTable=[];
	}
	else{
		this.vTable=v.tableData;
	}
  }
  contactNameDetails(data:any){
debugger;
console.log(data);
const dialogRef = this.dialog.open(ContactListCampaignContactDialogPageComponent, {
	width: '70%',
	disableClose: true,
	data: data,
});

dialogRef.afterClosed().subscribe(result => {
	console.log('The dialog was closed', result);

});
  }
}
