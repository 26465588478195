<div class="p-4">
	<p class="contactTitle1 mb-0">Select Whatsapp Template</p>                           
<div class="dc-labelGroup">
	<p>
	<mat-select [ngClass]="'arrowcs'" class="dropdownCs mt-0" [(ngModel)]="templateddlValue"
		(selectionChange)="changeWhatsappTemplate($event.value)" placeholder="Select a Template" >
		<mat-option [value]="v.template_name" *ngFor="let v of _ddlListExtraOption">
			{{v.template_name}}</mat-option>
	</mat-select>
	</p>
</div>
<div class="dc-labelGroup">
	<p *ngIf="templatepart1" [innerHTML]="templatepart1" class="pInner1">{{templatepart1}}</p>
</div>
            <div class="dc-labelGroup" *ngIf="ismedia">
				<p ><img src={{media}} style="width: 100%;" /></p>
			</div>

			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Relationship_Manager">
				<p>
					<span class="lvl">Relationship_Manager</span>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="relationshipManagerModel">
					</mat-form-field>
				</p>
			</div>
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Product_Name">
				<p>
					<span class="lvl">Product_Name</span>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Product_Name">
					</mat-form-field>
				</p>
			</div>
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Tracking_Link">
				<p>
					<span class="lvl">Tracking Link</span>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="trackingLinkModel">
					</mat-form-field>
				</p>
			</div>
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Phone_Number">
				<p>
					<span class="lvl">Phone number</span>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput (keypress)="onlyNumberValidation($event)"	class="mt-3 dtcs" autocomplete="off" maxlength="15"	[(ngModel)]="phoneNoModel">
					</mat-form-field>
				</p>
			</div>
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Accept_Key">
				<p>
					<span class="lvl">Accept_Key</span>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Accept_Key">
					</mat-form-field>
				</p>
			</div>
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Reject_Key">
				<p>
					<span class="lvl">Reject_Key</span> 
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Reject_Key">
					</mat-form-field>
				</p>
			</div>
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Publisher_Name">
				<p>
					<span class="lvl">Publisher_Name</span>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Publisher_Name">
					</mat-form-field>
				</p>
			</div>

			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Service_Station">
				<p>
					<span class="lvl">Service_Station</span>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Service_Station">
					</mat-form-field>
				</p>
			</div>

			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Vehicle_Number">
				<p>
					<span class="lvl">Vehicle_Number</span> 
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Vehicle_Number">
					</mat-form-field>
				</p>
			</div>
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Invoice_Number">
				<p>
					<span class="lvl">Invoice_Number</span> 
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Invoice_Number">
					</mat-form-field>
				</p>
			</div>


			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Service_Name">
				<p>
					<span class="lvl">Service_Name</span> <br>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Service_Name">
					</mat-form-field>
				</p>
			</div>
			
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Product_Link">
				<p>
					<span class="lvl">Product_Link</span> <br>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Product_Link">
					</mat-form-field>
				</p>
			</div>

			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Web_Link">
				<p>
					<span class="lvl">Web_Link</span> <br>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input matInput class="mt-3 dtcs" autocomplete="off" [(ngModel)]="Web_Link">
					</mat-form-field>
				</p>
			</div>
			<div class="dc-labelGroup" *ngIf="_rowExtraOptionData.Next_Update_Time">				
				<p>					
					<span class="lvl">Next_Update_Time</span> <br>
					<mat-form-field appearance="outline" class="mt-1 cForm"	style="width:100%">
						<input #fromInput matInput [min]="minDate" [matDatepicker]="picker"	class="mt-3 dtcs" placeholder="Date" [(ngModel)]="dateSelect"
							readonly (click)="picker.open()">
						<mat-datepicker-toggle matSuffix [for]="picker"	style="font-size: 18px !important;"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
				</p>
			</div>


<p class="text-right"> 
	<button (click)="closeDialog()" class="okBtn1 mr-2" >Close</button>
	<button class="dcContactSubmit" (click)="saveBtn()"> Submit </button></p>
</div>



