<div class="row" style="padding: 31px 12px 31px 12px;">
    <div class="col-md-12">
        <div class="m-div" style="height:auto">
            <p><span class="s-hd" style="width: 320px;">Email delivered to Agent and Agent replies</span></p>

            <div class="row" style="height:100px">
                <div class="col-md-6 ">
                    <mat-checkbox class="matcheckbox1"  [ngClass]="'cb-WH'"  style="color:gray" class="pl-3">Reply After</mat-checkbox><br>                  
                            <span>
                                <mat-form-field [ngClass]="'orchDDlOutline'"
                                    style="font-size: 12px;padding:  0px 0 0 20px;width: 30%;" appearance="outline"
                                    class="pb-0">
                                    <input matInput class="textInput"  value="00" style="color:gray;font-size: 20px !important">
                                </mat-form-field>
                            </span>
                            <span>
                                <mat-form-field [ngClass]="'orchDDlOutline'"
                                    style="font-size: 12px;padding:  0px 0 0 20px;width: 30%;" appearance="outline"
                                    class="pb-0">
                                    <input matInput class="textInput" value="00" style="color:gray;font-size: 20px !important">
                                </mat-form-field>
                            </span>
                            <span style="padding-left: 20px;color:gray">
                               Hours
                            </span>
                       

                </div>
                <div class="col-md-1" style="border-left: 2px dashed #8d8d8d;">

                </div>
                <div class="col-md-5 ">
                    <mat-checkbox  [ngClass]="'cb-WH'"  class="matcheckbox1" style="color:gray"  class="pl-3">Follow Up Mail After</mat-checkbox><br>
                            <span>
                                <mat-form-field [ngClass]="'orchDDlOutline'"
                                    style="font-size: 12px;padding: 0px 0 0 20px;width: 30%;" appearance="outline"
                                    class="pb-0">
                                    <input matInput class="textInput"  value="00" style="color:gray;font-size: 20px !important">
                                </mat-form-field>
                            </span>
                            <span>
                                <mat-form-field [ngClass]="'orchDDlOutline'"
                                    style="font-size: 12px;padding:  0px 0 0 20px;width: 30%;" appearance="outline"
                                    class="pb-0">
                                    <input matInput class="textInput" value="00" style="color:gray;font-size: 20px !important">
                                </mat-form-field>
                            </span>
                                <span style="padding-left: 20px;color:gray">Hours</span>
                           
                </div>
            </div>
<div style="border-bottom: 2px dashed #8d8d8d;">
&nbsp;
</div>
<br>
            <div class="row">
                <div class="col-md-12 text-right" style="position: relative;
                right: 20px;">
                    <button class="saveSubmit">SAVE</button>
                </div>
            </div>
            <br>

        </div>
    </div>
</div>