import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import {MyserviceService}from '../myservice.service';
@Component({
  selector: 'app-calandar',
  templateUrl: './calandar.component.html',
  styleUrls: ['./calandar.component.scss']
})
export class CalandarComponent implements OnInit {
  
  todayDate = new Date();
  setdate = new FormControl(this.todayDate)
  todayData:any;
  upcomingData:any;
  api_end_point:any
  constructor(private API: ApiService,private _service:MyserviceService) { }
  @ViewChild('picker') picker;
  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
    setTimeout(
      () => {
         this.picker.open();
      },
      50
    );
    // this.todayDate = this.todayDate.setHours(0,0,0,0);
    // this.setdate = new FormControl(this.todayDate);
    this.getCalander();
  }
  checkStartDateToday(startDate: any){
  

  const isToday = moment(moment(startDate)).isSame(moment(), 'day')
  if(isToday){
    return moment(startDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
  }
  return moment(startDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
}
getUTCformat(date, days){
    //console.log('input date', date);
    if(date == '' || date == null){
        return '';
    }
    var temp = moment(date,"YYYY-MM-DD HH:mm a");
    //console.log('printing temp', temp);
    return moment.utc(temp).add(days, 'd').format('YYYY-MM-DDTHH:mm:ss');
}
getCalander(){
  let days = 1;
  // let _startdate = moment(this.setdate.value).format("YYYY-MM-DD HH:mm:ss");
  //console.log(this.setdate.value)
   let _startdate = this.checkStartDateToday(this.setdate.value);
  let _nextDay = new Date(new Date(_startdate).setDate(new Date(_startdate).getDate() + days));
  let _enddate = moment(this.setdate.value).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');

  let _obj = {
    "for_group": 2053,
    "start_date": _startdate,
    "end_date": _enddate,
    "trainer_group": 2044,
    "recruitment_group": 24275
  }

  this.API.getCalanderData(_obj,this.api_end_point).subscribe((response: any) => {
    if (response.success) {
      this.todayData=response.success.today;
      this.upcomingData=response.success.upcoming;
    
    }
    if(response.errors){
      this.todayData=[];
      this.upcomingData=[];
    }
  })
}

  handleDateChange(event:any){
  //  console.log(event.value);
    this.getCalander();
    setTimeout(
      () => {
         this.picker.open();
      },
      50
    );
  }
}


