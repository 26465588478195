<div class="row">
	<div class="col-md-12">
		<mat-card style="margin: 0 10px;box-shadow: 0px 0px 4px 0px #dfdfdf;">
			<div class="row">
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon11.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1">{{getAllActiveTime()}}</span>
								<!--| date:'HH:mm:ss'-->
								<span class="nameLabel1">Active Time</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon12.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#50bafd;">{{getavgagent()}}</span>
								<!--| date:'HH:mm:ss'-->
								<!--  -->
								<span class="nameLabel1">Avg/Agent</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon13.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#f3385d;">{{getdialperagent()| number :
									'1.1-1'}}</span>
								<span class="nameLabel1">Dialed/Agent</span>
							</td>
						</tr>
					</table>
				</div>
			</div>

		</mat-card>
	</div>
</div>
<div class="row ">
	<div class="col-md-12">
		<mat-card style="margin: 0 12px;" class="mt-3">
			<h4 class="titlehead thd1 mb-4">ASM</h4>


			<table matSort (matSortChange)="sortData($event)" class="vTableV">
				<tr>
					<th mat-sort-header="name">NAME</th>
					<th mat-sort-header="idleTime"> IDLE TIME</th>
					<th mat-sort-header="waitTime">WAIT TIME</th>
					<th mat-sort-header="spokenTime">SPOKEN TIME</th>
					<th mat-sort-header="wrapTime">WRAP TIME</th>
					<th mat-sort-header="activeTime">ACTIVE TIME</th>
				</tr>
				<tbody *ngFor="let d of sortedData | async;let i = index;let first=first;">
					<tr>
						<td colspan="6" style="color: #000;font-weight: bold;">{{d.title}}</td>
					</tr>
					<tr *ngFor="let v of d.rData;let j = index;let first=first;">
						<td>{{v.name}}</td>
						<td>{{v.idleTime}}</td>
						<td>{{v.waitTime }}</td>
						<td>{{v.spokenTime }}</td>
						<td>{{v.wrapTime }}</td>
						<td>{{v.activeTime | hms}}</td>
					</tr>
					<tr style="background: #f9f9f9;">
						<td><b>Total</b></td>
						<td>{{getIdleTime(d) }}</td>
						<!--| date:'HH:mm:ss'-->
						<td>{{getWaitTime(d) | date:'HH:mm:ss'}}</td>
						<td><span >
								{{getSpokenTime(d)}}
							</span>
							<!-- <span *ngIf="withtimeproperty">
								{{getSpokenTime(d)| date:'HH:mm:ss' }}
							</span> -->
						</td>
						<td>{{getWrapTime(d) | date:'HH:mm:ss'}}</td>
						<td><span *ngIf="withouttimeproperty">
							{{getActiveTime(d)}}
						</span>
						<span *ngIf="withtimeproperty">
							{{getActiveTime(d)}}
						</span>

					</tr>
				</tbody>
				<tfoot>
					<tr style="background: #f3f3f3;">
						<td><b>All Total</b></td>
						<td>{{getAllIdleTime() | date:'HH:mm:ss'}}</td>
						<td>{{getAllWaitTime() | date:'HH:mm:ss'}}</td>
						<td>{{getAllSpokenTime() }}</td>
						<td>{{getAllWrapTime() | date:'HH:mm:ss'}}</td>
						<td>{{getAllActiveTime()}}</td>
						<!---| date:'HH:mm:ss'-->
					</tr>
				</tfoot>
			</table>
		</mat-card>
	</div>
</div>