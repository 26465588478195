import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MyserviceService} from '../../myservice.service';
import { ApiService } from '../../api.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Utils } from '../../utils';
import * as moment from 'moment';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ViewDetailsDialog1Component } from '../view-details-dialog1/view-details-dialog1.component';
import { DatePipe } from '@angular/common';
import { Routes, RouterModule, Router,ActivatedRoute, Params  } from '@angular/router';
import { MAT_DATE_FORMATS } from '@angular/material/core';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-contiinex-reports1',
  templateUrl: './contiinex-reports1.component.html',
  styleUrls: ['./contiinex-reports1.component.scss'],
  providers: [DatePipe, { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class ContiinexReports1Component implements OnInit {
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();
  customer_name:string;
  project_name:string = "";
  projects:any;
  ChannelsData:any;
  selected:any;
  selectedChannel:any;
  selectedType: any = "";
  showHideDateCondition: boolean = false;
  //today = new Date();
  todayMax = new Date();
  todayMax2 = new Date();
	tomorrow = moment().add(1,'days');
  utils = new Utils();
	startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
	endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
  byDateValue:any="ByDate";
	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('OutboundPaginator') OutboundPaginator: MatPaginator;
  @ViewChild('whatsappPaginator') whatsappPaginator: MatPaginator;
  @ViewChild('SMSPaginator') SMSPaginator: MatPaginator;
  @ViewChild('UPaginator') UPaginator: MatPaginator;
  currentLoader:boolean=false;
  selectedStatusValue:any;
  _statusValue:any=[];
  business_phones:string="";
  _phoneValue:any;
  _whatsAppTableData:any=[];

  api_end_point:any;
	currentTitle = 'Reports';
  currentUrl:any;
  selectedBusinessPhone:any="";
  minDate:any="";
  constructor(private _service:MyserviceService, private API: ApiService,private dialog: MatDialog,private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    if(this.byDateValue=="ByDate"){
      this.startDateFilter=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);;
      this.endDateFilter=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
    }
    this.selected="";
    this.selectedChannel="";
    //this.getReportData();
    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.projects = String(this._service.get_loggedin_user().project);
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.business_phones = String(this._service.get_loggedin_user().phone);
      this.project_name = String(this._service.get_loggedin_user().project).toLowerCase();
      this.project_name = this.project_name == 'undefined' ? '' : this.project_name;

    }

    this.ChannelsData=[];

    if (this.project_name && (this.project_name.indexOf('inivr')>=0)) {
      this.ChannelsData.push('Inbound IVR');
    } else if (this.project_name && (this.project_name.indexOf('ivr')>=0)) {
      this.ChannelsData.push('Outbound IVR');
    }

    if (this.project_name && (this.project_name.indexOf('waba')>=0)) {
      this.ChannelsData.push('WhatsApp');
    } else if (this.project_name && (this.project_name.indexOf('wa')>=0)) {
      this.ChannelsData.push('WhatsApp');
    }

    if (this.project_name && (this.project_name.indexOf('sms')>=0)) {
      this.ChannelsData.push('SMS');
    }

    if (this.project_name && (this.project_name.indexOf('out')>=0)) {
      this.ChannelsData.push('Outbound');
    }

    if (this.project_name && (this.project_name.indexOf('in_')>=0)) {
      this.ChannelsData.push('Inbound');
    }

    //console.log('ContiinexReportsComponent',this.ChannelsData,this.project_name);

    if ( this.project_name.indexOf('waba')>=0) {
      //this.getWaReport();
      if(this.ChannelsData && this.ChannelsData[0] == 'WhatsApp'){      
      setTimeout(()=>{
        this.project_name = "WABA";
        this.selectedChannel = 'WhatsApp'
        this.selectedType="detailed";
        this.getWaReport();
      },100);
     
      }
    } else {
     
      if(this.ChannelsData && (this.ChannelsData[0] == 'WhatsApp' || this.ChannelsData[1] == 'WhatsApp')){
        this.selectedChannel = 'WhatsApp'
        this.selectedType="detailed";
      setTimeout(()=>{
        this.getWaReport();
      },100);
     
      }
      else{
        this.getProjectReport();
     }
    }
  

  }
  changeRadioByDay(val:any){
    this.minDate="";
    this. todayMax = new Date();
     this.todayMax2=new Date();
    if(val=="ByDate"){
      this.startDateFilter=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);;
      this.endDateFilter=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);

    }
    if(val=="ByMonth"){
      this.startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
      this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
    }

  }
  OnChangeProject(val:any){
    //console.log(val);
    this._service.set_current_project(val);
    if(!val || val!="allProjects"){
      this.selectedChannel="";
      this.project_name = String(this._service.get_current_project()).toLowerCase();
      this.project_name = this.project_name == 'undefined' ? '' : this.project_name;
    } else{
      this.selectedChannel="";
      this.project_name = val;
    }
    if ( this.project_name.indexOf('waba')>=0) {
      this.getWaReport();
    } else {
      this.getProjectReport();
    }
  }

  OnChangeChannel(val:any){
    this.selectedChannel = val;
    this.selectedType = '';
    if(val == ''){
      this.getProjectReport()
    }

  }


  channelRefresh(){
    this.currentLoader=false;
    if(!this.selectedType){
      return;
    }
    if(this.selectedChannel == 'Outbound IVR'){
      this.getIvrReport();
    }else if(this.selectedChannel == 'WhatsApp'){
      this.getWaReport();
    }else if(this.selectedChannel == 'SMS'){
      this.getSmsReport();
    }else{
      this.getProjectReport()
    }
  }

  change_date(key: string, event: MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
			this.startDateFilter = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDateFilter).diff(moment(this.endDateFilter)) >= 0) {
				this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				//this.endDateFilter = "";
				//alert("Start date should be less than end date");
				//return;
			}

      if(this.byDateValue=="ByDate"){
        let  _today = new Date(String(this.startDateFilter));
      this.minDate="";
     this. todayMax = new Date();
      this.todayMax2=new Date();
     this.minDate = moment(_today).format('YYYY-MM-DD');
     let _maxDate = moment( this.minDate).add(0,'days');
    this.todayMax2=new Date(_maxDate.toString());
    this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false,  this.todayMax2);
      }
      else{
        debugger;
        let  _today = new Date(String(this.startDateFilter));
        this.minDate="";
        this.todayMax = new Date();
         this.todayMax2=new Date();
         this.minDate = moment(_today).format('YYYY-MM-DD');
         var _maxDate;
         _maxDate = moment( this.minDate).add(30,'days');
                  var iscurrentDate = _maxDate.isAfter(new Date(), "day");
                  if(iscurrentDate) {
                     _maxDate = moment().format('YYYY-MM-DD');
                  }

        this.todayMax2=new Date(_maxDate.toString());
        this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false,  this.todayMax2);



      }





		} else {
			this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDateFilter).diff(moment(this.endDateFilter)) >= 0) {
				//	this.endDateFilter = "";
				this.endDateFilter = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.showHideDateCondition=true;
				return;
			} else {
				this.showHideDateCondition=false;
			}

		}
	}

  ngAfterViewInit(){
    // this.dataSource.paginator = this.paginator
  }

  getProjectReport(){

    this.displayedColumns = ['channel','receiver_phone', 'status' , 'start_time', 'end_time','details'];
    this.API.getProjectReport(this.customer_name,this.project_name,this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter),this.api_end_point).subscribe((res: any) => {
      let ELEMENT_DATA = res.success;
      if(ELEMENT_DATA){
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      if(this.UPaginator){
      this.UPaginator.pageIndex = 0;
      this.dataSource.paginator = this.UPaginator;
      }
      }
      // setTimeout(() => this.dataSource.paginator = this.paginator);
    }, (err) => {

    })

  }
  getIvrReport(){
    this.displayedColumns = [ 'customer_number' ,'event_type', 'dtmf_digit', 'start_time', 'end_time', 'call_duration','details'];
    this.API.getChannelReportIVR(this.customer_name,this.project_name,this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter),this.api_end_point).subscribe((res: any) => {
      let ELEMENT_DATA = res.success;
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      this.OutboundPaginator.pageIndex = 0;
      this.dataSource.paginator = this.OutboundPaginator;

      // setTimeout(() => this.dataSource.paginator = this.paginator);

    }, (err) => {

    })
  }
  getWaReport(sender_phones: any=""){

//debugger;

this.displayedColumns = ['sender_phone', 'receiver_phone', 'status', ' content_type','template_name','content','details'];//'sent_at', 'read_at',

    let _sTime = (document.getElementById("myStartTime") as HTMLInputElement).value;
    let _eTime = (document.getElementById("myEndTime") as HTMLInputElement).value;
    let _splitStartDate;
    let _joinStartDate;
    let _splitEndDate;
    let _joinEndDate

    _splitStartDate=String(this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter)).split("T");
    _joinStartDate= _splitStartDate[0] + " " + _sTime + ":00 GMT%2b5:30";

    _splitEndDate=String(this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter)).split("T");
    _joinEndDate= _splitEndDate[0] + " " + _eTime + ":00 GMT%2b5:30";

/*
    if(_sTime !="00:00" && _eTime !="00:00"){
       _splitStartDate=String(this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter)).split("T");
       _joinStartDate= _splitStartDate[0] + " " + _sTime + ":00 GMT+5:30";
    
       _splitEndDate=String(this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter)).split("T");
       _joinEndDate= _splitEndDate[0] + " " + _eTime + ":00 GMT+5:30";
    }
    else{
      _joinStartDate=this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter);
      _joinEndDate=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter);
    }
*/

    // this.API.getChannelReportWA(this.customer_name,this.project_name,this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter)).subscribe((res: any) => {
      this.currentLoader=true;
    //console.log(sender_phones);
    this.API.getChannelReportWA1(this.customer_name,this.project_name,_joinStartDate,_joinEndDate,sender_phones,"",this.api_end_point).subscribe((res: any) => {
      this.currentLoader=false;
      let ELEMENT_DATA = res.success;
      if(ELEMENT_DATA){
    ELEMENT_DATA.filter(v=>{if(v.status !='FAILED'){return v.message_id='';}})
      //console.log(ELEMENT_DATA)
      this._whatsAppTableData=ELEMENT_DATA;
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      this.whatsappPaginator.pageIndex = 0;
      this.dataSource.paginator = this.whatsappPaginator
      // setTimeout(() => this.dataSource.paginator = this.paginator);
      }

    }, (err) => {
      this.currentLoader=false;
    })
  }

  getSmsReport(){
    this.displayedColumns = ['sender_phone', 'receiver_phone', 'status', 'sent_at', 'read_at','content','details'];
    this.API.getChannelReportSMS(this.customer_name,this.project_name,this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter),this.api_end_point).subscribe((res: any) => {
      let ELEMENT_DATA = res.success;
      //console.log(ELEMENT_DATA)
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      this.SMSPaginator.pageIndex = 0;
      this.dataSource.paginator = this.SMSPaginator
      // setTimeout(() => this.dataSource.paginator = this.paginator);

    }, (err) => {

    })
  }
  extractDataObject(ref, obj) {
		let arr = [];
        var jsonob = {};
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {
              //console.log(i,ref[i]);
        if( ref[i] == 'event_type' && obj['event_type']){
          arr.push(this.getEventName(obj['event_type']))
        }else if( ref[i] == 'start_time' && obj['start_time']){
          arr.push(this.datePipe.transform(obj['start_time'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'end_time' && obj['end_time']){
          arr.push(this.datePipe.transform(obj['end_time'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'created_at' && obj['created_at']){
          arr.push(this.datePipe.transform(obj['created_at'], 'dd-MMM-yyyy h:mm:ss a'))
        }
        else if( ref[i] == 'sent_at' && obj['sent_at']){
          arr.push(this.datePipe.transform(obj['sent_at'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'delivered_at' && obj['delivered_at']){
          arr.push(this.datePipe.transform(obj['delivered_at'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'read_at' && obj['read_at']){
          arr.push(this.datePipe.transform(obj['read_at'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'content' && obj['content']){
          try {
            jsonob = JSON.parse(obj['content']);
          } catch (error) {

          }
          if (jsonob["misc_data"]) {
            arr.push(obj[ref[i]]);
            arr.push(Object.values(jsonob["misc_data"])[0]);
          } else {
            arr.push(obj[ref[i]]);
          }
        }else{
          arr.push(obj[ref[i]])
        }

			} else {
				arr.push('')
			}
		}
		return arr;
  }



  downloadReport(){
   //debugger;
    this.currentLoader=true;
    let data = this.dataSource.filteredData ? this.dataSource.filteredData : this.dataSource.data;
    var data_mapper = {};

    data_mapper = {'channel':'Channel', 'receiver_phone': 'Customer', 'status': 'Status' , 'start_time': 'Start yime', 'end_time': 'End time'};
    if(this.selectedChannel == 'Outbound IVR'){
      data_mapper = {'project': 'Project', 'event_type': 'Event', 'customer_number': 'Recipient' ,'cli_number': 'Sender', 'customer':'Customer','dtmf_digit': 'Digit', 'start_time': 'Start time', 'end_time': 'End time','call_duration':'Duration' ,'hangup_cause':'Hangup cause','campaign':'Campaign','call_url':'Call url','call_price': 'Call price', 'audit_score': 'Audit score'};
    }
    if(this.selectedChannel == 'WhatsApp' || this.selectedChannel == 'SMS'){
      data_mapper = {'project': 'Project','customer':'Customer','sender_phone': 'Sender', 'receiver_phone': 'Recipient','template_name':'Template Name', 'content': 'Content', 'misc_data':'Custom_Object','status': 'Status','message_id': 'Failed Reason','scheduled_at':'Scheduled Date','created_at':'Created', 'sent_at': 'Sent', 'delivered_at': 'Delivered','read_at': 'Read'};
    }
    let headers = Object.values(data_mapper);
    //console.log('headers',headers);
    if (data && data.length) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Report');
      worksheet.addRow(headers);
      data.forEach((d: any) => {
        //console.log('data',d);
        // if(d.certified_date != null && d.certified_date != 'Uncertified'){
        //   let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
        // }
        let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));

        //console.log('printing row', row);
      })
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Report-'+(this.selectedChannel ? this.selectedChannel : 'project') +'.xlsx');
        this.currentLoader=false;
      })
    }
  }

  getCurrentDate(){
    let myDate = new Date();
  let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
  let date = myDate.getDate();
  let month = monthsList[myDate.getMonth()];
  let year = myDate.getFullYear();
  let day = daysList[myDate.getDay()];
  let today = `${date} ${month} ${year}, ${day}`;
  return today;
  }
  getReportData(){
    let ELEMENT_DATA = [
    ];

    this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    this.dataSource.paginator = this.UPaginator
  }
  viewDetailsDialog(element:any,channelType:any){
    let _element={
      "element":element,
      "channelType":channelType
    }
    const dialogRef = this.dialog.open(ViewDetailsDialog1Component, {
      width: '650px',
      height: '80%',
      disableClose:true,
      data: _element
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }
  getEventName(val){
    var event = 'Call Missed';
    switch(val){
      case 'ivr_out_completed':
        event = 'Call Connected';
        break;
      case 'DND Number':
          event = 'DND Number';
          break;
      default:
          break;
    }
    return event;
  }
  changeStatusClick(val:any){
   let array=[];
   this._statusValue=[];
    this._statusValue=val;;
    let _filterRecord=[];
    if(val ==undefined || val.length==0){
       _filterRecord=this._whatsAppTableData;
       this.dataSource = new MatTableDataSource(_filterRecord);
       this.whatsappPaginator.pageIndex = 0;
       this.dataSource.paginator = this.whatsappPaginator;    
    }
    else{   
      for(let j=0;j<  this._statusValue.length;j++){
       _filterRecord=this._whatsAppTableData.filter(v=>{return v.status== this._statusValue[j]});
        array.push(_filterRecord);      
      }
      this.dataSource = new MatTableDataSource( [].concat.apply([], array));
      this.whatsappPaginator.pageIndex = 0;
      this.dataSource.paginator = this.whatsappPaginator;
    }

    }
  filterSenderBusinessPhone(val:any) {
   // debugger;
    if (val) {
      this.getWaReport(encodeURIComponent(val));
    }
  }
  getDateArray(start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
        if(this.todayMax==end){
          return arr;
        }
    }
    return arr;
}
}
