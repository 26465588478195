<div class="row">
    <div class="col-md-6 col-sm-12 col-xs-12  mt-3">
        <mat-card>
            <p class="CN-Tab-head1">Call Overview</p>

            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div style="min-width: 240px; height: 240px; max-width: 240px;"
                        [chart]="donutChartOutboundIVR"></div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-4">
                    <p class="CN-Select">{{call_counts[1]}}<br> <span class="cn-sub-title"> Answered (Selected)</span> </p>

                    <p class="CN-NotSelect">{{call_counts[2]}}<br> <span class="cn-sub-title"> Answered (Not Selected) </span> </p>
                    <p class="CN-Missed">{{call_counts[3]}}<br> <span class="cn-sub-title"> Missed </span> </p>

                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12  mt-3">
        <mat-card class="callDetailsCard">
            <p class="CN-Tab-head1">Call Details<span style="color:#ababab">&nbsp;&nbsp;Answered (Selected)</span> </p>
            <div>
                <mat-nav-list>
                    <div *ngFor="let item of callDetailsItems; let i = index;">
                    <mat-expansion-panel [ngClass]="{'matExp1':i==0, 'matExp2': i==1, 'matExp3': i==2}">
                      <mat-expansion-panel-header>
                       <mat-panel-title>
                           <span class="numExp">{{i+1}}</span>
                       <span class="ex-title"> {{item.header}}</span>  <span><img class="arExp" src="../assets/arrowExp.svg"/></span>
                      </mat-panel-title>
                      <mat-panel-description>
                       {{item.description}}
                      </mat-panel-description>
                      </mat-expansion-panel-header>
                  <!--start inside loop -->
                  <div class="expHd">
                    <div  style="width:26%">Option</div>
                    <div style="width:26%">Digit</div>
                    <div style="width:25%">Total</div>
                    <div style="width:23%"> </div>
                     </div>
                  <div *ngFor="let v of item.content;" style="width:100%">
                    <mat-expansion-panel  style="background-color: #eaeaea47 !important;
                    margin: 7px 2px !important;box-shadow: none !important; border-bottom: 2px dotted #ccc;">
                    <mat-expansion-panel-header >
                    <div style="width:100%;display:inline-flex;">
                       <div  style="width:33%"> {{v.option}}</div>
                       <div style="width:33%"> {{v.digit}}</div>
                       <div style="width:33%">{{v.total}} </div>
                        </div>
                    </mat-expansion-panel-header>
                  <div *ngIf="!v.content">No Record</div>
                     <div *ngIf="v.content">
                        <div *ngFor="let v1 of v.content" style="width:100%;display:inline-flex;" >
                            <div  style="width:33%"> {{v1.option}}</div>
                            <div style="width:33%"> {{v1.digit}}</div>
                            <div style="width:33%">{{v1.total}} </div>
                             </div>
                     </div>
                    </mat-expansion-panel>


                   </div>
                  <!--end inside loop-->
                    </mat-expansion-panel>
                </div>

                <!-- <mat-expansion-panel class="matExp1">
                    <mat-expansion-panel-header>
                     <mat-panel-title>
                         <span class="numExp">1</span>
                     <span class="ex-title"> Intrested</span>  <span><img class="arExp" src="../assets/arrowExp.svg"/></span>
                    </mat-panel-title>
                    <mat-panel-description>
                     2657
                    </mat-panel-description>
                    </mat-expansion-panel-header>

                <a mat-list-item routerLink>Item 1</a>
                  </mat-expansion-panel>
                    <mat-expansion-panel  class="matExp2">
                      <mat-expansion-panel-header>
                       <mat-panel-title>
                        <span class="numExp">2</span>
                        <span  class="ex-title">Not Intrested</span> <span><img class="arExp" src="../assets/arrowExp.svg"/></span>
                      </mat-panel-title>
                      <mat-panel-description>
                       767
                      </mat-panel-description>
                      </mat-expansion-panel-header>

                  <a mat-list-item routerLink>Item 1</a>
                    </mat-expansion-panel>

                      <mat-expansion-panel  class="matExp3">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="numExp">3</span>
                            <span  class="ex-title">   No Response</span>  <span><img class="arExp" src="../assets/arrowExp.svg"/></span>
                          </mat-panel-title>
                          <mat-panel-description>
                           20
                          </mat-panel-description>
                      </mat-expansion-panel-header>

                  <a mat-list-item routerLink>Item 1</a>
                    </mat-expansion-panel> -->
                  </mat-nav-list>

            </div>
        </mat-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <mat-card class="CN-Card cardW cardHeight">
            <p><span class="CN-Tab-head1">Answered Vs Missed</span> </p>
            <div [chart]="totalAnsMissedChart"> </div>
        </mat-card>
    </div>
    <div class="col-md-3 col-md-3 col-sm-12 col-xs-12">
        <mat-card class="CN-Card cardW cardHeight">
            <p class="CN-Tab-head1">Duration</p>
            <br>
            <p class="CN-avgCall">{{duration_counts[0]}}<br> <span class="cn-sub-title"> Average Call Duration </span> </p>
            <br>
            <hr class="hr1">
            <br>
            <p class="CN-avgResponse">{{duration_counts[1]}}<br> <span class="cn-sub-title"> Average Response Duration </span> </p>
            <br>
           </mat-card>
        </div>

</div>
