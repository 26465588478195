import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as _ from 'underscore';
import { MyserviceService } from '../myservice.service';
import { RtddataService } from '../rtdData.service';
import {Utils} from '../utils'

@Component({
	selector: 'app-badashboard-contacted-page',
	templateUrl: './badashboard-contacted-page.component.html',
	styleUrls: ['./badashboard-contacted-page.component.scss']
})
export class BadashboardContactedPageComponent implements OnInit, OnDestroy {

	_fetchData: any = [];
	sortedData: BehaviorSubject<[]> = new BehaviorSubject([]);
	agentFeed: Subscription=null;
	groupFeed: Subscription=null;
	util = new Utils();
	group_level_data:any;
	totaldialled:any;
	groupdata=[];
	groupid:any;
	user: any;
	constructor(private rtd: RtddataService, private myService: MyserviceService) {
		this.user = this.myService.get_loggedin_user();
	}
	ngOnInit() {
		this.getRtdValues();
	}

	formTable(arr?){
		this._fetchData = []
		if(arr && arr.length){
			this._fetchData = arr;
		}
		this.sortedData.next(this._fetchData.slice());
	}

	getRtdValues() {
		this.group_level_data = this.myService.get_bidashboard();
		//this.groupid = this.myService.get_asm_groupid();
		  // console.log(JSON.stringify(this.group_level_data));
		  console.log(this.groupid);
		   this.groupdata = Object.values(this.group_level_data);
		//console.log("-1--",JSON.stringify(this.groupdata))
		//console.log("-2-",JSON.stringify(this.groupdata[0].rtd_dialled_count));
		//this.totaldialled = this.groupdata[0].rtd_dialled_count;
		this.agentFeed = this.rtd.agentFeed.subscribe(data => {
			//console.log('agent feed', data);let finaldata =[];
			// let finaldata =[];
			// if(this.groupid)
			// {
			// 	console.log('agent feed', data);
			// 	for(let i in data)
			// 	{
			// 		//console.log(data[i]);
			// 		if(data[i].groupid == this.groupid)
			// 		{
			// 			finaldata.push(data[i]);
			// 		}
			// 	}
			// 	console.log(finaldata);
			// }
			// else{
			// 	console.log('agent feed', data);
				
			// 	for(let i in data)
			// 	{
			// 		//console.log(data[i])
			// 			finaldata.push(data[i]);
					
			// 	}
			// 	console.log(finaldata);
			// }
			let tlbase = _.groupBy(data, 'tl_name');
			// console.log('tlbase', tlbase);
			let arr = [];
			for(let tl in tlbase){
				let obj:any = {};
				obj.title = tl;
				obj.rData = [];
				this.groupid = tlbase[tl][0]['groupid'];
				tlbase[tl].forEach((agent:any) => {
					obj.rData.push({
						name: agent.head, 
						//connected: this.util.getPercentage(agent.rtd_contacted_count,agent.rtd_dialled_count),
						connected: agent.rtd_contacted_count, 
						totalContacted: agent.rtd_contacted_count, 
						dnc: agent.rtd_dnd_count, 
						wrongNumber: agent.rtd_wrong_number_dialed_count, 
						rnr: agent.rtd_rnr_count,
						dialled:agent.rtd_dialled_count
					})
				})
				arr.push(obj);
			}
			this.formTable(arr);
		})
		this.groupFeed = this.rtd.groupFeed.subscribe(data => {
			console.log('group feed', data);
			if (this.groupid) {
				console.log(data[this.groupid]['rtd_dialled_count']);
				this.totaldialled= data[this.groupid]['rtd_dialled_count'];

			} else if (data.hasOwnProperty(this.user.groupid)) {
				this.totaldialled = data[this.user.groupid]['rtd_dialled_count'];
			}
		})
	}

	sortData(sort: Sort) {
		const data = this._fetchData.slice();
		if (!sort.active || sort.direction === '') {
			this.sortedData.next(data);
			return;
		}

		let p = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'connected':
					return this.compare(a.connected, b.connected, isAsc);
				case 'totalContacted':
					return this.compare(a.totalContacted, b.totalContacted, isAsc);
				case 'dnc':
					return this.compare(a.dnc, b.dnc, isAsc);
				case 'wrongNumber':
					return this.compare(a.wrongNumber, b.wrongNumber, isAsc);
				case 'rnr':
					return this.compare(a.rnr, b.rnr, isAsc);
				case 'dialled':
						return this.compare(a.dialled, b.dialled, isAsc);
				default:
					return 0;
			}
		});
		this.sortedData.next(p);
	}
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	getConnected(d: any) {
		let total = 0;
		let total_dialled=0;
		
		for (let k = 0; k < d.rData.length; k++)
		{
			total += Number(d.rData[k].connected);
			total_dialled += Number(d.rData[k].dialled);
			//console.log(total+"--"+total_dialled);
			//return total;
		}
			//	return total;
			return parseFloat(((total/total_dialled)*100).toFixed(0));
	}
	getSummationData(variable){
		let total = 0;
		let dataValue:any = this.sortedData.getValue();
		if(dataValue && dataValue.length){
			for (let j = 0; j < dataValue.length; j++) {
				if(dataValue[j].rData && dataValue[j].rData.length){
					for (let k = 0; k < dataValue[j].rData.length; k++)
						total += Number(dataValue[j].rData[k][variable]);
				}
			}
		}
		return total;
	}
	getnoofagents(){
		let total = 0;
		let dataValue:any = this.sortedData.getValue();
		if(dataValue && dataValue.length){
			for (let j = 0; j < dataValue.length; j++) {
				if(dataValue[j].rData && dataValue[j].rData.length){
					for (let k = 0; k < dataValue[j].rData.length; k++)
						total += 1;
				}
			}
		}
		return total;
	}
	getAllConnected() {
		return parseFloat(((this.getSummationData('connected')/this.gettotaldialled())*100).toFixed(0));
		//this.util.getDivision(,);
		//return this.getSummationData('connected');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].connected);
		}
		return total; */
	}

	gettotaldialled(){
		return this.getSummationData('dialled');
	}

	getTotalContacted(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].totalContacted);
			return total;
		//return ((total/d.rData.length));
	}
	getAllTotalContacted() {
		//return this.util.getDivision(this.getSummationData('totalContacted'),this.getnoofagents());
		return this.getSummationData('totalContacted');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].totalContacted);
		}
		return total; */
	}
	getAllTotalperContacted() {
		//return this.util.getDivision(this.getSummationData('totalContacted'),this.getnoofagents());
		return this.getSummationData('totalContacted');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].totalContacted);
		}
		return total; */
	}
	getDNC(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].dnc);
			//return total;
			return ((total/d.rData.length));
	}
	getAllDNC() {
		return this.getSummationData('dnc');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].dnc);
		}
		return total; */
	}
	getAllagentDNC() {
		return this.util.getDivision(this.getSummationData('dnc'),this.getnoofagents());
		//return this.getSummationData('dnc');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].dnc);
		}
		return total; */
	} 

	getWrongNumber(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].wrongNumber);
			return total;
		//return ((total/d.rData.length));
	}
	getAllWrongNumber() {
		//return this.util.getPercentage(this.getSummationData('wrongNumber'),this.getnoofagents());
		return this.getSummationData('wrongNumber');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].wrongNumber);
		}
		return total; */
	}
	getAllagentWrongNumber() {
		return this.getSummationData('wrongNumber');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].wrongNumber);
		}
		return total; */
	}

	getRNR(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].rnr);
			return total;
			//return ((total/d.rData.length));
	}
	getAllRNR() {
		return this.util.getDivision(this.getSummationData('rnr'),this.getnoofagents());
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].rnr);
		}
		return total; */
	}
	 get_percent(){
		let totaldialled = this.totaldialled;
		//= this.gettotaldialled();
		let totalContacted = this.getSummationData('totalContacted');
        //console.log(totaldialled+"-----"+totalContacted);
        if(totaldialled > 0){
            if(totalContacted > 0){
                    //console.log('printing decimal',((num/denm)*100).toFixed(decimals));
                    return parseFloat(((totalContacted/totaldialled)*100).toFixed(0));
            }else{
                return (0);
            }
        }else{
            return (0);
        }
	 }

	ngOnDestroy() {
		this.agentFeed.unsubscribe();
		this.groupFeed.unsubscribe();
	}
}


