import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {

  @Input() values: PROGRESSBAR;

  mode: string = "indeterminate";
  value: number = 0;
  bufferValue: number = 0;
  active: boolean = true;
  color: string = '#fd6c21';
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('print values', this.values)
    this.active = this.values.enable;
    this.mode = this.values.mode;
  }

  ngOnInit(): void {
  }

}

export interface PROGRESSBAR {
  enable: boolean
  mode?: string
  buffervalue?: number
  value?: number
}
