import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';

import { ServicesService } from '../services.service';

@Component({
	selector: 'app-attribute-chart',
	templateUrl: './attribute-chart.component.html',
	styleUrls: ['./attribute-chart.component.scss']
})
export class AttributeChartComponent implements OnInit {
	@Input() chart_division: string;
	chart_data = null;

	chart = new Chart();

	constructor(private service: ServicesService) {

	}

	ngOnInit(): void {
		// console.log('Oninit chart type', this.chart_division);
		this.chart_data = this.service.table_data_map[this.chart_division]['chart_data'];
	}

	ngAfterViewInit() {
		// console.log('After view init', this.chart_division);
		this.service.table_data_map[this.chart_division]['chart_data'].subscribe(
			(d) => {
				// console.log('chart data received', d);
				this.chart_data = d;
				// console.log('printing chart data', this.chart_data);
				this.chart = new Chart({
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: 'pie'
					},
					title: {
						text: this.chart_data.title
					},
					credits: {
						enabled: false
					},
					accessibility: {
						point: {
							valueSuffix: '%'
						}
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer',
							dataLabels: {
								enabled: false
							},
							showInLegend: true
						}
					},
					series: [
						{
							name: this.chart_data.name,
							data: this.chart_data.data,
							type: undefined
						}
					]
				})
			}
		);
	}

}
