<div class="row ml-0 mr-0" style="overflow:hidden;">
    <div class="col-md-10 col-sm-10">
        <h3 class="h3-call" style="font-size: 19px;text-align: center;">During Call Form Summary</h3>
    </div>
    <div class="col-md-2 col-sm-2">
       <mat-icon class="CR" style="color:red;font-size:28px;" (click)="closeDialog()">cancel_outlined</mat-icon>
    </div>
</div>
<!-- <mat-card class="mtCard mt-4"> -->
    <div  class="bdr-bg" >&nbsp;</div>

<div > 
    <!-- *ngFor="let v of _dataRecord;let i=index;"    -->
    <div *ngFor="let section of treeView;let $index=index">
    <ul >
        <li class="container"><p class="labelOne"><span class="span-alphabet">{{getAlphabets($index+1)}}</span>{{section.title}}
             <mat-icon class="matAddRemove"  (click)="togglepanel($index)" *ngIf="isToggle[$index] == false">add_circle_outline </mat-icon> 
            <mat-icon class="matAddRemove"  (click)="togglepanel($index)" *ngIf="isToggle[$index] == true">remove_circle_outline</mat-icon></p>
              <div *ngIf="isToggle[$index] == true" >
                <ul *ngFor="let child of section.child;let $childIndex=index">
                  <li *ngIf="child.at == 'FT'">
                      <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                        <ul>
                          <li><p class="labelThree">Input </p></li>
                      </ul>
                  </li>

                  <li *ngIf="child.at == 'RT'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Rating</p></li>
                    </ul>
                </li>

                <li *ngIf="child.at == 'FN'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Input Number</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'NP'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Input Min/Max Number</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'DATE'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Month / Day / year</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'TIME'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Hour / Minute  / AM/PM</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'DATETIME'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Month / Day / year - Hour / Minute  / AM/PM</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'CB'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Checkbox</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'RB'">
                  <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                    <ul>
                      <li><p class="labelThree">Radio</p></li>
                      <ul *ngIf="child.rbShow==true && child.YES && (child.YES).length>0">
                        <p class="labelThree" style="color:black">Yes</p>
                        <li><p class="labelThree" *ngFor="let v of child.YES; let $yesindex = index" >
                          <span style="color:black">{{$yesindex+1}}.</span> {{v.at}}-{{v.q}}-{{v.rb_data}}
                        </p></li>
                    </ul>
                    <ul *ngIf="child.rbShow==true && child.NO && (child.NO).length>0">
                      <p class="labelThree" style="color:black">NO</p>
                      <li><p class="labelThree"*ngFor="let v of child.NO; let $noindex = index" >
                        <span style="color:black">{{$noindex+1}}.</span> {{v.at}}-{{v.q}}-{{v.rb_data}}
                      </p></li>
                  </ul>
                    <ul *ngIf="child.rbShow==true && child.CALLBACK && (child.CALLBACK).length>0">
                      <p class="labelThree" style="color:black">CALLBACK</p>
                      <li><p class="labelThree"*ngFor="let v of child.CALLBACK; let $cbindex = index" >
                        <span style="color:black">{{$cbindex+1}}.</span> {{v.at}}-{{v.q}}-{{v.rb_data}}
                      </p></li>
                  </ul>
                  </ul>
              </li>
                <!-- <li *ngIf="child.at == 'RB'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul *ngIf="child.rbShow==true && (child.YES).length>0">
                        <li><p class="labelThree"*ngFor="let v of child.YES" >
                          {{v.at}}-{{v.q}}-{{v.rb_data}}
                        </p></li>
                    </ul>
                </li> -->
                <li *ngIf="child.at == 'DD'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Select</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'SDD'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Select</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'MSDD'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Multi Select</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'YN'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Yes/No Button</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'FLP'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Input</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'ATTACHMENT'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Upload</p></li>
                    </ul>
                </li>

                <li *ngIf="child.at == 'BLANK'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Input</p></li>
                    </ul>
                </li>
                <li *ngIf="child.at == 'BT'">
                    <p class="labelTwo"><span class="span-alphabet">{{$childIndex + 1}}</span>{{ child.q }} </p>
                      <ul>
                        <li><p class="labelThree">Button</p></li>
                    </ul>
                </li>

                  <!-- <li><p  class="labelTwo"><span class="span-alphabet">2</span>What is your current state </p></li>
                  <li class="container"><p  class="labelTwo"><span class="span-alphabet">3</span>What is your current city ? </p>
                      <ul>
                          <li><p  class="labelThree">Bangalore</p></li>
                          <li><p  class="labelThree">Kolkata</p></li>
                          <li><p  class="labelThree">Chennai</p></li>
                      </ul>
                  </li> -->
              </ul>
              </div>
          </li>
          <!-- <li class="container"><p class="labelOne"><span  class="span-alphabet">B</span>Education Details </p>
              <ul>
                  <li><p  class="labelTwo"><span class="span-alphabet">1</span>Do you have graduation pass certificate holding </p>
                        <ul>
                          <li><p  class="labelThree">How many years of experience do you have </p></li>
                      
                      </ul>
                  </li>
                  <li><p  class="labelTwo"><span class="span-alphabet">2</span>What is your year of pass out </p></li>
              
              </ul>
          </li>
          <li class="container"><p class="labelOne"><span  class="span-alphabet">C</span>Experience Details </p>
              <ul>
                  <li class="empty"><p>empty</p></li>
              </ul>
          </li> -->
      </ul>
    </div>
</div>
<!-- </mat-card> -->
