<div>
    <form>
        <div class="row  mt-3" style="border-bottom: 1px solid #ccc;">
            <div class="col-md-10 col-sm-10 col-xs-10">
                <h5 class="hd5">View Score</h5>
            </div>
            <div class="col-md-2  col-sm-2 col-xs-2">
                <i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
            </div>
        </div>
        <table class="tbl mt-3">
            <tr >
                <td><b>Name</b> </td>
                <td> <b>Score</b></td>
                <td> <b>Status</b></td>
            </tr>
            <tr *ngFor="let test of allTest">
                <td>{{test.name}}</td>
                <td> {{test.score}}</td>
                <td>{{test.eligible}} </td>
            </tr>
        </table>

    </form>
</div>