<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	  
	  
	</mat-drawer>

<div class="container-fluid main-section-layout" style="background: #f6f6f6;">
<ngb-alert [type]="alert.type" class="ng-alert-dialog" *ngIf="!alertClosed"  (close)="alertClosed = true">{{ alert.message }}</ngb-alert>


<div class="row">		
    <div class="col-md-12">
      <!-- <h4 class="titlehead" style="padding-left: 3px;margin-bottom: 24px;;z-index: 9;font-size: 20px;">Inbound Calls</h4> -->
      </div>
      </div>
      <mat-card class="CN-Card">
        <div class="row">
            <div class="col-md-3 col-sm-12 col-xs-12">
                <span>
                    <mat-select [ngClass]="'arrowDDLcs'" class="dropdownCs" [(ngModel)]="selectedProject">
                        <mat-option [value]="selectedProject">{{selectedProject}}</mat-option>                          
                    </mat-select>
                </span>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <mat-select [ngClass]="'arrowDDLcs'"  class="dropdownCs" [(value)]="selectedChannel" (selectionChange)="navigateTo($event.value)">
                    <mat-option value="view/outbound" *ngIf="settings.web && settings.web[0].definition.includes('outbound')==true">Outbound</mat-option>
                <mat-option value="dashboard-inbound" *ngIf="settings.web && settings.web[0].definition.includes('inbound')==true">Inbound</mat-option>
                <mat-option value="whatsapp-dashboard" *ngIf="settings.web && settings.web[0].definition.includes('whatsapp')==true">Whatsapp</mat-option>
                </mat-select>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12" style="position: relative;top: -9px;" *ngIf="selectedTabSts == 'call_status'">
                <mat-form-field  style="width:110px">
                    <input matInput [matDatepicker]="frompicker" [max]="maxDate"  placeholder="From" readonly
                        (click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)" [(ngModel)]="callStartDate">
                    <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                    <mat-datepicker #frompicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="width:110px"  class="ml-3">
                    <input #fromInput matInput [matDatepicker]="topicker" placeholder="To" [max]="maxDate" readonly (click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)" [(ngModel)]="callEndDate">
                    <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                    <mat-datepicker #topicker></mat-datepicker>
                </mat-form-field>
                <button mat-button class="btnAdd ml-3" (click)="refreshData()">Go</button>
            </div>


            <div class="col-md-6 col-sm-12 col-xs-12" style="position: relative;top: -9px;" *ngIf="selectedTabSts == 'slot_status'">
                <mat-form-field style="width:110px">
                    <input matInput [matDatepicker]="frompicker" placeholder="From" readonly
                        (click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [(ngModel)]="slotStartDate">
                    <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                    <mat-datepicker #frompicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="width:110px" class="ml-3">
                    <input #fromInput matInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [(ngModel)]="slotEndDate">
                    <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                    <mat-datepicker #topicker></mat-datepicker>
                </mat-form-field>
                <button mat-button class="btnAdd ml-3" (click)="refreshDataCallSlot()">Go</button>
            </div>












            <!-- <div class="col-md-3 col-sm-12 col-xs-12 cn-dat">{{getCurrentDate()}}</div> -->
        </div>
    </mat-card>
    <div class="row">
        <!-- <div class="col-md-12 col-sm-12 col-xs-12 pb-3">
            <span style="font-size: 30px; color: #7030a0;font-weight: 600;">Inbound Calls</span>
        </div> -->
        <div class="col-md-3 col-sm-12 col-xs-12 pr-0">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Inbound Data</mat-card-title>
                </mat-card-header>
                <mat-card-content>

                    <table class="Cam-table">
                        <tr><td><span> Data</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="inboundDashboardData.total_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData1"><span>{{inboundDashboardData.total_calls_perc}}% ({{inboundDashboardData.total_calls}})</span></td>
                        </tr>
                        <tr><td><span>Received Calls</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="inboundDashboardData.received_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData2"><span>{{inboundDashboardData.received_calls_perc}}% ({{inboundDashboardData.received_calls}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Abondoned Calls</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="inboundDashboardData.abonden_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData3"><span>{{inboundDashboardData.abonden_calls_perc}}% ({{inboundDashboardData.abonden_calls}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Total AHT</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-four'" [value]="inboundDashboardData.total_aht_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData4"><span>{{inboundDashboardData.total_aht_perc}}% ({{inboundDashboardData.total_aht_label}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Avg AHT</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-five'" [value]="inboundDashboardData.avg_aht_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData5"><span>{{inboundDashboardData.avg_aht_perc}}% ({{inboundDashboardData.avg_aht_label}})</span>
                         </td>
                        </tr>
                      </table>



                    <!-- <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Data</span><hr class="level1" [style.width.%]="inboundDashboardData.total_calls_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{inboundDashboardData.total_calls_perc}}%</span>({{inboundDashboardData.total_calls}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Received Calls</span><hr class="level2" [style.width.%]="inboundDashboardData.received_calls_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{inboundDashboardData.received_calls_perc}}%</span>({{inboundDashboardData.received_calls}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Abondoned Calls</span><hr class="level3" [style.width.%]="inboundDashboardData.abonden_calls_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{inboundDashboardData.abonden_calls_perc}}%</span>({{inboundDashboardData.abonden_calls}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Total AHT</span><hr class="level4" [style.width.%]="inboundDashboardData.total_aht_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{inboundDashboardData.total_aht_perc}}%</span>({{inboundDashboardData.total_aht_label}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Avg AHT</span><hr class="level5" [style.width.%]="inboundDashboardData.avg_aht_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{inboundDashboardData.avg_aht_perc}}%</span>({{inboundDashboardData.avg_aht_label}})</span>
                    </div> -->
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12 pr-0">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Outbound Data</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="Cam-table">
                        <tr><td><span> Data</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="outboundDashboardData.total_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData1"><span>{{outboundDashboardData.total_calls_perc}}% ({{outboundDashboardData.total_calls}})</span></td>
                        </tr>
                        <tr><td><span>Received Calls</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="outboundDashboardData.received_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData2"><span>{{outboundDashboardData.received_calls_perc}}% ({{outboundDashboardData.received_calls}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Abondoned Calls</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="outboundDashboardData.abonden_calls_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData3"><span>{{outboundDashboardData.abonden_calls_perc}}% ({{outboundDashboardData.abonden_calls}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Total AHT</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-four'" [value]="outboundDashboardData.total_aht_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData4"><span>{{outboundDashboardData.total_aht_perc}}% ({{outboundDashboardData.total_aht_label}})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><span>Avg AHT</span>
                            <mat-progress-bar mode="determinate" [ngClass]="'color-five'" [value]="outboundDashboardData.avg_aht_perc"></mat-progress-bar>
                          </td>
                          <td class="tdData5"><span>{{outboundDashboardData.avg_aht_perc}}% ({{outboundDashboardData.avg_aht_label}})</span>
                         </td>
                        </tr>
                      </table>



                    <!-- <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Data</span><hr class="level1" [style.width.%]="outboundDashboardData.total_calls_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{outboundDashboardData.total_calls_perc}}%</span>({{outboundDashboardData.total_calls}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Received Calls</span><hr class="level2" [style.width.%]="outboundDashboardData.received_calls_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{outboundDashboardData.received_calls_perc}}%</span>({{outboundDashboardData.received_calls}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Abondoned Calls</span><hr class="level3" [style.width.%]="outboundDashboardData.abonden_calls_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{outboundDashboardData.abonden_calls_perc}}%</span>({{outboundDashboardData.abonden_calls}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Total AHT</span><hr class="level4" [style.width.%]="outboundDashboardData.total_aht_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{outboundDashboardData.total_aht_perc}}%</span>({{outboundDashboardData.total_aht_label}})</span>
                    </div>
                    <div class="tile-row">
                        <span class="pr-4 flex-fill"> <span class="tile-title">Avg AHT</span><hr class="level5" [style.width.%]="outboundDashboardData.avg_aht_perc"/></span>
                        <span class="tile-text"><span class="tile-percent">{{outboundDashboardData.avg_aht_perc}}%</span>({{outboundDashboardData.avg_aht_label}})</span>
                    </div> -->
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12 pr-0">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Agents Status</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Total Agents</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{inboundAgentsData['agents'].length}}   </span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Today Active</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.inboundAgentsData['Total Active Agents']}}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Contacted</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.inboundAgentsData['Contacted Agents']}}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Avg Connected Calls</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.inboundAgentsData['Avg Contacted Calls'] }}</span>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-md-7">
                            <span class="tile-percent">Above Avg Connected Calls</span>
                        </div>
                        <div class="col-md-5">
                            <span class="tile-percent-primary pr-2">{{this.inboundAgentsData['Agents Above Avg Connected Calls'] }}</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Call Status</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="tile-row-pie" style="justify-content: center;">
                        <div style="height: 240px; width: 240px;" [chart]="agentStatusChart"></div>
                        <div>
                            <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #e40f59;"></span><span style="padding-left: 5px;"> <span style="color: #e40f59; font-weight: 500; font-size: 20px;">{{inboundAgentsData['0-5 Min']}}</span> <p class="tile-text mb-1">0-5 Mins</p> </span></div>
                            <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #08566e;"></span><span style="padding-left: 5px;"> <span style="color: #08566e; font-weight: 500; font-size: 20px;">{{inboundAgentsData['5-30 Min']}}</span> <p class="tile-text mb-1">5-30 Mins</p> </span></div>
                            <div class="tile-row-pie"><span> <hr class="pie-series" style="background: #1b7ec7;"></span><span style="padding-left: 5px;"> <span style="color: #1b7ec7; font-weight: 500; font-size: 20px;">{{inboundAgentsData['>30 Min']}}</span> <p class="tile-text mb-1">> 30 Mins</p> </span></div>

                        </div>
                    </div>  
                    <!-- <div class="d-flex flex-row justify-content-between py-2">
                        <span>Submitted</span>
                        <span class="pr-2">{{ summary.forms.total }}</span>
                    </div> -->

                </mat-card-content>
            </mat-card>
        </div>

    </div>


    <div class="md-12 sm-12 xs-12">
        <mat-card class="mat-card-table-responsive">
            <div class="row pdt filter-div  align-items-center">
                <div class="col-md-2 col-sm-12 col-xs-12 ">  
                    <button mat-button [matMenuTriggerFor]="export_menu" style="height: 38px;font-size: 17px;" aria-label="Example icon-button with a menu">
                        Reports
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                      <mat-menu #export_menu="matMenu">
                     
                        <button mat-menu-item (click)="downloadInboundcallsReport()">Call Report</button>
                        <button mat-menu-item (click)="downloadAgentActivityReport()">Agent Activity Report</button>
                        <button mat-menu-item (click)="downloadAgentLoginReport()">Agent Login Report</button>
                      </mat-menu>
                </div>
                <!-- <div class="col-md-2" style="padding-left: 20px;">
                  
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn "style="background: #3fb91f !important;border: 1px solid #3fb91f !important;"
                            [ngClass]="(selectedTabSts == 'call_status') ? 'btn-secondary' : 'btn-outline-secondary' "
                            (click)="changeTogleStatus('call_status')">Call Status</button>
                        <button type="button" class="btn "
                            [ngClass]="(selectedTabSts == 'slot_status') ? 'btn-secondary' : 'btn-outline-secondary' "
                            (click)="changeTogleStatus('slot_status')">Slot Status</button>
                    </div>
                </div> -->
                <!-- <div class="col-md-3 col-sm-2 col-xs-12">
                </div> -->

                <div class="col-md-5" *ngIf="selectedTabSts == 'call_status'">
                    <!-- <mat-form-field >
                        <input matInput [matDatepicker]="frompicker" [max]="maxDate"  placeholder="From" readonly
                            (click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)" [(ngModel)]="callStartDate">
                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                        <mat-datepicker #frompicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field  class="ml-3">
                        <input #fromInput matInput [matDatepicker]="topicker" placeholder="To" [max]="maxDate" readonly (click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)" [(ngModel)]="callEndDate">
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                    <button mat-button class="btnAdd ml-3" (click)="refreshData()">Go</button> -->
                    <div ngbDropdown class="d-inline-block ml-2">
                        <!-- <span class="table-tag px-2" style="color:#4dbe20;">Select Agents</span> -->
                        <button class="btn btn-outline-secondary" style="border-radius: 6px;" id="dropdownForm1" ngbDropdownToggle>{{selectAll ? 'All Selected' : selectedAgentsLen+' Agents'}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                          <div class="px-4 py-3" style="height: 300px;overflow-y: auto;">
                            <mat-checkbox (change)="updateCheck()"
                            class="example-margin"
                            [(ngModel)]="selectAll">
                          Select All
                        </mat-checkbox> 
                            <section *ngFor="let ing of selectedAgents; let i = index" class="example-section">
                                <mat-checkbox (change)="selectChildren()"
                                   [(ngModel)]="ing.checked">
                                  {{ing.name}}
                                </mat-checkbox>
                              </section>
                               
                          </div>
                        </div>
                      </div>
                      <button mat-button class="btnAdd ml-3" (click)="refreshData()">Go</button>
                    <!-- <section *ngFor="let ing of selectedAgents; let i = index" class="example-section">
                        <mat-checkbox (change)="selectChildren()"
                           [(ngModel)]="ing.checked">
                          {{ing.name}}
                        </mat-checkbox>
                      </section>
                        <mat-checkbox (change)="updateCheck()"
                            class="example-margin"
                            [(ngModel)]="selectAll">
                          Select All
                        </mat-checkbox> -->
                </div>
                
             
                <div class="col-md-5" *ngIf="selectedTabSts == 'slot_status'">
                    <!-- <mat-form-field >
                        <input matInput [matDatepicker]="frompicker" placeholder="From" readonly
                            (click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [(ngModel)]="slotStartDate">
                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                        <mat-datepicker #frompicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field  class="ml-3">
                        <input #fromInput matInput [matDatepicker]="topicker" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [(ngModel)]="slotEndDate">
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                    <button mat-button class="btnAdd ml-3" (click)="refreshDataCallSlot()">Go</button> -->
                    <button mat-button class="btnAdd ml-3" (click)="addSlot()"><i class="fa fa-cog" aria-hidden="true"></i></button>
                </div>

                <div class="col-md-3" style="margin-left: auto">
                    <mat-form-field class="wdh">
                        <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" />
                        <i class="fa fa-search" matSuffix aria-hidden="true"></i>
                    </mat-form-field>
                </div>
            </div>
            <div class="row pdt filter-div  align-items-center">
                <div class="col-md-12 col-sm-12 col-xs-12 ">
                    <!-- <span style="font-size: 14px;color: #445c6d; font-weight: bold;">Export</span> -->
                    <!-- <mat-form-field [ngClass]="'inputClr'">
                        <mat-select placeholder="Export" (selectionChange)="downloadReport($event.value)" >
                            <mat-option value="call">Call Report</mat-option>
                            <mat-option value="audit">Audit Report</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    
                    <!-- <div ngbDropdown class="d-inline-block ml-2">
                        <span style="font-size: 14px; color:#4dbe20; font-weight: bold;cursor: pointer;"  id="dropdownBasic1" ngbDropdownToggle>Export</span>
                        <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                        <button ngbDropdownItem (click)="downloadInboundcallsReport()" >Call Report</button>
                        <button ngbDropdownItem (click)="downloadAgentActivityReport()">Agent Activity Report</button>
                        <button ngbDropdownItem (click)="downloadAgentLoginReport()">Agent Login Report</button>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                    *ngIf="selectedTabSts == 'call_status'" style="box-shadow: none !important;">
                    <ng-container matColumnDef="user_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Id </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Name </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Agent Live Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span class="statusCircle" style="position: relative;margin: 0;"
                                [ngStyle]="{'background-color': element.color_code, border: 'none' }"></span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ib_total_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> IB Calls Offered </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ib_total_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ib_received_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>IB Calls Answered </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ib_received_calls || 0 }}</mat-cell>
                    </ng-container>
                   
                    <ng-container matColumnDef="ib_abonden_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> IB Calls Abandoned </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.ib_abonden_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ib_total_aht_in_sec">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> IB AHT </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ getDivision((element.ib_total_aht_in_sec || 0),60,2) }} <br/>[{{ element.ib_aht_in_format || "0:0:0" }}]</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ob_total_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> OB Calls Dialled </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ob_total_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ob_received_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> OB Calls Connected </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ob_received_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ob_abonden_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> OB Calls Not Connected </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ob_abonden_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ob_total_aht_in_sec">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> OB AHT </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ getDivision((element.ob_total_aht_in_sec || 0),60,2) }}<br/>[{{ element.ob_aht_in_format || "0:0:0" }}]</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="login_hours">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Overall Login Hrs </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.login_hours || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="audited_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Audited Calls </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.audited_calls || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="avg_score">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Avg. Audited Score </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.avg_score || 0 }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ctc_calls">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>C-T-C Audited </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ctc_calls || 0 }}</mat-cell>
                    </ng-container><ng-container matColumnDef="ctc_score">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Avg. C-T-C Score </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.ctc_score || 0 }}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef=displayedColumnsCallStatus></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsCallStatus"></mat-row>
                </table>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                    *ngIf="selectedTabSts == 'slot_status'" style="box-shadow: none !important;">
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.date }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="time">
                        <mat-header-cell *matHeaderCellDef> Slot Time </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.time }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="capacity">
                        <mat-header-cell *matHeaderCellDef> Capacity </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.capacity }}</mat-cell>
                        
                    </ng-container>
                    <ng-container matColumnDef="booked">
                        <mat-header-cell *matHeaderCellDef> Booked </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.booked }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="waiting">
                        <mat-header-cell *matHeaderCellDef> Waiting </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.waiting }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="occupancy">
                        <mat-header-cell *matHeaderCellDef> Occupancy </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.occupancy }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.status }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="id" >
                        <mat-header-cell *matHeaderCellDef [style.display]="'none'"> Id </mat-header-cell>
                        <mat-cell *matCellDef="let element" [style.display]="'none'">{{ element.id }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="options">
                        <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
                        <mat-cell *matCellDef="let element" (click)="editSlot(element)"><i class="fa fa-edit CR" style="font-size: 18px;color: #526778;"></i></mat-cell>
                       
                    </ng-container>

                    <mat-header-row *matHeaderRowDef=displayedColumnsCallSlot></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsCallSlot"></mat-row>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

        </mat-card>
        
    </div>

</div>
<div id="cover-spin" *ngIf="currentLoader"></div>
</mat-drawer-container>