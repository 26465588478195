<div class="row">
	<div class="col-md-12">
		<mat-card style="margin: 0 10px;box-shadow: 0px 0px 4px 0px #dfdfdf;">
			<div class="row">
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon14.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1">0</span>
								<span class="nameLabel1">QDE</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon15.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#50bafd;">0</span>
								<span class="nameLabel1">Gen</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon16.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#f3385d;">0</span>
								<span class="nameLabel1">Approved</span>
							</td>
						</tr>
					</table>
				</div>
			</div>

		</mat-card>
	</div>
</div>
<div class="row ">
	<div class="col-md-12">
		<mat-card style="margin: 0 12px;" class="mt-3">
			<mat-button-toggle-group class="matBtnTogBA" #group="matButtonToggleGroup" [value]="selecteBtnSM"
				(change)="smChangeToggle($event.value)">
				<mat-button-toggle value="ZSM">ZSM</mat-button-toggle>
				<mat-button-toggle value="RSM">RSM</mat-button-toggle>
				<mat-button-toggle value="ASM">ASM</mat-button-toggle>
			</mat-button-toggle-group>
			<div class="row mt-4 mb-3">
				<div class="col-md-3">
					<mat-form-field style="width:100%">
						<mat-select [(value)]="selectedDate" placeholder="Choose Date"
							(selectionChange)="dateChangeDDL($event.value)">
              <mat-option value="Today">Today</mat-option>
							<mat-option value="Yesterday">Yesterday</mat-option>
							<mat-option value="customDate">Custom Date</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-3" *ngIf="showHideDateRangeDiv">
					<mat-form-field style="width:100%">
						<mat-label>Select Date Range</mat-label>
						<mat-date-range-input [formGroup]="rangeDate" [rangePicker]="rangeCustomDatePicker"
							[max]="maxDate">
							<input matStartDate formControlName="startDate" placeholder="Start date" readonly
								(click)="rangeCustomDatePicker.open()">
							<input matEndDate formControlName="endDate" placeholder="End date" readonly
								(click)="rangeCustomDatePicker.open()">
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="rangeCustomDatePicker"></mat-datepicker-toggle>
						<mat-date-range-picker #rangeCustomDatePicker></mat-date-range-picker>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<app-central-dasboard-dd-section-part></app-central-dasboard-dd-section-part>
				</div>
				<div class="col-md-3">
					<mat-form-field style="width:100%">
						<input matInput #searchInput [(ngModel)]="nameSearch" (keyup)="applyFilter($event.target.value)"
							autocomplete="off" placeholder="Search">
						<i matSuffix class="fa fa-search searchicon" style="color: #d5d5d5;" aria-hidden="true"></i>
					</mat-form-field>
				</div>
				<div class="col-md-3 mt-2">
					<button mat-button class="downloadBtn"> Download <mat-icon class="ml-2">file_download</mat-icon>
					</button>
				</div>
			</div>
			<table matSort (matSortChange)="sortData($event)" class="vTableV">
				<tr>
					<th mat-sort-header="name">NAME</th>
					<th mat-sort-header="qdeGenerated"> QDE GENERATED</th>
					<th mat-sort-header="qdeRejected">QDE REJECTED</th>
					<th mat-sort-header="contactedAgent">CONTACTED / AGENT</th>
					<th mat-sort-header="qdeAgent">QDE / AGENT</th>
					<th mat-sort-header="score">SCORE</th>
				</tr>
				<tbody>

					<ng-container
						*ngFor="let v of sortedData | async | customerSearchFilter: nameSearch;let j = index;let first=first;">
						<tr *ngIf="v.visible">
							<td>
								<span class="v-actionViewMore" [ngStyle]="{'text-transform':'Capitalize','color':getcolorStatus(isASM_selected,stage_setting_access)}" (click)="actionViewMore(v)">
									{{v.name}}
								</span>
							</td>
							<td>{{v.qdeGenerated || 0}}</td>
							<td>{{v.qdeRejected || 0}}</td>
							<td>{{v.contactedAgent || 0}}</td>
							<td>{{v.qdeAgent || 0}}</td>
							<td>{{v.score || 0}}</td>
						</tr>
					</ng-container>
				</tbody>
				<tfoot>
					<tr style="background: #f3f3f3;">
						<td><b>All Total</b></td>
						<td>{{getTotalValue('qdeGenerated') || 0}}</td>
						<td>{{getTotalValue('qdeRejected') || 0}}</td>
						<td>{{getTotalValue('contactedAgent') || 0}}</td>
						<td>{{getTotalValue('qdeAgent') || 0}}</td>
						<td>{{getTotalValue('score') || 0}}</td>
					</tr>
				</tfoot>
			</table>
		</mat-card>
	</div>
</div>
