<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
     <mat-card>
         <h2 class="d-head">Agents in Training <img src="../assets/underline.svg"
            class="linebelow"> </h2>

<div class="searchFix">
    <mat-form-field style="font-size: 12px;" appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH AN AGENT">
        <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
      </mat-form-field>
</div>
<br>
<div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

<ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef>Name </th>
  <td mat-cell *matCellDef="let element">{{element.name}} </td>
</ng-container>

<ng-container matColumnDef="email">
  <th mat-header-cell *matHeaderCellDef>User Email Id </th>
  <td mat-cell *matCellDef="let element"> {{element.email}} </td>
</ng-container>

<ng-container matColumnDef="id">
  <th mat-header-cell *matHeaderCellDef> User Id </th>
  <td mat-cell *matCellDef="let element"> {{element.agent_reference_id || element.id}} </td>
</ng-container>

<ng-container matColumnDef="batch_code">
  <th mat-header-cell *matHeaderCellDef>Batch Id </th>
  <td mat-cell *matCellDef="let element"> {{element.batch_code}} </td>
</ng-container>

<ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element" [ngStyle]="getStatusColor(element.status)"> {{element.agent_status}} </td>
  </ng-container>
<ng-container matColumnDef="score">
    <th mat-header-cell *matHeaderCellDef> Score </th>
    <td mat-cell *matCellDef="let element" [disable]="element.score==null?false:true"> 
      <!-- {{element.score}} *ngIf -->
      <span class="vscore" [ngClass]="element.score==null?'vsclass':''" (click)="viewScoreDialog(element)" >View Score</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="quality_score">
    <th mat-header-cell *matHeaderCellDef>Audit Score </th>
    <td mat-cell *matCellDef="let element"> {{element.mock_audit_score}} </td>
  </ng-container>
  <ng-container matColumnDef="uncertify_count">
    <th mat-header-cell *matHeaderCellDef> Recertification  </th>
    <td mat-cell *matCellDef="let element"> {{element.uncertify_count}} </td>
  </ng-container>
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef> Edit </th>
    <td mat-cell *matCellDef="let element" (click)="openAgentsInfo(element)"> <span class="detailsCss">View Details</span> </td>
  </ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [ngClass]="'paginator-training'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
            
</mat-card>
</div>
</div>

