import { Component, OnInit,ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AgentInfoDialogComponent } from '../agent-info-dialog/agent-info-dialog.component';
import { ApiService } from '../api.service';
import { CertificationScoreDialogComponent } from '../auditor/certification-score-dialog/certification-score-dialog.component';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.scss']
})
export class AgentsListComponent implements OnInit {

  displayedColumns = ['name', 'email', 'id', 'batch_code','status','score','quality_score','uncertify_count','edit'];
  dataSource:any = new MatTableDataSource();
  api_end_point:any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private dialog: MatDialog,private API: ApiService,private myservice: MyserviceService) { }
 
  ngOnInit() {
	this.api_end_point = this.myservice.get_API_End_Point();
    this.getTabledata();    
  }
  getTabledata(){
    this.API.getAgentList("In Training",null,null,null,this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){
         let elementData=_response;
			// console.log(elementData);
			// debugger;
         this.dataSource = new MatTableDataSource(elementData);
         this.dataSource.paginator = this.paginator;
         this.dataSource.filterPredicate = function(data:any, filter: any): boolean {
          return data.name.toString().toLowerCase().includes(filter) || data.email.toString().toLowerCase().includes(filter) || data.id.toString().toLowerCase().includes(filter) || data.batch_code==filter|| data.status.toString().toLowerCase().includes(filter)
        };
     
      }
    }
    )
  }

  viewScoreDialog(element:any){

	//console.log(element);
	//debugger;
	if(element.score==null){
		return;
	}
    var dialogRef = this.dialog.open(CertificationScoreDialogComponent, {
			width: '35%',
      disableClose:true,
			data: {data: element}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
			}
		});
  }
  
  applyFilter(filterValue: any) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
	getStatusColor(val) {
		let obj = {
			'color': 'Inherit'
		}
		switch (val) {
			case 'Active':
				obj.color = 'gray';
				break;
			case 'Inactive':
				obj.color = 'red';
				break;
		}
		return obj;
	}

	openAgentsInfo(element:any) {
		const dialogRef = this.dialog.open(AgentInfoDialogComponent, {
			width: '62%',
			disableClose:true,
			data: element
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
		//	console.log('The dialog was closed');
		  });
	}
}
// let ELEMENT_DATA = [
    //   {name: 'Ajay',useremailid:'Aj12e3@gmail.com',userid:'11', batchid: '32EQER', status: 'Active'},
    //   {name: 'veenit',useremailid:'Aj12e3@gmail.com',userid:'11', batchid: '32EQER', status: 'Active'},
    //   {name: 'Ajay',useremailid:'Aj12e3@gmail.com',userid:'11', batchid: '32EQER', status: 'Active'},
    //   {name: 'Ajay',useremailid:'Aj12e3@gmail.com',userid:'11', batchid: '32EQER', status: 'Inactive'},
    //   {name: 'Ajay',useremailid:'Aj12e3@gmail.com',userid:'11', batchid: '32EQER', status: 'Active'},
    //   {name: 'Ajay',useremailid:'Aj12e3@gmail.com',userid:'11', batchid: '32EQER', status: 'Inactive'},
    //   {name: 'Ajay',useremailid:'Aj12e3@gmail.com',userid:'11', batchid: '32EQER', status: 'Active'},
    //   {name: 'Ajay',useremailid:'Aj12e3@gmail.com',userid:'11', batchid: '32EQER', status: 'Active'},
    // ];
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    // this.dataSource.paginator = this.paginator;


