import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { ManagementTeamDialogComponent } from '../management-team-dialog/management-team-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatInput } from '@angular/material/input';

@Component({
	selector: 'app-management-team',
	templateUrl: './management-team.component.html',
	styleUrls: ['./management-team.component.scss'],
	providers: [Title]
})
export class ManagementTeamComponent implements OnInit {

	@ViewChild('searchfeild', {
		read: MatInput
	}) searchInput: MatInput;
	currentTitle = 'managementTeam';
	displayedColumns = ['sno', 'active_status', 'name', 'email', 'phone', 'role', 'address2','job_profile','status', 'sip_id','reportTo', 'coordinator','work_option','profile_status', 'options'];
	dataSource: any = new MatTableDataSource();
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	AllFetchData: any;
	loadForDdlData: any;
	me_name: any;
	_name: any;
	_email: any;
	_phone: any;
	ddlGroup: any;
	_ddlGp: any;
	_ddlGroupGroupsLevel0: any;
	_ddlGroupGroupsLevel1: any;
	_ddlGroupGroupsLevel2: any;
	_ddlGroupGroupsLevel3: any;
	_ddlGroupGroupsLevel4: any;
	_ddlGroupGroupsLevel5: any;
	_ddlGroupGroupsLevel6: any;
	_ddlGroupGroupsLevel7: any;
	_ddlGroupGroupsLevel8: any;
	_ddlGroupGroupsLevel9: any;
	_ddlGroupGroupsLevel10: any;
	_ddlGroupGroupsLevel11: any;
	_ddlGroupGroupsLevel12: any;
	selectedAllFirstOption: any = "All";
	selectedAllOption: any = "All";
	selectedAllOptionLevel0: any = "All";
	selectedAllOptionLevel1: any = "All";
	selectedAllOptionLevel2: any = "All";
	selectedAllOptionLevel3: any = "All";
	selectedAllOptionLevel4: any = "All";
	selectedAllOptionLevel5: any = "All";
	selectedAllOptionLevel6: any = "All";
	selectedAllOptionLevel7: any = "All";
	selectedAllOptionLevel8: any = "All";
	selectedAllOptionLevel9: any = "All";
	selectedAllOptionLevel10: any = "All";
	selectedAllOptionLevel11: any = "All";
	selectedAllOptionLevel12: any = "All";
	AllMergedData: any;
	showHideOptionDDL: boolean = false;
	typeValueDDL: any;
	myUser: any;
	currentUrl: String;
	allNonUsers: any[] = [];
	selectedLevel: any;
	selectedValue: any;
	_role = [{ name: 'Tele Caller', role: 'user' }, { name: 'Non Tele Caller', role: 'non-user' }, { name: 'Admin', role: 'admin' }, { name: 'Data Manager', role: 'data-manager' }, { name: 'Super Admin', role: 'superadmin' }];
	showHideSpaceAuditorTeam:boolean=false;
	showHideSpaceManagementTeam:boolean=true;
	api_end_point:any;
	wdFlex12:any="col-md-10";
	constructor(private titleService: Title, private dialog: MatDialog, public router: Router, private API: ApiService, private service: MyserviceService, private snackBar: MatSnackBar) { }

	ngOnInit() {
		this.titleService.setTitle('Contiinex | Team Management');
		this.api_end_point = this.service.get_API_End_Point();
		this.getManagemantTeamTable();
		this.myUser = this.service.get_loggedin_user();
		console.log(this.myUser)
		this.currentUrl = this.router.url;
		//debugger;
		let _path=window.location.pathname;
		if(_path=="/auditor/team"){
			this.showHideSpaceAuditorTeam=false;
			this.showHideSpaceManagementTeam=false;
			this.wdFlex12="col-md-12";
		}
	}

	getManagemantTeamTable() {
		// this.allNonUsers = [];
		this.API.getManagmentTeam(this.api_end_point).subscribe((response: any) => {
			if (response.success) {
				let ELEMENT_DATA = response.success;
				this.AllFetchData = ELEMENT_DATA;
				if(this.selectedLevel){
					this.onChange(this.selectedValue, this.selectedLevel - 1);
					return;
				}else{
					
				this.loadForDdlData = this.AllFetchData.me;
				this.me_name = this.loadForDdlData.name ? this.loadForDdlData.name : this.myUser.name;
				this._ddlGp = this.AllFetchData.groups;
				this.ddlGroup = this.AllFetchData.groups[0];
				let _arrayAllData = [];
				for (let i = 0; i < this._ddlGp.length; i++) {
					this._ddlGp[i].reportTo = this.AllFetchData.me.name;
					this._ddlGp[i].groups.forEach((item, index) => {
						this._ddlGp[i].groups[index].reportTo = this._ddlGp[i].name;
					})
					if (this._ddlGp[i].role !== 'user') {
						this.allNonUsers.push(this._ddlGp[i])
					}
					_arrayAllData.push(this._ddlGp[i].groups)

				}
				// this._ddlGp = allNonUsers;
				this.AllMergedData = [].concat.apply([], _arrayAllData);
				this.getAllRecord(this.AllMergedData);
				}
				
			}
		})
	}
	getAllRecord(AllMergedData: any) {
		if (this.selectedAllOption == "All") {
		//	console.log(this._ddlGp);
			this.dataSource = new MatTableDataSource(this._ddlGp);
		}
		else {
			this._ddlGroupGroupsLevel0 = AllMergedData;
			this.dataSource = new MatTableDataSource(AllMergedData);
		}
		this.dataSource.paginator = this.paginator;
		this.dataSource.filterPredicate = function (data, filter: any): boolean {
			return data.name.toString().toLowerCase().includes(filter)
		};

	}

	getSecExecName(value){
		if(value == '' || value == null){
			return '';
		}
		const obj = this.dataSource.data.filter(x => x.id == parseInt(value));
		if(obj && obj.length){
			return obj[0].name;
		}
		return '';
		
	}
	onChange(value: any, level) {
		debugger;
		this.selectedValue = value;
		// this.typeValueDDL = value;
		// this.showHideOptionDDL = true;
		// this.selectedAllOption = "All";
		if (false) {
			this.getAllRecord(this.AllMergedData);
			this.showHideOptionDDL = false;
		}
		else {
			
			if(value == 'All'){
				this.selectedLevel = level - 1;
			}else{
				this.selectedLevel = level;
			}
			if(this.selectedLevel == -1){
				this.resetTeam();
				return;
			}
			// this._ddlGp = this.AllFetchData.groups;
			for(let checkUser of this.AllFetchData.groups){
				if(this.selectedLevel == 0){
					if(this.AllFetchData.groups.length > 0){
						this._ddlGp =  value == 'All' ? this.AllFetchData.groups : this.AllFetchData.groups.filter(v => v.email == value);
						break;
					}
					
				}
				if(checkUser.groups && this.selectedLevel >= 1){
					if(this.selectedLevel == 1){
						if(checkUser.groups.length > 0){
							this._ddlGp = value == 'All' ? checkUser.groups : checkUser.groups.filter(v => v.email == value);
							break;
						}
					}
					for(let checkUserLevel1 of checkUser.groups){
						
						
						if(checkUserLevel1.groups && this.selectedLevel >= 2){
							for(let checkUserLevel2 of checkUserLevel1.groups){
								if(checkUser.groups.length > 0){
									this._ddlGp = value == 'All' ? checkUserLevel1.groups : checkUserLevel1.groups.filter(v => v.email == value);
									break;
								}
								if(checkUserLevel2.groups && this.selectedLevel >= 3){
									for(let checkUserLevel3 of checkUserLevel2.groups){
										if(checkUserLevel2.groups.length > 0){
											this._ddlGp = value == 'All' ? checkUserLevel2.groups : checkUserLevel2.groups.filter(v => v.email == value);
											break;
										}
										 
										if(checkUserLevel3.groups && this.selectedLevel >= 4){
											for(let checkUserLevel4 of checkUserLevel3.groups){
												if(checkUserLevel3.groups.length > 0){
													this._ddlGp = value == 'All' ? checkUserLevel3.groups : checkUserLevel3.groups.filter(v => v.email == value);
													break;
												}
												if(checkUserLevel4.groups && this.selectedLevel >= 5){
													for(let checkUserLevel5 of checkUserLevel4.groups){
														if(checkUserLevel4.groups.length > 0){
															this._ddlGp = value == 'All' ? checkUserLevel4.groups : checkUserLevel4.groups.filter(v => v.email == value);
															break;
														} 
														if(checkUserLevel4.groups && this.selectedLevel >= 6){
															for(let checkUserLevel6 of checkUserLevel5.groups){
																if(checkUserLevel5.groups.length > 0){
																	this._ddlGp = value == 'All' ? checkUserLevel5.groups : checkUserLevel5.groups; 
																	break;
																}
																if(checkUserLevel5.groups && this.selectedLevel >= 7){
																	for(let checkUserLevel7 of checkUserLevel6.groups){
																		if(checkUserLevel6.groups.length > 0){
																			this._ddlGp = value == 'All' ? checkUserLevel6.groups : checkUserLevel6.groups; 
																			break;
																		}
																		if(checkUserLevel6.groups && this.selectedLevel >= 8){
																			for(let checkUserLevel8 of checkUserLevel7.groups){
																				if(checkUserLevel7.groups.length > 0){
																					this._ddlGp = value == 'All' ? checkUserLevel7.groups : checkUserLevel7.groups; 
																					break;
																				}
																				if(checkUserLevel7.groups && this.selectedLevel >= 9){
																					for(let checkUserLevel9 of checkUserLevel8.groups){
																						if(checkUserLevel8.groups.length > 0){
																							this._ddlGp = value == 'All' ? checkUserLevel8.groups : checkUserLevel8.groups; 
																							break;
																						}
																						if(checkUserLevel8.groups && this.selectedLevel >= 10){
																							for(let checkUserLevel10 of checkUserLevel9.groups){
																								if(checkUserLevel9.groups.length > 0){
																									this._ddlGp = value == 'All' ? checkUserLevel9.groups : checkUserLevel9.groups; 
																									break;
																								}
																							}
																						}
																					}
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				
			}
			// console.log(this.selectedLevel,this._ddlGp)
			this.ddlGroup = (this._ddlGp || []).filter(v => { return v.name });;
			//console.log('ddl group', this.ddlGroup)
			if (this.ddlGroup && this.ddlGroup.length) {
				this._name = this.ddlGroup[0].name;
				this._email = this.ddlGroup[0].email;
				this._phone = this.ddlGroup[0].phone;
				this.dataSource = new MatTableDataSource(this.ddlGroup[0].groups);
				this.dataSource.paginator = this.paginator;
				this.dataSource.filterPredicate = function (data, filter: any): boolean {
					return data.name.toString().toLowerCase().includes(filter)
				};
				this['_ddlGroupGroupsLevel'+this.selectedLevel] = (this.ddlGroup[0].groups || []).filter(v => { return v.name && v.role != 'user'});

				if(value != 'All'){
					this.selectedLevel = this.selectedLevel + 1;
				}
			}			
		}
	}
	resetTeam(){
		this.selectedLevel = undefined;
		this._ddlGroupGroupsLevel0= undefined;
	this._ddlGroupGroupsLevel1= undefined;
	this._ddlGroupGroupsLevel2= undefined;
	this._ddlGroupGroupsLevel3= undefined;
	this._ddlGroupGroupsLevel4= undefined;
	this._ddlGroupGroupsLevel5= undefined;
	this._ddlGroupGroupsLevel6= undefined;
	this.selectedAllFirstOption= "All";
	this.selectedAllOption= "All";
	this.selectedAllOptionLevel0= "All";
	this.selectedAllOptionLevel1= "All";
	this.selectedAllOptionLevel2= "All";
	this.selectedAllOptionLevel3= "All";
	this.selectedAllOptionLevel4= "All";
	this.selectedAllOptionLevel5= "All";
	this.selectedAllOptionLevel6= "All";
	this.selectedAllOptionLevel7= "All";
	this.selectedValue= "All";
	//this.inputName.nativeElement.value = ' ';
	this.searchInput.value = '';
	this.me_name = this.loadForDdlData.name ? this.loadForDdlData.name : this.myUser.name;
				this._ddlGp = this.AllFetchData.groups;
				this.ddlGroup = this.AllFetchData.groups;
				this._name = this.AllFetchData.me.name;
			this._email = this.AllFetchData.me.email;
			this._phone = this.AllFetchData.me.phone;
			this.dataSource = new MatTableDataSource(this.ddlGroup);
			this.dataSource.paginator = this.paginator;
			this.dataSource.filterPredicate = function (data, filter: any): boolean {
				return data.name.toString().toLowerCase().includes(filter)
			};
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}
	addProfile(upload: boolean = false) {
		let _id;
		let _reportToName;
		let _groupData;
		if (this.typeValueDDL == "All" || this.selectedAllFirstOption == "All") {
			_reportToName = this.me_name;
			_id = this.loadForDdlData.id ? this.loadForDdlData.id : this.myUser.id;
			_groupData = this._ddlGp;
		}
		else {
			_reportToName = this.selectedAllFirstOption;
			_id = this.ddlGroup[0].id;
			_groupData = this.ddlGroup;
		}
		let _obj = {
			"meName": this._name,
			"forNew": 'add',
			"_id": _id,
			"_group": _groupData,
			upload
		}
		const dialogRef = this.dialog.open(ManagementTeamDialogComponent, {
			width: '400px',
			disableClose: true,
			data: _obj
		});

		dialogRef.afterClosed().subscribe(result => {
		//	debugger;
			// console.log('The dialog was closed');
			if (result && result.refresh) {
				this.getManagemantTeamTable();
				// this.selectedAllFirstOption = "All";
				// this.selectedAllOption = "All";
				// this.showHideOptionDDL = false;
			}

		});
	}
	workoption(upload: boolean = false) {
		let _id;
		let _reportToName;
		let _groupData;
		if (this.typeValueDDL == "All" || this.selectedAllFirstOption == "All") {
			_reportToName = this.me_name;
			_id = this.loadForDdlData.id ? this.loadForDdlData.id : this.myUser.id;
			_groupData = this._ddlGp;
		}
		else {
			_reportToName = this.selectedAllFirstOption;
			_id = this.ddlGroup[0].id;
			_groupData = this.ddlGroup;
		}
		let _obj = {
			"meName": this._name,
			"forNew": 'workoption',
			"_id": _id,
			"_group": _groupData,
			upload
		}
		const dialogRef = this.dialog.open(ManagementTeamDialogComponent, {
			width: '400px',
			disableClose: true,
			data: _obj
		});

		dialogRef.afterClosed().subscribe(result => {
		//	debugger;
			// console.log('The dialog was closed');
			if (result && result.refresh) {
				this.getManagemantTeamTable();
				// this.selectedAllFirstOption = "All";
				// this.selectedAllOption = "All";
				// this.showHideOptionDDL = false;
			}

		});
	}
	getUserRole(r: string) {
		let result = 'User';
		for (var i in this._role) {
			if (this._role[i]['role'] == r) {
				result = this._role[i]['name']
			}
		}
		return result;
	}
	editProfile(element: any) {
	//	debugger;
		this.searchInput.value = '';
		let _reportToName;
		let _id;
	//	console.log(element);
		if (this.selectedAllFirstOption == "All") {
			_id = this.loadForDdlData.id;
			_reportToName = this.me_name;
		}
		else {
			_id = this.ddlGroup[0].id;
			_reportToName = this.selectedAllFirstOption;
		}
		let _element = {
			"_group": this._ddlGp,
			"_element": element,
			"_id": _id,
			"_typeDrodown": this.selectedAllFirstOption,
			"meName": _reportToName,
		}
		const dialogRef = this.dialog.open(ManagementTeamDialogComponent, {
			width: '400px',
			disableClose: true,
			data: _element
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.refresh) {
				this.getManagemantTeamTable();
				// console.log('The dialog was closed');
				// this.selectedAllFirstOption = "All";
				// this.selectedAllOption = "All";
				// this.showHideOptionDDL = false;
				// this.typeValueDDL == "All";
			}

		});
	}

	changeStatus(e, row) {
		// This is for the force logout
		let prev_status = !e.checked;
		let cur_status = e.checked;
		let to_user = row.id;
		let to_user_status = 'disable';
		if (cur_status) {
			to_user_status = 'enable';
		}
		this.API.forceLogout({ "to_user": to_user, "to_user_status": to_user_status },this.api_end_point).subscribe((res: any) => {
			if (res && res.success) {
				this.snackBar.open('Success', "Ok", {
					duration: 1000,
				  });
			}
		})
		// console.log(e, row);
	}
}